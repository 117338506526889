import { createStyles } from '@material-ui/core';
import { GowagoTheme } from './../../../theme';

export const styles = (theme: GowagoTheme) =>
  createStyles({
    root: {
      display: 'block',
      width: '100%',
      height: 37,
      paddingLeft: 10,
      paddingTop: 8,
      paddingBottom: 8,
      lineHeight: 1.5,
      color: theme.gowago.text.secondary,
      backgroundColor: theme.gowago.white,
      backgroundClip: 'padding-box',
      border: '1px solid #ced4da',
      borderRadius: '0.2rem',
      '-webkit-transition': 'border-color 0.15s ease-in-out',
      boxShadow: '0.15s ease-in-out',
      transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
      overflow: 'visible',
      margin: 0,
      '&.active': {
        border: '1px solid #2196f3',
      },
      '&.disabled': {
        backgroundColor: '#E9ECEF',
        cursor: 'not-allowed',
      },
      '&.error': {
        border: '1px solid #e53934',
      },
      '&.withInputAdornment': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 0,
        overflow: 'hidden',
      },
    },
    required: {
      fontWeight: 700,
    },

    inputAdornment: {
      height: '100%',
      width: 36,
      backgroundColor: '#e9ecef',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&.start': {
        marginRight: 10,
        borderRight: '1px solid #ced4da',
      },
      '&.isText': {
        width: 50,
      },
    },
    adormentIcon: {
      height: 14,
      width: 14,
      color: theme.gowago.text.secondary,
      fontSize: theme.gowago.fontSize.smaller,
      lineHeight: '14px',
    },
    input: {
      width: '100%',
      height: '100%',
      border: 'none',
      fontSize: theme.gowago.spacing.standard,
      '&:focus': {
        outline: 'none',
      },
      '&:disabled': {
        color: theme.gowago.text.secondary,
        backgroundColor: '#E9ECEF',
        cursor: 'not-allowed',
      },
    },
    label: {
      display: 'inline-block',
      marginBottom: '0.5rem',
      boxSizing: 'border-box',
      cursor: 'default',
      color: theme.gowago.text.primary,
      fontSize: theme.gowago.fontSize.smaller,
      lineHeight: theme.gowago.lineHeight.rem[19],
    },
    errorLabel: {
      fontSize: theme.gowago.fontSize.small,
      marginTop: 5,
      color: '#fd5252',
    },
    footerLabel: {
      marginTop: 3,
      fontSize: theme.gowago.spacing.small,
      lineHeight: '16px',
    },
    componentRoot: {
      marginBottom: theme.gowago.spacing.standard,
    },
  });
