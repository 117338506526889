import { defineMessages } from 'react-intl';

export default defineMessages({
  AllInOneCostsCosts: {
    id: 'AllInOneCostsCosts',
    defaultMessage: 'Costs',
  },
  AllInOneCostsInsurance: {
    id: 'AllInOneCostsInsurance',
    defaultMessage: 'Insurance',
  },
  AllInOneCostsService: {
    id: 'AllInOneCostsService',
    defaultMessage: 'Service',
  },
  AllInOneCostsTaxes: {
    id: 'AllInOneCostsTaxes',
    defaultMessage: 'Taxes',
  },
  AllInOneCostsTyres: {
    id: 'AllInOneCostsTyres',
    defaultMessage: 'Tyres',
  },
  AllInOneCostsWarranty: {
    id: 'AllInOneCostsWarranty',
    defaultMessage: 'Warranty',
  },
  AllInOneCostsKMPeriod: {
    id: 'AllInOneCostsKMPeriod',
    defaultMessage: 'KM / Period',
  },
  AllInOneCostsVignette: {
    id: 'AllInOneCostsVignette',
    defaultMessage: 'Vignette',
  },
});
