import { defineMessages } from 'react-intl';

export default defineMessages({
  StandardEquipmentFormEquipmentIncluded: {
    id: 'StandardEquipmentFormEquipmentIncluded',
    defaultMessage: 'Equipment included',
  },
  StandardEquipmentForm_alloy_wheels: {
    id: 'StandardEquipmentForm_alloy_wheels',
    defaultMessage: 'Alloy wheels',
  },
  StandardEquipmentForm_trailer_hitch: {
    id: 'StandardEquipmentForm_trailer_hitch',
    defaultMessage: 'Trailer hitch',
  },
  StandardEquipmentForm_bluetooth_interface: {
    id: 'StandardEquipmentForm_bluetooth_interface',
    defaultMessage: 'Bluetooth interface',
  },
  StandardEquipmentForm_dab_radio: {
    id: 'StandardEquipmentForm_dab_radio',
    defaultMessage: 'DAB radio',
  },
  StandardEquipmentForm_differential_lock: {
    id: 'StandardEquipmentForm_differential_lock',
    defaultMessage: 'Differential lock',
  },
  StandardEquipmentForm_distance_controller: {
    id: 'StandardEquipmentForm_distance_controller',
    defaultMessage: 'Distance controller',
  },
  StandardEquipmentForm_electric_seat_adjustment: {
    id: 'StandardEquipmentForm_electric_seat_adjustment',
    defaultMessage: 'Electric seat adjustment',
  },
  StandardEquipmentForm_handsfree: {
    id: 'StandardEquipmentForm_handsfree',
    defaultMessage: 'Hands-free',
  },
  StandardEquipmentForm_hardtop: {
    id: 'StandardEquipmentForm_hardtop',
    defaultMessage: 'Hard-top',
  },
  StandardEquipmentForm_isofix: {
    id: 'StandardEquipmentForm_isofix',
    defaultMessage: 'ISOFIX',
  },
  StandardEquipmentForm_air_conditioning: {
    id: 'StandardEquipmentForm_air_conditioning',
    defaultMessage: 'Air conditioning',
  },
  StandardEquipmentForm_automatic_air_conditioning: {
    id: 'StandardEquipmentForm_automatic_air_conditioning',
    defaultMessage: 'Automatic air conditioning',
  },
  StandardEquipmentForm_manual_air_conditioning: {
    id: 'StandardEquipmentForm_manual_air_conditioning',
    defaultMessage: 'Manual air conditioning',
  },
  StandardEquipmentForm_bend_lighting: {
    id: 'StandardEquipmentForm_bend_lighting',
    defaultMessage: 'Bend lighting',
  },
  StandardEquipmentForm_air_suspension: {
    id: 'StandardEquipmentForm_air_suspension',
    defaultMessage: 'Air suspension',
  },
  StandardEquipmentForm_navigation_system: {
    id: 'StandardEquipmentForm_navigation_system',
    defaultMessage: 'Navigation system',
  },
  StandardEquipmentForm_built_in_navigation_system: {
    id: 'StandardEquipmentForm_built_in_navigation_system',
    defaultMessage: 'Navigation system (integrated)',
  },
  StandardEquipmentForm_portable_navigation_system: {
    id: 'StandardEquipmentForm_portable_navigation_system',
    defaultMessage: 'Navigation system (portable)',
  },
  StandardEquipmentForm_panorama_roof: {
    id: 'StandardEquipmentForm_panorama_roof',
    defaultMessage: 'Panorama roof',
  },
  StandardEquipmentForm_parking_aid: {
    id: 'StandardEquipmentForm_parking_aid',
    defaultMessage: 'Parking aid',
  },
  StandardEquipmentForm_parking_sensors_in_the_back: {
    id: 'StandardEquipmentForm_parking_sensors_in_the_back',
    defaultMessage: 'Parking sensors (rear)',
  },
  StandardEquipmentForm_parking_sensors_front: {
    id: 'StandardEquipmentForm_parking_sensors_front',
    defaultMessage: 'Parking sensors (front)',
  },
  StandardEquipmentForm_rear_view_camera: {
    id: 'StandardEquipmentForm_rear_view_camera',
    defaultMessage: 'Rear view camera',
  },
  StandardEquipmentForm_headlights: {
    id: 'StandardEquipmentForm_headlights',
    defaultMessage: 'Headlights',
  },
  StandardEquipmentForm_led_headlights: {
    id: 'StandardEquipmentForm_led_headlights',
    defaultMessage: 'LED headlights',
  },
  StandardEquipmentForm_xenon_headlights: {
    id: 'StandardEquipmentForm_xenon_headlights',
    defaultMessage: 'Xenon headlights',
  },
  StandardEquipmentForm_laser_headlights: {
    id: 'StandardEquipmentForm_laser_headlights',
    defaultMessage: 'Laser headlights',
  },
  StandardEquipmentForm_sunroof: {
    id: 'StandardEquipmentForm_sunroof',
    defaultMessage: 'Sunroof',
  },
  StandardEquipmentForm_keyless_access_start: {
    id: 'StandardEquipmentForm_keyless_access_start',
    defaultMessage: 'Keyless access/start',
  },
  StandardEquipmentForm_seat_ventilation: {
    id: 'StandardEquipmentForm_seat_ventilation',
    defaultMessage: 'Seat ventilation',
  },
  StandardEquipmentForm_seat_covers: {
    id: 'StandardEquipmentForm_seat_covers',
    defaultMessage: 'Seat covers',
  },
  StandardEquipmentForm_seat_cover_alcantara: {
    id: 'StandardEquipmentForm_seat_cover_alcantara',
    defaultMessage: 'Seat covers (alcantra)',
  },
  StandardEquipmentForm_seat_cover_leather: {
    id: 'StandardEquipmentForm_seat_cover_leather',
    defaultMessage: 'Seat covers (leather)',
  },
  StandardEquipmentForm_seat_cover_fabric: {
    id: 'StandardEquipmentForm_seat_cover_fabric',
    defaultMessage: 'Seat covers (fabric)',
  },
  StandardEquipmentForm_seat_cover_partial_leather: {
    id: 'StandardEquipmentForm_seat_cover_partial_leather',
    defaultMessage: 'Seat covers (partial leather)',
  },
  StandardEquipmentForm_seat_heating: {
    id: 'StandardEquipmentForm_seat_heating',
    defaultMessage: 'Seat heating',
  },
  StandardEquipmentForm_sports_seats: {
    id: 'StandardEquipmentForm_sports_seats',
    defaultMessage: 'Sports seats',
  },
  StandardEquipmentForm_lane_keeping_assistant: {
    id: 'StandardEquipmentForm_lane_keeping_assistant',
    defaultMessage: 'Lane keeping assistant',
  },
  StandardEquipmentForm_stability_control: {
    id: 'StandardEquipmentForm_stability_control',
    defaultMessage: 'Stability control',
  },
  StandardEquipmentForm_parking_heater: {
    id: 'StandardEquipmentForm_parking_heater',
    defaultMessage: 'Parking heater',
  },
  StandardEquipmentForm_start_stop_system: {
    id: 'StandardEquipmentForm_start_stop_system',
    defaultMessage: 'Start/stop system',
  },
  StandardEquipmentForm_cruise_control: {
    id: 'StandardEquipmentForm_cruise_control',
    defaultMessage: 'Cruise control',
  },
  StandardEquipmentForm_blind_spot_assistant: {
    id: 'StandardEquipmentForm_blind_spot_assistant',
    defaultMessage: 'Blind spot assistant',
  },
  StandardEquipmentForm_eight_tires: {
    id: 'StandardEquipmentForm_eight_tires',
    defaultMessage: 'Eight tires',
  },
  StandardEquipmentForm_roof_rack: {
    id: 'StandardEquipmentForm_roof_rack',
    defaultMessage: 'Roof rack',
  },
  StandardEquipmentForm_dog_lattice: {
    id: 'StandardEquipmentForm_dog_lattice',
    defaultMessage: 'Dog lattice',
  },
});
