import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Card from 'components/Card';
import SelectionBase from 'components/Forms/SelectionBase';
import LoadingIndicator from 'components/LoadingIndicator';
import { routeConstants } from 'constants/routes';
import { useFormikContext } from 'formik';
import _set from 'lodash.set';
import { AddEditListingSchema, DraftListingsValidationSchema } from 'pages/AddEditListings/schema';
import { convertSchemaToDealRequest, FormStatus, getMakeSlug } from 'pages/AddEditListings/utils';
import { RootState } from 'pages/_store/root-reducer';
import { FunctionComponent, useState } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DealStatus, DefaultVendorParams, SelectOption } from 'types';
import { compose } from 'utils/compose';
import { ValidationError } from 'yup';
import { Actions } from '../../store/actions';
import { getStatusOptions } from './options';
import styles from './SubmitButtons.module.scss';
import Translations from './SubmitButtons.translations';

const REACT_APP_GOWAGO_URL = String(process.env.REACT_APP_GOWAGO_URL);

const SubmitButtons: FunctionComponent = () => {
  const intl = useIntl();
  const { formatMessage } = intl;

  const { submitForm, values, setStatus } = useFormikContext<AddEditListingSchema>();
  const dispatch = useDispatch();
  const { vendorId } = useParams<DefaultVendorParams>();

  const { deal, isLoadingDeal, unsavedChanges } = useSelector(
    (state: RootState) => state.addEditListingsPage,
    shallowEqual
  );

  const statusOptions = getStatusOptions(intl);

  const [selectedStatus, setSelectedStatus] = useState<SelectOption>(statusOptions[0]);

  const getDealId = () => deal?.id;
  const getDealStatus = () => deal?.status;
  const getMake = () => getMakeSlug(deal?.listing?.make);
  const getModelSlug = () => deal?.listing?.modelSlug;
  const getPublicId = () => deal?.publicId;

  const getRedirectUrl = () => {
    if (!deal || !deal.id) {
      return window.location.href;
    }
    return (
      process.env.REACT_APP_CANONICAL_URL + routeConstants.PROTECTED.EDIT_LISTINGS_PAGE.url(Number(vendorId), deal.id)
    );
  };

  const gowagoDealPageUrl = () =>
    `${REACT_APP_GOWAGO_URL}listing/${getMake()}-${getModelSlug()}/${getPublicId()}?editListingReturnUrl=${getRedirectUrl()}&disableCache=1&isPreview=true`;

  const onSubmit = () => {
    setStatus(FormStatus.PUBLISH);
    setSelectedStatus(statusOptions[0]);
    dispatch(Actions.hideSuccessAlertMessage());
    submitForm();
  };

  const saveDraft = () => {
    dispatch(Actions.hideSuccessAlertMessage());
    setStatus(FormStatus.DRAFT);

    const vendor = !deal && !getDealStatus() ? Number(vendorId) : undefined;

    DraftListingsValidationSchema(intl)
      .validate(values, { abortEarly: false })
      .then((isValid) => {
        dispatch(
          Actions.saveDraftRequest(
            values,
            convertSchemaToDealRequest(values, vendor),
            values.images,
            Number(vendorId),
            getDealId()
          )
        );
      })
      .catch((reason) => {
        if (reason.inner && reason.inner.length > 0) {
          const validationError = {};
          reason.inner.forEach((err: ValidationError) => {
            _set(validationError, err.path, err.message);
          });
          dispatch(Actions.setDraftValidationError(validationError));
        }
      });
  };

  const moveToTrash = () => dispatch(Actions.moveToTrashRequest(getDealId()));

  const duplicate = () => dispatch(Actions.duplicateDealRequest(Number(vendorId)));

  const onChangeStatusChange = (value: string) => {
    setSelectedStatus(statusOptions.find((o) => o.value === value));
  };

  const onChangeStatusBlur = () => {};

  const onUpdateClick = () => {
    const status = selectedStatus?.value as DealStatus;
    switch (status) {
      case DealStatus.DRAFT:
        saveDraft();
        break;
      case DealStatus.TRASH:
        moveToTrash();
        break;
      default:
        onSubmit();
        break;
    }
  };

  const getStatusValues = (): [string, string] => {
    switch (getDealStatus()) {
      case DealStatus.PUBLISHED:
        return [
          formatMessage(Translations.SubmitButtonsStatusValuePublished),
          formatMessage(Translations.SubmitButtonsVisibilityPublicListed),
        ];
      case DealStatus.DRAFT:
        return [
          formatMessage(Translations.SubmitButtonsStatusValueDraft),
          formatMessage(Translations.SubmitButtonsVisibilityPrivateUnlistedValue),
        ];
      case DealStatus.TRASH:
        return [
          formatMessage(Translations.SubmitButtonsStatusValueTrash),
          formatMessage(Translations.SubmitButtonsVisibilityPrivateUnlistedValue),
        ];
      case DealStatus.ERROR:
        return [
          formatMessage(Translations.SubmitButtonsStatusValueError),
          formatMessage(Translations.SubmitButtonsVisibilityPrivateUnlistedValue),
        ];
      default:
        return [
          formatMessage(Translations.SubmitButtonsStatusValueUnsaved),
          formatMessage(Translations.SubmitButtonsVisibilityPrivateUnlistedValue),
        ];
    }
  };

  const [status, visibility] = getStatusValues();
  const isPublished = getDealStatus() === DealStatus.PUBLISHED;
  const isDraft = getDealStatus() === DealStatus.DRAFT;
  const isPreviewButtonVisible =
    !!deal?.publicId && deal?.listing?.make && deal?.listing?.modelSlug && (isPublished || isDraft);

  return (
    <div className={styles.root}>
      <Card className={styles.card}>
        <LoadingIndicator isLoading={isLoadingDeal}>
          <div className={styles.cardContainer}>
            <div
              className={classNames(styles.secondaryButtonContainer, {
                [styles.noMargin]: isPublished,
              })}
            >
              <div className={styles.columnLeft}>
                {isPublished ? (
                  <SelectionBase
                    options={statusOptions}
                    onChange={onChangeStatusChange}
                    onBlur={onChangeStatusBlur}
                    value={selectedStatus}
                    className={styles.changeStatusOption}
                    customStyles={{
                      singleValue: (styles) => ({ ...styles, color: '#2096F3' }),
                    }}
                  />
                ) : (
                  <button className={styles.secondaryButton} onClick={saveDraft} type="button">
                    {formatMessage(Translations.SubmitButtonsSaveDraft)}
                  </button>
                )}
                {getDealId() && (
                  <button
                    className={classNames(styles.secondaryButton, styles.buttonTopSpacing)}
                    onClick={duplicate}
                    type="button"
                    disabled={unsavedChanges}
                  >
                    {formatMessage(Translations.SubmitButtonsDuplicate)}
                  </button>
                )}
              </div>
              {isPreviewButtonVisible && (
                <a
                  className={classNames(styles.secondaryButton, styles.linkButton, {
                    [styles.previewPublishedBtn]: isPublished,
                  })}
                  href={gowagoDealPageUrl()}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {formatMessage(Translations.SubmitButtonsPreview)}
                </a>
              )}
            </div>
            <div className={styles.status}>
              {formatMessage(Translations.SubmitButtonsStatusLabel, {
                value: (
                  <span key="statusValue-status" className={styles.statusValue}>
                    {status}
                  </span>
                ),
              })}
            </div>
            <div className={styles.status}>
              {formatMessage(Translations.SubmitButtonsVisibilityLabel, {
                value: (
                  <span key="statusValue-visibility" className={styles.statusValue}>
                    {visibility}
                  </span>
                ),
              })}
            </div>
            <div className={styles.divider} />
            <div className={styles.primaryButtonContainer}>
              <button className={styles.moveToTrashButton} onClick={moveToTrash} type="button">
                <FontAwesomeIcon className={styles.moveToTrashIcon} icon={['fal', 'trash-alt']} />
                {formatMessage(Translations.SubmitButtonsMoveToTrash)}
              </button>
              {isPublished ? (
                <button className={styles.publishButton} onClick={onUpdateClick} type="button">
                  {formatMessage(Translations.SubmitButtonsUpdate)}
                </button>
              ) : (
                <button className={styles.publishButton} onClick={onSubmit} type="button">
                  {formatMessage(Translations.SubmitButtonsPublish)}
                </button>
              )}
            </div>
          </div>
        </LoadingIndicator>
      </Card>
      <div className={styles.legend}>{formatMessage(Translations.SubmitButtonsRequiredInformation)}</div>
    </div>
  );
};

export default compose(SubmitButtons);
