import { PublicOffer } from 'types';

export interface OrderPageState {
  order?: PublicOffer;
  isFetchingResult: boolean;
  isUpdatingOrder: boolean;
  updateSuccess: boolean;
  errorMessages?: Array<string>;
}

export const initialState: OrderPageState = {
  order: undefined,
  isFetchingResult: false,
  isUpdatingOrder: false,
  updateSuccess: false,
  errorMessages: [],
};
