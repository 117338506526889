import { getSeller, getVendorsOfSeller, updateSeller } from 'api/seller';
import { diff } from 'deep-object-diff';
import { RootState } from 'pages/_store/root-reducer';
import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { call, select } from 'typed-redux-saga';
import { Seller, Vendor } from 'types';
import { Actions as SellerProfileAction, ActionTypes as SellerProfileActionTypes } from './actions';

function* getSellerRequestAsync(action: ReturnType<typeof SellerProfileAction.getSellerRequest>) {
  try {
    const sellerId = action.payload || 0;
    const [seller, vendors] = yield all([call(getSeller, sellerId), call(getVendorsOfSeller, sellerId)]);
    const vendorsNames = (vendors as Vendor[]).map((vendor) => vendor.name);
    yield put(SellerProfileAction.getSellerSuccess(seller, vendorsNames));
  } catch (e) {
    yield put(SellerProfileAction.getSellerFailure());
  }
}

function* watchGetSellerRequestAsync() {
  yield takeEvery(SellerProfileActionTypes.GET_SELLER_REQUEST, getSellerRequestAsync);
}

function* updateSellerRequestAsync(action: ReturnType<typeof SellerProfileAction.updateSellerRequest>) {
  try {
    const seller = (yield* select((state: RootState) => state.sellerProfilePage.seller)) as Seller;
    const { id } = seller;
    const currentSellerInfo = {
      firstName: seller.firstName,
      lastName: seller.lastName,
    };

    const sellerUpdate = {
      firstName: action.payload.name,
      lastName: action.payload.lastName,
    };
    const sellerUpdatedFields = diff(currentSellerInfo, sellerUpdate);
    yield* call(updateSeller, id, sellerUpdatedFields);
    yield put(SellerProfileAction.updateSellerSuccess());
  } catch (error) {
    const isHubspotError = error.statusCode && Number(error.statusCode) >= 500 && Number(error.statusCode) <= 599;
    yield put(SellerProfileAction.updateSellerFailure(isHubspotError));
  }
}

function* watchUpdateSellerRequestAsync() {
  yield takeEvery(SellerProfileActionTypes.UPDATE_SELLER_REQUEST, updateSellerRequestAsync);
}

export default [fork(watchGetSellerRequestAsync), fork(watchUpdateSellerRequestAsync)];
