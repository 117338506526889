import { defineMessages } from 'react-intl';

export default defineMessages({
  ManagePeopleNotificationsInvitationSentSuccessfully: {
    id: 'ManagePeopleNotificationsInvitationSentSuccessfully',
    defaultMessage: 'Invitation sent successfully',
  },
  ManagePeopleNotificationsSomethingWentWrong: {
    id: 'ManagePeopleNotificationsSomethingWentWrong',
    defaultMessage: 'Something went wrong',
  },
  ManagePeopleNotificationsIfThisIssuePersistPleaseContactOurSupport: {
    id: 'ManagePeopleNotificationsIfThisIssuePersistPleaseContactOurSupport',
    defaultMessage: 'If this issue persists, please contact our support team for assistance',
  },
});
