import { useFormikContext } from 'formik';
import { AddEditListingSchema } from 'pages/AddEditListings/schema';
import { RootState } from 'pages/_store/root-reducer';
import { FC, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { SourceType } from 'types';
import { compose } from 'utils/compose';
import { Actions as AddEditListingsActions } from '../../store/actions';
import AllInOneSection from '../AllInOneSection';
import CarImageForm from '../CarImageForm';
import CarPriceForm from '../CarPriceForm';
import DisableSyncConfirmationModal from '../DisableSyncConfirmationModal';
import EnableSyncConfirmationModal from '../EnableSyncConfirmationModal';
import Notifications from '../Notifications';
import PromotionsForm from '../PromotionsForm';
import StandardEquipmentForm from '../StandardEquipmentForm';
import SubmitButtons from '../SubmitButtons';
import SyncWithAutoScoutForm from '../SyncWithAutoScoutForm';
import TechnicalInformationForm from '../TechnicalInformationForm';
import VehicleBaseInformationForm from '../VehicleBaseInformationForm';
import styles from './FormContent.module.scss';

interface Props {
  isCreate?: boolean;
}

interface Params {
  vendorId: string;
  dealId?: string;
}

const FormContent: FC<Props> = ({ isCreate }) => {
  const { handleSubmit, setFieldValue, values, resetForm } = useFormikContext<AddEditListingSchema>();
  const { vendorId, dealId } = useParams<Params>();
  const dispatch = useDispatch();
  const { isSyncUpdateError, source } = useSelector(
    (state: RootState) => ({
      isSyncUpdateError: state.addEditListingsPage.isSyncUpdateError,
      source: state.addEditListingsPage?.deal?.listing?.source,
    }),
    shallowEqual
  );

  useEffect(() => {
    resetForm();
  }, [vendorId, dealId, resetForm]);

  useEffect(() => {
    if (isSyncUpdateError) {
      setFieldValue('isLockedToSource', !values.isLockedToSource);
      dispatch(AddEditListingsActions.setIsUpdateSyncError(false));
    }
  }, [dispatch, isSyncUpdateError, setFieldValue, values]);

  return (
    <>
      <Notifications />
      <div className={styles.mainContainer}>
        <div className={styles.formContainer}>
          <form onSubmit={handleSubmit}>
            {dealId && source === SourceType.AS24 && <SyncWithAutoScoutForm />}
            <VehicleBaseInformationForm />
            <PromotionsForm />
            <CarPriceForm isCreate={isCreate} />
            <AllInOneSection />
            <TechnicalInformationForm />
            <StandardEquipmentForm />
            <CarImageForm />
          </form>
        </div>
        <div className={styles.submitContainer}>
          <SubmitButtons />
        </div>
      </div>
      <EnableSyncConfirmationModal />
      <DisableSyncConfirmationModal />
    </>
  );
};

export default compose<Props>(FormContent);
