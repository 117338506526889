import { defineMessages } from 'react-intl';

export default defineMessages({
  DisableSyncConfirmationModalDisableSyncWithAutoScout24: {
    id: 'DisableSyncConfirmationModalDisableSyncWithAutoScout24',
    defaultMessage: 'Disable sync with AutoScout24',
  },
  DisableSyncConfirmationModalDisableAutomaticListingUpdates: {
    id: 'DisableSyncConfirmationModalDisableAutomaticListingUpdates',
    defaultMessage: 'Disable automatic listing updates from AutoScout24?',
  },
  DisableSyncConfirmationModalBack: {
    id: 'DisableSyncConfirmationModalBack',
    defaultMessage: 'Back',
  },
  DisableSyncConfirmationModalYesDisableSync: {
    id: 'DisableSyncConfirmationModalYesDisableSync',
    defaultMessage: 'Yes, disable sync',
  },
});
