import ConfirmationModalBase from 'components/ConfirmationModalBase';
import { useFormikContext } from 'formik';
import { AddEditListingSchema } from 'pages/AddEditListings/schema';
import { RootState } from 'pages/_store/root-reducer';
import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Actions as AddEditListingsActions } from '../../store/actions';
import styles from './EnableSyncConfirmationModal.module.scss';
import Translations from './EnableSyncConfirmationModal.translations';

const EnableSyncConfirmationModal: FunctionComponent = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const isEnableSyncConfirmationModalOpen = useSelector(
    (state: RootState) => state.addEditListingsPage.isEnableSyncDialogOpen,
    shallowEqual
  );

  const { setFieldValue, values } = useFormikContext<AddEditListingSchema>();

  const onOkey = () => {
    dispatch(AddEditListingsActions.setIsEnableSyncDialogOpen(false));
    dispatch(AddEditListingsActions.updateLockedToSourceRequest(true));
  };

  const onClose = () => {
    setFieldValue('isLockedToSource', !values.isLockedToSource);
    dispatch(AddEditListingsActions.setIsEnableSyncDialogOpen(false));
  };

  const descriptionLine = <span className={styles.descriptionLine}>-</span>;

  return (
    <ConfirmationModalBase
      title={formatMessage(Translations.EnableSyncConfirmationModalEnableSyncWithAutoScout24)}
      message={formatMessage(Translations.EnableSyncConfirmationModalEnableAutomaticListingUpdates)}
      okLabel={formatMessage(Translations.EnableSyncConfirmationModalYesEnableSync)}
      closeLabel={formatMessage(Translations.EnableSyncConfirmationModalBack)}
      isOpen={isEnableSyncConfirmationModalOpen}
      onOkay={onOkey}
      onClose={onClose}
      variant={'error'}
      isOkButtonLeft={false}
    >
      <>
        <div className={styles.descriptionTitle}>
          {formatMessage(Translations.EnableSyncConfirmationModalWhatWillChange)}
        </div>
        <div className={styles.descriptionContent}>
          <div className={styles.descriptionRow}>
            {descriptionLine}
            {formatMessage(Translations.EnableSyncConfirmationModalYourGowagoChListingsInformationWillBeReplaced)}
          </div>
          <div className={styles.descriptionRow}>
            {descriptionLine}
            {formatMessage(Translations.EnableSyncConfirmationModalAnyFutureChangesMadeToTheAutoScout24ListingWill)}
          </div>
          <div className={styles.descriptionRow}>
            {descriptionLine}
            {formatMessage(Translations.EnableSyncConfirmationModalLeasingInformationAndResidualValuesInYourGowago)}
          </div>
          <div className={styles.descriptionRow}>
            {descriptionLine}
            {formatMessage(Translations.EnableSyncConfirmationModalItCanTakeUpToAnHourForTheseChangesToTakeEffect)}
          </div>
        </div>
      </>
    </ConfirmationModalBase>
  );
};

export default EnableSyncConfirmationModal;
