import { defineMessages } from 'react-intl';

export default defineMessages({
  SetNewPasswordPageThisFieldIsRequired: {
    id: 'SetNewPasswordPageThisFieldIsRequired',
    defaultMessage: 'This field is required',
  },
  SetNewPasswordPageYourNewPasswordHasBeenSet: {
    id: 'SetNewPasswordPageYourNewPasswordHasBeenSet',
    defaultMessage: 'Your new password has been set',
  },
  SetNewPasswordPageSetANewPassword: {
    id: 'SetNewPasswordPageSetANewPassword',
    defaultMessage: 'Set a new password',
  },
  SetNewPasswordPageNewPassword: {
    id: 'SetNewPasswordPageNewPassword',
    defaultMessage: 'New password',
  },
  SetNewPasswordPageRepeatPassword: {
    id: 'SetNewPasswordPageRepeatPassword',
    defaultMessage: 'Repeat password',
  },
  SetNewPasswordPageSetPassword: {
    id: 'SetNewPasswordPageSetPassword',
    defaultMessage: 'Set password',
  },
  SetNewPasswordPagePasswordNotEqual: {
    id: 'SetNewPasswordPagePasswordNotEqual',
    defaultMessage: 'Passwords are not the same',
  },
  SetNewPasswordPageNeedSomeHelp: {
    id: 'SetNewPasswordPageNeedSomeHelp',
    defaultMessage: 'Need some help',
  },
  SetNewPasswordPageTokenInvalid: {
    id: 'SetNewPasswordPageTokenInvalid',
    defaultMessage: 'Reset password token invalid',
  },
  SetNewPasswordPageEmailInvalid: {
    id: 'SetNewPasswordPageEmailInvalid',
    defaultMessage: 'Invalid email',
  },
  SetNewPasswordPageTokenExpired: {
    id: 'SetNewPasswordPageTokenExpired',
    defaultMessage: 'Token has already been expired',
  },
  SetNewPasswordPageInvalidUrlHeader: {
    id: 'SetNewPasswordPageInvalidUrlHeader',
    defaultMessage: 'Invalid url',
  },
  SetNewPasswordPageInvalidUrlMessage: {
    id: 'SetNewPasswordPageInvalidUrlMessage',
    defaultMessage: 'Please contact our support team',
  },
});
