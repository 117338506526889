import classNames from 'classnames';
import Accordion from 'components/Accordion';
import Switch from 'components/Forms/Switch';
import LoadingIndicator from 'components/LoadingIndicator';
import { DATE_TIME_FORMAT } from 'constants/constants';
import { useFormikContext } from 'formik';
import moment from 'moment';
import { AddEditListingSchema } from 'pages/AddEditListings/schema';
import { RootState } from 'pages/_store/root-reducer';
import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Actions as AddEditListingsActions } from '../../store/actions';
import styles from './SyncWithAutoScoutForm.module.scss';
import Translations from './SyncWithAutoScoutForm.translations';

const SyncWithAutoScoutForm: FunctionComponent = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const { deal, isLoadingDeal } = useSelector((state: RootState) => state.addEditListingsPage, shallowEqual);

  const { values } = useFormikContext<AddEditListingSchema>();

  const onSyncChange = (value: boolean) => {
    if (value) {
      dispatch(AddEditListingsActions.setIsEnableSyncDialogOpen(true));
    } else {
      dispatch(AddEditListingsActions.setIsDisableSyncDialogOpen(true));
    }
  };

  return (
    <div className={styles.root}>
      <LoadingIndicator isLoading={isLoadingDeal}>
        <Accordion header={formatMessage(Translations.SyncWithAutoScoutFormSyncWithAutoScout24)} collapsable={false}>
          <div className={styles.content}>
            <div className={styles.checkboxContainer}>
              <Switch
                label={
                  values.isLockedToSource
                    ? formatMessage(Translations.SyncWithAutoScoutFormEnabled)
                    : formatMessage(Translations.SyncWithAutoScoutFormDisabled)
                }
                name="isLockedToSource"
                onChange={onSyncChange}
              />
              <div
                className={classNames(styles.lastSync, {
                  [styles.hidden]: !deal || !values.isLockedToSource,
                })}
              >
                {formatMessage(Translations.SyncWithAutoScoutFormLastSync)}
                {deal && values.isLockedToSource && ` ${moment(deal?.listing?.sourceUpdated).format(DATE_TIME_FORMAT)}`}
              </div>
            </div>
            <div className={styles.text}>
              {formatMessage(Translations.SyncWithAutoScoutFormAutomaticallyUpdateThisListingToAlways)}
              <a
                className={styles.yourLeasingLink}
                target="_blank"
                rel="noopener noreferrer"
                href={deal?.listing?.externalUrl || '/'}
              >
                {formatMessage(Translations.SyncWithAutoScoutFormYourAutoScout24Listing)}
              </a>
              .
            </div>
          </div>
        </Accordion>
      </LoadingIndicator>
    </div>
  );
};

export default SyncWithAutoScoutForm;
