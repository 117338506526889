import { defineMessages } from 'react-intl';

export default defineMessages({
  WorkingContract: {
    id: 'OfferFileUploadFormWorkingContract',
    defaultMessage: 'Working Contract',
  },
  InsurancePolicy: {
    id: 'OfferFileUploadFormInsurancePolicy',
    defaultMessage: 'Insurance policy',
  },
  EmployerLetters: {
    id: 'OfferFileUploadFormEmployerLetters',
    defaultMessage: 'Employer letters',
  },
  BankingStatement: {
    id: 'OfferFileUploadFormBankingStatement',
    defaultMessage: 'Banking statement',
  },
  PurchaseAgreement: {
    id: 'OfferFileUploadFormPurchaseAgreement',
    defaultMessage: 'Purchase agreement',
  },
  PurchaseAgreementCustomerGowago: {
    id: 'OfferFileUploadFormPurchaseAgreementCustomerGowago',
    defaultMessage: 'Purchase agreement - customer Gowago',
  },
  PurchaseAgreementResellerGowago: {
    id: 'OfferFileUploadFormPurchaseAgreementResellerGowago',
    defaultMessage: 'Purchase agreement - reseller gowago',
  },
  HandoverProtocol: {
    id: 'OfferFileUploadFormHandoverProtocol',
    defaultMessage: 'Handover protocol',
  },
  LeasingContract: {
    id: 'OfferFileUploadFormLeasingContract',
    defaultMessage: 'Leasing contract',
  },
  LeasingContractWithoutKFP: {
    id: 'OfferFileUploadFormLeasingContractWithoutKFP',
    defaultMessage: 'Leasing contract without KFP',
  },
  PrivateCreditContract: {
    id: 'OfferFileUploadFormPrivateCreditContract',
    defaultMessage: 'Private credit contract',
  },
  PreApprovalPrivateCredit: {
    id: 'OfferFileUploadFormPreApprovalPrivateCredit',
    defaultMessage: 'Private credit Pre Approval',
  },
  Equipment: {
    id: 'OfferFileUploadFormEquipment',
    defaultMessage: 'Equipment',
  },
  CarteGris: {
    id: 'OfferFileUploadFormCarteGris',
    defaultMessage: 'Carte gris',
  },
  PCASOptConfirmation: {
    id: 'OfferFileUploadFormPCASOptConfirmation',
    defaultMessage: 'PCAS Opt confirmation',
  },
  KFP: {
    id: 'OfferFileUploadFormKFP',
    defaultMessage: 'KFP',
  },
  BuyingContractDealer: {
    id: 'OfferFileUploadFormBuyingContractDealer',
    defaultMessage: 'Buying contract dealer',
  },
  ResidualValueObligationMigros: {
    id: 'OfferFileUploadFormResidualValueObligationMigros',
    defaultMessage: 'Residual value obligation Migros',
  },
  InvoiceDealer: {
    id: 'OfferFileUploadFormInvoiceDealer',
    defaultMessage: 'Invoice dealer',
  },
  HandoverProtocol_AIO: {
    id: 'OfferFileUploadFormHandoverProtocol_AIO',
    defaultMessage: 'Handover protocol AIO',
  },
  HandoverProtocol_MB: {
    id: 'OfferFileUploadFormHandoverProtocol_MB',
    defaultMessage: 'Handover protocol MB',
  },
  AIOContract: {
    id: 'OfferFileUploadFormAIOContract',
    defaultMessage: 'AIO contract',
  },
  AIOContractSigned: {
    id: 'OfferFileUploadFormAIOContractSigned',
    defaultMessage: 'AIO contract signed',
  },
  LogTCAndZEK: {
    id: 'OfferFileUploadFormLogTCAndZEK',
    defaultMessage: 'Log TC and ZEK',
  },
  IDFront: {
    id: 'OfferFileUploadFormIDFront',
    defaultMessage: 'ID front',
  },
  IDBack: {
    id: 'OfferFileUploadFormIDBack',
    defaultMessage: 'ID back',
  },
  SalarySlip1: {
    id: 'OfferFileUploadFormSalarySlip1',
    defaultMessage: 'Salary slip 1',
  },
  SalarySlip2: {
    id: 'OfferFileUploadFormSalarySlip2',
    defaultMessage: 'Salary slip 2',
  },
  SalarySlip3: {
    id: 'OfferFileUploadFormSalarySlip3',
    defaultMessage: 'Salary slip 3',
  },
  SalarySlip4: {
    id: 'OfferFileUploadFormSalarySlip4',
    defaultMessage: 'Salary slip 4',
  },
  SalarySlip5: {
    id: 'OfferFileUploadFormSalarySlip5',
    defaultMessage: 'Salary slip 5',
  },
  SalarySlip6: {
    id: 'OfferFileUploadFormSalarySlip6',
    defaultMessage: 'Salary slip 6',
  },
  AdditionalIncomeSlip1: {
    id: 'OfferFileUploadFormAdditionalIncomeSlip1',
    defaultMessage: 'Additional Income Slip 1',
  },
  AdditionalIncomeSlip2: {
    id: 'OfferFileUploadFormAdditionalIncomeSlip2',
    defaultMessage: 'Additional Income Slip 2',
  },
  AdditionalIncomeSlip3: {
    id: 'OfferFileUploadFormAdditionalIncomeSlip3',
    defaultMessage: 'Additional Income Slip 3',
  },
  AdditionalIncomeSlip4: {
    id: 'OfferFileUploadFormAdditionalIncomeSlip4',
    defaultMessage: 'Additional Income Slip 4',
  },
  AdditionalIncomeSlip5: {
    id: 'OfferFileUploadFormAdditionalIncomeSlip5',
    defaultMessage: 'Additional Income Slip 5',
  },
  AdditionalIncomeSlip6: {
    id: 'OfferFileUploadFormAdditionalIncomeSlip6',
    defaultMessage: 'Additional Income Slip 6',
  },
  Registration: {
    id: 'OfferFileUploadFormRegistration',
    defaultMessage: 'Registration',
  },
  DamageImage1: {
    id: 'OfferFileUploadFormDamageImage1',
    defaultMessage: 'Damage 1',
  },
  DamageImage2: {
    id: 'OfferFileUploadFormDamageImage2',
    defaultMessage: 'Damage 2',
  },
  DamageImage3: {
    id: 'OfferFileUploadFormDamageImage3',
    defaultMessage: 'Damage 3',
  },
  DamageImage4: {
    id: 'OfferFileUploadFormDamageImage4',
    defaultMessage: 'Damage 4',
  },
  DamageImage5: {
    id: 'OfferFileUploadFormDamageImage5',
    defaultMessage: 'Damage 5',
  },
  DamageImage6: {
    id: 'OfferFileUploadFormDamageImage6',
    defaultMessage: 'Damage 6',
  },
  DamageImage7: {
    id: 'OfferFileUploadFormDamageImage7',
    defaultMessage: 'Damage 7',
  },
  DamageImage8: {
    id: 'OfferFileUploadFormDamageImage8',
    defaultMessage: 'Damage 8',
  },
  Front: {
    id: 'OfferFileUploadFormFront',
    defaultMessage: 'Front',
  },
  Rear: {
    id: 'OfferFileUploadFormRear',
    defaultMessage: 'Rear',
  },
  DriverSide: {
    id: 'OfferFileUploadFormDriverSide',
    defaultMessage: 'Driver side',
  },
  PassengerSide: {
    id: 'OfferFileUploadFormPassengerSide',
    defaultMessage: 'Passenger side',
  },
  Tyres: {
    id: 'OfferFileUploadFormTyres',
    defaultMessage: 'Tyres',
  },
  FrontSeats: {
    id: 'OfferFileUploadFormFrontSeats',
    defaultMessage: 'Front seats',
  },
  Dashboard: {
    id: 'OfferFileUploadFormDashboard',
    defaultMessage: 'Dashboard',
  },
  KFPResults: {
    id: 'OfferFileUploadFormKFPResults',
    defaultMessage: 'KFP Results (XML)',
  },
  Passport: {
    id: 'OfferFileUploadFormPassport',
    defaultMessage: 'Passport',
  },
  OfferFileUploadFormKFPResultsUpdatedForCustomer: {
    id: 'OfferFileUploadFormKFPResultsUpdatedForCustomer',
    defaultMessage: 'KFP results updated for customer',
  },
  Other1: {
    id: 'OfferFileUploadFormOther1',
    defaultMessage: 'Other 1',
  },
  Other2: {
    id: 'OfferFileUploadFormOther2',
    defaultMessage: 'Other 2',
  },
  Other3: {
    id: 'OfferFileUploadFormOther3',
    defaultMessage: 'Other 3',
  },
  TradeIn_Other: {
    id: 'OfferFileUploadFormTradeIn_Other',
    defaultMessage: 'Trade in - other',
  },
  MSSOffer: {
    id: 'OfferFileUploadFormMSSOffer',
    defaultMessage: 'MSS offer',
  },
  MSSOffer1: {
    id: 'OfferFileUploadFormMSSOffer1',
    defaultMessage: 'MSS offer 1',
  },
  MSSOffer2: {
    id: 'OfferFileUploadFormMSSOffer2',
    defaultMessage: 'MSS offer 2',
  },
  MSSOffer3: {
    id: 'OfferFileUploadFormMSSOffer3',
    defaultMessage: 'MSS offer 3',
  },
  AIOCalculation: {
    id: 'OfferFileUploadFormAIOCalculation',
    defaultMessage: 'AIO calculation',
  },
  TaxYear1: {
    id: 'OfferFileUploadFormTaxYear1',
    defaultMessage: 'Tax year 1',
  },
  TaxYear2: {
    id: 'OfferFileUploadFormTaxYear2',
    defaultMessage: 'Tax year 2',
  },
  TaxYear3: {
    id: 'OfferFileUploadFormTaxYear3',
    defaultMessage: 'Tax year 3',
  },
  TaxYear4: {
    id: 'OfferFileUploadFormTaxYear4',
    defaultMessage: 'Tax year 4',
  },
  TaxYear5: {
    id: 'OfferFileUploadFormTaxYear5',
    defaultMessage: 'Tax year 5',
  },
  CarRegistrationInvoice: {
    id: 'OfferFileUploadFormCarRegistrationInvoice',
    defaultMessage: 'Car registration invoice',
  },
  Other: {
    id: 'OfferFileUploadFormOther',
    defaultMessage: 'Other',
  },
  AnzeigeZession: {
    id: 'OfferFileUploadAnzeigeZession',
    defaultMessage: 'Anzeige Zession',
  },
  DebtEnforcementExtract: {
    id: 'OfferFileUploadDebtEnforcementExtract',
    defaultMessage: 'Debt enforcement extract',
  },
  FleetDiscountConfirmation: {
    id: 'OfferFileUploadFleetDiscountConfirmation',
    defaultMessage: 'Fleet discount confirmation',
  },
  InvoiceMiba: {
    id: 'OfferFileUploadInvoiceMiba',
    defaultMessage: 'Invoice Miba',
  },
  PurchaseAgreementMiba: {
    id: 'OfferFileUploadPurchaseAgreementMiba',
    defaultMessage: 'Purchase agreement Miba',
  },
  FormA: {
    id: 'OfferFileUploadFormA',
    defaultMessage: 'Form A',
  },
  FormK: {
    id: 'OfferFileUploadFormK',
    defaultMessage: 'Form K',
  },
  FormKYC: {
    id: 'OfferFileUploadFormKYC',
    defaultMessage: 'Form KYC',
  },
  PowerOfAttorney: {
    id: 'OfferFileUploadPowerOfAttorney',
    defaultMessage: 'Power of attorney',
  },
  Business_Leasing_Zession: {
    id: 'OfferFileUploadBusinessLeasingZession',
    defaultMessage: 'Business leasing zession',
  },
  CertifiedCopyID: {
    id: 'CertifiedCopyID',
    defaultMessage: 'Certified Copy ID',
  },
  CertifiedCopyPassport: {
    id: 'CertifiedCopyPassport',
    defaultMessage: 'Certified Copy Passport',
  },
  CertifiedCopyResidencePermit: {
    id: 'CertifiedCopyResidencePermit',
    defaultMessage: 'Certified Copy Residence Permit',
  },
  ResidencePermitRenewalConfirmation: {
    id: 'ResidencePermitRenewalConfirmation',
    defaultMessage: 'Residence Permit Renewal Confirmation',
  },
  OtherIDDocs: {
    id: 'OtherIDDocs',
    defaultMessage: 'Other ID documents',
  },
  TrialPeriodConfirmation: {
    id: 'TrialPeriodConfirmation',
    defaultMessage: 'Trial period confirmation',
  },
  TestEmailCompanyDomain: {
    id: 'TestEmailCompanyDomain',
    defaultMessage: 'Test Email Company Domain',
  },
  EmploymentConfirmation: {
    id: 'EmploymentConfirmation',
    defaultMessage: 'Employment confirmation',
  },
  OtherEmploymentDocs: {
    id: 'OtherEmploymentDocs',
    defaultMessage: 'Other employment documents',
  },
  BankingStatement1: {
    id: 'BankingStatement1',
    defaultMessage: 'Banking statement 1',
  },
  BankingStatement2: {
    id: 'BankingStatement2',
    defaultMessage: 'Banking statement 2',
  },
  BankingStatement3: {
    id: 'BankingStatement3',
    defaultMessage: 'Banking statement 3',
  },
  BankingStatement4: {
    id: 'BankingStatement4',
    defaultMessage: 'Banking statement 4',
  },
  BankingStatement5: {
    id: 'BankingStatement5',
    defaultMessage: 'Banking statement 5',
  },
  BankingStatement6: {
    id: 'BankingStatement6',
    defaultMessage: 'Banking statement 6',
  },
  FitnessToWorkCertificate: {
    id: 'FitnessToWorkCertificate',
    defaultMessage: 'Fitness to Work Certificate',
  },
  RentalAgreement: {
    id: 'RentalAgreement',
    defaultMessage: 'Rental agreement',
  },
  TaxDeclaration: {
    id: 'TaxDeclaration',
    defaultMessage: 'Tax declaration',
  },
  OtherProofOfFinancialViability: {
    id: 'OtherProofOfFinancialViability',
    defaultMessage: 'Other proof of financial viability',
  },
  ThirdPartyCreditAgreement: {
    id: 'ThirdPartyCreditAgreement',
    defaultMessage: '3rd Party Credit Agreement',
  },
  ThirdPartyLeasingAgreement: {
    id: 'ThirdPartyLeasingAgreement',
    defaultMessage: '3rd Party Leasing Agreement',
  },
  CRIFReport: {
    id: 'CRIFReport',
    defaultMessage: 'CRIF Credit Check Report',
  },
  PartnerID: {
    id: 'PartnerID',
    defaultMessage: 'Partner ID',
  },
  PartnerPassport: {
    id: 'PartnerPassport',
    defaultMessage: 'Partner Passport',
  },
  PartnerResidencePermit: {
    id: 'PartnerResidencePermit',
    defaultMessage: 'Partner Residence Permit',
  },
  ResidencePermitFront: {
    id: 'ResidencePermitFront',
    defaultMessage: 'Residence permit front',
  },
  ResidencePermitBack: {
    id: 'ResidencePermitBack',
    defaultMessage: 'Residence permit back',
  },
  ResidencePermitBothSides: {
    id: 'ResidencePermitBothSides',
    defaultMessage: 'Residence permit (both sides)',
  },
  SalarySlipsCollection: {
    id: 'SalarySlipsCollection',
    defaultMessage: 'Salary slips (collection)',
  },
  AddressChangeForm: {
    id: 'AddressChangeForm',
    defaultMessage: 'Address change form',
  },
});
