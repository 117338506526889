import { Reducer } from 'redux';
import { Actions, ActionTypes } from './actions';
import { DealResultsState, initialState } from './initial-state';

export const dealResultReducer: Reducer<DealResultsState> = (
  state = initialState,
  action: Actions
): DealResultsState => {
  switch (action.type) {
    case ActionTypes.DUPLICATE_SELECTED_DEAL_DONE: {
      return {
        ...state,
        isDuplicateLoading: false,
      };
    }
    case ActionTypes.DUPLICATE_SELECTED_DEAL_REQUEST: {
      return {
        ...state,
        isDuplicateLoading: true,
      };
    }
    case ActionTypes.DEAL_RESULT_REQUEST: {
      return {
        ...state,
        isDealResultLoading: true,
        filters: {
          ...initialState.filters,
          ...action.payload.filters,
        },
      };
    }
    case ActionTypes.DEAL_RESULT_SUCCESS: {
      const { countTotal, countFiltered, results, facets } = action.payload;

      return {
        ...state,
        isDealResultLoading: false,
        countTotal,
        countFiltered,
        results: [...results],
        facets: {
          ...facets,
        },
      };
    }
    case ActionTypes.DEAL_RESULT_FAILURE: {
      return {
        ...state,
        isDealResultLoading: false,
      };
    }
    case ActionTypes.VIEW_VENDOR_INFO_SUCCESS: {
      return {
        ...state,
        selectedVendor: action.payload,
      };
    }
    case ActionTypes.UPDATED_SELECTED_DEALS: {
      return {
        ...state,
        selectedDeals: [...action.payload],
      };
    }
    case ActionTypes.VIEW_VENDOR_INFO_FAILURE: {
      return {
        ...state,
        isVendorNotFound: action.payload.statusCode === 404,
      };
    }
    case ActionTypes.BULK_STATUS_UPDATE_DEALS_REQUEST: {
      return {
        ...state,
        isDealResultLoading: true,
      };
    }
    case ActionTypes.BULK_STATUS_UPDATE_DEALS_SUCCESS: {
      return {
        ...state,
        selectedDeals: [],
        isDealResultLoading: false,
      };
    }
    case ActionTypes.RESET_SELECTED_VENDOR: {
      return {
        ...state,
        selectedVendor: undefined,
      };
    }

    default: {
      return state;
    }
  }
};
