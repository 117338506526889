import { SelectOption } from 'types';

export const hasOwnPropertyCheck = (obj: any, property: string) => Object.prototype.hasOwnProperty.call(obj, property);

export const convertFieldsToCorrespondingTypes = (obj: any, initialObj: any) => {
  const result: any = {};
  for (const field in obj) {
    if (hasOwnPropertyCheck(obj, field)) {
      const element = obj[field];
      if (hasOwnPropertyCheck(initialObj, field)) {
        const typeOfResult = typeof initialObj[field];
        switch (typeOfResult) {
          case 'boolean': {
            result[field] = Boolean(element);
            break;
          }
          case 'number': {
            result[field] = Number(element);
            break;
          }
          case 'string': {
            result[field] = String(element);
            break;
          }
          default: {
            result[field] = element;
            break;
          }
        }
      } else {
        result[field] = element;
      }
    }
  }
  return result;
};

export const sortOptionObjectFn = (a: SelectOption, b: SelectOption) => {
  if (a.label < b.label) return -1;
  if (a.label > b.label) return 1;
  return 0;
};

export const isNullOrUndefined = (value: any) => value === null || value === undefined;
