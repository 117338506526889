import SellerProfileSaga from 'pages/AccountSettings/pages/SellerProfile/store/saga';
import RegisterPageSaga from 'pages/Public/RegisterPage/store/saga';
import ResetPasswordPageSaga from 'pages/Public/ResetPasswordPage/store/saga';
import VendorInformationSaga from 'pages/VendorInformation/store/saga';
import ViewAllDealsPageSaga from 'pages/ViewAllDeals/store/saga';
import ViewAllVendorsPageSaga from 'pages/ViewAllVendors/store/saga';
import { all } from 'redux-saga/effects';
import ChangePasswordSaga from '../AccountSettings/pages/ChangePassword/store/saga';
import AddEditListingsSaga from '../AddEditListings/store/saga';
import OrderSaga from '../EditOrderPage/store/saga';
import ManagePeopleSaga from '../ManagePeople/store/saga';
import OrdersSaga from '../ViewAllOrders/store/saga';
import GlobalSaga from './global/saga';

export default function* () {
  yield all([
    ...GlobalSaga,
    ...RegisterPageSaga,
    ...ResetPasswordPageSaga,
    ...SellerProfileSaga,
    ...ViewAllDealsPageSaga,
    ...ViewAllVendorsPageSaga,
    ...VendorInformationSaga,
    ...ChangePasswordSaga,
    ...AddEditListingsSaga,
    ...ManagePeopleSaga,
    ...OrdersSaga,
    ...OrderSaga,
  ]);
}
