import { PartnerToken, Seller, Vendor } from '../types';
import ApiClient from './apiClient';

export const getSeller = async (id: number): Promise<Seller> => {
  return await ApiClient.get<Seller>(`seller/${id}`);
};

export const getVendorsOfSeller = async (id: number): Promise<Vendor[]> => {
  return await ApiClient.get<Vendor[]>(`seller/${id}/vendors`);
};

export const updateSeller = async (id: number, seller: Partial<Seller>): Promise<any> => {
  return await ApiClient.put(`seller/${id}`, seller, undefined, false);
};

export const sellerSignupRequest = async (sellerId: number, vendorId: number): Promise<any> => {
  return await ApiClient.post('seller-signup-request', {
    sellerId,
    vendorId,
  });
};

export const getPartnerOfferToken = async (offerId: string): Promise<PartnerToken> => {
  return await ApiClient.get(`offer/partner/auth/partner-token/${offerId}`);
};
