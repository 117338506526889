import { GoogleLogin } from '@react-oauth/google';
import useGoogleAuthentication from 'hooks/useGoogleAuthentication';
import { FC } from 'react';

const GoogleLoginButton: FC = () => {
  const { handleSuccess } = useGoogleAuthentication();

  return <GoogleLogin onSuccess={handleSuccess} />;
};

export default GoogleLoginButton;
