import { FunctionComponent } from 'react';
import { compose } from 'utils/compose';
import styles from './PublicHeader.module.scss';

interface Props {
  title: string;
  subTitle?: string;
}

const PublicHeader: FunctionComponent<Props> = ({ title, subTitle }) => {
  return (
    <div className={styles.root}>
      <div className={styles.title}>{title}</div>
      {!!subTitle && <div className={styles.subTitle}>{subTitle}</div>}
    </div>
  );
};

export default compose<Props>(PublicHeader);
