import clsx from 'clsx';
import { DATE_FORMAT } from 'constants/constants';
import moment from 'moment';
import { RootState } from 'pages/_store/root-reducer';
import { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { OrderQueryParams, PublicOffer } from 'types';
import { useStyles } from './EditOrderPage.styles';
import { messages } from './EditOrderPage.translations';
import FormContent from './FormContent/FormContent';
import { Actions as OrderActions } from './store/actions';

interface Props {
  isDocumentsUpload: boolean;
}

const EditOrderPage: FC<Props> = ({ isDocumentsUpload }) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { orderId } = useParams<OrderQueryParams>();

  const order = useSelector<RootState, PublicOffer>((state) => state.orderPage.order, shallowEqual);

  useEffect(() => {
    dispatch(OrderActions.getOrderRequest(orderId));
  }, [orderId, dispatch]);

  return (
    <div className={clsx('EditOrderPage', classes.root)}>
      <div className={classes.headerContainer}>
        <div className={classes.header}>{formatMessage(messages.EditOrderPageEditOrder)}</div>
        {!!order?.createdAt && (
          <div className={classes.subHeader}>
            {formatMessage(messages.EditOrderPageCreated, {
              dateString: moment(order?.createdAt).format(DATE_FORMAT),
            })}
          </div>
        )}
      </div>
      <FormContent isDocumentsUpload={isDocumentsUpload} />
    </div>
  );
};
export default EditOrderPage;
