import { makeToSlug, makeToWritten } from '@carcodex/gow-shared-mono/modules/car/car.constants';
import clsx from 'clsx';
import Accordion from 'components/Accordion';
import CheckboxBase from 'components/Forms/CheckboxBase';
import DatePicker from 'components/Forms/DatePicker';
import MonthYearSelection from 'components/Forms/MonthYearSelection';
import Selection from 'components/Forms/Selection';
import TextField from 'components/Forms/TextField';
import LoadingIndicator from 'components/LoadingIndicator';
import { MAX_KM } from 'constants/constants';
import { useFormikContext } from 'formik';
import useIsLockedToSource from 'pages/AddEditListings/hooks/useIsLockedToSource';
import { AddEditListingSchema } from 'pages/AddEditListings/schema';
import { Actions as AddEditListingsActions } from 'pages/AddEditListings/store/actions';
import { RootState } from 'pages/_store/root-reducer';
import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { slugToModel } from 'utils/car-makes';
import { compose } from 'utils/compose';
import { sortOptionObjectFn } from 'utils/objects';
import { getMakeFromSlug } from '../../utils';
import CarLocationForm from './components/CarLocationForm';
import DeliveredIn from './components/DeliveredIn';
import GuaranteeFormBox from './components/GuaranteeFormBox';
import { getBodyShape, getConditionOptions } from './options';
import styles from './VehicleBaseInformationForm.module.scss';
import Translations from './VehicleBaseInformationForm.translations';

const VehicleBaseInformationForm: FunctionComponent = () => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const dispatch = useDispatch();

  const { isLockedToSource, lockedToSourceMessage } = useIsLockedToSource();

  const { values, setFieldValue } = useFormikContext<AddEditListingSchema>();

  const { isLoadingAction, isLoadingDeal } = useSelector((state: RootState) => state.addEditListingsPage, shallowEqual);

  const { isLoadingCarMakes, carMakes } = useSelector((state: RootState) => state.globalAppState, shallowEqual);

  const makeOptions = Object.keys(carMakes)
    .map((key: string) => ({
      label: makeToWritten[key as keyof typeof makeToWritten],
      value: makeToSlug[key as keyof typeof makeToSlug],
    }))
    .sort(sortOptionObjectFn);

  const makeValue = getMakeFromSlug(values.make);

  const modelOptions =
    !!makeValue && carMakes[makeValue]
      ? (() => {
          return carMakes[makeValue]
            .map((item) => ({
              label: item.model,
              value: item.slug,
            }))
            .sort(sortOptionObjectFn);
        })()
      : [];
  const setUnsavedChanges = () => dispatch(AddEditListingsActions.setUnsavedChanges());

  const onModelSlugSelection = (modelSlug: string) => {
    setFieldValue('model', slugToModel({ modelSlug, carMakes }));
    setUnsavedChanges();
  };

  const isLoading = isLoadingCarMakes || isLoadingAction || isLoadingDeal;

  return (
    <div className={styles.root}>
      <LoadingIndicator isLoading={isLoading}>
        <Accordion
          header={formatMessage(Translations.VehicleBaseInformationFormVehicleInformation)}
          collapsable={false}
        >
          <div className={styles.formColumn}>
            <div className={styles.formRow}>
              <Selection
                name="make"
                label={formatMessage(Translations.VehicleBaseInformationFormMake)}
                options={makeOptions}
                required
                disabled={isLockedToSource}
                tooltip={lockedToSourceMessage}
                onChange={setUnsavedChanges}
              />
            </div>
            <div className={styles.formRow}>
              <Selection
                name="modelSlug"
                label={formatMessage(Translations.VehicleBaseInformationFormModel)}
                options={modelOptions}
                required
                onChange={onModelSlugSelection}
                disabled={isLockedToSource}
                tooltip={lockedToSourceMessage}
              />
            </div>
            <div className={styles.formRow}>
              <TextField
                name="modelYear"
                type="number"
                label={formatMessage(Translations.VehicleBaseInformationFormModelYear)}
                disabled={isLockedToSource}
                tooltip={lockedToSourceMessage}
                onChange={setUnsavedChanges}
              />
            </div>
          </div>
          <div className={styles.formColumn}>
            <div className={styles.formRow}>
              <TextField
                name="version"
                type="text"
                required
                label={formatMessage(Translations.VehicleBaseInformationFormVersion)}
                disabled={isLockedToSource}
                tooltip={lockedToSourceMessage}
                onChange={setUnsavedChanges}
              />
            </div>
            <div className={styles.formRow}>
              <Selection
                name="condition"
                label={formatMessage(Translations.VehicleBaseInformationFormCondition)}
                options={getConditionOptions(intl)}
                required
                onChange={setUnsavedChanges}
                disabled={isLockedToSource}
                tooltip={lockedToSourceMessage}
              />
            </div>
            <div className={styles.formRow}>
              <TextField
                name="lifetimeMileage"
                type="number"
                min={0}
                max={MAX_KM}
                label={formatMessage(Translations.VehicleBaseInformationFormLifetimeMileage)}
                startAdornment={formatMessage(Translations.VehicleBaseInformationFormKm)}
                required
                onChange={setUnsavedChanges}
                disabled={isLockedToSource}
                tooltip={lockedToSourceMessage}
              />
            </div>
          </div>
          <div className={styles.formColumn}>
            <div className={styles.formRow}>
              <MonthYearSelection
                label={formatMessage(Translations.VehicleBaseInformationFormRegistrationDate)}
                monthName="registrationDateMonth"
                yearName="registrationDateYear"
                monthValue={values?.registrationDateMonth}
                yearValue={values?.registrationDateYear}
                onYearChange={setUnsavedChanges}
                onMonthChange={setUnsavedChanges}
                disabled={isLockedToSource}
                tooltip={lockedToSourceMessage}
              />
            </div>
            <div className={styles.formRow}>
              <DatePicker
                onChange={(value) => {
                  setFieldValue('lastTestDate', value);
                  setUnsavedChanges();
                }}
                value={values.lastTestDate}
                label={formatMessage(Translations.VehicleBaseInformationFormLastTestDate)}
                name={'lastTestDate'}
                disabled={isLockedToSource}
                variant="dialog"
                clearable
              />
            </div>
            <div className={styles.formRow}>
              <TextField
                name="modelDesignationNumber"
                type="text"
                label={formatMessage(Translations.VehicleBaseInformationFormModelDesignationNumber)}
                onChange={setUnsavedChanges}
                disabled={isLockedToSource}
                tooltip={lockedToSourceMessage}
              />
            </div>
          </div>
          <div className={styles.formColumn}>
            <div className={styles.formRow}>
              <Selection
                name="bodyShape"
                label={formatMessage(Translations.VehicleBaseInformationFormBodyShape)}
                options={getBodyShape(intl)}
                onChange={setUnsavedChanges}
                disabled={isLockedToSource}
                tooltip={lockedToSourceMessage}
              />
            </div>
            <div className={clsx(styles.formRow, styles.checkboxWrapper)}>
              <CheckboxBase
                name="greyImport"
                key="greyImport"
                label={formatMessage(Translations.VehicleBaseInformationFormGreyImport)}
                checked={values.greyImport}
                onChange={(value) => {
                  setFieldValue('greyImport', value);
                  setUnsavedChanges();
                }}
                disabled={isLockedToSource}
                tooltip={lockedToSourceMessage}
              />
            </div>
            <div className={styles.formRow} />
          </div>
          <div className={styles.divider} />
          <div className={styles.formColumn}>
            <div className={styles.formRow}>
              <TextField
                name="vin"
                type="text"
                label={formatMessage(Translations.VehicleBaseInformationFormVinChassisNumber)}
                onChange={setUnsavedChanges}
                disabled={isLockedToSource}
                tooltip={lockedToSourceMessage}
              />
            </div>
            <div className={styles.formRow}>
              <TextField
                name="internalId"
                type="text"
                label={formatMessage(Translations.VehicleBaseInformationFormInternalID)}
                onChange={setUnsavedChanges}
                disabled={isLockedToSource}
                tooltip={lockedToSourceMessage}
              />
            </div>
            <div className={styles.formRow} />
          </div>
          <div className={styles.divider} />
          <GuaranteeFormBox />
          {!isLockedToSource && (
            <>
              <div className={styles.divider} />
              <CarLocationForm />
            </>
          )}
          <div className={styles.divider} />
          <DeliveredIn />
        </Accordion>
      </LoadingIndicator>
    </div>
  );
};

export default compose(VehicleBaseInformationForm);
