import { StyledComponentProps, Typography, WithStyles, withStyles } from '@material-ui/core';
import Translations from 'App.translations';
import clsx from 'clsx';
import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog';
import ErrorHelperText from 'components/Forms/ErrorHelperText';
import { INPUT_UPLOAD_CLASS_PREFIX } from 'components/Forms/FileUpload/FileUploadDnD/FileUploadInput/FileUploadInput';
import FileUploadDnDPlaceholder from 'components/Forms/FileUpload/FileUploadDnDPlaceholder';
import { Actions as GlobalActions } from 'pages/_store/global/actions';
import { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { compose } from 'utils/compose';
import { styles } from './FileUpload.styles';
import { messages } from './FileUpload.translations';
import { createFile } from './FileUpload.utils';

interface Props {
  name: string;
  label: string;
  src?: string;
  error?: string;
  isLoading?: boolean;
  disabled?: boolean;
  fileType?: string;
  allowedTypes?: string[];
  acceptsTypes?: string[];
  hasControls?: boolean;
  onChange?: (name: string, files: Array<File>) => void;
  onLoad?: (name: string, src: string) => void;
  onDelete?: (name: string) => void;
  uploadFn?: (file: File, fileType: string) => Promise<void>;
}

const RHFFileUpload: FC<Props & WithStyles<typeof styles>> = ({
  classes,
  label,
  name,
  onChange,
  onLoad,
  onDelete,
  src,
  error,
  disabled = false,
  isLoading = false,
  fileType,
  allowedTypes,
  acceptsTypes,
  hasControls = true,
  uploadFn,
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [selectedFiles, setSelectedFiles] = useState<Array<File>>();
  const [hasError, setHasError] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [value, setValue] = useState('');
  const [isConfirmFileDeleteOpen, setIsConfirmFileDeleteOpen] = useState(false);

  const handleOnChange = async (newFiles: Array<File>) => {
    setSelectedFiles([]);
    setHasError(true);
    if (newFiles?.length) {
      setIsUploading(true);
      try {
        await uploadFn(newFiles[0], fileType);
        setSelectedFiles(newFiles);
        setHasError(false);
        setIsUploading(false);
      } catch (e) {
        const element = document.getElementById(INPUT_UPLOAD_CLASS_PREFIX + name) as HTMLInputElement;
        element.value = null;
        setValue('');
        setIsUploading(false);
      }
    }
    if (onChange) {
      onChange(name, newFiles);
    }
  };

  const handleOnLoad = (loadedSrc: string) => {
    if (!hasError) {
      setValue(loadedSrc);
      if (onLoad) {
        onLoad(name, loadedSrc);
      }
    }
  };

  const localDeleteFile = () => {
    setHasError(false);
    // Clear input value so that people can upload same file again
    const element = document.getElementById(INPUT_UPLOAD_CLASS_PREFIX + name) as HTMLInputElement;
    element.value = null;
    setSelectedFiles([]);
    setValue('');
  };

  useEffect(() => {
    setValue(src);
  }, [name, src, setValue]);

  // needed for initial image preview
  useEffect(() => {
    if (src) {
      const getSelectedFiles = async () => {
        const file = await createFile(src);
        setSelectedFiles([file]);
      };

      getSelectedFiles();
    } else {
      if (selectedFiles?.length > 0) {
        localDeleteFile();
      }
    }
  }, [src]);

  const handleDeleteFile = () => {
    localDeleteFile();

    if (onDelete) {
      onDelete(name);
    }
  };

  const setIsConfirmFileDeleteOpenValue = (open: boolean) => () => setIsConfirmFileDeleteOpen(open);

  const handleDownloadFile = async () => {
    try {
      const file = selectedFiles?.[0];
      if (!file) return;

      if (!file.type) {
        document.getElementById(`rhf-file-upload-open-${name}-element`).click();
        return;
      }

      const url = URL.createObjectURL(file);
      const anchor = document.createElement('a');
      anchor.download = file.name;
      anchor.href = url;

      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);

      URL.revokeObjectURL(url);
    } catch (error) {
      dispatch(
        GlobalActions.sendNotification({
          message: formatMessage(Translations.AppErrorDownloadingFile),
          variant: 'error',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'top',
          },
        })
      );
    }
  };
  const showControls = !!value && !hasError && !disabled && hasControls;

  return (
    <>
      <div className={clsx('RHFFileUpload', classes.root)}>
        <Typography className={classes.title}>
          <span>{label}</span>
          {showControls && (
            <>
              {src && (
                <a
                  id={`rhf-file-upload-open-${name}-element`}
                  className={classes.link}
                  href={src}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {formatMessage(messages.RHFFileUploadOpen)}
                </a>
              )}
              <span
                id={`rhf-file-upload-delete-${name}-element`}
                onClick={setIsConfirmFileDeleteOpenValue(true)}
                className={classes.link}
              >
                {formatMessage(messages.RHFFileUploadDelete)}
              </span>
              {src && (
                <span
                  id={`rhf-file-upload-download-${name}-element`}
                  onClick={handleDownloadFile}
                  className={classes.link}
                >
                  {formatMessage(messages.RHFFileUploadDownload)}
                </span>
              )}
            </>
          )}
        </Typography>
        <FileUploadDnDPlaceholder
          name={name}
          disabled={disabled}
          isLoading={isLoading || isUploading}
          classes={{
            fileUploadInputRoot: clsx({ [classes.errorRoot]: !!error }),
          }}
          selectedFiles={selectedFiles}
          allowedTypes={allowedTypes}
          acceptsTypes={acceptsTypes}
          onChange={handleOnChange}
          onLoad={handleOnLoad}
        />
        <ErrorHelperText message={error} />
      </div>
      <ConfirmationDialog
        key="delete-file"
        description={
          <FormattedMessage
            id="FileUploadAreYouSureYouWantToDelete"
            defaultMessage="Are you sure you want to delete?"
          />
        }
        open={isConfirmFileDeleteOpen}
        onYes={handleDeleteFile}
        onClose={setIsConfirmFileDeleteOpenValue(false)}
      />
    </>
  );
};
export default compose<Props & StyledComponentProps>(RHFFileUpload, withStyles(styles, { name: 'RHFFileUpload' }));
