import brokenCar from 'assets/images/brokenCar.svg';
import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'utils/compose';
import NavPanel from '../../layouts/Dashboard/components/NavPanel';
import styles from './ErrorPage.module.scss';
import Translations from './ErrorPage.translations';

interface Props extends Partial<RouteComponentProps> {
  error: Error | null;
  componentStack: string | null;
  resetError: () => void;
}

const ErrorPage: FC<Props> = ({ error, componentStack, resetError, history, match, location }) => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const [currentPath, setCurrentPath] = useState(window.location.pathname);

  useEffect(() => {
    if (location && location.pathname !== currentPath) {
      resetError();
      setCurrentPath(location.pathname);
    }
  }, [currentPath, resetError, location]);

  return (
    <div className={styles.root}>
      <NavPanel />
      <div className={styles.row}>
        <div className={styles.column}>
          <div className={styles.textContainer}>
            <h1>{formatMessage(Translations.ErrorPageTitle)}</h1>
            <p>{formatMessage(Translations.ErrorPageInternalErrorText)}</p>
            <p>{formatMessage(Translations.ErrorPageTryReloadingThePage)}</p>
          </div>
        </div>
        <div className={styles.column}>
          <img src={brokenCar} alt="broken car" />
        </div>
      </div>
    </div>
  );
};

export default compose<Props>(ErrorPage, withRouter);
