import { VALID_TERM_PERIODS } from 'constants/constants';
import { FormattedMessage } from 'react-intl';
import { AIOPackageName, DealPricing, SelectOption } from 'types';
import { messages } from '../pages/EditOrderPage/FormContent/FormContent.translations';

export const renderAioPackageShortlLabel = (aioPackageName: AIOPackageName) => {
  switch (aioPackageName) {
    case AIOPackageName.LIGHT:
      return <FormattedMessage id="AIOPackageNameLight" defaultMessage="Light" />;
    case AIOPackageName.ESSENTIALS:
      return <FormattedMessage id="AIOPackageNameEssentials" defaultMessage="Essentials" />;
    default:
      return <FormattedMessage id="AIOPackageNamePremium" defaultMessage="Premium" />;
  }
};

export const renderAioPackageShortlLabelString = (aioPackageName: string) => {
  switch (aioPackageName) {
    case AIOPackageName.LIGHT:
      return 'Light';
    case AIOPackageName.ESSENTIALS:
      return 'Essentials';
    default:
      return 'Premium';
  }
};

export const getAioPackageNameOptions = (formatMessage: any): Array<SelectOption> => {
  return [
    {
      label: formatMessage(messages.OfferOverviewFormLight),
      value: AIOPackageName.LIGHT,
    },
    {
      label: formatMessage(messages.OfferOverviewFormEssentials),
      value: AIOPackageName.ESSENTIALS,
    },
    {
      label: formatMessage(messages.OfferOverviewFormPremium),
      value: AIOPackageName.PREMIUM,
    },
  ];
};

export const getPrivateCreditDurationOptions = () =>
  VALID_TERM_PERIODS.map((termPeriod) => ({ label: termPeriod.toString(), value: termPeriod.toString() }));

export const getPackagesPricing = (pricing: DealPricing): Record<AIOPackageName, number> =>
  pricing?.packages
    ? {
        all_in_one_light: Math.round(pricing.packages.all_in_one_light + pricing.leasingRate),
        all_in_one_essential: Math.round(pricing.packages.all_in_one_essential + pricing.leasingRate),
        all_in_one_premium: Math.round(pricing.packages.all_in_one_premium + pricing.leasingRate),
      }
    : undefined;
