import clsx from 'clsx';
import Accordion from 'components/Accordion';
import DatePicker from 'components/Forms/DatePicker';
import RHFInput from 'components/Forms/RHFInput/RHFInput';
import LoadingIndicator from 'components/LoadingIndicator';
import { RootState } from 'pages/_store/root-reducer';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { PublicOffer } from 'types';
import OrderPageSelectors from '../../store/selectors';
import { useStyles } from '../FormContent.styles';
import { messages } from '../FormContent.translations';

const OfferCarForm: FC = () => {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  const { isOEM, isFetchingResult, isPrivateCredit, order } = useSelector<
    RootState,
    {
      isOEM: boolean;
      isFetchingResult: boolean;
      order: PublicOffer;
      isPrivateCredit: boolean;
    }
  >(
    (state: RootState) => ({
      isFetchingResult: OrderPageSelectors.extractIsFetchingResult(state),
      order: OrderPageSelectors.extractOrder(state),
      isOEM: OrderPageSelectors.getIsOEM(state),
      isPrivateCredit: OrderPageSelectors.getIsPrivateCredit(state),
    }),
    shallowEqual
  );

  const orderDeactivated = !!order?.datetimeDeactivated;

  const { watch, setValue, errors } = useFormContext<PublicOffer>();

  const { initialCarPrice } = errors || {};
  const { make, model, modelYear, engine, km, licensingDateRaw, price, listPrice } = errors?.esDeal || {};

  const licensingDateWatch: number = watch('esDeal.licensingDateRaw');

  const isTeslaOEM = isOEM && !isPrivateCredit;

  const handleLicensingDateChange = (value: string) => setValue('esDeal.licensingDateRaw', value || null);

  return (
    <div className={clsx('OfferCarForm', classes.root)}>
      <LoadingIndicator isLoading={isFetchingResult}>
        <Accordion header={formatMessage(messages.OfferOverviewFormCarInformation)} collapsable={false}>
          <div className={classes.formRow}>
            <div className={classes.formColumn}>
              <RHFInput
                errorLabel={make}
                name="esDeal.make"
                label={formatMessage(messages.OfferOverviewFormMake)}
                disabled={true || orderDeactivated}
              />
            </div>
            <div className={classes.formColumn}>
              <RHFInput
                errorLabel={model}
                name="esDeal.model"
                label={formatMessage(messages.OfferOverviewFormModel)}
                disabled={true || orderDeactivated}
              />
            </div>
            <div className={classes.formColumn}>
              <RHFInput
                errorLabel={modelYear}
                type="number"
                name="esDeal.modelYear"
                label={formatMessage(messages.OfferOverviewFormModelYear)}
                disabled={orderDeactivated}
              />
            </div>
          </div>
          <div className={classes.formRow}>
            <div className={classes.formColumn}>
              <RHFInput
                errorLabel={engine}
                name="esDeal.engine"
                label={formatMessage(messages.OfferOverviewFormEngine)}
                disabled={orderDeactivated}
              />
            </div>
            <div className={classes.formColumn}>
              <DatePicker
                error={licensingDateRaw}
                onChange={handleLicensingDateChange}
                value={licensingDateWatch}
                label={formatMessage(messages.OfferOverviewFormLicencingDate)}
                name="esDeal.licensingDateRaw"
                disabled={orderDeactivated}
                isInput
              />
            </div>
            <div className={classes.formColumn}>
              <RHFInput
                errorLabel={km}
                startAdornment="km"
                type="number"
                name="esDeal.km"
                label={formatMessage(messages.OfferOverviewFormLifetimeMileage)}
                disabled={orderDeactivated}
              />
            </div>
          </div>
          <div className={classes.formRow}>
            <div className={classes.formColumn}>
              <RHFInput
                startAdornment="CHF"
                type="number"
                errorLabel={listPrice}
                name="esDeal.listPrice"
                integer
                label={formatMessage(messages.OfferOverviewFormListPrice)}
                disabled={orderDeactivated}
              />
            </div>
            {isTeslaOEM && (
              <div className={classes.formColumn}>
                <RHFInput
                  errorLabel={initialCarPrice}
                  startAdornment="CHF"
                  type="number"
                  name="initialCarPrice"
                  integer
                  label={formatMessage(messages.OfferOverviewFormInitialCarPrice)}
                  disabled={orderDeactivated}
                />
              </div>
            )}
            <div className={classes.formColumn}>
              <RHFInput
                errorLabel={price}
                startAdornment="CHF"
                type="number"
                name="esDeal.price"
                integer
                label={formatMessage(messages.OfferOverviewFormCarPrice)}
                disabled={orderDeactivated || isTeslaOEM}
              />
            </div>
            {!isTeslaOEM && <div className={classes.formColumn} />}
          </div>
        </Accordion>
      </LoadingIndicator>
    </div>
  );
};
export default OfferCarForm;
