import { makeToWritten } from '@carcodex/gow-shared-mono/modules/car/car.constants';
import { StyledComponentProps, withStyles, WithStyles } from '@material-ui/core';
import clsx from 'clsx';
import { FULL_YEAR_MONTH_DATE_FORMAT } from 'constants/constants';
import { useParams } from 'hooks/router';
import { useRenderNoInfoIfValueIsNull } from 'hooks/useRenderNoInfoIfValueIsNull';
import moment from 'moment';
import { AddEditListingsState } from 'pages/AddEditListings/store/initial-state';
import { DefaultAddEditListingParams } from 'pages/AddEditListings/utils';
import { RootState } from 'pages/_store/root-reducer';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { compose } from 'utils/compose';
import Translations from '../../AddEditListings.translations';
import { styles } from './Header.styles';
import { messages } from './Header.translations';
import { HeaderContext } from './Header.utils';

interface Props {
  context: HeaderContext;
  withSubHeader?: boolean;
}

const Header: FC<Props & WithStyles<typeof styles>> = ({ classes, context, withSubHeader = true }) => {
  const { formatMessage } = useIntl();

  const isViewContext = context === HeaderContext.EDIT;

  const { dealId: dealIdParam } = useParams<DefaultAddEditListingParams>();

  const {
    addEditListingsPage: { deal },
  } = useSelector<RootState, { addEditListingsPage: AddEditListingsState }>(
    (state) => ({
      addEditListingsPage: state.addEditListingsPage,
    }),
    shallowEqual
  );

  const model = useRenderNoInfoIfValueIsNull(deal?.listing?.model);

  let title = dealIdParam
    ? formatMessage(Translations.AddEditListingsEditListing)
    : formatMessage(Translations.AddEditListingsAddANewListing);

  const gowagoId = deal?.publicId || '-';

  const createdAt = deal?.listing?.createdAt ? moment(deal.listing.createdAt) : undefined;
  let createdAtLabel = createdAt ? moment(deal?.listing?.createdAt).format(FULL_YEAR_MONTH_DATE_FORMAT) : '-';

  if (isViewContext) {
    title = `${makeToWritten[deal?.listing?.make] || formatMessage(messages.ViewListingNoInformation)} ${model}`;

    createdAtLabel = !createdAt ? formatMessage(messages.ViewListingNoInformation) : createdAtLabel;
  }

  return (
    <div className={clsx('Header', classes.root)}>
      <div className={classes.header}>{title}</div>
      {withSubHeader && (
        <div className={classes.subHeader}>
          {formatMessage(Translations.AddEditListingsGowagoID)}: {gowagoId}
          {createdAt && <div>{`${formatMessage(Translations.AddEditListingsCreated)}: ${createdAtLabel}`}</div>}
        </div>
      )}
    </div>
  );
};

export default compose<Props & StyledComponentProps>(Header, withStyles(styles, { name: 'Header' }));
