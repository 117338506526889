import { defineMessages } from 'react-intl';

export default defineMessages({
  CarImageFormCarPhotos: {
    id: 'CarImageFormCarPhotos',
    defaultMessage: 'Car photos',
  },
  CarImageFormAddUpTo16PhotosOfTheCar: {
    id: 'CarImageFormAddUpTo16PhotosOfTheCar',
    defaultMessage: 'Add up to <bold>16 photos</bold> of the car.',
  },
  CarImageFormImagesMustBeInJpgPngOrGifFormatsAMinimumOf: {
    id: 'CarImageFormImagesMustBeInJpgPngOrGifFormatsAMinimumOf',
    defaultMessage:
      'Images must be in JPG, PNG, or GIF formats, a minimum of 800x600 pixels in resolution, and no larger than 5mb each.',
  },
  CarImageFormDragAndDropPhotosHereOrBrowseFiles: {
    id: 'CarImageFormDragAndDropPhotosHereOrBrowseFiles',
    defaultMessage: 'Drag-and-drop photos here, or <browselink>browse files</browselink>',
  },
});
