import HorizintalStepper from 'components/HorizontalStepper/HorizintalStepper';
import { StepState } from 'components/HorizontalStepper/types';
import { Formik } from 'formik';
import AddVehicleInformation from 'pages/AddEditListings/components/AddVehicleInformation';
import ChooseModelAndVersion from 'pages/AddEditListings/components/ChooseModelAndVersion';
import Header from 'pages/AddEditListings/components/Header';
import { HeaderContext } from 'pages/AddEditListings/components/Header/Header.utils';
import { useJumpToTopOnError } from 'pages/AddEditListings/hooks/useJumpToTopOnError';
import { usePrevious } from 'pages/AddEditListings/hooks/usePrevious';
import { AddEditListingsState } from 'pages/AddEditListings/store/initial-state';
import ViewAllDealsSelectors from 'pages/ViewAllDeals/store/selectors';
import { Actions as GlobalActions } from 'pages/_store/global/actions';
import { RootState } from 'pages/_store/root-reducer';
import { FC, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Prompt, useParams } from 'react-router-dom';
import { Vendor } from 'types';
import { compose } from 'utils/compose';
import styles from '../../AddEditListings.module.scss';
import Translations from '../../AddEditListings.translations';
import FormContent from '../../components/FormContent';
import StepChangeModal from '../../components/StepChangeModal';
import { AddEditListingSchema, AddEditListingsValidationSchema, getInitialValues } from '../../schema';
import { Actions as AddEditListingsActions } from '../../store/actions';
import { convertDealToSchema, convertSchemaToDealRequest, DefaultAddEditListingParams } from '../../utils';

const AddListingsPage: FC = () => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const [currentStep, setCurrentStep] = useState(0);
  const [stepStates, setStepStates] = useState<Array<StepState> | undefined>();
  const [desiredStep, setDesiredStep] = useState(-1);
  const dispatch = useDispatch();
  const { vendorId, dealId: dealIdParam } = useParams<DefaultAddEditListingParams>();

  const {
    addEditListingsPage: { deal, unsavedChanges },
    vendor,
  } = useSelector<RootState, { addEditListingsPage: AddEditListingsState; vendor: Vendor }>(
    (state) => ({
      addEditListingsPage: state.addEditListingsPage,
      vendor: ViewAllDealsSelectors.getSelectedVendor(state),
    }),
    shallowEqual
  );

  const previousStep = usePrevious(currentStep);

  const formikValues = useMemo(() => {
    const initialValues = getInitialValues(vendor);
    let result: AddEditListingSchema = { ...initialValues };
    const hasDeal = !!deal;
    const isCreated = !!deal?.id;

    if (hasDeal) {
      result = convertDealToSchema(deal, [], vendor);

      if (!isCreated) {
        result = {
          ...result,
          leasingProvider: initialValues.leasingProvider,
          interestRate: initialValues.interestRate,
          dealerDeliveryFee: initialValues.dealerDeliveryFee,
          carLocation: initialValues.carLocation,
          isVendorCarLocation: initialValues.isVendorCarLocation,
        };
      }
    }
    return result;
  }, [deal, vendor]);

  const getDealId = () => deal?.id;

  useJumpToTopOnError();

  useEffect(() => {
    if (dealIdParam) {
      dispatch(AddEditListingsActions.getDealRequest(Number(dealIdParam)));
    } else {
      dispatch(AddEditListingsActions.getDealSuccess());
    }
  }, [dispatch, dealIdParam]);

  useEffect(() => {
    dispatch(AddEditListingsActions.getDealSuccess());
    dispatch(AddEditListingsActions.setDealImages([]));
    return () => {
      dispatch(AddEditListingsActions.resetState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!vendorId || isNaN(vendorId as any)) return;
    const id = Number(vendorId);
    dispatch(
      AddEditListingsActions.getDealOwnershipRequest({
        vendorId: id,
        ...(deal ? deal.listing : undefined),
      })
    );
  }, [dispatch, vendorId, deal]);

  const dialogMessage = formatMessage(Translations.AddEditListingsYouHaveUnsavedChanged);
  const okLabel = formatMessage(Translations.AddEditListingsContinueEditing);
  const closeLabel = formatMessage(Translations.AddEditListingsDiscardChanges);

  useEffect(() => {
    dispatch(
      GlobalActions.setConfirmationModalProps({
        message: dialogMessage,
        okLabel: okLabel,
        closeLabel: closeLabel,
      })
    );
  }, [dispatch, dialogMessage, okLabel, closeLabel]);

  const onSubmit = (values: AddEditListingSchema) => {
    dispatch(
      AddEditListingsActions.publishRequest(convertSchemaToDealRequest(values, vendor?.id), values.images, getDealId())
    );
  };

  const handleStepChange = (step: number) => {
    // we are going back from third step show modal
    const isLastStep = currentStep === 2;
    const hasPreviousStep = !!previousStep;
    const isCurrentStepBiggerThanPrevious = currentStep > previousStep;

    const isModalOpen = isLastStep && hasPreviousStep && isCurrentStepBiggerThanPrevious;

    if (isModalOpen) {
      dispatch(AddEditListingsActions.setIsStepChangeModalOpen(true));
      setDesiredStep(step);
      return;
    }

    setStepStates(undefined);
    setDesiredStep(-1);
    setCurrentStep(step);

    if (!step) {
      dispatch(AddEditListingsActions.resetCars());
    }
  };

  // clear filters and deal when adding it manually
  // set step icons
  const handleAddInfoManually = () => {
    dispatch(AddEditListingsActions.getDealSuccess());
    dispatch(AddEditListingsActions.updateDealForManualAdding());
    setStepStates([StepState.ERROR, StepState.DISABLED, StepState.ACTIVE]);
    setCurrentStep(2);
  };

  const stepContent = [
    <AddVehicleInformation
      key="AddVehicleInformation"
      onAddInfoManually={handleAddInfoManually}
      onMoveToStepTwo={() => handleStepChange(1)}
    />,
    <ChooseModelAndVersion
      key="ChooseModelAndVersion"
      onAddInfoManually={handleAddInfoManually}
      onMoveToStepThree={() => handleStepChange(2)}
    />,
    <Formik
      key="Formik"
      initialValues={formikValues}
      validationSchema={AddEditListingsValidationSchema(intl)}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {() => (
        <>
          <FormContent isCreate />
          {desiredStep >= 0 && (
            <StepChangeModal
              title={
                desiredStep === 1
                  ? formatMessage(Translations.AddEditListingsChangeModelAndVersion)
                  : formatMessage(Translations.AddEditListingsChangeVehicleInformation)
              }
              okLabel={
                desiredStep === 1
                  ? formatMessage(Translations.AddEditListingsYesChangeModelAndVersion)
                  : formatMessage(Translations.AddEditListingsYesChangeVehicleInformation)
              }
              onOk={handleStepChange}
              stepNumber={desiredStep}
            />
          )}
        </>
      )}
    </Formik>,
  ];

  const stepTitles = [
    Translations.AddEditListingsVehicleInformation,
    Translations.AddEditListingsModelAndVersion,
    Translations.AddEditListingsListingDetails,
  ];

  const withSubHeader = currentStep > 1;

  return (
    <>
      <div className={styles.root}>
        <Header context={HeaderContext.ADD} withSubHeader={withSubHeader} />
        <HorizintalStepper
          activeStep={currentStep}
          onStepChange={handleStepChange}
          stepTitles={stepTitles}
          stepContent={stepContent}
          stepStates={stepStates}
        />
      </div>
      <Prompt when={unsavedChanges} message="" />
    </>
  );
};

export default compose(AddListingsPage);
