import { IconProp } from '@fortawesome/fontawesome-svg-core';
import classNames from 'classnames';
import { useField, useFormikContext } from 'formik';
import React, { FC, useState } from 'react';
import { compose } from 'utils/compose';
import Input from '../Input';
import styles from './TextField.module.scss';

interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label?: string;
  name: string;
  errorLabel?: string;
  startAdornment?: IconProp | string;
  footerLabel?: string;
  required?: boolean;
  labelClassName?: string;
  tooltip?: string;
  disableErrorMessages?: boolean;
  errorLabelClass?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  customErrorLabel?: (value: any, error: boolean, dirty: boolean) => React.ReactElement;
}

const TextField: FC<Props> = ({
  name,
  label,
  errorLabel,
  startAdornment,
  footerLabel,
  labelClassName,
  required = false,
  onChange,
  customErrorLabel,
  tooltip,
  disableErrorMessages = false,
  errorLabelClass,
  ...props
}) => {
  const [field, meta] = useField(name);
  const [isDirty, setDirty] = useState(false);
  const { submitCount, setFieldValue } = useFormikContext();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (props.max && !isNaN(value as any) && Number(value) > props.max) {
      setFieldValue(name, props.max);
    } else {
      field.onChange(e);
    }

    if (onChange) {
      onChange(e);
    }
    if (!isDirty) {
      setDirty(true);
    }
  };

  const touched = meta.touched || submitCount > 0;

  return (
    <div className={styles.root}>
      {!!label && (
        <label className={classNames(styles.label, labelClassName, { required })}>
          {label}
          {required ? ` *` : ''}
        </label>
      )}
      <Input
        {...props}
        {...field}
        startAdornment={startAdornment}
        error={!!(touched && meta.error)}
        touched={touched}
        onChange={handleOnChange}
        tooltip={tooltip}
      />
      {!disableErrorMessages && !!(touched && meta.error && !customErrorLabel) && (
        <div className={classNames(styles.errorLabel, errorLabelClass)}>{meta.error}</div>
      )}
      {!!customErrorLabel && customErrorLabel(field.value, !!meta.error, isDirty)}
      {!!footerLabel && <div className={classNames(styles.footerLabel, errorLabelClass)}>{footerLabel}</div>}
    </div>
  );
};

export default compose<Props>(TextField);
