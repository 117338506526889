import { Reducer } from 'redux';
import { Actions, ActionTypes } from './actions';
import { initialState, SellerProfileState } from './initial-state';

export const sellerProfileReducer: Reducer<SellerProfileState> = (
  state = initialState,
  action: Actions
): SellerProfileState => {
  switch (action.type) {
    case ActionTypes.GET_SELLER_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }

    case ActionTypes.GET_SELLER_SUCCESS: {
      return {
        ...state,
        seller: action.payload.seller,
        vendors: action.payload.vendors,
        isLoading: false,
        isError: false,
      };
    }
    case ActionTypes.GET_SELLER_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    }
    case ActionTypes.UPDATE_SELLER_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
        successfullyUpdated: false,
      };
    }

    case ActionTypes.UPDATE_SELLER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isError: false,
        successfullyUpdated: true,
      };
    }
    case ActionTypes.UPDATE_SELLER_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isError: action.payload.isError,
        successfullyUpdated: false,
      };
    }
    case ActionTypes.HIDE_SUCCESS_ALERT_MESSAGE: {
      return {
        ...state,
        successfullyUpdated: false,
      };
    }

    case ActionTypes.RESET_STATE: {
      return {
        ...initialState,
      };
    }

    default: {
      return state;
    }
  }
};
