const formatNestedObject = (obj: Record<string, any>) => {
  return Object.entries(obj)
    .map(([nestedKey, nestedValue]) => {
      const isObject = typeof nestedValue === 'object';
      const value = isObject ? `{ ${formatNestedObject(nestedValue)} }` : nestedValue;

      return `${nestedKey}: ${value}`;
    })
    .join(', ');
};

export const renderErrorMessage = (key: string, value: any) => {
  const isObject = typeof value === 'object';
  const formattedValue = isObject ? formatNestedObject(value) : value;

  return <li key={key}>{`${key}: ${formattedValue}`}</li>;
};
