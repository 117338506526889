import classNames from 'classnames';
import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { compose } from 'utils/compose';
import styles from './Navigation.module.scss';
import Translations from './Navigation.translations';

interface Props {
  isContactInformationSelected: boolean;
  setIsContactInformationSelected: (isSelected: boolean) => void;
}

const Navigation: FunctionComponent<Props> = ({ isContactInformationSelected, setIsContactInformationSelected }) => {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.root}>
      <div className={styles.rootCard}>
        <div
          className={classNames(styles.navItem, { [styles.navItemSelected]: isContactInformationSelected })}
          onClick={() => setIsContactInformationSelected(true)}
        >
          {formatMessage(Translations.VendorInformationNavigationContactInformation)}
        </div>
        {/* TODO: THIS SHOULD BE PART OF THE GOW-2024 - add as separate route */}
        {/* <div
                    className={classNames(styles.navItem, {[styles.navItemSelected]: !isContactInformationSelected})}
                    onClick={() => setIsContactInformationSelected(false)}
                >
                    {formatMessage(Translations.VendorInformationNavigationOpeningTimes)}
                </div> */}
      </div>
    </div>
  );
};

export default compose(Navigation);
