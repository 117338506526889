import { StyledComponentProps, withStyles, WithStyles } from '@material-ui/core';
import clsx from 'clsx';
import Card from 'components/Card';
import RHFCheckbox from 'components/Forms/RHFCheckbox';
import { RootState } from 'pages/_store/root-reducer';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { compose } from 'utils/compose';
import { styles } from './SuperAdminActionsForm.styles';
import { messages } from './SuperAdminActionsForm.translations';

const SuperAdminActionsForm: FC<WithStyles<typeof styles>> = ({ classes }) => {
  const { formatMessage } = useIntl();
  const { orderDeactivated } = useSelector<RootState, { orderDeactivated: boolean }>(
    (state) => ({ orderDeactivated: !!state.orderPage.order?.datetimeDeactivated }),
    shallowEqual
  );

  return (
    <Card className={clsx('SuperAdminActionsForm', classes.root)}>
      <RHFCheckbox
        name="skipContractSignedCheck"
        label={formatMessage(messages.SuperAdminActionsFormOverrideContractCheck)}
        disabled={orderDeactivated}
      />
    </Card>
  );
};

export default compose<StyledComponentProps>(
  SuperAdminActionsForm,
  withStyles(styles, { name: 'SuperAdminActionsForm' })
);
