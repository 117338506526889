import { Fragment, FunctionComponent } from 'react';
import { compose } from 'utils/compose';

interface Props {
  value: number;
  withCurrency?: boolean;
}

const Index: FunctionComponent<Props> = ({ value, withCurrency }) => {
  return <Fragment>{`${withCurrency ? `CHF ` : ''}${value.toLocaleString('de-CH')}`}</Fragment>;
};

export default compose<Props>(Index);
