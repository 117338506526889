import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Callout from 'components/Callout';
import Checkbox from 'components/Forms/Checkbox';
import TextField from 'components/Forms/TextField';
import { routeConstants } from 'constants/routes';
import { useFormikContext } from 'formik';
import { useParams } from 'hooks/router';
import { AddEditListingSchema } from 'pages/AddEditListings/schema';
import { Actions as AddEditListingsActions } from 'pages/AddEditListings/store/actions';
import ViewAllDealsSelectors from 'pages/ViewAllDeals/store/selectors';
import { RootState } from 'pages/_store/root-reducer';
import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { DefaultVendorParams, Vendor } from 'types';
import styles from './CarLocationForm.module.scss';
import Translations from './CarLocationForm.translations';

const CarLocationForm: FC = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const { vendorId } = useParams<DefaultVendorParams>();
  const { values, setFieldValue } = useFormikContext<AddEditListingSchema>();
  const vendor = useSelector<RootState, Vendor>(
    (state) => ViewAllDealsSelectors.getSelectedVendor(state),
    shallowEqual
  );

  const [customValues, setCustomValues] = useState({
    street: '',
    city: '',
    zip: '',
  });
  const [isVendorCarLocationChanged, setIsVendorCarLocationChanged] = useState(false);

  useEffect(() => {
    if (!isVendorCarLocationChanged) return;

    const isVendorCarLocation = values.isVendorCarLocation;

    let zip = isVendorCarLocation ? vendor?.zip : customValues.zip;
    zip = zip ? Number(zip) : zip;

    setFieldValue('carLocation', {
      street: isVendorCarLocation ? vendor?.street : customValues.street,
      city: isVendorCarLocation ? vendor?.city : customValues.city,
      zip,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.isVendorCarLocation]);

  const setUnsavedChanges = () => dispatch(AddEditListingsActions.setUnsavedChanges());

  const handleOnIsVendorCarLocationChange = (isVendorCarLocation: boolean) => {
    setIsVendorCarLocationChanged(true);
    setUnsavedChanges();

    if (isVendorCarLocation) {
      setCustomValues({
        street: values?.carLocation?.street || '',
        city: values?.carLocation?.city || '',
        zip: values?.carLocation?.zip?.toString() || '',
      });
    }
  };

  return (
    <div>
      <div className={styles.title}>{formatMessage(Translations.CarLocationFormCarLocation)}</div>
      <div className={styles.defaultVendorLocation}>
        <Checkbox
          label={formatMessage(Translations.CarLocationFormUseMyVendorLocation)}
          name="isVendorCarLocation"
          onChange={handleOnIsVendorCarLocationChange}
        />
        <span data-tip className={styles.infoIconContainer} data-for="info-label-car-location" data-event="click focus">
          <FontAwesomeIcon icon={['fas', 'info-circle']} className={styles.infoIcon} />
        </span>
        <Callout tooltipId="info-label-car-location" header={formatMessage(Translations.CarLocationFormVendorLocation)}>
          {formatMessage(Translations.CarLocationFormViewOrChangeYourLocationInOurVendorInformation, {
            vendorInformation: (
              <Link
                className={styles.vendorInformationLink}
                to={routeConstants.PROTECTED.VENDOR_SETTINGS_VENDOR_INFORMATION_PAGE.url(
                  vendorId ? Number(vendorId) : 0
                )}
                key="vendor-information-link"
              >
                {formatMessage(Translations.CarLocationFormVendorInformation)}
              </Link>
            ),
          })}
        </Callout>
      </div>
      <div className={styles.formColumn}>
        <div className={styles.formRow}>
          <TextField
            name="carLocation.street"
            type="text"
            label={formatMessage(Translations.CarLocationFormStreetAndNumber)}
            onChange={setUnsavedChanges}
            disabled={values.isVendorCarLocation}
            required
          />
        </div>
        <div className={styles.formRow}>
          <TextField
            name="carLocation.city"
            type="text"
            label={formatMessage(Translations.CarLocationFormCity)}
            onChange={setUnsavedChanges}
            disabled={values.isVendorCarLocation}
            required
          />
        </div>
        <div className={styles.formRow}>
          <TextField
            name="carLocation.zip"
            type="number"
            label={formatMessage(Translations.CarLocationFormZipCode)}
            onChange={setUnsavedChanges}
            disabled={values.isVendorCarLocation}
            required
          />
        </div>
      </div>
    </div>
  );
};

export default CarLocationForm;
