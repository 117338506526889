import { FuelTypesDetailed } from '@carcodex/gow-shared-mono/modules/car/car.types';
import { IntlShape } from 'react-intl';
import { DrivenWheels, TransmissionDetailed } from 'types';
import TechnicalInformationFormTranslations from '../../pages/AddEditListings/components/TechnicalInformationForm/TechnicalInformationForm.translations';

export const getAndTranslateGearboxValues = ({ formatMessage }: IntlShape, gearBoxValue: TransmissionDetailed) => {
  switch (gearBoxValue) {
    case TransmissionDetailed.AUTOMATIC:
      return formatMessage(TechnicalInformationFormTranslations.TechnicalInformationFormAutomatic);
    case TransmissionDetailed.AUTOMATIC_MANUAL:
      return formatMessage(TechnicalInformationFormTranslations.TechnicalInformationFormAutomaticManual);
    case TransmissionDetailed.AUTOMATIC_SEQUENTIAL:
      return formatMessage(TechnicalInformationFormTranslations.TechnicalInformationFormAutomaticSequential);
    case TransmissionDetailed.AUTOMATIC_STEPLESS:
      return formatMessage(TechnicalInformationFormTranslations.TechnicalInformationFormAutomaticStepless);
    case TransmissionDetailed.AUTOMATIC_STEPLESS_SEQUENTIAL:
      return formatMessage(TechnicalInformationFormTranslations.TechnicalInformationFormAutomaticSteplessSequential);
    case TransmissionDetailed.MANUAL:
      return formatMessage(TechnicalInformationFormTranslations.TechnicalInformationFormManual);
    case TransmissionDetailed.MANUAL_SEQUENTIAL:
      return formatMessage(TechnicalInformationFormTranslations.TechnicalInformationFormManualSequential);
    default:
      return null;
  }
};

export const getAndTranslatefuelTypeDetailedValues = (
  { formatMessage }: IntlShape,
  fuelTypeDetailedValue: FuelTypesDetailed
) => {
  switch (fuelTypeDetailedValue) {
    case FuelTypesDetailed.PETROL:
      return formatMessage(TechnicalInformationFormTranslations.TechnicalInformationFormPetrol);
    case FuelTypesDetailed.HYBRID:
      return formatMessage(TechnicalInformationFormTranslations.TechnicalInformationFormHybrid);
    case FuelTypesDetailed.DIESEL:
      return formatMessage(TechnicalInformationFormTranslations.TechnicalInformationFormDiesel);
    case FuelTypesDetailed.ELECTRIC:
      return formatMessage(TechnicalInformationFormTranslations.TechnicalInformationFormElectric);
    case FuelTypesDetailed.CNG:
      return formatMessage(TechnicalInformationFormTranslations.TechnicalInformationFormCNG);
    case FuelTypesDetailed.E85:
      return formatMessage(TechnicalInformationFormTranslations.TechnicalInformationFormE85);
    case FuelTypesDetailed.LPG:
      return formatMessage(TechnicalInformationFormTranslations.TechnicalInformationFormLPG);
    case FuelTypesDetailed.H2:
      return formatMessage(TechnicalInformationFormTranslations.TechnicalInformationFormH2);
    case FuelTypesDetailed.CNG_PETROL:
      return formatMessage(TechnicalInformationFormTranslations.TechnicalInformationFormCNGPetrol);
    case FuelTypesDetailed.HYBRID_DIESEL:
      return formatMessage(TechnicalInformationFormTranslations.TechnicalInformationFormHybridDiesel);
    case FuelTypesDetailed.HYBRID_PETROL:
      return formatMessage(TechnicalInformationFormTranslations.TechnicalInformationFormHybridPetrol);
    case FuelTypesDetailed.FULL_HYBRID_DIESEL:
      return formatMessage(TechnicalInformationFormTranslations.TechnicalInformationFormFullHybridDiesel);
    case FuelTypesDetailed.FULL_HYBRID_PETROL:
      return formatMessage(TechnicalInformationFormTranslations.TechnicalInformationFormFullHybridPetrol);
    case FuelTypesDetailed.PLUG_IN_HYBRID_DIESEL:
      return formatMessage(TechnicalInformationFormTranslations.TechnicalInformationFormPlugInHybridDiesel);
    case FuelTypesDetailed.PLUG_IN_HYBRID_PETROL:
      return formatMessage(TechnicalInformationFormTranslations.TechnicalInformationFormPlugInHybridPetrol);
    case FuelTypesDetailed.MILD_HYBRID_DIESEL:
      return formatMessage(TechnicalInformationFormTranslations.TechnicalInformationFormMildHybridDiesel);
    case FuelTypesDetailed.MILD_HYBRID_PETROL:
      return formatMessage(TechnicalInformationFormTranslations.TechnicalInformationFormMildHybridPetrol);
    default:
      return null;
  }
};

export const getAndTranslatedrivenWheelsValues = ({ formatMessage }: IntlShape, drivenWheelsValue: DrivenWheels) => {
  switch (drivenWheelsValue) {
    case DrivenWheels.REAR:
      return formatMessage(TechnicalInformationFormTranslations.TechnicalInformationFormRear);
    case DrivenWheels.FRONT:
      return formatMessage(TechnicalInformationFormTranslations.TechnicalInformationFormFront);
    case DrivenWheels['4X4']:
      return '4x4';
    default:
      return null;
  }
};
