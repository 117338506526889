import { defineMessages } from 'react-intl';

export default defineMessages({
  ResidualValueRowFormAddAnnualMileage: {
    id: 'ResidualValueRowFormAddAnnualMileage',
    defaultMessage: 'Add annual mileage',
  },
  ResidualValueRowFormMonths: {
    id: 'ResidualValueRowFormMonths',
    defaultMessage: 'months',
  },
});
