import classNames from 'classnames';
import { FunctionComponent } from 'react';
import { compose } from 'utils/compose';
import styles from './RadioButtonBase.module.scss';
interface Props {
  selected: boolean;
  label?: string;
  value: string;
  onClick?: (selectedValue: string) => void;
}

const RadioButtonBase: FunctionComponent<Props> = ({ onClick, selected, label, value }) => {
  const handleOnClick = () => {
    if (onClick) {
      onClick(value);
    }
  };
  return (
    <div className={styles.root} onClick={handleOnClick}>
      <div className={classNames(styles.circleOuter, { [styles.selected]: selected })}>
        {selected && <div className={styles.circleInner} />}
      </div>
      {!!label && <div className={styles.label}>{label}</div>}
    </div>
  );
};

export default compose<Props>(RadioButtonBase);
