import classNames from 'classnames';
import { FunctionComponent } from 'react';
import { ErrorValidationListFields } from 'types';
import { compose } from 'utils/compose';
import styles from './ErrorValidationList.module.scss';
interface Props {
  errorMessages: ErrorValidationListFields;
  errors: string[];
  classes?: {
    ul: string;
    li: string;
  };
}

const ErrorValidationList: FunctionComponent<Props> = ({ errorMessages, errors, classes }) => {
  return (
    <ul className={classNames(styles.dash, classes?.ul)}>
      {errors.map((key) => (
        <li key={`${key}${errorMessages[key]}`} className={classes?.li}>
          {errorMessages[key]}
        </li>
      ))}
    </ul>
  );
};

export default compose(ErrorValidationList);
