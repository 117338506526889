import { defineMessages } from 'react-intl';

export default defineMessages({
  VendorInformationContactInformationContactInformation: {
    id: 'VendorInformationContactInformationContactInformation',
    defaultMessage: 'Contact information',
  },
  VendorInformationContactInformationName: {
    id: 'VendorInformationContactInformationName',
    defaultMessage: 'Name',
  },
  VendorInformationContactInformationPhoneNumber: {
    id: 'VendorInformationContactInformationPhoneNumber',
    defaultMessage: 'Phone number',
  },
  VendorInformationContactInformationLeadEmail: {
    id: 'VendorInformationContactInformationLeadEmail',
    defaultMessage: 'Lead email',
  },
  VendorInformationContactInformationGowagoLeaseApplicationsWillBeSent: {
    id: 'VendorInformationContactInformationGowagoLeaseApplicationsWillBeSent',
    defaultMessage: 'gowago.ch lease applications will be sent to this email.',
  },
  VendorInformationContactInformationContactOurSupportTeam: {
    id: 'VendorInformationContactInformationContactOurSupportTeam',
    defaultMessage: 'Contact our support team to change your lead email.',
  },
  VendorInformationContactInformationStreetAndNumber: {
    id: 'VendorInformationContactInformationStreetAndNumber',
    defaultMessage: 'Street and number',
  },
  VendorInformationContactInformationCity: {
    id: 'VendorInformationContactInformationCity',
    defaultMessage: 'City',
  },
  VendorInformationContactInformationZipCode: {
    id: 'VendorInformationContactInformationZipCode',
    defaultMessage: 'Zip code',
  },
});
