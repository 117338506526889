import { reactivate } from 'api/offers';
import { Actions as GlobalActions } from 'pages/_store/global/actions';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { messages } from '../EditOrderPage.translations';

export const useReactivateOrder = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const call = async (publicId: string) => {
    try {
      await reactivate(publicId);
      dispatch(
        GlobalActions.sendNotification({
          message: formatMessage(messages.EditOrderPageOrderReactivated),
          variant: 'success',
        })
      );
    } catch (e) {
      dispatch(
        GlobalActions.sendNotification({
          message: formatMessage(messages.EditOrderPageErrorReactivatingTheOrder),
          variant: 'error',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'top',
          },
        })
      );
    }
  };

  return call;
};
