import classNames from 'classnames';
import React, { FunctionComponent, ReactNode, useRef } from 'react';
import { compose } from 'utils/compose';
import styles from './Switch.module.scss';

export interface SwitchBaseProps
  extends Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'onChange'> {
  label?: ReactNode;
  facets?: number;
  onChange?: (value: boolean) => void;
}

const SwitchBase: FunctionComponent<SwitchBaseProps> = ({ label, facets, onChange, ...other }) => {
  const checkboxRef = useRef<HTMLInputElement>(null);

  const handleOnChangeEvent = () => {
    if (other.disabled) return;
    if (onChange) {
      onChange(!other.checked);
    }
    if (checkboxRef.current) {
      checkboxRef.current.dispatchEvent(new Event('change'));
    }
  };

  // Empty function to prevent controlled/uncontrolled warning
  const handleInputChange = () => {};

  const cx = classNames.bind(styles);
  return (
    <div className={styles.root} onClick={handleOnChangeEvent}>
      <div className={styles.switchContainer}>
        <label className="label-switch switch-primary">
          <input
            type="checkbox"
            className={cx('switch switch-bootstrap status')}
            onChange={handleInputChange}
            {...other}
          />
          <span className="label" />
        </label>
        {!!label && <div className={styles.primaryLabel}>{label}</div>}
      </div>
    </div>
  );
};

export default compose<SwitchBaseProps>(SwitchBase);
