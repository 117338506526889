import Card from 'components/Card';
import CheckboxBase from 'components/Forms/CheckboxBase';
import Input from 'components/Forms/Input';
import { DEFAULT_DEBOUNCE_TIMEOUT } from 'constants/constants';
import debounce from 'debounce';
import { useUpdateQuery } from 'hooks/router';
import React, { FunctionComponent, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { VendorQuery, VendorStatus } from 'types';
import { compose } from 'utils/compose';
import { RootState } from '../../../_store/root-reducer';
import { initialState } from '../../store/initial-state';
import styles from './FilterPanel.module.scss';
import Translations from './ViewAllVendorsFilterPanel.translations';

interface Props {
  searchTerm: string;
  onSearchTermChange: (term: string) => void;
}

const FilterPanel: FunctionComponent<Props> = ({ searchTerm, onSearchTermChange }) => {
  const { formatMessage } = useIntl();
  const updateQuery = useUpdateQuery<VendorQuery>({
    replace: true,
    initialState,
  });
  const facets = useSelector((state: RootState) => state.viewAllVendorsPage.result.facets, shallowEqual);
  const queryVendorStatus = useSelector(
    (state: RootState) => state.viewAllVendorsPage.query.vendorStatus || [],
    shallowEqual
  );
  const facetActive = facets.status.find((item) => item.key === 'active');
  const facetInactive = facets.status.find((item) => item.key === 'inactive');
  const facetDropped = facets.status.find((item) => item.key === 'dropped');
  const facetTesting = facets.status.find((item) => item.key === 'testing');

  const handleFacetChange = (facet: VendorStatus) => () => {
    const newFilterStatus = queryVendorStatus.includes(facet)
      ? queryVendorStatus.filter((item) => item !== facet)
      : [...queryVendorStatus, facet];
    updateQuery({
      vendorStatus: [...newFilterStatus],
    });
  };

  const handleQuerySearchChangeDebounced = useCallback(
    debounce((value: string) => {
      updateQuery({
        name: value,
      });
    }, DEFAULT_DEBOUNCE_TIMEOUT),
    []
  );

  const handleQuerySearchChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    onSearchTermChange(evt.target.value);
    handleQuerySearchChangeDebounced(evt.target.value);
  };

  return (
    <div className={styles.root}>
      <form>
        <Card>
          <div className={styles.container}>
            <div className={styles.title}>{formatMessage(Translations.ViewAllVendorsFilterPanelFilterVendors)}</div>
            <Input
              startAdornment={['far', 'search']}
              placeholder={formatMessage(Translations.ViewAllVendorsFilterPanelSearchFor)}
              onChange={handleQuerySearchChange}
              value={searchTerm}
            />
            <div className={styles.divider} />
            <div className={styles.subTitle}>Status</div>
            <div className={styles.checkboxContainer}>
              <CheckboxBase
                label={formatMessage(Translations.ViewAllVendorsFilterPanelActive)}
                facets={facetActive ? facetActive.count : 0}
                checked={queryVendorStatus.includes(VendorStatus.ACTIVE)}
                onChange={handleFacetChange(VendorStatus.ACTIVE)}
                disabled={!facetActive}
              />
              <CheckboxBase
                label={formatMessage(Translations.ViewAllVendorsFilterPanelInactive)}
                facets={facetInactive ? facetInactive.count : 0}
                checked={queryVendorStatus.includes(VendorStatus.INACTIVE)}
                onChange={handleFacetChange(VendorStatus.INACTIVE)}
                disabled={!facetInactive}
              />
              <CheckboxBase
                label={formatMessage(Translations.ViewAllVendorsFilterPanelDropped)}
                facets={facetDropped ? facetDropped.count : 0}
                checked={queryVendorStatus.includes(VendorStatus.DROPPED)}
                onChange={handleFacetChange(VendorStatus.DROPPED)}
                disabled={!facetDropped}
              />
              <CheckboxBase
                label={formatMessage(Translations.ViewAllVendorsFilterPanelTesting)}
                facets={facetTesting ? facetTesting.count : 0}
                checked={queryVendorStatus.includes(VendorStatus.TESTING)}
                onChange={handleFacetChange(VendorStatus.TESTING)}
                disabled={!facetTesting}
              />
            </div>
          </div>
        </Card>
      </form>
    </div>
  );
};

export default compose<Props>(FilterPanel);
