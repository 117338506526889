import DealResultsSelectors from 'pages/ViewAllDeals/store/selectors';
import { RootState } from 'pages/_store/root-reducer';
import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { Vendor } from 'types';
import { compose } from 'utils/compose';
import styles from './Header.module.scss';
import Translations from './ManagePeopleHeader.translations';

const Header: FunctionComponent = () => {
  const { formatMessage } = useIntl();
  const vendor = useSelector<RootState, Vendor>((state) => DealResultsSelectors.getSelectedVendor(state), shallowEqual);

  return (
    <div className={styles.root}>
      <div className={styles.title}>{formatMessage(Translations.ManagePeopleHeaderManagePeople)}</div>
      <div className={styles.subTitle}>
        {formatMessage(Translations.ManagePeopleHeaderInviteTeamMembersToJoinTheGowagoSalesPortal, {
          vendor: vendor?.name,
        })}
      </div>
    </div>
  );
};

export default compose(Header);
