import { defineMessages } from 'react-intl';

export default defineMessages({
  ViewAllOrdersFilterPanelFilterOrders: {
    id: 'ViewAllOrdersFilterPanelFilterOrders',
    defaultMessage: 'Filter orders',
  },
  ViewAllOrdersFilterPanelSearchFor: {
    id: 'ViewAllOrdersFilterPanelSearchFor',
    defaultMessage: 'Search for...',
  },
});
