import { faAngleDown, faAngleUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import clsx from 'clsx';
import Card from 'components/Card';
import { FC, ReactElement, useEffect, useState } from 'react';
import { compose } from 'utils/compose';
import styles from './Accordion.module.scss';

interface Props {
  header: string | ReactElement;
  collapsable?: boolean;
  disabled?: boolean;
  initialExpandState?: boolean;
  classes?: {
    icon?: string;
    header?: string;
    rootDisabled?: string;
    container?: string;
  };
}

const Accordion: FC<Props> = ({ header, children, collapsable = true, disabled, classes, initialExpandState }) => {
  const [isExpanded, setExpanded] = useState(typeof initialExpandState === 'boolean' ? initialExpandState : true);

  const toggleAccordion = () => {
    if (collapsable) {
      setExpanded(!isExpanded);
    }
  };

  useEffect(() => {
    if (isExpanded === true && disabled === true) {
      setExpanded(false);
    }
  }, [disabled]);

  return (
    <Card>
      <div className={!disabled ? styles.root : classNames(styles.rootDisabled, classes?.rootDisabled)}>
        <div
          className={classNames(styles.headerContainer, classes?.header, {
            [styles.headerContainerNoChildren]: !children,
          })}
          onClick={toggleAccordion}
        >
          {!!header && <div className={styles.headerText}>{header}</div>}
          {collapsable && (
            <FontAwesomeIcon
              icon={isExpanded ? faAngleUp : faAngleDown}
              className={classNames(styles.icon, classes?.icon)}
            />
          )}
        </div>
        {children && (
          <div
            key="content"
            className={clsx(styles.content, {
              [styles.contentCollapsed]: !isExpanded,
            })}
          >
            <div className={classNames(styles.paddingContainer, classes?.container)}>{children}</div>
          </div>
        )}
      </div>
    </Card>
  );
};

export default compose<Props>(Accordion);
