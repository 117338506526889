import { defineMessages } from 'react-intl';

export default defineMessages({
  ByModelDesignationNumberModelDesignationNumber: {
    id: 'ByModelDesignationNumberModelDesignationNumber',
    defaultMessage: 'Model designation number',
  },
  ByModelDesignationNumberRegistratioOrConstructionDate: {
    id: 'ByModelDesignationNumberRegistratioOrConstructionDate',
    defaultMessage: 'Registration or construction date',
  },
  ByModelDesignationNumberEnterValidModelDesignationNumberFirst: {
    id: 'ByModelDesignationNumberEnterValidModelDesignationNumberFirst',
    defaultMessage: 'Enter valid model designation number first',
  },
  ByModelDesignationNumberRegistrationOrConstructionYear: {
    id: 'ByModelDesignationNumberRegistrationOrConstructionYear',
    defaultMessage: 'Registration or construction year',
  },
});
