import { Reducer } from 'redux';
import { Actions, ActionTypes } from './actions';
import { initialState, OrderPageState } from './initial-state';

export const orderPageReducer: Reducer<OrderPageState> = (state = initialState, action: Actions): OrderPageState => {
  switch (action.type) {
    case ActionTypes.GET_ORDER_REQUEST: {
      return {
        ...state,
        isFetchingResult: true,
        errorMessages: [],
      };
    }
    case ActionTypes.GET_ORDER_SUCCESS: {
      return {
        ...state,
        order: action.payload,
        isFetchingResult: false,
      };
    }
    case ActionTypes.GET_ORDER_FAILURE: {
      return {
        ...state,
        isFetchingResult: false,
      };
    }
    case ActionTypes.UPDATE_ORDER_REQUEST: {
      return {
        ...state,
        isUpdatingOrder: true,
        updateSuccess: false,
        errorMessages: [],
      };
    }
    case ActionTypes.UPDATE_ORDER_SUCCESS: {
      return {
        ...state,
        isUpdatingOrder: false,
        updateSuccess: true,
        errorMessages: [],
      };
    }
    case ActionTypes.UPDATE_ORDER_FAILURE: {
      return {
        ...state,
        isUpdatingOrder: false,
        updateSuccess: false,
        errorMessages: action.payload.errorMessage,
      };
    }
    case ActionTypes.RESET_SUCCESS: {
      return {
        ...state,
        updateSuccess: false,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
