import { defineMessages } from 'react-intl';

export default defineMessages({
  PromotionsFormPromotions: {
    id: 'PromotionsFormPromotions',
    defaultMessage: 'Promotions',
  },
  PromotionsFormShowInGowagoChSalePage: {
    id: 'PromotionsFormShowInGowagoChSalePage',
    defaultMessage: 'Show in gowago.ch sale page',
  },
  PromotionsFormGowagoChSalePage: {
    id: 'PromotionsFormGowagoChSalePage',
    defaultMessage: 'gowago.ch sale page',
  },
  PromotionsFormOrderInTheList: {
    id: 'PromotionsFormOrderInTheList',
    defaultMessage: 'Order in the list',
  },
  PromotionsFormDefaultContractLength: {
    id: 'PromotionsFormDefaultContractLength',
    defaultMessage: 'Default contract length',
  },
  PromotionsFormDefaultAnnualMileage: {
    id: 'PromotionsFormDefaultAnnualMileage',
    defaultMessage: 'Default annual mileage',
  },
  PromotionsFormDefaultDownPayment: {
    id: 'PromotionsFormDefaultDownPayment',
    defaultMessage: 'Default down payment',
  },
  PromotionsFormAnnualMileageKm: {
    id: 'PromotionsFormAnnualMileageKm',
    defaultMessage: '{mileage} km',
  },
  PromotionsFormContractLengthMonths: {
    id: 'PromotionsFormContractLengthMonths',
    defaultMessage: '{contractLength} months',
  },
  PromotionsFormFeaturedDeals: {
    id: 'PromotionsFormFeaturedDeals',
    defaultMessage: 'Featured deals',
  },
  PromotionsFormShowInFeaturedDeals: {
    id: 'PromotionsFormShowInFeaturedDeals',
    defaultMessage: 'Show in featured deals',
  },
  PromotionsFormChoseThePositionOfThisCar: {
    id: 'PromotionsFormChoseThePositionOfThisCar',
    defaultMessage: 'Choose the position of this car in the product list. ',
  },
  PromotionsSelectAnnualMileage: {
    id: 'PromotionsSelectAnnualMileage',
    defaultMessage: 'Select annual mileage',
  },
  PromotionsSelectContractLength: {
    id: 'PromotionsSelectContractLength',
    defaultMessage: 'Select contract length',
  },
  PromotionsFormHotDeals: {
    id: 'PromotionsFormHotDeals',
    defaultMessage: 'Hot deals',
  },
  PromotionsFormMarkAsAHotDeal: {
    id: 'PromotionsFormMarkAsAHotDeal',
    defaultMessage: 'Mark as a hot deal',
  },
  PromotionsFormDealOfTheMonth: {
    id: 'PromotionsFormDealOfTheMonth',
    defaultMessage: 'Deal of the month',
  },
  PromotionsFormMarkAsDealOfTheMonth: {
    id: 'PromotionsFormMarkAsDealOfTheMonth',
    defaultMessage: 'Mark as deal of the month',
  },
  PromotionsFormFleetDiscount: {
    id: 'PromotionsFormFleetDiscount',
    defaultMessage: 'Fleet discount',
  },
  PromotionsFormMarkAsAvailableForFleetDiscount: {
    id: 'PromotionsFormMarkAsAvailableForFleetDiscount',
    defaultMessage: 'Mark as available for fleet discount',
  },
  PromotionsNoClosedUserGroupSelected: {
    id: 'PromotionsNoClosedUserGroupSelected',
    defaultMessage: 'No closed user group selected',
  },
  PromotionsFormClosedUserGroups: {
    id: 'PromotionsFormClosedUserGroups',
    defaultMessage: 'Closed user groups',
  },
  PromotionsFormShowThisToCloseGroupsMembers: {
    id: 'PromotionsFormShowThisToCloseGroupsMembers',
    defaultMessage: 'Show this listing only to the members of a closed user group',
  },
});
