import { defineMessages } from 'react-intl';

export default defineMessages({
  LoginPageInvalidEmailFormat: {
    id: 'LoginPageInvalidEmailFormat',
    defaultMessage: 'Invalid email format',
  },
  LoginPageThisFieldIsRequired: {
    id: 'LoginPageThisFieldIsRequired',
    defaultMessage: 'This field is required',
  },
  LoginPageWelcomeBack: {
    id: 'LoginPageWelcomeBack',
    defaultMessage: 'Welcome back',
  },
  LoginPageSignInToYourAccountToContinue: {
    id: 'LoginPageSignInToYourAccountToContinue',
    defaultMessage: 'Sign in to your account to continue',
  },
  LoginPageEnterYourEmail: {
    id: 'LoginPageEnterYourEmail',
    defaultMessage: 'Enter your email',
  },
  LoginPageEnterYourPassword: {
    id: 'LoginPageEnterYourPassword',
    defaultMessage: 'Enter your password',
  },
  LoginPagePassword: {
    id: 'LoginPagePassword',
    defaultMessage: 'Password',
  },
  LoginPageRememberMeNextTime: {
    id: 'LoginPageRememberMeNextTime',
    defaultMessage: 'Remember me next time',
  },
  LoginPageSignIn: {
    id: 'LoginPageSignIn',
    defaultMessage: 'Sign in',
  },
  LoginPageDontHaveAnAccountYet: {
    id: 'LoginPageDontHaveAnAccountYet',
    defaultMessage: 'Don’t have an account yet?',
  },
  LoginPageInvalidLoginMessage: {
    id: 'LoginPageInvalidLoginMessage',
    defaultMessage:
      "Sorry, that password isn't right. Try <recoverlink>recovering your password</recoverlink> if you have forgotten it.",
  },
  LoginPageForgotPasswordHelperText: {
    id: 'LoginPageForgotPasswordHelperText',
    defaultMessage: 'Forgot password?',
  },
});
