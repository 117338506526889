import { StyledComponentProps, withStyles, WithStyles } from '@material-ui/core';
import Accordion from 'components/Accordion';
import ErrorMessage from 'components/Alerts/ErrorMessage';
import RadioButtonBase from 'components/Forms/RadioButtonBase';
import LoadingIndicator from 'components/LoadingIndicator';
import { Formik } from 'formik';
import AddEditListingSelectors from 'pages/AddEditListings/store/selectors';
import { RootState } from 'pages/_store/root-reducer';
import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { compose } from 'utils/compose';
import {
  AddEditVehicleInformationMakeAndModelValidationShema,
  AddEditVehicleInformationModelDesignationNumberValidationShema,
  AddEditVehicleInformationShema,
  AddEditVehicleMakeAndModelInitialValues,
  AddEditVehicleModelDesignationNumberInitialValues,
} from '../../schema';
import { Actions as AddEditListingsActions } from '../../store/actions';
import { styles } from './AddVehicleInformation.styles';
import Translatons from './AddVehicleInformation.translations';
import ByMakeAndModelFormContent from './ByMakeAndModelFormContent';
import ByModelDesignationNumber from './ByModelDesignationNumber';

enum AddVehicleInformatioSelectionWay {
  BY_MODEL_DESIGNATION_NUMBER = 'BY_MODEL_DESIGNATION_NUMBER',
  BY_MAKE_AND_MODEL = 'BY_MAKE_AND_MODEL',
}

interface Props {
  onMoveToStepTwo: () => void;
  onAddInfoManually: () => void;
}

const AddVehicleInformation: FC<Props & WithStyles<typeof styles>> = ({
  classes,
  onAddInfoManually,
  onMoveToStepTwo,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { formatMessage } = intl;
  const [selectionWay, setSelectionWay] = useState(AddVehicleInformatioSelectionWay.BY_MODEL_DESIGNATION_NUMBER);

  const { isLoading, successGotCars, cars } = useSelector(
    (state: RootState) => ({
      isLoading: AddEditListingSelectors.getIsLoadingVehicleInformation(state),
      successGotCars: AddEditListingSelectors.extractSuccessGotCars(state),
      cars: AddEditListingSelectors.extractCars(state),
      carMakes: state.globalAppState.carMakes,
    }),
    shallowEqual
  );

  const isByMakeAndModel = selectionWay === AddVehicleInformatioSelectionWay.BY_MAKE_AND_MODEL;
  const isByModelDesignationNumber = selectionWay === AddVehicleInformatioSelectionWay.BY_MODEL_DESIGNATION_NUMBER;

  const onSubmit = (values: AddEditVehicleInformationShema) => {
    const { modelDesignationNumber, make, modelSlug, registrationDateYear } = values;

    if (isByModelDesignationNumber) {
      dispatch(AddEditListingsActions.updateDealWithCarFilter({ typenschein: modelDesignationNumber }));
    } else {
      const modelYear = registrationDateYear ? Number(registrationDateYear) : undefined;
      dispatch(AddEditListingsActions.updateDealWithCarFilter({ modelSlug, make, modelYear }));
    }
    onMoveToStepTwo();
  };

  // reset deal on first step mount
  useEffect(() => {
    dispatch(AddEditListingsActions.getDealSuccess());
  }, [dispatch]);

  const handleUpdateSelectionWay = (value: string) => {
    setSelectionWay(value as AddVehicleInformatioSelectionWay);
    dispatch(AddEditListingsActions.getDealSuccess());
    dispatch(AddEditListingsActions.resetCars());
  };

  const initialValues = isByMakeAndModel
    ? AddEditVehicleMakeAndModelInitialValues
    : AddEditVehicleModelDesignationNumberInitialValues;

  const schema = isByMakeAndModel
    ? AddEditVehicleInformationMakeAndModelValidationShema(intl)
    : AddEditVehicleInformationModelDesignationNumberValidationShema(intl);

  const noCarsAvailable = successGotCars && !cars?.length;

  return (
    <div className={classes.root}>
      <Formik initialValues={initialValues} validationSchema={schema} enableReinitialize onSubmit={onSubmit}>
        {() => (
          <div className={classes.root}>
            {noCarsAvailable && <ErrorMessage title={formatMessage(Translatons.AddVehicleNoCarsAvailable)} />}
            <LoadingIndicator isLoading={isLoading}>
              <Accordion header={formatMessage(Translatons.AddVehicleInformationTitle)} collapsable={false}>
                <p className={classes.selectionTitle}>
                  {formatMessage(Translatons.AddVehicleInformationFindVehicleBy)}
                </p>
                <div className={classes.selectionWrapper}>
                  <RadioButtonBase
                    onClick={handleUpdateSelectionWay}
                    value={AddVehicleInformatioSelectionWay.BY_MODEL_DESIGNATION_NUMBER}
                    selected={isByModelDesignationNumber}
                    label={formatMessage(Translatons.AddVehicleInformationModelDesignationNumber)}
                  />
                  <RadioButtonBase
                    onClick={handleUpdateSelectionWay}
                    value={AddVehicleInformatioSelectionWay.BY_MAKE_AND_MODEL}
                    selected={isByMakeAndModel}
                    label={formatMessage(Translatons.AddVehicleInformationMakeAndModel)}
                  />
                </div>
                {isByMakeAndModel && <ByMakeAndModelFormContent onAddInfoManually={onAddInfoManually} />}
                {isByModelDesignationNumber && <ByModelDesignationNumber onAddInfoManually={onAddInfoManually} />}
              </Accordion>
            </LoadingIndicator>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default compose<Props & StyledComponentProps>(
  AddVehicleInformation,
  withStyles(styles, { name: 'AddVehicleInformation' })
);
