import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import ButtonBase from 'components/Forms/ButtonBase';
import TextField from 'components/Forms/TextField';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { RootState } from '../../../_store/root-reducer';
import styles from './ChangePassword.module.scss';
import messages from './ChangePassword.translations';
import FormMessageContainer from './components/FormMessageContainer';
import { Actions as ChangePasswordActions } from './store/actions';
import { ChangePasswordFormValue } from './types';

const ChangePassword: FC = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const cx = classNames.bind(styles);

  const isLoading = useSelector((state: RootState) => state.changePasswordPage.isFetching, shallowEqual);
  const ChangePasswordFormSchema = Yup.object().shape({
    currentPassword: Yup.string().required(formatMessage(messages.ChangePasswordThisFieldIsRequired)),
    newPassword: Yup.string().required(formatMessage(messages.ChangePasswordThisFieldIsRequired)).min(6).max(50),
    repeatPassword: Yup.string()
      .required(formatMessage(messages.ChangePasswordThisFieldIsRequired))
      .when('newPassword', {
        is: (val) => !!(val && val.length > 0),
        then: Yup.string().oneOf(
          [Yup.ref('newPassword')],
          formatMessage(messages.ChangePasswordBothPasswordNeedToBeTheSameErrorLabel)
        ),
      }),
  });

  const handleFormSubmit = async (values: ChangePasswordFormValue, actions: FormikHelpers<ChangePasswordFormValue>) => {
    dispatch(ChangePasswordActions.changePasswordRequest({ ...values }));
  };

  const isPasswordCharacterLengthValid = (value: string) => value.length > 5 && value.length < 49;
  const isPasswordHasNumberAndLetter = (value: string) => /\d/.test(value) && /.*[a-zA-Z].*/.test(value);

  return (
    <div className={styles.root}>
      <Formik
        initialValues={{
          currentPassword: '',
          newPassword: '',
          repeatPassword: '',
        }}
        validationSchema={ChangePasswordFormSchema}
        onSubmit={handleFormSubmit}
      >
        {(props: FormikProps<ChangePasswordFormValue>) => (
          <>
            <FormMessageContainer />
            <div className={styles.formContainer}>
              <div className={styles.formTitle}>{formatMessage(messages.ChangePasswordTitle)}</div>
              <form onSubmit={props.handleSubmit} className={styles.formContent}>
                <div className={styles.fieldContainer}>
                  <TextField
                    name="currentPassword"
                    type="password"
                    label={formatMessage(messages.ChangePasswordCurrentPasswordLabel)}
                  />
                </div>
                <div className={styles.fieldContainer}>
                  <TextField
                    name="newPassword"
                    type="password"
                    label={formatMessage(messages.ChangePasswordNewPasswordLabel)}
                    customErrorLabel={(value: any, error: boolean, dirty: boolean) => {
                      return (
                        <div>
                          <div className={styles.newPasswordErrorLabelContent}>
                            <FontAwesomeIcon
                              icon={['far', isPasswordCharacterLengthValid(value) ? 'check-circle' : 'times-circle']}
                              className={cx(styles.newPasswordErrorIcon, {
                                error: dirty && !isPasswordCharacterLengthValid(value),
                                good: isPasswordCharacterLengthValid(value),
                              })}
                            />
                            <span
                              className={cx(styles.newPasswordErrorLabel, {
                                error: dirty && !isPasswordCharacterLengthValid(value),
                              })}
                            >
                              {formatMessage(messages.ChangePasswordCharacterLengthRequirement)}
                            </span>
                          </div>
                          <div className={styles.newPasswordErrorLabelContent}>
                            <FontAwesomeIcon
                              icon={['far', isPasswordHasNumberAndLetter(value) ? 'check-circle' : 'times-circle']}
                              className={cx(styles.newPasswordErrorIcon, {
                                error: dirty && !isPasswordHasNumberAndLetter(value),
                                good: isPasswordHasNumberAndLetter(value),
                              })}
                            />
                            <span
                              className={cx(styles.newPasswordErrorLabel, {
                                error: dirty && !isPasswordHasNumberAndLetter(value),
                              })}
                            >
                              {formatMessage(messages.ChangePasswordContainsLettersAndNumbersRequirement)}
                            </span>
                          </div>
                        </div>
                      );
                    }}
                  />
                </div>
                <div className={styles.fieldContainer}>
                  <TextField
                    name="repeatPassword"
                    type="password"
                    label={formatMessage(messages.ChangePasswordRepeatPasswordLabel)}
                  />
                </div>
                <div className={styles.submitButtonContainer}>
                  <ButtonBase type="submit" classes={{ root: styles.submitButton }} isLoading={isLoading}>
                    {formatMessage(messages.ChangePasswordSaveChangesSubmitButton)}
                  </ButtonBase>
                </div>
              </form>
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};

export default ChangePassword;
