import { Seller } from 'types';

export interface SellerProfileState {
  seller?: Seller;
  vendors: string[];
  isLoading: boolean;
  isError: boolean;
  successfullyUpdated: boolean;
}

export const initialState: SellerProfileState = {
  vendors: [],
  isLoading: false,
  isError: false,
  successfullyUpdated: false,
};
