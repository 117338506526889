import { getOffer, updateOffer } from 'api/offers';
import { SOMETHING_WENT_WRONG } from 'constants/errors';
import moment from 'moment';
import { getErrorMessages } from 'pages/AddEditListings/utils';
import GlobalSelectors from 'pages/_store/global/selectors';
import { fork, put, takeEvery } from 'redux-saga/effects';
import { call, select } from 'typed-redux-saga';
import { YYYY_MM_DD } from 'utils/date';
import { Actions as OrdersActions, ActionTypes as OrdersActionTypes } from './actions';

function* getOrdersRequestAsync(action: ReturnType<typeof OrdersActions.getOrderRequest>) {
  try {
    window.scrollTo(0, 0);
    const res = yield* call(getOffer, action.payload);

    if (res.esDeal.licensingDateRaw) {
      res.esDeal.licensingDateRaw = moment(res.esDeal.licensingDateRaw).format(YYYY_MM_DD);
    }

    const nominalInterestRateOriginal = res.nominalInterestRateOriginal || res.interestRateNominalConfirmed;

    yield put(
      OrdersActions.getOrderSuccess({
        ...res,
        nationality: res.insuranceNationality,
        ageCategory: res.insuranceAgeCategory,
        nominalInterestRateOriginal,
      })
    );
  } catch (e) {
    yield put(OrdersActions.getOrderFailure(e));
  }
}

function* updateOrderRequestAsync(action: ReturnType<typeof OrdersActions.updateOrderRequest>) {
  try {
    const isSuperAdmin = yield select(GlobalSelectors.getIsSuperAdmin);
    const isOfferPricingUpdateAdmin = yield select(GlobalSelectors.getIsOfferPricingUpdateAdmin);

    const isSuperAdminOrPricingUpdateAdmin = isSuperAdmin || isOfferPricingUpdateAdmin;

    yield* call(updateOffer, { ...action.payload }, isSuperAdminOrPricingUpdateAdmin);

    window.scrollTo(0, 0);
    yield put(OrdersActions.updateOrderSuccess());
    yield put(OrdersActions.getOrderRequest(action.payload.publicId));
  } catch (e) {
    window.scrollTo(0, 0);

    const errorData = e?.data;
    const errorMessage = e?.message;
    const sanityIssues = e?.sanityIssues;

    let message: string[] = [];
    if (!e) {
      message = [SOMETHING_WENT_WRONG];
    } else if (errorData || errorMessage) {
      // TODO very bad and specific case, agree with BE about validation error format and refactor this
      if (errorData?.minDownPayment || errorData?.maximumPrivateCreditAmount) {
        message = getErrorMessages(errorMessage);
      } else {
        message = getErrorMessages(errorData || errorMessage);
      }
      //
    } else if (sanityIssues) {
      message = sanityIssues;
    } else {
      message = Object.entries(e).map(([key, value]) => JSON.stringify({ [key]: value }, undefined, 2));
    }

    yield put(OrdersActions.updateOrderFailure(message));
  }
}

function* watchGetOrdersRequestAsync() {
  yield takeEvery(OrdersActionTypes.GET_ORDER_REQUEST, getOrdersRequestAsync);
}

function* watchUpdateOrderRequestAsync() {
  yield takeEvery(OrdersActionTypes.UPDATE_ORDER_REQUEST, updateOrderRequestAsync);
}

export default [fork(watchGetOrdersRequestAsync), fork(watchUpdateOrderRequestAsync)];
