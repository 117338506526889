import { defineMessages } from 'react-intl';

export default defineMessages({
  CarPriceFormPricing: {
    id: 'CarPriceFormPricing',
    defaultMessage: 'Pricing',
  },
  CarPriceFormPrice: {
    id: 'CarPriceFormPrice',
    defaultMessage: 'Price',
  },
  CarPriceFormNewPrice: {
    id: 'CarPriceFormNewPrice',
    defaultMessage: 'New Price',
  },
  CarPriceFormTotalCarPrice: {
    id: 'CarPriceFormTotalCarPrice',
    defaultMessage: 'Total car price',
  },
  CarPriceFormDealerDeliveryFee: {
    id: 'CarPriceFormDealerDeliveryFee',
    defaultMessage: 'Dealer delivery fee',
  },
  CarPriceFormLeasingProvider: {
    id: 'CarPriceFormLeasingProvider',
    defaultMessage: 'Leasing provider',
  },
  CarPriceFormInterestRate: {
    id: 'CarPriceFormInterestRate',
    defaultMessage: 'Interest rate',
  },
  CarPriceFormLeaseType: {
    id: 'CarPriceFormLeaseType',
    defaultMessage: 'Lease type',
  },
  CarPriceFormBusiness: {
    id: 'CarPriceFormBusiness',
    defaultMessage: 'Business',
  },
  CarPriceFormPrivate: {
    id: 'CarPriceFormPrivate',
    defaultMessage: 'Private',
  },
  CarPriceFormMonthlyManagementFee: {
    id: 'CarPriceFormMonthlyManagementFee',
    defaultMessage: 'Monthly management fee',
  },
  CarPriceFormPriceTitle: {
    id: 'CarPriceFormPriceTitle',
    defaultMessage: 'Price',
  },
  CarPriceFormLeasingTerms: {
    id: 'CarPriceFormLeasingTerms',
    defaultMessage: 'Leasing terms',
  },
  CarPriceFormUseMyDefaultLeasingTerms: {
    id: 'CarPriceFormUseMyDefaultLeasingTerms',
    defaultMessage: 'Use my default leasing terms',
  },
  CarPriceFormSwitchToCustomLeasingTermsToEdit: {
    id: 'CarPriceFormSwitchToCustomLeasingTermsToEdit',
    defaultMessage: 'Switch to custom leasing terms to edit this field',
  },
  CarPriceFormDefaultLeasingTerms: {
    id: 'CarPriceFormDefaultLeasingTerms',
    defaultMessage: 'Default leasing terms',
  },
  CarPriceFormViewOrChangeYourDefaultTerms: {
    id: 'CarPriceFormViewOrChangeYourDefaultTerms',
    defaultMessage: 'View or change your default terms in your',
  },
  CarPriceFormListingSettingsLink: {
    id: 'CarPriceFormListingSettingsLink',
    defaultMessage: 'Listing settings',
  },
  CarPriceFormContractType: {
    id: 'CarPriceFormContractType',
    defaultMessage: 'Contract type',
  },
});
