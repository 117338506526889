import { StyledComponentProps, withStyles, WithStyles } from '@material-ui/core';
import clsx from 'clsx';
import { FC, useEffect, useState } from 'react';
import { DropTargetMonitor } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { compose } from 'utils/compose';
import { ALLOWED_MIME_TYPES, FileUploadErrorTypes } from '../FileUpload.utils';
import { styles } from './FileUploadDnD.styles';
import FileUploadInput from './FileUploadInput';
import { ExternalProps as FileUploadInputExternalProps } from './FileUploadInput/FileUploadInput';

const { FILE } = NativeTypes;

interface Props {
  allowedTypes?: string[];
  acceptsTypes?: string[];
  maxLength?: number;
  maxSize?: number;
  takeFirstIfMultiple?: boolean;
  selectedFiles?: File[];
  fileUploadInputProps?: FileUploadInputExternalProps & StyledComponentProps;
  setIsDismissed?: (dismissed: boolean) => void;
  onChange?: (files: File[]) => void;
  onError?: (type: FileUploadErrorTypes) => void;
  isLoading?: boolean;
  disabled?: boolean;
  name: string;
}

const FileUploadDnD: FC<Props & WithStyles<typeof styles>> = ({
  classes,
  allowedTypes = ALLOWED_MIME_TYPES,
  acceptsTypes = [FILE],
  maxLength = 1,
  takeFirstIfMultiple = true,
  selectedFiles = [],
  fileUploadInputProps,
  setIsDismissed,
  onChange,
  onError,
  isLoading = false,
  disabled = false,
  name,
  maxSize = 10000000, //10 mb
}) => {
  const [droppedFiles, setDroppedFiles] = useState<File[]>(selectedFiles);

  useEffect(() => {
    const oldName = droppedFiles?.[0]?.name || '';
    const newName = selectedFiles?.[0]?.name || '';

    if (oldName !== newName) {
      setDroppedFiles(selectedFiles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFiles]);

  const handleFilesChange = (files: File[]) => {
    if (takeFirstIfMultiple) {
      files = [files[0]];
    }

    if (files.length > maxLength) {
      handleOnError(FileUploadErrorTypes.LENGTH);
      return;
    }

    if (files.some((file) => !allowedTypes.includes(file?.type?.toLowerCase()))) {
      handleOnError(FileUploadErrorTypes.FORMAT);
      return;
    }

    if (files.some((file) => file.size > maxSize)) {
      handleOnError(FileUploadErrorTypes.SIZE);
      return;
    }

    setDroppedFiles(files);

    if (onChange) {
      onChange(files);
    }
  };

  const handleOnError = (type: FileUploadErrorTypes) => {
    if (onError) {
      onError(type);
    }
  };

  const handleFileDrop = (item: any, monitor: DropTargetMonitor) => {
    if (!monitor) return;

    const dropped = monitor.getItem<any>().files as File[];

    handleFilesChange(dropped);
  };

  const onFileInputChange = (files: FileList) => handleFilesChange(Array.from(files));

  const handleDismiss = () => {
    setDroppedFiles([]);

    if (setIsDismissed) {
      setIsDismissed(true);
    }
  };

  return (
    <div className={clsx('FileUploadDnD', classes.root)}>
      <FileUploadInput
        name={name}
        disabled={disabled}
        isLoading={isLoading}
        accepts={acceptsTypes}
        onDrop={handleFileDrop}
        onFileInputChange={onFileInputChange}
        droppedFiles={droppedFiles}
        onDismiss={handleDismiss}
        accept={acceptsTypes?.join(',')}
        {...(fileUploadInputProps || {})}
      />
    </div>
  );
};

export default compose<Props & StyledComponentProps>(FileUploadDnD, withStyles(styles, { name: 'FileUploadDnD' }));
