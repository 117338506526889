import { Formik } from 'formik';
import Header from 'pages/AddEditListings/components/Header';
import { HeaderContext } from 'pages/AddEditListings/components/Header/Header.utils';
import { useJumpToTopOnError } from 'pages/AddEditListings/hooks/useJumpToTopOnError';
import ViewAllDealsSelectors from 'pages/ViewAllDeals/store/selectors';
import { Actions as GlobalActions } from 'pages/_store/global/actions';
import { RootState } from 'pages/_store/root-reducer';
import { FunctionComponent, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Prompt, useParams } from 'react-router-dom';
import { compose } from 'utils/compose';
import styles from '../../AddEditListings.module.scss';
import Translations from '../../AddEditListings.translations';
import FormContent from '../../components/FormContent';
import { AddEditListingSchema, AddEditListingsValidationSchema, getInitialValues } from '../../schema';
import { Actions as AddEditListingsActions } from '../../store/actions';
import { convertDealToSchema, convertSchemaToDealRequest, DefaultAddEditListingParams } from '../../utils';

const EditListingsPage: FunctionComponent = () => {
  const intl = useIntl();
  const { formatMessage } = intl;

  const dispatch = useDispatch();
  const { dealId: dealIdParam } = useParams<DefaultAddEditListingParams>();

  const {
    addEditListingsPage: { deal, dealImages, unsavedChanges },
    vendor,
  } = useSelector(
    (state: RootState) => ({
      addEditListingsPage: state.addEditListingsPage,
      vendor: ViewAllDealsSelectors.getSelectedVendor(state),
    }),
    shallowEqual
  );

  const formikValues = useMemo(() => {
    if (!deal || !dealIdParam) {
      return getInitialValues(vendor);
    }
    return convertDealToSchema(deal, dealImages, vendor);
  }, [deal, dealImages, dealIdParam, vendor]);

  const getDealId = () => deal?.id;

  useJumpToTopOnError();

  useEffect(() => {
    if (dealIdParam) {
      dispatch(AddEditListingsActions.getDealRequest(Number(dealIdParam)));
    } else {
      dispatch(AddEditListingsActions.getDealSuccess());
      dispatch(AddEditListingsActions.setDealImages([]));
    }
  }, [dispatch, dealIdParam]);

  useEffect(() => {
    return () => {
      dispatch(AddEditListingsActions.resetState());
    };
  }, [dispatch]);

  const dialogMessage = formatMessage(Translations.AddEditListingsYouHaveUnsavedChanged);
  const okLabel = formatMessage(Translations.AddEditListingsContinueEditing);
  const closeLabel = formatMessage(Translations.AddEditListingsDiscardChanges);

  useEffect(() => {
    dispatch(
      GlobalActions.setConfirmationModalProps({
        message: dialogMessage,
        okLabel: okLabel,
        closeLabel: closeLabel,
      })
    );
  }, [dispatch, dialogMessage, okLabel, closeLabel]);

  const onSubmit = (values: AddEditListingSchema) => {
    dispatch(AddEditListingsActions.publishRequest(convertSchemaToDealRequest(values), values.images, getDealId()));
  };

  return (
    <>
      <div className={styles.root}>
        <Header context={HeaderContext.EDIT} />
        <Formik
          initialValues={formikValues}
          validationSchema={AddEditListingsValidationSchema(intl)}
          enableReinitialize
          onSubmit={onSubmit}
        >
          {() => <FormContent />}
        </Formik>
      </div>
      <Prompt when={unsavedChanges} message="" />
    </>
  );
};

export default compose(EditListingsPage);
