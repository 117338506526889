import { createStyles } from '@material-ui/core';
import { GowagoTheme } from 'theme';

export const styles = (theme: GowagoTheme) =>
  createStyles({
    root: {
      cursor: 'pointer',
    },
    disabled: {
      cursor: 'not-allowed',
    },
    rootActive: {},
    fileInput: {
      display: 'none',
    },
    content: {},
    label: {},
  });
