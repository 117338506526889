import _ from 'lodash';
import { MakeInterestRate } from 'types';
import { makeInterestRateComparer } from './comparer-functions';
import { isNullOrUndefined } from './objects';

export function parseInterestRateTo100(value?: number): number {
  if (value === null || typeof value === 'undefined') {
    throw new Error('Interest rate not defined');
  }
  return Number((value * 100).toFixed(2));
}

export function parseInterestRateToDecimal(value?: number): number {
  if (!value && value !== 0) {
    return value;
  }
  return Math.round((value / 100) * 10000) / 10000;
}

export const parseLeasingInterestRateByMakeTo100 = (interestRatesByMake?: Array<MakeInterestRate>) => {
  const arrayDeepClone = _.cloneDeep(interestRatesByMake);

  if (!arrayDeepClone || arrayDeepClone.length === 0) return [];

  arrayDeepClone.forEach((interestRateByMake) => {
    if (interestRateByMake.leasingInterestRateNewCar) {
      interestRateByMake.leasingInterestRateNewCar = parseInterestRateTo100(
        interestRateByMake.leasingInterestRateNewCar
      );
    }
    if (interestRateByMake.leasingInterestRateUsedCar) {
      interestRateByMake.leasingInterestRateUsedCar = parseInterestRateTo100(
        interestRateByMake.leasingInterestRateUsedCar
      );
    }
  });

  arrayDeepClone.sort(makeInterestRateComparer);
  return arrayDeepClone as Array<MakeInterestRate>;
};

export const parseLeasingInterestRatesByMakeToDecimal = (interestRatesByMake: Array<Partial<MakeInterestRate>>) => {
  const arrayDeepClone = _.cloneDeep(interestRatesByMake);
  if (!arrayDeepClone || arrayDeepClone.length === 0) return [];

  arrayDeepClone.forEach((interestRateByMake) => {
    if (interestRateByMake.leasingInterestRateNewCar) {
      interestRateByMake.leasingInterestRateNewCar = parseInterestRateToDecimal(
        interestRateByMake.leasingInterestRateNewCar
      );
    }
    if (interestRateByMake.leasingInterestRateUsedCar) {
      interestRateByMake.leasingInterestRateUsedCar = parseInterestRateToDecimal(
        interestRateByMake.leasingInterestRateUsedCar
      );
    }
  });
  return arrayDeepClone as Array<MakeInterestRate>;
};

export const toNumber = <T>(val: any, defaultValue?: number) =>
  (!isNaN(val) || !isNullOrUndefined(val) ? Number(val) : defaultValue) as unknown as T;
