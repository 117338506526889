import { ActionsUnion, createAction } from 'utils/action-utils';
import { ChangePasswordParams } from './initial-state';

export enum ActionTypes {
  CHANGE_PASSWORD_REQUEST = '[CHANGE_PASSWORD] CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS = '[CHANGE_PASSWORD] CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE = '[CHANGE_PASSWORD] CHANGE_PASSWORD_FAILURE',
}

export const Actions = {
  changePasswordRequest: (params: ChangePasswordParams) => createAction(ActionTypes.CHANGE_PASSWORD_REQUEST, params),
  changePasswordSuccess: () => createAction(ActionTypes.CHANGE_PASSWORD_SUCCESS),
  changePasswordFailure: (errorCode: number) => createAction(ActionTypes.CHANGE_PASSWORD_FAILURE, errorCode),
};

export type Actions = ActionsUnion<typeof Actions>;
