import { Seller } from 'types';
import { ActionsUnion, createAction } from 'utils/action-utils';
import { SellerProfileFormData } from './types';

export enum ActionTypes {
  GET_SELLER_REQUEST = '[SELLER_PROFILE] GET_SELLER_REQUEST',
  GET_SELLER_SUCCESS = '[SELLER_PROFILE] GET_SELLER_SUCCESS',
  GET_SELLER_FAILURE = '[SELLER_PROFILE] GET_SELLER_FAILURE',
  UPDATE_SELLER_REQUEST = '[SELLER_PROFILE] UPDATE_SELLER_REQUEST',
  UPDATE_SELLER_SUCCESS = '[SELLER_PROFILE] UPDATE_SELLER_SUCCESS',
  UPDATE_SELLER_FAILURE = '[SELLER_PROFILE] UPDATE_SELLER_FAILURE',
  HIDE_SUCCESS_ALERT_MESSAGE = '[SELLER_PROFILE] HIDE_SUCCESS_ALERT_MESSAGE',
  RESET_STATE = '[SELLER_PROFILE] RESET_STATE',
}

export const Actions = {
  getSellerRequest: (id: number) => createAction(ActionTypes.GET_SELLER_REQUEST, id),
  getSellerSuccess: (seller: Seller, vendors: string[]) =>
    createAction(ActionTypes.GET_SELLER_SUCCESS, { seller, vendors }),
  getSellerFailure: () => createAction(ActionTypes.GET_SELLER_FAILURE),
  updateSellerRequest: (sellerProfileData: SellerProfileFormData) =>
    createAction(ActionTypes.UPDATE_SELLER_REQUEST, sellerProfileData),
  updateSellerSuccess: () => createAction(ActionTypes.UPDATE_SELLER_SUCCESS),
  updateSellerFailure: (isError: boolean) => createAction(ActionTypes.UPDATE_SELLER_FAILURE, { isError }),
  hideSuccessAlertMessage: () => createAction(ActionTypes.HIDE_SUCCESS_ALERT_MESSAGE),
  resetState: () => createAction(ActionTypes.RESET_STATE),
};

export type Actions = ActionsUnion<typeof Actions>;
