import clsx from 'clsx';
import Accordion from 'components/Accordion';
import DatePicker from 'components/Forms/DatePicker';
import RHFInput from 'components/Forms/RHFInput/RHFInput';
import LoadingIndicator from 'components/LoadingIndicator';
import { RootState } from 'pages/_store/root-reducer';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { PublicOffer } from 'types';
import { useStyles } from '../FormContent.styles';
import { messages } from '../FormContent.translations';

const OfferOverviewForm: FC = () => {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  const { isFetchingResult, order } = useSelector((state: RootState) => state.orderPage, shallowEqual);

  const orderTyped = order as PublicOffer;

  const { watch } = useFormContext<PublicOffer>();
  const createdAt = watch('createdAt');

  const orderDeactivated = !!orderTyped?.datetimeDeactivated;

  return (
    <div className={clsx('OfferOverviewForm', classes.root)}>
      <LoadingIndicator isLoading={isFetchingResult}>
        <Accordion header={formatMessage(messages.OfferOverviewFormOfferInformation)} collapsable={false}>
          <div className={classes.formRow}>
            <div className={classes.formColumn}>
              <RHFInput
                name="hubspotDealId"
                label={formatMessage(messages.OfferOverviewFormHubspotDealId)}
                disabled={true || orderDeactivated}
              />
            </div>
            <div className={classes.formColumn}>
              <RHFInput
                name="publicId"
                label={formatMessage(messages.OfferOverviewFormPublicId)}
                disabled={true || orderDeactivated}
              />
            </div>
            <div className={classes.formColumn}>
              <RHFInput
                name="dealId"
                label={formatMessage(messages.OfferOverviewFormDealId)}
                disabled={true || orderDeactivated}
              />
            </div>
          </div>
          <div className={classes.formRow}>
            <div className={classes.formColumn}>
              <RHFInput
                name="firstName"
                label={formatMessage(messages.OfferOverviewFormFirstName)}
                disabled={true || orderDeactivated}
              />
            </div>
            <div className={classes.formColumn}>
              <RHFInput
                name="lastName"
                label={formatMessage(messages.OfferOverviewFormLastName)}
                disabled={true || orderDeactivated}
              />
            </div>
            <div className={classes.formColumn}>
              <DatePicker
                isInput
                disabled={true || orderDeactivated}
                value={createdAt}
                label={formatMessage(messages.OfferOverviewFormDateCreated)}
                name="createdAt"
              />
            </div>
          </div>
          <div className={classes.formRow}>
            <div className={classes.formColumn}>
              <RHFInput
                name="offerType"
                label={formatMessage(messages.OfferOverviewFormOfferType)}
                disabled={true || orderDeactivated}
              />
            </div>
            <div className={classes.formColumn} />
            <div className={classes.formColumn} />
          </div>
          {orderDeactivated && (
            <div className={classes.formRow}>
              <div className={classes.formColumn}>
                <DatePicker
                  isInput
                  disabled={true || orderDeactivated}
                  value={orderTyped?.datetimeDeactivated}
                  label={formatMessage(messages.OfferOverviewFormDateDeactivated)}
                  name="datetimeDeactivated"
                />
              </div>
            </div>
          )}
        </Accordion>
      </LoadingIndicator>
    </div>
  );
};

export default OfferOverviewForm;
