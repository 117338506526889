import { DropTarget, DropTargetConnector, DropTargetMonitor } from 'react-dnd';

export interface WithDnDProps {
  accepts: string[];
  onDrop: (props: WithDnDProps, monitor: DropTargetMonitor) => void;
}

const withDnD = DropTarget(
  (props: WithDnDProps) => props.accepts,
  {
    drop(props: WithDnDProps, monitor: DropTargetMonitor) {
      if ((props as any).disabled) return;

      if (props.onDrop) {
        props.onDrop(props, monitor);
      }
    },
  },
  (connect: DropTargetConnector, monitor: DropTargetMonitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  })
);

export default withDnD;
