import { createStyles } from '@material-ui/core';
import { GowagoTheme } from 'theme';

export const styles = (theme: GowagoTheme) =>
  createStyles({
    root: {
      maxWidth: 350,
      padding: theme.gowago.spacing.rem,
      marginLeft: 'auto',
    },
  });
