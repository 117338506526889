import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormHelperText, StyledComponentProps, withStyles, WithStyles } from '@material-ui/core';
import clsx from 'clsx';
import { FC, Fragment, ReactNode } from 'react';
import { compose } from 'utils/compose';
import { styles } from './ErrorHelperText.styles';

interface Props {
  message?: string | ReactNode;
  errorLabel?: string;
}

const ErrorHelperText: FC<Props & WithStyles<typeof styles>> = ({ classes, message, errorLabel }) => {
  return message ? (
    <FormHelperText error className={clsx('ErrorHelperText', classes.root)} data-error-label={errorLabel}>
      <FontAwesomeIcon icon={faExclamationCircle} className={classes.icon} />
      {message}
    </FormHelperText>
  ) : (
    <Fragment />
  );
};

export default compose<Props & StyledComponentProps>(ErrorHelperText, withStyles(styles, { name: 'ErrorHelperText' }));
