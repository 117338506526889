import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { FC, Fragment } from 'react';
import styles from './RecordValuesTable.module.scss';
import { getKeyValueRows } from './RecordValuesTable.utils';

interface Props {
  name: string;
  title: string;
  columnTitle: string;
  data: Record<string, Record<string, number>> | undefined;
}

const RecordValuesTable: FC<Props> = ({ name, title, columnTitle, data }) => {
  return data ? (
    <>
      <p className={styles.title}>{title}</p>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{columnTitle}</TableCell>
              {Object.keys(data).map((key) => (
                <TableCell key={`${name}-${key}`}>{key}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {getKeyValueRows(data).map(({ km, costs }, index1) => (
              <TableRow key={`${name}-${km}`}>
                <TableCell>{km}</TableCell>
                {costs.map((cost, index2) => (
                  <TableCell key={`${name}-${km}-${cost}-${index1}-${index2}`}>{cost}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  ) : (
    <Fragment />
  );
};

export default RecordValuesTable;
