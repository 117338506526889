export interface SellerRegistrationData extends ValidateSellerInfo {
  token: string;
  password: string;
  passwordRepeated: string;
}

export interface ValidateSellerInfo {
  sellerId: number;
  vendorId: number;
  firstName: string;
  lastName: string;
}
export interface RegisterPageState {
  isTokenValidationFetching: boolean;
  isSellerRegistrationFetching: boolean;
  sellerData: SellerRegistrationData;
  isTokenInvalid: boolean;
  isSuccess: boolean;
}

export const initialState: RegisterPageState = {
  isSellerRegistrationFetching: false,
  isTokenValidationFetching: false,
  isTokenInvalid: false,
  isSuccess: false,
  sellerData: {
    sellerId: 0,
    vendorId: 0,
    firstName: '',
    lastName: '',
    token: '',
    password: '',
    passwordRepeated: '',
  },
};
