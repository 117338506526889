import GlobalAppSelectors from '../../_store/global/selectors';
import { RootState } from '../../_store/root-reducer';

const getSelectedVendor = (state: RootState) => {
  const userVendors = GlobalAppSelectors.extractUserVendors(state);

  if (userVendors?.length === 1) {
    return userVendors[0];
  }

  return state.dealResultsState.selectedVendor;
};

export default {
  getSelectedVendor,
};
