import { createStyles } from '@material-ui/core';
import { GowagoTheme } from 'theme';

export const styles = (theme: GowagoTheme) =>
  createStyles({
    root: {},
    dialogRoot: {
      zIndex: 9999999999,
    },
    dialogTitleContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    closeContainer: {
      ...theme.containers.resetDefaultButtonStyles,
      padding: 0,
      paddingTop: 0,
      lineHeight: 'inherit',
      cursor: 'pointer',
      fontSize: theme.gowago.fontSize.standard,
      height: 51,
      width: 51,
      borderRadius: '50%',
    },
    titleRoot: {
      padding: 0,
      borderBottom: '1px solid rgba(108,122,137,0.2)',
    },
    closeIcon: {
      fontSize: 24,
      color: theme.gowago.text.secondary,
    },
    dialogContent: {
      padding: '40px 24px',
      [theme.breakpoints.down('sm')]: {
        minWidth: '100%',
        boxSizing: 'border-box',
        padding: '29px 15px 15px 15px',
        flex: 'initial',
      },
    },
    paperScrollPaper: {
      ...theme.gowago.dialogs.dialogPaperScrollPaper(theme),

      [theme.breakpoints.up('md')]: {
        maxHeight: 'unset',
        left: '50%',
        transform: 'translateX(-50%)',
        marginLeft: 0,
      },
      [theme.breakpoints.up('sm')]: {
        minWidth: 'auto',
      },
    },
    dialogTitle: {
      color: theme.gowago.text.primary,
      fontFamily: theme.gowago.fontFamily,
      fontSize: theme.gowago.fontSize.standard,
      fontWeight: 600,
      lineHeight: theme.gowago.lineHeight.rem[19],
      textAlign: 'center',
      width: '100%',
      marginRight: -50,
    },
    loadingIndicator: {
      display: 'block',
      margin: `${theme.gowago.spacing.medium} auto`,
    },
    scrollPaper: {
      [theme.breakpoints.up('md')]: {
        display: 'block',
        overflow: 'auto',
      },
    },
  });
