import { defineMessages } from 'react-intl';

export default defineMessages({
  SyncWithAutoScoutFormSyncWithAutoScout24: {
    id: 'SyncWithAutoScoutFormSyncWithAutoScout24',
    defaultMessage: 'Sync with AutoScout24',
  },
  SyncWithAutoScoutFormDisabled: {
    id: 'SyncWithAutoScoutFormDisabled',
    defaultMessage: 'Disabled',
  },
  SyncWithAutoScoutFormEnabled: {
    id: 'SyncWithAutoScoutFormEnabled',
    defaultMessage: 'Enabled',
  },
  SyncWithAutoScoutFormAutomaticallyUpdateThisListingToAlways: {
    id: 'SyncWithAutoScoutFormAutomaticallyUpdateThisListingToAlways',
    defaultMessage: 'Automatically update this listing to always show the latest information from',
  },
  SyncWithAutoScoutFormYourAutoScout24Listing: {
    id: 'SyncWithAutoScoutFormYourAutoScout24Listing',
    defaultMessage: 'your AutoScout24 listing',
  },
  SyncWithAutoScoutFormLastSync: {
    id: 'SyncWithAutoScoutFormLastSync',
    defaultMessage: 'Last sync',
  },
});
