import { defineMessages } from 'react-intl';

export default defineMessages({
  VendorInformationHeaderVendorInformation: {
    id: 'VendorInformationHeaderVendorInformation',
    defaultMessage: 'Vendor information',
  },
  VendorInformationHeaderThisInformationWillBeShownOnGowagoCh: {
    id: 'VendorInformationHeaderThisInformationWillBeShownOnGowagoCh',
    defaultMessage: 'This information will be shown on gowago.ch',
  },
});
