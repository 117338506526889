import { InputLabel, MenuItem, Select, StyledComponentProps, withStyles, WithStyles } from '@material-ui/core';
import classNames from 'classnames';
import { ChangeEvent, FC, FocusEvent, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import ReactTooltip from 'react-tooltip';
import { SelectOption } from 'types';
import { compose } from 'utils/compose';
import { v4 as uuid } from 'uuid';
import { styles } from './RHFSelect.styles';

interface Props {
  name: string;
  options: SelectOption[];
  tooltip?: string;
  placeholder?: string;
  disabled?: boolean;
  touched?: boolean;
  error?: boolean;
  onBlur?: (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  className?: string;
  onChange?: (value: string) => void;
  inputAdornment?: string;
  required?: boolean;
  label?: string;
}

const RHFSelect: FC<Props & WithStyles<typeof styles>> = ({
  options,
  tooltip,
  placeholder,
  onChange,
  onBlur,
  touched,
  error,
  className,
  disabled = false,
  inputAdornment,
  name,
  classes,
  required,
  label,
}) => {
  const [innerValue, setInnerValue] = useState('');
  const tooltipUuid = useMemo(uuid, []);
  const { setValue, watch, register } = useFormContext();
  const value = watch(name);

  const handleChange = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
    const newValue = String(event.target.value);

    setInnerValue(newValue);
    setValue(name, newValue);

    if (onChange) {
      onChange(newValue);
    }
  };

  useEffect(() => {
    if (register) {
      register(name);
    }
  }, [name, register]);

  useEffect(() => {
    if (value !== innerValue) {
      setInnerValue(value);
      if (setValue) {
        setValue(name, value);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, value, setInnerValue, setValue]);

  return (
    <>
      {label && (
        <InputLabel className={classNames(classes.label, { [classes.required]: required })} id={`${name}-placeholder`}>
          {label}
          {required ? ` *` : ''}
        </InputLabel>
      )}
      <div
        data-tip={tooltip}
        data-for={tooltipUuid}
        className={classNames(
          classes.root,
          { [classes.error]: touched && error, [classes.disabledRoot]: disabled },
          className
        )}
      >
        {!!inputAdornment && <div className={classes.inputAdournment}>{inputAdornment}</div>}

        <Select
          name={name}
          onBlur={onBlur}
          onChange={handleChange}
          placeholder={placeholder || ''}
          disabled={disabled}
          disableUnderline
          value={value || ''}
          inputProps={{ inputRef: register }}
          classes={{
            select: classes.select,
          }}
          startAdornment={inputAdornment ? <div className={classes.inputAdournment}>{inputAdornment}</div> : undefined}
        >
          {options.map((option, i) => (
            <MenuItem key={`option-${name}-${i}`} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </div>
      <ReactTooltip key={tooltip} effect="solid" id={tooltipUuid} />
    </>
  );
};

export default compose<Props & StyledComponentProps>(RHFSelect, withStyles(styles, { name: 'RHFSelect' }));
