import { CredentialResponse } from '@react-oauth/google';
import { useDispatch } from 'react-redux';
import { Actions as GlobalActions, GoogleLoginRequestParams } from '../pages/_store/global/actions';

function useGoogleAuthentication() {
  const dispatch = useDispatch();
  const handleLogin = (values: GoogleLoginRequestParams) => dispatch(GlobalActions.googleLoginRequest(values));
  const handleSuccess = async (response: CredentialResponse) => handleLogin({ token: response.credential });

  return {
    handleSuccess,
  };
}

export default useGoogleAuthentication;
