import { CarMakesResponse, Locale, NotificationPropsExtended, UserInfo, Vendor } from 'types';

export interface MainUI {
  isSidePanelVisible: boolean;
}

export enum ResetPasswordErrorType {
  EMAIL_INVALID = 'EMAIL_INVALID',
  TOKEN_INVALID = 'TOKEN_INVALID',
  TOKEN_EXPIRED = 'TOKEN_EXPIRED',
  UNEXPECTED = 'UNEXPECTED',
}

export interface User extends UserInfo {
  locale: Locale;
  isAuthenticated: boolean;
  vendors: Vendor[];
  vendorsCount: string;
}

export interface ConfirmationModalProps {
  message: string;
  okLabel: string;
  closeLabel: string;
  isOpen: boolean;
  callback: (ok: boolean) => void;
}

export interface GlobalAppState {
  mainUI: MainUI;
  user: User;
  carMakes: CarMakesResponse;
  isPasswordRequestFetching: boolean; //TODO: Ignore this in rehydration on redux persist
  isPasswordResetSuccess: boolean;
  isLoginInvalid: boolean;
  isLoginFetching: boolean;
  isLoadingCarMakes: boolean;
  isInitialLoaded: boolean;
  notifications: NotificationPropsExtended[];
  confirmationModal: ConfirmationModalProps;
  resetPasswordError?: ResetPasswordErrorType;
}

export const initialState: GlobalAppState = {
  mainUI: {
    isSidePanelVisible: true,
  },
  user: {
    id: 0,
    locale: 'de-ch',
    isAuthenticated: false,
    email: '',
    firstName: '',
    lastName: '',
    roles: [],
    makes: [],
    status: '',
    vendors: [],
    vendorsCount: '0',
  },
  carMakes: {} as CarMakesResponse,
  isPasswordRequestFetching: false,
  isPasswordResetSuccess: false,
  isLoginInvalid: false,
  isLoginFetching: false,
  isLoadingCarMakes: false,
  notifications: [],
  confirmationModal: {
    message: '',
    okLabel: '',
    closeLabel: '',
    isOpen: false,
    callback: () => {},
  },
  isInitialLoaded: false,
};
