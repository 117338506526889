import classNames from 'classnames';
import TextField from 'components/Forms/TextField';
import { FormikProps } from 'formik';
import { SellerProfileFormData } from 'pages/AccountSettings/pages/SellerProfile/store/types';
import { RootState } from 'pages/_store/root-reducer';
import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { compose } from 'utils/compose';
import styles from './ContactInformation.module.scss';
import Translations from './ContactInformation.translations';

interface Props {
  formikProps: FormikProps<SellerProfileFormData>;
  onChange: () => void;
}

const ContactInformation: FunctionComponent<Props> = ({ formikProps, onChange }) => {
  const { formatMessage } = useIntl();
  const { vendors } = useSelector(
    (state: RootState) => ({
      vendors: state.sellerProfilePage.vendors,
    }),
    shallowEqual
  );

  return (
    <div>
      <div className={styles.title}>
        {formatMessage(Translations.SellerProfileContactInformationContactInformation)}
      </div>
      <div className={styles.form}>
        <div className={styles.formRow}>
          <div className={styles.formControl}>
            <TextField
              name="name"
              type="text"
              required={true}
              labelClassName={styles.label}
              label={formatMessage(Translations.SellerProfileContactInformationName)}
              value={formikProps.initialValues.name}
              onChange={onChange}
            />
          </div>
          <div className={styles.formControl}>
            <TextField
              name="lastName"
              type="text"
              required={true}
              labelClassName={styles.label}
              label={formatMessage(Translations.SellerProfileContactInformationLastName)}
              value={formikProps.initialValues.lastName}
              onChange={onChange}
            />
          </div>
        </div>
        <div className={styles.formRow}>
          <div className={styles.formControl}>
            <TextField
              name="email"
              type="text"
              disabled
              label={formatMessage(Translations.SellerProfileContactInformationEmail)}
              value={formikProps.initialValues.email}
              onChange={onChange}
            />
          </div>
        </div>
        <div className={styles.text}>
          {formatMessage(Translations.SellerProfileContactInformationContactOurSupportTeamToChangeYourEmail)}
        </div>
        {vendors.length > 0 && (
          <>
            <div className={styles.vendorContainer}>
              <div className={styles.vendorTitle}>
                {formatMessage(Translations.SellerProfileContactInformationVendor)}
              </div>
              <div className={styles.divider} />
              {vendors.map((vendor) => (
                <>
                  <div key={vendor} className={styles.vendor}>
                    {vendor}
                  </div>
                  <div className={styles.dividerVendor} />
                </>
              ))}
            </div>
            <div className={classNames(styles.text)}>
              {formatMessage(Translations.SellerProfileContactInformationContactOurSupportTeamToChangeYourVendor)}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default compose<Props>(ContactInformation);
