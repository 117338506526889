import { makeToSlug } from '@carcodex/gow-shared-mono/modules/car/car.constants';
import { FuelTypesDetailed, Make } from '@carcodex/gow-shared-mono/modules/car/car.types';
import { DATE_PICKER_FORMAT, KMType } from 'constants/constants';
import moment from 'moment';
import {
  BackendErrorMessage,
  ConditionType,
  ConsumptionRating,
  Deal,
  DealRequest,
  DrivenWheels,
  FilterListingsDto,
  ListingPublic,
  OptionExtended,
  PollutionNorm,
  possibleKMValues,
  possibleMonthValues,
  PublishBackendError,
  ResidualValue,
  Segments,
  SpecialDealType,
  TransmissionDetailed,
  Vendor,
} from 'types';
import { getDateWithoutTimeUTC } from 'utils/date';
import { getNumberOrNull, getStringValueOrNull, getValueOrUndefined } from 'utils/form';
import { hasOwnPropertyCheck } from 'utils/objects';
import { isNil } from '../../utils/checks';
import { parseInterestRateTo100, parseInterestRateToDecimal } from '../../utils/formatting';
import { AddEditListingSchema, getInitialValues } from './schema';

export enum FormStatus {
  DRAFT,
  PUBLISH,
}

export interface DefaultAddEditListingParams {
  vendorId: string;
  dealId?: string;
}

const getSpecialDealTypeArray = (values: AddEditListingSchema) => {
  const result = [];
  if (values.showInFeaturedDeals) {
    result.push(SpecialDealType.FEATURED);
  }

  if (values.isHotDeal) {
    result.push(SpecialDealType.HOT_DEAL);
  }

  if (values.isDealOfTheMonth) {
    result.push(SpecialDealType.DEAL_OF_THE_MONTH);
  }

  if (values.showOnSalePage) {
    result.push(SpecialDealType.GOWAGO_SALE);
  }

  if (values.isAvailableForFleetDiscount) {
    result.push(SpecialDealType.FLEET_DISCOUNT);
  }

  return result;
};

export const convertSchemaToDealRequest = (values: AddEditListingSchema, vendorId?: number): DealRequest => {
  const hasRegistrationDate = !!values.registrationDateMonth && !!values.registrationDateYear;

  let request: DealRequest = {
    isLockedToSource: values.isLockedToSource,
    isOwnershipDefault: values.isOwnershipDefault,
    isResidualValueDefault: values.isResidualValueDefault,
    residualValues: values.isResidualValueDefault ? undefined : values.residualValues,
    fee: values.monthlyManagementFee,
    businessLeasing: !values.leaseTypePrivate,
    leasingInterestRate: parseInterestRateToDecimal(values.interestRate),
    leasingProvider: values.leasingProvider,
    specialDealType: getSpecialDealTypeArray(values),
    orderGowagoSale: getNumberOrNull(values.orderGowagoSale),
    orderFeatured: getNumberOrNull(values.orderFeatured),
    defaultLeasingPeriod: values.defaultLeasingPeriod ? Number(values.defaultLeasingPeriod) : null,
    defaultLeasingMileage: values.defaultLeasingMileage ? Number(values.defaultLeasingMileage) : null,
    defaultDownPayment: getNumberOrNull(values.defaultDownPayment),
    closedUserGroups: values.closedUserGroups ? [values.closedUserGroups] : null,
    contractType: values.contractType || null,
    listing: {
      make: getMakeFromSlug(values.make),
      model: values.model,
      modelSlug: values.modelSlug,
      engine: values.version,
      modelYear: values.modelYear || null,
      licensingDate: hasRegistrationDate
        ? getDateWithoutTimeUTC(
            new Date(Number(values.registrationDateYear), Number(values.registrationDateMonth || 1) - 1, 1)
          )
        : null,
      conditionTypeDetailed: values.condition as ConditionType,
      km: values.lifetimeMileage,
      lastCheck: values.lastTestDate ? getDateWithoutTimeUTC(new Date(values.lastTestDate)) : undefined,
      vehicleNumber: values.internalId || '',
      typenschein: values.modelDesignationNumber,
      segments: values.bodyShape ? ([values.bodyShape] as Segments[]) : undefined,
      chassisNumber: values.vin,
      warranty: values.guarantee,
      warrantyText: values.guaranteeText ? [values.guaranteeText] : undefined,
      warrantyInKM: getNumberOrNull(values.warrantyInKM),
      warrantyInMonths: getNumberOrNull(values.warrantyInMonths),
      warrantyStartType: values.warrantyStartType,
      warrantyUntilDate: values.warrantyUntilDate
        ? getDateWithoutTimeUTC(new Date(values.warrantyUntilDate))
        : undefined,
      price: values.price,
      listPrice: values.newPrice,
      transmissionDetailed: values.gearboxDetailed as TransmissionDetailed,
      drivenWheels: values.drivenWheels as DrivenWheels,
      fuelTypeDetailed: values.fuelDetailed as FuelTypesDetailed,
      colorOutside: values.exteriorColor,
      colorInside: values.interiorColor,
      seats: values.seats,
      doors: values.doors,
      cylinder: values.cylinders,
      hp: values.horsepower,
      cylinderCapacity: values.engineCapacity,
      co2Emissions: values.co2emissions,
      consumptionCity: values.consumptionCity,
      consumptionCountryside: values.consumptionCountry,
      consumptionTotal: values.consumptionTotal,
      consumptionElectric: values.powerConsumption,
      energyEfficiency: values.efficiencyRating as ConsumptionRating,
      euroNorm: values.euroStandard as PollutionNorm,
      curbWeight: values.tare,
      towingCapacity: values.trailerLoad,
      optionsExtended: values.optionsExtended as OptionExtended[],
      carLocation: values.isVendorCarLocation || values.isLockedToSource ? null : values.carLocation,
      deliveredIn: values.deliveredIn,
      greyImport: values.greyImport,
      batteryCapacity: values.batteryCapacity,
      batteryRange: values.batteryRange,
    },
  };

  if (vendorId) {
    request = {
      ...request,
      listing: {
        ...request.listing,
        vendor: vendorId,
      },
    };
  }

  return request;
};

export const convertDealToSchema = (
  deal: Partial<Deal>,
  dealImages: File[],
  vendor: Vendor | undefined
): AddEditListingSchema => {
  let licensingDateMoment: moment.Moment | undefined = undefined;

  if (deal?.listing?.licensingDate) {
    licensingDateMoment = moment(deal.listing.licensingDate);
  }

  const schema: AddEditListingSchema = {
    make: deal?.listing?.make ? makeToSlug[deal.listing.make as Make] : undefined,
    modelSlug: getValueOrUndefined(deal?.listing?.modelSlug),
    model: getValueOrUndefined(deal?.listing?.model),
    version: getValueOrUndefined(deal?.listing?.engine),
    registrationDateMonth: licensingDateMoment ? (licensingDateMoment.month() + 1).toString() : undefined,
    registrationDateYear: licensingDateMoment ? licensingDateMoment.year().toString() : undefined,
    condition: getValueOrUndefined(deal?.listing?.conditionTypeDetailed),
    lifetimeMileage: getValueOrUndefined(deal.listing?.km),
    modelYear: getValueOrUndefined(deal?.listing?.modelYear),
    lastTestDate: deal.listing?.lastCheck ? moment(deal.listing.lastCheck).format(DATE_PICKER_FORMAT) : undefined,
    modelDesignationNumber: getValueOrUndefined(deal.listing?.typenschein),
    bodyShape: deal?.listing?.segments && deal.listing.segments.length > 0 ? deal.listing.segments[0] : undefined,
    vin: getValueOrUndefined(deal?.listing?.chassisNumber),
    internalId: getValueOrUndefined(deal.listing?.vehicleNumber),
    residualValues: getValueOrUndefined(deal.residualValues),
    deliveredIn: {
      min: deal.listing?.deliveredIn?.min || getInitialValues().deliveredIn.min,
      max: deal.listing?.deliveredIn?.max || getInitialValues().deliveredIn.max,
      unit: deal.listing?.deliveredIn?.unit || getInitialValues().deliveredIn.unit,
    },
    guarantee: getValueOrUndefined(deal.listing?.warranty),
    guaranteeText:
      deal.listing?.warrantyText && deal.listing?.warrantyText.length > 0 ? deal.listing.warrantyText[0] : undefined,
    warrantyInKM: deal.listing?.warrantyInKM,
    warrantyInMonths: deal.listing?.warrantyInMonths,
    warrantyStartType: deal.listing?.warrantyStartType,
    warrantyUntilDate: deal.listing?.warrantyUntilDate
      ? moment(deal.listing.warrantyUntilDate).format(DATE_PICKER_FORMAT)
      : undefined,
    price: getValueOrUndefined(deal.listing?.price),
    newPrice: getValueOrUndefined(deal.listing?.listPrice),
    dealerDeliveryFee: getValueOrUndefined(deal?.dealerDeliveryFee),
    leasingProvider: getValueOrUndefined(deal?.leasingProvider),
    interestRate: !isNil(deal.leasingInterestRate) ? parseInterestRateTo100(deal.leasingInterestRate) : undefined,
    leaseTypeBusiness: true,
    leaseTypePrivate: getValueOrUndefined(!deal?.businessLeasing),
    monthlyManagementFee: getValueOrUndefined(deal?.fee),
    gearboxDetailed: getValueOrUndefined(deal.listing?.transmissionDetailed),
    drivenWheels: getValueOrUndefined(deal.listing?.drivenWheels),
    fuelDetailed: getValueOrUndefined(deal.listing?.fuelTypeDetailed),
    exteriorColor: getValueOrUndefined(deal.listing?.colorOutside),
    interiorColor: getValueOrUndefined(deal.listing?.colorInside),
    seats: getValueOrUndefined(deal.listing?.seats),
    doors: getValueOrUndefined(deal.listing?.doors),
    cylinders: getValueOrUndefined(deal.listing?.cylinder),
    horsepower: getValueOrUndefined(deal.listing?.hp),
    engineCapacity: getValueOrUndefined(deal.listing?.cylinderCapacity),
    co2emissions: getValueOrUndefined(deal.listing?.co2Emissions),
    consumptionCity: getValueOrUndefined(deal.listing?.consumptionCity),
    consumptionCountry: getValueOrUndefined(deal.listing?.consumptionCountryside),
    consumptionTotal: getValueOrUndefined(deal.listing?.consumptionTotal),
    powerConsumption: getValueOrUndefined(deal.listing?.consumptionElectric),
    efficiencyRating: getValueOrUndefined(deal.listing?.energyEfficiency),
    euroStandard: getValueOrUndefined(deal.listing?.euroNorm),
    tare: getValueOrUndefined(deal.listing?.curbWeight),
    trailerLoad: getValueOrUndefined(deal.listing?.towingCapacity),
    isOwnershipDefault: !!deal.isOwnershipDefault,
    isResidualValueDefault: !!deal.isResidualValueDefault,
    optionsExtended: deal.listing?.optionsExtended || [],
    images: dealImages,
    isLockedToSource: !!deal.isLockedToSource,
    showOnSalePage: deal.specialDealType?.includes(SpecialDealType.GOWAGO_SALE),
    showInFeaturedDeals: deal.specialDealType?.includes(SpecialDealType.FEATURED),
    closedUserGroups: deal.closedUserGroups ? deal.closedUserGroups[0] : undefined,
    isDealOfTheMonth: deal.specialDealType?.includes(SpecialDealType.DEAL_OF_THE_MONTH),
    isHotDeal: deal.specialDealType?.includes(SpecialDealType.HOT_DEAL),
    isAvailableForFleetDiscount: deal.specialDealType?.includes(SpecialDealType.FLEET_DISCOUNT),
    orderGowagoSale: getValueOrUndefined(deal.orderGowagoSale),
    orderFeatured: getValueOrUndefined(deal.orderFeatured),
    defaultLeasingPeriod: getStringValueOrNull(deal.defaultLeasingPeriod),
    defaultLeasingMileage: getStringValueOrNull(deal.defaultLeasingMileage),
    defaultDownPayment: getValueOrUndefined(deal.defaultDownPayment),
    isVendorCarLocation: !deal?.listing?.carLocation,
    carLocation: deal?.listing?.carLocation || {
      city: vendor?.city,
      street: vendor?.street,
      zip: vendor?.zip,
    },
    greyImport: !!deal.listing?.greyImport,
    batteryCapacity: getValueOrUndefined(deal.listing?.batteryCapacity),
    batteryRange: getValueOrUndefined(deal.listing?.batteryRange),
    contractType: getValueOrUndefined(deal?.contractType),
  };

  return schema;
};

export const getMakeFromSlug = (make?: string): Make | undefined => {
  if (!make) return undefined;
  let makeKey = '';
  for (const key in makeToSlug) {
    if (hasOwnPropertyCheck(makeToSlug, key)) {
      const value = (makeToSlug as any)[key];
      if (value === make) {
        makeKey = key;
        break;
      }
    }
  }
  return (Make as any)[makeKey];
};

export const convertCarFilterToDeal = (filter: FilterListingsDto) => {
  const deal = {
    isLockedToSource: false,
    isSelectedCar: false,
    isResidualValueDefault: true,
    isOwnershipDefault: true,
    listing: {
      make: filter.make,
      modelSlug: filter.modelSlug,
      model: filter.modelSlug,
      typenschein: filter.typenschein,
    },
  };

  return deal as Partial<Deal>;
};

export const convertCarToDeal = (listing: Partial<ListingPublic>) => {
  const deal = {
    isLockedToSource: false,
    isSelectedCar: true,
    isResidualValueDefault: true,
    isOwnershipDefault: true,
    listing,
  } as Partial<Deal>;

  return deal;
};

export const getMakeSlug = (make?: Make): string | undefined => {
  if (!make) return undefined;

  return makeToSlug[make];
};

export const calculateResidualValueAbsoluteValue = (
  residualValue: number,
  isOnPrice: boolean,
  isOnListPrice: boolean,
  price?: number,
  newPrice?: number
): number | undefined => {
  if (residualValue) {
    if (isOnPrice && price) {
      return (price * residualValue) / 100;
    }

    if (isOnListPrice && newPrice) {
      return (newPrice * residualValue) / 100;
    }
  }
  return undefined;
};

export const calculateResidualValuePercent = (
  value: number,
  isOnPrice: boolean,
  isOnListPrice: boolean,
  price?: number,
  newPrice?: number
): number | undefined => {
  if (isOnPrice && price) {
    return (value * 100) / price;
  }

  if (isOnListPrice && newPrice) {
    return (value * 100) / newPrice;
  }

  return undefined;
};

export const isNextAnnualMileageAvailable = (residualValues: ResidualValue[] = []) =>
  possibleKMValues.length !== residualValues.length;

export const geAvailableKMs = (residualValues: ResidualValue[] = []): KMType[] => {
  return possibleKMValues.filter((kmItem) => {
    return residualValues.findIndex((resItem) => Number(resItem.km) === kmItem) < 0;
  });
};

export const getNextAvailableContractLength = (residualValues: ResidualValue[] = []): number | undefined => {
  const existingMonths = new Set(residualValues.map((r) => Number(r.month)));
  for (const month of possibleMonthValues) {
    if (!existingMonths.has(month)) {
      return month;
    }
  }
  return;
};

type BackendError = Array<BackendErrorMessage> | string | PublishBackendError | { reason: string };

const determineBackendOrPublishError = (toBeDetermined: BackendError): toBeDetermined is PublishBackendError =>
  !!(toBeDetermined as PublishBackendError)?.publishErrors;

const formatObjectError = (error: Record<string, unknown>, separator = ': '): string =>
  Object.entries(error)
    .map(([key, value]) => `${key}${separator}${value}`)
    .join();

const extractPublishErrors = (error: PublishBackendError) => {
  const messages: string[] = [];
  const errorData = error.publishErrors.data;

  Object.entries(errorData).forEach(([errorKey, errorValue]) => {
    if (typeof errorValue !== 'object') {
      messages.push(`${errorKey} - ${errorValue}`);
    } else if (Array.isArray(errorValue)) {
      messages.push(`${errorKey} - ${errorValue.join()}`);
    } else {
      const expected = errorValue.expected ? `EXPECTED ${errorValue.expected};` : '';
      const actual = errorValue.actual ? ` ACTUAL ${errorValue.actual};` : '';
      const reason = errorValue.reason ? ` REASON ${formatObjectError(errorValue.reason)}` : '';

      messages.push(`${errorKey} -${expected}${actual}${reason}`);
    }
  });

  return messages;
};

// extract backend error
export const getErrorMessages = (backendError: BackendError, accumulator: Array<string> = []): Array<string> => {
  let messages: Array<string> = accumulator;

  if (typeof backendError === 'string') {
    return [backendError];
  } else if (determineBackendOrPublishError(backendError)) {
    messages = [...extractPublishErrors(backendError)];
  } else if (Array.isArray(backendError)) {
    backendError?.forEach((messageObject) => {
      if (messageObject?.children?.length) {
        messages = [...messages, ...getErrorMessages(messageObject?.children, messages)];
      } else if (messageObject?.constraints) {
        messages = [...messages, ...Object.values(messageObject?.constraints)];
      }
    });
  } else if (typeof backendError === 'object') {
    messages = [backendError.reason];
  }

  return messages.length ? messages : ['Internal Server Error'];
};
