import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ButtonBase,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  StyledComponentProps,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import { useLazyComponentImport } from 'hooks/useLazyComponentImport';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { compose } from 'utils/compose';
import DialogFloatingCloseButton from './DialogFloatingCloseButton';
import { styles } from './GowDialog.styles';

interface Props {
  title?: string | ReactNode;
  showTitle: boolean;
  idKey: string;
  open: boolean;
  transitionDuration?: number;
  onClose: () => void;
  keepMounted?: boolean;
  withCloseButton?: boolean;
  withTransition?: boolean;
  lazyImport?: {
    importFunc: () => Promise<any>;
    componentParams?: any;
  };
}

const Transition = React.forwardRef(function Transition(props: any, ref: React.Ref<unknown>) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const GowDialog: FC<Props & WithStyles<typeof styles>> = ({
  title,
  showTitle = false,
  idKey,
  children,
  classes,
  transitionDuration,
  open = false,
  keepMounted = false,
  withCloseButton = true,
  withTransition = true,
  onClose,
  lazyImport,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { Component, load } = useLazyComponentImport<any>();

  useEffect(() => {
    if (isOpen && lazyImport) {
      load({
        importFunc: lazyImport.importFunc,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (open !== isOpen) {
      setIsOpen(open);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleCloseDialog = () => {
    onClose();
    setIsOpen(false);
  };

  return (
    <div className={clsx('Dialog', classes.root)}>
      {isOpen && withCloseButton && <DialogFloatingCloseButton onClick={handleCloseDialog} isVisible={isOpen} />}
      <Dialog
        onClose={handleCloseDialog}
        className={clsx('BaseDialog', classes.root)}
        open={isOpen}
        fullWidth
        keepMounted={keepMounted}
        transitionDuration={Number.isInteger(transitionDuration) ? transitionDuration : 300}
        TransitionComponent={withTransition ? (Transition as any) : undefined}
        classes={{
          root: classes.dialogRoot,
          paperScrollPaper: classes.paperScrollPaper,
          scrollPaper: classes.scrollPaper,
        }}
      >
        {!!showTitle && (
          <DialogTitle classes={{ root: classes.titleRoot }}>
            <div className={classes.dialogTitleContainer}>
              {title && <Typography className={classes.dialogTitle}>{title}</Typography>}
              <ButtonBase
                id={`gow-dialog-${idKey}-close-button`}
                className={classes.closeContainer}
                onClick={handleCloseDialog}
              >
                <FontAwesomeIcon icon={faTimes} className={classes.closeIcon} />
              </ButtonBase>
            </div>
          </DialogTitle>
        )}
        <DialogContent classes={{ root: classes.dialogContent }} id={idKey}>
          {lazyImport ? (
            Component ? (
              <Component {...(lazyImport.componentParams || {})} />
            ) : (
              <CircularProgress className={classes.loadingIndicator} />
            )
          ) : (
            children
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default compose<Props & StyledComponentProps>(GowDialog, withStyles(styles, { name: 'Dialog' }));
