import { Reducer } from 'redux';
import { Actions, ActionTypes } from './actions';
import { initialState, VendorInformationState } from './initial-state';

export const vendorInformationReducer: Reducer<VendorInformationState> = (
  state = initialState,
  action: Actions
): VendorInformationState => {
  switch (action.type) {
    case ActionTypes.VIEW_VENDOR_INFO_REQUEST: {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
        isLoading: true,
        isError: false,
      };
    }

    case ActionTypes.VIEW_VENDOR_INFO_SUCCESS: {
      return {
        ...state,
        selectedVendor: action.payload,
        isLoading: false,
        isError: false,
      };
    }
    case ActionTypes.VIEW_VENDOR_INFO_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    }
    case ActionTypes.UPDATE_VENDOR_INFO_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
        successfullyUpdated: false,
      };
    }

    case ActionTypes.UPDATE_VENDOR_INFO_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isError: false,
        successfullyUpdated: true,
      };
    }
    case ActionTypes.UPDATE_VENDOR_INFO_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isError: action.payload.isError,
        successfullyUpdated: false,
      };
    }
    case ActionTypes.HIDE_SUCCESS_ALERT_MESSAGE: {
      return {
        ...state,
        successfullyUpdated: false,
      };
    }

    case ActionTypes.RESET_STATE: {
      return {
        ...initialState,
      };
    }

    default: {
      return state;
    }
  }
};
