import { defineMessages } from 'react-intl';

export default defineMessages({
  MonthYearSelectionMonth: {
    id: 'MonthYearSelectionMonth',
    defaultMessage: 'Month',
  },
  MonthYearSelectionYear: {
    id: 'MonthYearSelectionYear',
    defaultMessage: 'Year',
  },
});
