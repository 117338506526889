import { FunctionComponent } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { compose } from 'utils/compose';
import { routeConstants } from '../constants/routes';
import AccountSettings from './AccountSettings';
import ContactSupport from './ContactSupport';
import EditOrderPage from './EditOrderPage';
import ViewAllDeals from './ViewAllDeals';
import ViewAllOrders from './ViewAllOrders';
import ViewAllVendorOrders from './ViewAllOrders/ViewAllVendorOrders/ViewAllVendorOrders';
import ViewAllVendors from './ViewAllVendors';
import ViewVendorRootPage from './ViewVendorRootPage';

const Routes: FunctionComponent = () => {
  return (
    <Switch>
      <Route
        exact
        path={routeConstants.PROTECTED.VIEW_ORDER_PAGE.route}
        render={() => <EditOrderPage isDocumentsUpload={false} />}
      />
      <Route
        exact
        path={routeConstants.PROTECTED.VIEW_ORDER_FILES_PAGE.route}
        render={() => <EditOrderPage isDocumentsUpload />}
      />
      <Route path={routeConstants.PROTECTED.VIEW_ALL_DEALS_PAGE.route} component={ViewAllDeals} />
      <Route path={routeConstants.PROTECTED.VIEW_ORDERS_PAGE.route} component={ViewAllOrders} />
      <Route exact path={routeConstants.PROTECTED.VIEW_VENDOR_ORDERS.route} component={ViewAllVendorOrders} />
      <Route path={routeConstants.PROTECTED.VIEW_VENDOR_MAIN.route} component={ViewVendorRootPage} />
      <Route path={routeConstants.PROTECTED.VIEW_ALL_VENDORS_PAGE.route} component={ViewAllVendors} />
      <Route path={routeConstants.PROTECTED.ACCOUNT_SETTINGS.route} component={AccountSettings} />
      <Route path={routeConstants.PROTECTED.CONTACT_SUPPORT.route} component={ContactSupport} />
      <Redirect
        to={routeConstants.PROTECTED.VIEW_ALL_VENDORS_PAGE.url({})}
        from={routeConstants.PUBLIC.LOGIN_PAGE.url()}
      />
    </Switch>
  );
};

export default compose(Routes);
