import { IconProp } from '@fortawesome/fontawesome-svg-core';
import classNames from 'classnames';
import React, { FunctionComponent, useState } from 'react';
import { compose } from 'utils/compose';
import FieldErrorLabel from '../FieldErrorLabel';
import Input from '../Input';
import styles from './TextFieldBase.module.scss';

type CustomErrorRenderer = (value: any, error: boolean, dirty: boolean) => React.ReactElement;

interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label: string;
  name: string;
  errorLabel?: string;
  customErrorLabel?: CustomErrorRenderer;
  error?: boolean;
  touched?: boolean;
  startAdornment?: IconProp | string;
  footerLabel?: string;
  required?: boolean;
  labelClassName?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  inputRef?: React.LegacyRef<HTMLInputElement>;
}

const TextFieldBase: FunctionComponent<Props> = ({
  name,
  label,
  error,
  startAdornment,
  footerLabel,
  labelClassName,
  required = false,
  onChange,
  touched,
  errorLabel,
  customErrorLabel,
  inputRef,
  ...props
}) => {
  const [isDirty, setDirty] = useState(false);
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e);
    }
    if (!isDirty) {
      setDirty(true);
    }
  };

  return (
    <div className={styles.root}>
      <label className={classNames(styles.label, labelClassName, { required })}>
        {label}
        {required ? ` *` : ''}
      </label>
      <Input
        {...props}
        name={name}
        startAdornment={startAdornment}
        error={!!(touched && error)}
        touched={touched}
        onChange={handleOnChange}
        inputRef={inputRef}
      />
      <FieldErrorLabel
        dirty={isDirty}
        touched={!!touched}
        value={props.value}
        customErrorLabel={customErrorLabel}
        error={!!error}
        errorLabel={errorLabel || ''}
      />

      {!!footerLabel && <div className={styles.footerLabel}>{footerLabel}</div>}
    </div>
  );
};

export default compose<Props>(TextFieldBase);
