import { createStyles } from '@material-ui/core';
import { GowagoTheme } from 'theme';

export const styles = (theme: GowagoTheme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    selectionTitle: {
      margin: `${theme.gowago.spacing[8]} 0 ${theme.gowago.spacing[8]} 0`,
      boxSizing: 'border-box',
      cursor: 'default',
      color: theme.gowago.text.primary,
      fontSize: theme.gowago.fontSize.smaller,
      fontWeight: 'bold',
    },

    selectionWrapper: {
      display: 'flex',
      marginBottom: theme.gowago.spacing[18],

      '& > div:first-child': {
        paddingRight: theme.gowago.spacing.large,
      },
    },
  });
