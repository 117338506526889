import Alert, { AlertType } from 'components/Alerts/Alert';
import ErrorMessage from 'components/Alerts/ErrorMessage';
import ErrorValidationList from 'components/Alerts/ErrorMessage/components/ErrorValidationList';
import { useFormikContext } from 'formik';
import { RootState } from 'pages/_store/root-reducer';
import { FunctionComponent, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { ErrorValidationListFields } from 'types';
import { compose } from 'utils/compose';
import messages from '../../ChangePassword.translations';
import { ChangePasswordFormValue } from '../../types';
import styles from './FormMessageContainer.module.scss';

const FormMessageContainer: FunctionComponent = () => {
  const { formatMessage } = useIntl();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const { errors, submitCount, setSubmitting, setFieldError, resetForm } = useFormikContext<ChangePasswordFormValue>();
  const { isSuccess, isFetching, errorCode } = useSelector(
    (state: RootState) => state.changePasswordPage,
    shallowEqual
  );
  const errorHeaderMessages: ErrorValidationListFields = {
    newPassword: formatMessage(messages.ChangePasswordNewPasswordErrorHeader, {
      underline: (val: string) => <u>{val}</u>,
    }),
    repeatPassword: formatMessage(messages.ChangePasswordConfirmPasswordErrorHeader, {
      underline: (val: string) => <u>{val}</u>,
    }),
    currentPassword: formatMessage(messages.ChangePasswordCurrentPasswordErrorHeader, {
      underline: (val: string) => <u>{val}</u>,
    }),
  };
  useEffect(() => {
    setSubmitting(isFetching);
  }, [isFetching, setSubmitting]);
  useEffect(() => {
    if (errorCode === 403) {
      setFieldError('currentPassword', formatMessage(messages.ChangePasswordYouProvidedAnIncorrectOldPassword));
    }
  }, [errorCode, setFieldError, formatMessage]);
  useEffect(() => {
    if (isSuccess) {
      resetForm();
    }
    setShowSuccessMessage(isSuccess);
  }, [resetForm, isSuccess]);
  const handleMessageClose = () => {
    setShowSuccessMessage(false);
  };
  return (
    <>
      {showSuccessMessage && (
        <div className={styles.errorMessageCard}>
          <Alert
            type={AlertType.SUCCESS}
            onClose={handleMessageClose}
            message={formatMessage(messages.ChangePasswordYourPasswordHasNowBeenChanged)}
          />
        </div>
      )}
      {submitCount > 0 && Object.keys(errors).length > 0 && (
        <ErrorMessage
          title={formatMessage(messages.ChangePasswordErrorMessageTitle)}
          classes={{
            root: styles.errorMessageCard,
          }}
        >
          <ErrorValidationList errorMessages={errorHeaderMessages} errors={Object.keys(errors)} />
        </ErrorMessage>
      )}
    </>
  );
};

export default compose<{}>(FormMessageContainer);
