import { defineMessages } from 'react-intl';

export default defineMessages({
  DeliveredInDeliveredIn: {
    id: 'DeliveredInDeliveredIn',
    defaultMessage: 'Delivered in',
  },
  DeliveredInTo: {
    id: 'DeliveredInTo',
    defaultMessage: 'to',
  },
  months: {
    id: 'DeliveredInMonths',
    defaultMessage: 'months',
  },
  weeks: {
    id: 'DeliveredInWeeks',
    defaultMessage: 'weeks',
  },
});
