import { VendorQuery, VendorQueryResult } from 'types';

export interface ViewAllVendorsPageState {
  result: VendorQueryResult;
  query: VendorQuery;
  isFetchingResults: boolean;
}

export const initialState: ViewAllVendorsPageState = {
  result: {
    facets: {
      status: [],
    },
    results: [],
    countTotal: 0,
    countFiltered: 0,
  },
  query: {
    $skip: 0,
    $limit: 20,
    vendorStatus: [],
    name: undefined,
    sorting: {},
  },
  isFetchingResults: false,
};
