import { APIError } from 'types';
import { ActionsUnion, createAction } from 'utils/action-utils';

export enum ActionTypes {
  RESET_PASSWORD_REQUEST = '[RESET_PASSWORD_PAGE] RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS = '[RESET_PASSWORD_PAGE] RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE = '[RESET_PASSWORD_PAGE] RESET_PASSWORD_FAILURE',
}

export const Actions = {
  resetPasswordRequest: (email: string) => createAction(ActionTypes.RESET_PASSWORD_REQUEST, email),
  resetPasswordSuccess: () => createAction(ActionTypes.RESET_PASSWORD_SUCCESS),
  resetPasswordFailure: (e: APIError) => createAction(ActionTypes.RESET_PASSWORD_FAILURE, e),
};

export type Actions = ActionsUnion<typeof Actions>;
