import { defineMessages } from 'react-intl';

export default defineMessages({
  ContactSupportContactSupport: {
    id: 'ContactSupportContactSupport',
    defaultMessage: 'Contact support',
  },
  ContactSupportOpenMondayToFriday: {
    id: 'ContactSupportOpenMondayToFriday',
    defaultMessage: 'Open Monday to Friday',
  },
});
