import { getCarMakes } from 'api/carMake';
import { getVendorsOfSeller } from 'api/seller';
import { login, loginGoogle, me, resetPassword } from 'api/user';
import { routeConstants } from 'constants/routes';
import { Action } from 'redux';
import storage from 'redux-persist/lib/storage';
import { fork, put, takeEvery } from 'redux-saga/effects';
import { call } from 'typed-redux-saga';
import { Vendor } from 'types';
import { history } from 'utils/history';
import { Actions as GlobalActions, ActionTypes as GlobalActionTypes } from './actions';
import { ResetPasswordErrorType } from './initial-state';

const ignoreApiFailureActionTypes = [GlobalActionTypes.USER_LOGIN_FAILURE, GlobalActionTypes.USER_ME_FAILURE];

function* globalApiErrorAsync(action: ReturnType<typeof GlobalActions.loginFailure>) {
  const e = action.payload;
  if (e && e.statusCode === 500) {
    yield put(
      GlobalActions.sendNotification({
        message: 'Something went wrong!',
        variant: 'error',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'top',
        },
      })
    );
  }
  if (e && e.statusCode === 401 && e.error === 'Unauthorized') {
    yield put(GlobalActions.userTokenExpire());
  }
}

function* loginAsync(action: ReturnType<typeof GlobalActions.loginRequest>) {
  const { email, password } = action.payload;
  try {
    const loginResp = yield* call(login, email, password);
    yield put(GlobalActions.loginSuccess(loginResp.accessToken));
    const userMeResp = yield* call(me);
    let sellerVendors: Vendor[];
    if (!userMeResp.roles) {
      sellerVendors = yield* call(getVendorsOfSeller, userMeResp.id);
    }
    yield put(GlobalActions.userMeSuccess(userMeResp, sellerVendors));

    heap.identify(userMeResp.email);
    heap.addUserProperties({ role: userMeResp.roles?.[0] });
    heap.addUserProperties({ user_name: `${userMeResp.firstName} ${userMeResp.lastName}` });

    if (sellerVendors?.length === 1) {
      heap.addUserProperties({ vendor: sellerVendors[0].name });
    }
  } catch (e) {
    if (e && e.statusCode === 401) {
      yield put(GlobalActions.loginFailure(e));
    } else {
      yield put(GlobalActions.setUserLoginFetching(false));

      const isString = typeof e?.message === 'string';
      let message = isString ? e.message : e?.message?.[0]?.constraints?.equals;
      message = message || 'Something went wrong!';

      yield put(
        GlobalActions.sendNotification({
          message,
          variant: 'error',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'top',
          },
        })
      );
    }
  }
}

function* googleLoginAsync(action: ReturnType<typeof GlobalActions.googleLoginRequest>) {
  const { token } = action.payload;
  try {
    const loginResp = yield* call(loginGoogle, token);
    yield put(GlobalActions.googleLoginSuccess(loginResp.accessToken));
    const userMeResp = yield* call(me);
    let sellerVendors: Vendor[];
    if (!userMeResp.roles) {
      sellerVendors = yield* call(getVendorsOfSeller, userMeResp.id);
    }
    yield put(GlobalActions.userMeSuccess(userMeResp, sellerVendors));
  } catch (e) {
    if (e && e.statusCode === 401 && e.message === 'User not activated for Google Login.') {
      yield put(
        GlobalActions.sendNotification({
          message: 'User not activated for Google Login. Contact your administrator.',
          variant: 'error',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'top',
          },
        })
      );
    } else if (e && e.statusCode === 404 && e.message === 'User not found') {
      yield put(
        GlobalActions.sendNotification({
          message: 'User not found! Contact your administrator.',
          variant: 'error',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'top',
          },
        })
      );
    } else if (e && e.statusCode === 401) {
      yield put(GlobalActions.googleLoginFailure(e));
    } else {
      yield put(
        GlobalActions.sendNotification({
          message: 'Something went wrong!',
          variant: 'error',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'top',
          },
        })
      );
    }
  }
}

function* userMeAsync() {
  try {
    const userMeResp = yield* call(me);
    let sellerVendors: Vendor[];
    if (!userMeResp.roles) {
      sellerVendors = yield* call(getVendorsOfSeller, userMeResp.id);
    }
    yield put(GlobalActions.userMeSuccess(userMeResp, sellerVendors));
  } catch (e) {
    yield put(GlobalActions.userMeFailure(e));
  }
}

function* userResetPasswordAsync(action: ReturnType<typeof GlobalActions.userResetPasswordRequest>) {
  try {
    yield* call(resetPassword, action.payload);
    yield put(GlobalActions.userResetPasswordSuccess());
  } catch (e) {
    if (e.message === 'Email does not match token') {
      yield put(GlobalActions.userResetPasswordFailure(ResetPasswordErrorType.EMAIL_INVALID));
    } else if (e.message === 'Token not valid') {
      yield put(GlobalActions.userResetPasswordFailure(ResetPasswordErrorType.TOKEN_INVALID));
    } else if (e.message === 'Token expired') {
      yield put(GlobalActions.userResetPasswordFailure(ResetPasswordErrorType.TOKEN_EXPIRED));
    } else {
      yield put(GlobalActions.userResetPasswordFailure(ResetPasswordErrorType.TOKEN_INVALID));
    }
  }
}
function userLogoutAsync() {
  setTimeout(() => {
    storage.removeItem('persist:root');
  }, 1000);
  history.push(routeConstants.PUBLIC.LOGIN_PAGE.url());
}

function* watchLoginRequestAsync() {
  yield takeEvery(GlobalActionTypes.USER_LOGIN_REQUEST, loginAsync);
}

function* watchGoogleLoginRequestAsync() {
  yield takeEvery(GlobalActionTypes.GOOGLE_USER_LOGIN_REQUEST, googleLoginAsync);
}

function* watchUserResetPasswordAsync() {
  yield takeEvery(GlobalActionTypes.USER_RESET_PASSWORD_REQUEST, userResetPasswordAsync);
}

function* watchUserMeRequstAsync() {
  yield takeEvery(GlobalActionTypes.USER_ME_REQUEST, userMeAsync);
}

function* watchUserLogoutAsync() {
  yield takeEvery(GlobalActionTypes.USER_LOGOUT, userLogoutAsync);
}

function* watchGlobalApiErrorAsync() {
  yield takeEvery(
    (action: Action) =>
      action.type.includes('_FAILURE') &&
      ignoreApiFailureActionTypes.every((fragment) => !action.type.includes(fragment)),
    globalApiErrorAsync
  );
}

function* getCarMakesRequestAsync(action: ReturnType<typeof GlobalActions.getCarMakesRequest>) {
  try {
    const carMakesResponse = yield* call(getCarMakes);
    yield put(GlobalActions.getCarMakesSuccess(carMakesResponse));
  } catch (e) {
    yield put(GlobalActions.getCarMakesFailure());
  }
}

function* watchGetCarMakesRequestAsync() {
  yield takeEvery(GlobalActionTypes.GET_CAR_MAKES_REQUEST, getCarMakesRequestAsync);
}

export default [
  fork(watchLoginRequestAsync),
  fork(watchGoogleLoginRequestAsync),
  fork(watchUserMeRequstAsync),
  fork(watchUserResetPasswordAsync),
  fork(watchUserLogoutAsync),
  fork(watchGlobalApiErrorAsync),
  fork(watchGetCarMakesRequestAsync),
];
