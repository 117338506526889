import { APIError, OrdersQuery, OrdersQueryResult } from 'types';
import { ActionsUnion, createAction } from 'utils/action-utils';

export enum ActionTypes {
  GET_ORDERS_REQUEST = '[ORDERS] GET_ORDERS_REQUEST',
  GET_ORDERS_SUCCESS = '[ORDERS] GET_ORDERS_SUCCESS',
  GET_ORDERS_FAILURE = '[ORDERS] GET_ORDERS_FAILURE',
  GET_VENDOR_ORDERS_REQUEST = '[ORDERS] GET_VENDOR_ORDERS_REQUEST',
  GET_VENDOR_ORDERS_SUCCESS = '[ORDERS] GET_VENDOR_ORDERS_SUCCESS',
  GET_VENDOR_ORDERS_FAILURE = '[ORDERS] GET_VENDOR_ORDERS_FAILURE',
}

export const Actions = {
  getOrdersRequest: (query: Partial<OrdersQuery>) => createAction(ActionTypes.GET_ORDERS_REQUEST, query),
  getOrdersSuccess: (result: OrdersQueryResult) => createAction(ActionTypes.GET_ORDERS_SUCCESS, result),
  getOrdersFailure: (e: APIError) => createAction(ActionTypes.GET_ORDERS_FAILURE, e),
  getVendorOrdersRequest: (vendorId: number, query: Partial<OrdersQuery>) =>
    createAction(ActionTypes.GET_VENDOR_ORDERS_REQUEST, { vendorId, query }),
  getVendorOrdersSuccess: (result: OrdersQueryResult) => createAction(ActionTypes.GET_VENDOR_ORDERS_SUCCESS, result),
  getVendorOrdersFailure: (e: APIError) => createAction(ActionTypes.GET_VENDOR_ORDERS_FAILURE, e),
  noActionWithoutPayload: () => createAction('NO_ACTION_WITHOUT_PAYLOAD'),
};

export type Actions = ActionsUnion<typeof Actions>;
