import { makeStyles } from '@material-ui/core';
import { theme } from 'theme';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  pickerRoot: {
    borderRadius: 3,
    color: theme.gowago.white,
    minHeight: 37,
    border: theme.gowago.borders.lightGray,
    padding: '2px 8px',
    margin: 0,
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  noIcon: {
    '&> div > div': {
      display: 'none',
    },
  },
  required: {
    fontWeight: 700,
  },
  label: {
    display: 'inline-block',
    marginBottom: '0.5rem',
    boxSizing: 'border-box',
    cursor: 'default',
    color: theme.gowago.text.primary,
    fontSize: 14,
    lineHeight: '19px',
  },
  adormentIcon: {
    fontSize: theme.gowago.spacing.standard,
    lineHeight: theme.gowago.lineHeight.rem.standard,
    padding: 0,
  },
  inputRoot: {
    fontSize: theme.gowago.spacing.standard,
    color: theme.gowago.text.primary,
    '&::before': {
      border: 'none',
    },
  },
  disabled: {
    backgroundColor: '#E9ECEF',
  },
  inputDisabled: {
    color: 'rgba(0, 0, 0, 0.38)',
  },
});
