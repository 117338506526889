export const emptyStringToNull = (value: string, originalValue: string) => {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null;
  }
  return value;
};

export const getMaxLengthString = ({
  value,
  maxLength,
  fromBehind,
}: {
  value: string;
  maxLength: number;
  fromBehind?: boolean;
}) => {
  const totalLength = value.length;
  if (totalLength <= maxLength) {
    return value;
  }

  if (fromBehind) {
    const difference = totalLength - maxLength;
    return `...${value.substring(difference + 3)}`;
  }

  return `${value.substr(0, maxLength - 3)}...`;
};

export const capitalize = (string: string) => string && string.charAt(0).toUpperCase() + string.slice(1);
