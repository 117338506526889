export interface ResetPasswordPageState {
  isPasswordRequestFetching: boolean;
  email: string;
  isRequestSuccess: boolean;
}

export const initialState: ResetPasswordPageState = {
  isPasswordRequestFetching: false,
  email: '',
  isRequestSuccess: false,
};
