import { createStyles } from '@material-ui/core';
import { GowagoTheme } from 'theme';

export const styles = (theme: GowagoTheme) =>
  createStyles({
    submitWrapper: {
      display: 'flex',
      alignItems: 'center',
      marginTop: theme.gowago.spacing.rem,
    },

    submitButton: {
      ...theme.gowago.baseButton(theme.gowago.colors.primaryBlue, theme),
    },

    addInfoManually: {
      color: theme.gowago.colors.primaryBlue,
      fontSize: theme.gowago.fontSize.smaller,
      paddingLeft: theme.gowago.spacing.large,
      margin: 0,
      cursor: 'pointer',

      '&:hover': {
        textDecoration: 'underline',
      },
    },
  });
