import { SellerPublic, SellerStatus } from 'types';
import { ActionsUnion, createAction } from 'utils/action-utils';

export enum ActionTypes {
  GET_SELLERS_REQUEST = '[MANAGE_PEOPLE] GET_SELLERS_REQUEST_REQUEST',
  GET_SELLERS_SUCCESS = '[MANAGE_PEOPLE] GET_SELLERS_SUCCESS',
  GET_SELLERS_FAILURE = '[MANAGE_PEOPLE] GET_SELLERS_FAILURE',
  SEND_SELLER_SIGNUP_REQUEST_REQUEST = '[MANAGE_PEOPLE] SEND_SELLER_SIGNUP_REQUEST_REQUEST',
  SEND_SELLER_SIGNUP_REQUEST_SUCCESS = '[MANAGE_PEOPLE] SEND_SELLER_SIGNUP_REQUEST_SUCCESS',
  SEND_SELLER_SIGNUP_REQUEST_FAILURE = '[MANAGE_PEOPLE] SEND_SELLER_SIGNUP_REQUEST_FAILURE',
  HIDE_SUCCESS_MESSAGE = '[MANAGE_PEOPLE] HIDE_SUCCESS_MESSAGE',
}

export const Actions = {
  getSellersRequest: (vendorId: number) => createAction(ActionTypes.GET_SELLERS_REQUEST, vendorId),
  getSellersSuccess: (sellers: SellerPublic[]) => createAction(ActionTypes.GET_SELLERS_SUCCESS, sellers),
  getSellersFailure: () => createAction(ActionTypes.GET_SELLERS_FAILURE),
  sendSellerSignupRequestRequest: (vendorId: number, sellerId: number, currentStatus: SellerStatus) =>
    createAction(ActionTypes.SEND_SELLER_SIGNUP_REQUEST_REQUEST, {
      vendorId,
      sellerId,
      currentStatus,
    }),
  sendSellerSignupRequestSuccess: (updatedSellers: SellerPublic[]) =>
    createAction(ActionTypes.SEND_SELLER_SIGNUP_REQUEST_SUCCESS, updatedSellers),
  sendSellerSignupRequestFailure: () => createAction(ActionTypes.SEND_SELLER_SIGNUP_REQUEST_FAILURE),
  hideSuccessMessage: () => createAction(ActionTypes.HIDE_SUCCESS_MESSAGE),
};

export type Actions = ActionsUnion<typeof Actions>;
