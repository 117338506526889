import { darken, makeStyles } from '@material-ui/core';
import { theme } from 'theme';

const {
  gowago: { spacing },
} = theme;

export const useStyles = makeStyles({
  root: {
    width: '100%',
    position: 'relative',
  },
  formRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formColumn: {
    maxWidth: '32%',
    flexBasis: '32%',
  },
  divider: {
    boxSizing: 'border-box',
    height: 1,
    width: '100%',
    border: '1px solid #000000',
    opacity: '0.1',
    margin: spacing.large,
  },
  actions: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  navLink: {
    textDecoration: 'none',
  },
  button: {
    background: theme.palette.secondary.main,
    color: theme.gowago.white,
    textTransform: 'none',
    height: 42,
    minWidth: 100,
    fontFamily: theme.gowago.fontFamily,
    fontSize: theme.gowago.fontSize.standard,
    marginLeft: theme.gowago.spacing.rem,

    '&:disabled': {
      background: 'lightgray',
      color: 'gray',
    },

    '&:hover': {
      background: darken(theme.palette.secondary.main, 0.1),
    },
  },
  deactivateBtn: {
    background: theme.palette.error.main,
    marginRight: theme.gowago.spacing.rem,
    marginLeft: 0,

    '&:hover': {
      background: darken(theme.palette.error.main, 0.1),
    },
  },
  reactivateBtn: {
    background: theme.palette.success.main,
    marginRight: 'auto',
    marginLeft: 0,

    '&:hover': {
      background: darken(theme.palette.success.main, 0.1),
    },
  },
  submitBtn: {
    background: theme.palette.primary.main,
    color: theme.gowago.white,
    textTransform: 'none',
    fontFamily: theme.gowago.fontFamily,
    fontSize: theme.gowago.fontSize.standard,
    height: 42,
    minWidth: 100,
    marginLeft: theme.gowago.spacing.rem,

    '&:disabled': {
      background: 'lightgray',
      color: 'gray',
    },

    '&:hover': {
      background: darken(theme.palette.primary.main, 0.1),
    },
  },
  btnSpinner: {
    color: theme.gowago.white,
  },
});
