import { StyledComponentProps, withStyles, WithStyles } from '@material-ui/core';
import GowDialog from 'components/GowDialog';
import { FC, ReactNode } from 'react';
import { compose } from 'utils/compose';
import { optionalFunc } from 'utils/functions';
import { styles } from './ConfirmationDialog.styles';

interface Props {
  key: string;
  open: boolean;
  title?: ReactNode;
  description: ReactNode;
  yesLabel?: ReactNode;
  noLabel?: ReactNode;
  onYes: () => void;
  onNo?: () => void;
  onClose: () => void;
}

const ConfirmationDialog: FC<Props & WithStyles<typeof styles>> = ({
  classes,
  key,
  open,
  title,
  description,
  yesLabel,
  noLabel,
  onYes,
  onNo,
  onClose,
}) => {
  const handleDialogAction = (callback: () => void) => () => {
    optionalFunc(callback, undefined);
    onClose();
  };

  return (
    <GowDialog
      open={open}
      showTitle={!!title}
      title={title}
      idKey={`confirmation-dialog-${key}`}
      onClose={onClose}
      withCloseButton={false}
      withTransition={false}
      classes={{
        paperScrollPaper: classes.dialogPaperScrollPaper,
        dialogContent: classes.dialogContent,
      }}
      lazyImport={{
        importFunc: () => import('./ConfirmationDialogContent'),
        componentParams: {
          description,
          yesLabel,
          noLabel,
          onYes: handleDialogAction(onYes),
          onNo: handleDialogAction(onNo),
        },
      }}
    />
  );
};

export default compose<Props & StyledComponentProps>(
  ConfirmationDialog,
  withStyles(styles, { name: 'ConfirmationDialog' })
);
