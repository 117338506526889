import classNames from 'classnames';
import { FunctionComponent, ReactNode } from 'react';
import { compose } from 'utils/compose';
import styles from './Card.module.scss';

interface Props {
  title?: ReactNode;
  subTitle?: string;
  className?: string;
  classes?: {
    root?: string;
    body?: string;
    header?: string;
    title?: string;
  };
}

const Card: FunctionComponent<Props> = ({ title, subTitle, className, children, classes }) => {
  return (
    <div className={classNames(styles.root, className, classes?.root)}>
      {(!!title || !!subTitle) && (
        <div className={classNames(styles.header, classes?.header)}>
          {!!title && <div className={classNames(styles.title, classes?.title)}>{title}</div>}
          {!!subTitle && <div className={styles.subTitle}>{subTitle}</div>}
        </div>
      )}
      <div className={classNames(styles.body, classes?.body)}>{children}</div>
    </div>
  );
};

export default compose<Props>(Card);
