import { faImage } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress, StyledComponentProps, withStyles, WithStyles } from '@material-ui/core';
import clsx from 'clsx';
import { Actions as AppActions } from 'pages/_store/global/actions';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { NotificationProps } from 'types';
import { compose } from 'utils/compose';
import FilePreview from '../FilePreview';
import { FileUploadErrorTypes } from '../FileUpload.utils';
import FileUploadDnD from '../FileUploadDnD';
import { styles } from './FileUploadDnDPlaceholder.styles';
import Translations from './FileUploadDnDPlaceholder.translations';

interface Props {
  onChange: (files: File[]) => void;
  onLoad?: (src: string) => void;
  selectedFiles?: Array<File>;
  isLoading?: boolean;
  disabled?: boolean;
  name: string;
  allowedTypes?: string[];
  acceptsTypes?: string[];
}

const FileUploadDnDPlaceholder: FC<Props & WithStyles<typeof styles>> = ({
  classes,
  onChange,
  onLoad,
  selectedFiles,
  isLoading = false,
  disabled = false,
  allowedTypes,
  acceptsTypes,
  name,
}) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const handleRenderFilePreview = (files: File[]) => (
    <FilePreview onLoad={onLoad} classes={{ root: classes.filePreviewRoot }} files={files} height={146} width={234} />
  );

  const handleOnChange = (files: File[]) => {
    if (onChange) {
      onChange(files);
    }
  };

  const handleOnError = (type: FileUploadErrorTypes) => {
    const notificationProps: Partial<NotificationProps> = {
      variant: 'error',
      anchorOrigin: {
        horizontal: 'center',
        vertical: 'top',
      },
    };
    switch (type) {
      case FileUploadErrorTypes.FORMAT:
        dispatch(
          AppActions.sendNotification({
            message: formatMessage(Translations.FileUploadDnDPlaceholderUnsupportedFileFormat),
            ...notificationProps,
          })
        );
        break;
      case FileUploadErrorTypes.SIZE:
        dispatch(
          AppActions.sendNotification({
            message: formatMessage(Translations.FileUploadDnDPlaceholderTheSelectedFileIsTooLarge),
            ...notificationProps,
          })
        );
        break;
      default:
        break;
    }
  };

  return (
    <div className={clsx('FileUploadDnDPlaceholder', classes.root)}>
      <FileUploadDnD
        name={name}
        disabled={disabled}
        classes={{
          root: classes.fileUploadDnDRoot,
        }}
        allowedTypes={allowedTypes}
        acceptsTypes={acceptsTypes}
        isLoading={isLoading}
        selectedFiles={selectedFiles}
        onChange={handleOnChange}
        fileUploadInputProps={{
          renderFilePreview: handleRenderFilePreview,
          children: !isLoading ? <FontAwesomeIcon icon={faImage} className={classes.icon} /> : <CircularProgress />,
          classes: {
            root: classes.fileUploadInputRoot,
            rootActive: clsx(classes.fileUploadInputRootActive, {
              [classes.fileUploadInputRootActiveDisabled]: disabled,
            }),
            content: classes.fileUploadInputContent,
          },
        }}
        onError={handleOnError}
      />
    </div>
  );
};

export default compose<Props & StyledComponentProps>(
  FileUploadDnDPlaceholder,
  withStyles(styles, { name: 'FileUploadDnDPlaceholder' })
);
