import { defineMessages } from 'react-intl';

export default defineMessages({
  AddEditListingsNotificationsListingWasSaved: {
    id: 'AddEditListingsNotificationsListingWasSaved',
    defaultMessage: 'Listing was saved',
  },
  AddEditListingsNotificationsSomethingWentWrong: {
    id: 'AddEditListingsNotificationsSomethingWentWrong',
    defaultMessage: 'Something went wrong',
  },
  AddEditListingsNotificationsIfThisIssuePersistPleaseContactOurSupport: {
    id: 'AddEditListingsNotificationsIfThisIssuePersistPleaseContactOurSupport',
    defaultMessage: 'If this issue persists, please contact our support team for assistance',
  },
  AddEditListingsNotificationsListingCouldNotBePublishedDueTo: {
    id: 'AddEditListingsNotificationsListingCouldNotBePublishedDueTo',
    defaultMessage: 'Listing could not be published due to the following errors',
  },
  AddEditListingsNotificationsListingCouldNotBeSavedToDraft: {
    id: 'AddEditListingsNotificationsListingCouldNotBeSavedToDraft',
    defaultMessage: 'Listing could not be saved to draft due to the following errors',
  },
  AddEditListingsNotificationsMustBeProvided: {
    id: 'AddEditListingsNotificationsMustBeProvided',
    defaultMessage: '{field} must be provided',
  },
  AddEditListingsNotificationsMustBeWithinThePermittedRange: {
    id: 'AddEditListingsNotificationsMustBeWithinThePermittedRange',
    defaultMessage: '{field} must be within the permitted range',
  },
  AddEditListingsNotificationsFieldMustMeetTheRequiredCriteria: {
    id: 'AddEditListingsNotificationsFieldMustMeetTheRequiredCriteria',
    defaultMessage: '{field} must meet the required criteria',
  },
  AddEditListingsNotificationsSyncWithAutoScout24WasDisabled: {
    id: 'AddEditListingsNotificationsSyncWithAutoScout24WasDisabled',
    defaultMessage: 'Sync with AutoScout24 was disabled',
  },
  AddEditListingsNotificationsSyncWithAutoScout24WasEnabled: {
    id: 'AddEditListingsNotificationsSyncWithAutoScout24WasEnabled',
    defaultMessage: 'Sync with AutoScout24 was enabled',
  },
  AddEditListingsNotificationsAgeMustBeLessThanMonths: {
    id: 'AddEditListingsNotificationsAgeMustBeLessThanMonths',
    defaultMessage: 'Age must be less than {maxAgeInMonths} months',
  },
  AddEditListingsNotificationsPriceMustBeLessThan: {
    id: 'AddEditListingsNotificationsPriceMustBeLessThan',
    defaultMessage: 'Price must be less than {price}',
  },
  AddEditListingsNotificationsPriceMustBeGreaterThan: {
    id: 'AddEditListingsNotificationsPriceMustBeGreaterThan',
    defaultMessage: 'Price must be greater than {price}',
  },
  AddEditListingsNotificationsListPriceMustBeLessThan: {
    id: 'AddEditListingsNotificationsListPriceMustBeLessThan',
    defaultMessage: 'List price must be less than {price}',
  },
  AddEditListingsNotificationsListPriceMustBeGreaterThan: {
    id: 'AddEditListingsNotificationsListPriceMustBeGreaterThan',
    defaultMessage: 'List price must be greater than {price}',
  },
  AddEditListingsNotificationsTheListingWithTheSameParametersHasAlreadyBeenAdded: {
    id: 'AddEditListingsNotificationsTheListingWithTheSameParametersHasAlreadyBeenAdded',
    defaultMessage: 'Listing with the same parameters has already been added',
  },
});
