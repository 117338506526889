export interface ChangePasswordState {
  isFetching: boolean;
  errorCode: number | null;
  isSuccess: boolean;
}

export interface ChangePasswordParams {
  newPassword: string;
  currentPassword: string;
  repeatPassword: string;
}

export const initialState: ChangePasswordState = {
  isFetching: false,
  errorCode: null,
  isSuccess: false,
};
