import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useField, useFormikContext } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import { compose } from 'utils/compose';
import TextArea from '../TextArea';
import styles from './TextAreaField.module.scss';

interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
  label: string;
  name: string;
  errorLabel?: string;
  startAdornment?: IconProp | string;
  footerLabel?: string;
  rows?: number;
  tooltip?: string;
  showErrorOnlyOnSubmit?: boolean;
}

const TextAreaField: FC<Props> = ({
  name,
  label,
  errorLabel,
  startAdornment,
  footerLabel,
  rows,
  tooltip,
  showErrorOnlyOnSubmit,
  ...props
}) => {
  const [field, meta] = useField(name);
  const { submitCount, setFieldValue } = useFormikContext();
  const [isDirty, setDirty] = useState(false);
  const [value, setValue] = useState(field.value);

  const isRenderError = () => {
    if (showErrorOnlyOnSubmit) {
      return submitCount > 0 && !!(meta.touched && meta.error);
    } else {
      return !!(meta.touched && meta.error);
    }
  };

  useEffect(() => {
    if (!value && field.value && !isDirty) {
      setValue(field.value);
    }
  }, [field.value, value, isDirty]);

  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);

    if (!isDirty) {
      setDirty(true);
    }
  };

  const handleOnBlur = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFieldValue(name, value);
  };

  return (
    <div className={styles.root}>
      <label className={styles.label}>{label}</label>
      <TextArea
        {...props}
        {...field}
        value={value}
        startAdornment={startAdornment}
        error={!!(meta.touched && meta.error)}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        touched={meta.touched}
        rows={rows}
        tooltip={tooltip}
      />
      {isRenderError() && <div className={styles.errorLabel}>{meta.error}</div>}
      {!!footerLabel && <div className={styles.footerLabel}>{footerLabel}</div>}
    </div>
  );
};

export default compose<Props>(TextAreaField);
