import { Deal, DealRequest, DealStatus, SourceType } from 'types';

export const createDuplicateDealRequest = ({ deal, vendor }: { deal: Deal; vendor: number }) => {
  const {
    id,
    publicId,
    residualValues,
    residualValueCalculation,
    isLockedToSource,
    listing: {
      createdAt,
      updatedAt,
      id: listingId,
      source,
      sourceCreated,
      sourceChecked,
      sourceUpdated,
      externalUrl,
      externalId,
      aio,
      ...listingUpdateFields
    },
    ...dealUpdateFields
  } = deal;

  const dealRequest: DealRequest = {
    ...dealUpdateFields,
    isOwnershipDefault: true,
    isResidualValueDefault: true,
    status: DealStatus.DRAFT,
    isLockedToSource: false,
    listing: {
      ...listingUpdateFields,
      vendor,
      source: SourceType.MANUAL,
    },
  };

  return dealRequest;
};
