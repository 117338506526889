import { defineMessages } from 'react-intl';

export default defineMessages({
  ManagePeopleHeaderManagePeople: {
    id: 'ManagePeopleHeaderManagePeople',
    defaultMessage: 'Manage people',
  },
  ManagePeopleHeaderInviteTeamMembersToJoinTheGowagoSalesPortal: {
    id: 'ManagePeopleHeaderInviteTeamMembersToJoinTheGowagoSalesPortal',
    defaultMessage: 'Invite {vendor} team members to join the gowago.ch Sales Portal',
  },
});
