import { filterListings } from 'api/auto-i';
import { createDeal, getDeal, getImages, getResidualValues, updateDeal, uploadImages } from 'api/deals';
import { MAX_AGE, MAX_PRICE, MIN_PRICE } from 'constants/constants';
import { routeConstants } from 'constants/routes';
import moment from 'moment';
import { RootState } from 'pages/_store/root-reducer';
import { fork, put, takeEvery } from 'redux-saga/effects';
import { call, select } from 'typed-redux-saga';
import {
  Deal,
  DealRequest,
  DealStatus,
  DealUpdateStatuses,
  DeleteReason,
  ErrorObject,
  GowagoError,
  ImageGalleryElement,
  ResidualValue,
  SourceType,
} from 'types';
import { createDuplicateDealRequest } from 'utils/duplicate-deal';
import { hasOwnPropertyCheck } from 'utils/objects';
import { getErrorMessages } from '../utils';
import { Actions as AddEditListingsActions, ActionTypes as AddEditListingsActionTypes } from './actions';
import { ErrorType } from './initial-state';

function* getDealRequestAsync(action: ReturnType<typeof AddEditListingsActions.getDealRequest>) {
  const dealId = action.payload;

  try {
    const deal = yield* call(getDeal, dealId);
    yield put(AddEditListingsActions.getDealSuccess(deal));

    yield put(AddEditListingsActions.setIsLoadingImages(true));
    const dealImages = yield loadImages(deal.listing.images);
    yield put(AddEditListingsActions.setDealImages(dealImages));
  } catch (e) {
    yield put(AddEditListingsActions.getDealFailure());
  }
}

function* watchGetDealRequestAsync() {
  yield takeEvery(AddEditListingsActionTypes.GET_DEAL_REQUEST, getDealRequestAsync);
}

function* getResidualValuesRequestAsync(action: ReturnType<typeof AddEditListingsActions.getResidualValuesRequest>) {
  try {
    const residualValues = yield* call(getResidualValues, action.payload);
    yield put(AddEditListingsActions.getResidualValuesSuccess(residualValues));
  } catch (e) {
    yield put(AddEditListingsActions.getResidualValuesFailure());
  }
}

function* watchGetResidualValuesRequestAsync() {
  yield takeEvery(AddEditListingsActionTypes.GET_RESIDUAL_VALUES_REQUEST, getResidualValuesRequestAsync);
}

function* moveToTrashRequestAsync(action: ReturnType<typeof AddEditListingsActions.moveToTrashRequest>) {
  if (!action.payload) {
    yield put(AddEditListingsActions.resetState());
    window.location.href = '/';
    return;
  }
  try {
    const id = Number(action.payload);

    const data: Partial<DealRequest> = {
      status: DealStatus.TRASH,
      deleted: new Date(),
      deleteReason: DeleteReason.MANUALLY_REMOVED,
    };

    const response = yield* call(updateDeal, id, data);
    if (response.status === DealUpdateStatuses.UPDATED) {
      yield put(AddEditListingsActions.moveToTrashSuccess());
    } else {
      throw new Error(`Move to trash retrieved status: ${DealUpdateStatuses.FAILED}`);
    }
  } catch (e) {
    yield put(AddEditListingsActions.moveToTrashFailure());
  }
}

function* watchMoveToTrashRequestAsync() {
  yield takeEvery(AddEditListingsActionTypes.MOVE_TO_TRASH_REQUEST, moveToTrashRequestAsync);
}

function* publishRequestAsync(action: ReturnType<typeof AddEditListingsActions.publishRequest>) {
  try {
    const id = action.payload.id;
    const images = action.payload.images;

    let data: DealRequest = {
      ...action.payload.data,
      status: DealStatus.PUBLISHED,
    };

    data = transformDealOwnership(data);

    const { deal, dealImages, validationError } = yield createOrUpdateDeal(id, data, images);
    if (!validationError) {
      yield put(AddEditListingsActions.publishSuccess(deal, dealImages));
    }
  } catch (e) {
    yield put(AddEditListingsActions.publishFailure(getErrorMessages(e?.data || e?.message)));
  }
}

function* watchPublishRequestAsync() {
  yield takeEvery(AddEditListingsActionTypes.PUBLISH_REQUEST, publishRequestAsync);
}

function* getCarsRequestAsync(action: ReturnType<typeof AddEditListingsActions.getCarsRequest>) {
  try {
    const { data } = action.payload || {};
    const response = yield* call(filterListings, data);

    yield put(AddEditListingsActions.getCarsSuccess(response));
  } catch (e) {
    yield put(AddEditListingsActions.getCarsFailure());
  }
}

function* watchGetCarsRequestAsync() {
  yield takeEvery(AddEditListingsActionTypes.GET_CARS_REQUEST, getCarsRequestAsync);
}

function* saveDraftRequestAsync(action: ReturnType<typeof AddEditListingsActions.saveDraftRequest>) {
  try {
    const id = action.payload.id;
    const images = action.payload.images;
    const vendorId = action.payload.vendorId;

    let data: DealRequest = {
      ...action.payload.data,
      listing: {
        ...action.payload.data.listing,
        vendor: vendorId,
      },
      status: DealStatus.DRAFT,
    };

    data = transformDealOwnership(data);
    const { deal, dealImages, validationError } = yield createOrUpdateDeal(id, data, images);

    if (!validationError) {
      yield put(AddEditListingsActions.saveDraftSuccess(deal, dealImages));
    }
  } catch (e) {
    yield put(AddEditListingsActions.saveDraftFailure(getErrorMessages(e?.message)));
  }
}

function* watchSaveDraftRequestAsync() {
  yield takeEvery(AddEditListingsActionTypes.SAVE_DRAFT_REQUEST, saveDraftRequestAsync);
}

function* updateLockedToSourceRequestAsync(
  action: ReturnType<typeof AddEditListingsActions.updateLockedToSourceRequest>
) {
  try {
    const isLockedToSource = action.payload;

    const deal = (yield* select((state: RootState) => state.addEditListingsPage.deal)) as Deal;

    const { id } = deal;
    // TODO: Do we need to send more data in case the user changed something?
    const data: Partial<DealRequest> = {
      isLockedToSource,
    };
    const response = yield* call(updateDeal, id, data);
    if (response.status === DealUpdateStatuses.FAILED) {
      throw new Error(`Deal update failed`);
    }

    deal.isLockedToSource = isLockedToSource;

    yield put(AddEditListingsActions.updateLockedToSourceSuccess(deal));
  } catch (e) {
    yield put(AddEditListingsActions.updateLockedToSourceFailure());
  }
}

function* watchUpdateLockedToSourceRequestAsync() {
  yield takeEvery(AddEditListingsActionTypes.UPDATE_LOCKED_TO_SOURCE_REQUEST, updateLockedToSourceRequestAsync);
}

function* uploadNewImagesAsync(action: ReturnType<typeof AddEditListingsActions.uploadNewImagesRequest>) {
  const { id, images } = action.payload;

  try {
    const uploadedImages = yield* uploadNewImages(id, images);
    if (uploadedImages) {
      yield put(AddEditListingsActions.uploadNewImagesSuccess(uploadedImages));
    }
  } catch (e) {
    yield put(AddEditListingsActions.uploadNewImagesFailure());
  }
}

function* uploadNewImages(id: number, images: File[]) {
  if (images?.length) {
    const imagesForUpload = images.filter((i) => hasOwnPropertyCheck(i, 'path'));
    if (imagesForUpload.length) {
      return yield* call(uploadImages, id, imagesForUpload);
    }
  }
}

function* watchUploadNewImages() {
  yield takeEvery(AddEditListingsActionTypes.UPLOAD_NEW_IMAGES_REQUEST, uploadNewImagesAsync);
}

function* loadImages(images?: ImageGalleryElement[]) {
  if (!images) {
    return [];
  }

  const dealImages: File[] = [];

  if (images) {
    const result = yield* call(getImages, images);
    dealImages.push(...result);
  }

  return dealImages;
}

function* validateData(deal: DealRequest) {
  if (deal.listing.licensingDate) {
    const licensingDate = moment(deal.listing.licensingDate);

    const isMaxAgeInMonthsValid = moment().diff(licensingDate, 'months', true) < MAX_AGE;
    if (!isMaxAgeInMonthsValid) {
      yield put(
        AddEditListingsActions.setError(ErrorType.MAX_AGE_OF_CAR, {
          maxAgeInMonths: MAX_AGE.toString(),
        })
      );
      return true;
    }
  }

  if (deal.listing.lastCheck === undefined) {
    deal.listing.lastCheck = null;
  }

  const price = deal.listing.price;
  if (price && price < MIN_PRICE) {
    yield put(
      AddEditListingsActions.setError(ErrorType.MINIMUM_PRICE, {
        price: MIN_PRICE.toString(),
      })
    );
    return true;
  }

  if (price && price > MAX_PRICE) {
    yield put(
      AddEditListingsActions.setError(ErrorType.MAXIMUM_PRICE, {
        price: MAX_PRICE.toString(),
      })
    );
    return true;
  }

  const listPrice = deal.listing.listPrice;
  if (listPrice && listPrice < MIN_PRICE) {
    yield put(
      AddEditListingsActions.setError(ErrorType.MINIMUM_LIST_PRICE, {
        price: MIN_PRICE.toString(),
      })
    );
    return true;
  }

  if (listPrice && listPrice > MAX_PRICE) {
    yield put(
      AddEditListingsActions.setError(ErrorType.MAXIMUM_LIST_PRICE, {
        price: MAX_PRICE.toString(),
      })
    );
    return true;
  }

  return false;
}

function* handleIdenticalDealsError(errors?: ErrorObject[]) {
  if (errors?.length && errors[0].error === GowagoError.IDENTICAL_ENTITY) {
    yield put(AddEditListingsActions.setError(ErrorType.SAME));
    return true;
  }

  return false;
}

function* handleNoResidualValuesError(errors?: ErrorObject[]) {
  if (errors?.length && errors[0].error === GowagoError.EMPTY_RESIDUAL_VALUES) {
    yield put(
      AddEditListingsActions.setError(ErrorType.NO_RESIDUAL_VALUES, {
        noResidualValues: String(errors[0].message),
      })
    );
    return true;
  }

  return false;
}

function* duplicateDealRequestAsync(action: ReturnType<typeof AddEditListingsActions.duplicateDealRequest>) {
  const deal = yield select((state: RootState) => state.addEditListingsPage.deal);
  const vendor = action.payload;

  const request = createDuplicateDealRequest({ deal, vendor });

  try {
    const response = yield* call(createDeal, request);

    window.location.href = routeConstants.PROTECTED.EDIT_LISTINGS_PAGE.url(vendor, response.id);
  } catch (e) {
    yield put(AddEditListingsActions.duplicateDealFailure());
    yield put(AddEditListingsActions.saveDraftFailure(getErrorMessages(e?.message)));
  }
}

function* watchDuplicateDealRequestAsync() {
  yield takeEvery(AddEditListingsActionTypes.DUPLICATE_SELECTED_DEAL_REQUEST, duplicateDealRequestAsync);
}

function* createOrUpdateDeal(id: number | undefined, dealData: DealRequest, images: File[]) {
  const { deal: currentDeal } = yield select((state: RootState) => state.addEditListingsPage);

  let deal: Deal | undefined;

  dealData = {
    ...dealData,
    isLockedToSource: !id ? false : currentDeal.isLockedToSource,
    residualValues: dealData.residualValues
      ? dealData.residualValues?.map(
          ({
            km,
            residualValue,
            month,
            residualValueAbsolute,
            leasingRateDefault,
            leasingRateMax,
            leasingRateMin,
          }: ResidualValue) => ({
            km,
            residualValue,
            month,
            residualValueAbsolute,
            leasingRateDefault,
            leasingRateMax,
            leasingRateMin,
          })
        )
      : undefined,
    listing: {
      ...dealData.listing,
      source: !id ? SourceType.MANUAL : currentDeal.listing.source,
      greyImport: !!dealData.listing.greyImport,
    },
  };

  let validationError = yield validateData(dealData);

  if (!validationError) {
    if (!id) {
      // create deal
      deal = yield* call(createDeal, dealData);
      id = deal.id;

      // upload images (we need to upload images after creating a deal because we need deal.id)
      if (images?.length) {
        const uploadResult = yield* uploadNewImages(id, images);
        if (deal && uploadResult) {
          deal.listing.images = uploadResult;
        }
        // send update deal request and hangle errors if they exist
        const { deal: responseDeal, validationError: responseValidationError } = yield* updateDealLogic(id, dealData);
        deal = responseDeal;
        validationError = responseValidationError;
      }
    } else {
      const notRemovedImages = getNotRemovedDealImages(images, currentDeal);
      dealData.listing.images = notRemovedImages.map((imageItem: ImageGalleryElement, index: number) => ({
        ...imageItem,
        position: index + 1,
      }));
      const uploadResult = yield* uploadNewImages(id, images);
      if (dealData && uploadResult) {
        dealData.listing.images = [...(dealData.listing.images || []), ...uploadResult];
      }

      // send update deal request and hangle errors if they exist
      const { deal: responseDeal, validationError: responseValidationError } = yield* updateDealLogic(id, dealData);
      deal = responseDeal;
      validationError = responseValidationError;
    }
  }

  return {
    deal,
    dealImages: deal?.listing.images,
    validationError,
  };
}
function* updateDealLogic(id: number, dealData: DealRequest) {
  const response = yield* call(updateDeal, id, dealData);
  const { errors, status, data: deal } = response || {};
  let validationError = false;

  if (status === DealUpdateStatuses.FAILED) {
    if (yield* handleIdenticalDealsError(errors)) {
      validationError = true;
    } else if (yield* handleNoResidualValuesError(errors)) {
      validationError = true;
    } else {
      throw new Error(`Deal update failed`);
    }
  }

  return {
    deal,
    validationError,
  };
}

const getNotRemovedDealImages = (
  images: Array<File> | Array<ImageGalleryElement>,
  deal?: Deal
): ImageGalleryElement[] => {
  if (!deal?.listing.images) {
    return [];
  }

  const photos: Array<any> = [];
  images.forEach((item: any) => {
    if (item.images?.thumbnail) photos.push(item.images.thumbnail);
    else if (item.name) photos.push(item.name);
  });

  const removeQueryFromPath = (url: string) => (url.includes('?') ? url.substring(0, url.indexOf('?')) : url);

  const returnVal: ImageGalleryElement[] = [];
  photos.forEach((imgItem) => {
    if (deal.listing.images) {
      for (let i = 0; i < deal.listing.images?.length; i++) {
        const url = deal.listing.images[i].images.thumbnail;
        const pathWithoutQuery = removeQueryFromPath(url);
        const imgItemWithoutQuery = removeQueryFromPath(imgItem);

        if (imgItemWithoutQuery === pathWithoutQuery) {
          returnVal.push(deal.listing.images[i]);
          break;
        }
      }
    }
  });
  return returnVal;
};

function transformDealOwnership(data: DealRequest): DealRequest {
  if (data.isOwnershipDefault) {
    return ['leasingProvider', 'leasingInterestRate', 'businessLeasing', 'fee'].reduce(
      (acc, key) => ({
        ...acc,
        [key]: undefined,
      }),
      data
    );
  }
  return data;
}

export default [
  fork(watchGetDealRequestAsync),
  fork(watchDuplicateDealRequestAsync),
  fork(watchGetResidualValuesRequestAsync),
  fork(watchMoveToTrashRequestAsync),
  fork(watchPublishRequestAsync),
  fork(watchSaveDraftRequestAsync),
  fork(watchUpdateLockedToSourceRequestAsync),
  fork(watchGetCarsRequestAsync),
  fork(watchUploadNewImages),
];
