import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';
import { compose } from 'utils/compose';
import styles from './ErrorMessage.module.scss';

interface Props {
  title: ReactNode;
  children?: React.ReactNode;
  classes?: {
    root?: string;
    childrenContainer?: string;
  };
}

const ErrorMessage: FC<Props> = ({ title, children, classes }) => {
  return (
    <div className={classNames(styles.root, classes?.root)}>
      <div className={styles.title}>{title}</div>
      {children && <div className={classNames(styles.childrenContainer, classes?.childrenContainer)}>{children}</div>}
    </div>
  );
};

export default compose(ErrorMessage);
