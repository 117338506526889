import { defineMessages } from 'react-intl';

export default defineMessages({
  FooterTermsOfUse: {
    id: 'FooterTermsOfUse',
    defaultMessage: 'Terms of use',
  },
  FooterPrivacyPolicy: {
    id: 'FooterPrivacyPolicy',
    defaultMessage: 'Privacy policy',
  },
});
