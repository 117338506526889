import { FuelTypesDetailed } from '@carcodex/gow-shared-mono/modules/car/car.types';
import { IntlShape } from 'react-intl';
import { ConsumptionRating, DrivenWheels, PollutionNorm, SelectOption, TransmissionDetailed } from 'types';
import { selectCompareFn } from 'utils/comparer-functions';
import Translations from './TechnicalInformationForm.translations';

export const getGearboxDetailedOptions = ({ formatMessage }: IntlShape): SelectOption[] =>
  [
    {
      label: formatMessage(Translations.TechnicalInformationFormAutomatic),
      value: TransmissionDetailed.AUTOMATIC,
    },
    {
      label: formatMessage(Translations.TechnicalInformationFormAutomaticManual),
      value: TransmissionDetailed.AUTOMATIC_MANUAL,
    },
    {
      label: formatMessage(Translations.TechnicalInformationFormAutomaticSequential),
      value: TransmissionDetailed.AUTOMATIC_SEQUENTIAL,
    },
    {
      label: formatMessage(Translations.TechnicalInformationFormAutomaticStepless),
      value: TransmissionDetailed.AUTOMATIC_STEPLESS,
    },
    {
      label: formatMessage(Translations.TechnicalInformationFormAutomaticSteplessSequential),
      value: TransmissionDetailed.AUTOMATIC_STEPLESS_SEQUENTIAL,
    },
    {
      label: formatMessage(Translations.TechnicalInformationFormManual),
      value: TransmissionDetailed.MANUAL,
    },
    {
      label: formatMessage(Translations.TechnicalInformationFormManualSequential),
      value: TransmissionDetailed.MANUAL_SEQUENTIAL,
    },
  ].sort(selectCompareFn());

export const getDrivenWheelsOptions = ({ formatMessage }: IntlShape): SelectOption[] =>
  [
    {
      label: formatMessage(Translations.TechnicalInformationFormRear),
      value: DrivenWheels.REAR,
    },
    {
      label: formatMessage(Translations.TechnicalInformationFormFront),
      value: DrivenWheels.FRONT,
    },
    {
      label: '4x4',
      value: DrivenWheels['4X4'],
    },
  ].sort(selectCompareFn());

export const getFuelDetailedOptions = ({ formatMessage }: IntlShape): SelectOption[] =>
  [
    {
      label: formatMessage(Translations.TechnicalInformationFormPetrol),
      value: FuelTypesDetailed.PETROL as unknown as string,
    },
    {
      label: formatMessage(Translations.TechnicalInformationFormHybrid),
      value: FuelTypesDetailed.HYBRID as unknown as string,
    },
    {
      label: formatMessage(Translations.TechnicalInformationFormDiesel),
      value: FuelTypesDetailed.DIESEL as unknown as string,
    },
    {
      label: formatMessage(Translations.TechnicalInformationFormElectric),
      value: FuelTypesDetailed.ELECTRIC as unknown as string,
    },
    {
      label: formatMessage(Translations.TechnicalInformationFormCNG),
      value: FuelTypesDetailed.CNG as unknown as string,
    },
    {
      label: formatMessage(Translations.TechnicalInformationFormE85),
      value: FuelTypesDetailed.E85 as unknown as string,
    },
    {
      label: formatMessage(Translations.TechnicalInformationFormLPG),
      value: FuelTypesDetailed.LPG as unknown as string,
    },
    {
      label: formatMessage(Translations.TechnicalInformationFormH2),
      value: FuelTypesDetailed.H2 as unknown as string,
    },
    {
      label: formatMessage(Translations.TechnicalInformationFormCNGPetrol),
      value: FuelTypesDetailed.CNG_PETROL as unknown as string,
    },
    {
      label: formatMessage(Translations.TechnicalInformationFormHybridDiesel),
      value: FuelTypesDetailed.HYBRID_DIESEL as unknown as string,
    },
    {
      label: formatMessage(Translations.TechnicalInformationFormHybridPetrol),
      value: FuelTypesDetailed.HYBRID_PETROL as unknown as string,
    },
    {
      label: formatMessage(Translations.TechnicalInformationFormFullHybridDiesel),
      value: FuelTypesDetailed.FULL_HYBRID_DIESEL as unknown as string,
    },
    {
      label: formatMessage(Translations.TechnicalInformationFormFullHybridPetrol),
      value: FuelTypesDetailed.FULL_HYBRID_PETROL as unknown as string,
    },
    {
      label: formatMessage(Translations.TechnicalInformationFormPlugInHybridDiesel),
      value: FuelTypesDetailed.PLUG_IN_HYBRID_DIESEL as unknown as string,
    },
    {
      label: formatMessage(Translations.TechnicalInformationFormPlugInHybridPetrol),
      value: FuelTypesDetailed.PLUG_IN_HYBRID_PETROL as unknown as string,
    },
    {
      label: formatMessage(Translations.TechnicalInformationFormMildHybridDiesel),
      value: FuelTypesDetailed.MILD_HYBRID_DIESEL as unknown as string,
    },
    {
      label: formatMessage(Translations.TechnicalInformationFormMildHybridPetrol),
      value: FuelTypesDetailed.MILD_HYBRID_PETROL as unknown as string,
    },
  ].sort(selectCompareFn());

export const efficiencyRatingOptions: SelectOption[] = [
  {
    label: 'A',
    value: ConsumptionRating.A,
  },
  {
    label: 'B',
    value: ConsumptionRating.B,
  },
  {
    label: 'C',
    value: ConsumptionRating.C,
  },
  {
    label: 'D',
    value: ConsumptionRating.D,
  },
  {
    label: 'E',
    value: ConsumptionRating.E,
  },
  {
    label: 'F',
    value: ConsumptionRating.F,
  },
  {
    label: 'G',
    value: ConsumptionRating.G,
  },
].sort(selectCompareFn());

export const euroStandardOptions: SelectOption[] = [
  {
    label: 'Euro 1',
    value: PollutionNorm.EURO_1,
  },
  {
    label: 'Euro 2',
    value: PollutionNorm.EURO_2,
  },
  {
    label: 'Euro 3',
    value: PollutionNorm.EURO_3,
  },
  {
    label: 'Euro 4',
    value: PollutionNorm.EURO_4,
  },
  {
    label: 'Euro 5',
    value: PollutionNorm.EURO_5,
  },
  {
    label: 'Euro 6',
    value: PollutionNorm.EURO_6,
  },
  {
    label: 'Euro 6a',
    value: PollutionNorm.EURO_6A,
  },
  {
    label: 'Euro 6b',
    value: PollutionNorm.EURO_6B,
  },
  {
    label: 'Euro 6c',
    value: PollutionNorm.EURO_6C,
  },
  {
    label: 'Euro 6d',
    value: PollutionNorm.EURO_6D,
  },
  {
    label: 'Euro 6d-TEMP',
    value: PollutionNorm.EURO_6D_TEMP,
  },
].sort(selectCompareFn());
