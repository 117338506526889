import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  OfferOverviewFormOfferInformation: {
    id: 'OfferOverviewFormOfferInformation',
    defaultMessage: 'Offer information',
  },
  OfferOverviewFormOfferType: {
    id: 'OfferOverviewFormOfferType',
    defaultMessage: 'Offer Type',
  },
  OfferOverviewFormAllInOnePackageName: {
    id: 'OfferOverviewFormAllInOnePackageName',
    defaultMessage: 'All-in-one package',
  },
  OfferOverviewFormCarInformation: {
    id: 'OfferOverviewFormCarInformation',
    defaultMessage: 'Car information',
  },
  OfferOverviewFormAbo: {
    id: 'OfferOverviewFormAbo',
    defaultMessage: 'Abo',
  },
  OfferOverviewNoOfMonths: {
    id: 'OfferOverviewNoOfMonths',
    defaultMessage: '{noOfMonths} months',
  },
  OfferOverviewNoOfKM: {
    id: 'OfferOverviewNoOfKM',
    defaultMessage: '{noOfKm} km',
  },
  OfferOverviewFormUnder25: {
    id: 'OfferOverviewFormUnder25',
    defaultMessage: 'Under 25',
  },
  OfferOverviewForm25OrAbove: {
    id: 'OfferOverviewForm25OrAbove',
    defaultMessage: '25 or above',
  },
  OfferOverviewFormLeasingPlusAllInOne: {
    id: 'OfferOverviewFormLeasingPlusAllInOne',
    defaultMessage: 'Leasing + All-in-one',
  },
  OfferOverviewFormLeasingOnly: {
    id: 'OfferOverviewFormLeasingOnly',
    defaultMessage: 'Leasing only',
  },
  OfferOverviewFormPrivateCredit: {
    id: 'OfferOverviewFormPrivateCredit',
    defaultMessage: 'Private credit',
  },
  OfferOverviewFormBusinessLeasing: {
    id: 'OfferOverviewFormBusinessLeasing',
    defaultMessage: 'Business leasing',
  },
  OfferOverviewFormLeasingInformation: {
    id: 'OfferOverviewFormLeasingInformation',
    defaultMessage: 'Leasing information',
  },
  OfferOverviewFormHubspotDealId: {
    id: 'OfferOverviewFormHubspotDealId',
    defaultMessage: 'Hubspot deal ID',
  },
  OfferOverviewFormPublicId: {
    id: 'OfferOverviewFormPublicId',
    defaultMessage: 'Publid ID',
  },
  OfferOverviewFormDealId: {
    id: 'OfferOverviewFormDealId',
    defaultMessage: 'Deal ID',
  },
  OfferOverviewFormFirstName: {
    id: 'OfferOverviewFormFirstName',
    defaultMessage: 'First name',
  },
  OfferOverviewFormLastName: {
    id: 'OfferOverviewFormLastName',
    defaultMessage: 'Last name',
  },
  OfferOverviewFormDateCreated: {
    id: 'OfferOverviewFormDateCreated',
    defaultMessage: 'Date created',
  },
  OfferOverviewFormDateDeactivated: {
    id: 'OfferOverviewFormDateDeactivated',
    defaultMessage: 'Date deactivated',
  },
  OfferOverviewFormMake: {
    id: 'OfferOverviewFormMake',
    defaultMessage: 'Make',
  },
  OfferOverviewFormModel: {
    id: 'OfferOverviewFormModel',
    defaultMessage: 'Model',
  },
  OfferOverviewFormModelYear: {
    id: 'OfferOverviewFormModelYear',
    defaultMessage: 'Model year',
  },
  OfferOverviewFormEngine: {
    id: 'OfferOverviewFormEngine',
    defaultMessage: 'Engine',
  },
  OfferOverviewFormLicencingDate: {
    id: 'OfferOverviewFormLicencingDate',
    defaultMessage: 'Licencing date',
  },
  OfferOverviewFormLifetimeMileage: {
    id: 'OfferOverviewFormLifetimeMileage',
    defaultMessage: 'Lifetime Mileage',
  },
  OfferOverviewFormListPrice: {
    id: 'OfferOverviewFormListPrice',
    defaultMessage: 'List price',
  },
  OfferOverviewFormCarPrice: {
    id: 'OfferOverviewFormCarPrice',
    defaultMessage: 'Car price',
  },
  OfferOverviewFormInitialCarPrice: {
    id: 'OfferOverviewFormInitialCarPrice',
    defaultMessage: 'Initial car price',
  },
  OfferOverviewFormOwnershipType: {
    id: 'OfferOverviewFormOwnershipType',
    defaultMessage: 'Ownership type',
  },
  OfferOverviewFormCanton: {
    id: 'OfferOverviewFormCanton',
    defaultMessage: 'Canton',
  },
  OfferOverviewFormNationality: {
    id: 'OfferOverviewFormNationality',
    defaultMessage: 'Nationality',
  },
  OfferOverviewFormAgeCategory: {
    id: 'OfferOverviewFormAgeCategory',
    defaultMessage: 'Age category',
  },
  OfferOverviewFormLeasingPeriod: {
    id: 'OfferOverviewFormLeasingPeriod',
    defaultMessage: 'Leasing period',
  },
  OfferOverviewFormLeasingMileage: {
    id: 'OfferOverviewFormLeasingMileage',
    defaultMessage: 'Leasing mileage',
  },
  OfferOverviewFormAboPeriod: {
    id: 'OfferOverviewFormAboPeriod',
    defaultMessage: 'Abo period',
  },
  OfferOverviewFormAboMileage: {
    id: 'OfferOverviewFormAboMileage',
    defaultMessage: 'Abo mileage',
  },
  OfferOverviewFormDownPayment: {
    id: 'OfferOverviewFormDownPayment',
    defaultMessage: 'Down payment',
  },
  OfferOverviewFormLeasingRateConfirmed: {
    id: 'OfferOverviewFormLeasingRateConfirmed',
    defaultMessage: 'Leasing rate confirmed',
  },
  OfferOverviewFormAllInOneMonthlyRate: {
    id: 'OfferOverviewFormAllInOneMonthlyRate',
    defaultMessage: 'All-in-one monthly rate',
  },
  OfferOverviewFormAboMonthlyRate: {
    id: 'OfferOverviewFormAboMonthlyRate',
    defaultMessage: 'Abo monthly rate',
  },
  OfferOverviewFormAboFirstPayment: {
    id: 'OfferOverviewFormAboFirstPayment',
    defaultMessage: 'Abo first payment',
  },
  OfferOverviewFormTotalMonthlyPrice: {
    id: 'OfferOverviewFormTotalMonthlyPrice',
    defaultMessage: 'Total monthly price',
  },
  OfferOverviewFormResidualValueConfirmed: {
    id: 'OfferOverviewFormResidualValueConfirmed',
    defaultMessage: 'Residual value confirmed',
  },
  OfferOverviewFormExtraKMCHF: {
    id: 'OfferOverviewFormExtraKMCHF',
    defaultMessage: 'Extra km surcharge (per kilometer)',
  },
  OfferOverviewFormLight: {
    id: 'OfferOverviewFormLight',
    defaultMessage: 'Light',
  },
  OfferOverviewFormEssentials: {
    id: 'OfferOverviewFormEssentials',
    defaultMessage: 'Essentials',
  },
  OfferOverviewFormPremium: {
    id: 'OfferOverviewFormPremium',
    defaultMessage: 'Premium',
  },
  OfferOverviewPromoCode: {
    id: 'OfferOverviewPromoCode',
    defaultMessage: 'Promo code',
  },
  OfferOverviewNominalInterestRate: {
    id: 'OfferOverviewNominalInterestRate',
    defaultMessage: 'Nominal interest rate',
  },
  OfferOverviewNominalInterestRateOriginal: {
    id: 'OfferOverviewNominalInterestRateOriginal',
    defaultMessage: 'Nominal interest rate original',
  },
  OfferOverviewInterestRateDiscount: {
    id: 'OfferOverviewInterestRateDiscount',
    defaultMessage: 'Interest rate discount',
  },
  OfferOverviewAIOPrice: {
    id: 'OfferOverviewAIOPrice',
    defaultMessage: 'AIO price',
  },
});
