import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { compose } from 'utils/compose';
import styles from './NavItemSingleLink.module.scss';

interface Props {
  title: string;
  titleIcon: IconProp;
  link: string;
  exact?: boolean;
  onClick?: () => void;
}

const NavItemSingleLink: FunctionComponent<Props> = ({ title, titleIcon, link, exact = true, onClick }) => {
  const handleOnClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <NavLink
      onClick={handleOnClick}
      to={link}
      className={styles.root}
      activeClassName={styles.activeLink}
      exact={exact}
    >
      <div className={classNames(styles.titleContainer)}>
        <FontAwesomeIcon icon={titleIcon} className={styles.titleIcon} fixedWidth />
        <span className={styles.title}>{title}</span>
      </div>
    </NavLink>
  );
};

export default compose<Props>(NavItemSingleLink);
