import Accordion from 'components/Accordion';
import CheckboxBase from 'components/Forms/CheckboxBase';
import LoadingIndicator from 'components/LoadingIndicator';
import { FieldArray, useFormikContext } from 'formik';
import { useRenderSortedStandardEquipmentTranslationsLabels } from 'hooks/useRenderSortedStandardEquipmentTranslationsLabels';
import useIsLockedToSource from 'pages/AddEditListings/hooks/useIsLockedToSource';
import { AddEditListingSchema } from 'pages/AddEditListings/schema';
import { Actions as AddEditListingsActions } from 'pages/AddEditListings/store/actions';
import { RootState } from 'pages/_store/root-reducer';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styles from './StandardEquipmentForm.module.scss';
import Translations from './StandardEquipmentForm.translations';

const StandardEquipmentForm: FC = () => {
  const { formatMessage } = useIntl();
  const { isLoadingAction, isLoadingDeal } = useSelector((state: RootState) => state.addEditListingsPage, shallowEqual);

  const { isLockedToSource, lockedToSourceMessage } = useIsLockedToSource();

  const { values, setFieldValue } = useFormikContext<AddEditListingSchema>();
  const dispatch = useDispatch();

  const listItems = useRenderSortedStandardEquipmentTranslationsLabels();

  const setUnsavedChanges = () => dispatch(AddEditListingsActions.setUnsavedChanges());

  const remove = (key: string) => {
    setFieldValue('optionsExtended', [...values.optionsExtended.filter((o) => o !== key)]);
    setUnsavedChanges();
  };

  const onCheckboxChange = (checked: boolean, key: string, push: (key: string) => void) => {
    checked ? push(key) : remove(key);
    setUnsavedChanges();
  };

  return (
    <div className={styles.root}>
      <LoadingIndicator isLoading={isLoadingAction || isLoadingDeal}>
        <Accordion header={formatMessage(Translations.StandardEquipmentFormEquipmentIncluded)} collapsable={false}>
          <FieldArray
            name="optionsExtended"
            render={({ push }) => (
              <ul className={styles.listContainer}>
                {listItems.map(({ key, label }) => (
                  <li key={key} className={styles.listItem}>
                    <CheckboxBase
                      label={label}
                      checked={values.optionsExtended.includes(key)}
                      onChange={(checked) => onCheckboxChange(checked, key, push)}
                      disabled={isLockedToSource}
                      tooltip={lockedToSourceMessage}
                    />
                  </li>
                ))}
              </ul>
            )}
          />
        </Accordion>
      </LoadingIndicator>
    </div>
  );
};

export default StandardEquipmentForm;
