import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FieldArrayRenderProps } from 'formik';
import { Actions as AddEditListingsActions } from 'pages/AddEditListings/store/actions';
import { geAvailableKMs, isNextAnnualMileageAvailable } from 'pages/AddEditListings/utils';
import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ResidualValue } from 'types';
import { compose } from 'utils/compose';
import ResidualValueMileageRow from './components/ResidualValueMileageRowForm';
import styles from './ResidualValueRowForm.module.scss';
import Translations from './ResidualValueRowForm.translations';

interface Props {
  residualValues: ResidualValue[];
  fieldArrayProps: FieldArrayRenderProps;
  index: number;
  disabled: boolean;
}

const ResidualValueRowForm: FunctionComponent<Props> = ({
  residualValues,
  fieldArrayProps: { remove, push },
  index,
  disabled,
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const setUnsavedChanges = () => dispatch(AddEditListingsActions.setUnsavedChanges());

  const onAddAnnualMileage = () => {
    if (isNextAnnualMileageAvailable(residualValues)) {
      const newValue: Partial<ResidualValue> = {
        // km: null,
        month: residualValues[0].month,
        // residualValue: undefined,
        isNewEntry: true,
      };
      push(newValue);
      setUnsavedChanges();
    }
  };

  const removeResidualValues = () => {
    const firstIndex = index;
    const lastIndex = index + residualValues.length - 1;
    for (let i = firstIndex; i <= lastIndex; i++) {
      remove(index);
    }
    setUnsavedChanges();
  };

  const availableKms = geAvailableKMs(residualValues);

  return (
    <div className={styles.root}>
      <div className={styles.contractLengthRow}>
        <button className={styles.deleteButton} onClick={removeResidualValues} disabled={disabled} type="button">
          <FontAwesomeIcon icon={['fal', 'trash-alt']} />
        </button>
        <span className={styles.months}>
          {`${residualValues.length > 0 ? `${residualValues[0].month}` : ''} ${formatMessage(
            Translations.ResidualValueRowFormMonths
          )}`}
        </span>
      </div>
      <div className={styles.residualValuesContainer}>
        {residualValues.map((item, priceBreakdownIndex) => (
          <ResidualValueMileageRow
            key={`${item.month}-${index + priceBreakdownIndex}`}
            index={index + priceBreakdownIndex}
            residualValue={item}
            disabled={disabled}
            remove={remove}
            availableKMs={availableKms}
          />
        ))}
        <button
          className={styles.addAnnualMileage}
          disabled={disabled || !isNextAnnualMileageAvailable(residualValues)}
          onClick={onAddAnnualMileage}
          type="button"
        >
          <FontAwesomeIcon icon={['fas', 'plus-square']} className={styles.plusSquareIcon} />
          {` ${formatMessage(Translations.ResidualValueRowFormAddAnnualMileage)}`}
        </button>
      </div>
    </div>
  );
};

export default compose<Props>(ResidualValueRowForm);
