import TextField from 'components/Forms/TextField';
import { FormikProps } from 'formik';
import { VendorInformationFormData } from 'pages/VendorInformation/store/types';
import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { compose } from 'utils/compose';
import styles from './ContactInformation.module.scss';
import Translations from './ContactInformation.translations';

interface Props {
  formikProps: FormikProps<VendorInformationFormData>;
  onChange: () => void;
}

const ContactInformation: FunctionComponent<Props> = ({ formikProps, onChange }) => {
  const { formatMessage } = useIntl();
  return (
    <div>
      <div className={styles.title}>
        {formatMessage(Translations.VendorInformationContactInformationContactInformation)}
      </div>
      <div className={styles.form}>
        <div className={styles.formRow}>
          <div className={styles.formControl}>
            <TextField
              name="name"
              type="text"
              required={true}
              labelClassName={styles.label}
              label={formatMessage(Translations.VendorInformationContactInformationName)}
              value={formikProps.initialValues.name}
              onChange={onChange}
            />
          </div>
          <div className={styles.formControl}>
            <TextField
              name="phoneNumber"
              type="tel"
              required={true}
              labelClassName={styles.label}
              label={formatMessage(Translations.VendorInformationContactInformationPhoneNumber)}
              value={formikProps.initialValues.streetAndNumber}
              onChange={onChange}
            />
          </div>
        </div>
        <div className={styles.formRow}>
          <div className={styles.formControl}>
            <TextField
              name="email"
              type="text"
              disabled
              label={formatMessage(Translations.VendorInformationContactInformationLeadEmail)}
              value={formikProps.initialValues.email}
              onChange={onChange}
            />
          </div>
        </div>
        <div className={styles.text}>
          {formatMessage(Translations.VendorInformationContactInformationGowagoLeaseApplicationsWillBeSent)}
        </div>
        <div className={styles.text}>
          {formatMessage(Translations.VendorInformationContactInformationContactOurSupportTeam)}
        </div>
        <div className={styles.divider} />
        <div className={styles.formRow}>
          <div className={styles.formControl}>
            <TextField
              name="streetAndNumber"
              type="text"
              required={true}
              labelClassName={styles.label}
              label={formatMessage(Translations.VendorInformationContactInformationStreetAndNumber)}
              value={formikProps.initialValues.streetAndNumber}
              onChange={onChange}
            />
          </div>
          <div className={styles.formControl}>
            <TextField
              name="city"
              type="text"
              required={true}
              labelClassName={styles.label}
              label={formatMessage(Translations.VendorInformationContactInformationCity)}
              value={formikProps.initialValues.city}
              onChange={onChange}
            />
          </div>
          <div className={styles.formControl}>
            <TextField
              name="zip"
              type="tel"
              required={true}
              labelClassName={styles.label}
              label={formatMessage(Translations.VendorInformationContactInformationZipCode)}
              value={formikProps.initialValues.zip}
              maxLength={4}
              onChange={onChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose<Props>(ContactInformation);
