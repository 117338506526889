import SentryErrorBoundary from 'HOCs/SentryErrorBoundary';
import Routes from 'pages/Routes';
import { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import getUserConfirmation from 'userConfirmation';
import { compose } from 'utils/compose';
import MainContainer from './components/MainContainer';
import SidePanel from './components/SidePanel';
import styles from './Dashboard.module.scss';

const Dashboard: FunctionComponent = () => {
  const dispatch = useDispatch();

  return (
    <div className={styles.root}>
      <Router getUserConfirmation={getUserConfirmation(dispatch)}>
        <SidePanel />
        <SentryErrorBoundary>
          <MainContainer>
            <Routes />
          </MainContainer>
        </SentryErrorBoundary>
      </Router>
    </div>
  );
};

export default compose<{}>(Dashboard);
