import { library } from '@fortawesome/fontawesome-svg-core';
import { faEnvelope, faSmile } from '@fortawesome/free-regular-svg-icons';
import {
  faCaretDown as faCaretDownSolid,
  faCheckCircle as faCheckCircleSolid,
  faCircle as faCircleSolid,
  faClock,
  faCog,
  faComment,
  faEnvelope as faEnvelopeSolid,
  faInfoCircle,
  faList,
  faPhone,
  faPlus as faPlusFreeSolid,
  faPlusSquare as faPlusSquareFreeSolid,
  faShoppingCart as faShoppingCartSolid,
  faStar as faStarSolid,
  faTrashAlt as faTrashAltFreeSolid,
  faUser,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { faSortAlt } from '@fortawesome/pro-duotone-svg-icons/faSortAlt';
import { faSortUp } from '@fortawesome/pro-duotone-svg-icons/faSortUp';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons/faSpinnerThird';
import { faArrowLeft, faTrashAlt as faTrashAltLight } from '@fortawesome/pro-light-svg-icons';
import {
  faBars,
  faCalendar as faCalendarRegular,
  faCarSide,
  faCheck,
  faCheckCircle as faCheckCircleProRegular,
  faChevronDown as faChevronDownProRegular,
  faChevronLeft,
  faChevronRight,
  faChevronUp as faChevronUpProRegular,
  faCircleNotch,
  faComment as faCommentRegular,
  faExclamation,
  faGlobeEurope,
  faHome,
  faInfo,
  faPlus,
  faSearch,
  faSignOut,
  faTimes,
  faTimesCircle as faTimesCircleProRegular,
} from '@fortawesome/pro-regular-svg-icons';

library.add(
  faStarSolid,
  faTrashAltFreeSolid,
  faChevronDownProRegular,
  faChevronUpProRegular,
  faPlusSquareFreeSolid,
  faCaretDownSolid,
  faTrashAltLight,
  faCalendarRegular,
  faPlusFreeSolid,
  faArrowLeft,
  faChevronLeft,
  faCircleNotch,
  faHome,
  faBars,
  faChevronRight,
  faCarSide,
  faEnvelope,
  faExclamation,
  faInfo,
  faCheck,
  faGlobeEurope,
  faTimes,
  faSmile,
  faList,
  faPlus,
  faUsers,
  faUser,
  faCog,
  faPhone,
  faSignOut,
  faSearch,
  faComment,
  faCommentRegular,
  faSortAlt,
  faTimesCircleProRegular,
  faCheckCircleProRegular,
  faSpinnerThird,
  faInfoCircle,
  faSortUp,
  faEnvelopeSolid,
  faClock,
  faCheckCircleSolid,
  faCircleSolid,
  faShoppingCartSolid
);
