import { faClone } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import ConfirmationModalBase from 'components/ConfirmationModalBase';
import ButtonSelection, { MenuItem } from 'components/Menu/ButtonSelection';
import TableNavigation from 'components/TableNavigation';
import { FunctionComponent, useState } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { DealStatus } from 'types';
import { compose } from 'utils/compose';
import { RootState } from '../../../_store/root-reducer';
import { Actions } from '../../store/actions';
import styles from './HeaderNavigation.module.scss';
import Translations from './HeaderNavigation.translations';

interface Props {
  onClickNext: () => void;
  onClickPrev: () => void;
  $total: number;
  $skip: number;
  $limit: number;
}

const HeaderNavigation: FunctionComponent<Props> = ({ onClickNext, onClickPrev, $total, $skip, $limit }) => {
  const { formatMessage } = useIntl();
  const [isRemoveConfirmationModalOpen, setRemoveConfirmationModalOpen] = useState(false);
  const dispatch = useDispatch();
  const selectedDeals = useSelector((state: RootState) => state.dealResultsState.selectedDeals, shallowEqual);
  const bulkActionsItems = [
    {
      label: formatMessage(Translations.HeaderNavigationPublishedItem),
      value: DealStatus.PUBLISHED,
    },
    {
      label: formatMessage(Translations.HeaderNavigationDraftItem),
      value: DealStatus.DRAFT,
    },
  ];

  const handleBulkSelectionOnChange = (item: MenuItem) => {
    dispatch(Actions.bulkStatusUpdateDealsRequest(item.value as DealStatus));
  };

  const handleBulkDelete = () => {
    dispatch(Actions.bulkStatusUpdateDealsRequest(DealStatus.TRASH));
  };
  const handleRemoveOnClick = () => setRemoveConfirmationModalOpen(true);

  const handleDialogActionClick = (value: boolean) => () => {
    if (value) {
      handleBulkDelete();
    }
    setRemoveConfirmationModalOpen(false);
  };

  const handleDuplicate = () => dispatch(Actions.duplicateSelectedDealRequest());

  return (
    <div className={styles.root}>
      <ConfirmationModalBase
        message={formatMessage(Translations.HeaderNavigationRemoveListingsFromGowagoCh)}
        okLabel={formatMessage(Translations.HeaderNavigationYesMoveListingToTrash)}
        closeLabel={formatMessage(Translations.HeaderNavigationCancel)}
        isOpen={isRemoveConfirmationModalOpen}
        onOkay={handleDialogActionClick(true)}
        onClose={handleDialogActionClick(false)}
        variant={'error'}
      />
      <div>
        {selectedDeals.length > 0 && (
          <>
            <button className={classNames(styles.actionButton, styles.deleteButton)} onClick={handleRemoveOnClick}>
              <FontAwesomeIcon icon={['fas', 'trash-alt']} className={styles.buttonIcon} />
              {formatMessage(Translations.HeaderNavigationMoveItemsSelectedToTrash, {
                itemsSelected: selectedDeals.length,
              })}
            </button>
            <ButtonSelection
              id="DealStatusBulkAction"
              items={bulkActionsItems}
              onChange={handleBulkSelectionOnChange}
              className={{
                button: styles.actionButton,
              }}
            >
              {formatMessage(Translations.HeaderNavigationChangeStatus)}
            </ButtonSelection>
            {selectedDeals.length === 1 && (
              <button className={classNames(styles.actionButton, styles.duplicateButton)} onClick={handleDuplicate}>
                <FontAwesomeIcon icon={faClone} className={styles.buttonIcon} />
                {formatMessage(Translations.HeaderNavigationDuplicate)}
              </button>
            )}
          </>
        )}
      </div>
      <div className={styles.rightContent}>
        <TableNavigation
          onClickNext={onClickNext}
          onClickPrev={onClickPrev}
          total={$total}
          skip={$skip}
          limit={$limit}
        />
      </div>
    </div>
  );
};

export default compose<Props>(HeaderNavigation);
