import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { compose } from 'utils/compose';
import styles from './Header.module.scss';
import Translations from './SellerProfileHeader.translations';

const Header: FunctionComponent = () => {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.root}>
      <div className={styles.title}>{formatMessage(Translations.SellerProfileHeaderYourProfile)}</div>
    </div>
  );
};

export default compose(Header);
