import { defineMessages } from 'react-intl';

export default defineMessages({
  ChangePasswordThisFieldIsRequired: {
    id: 'ChangePasswordThisFieldIsRequired',
    defaultMessage: 'This field is required',
  },
  ChangePasswordCurrentPasswordLabel: {
    id: 'ChangePasswordCurrentPasswordLabel',
    defaultMessage: 'Current password',
  },
  ChangePasswordNewPasswordLabel: {
    id: 'ChangePasswordNewPasswordLabel',
    defaultMessage: 'New password',
  },
  ChangePasswordRepeatPasswordLabel: {
    id: 'ChangePasswordRepeatPasswordLabel',
    defaultMessage: 'Repeat password',
  },
  ChangePasswordSaveChangesSubmitButton: {
    id: 'ChangePasswordSaveChangesSubmitButton',
    defaultMessage: 'Save changes',
  },
  ChangePasswordCharacterLengthRequirement: {
    id: 'ChangePasswordCharacterLengthRequirement',
    defaultMessage: '6 - 50 characters long',
  },
  ChangePasswordContainsLettersAndNumbersRequirement: {
    id: 'ChangePasswordContainsLettersAndNumbersRequirement',
    defaultMessage: 'Contains letters and numbers',
  },
  ChangePasswordBothPasswordNeedToBeTheSameErrorLabel: {
    id: 'ChangePasswordBothPasswordNeedToBeTheSameErrorLabel',
    defaultMessage: 'Both password need to be the same',
  },
  ChangePasswordErrorMessageTitle: {
    id: 'ChangePasswordErrorMessageTitle',
    defaultMessage: 'Password could not be changed due to the following errors',
  },
  ChangePasswordCurrentPasswordErrorHeader: {
    id: 'ChangePasswordCurrentPasswordErrorHeader',
    defaultMessage: '<underline>Current password</underline> must be provided',
  },
  ChangePasswordNewPasswordErrorHeader: {
    id: 'ChangePasswordNewPasswordErrorHeader',
    defaultMessage: '<underline>New password</underline> must meet the required criteria',
  },
  ChangePasswordConfirmPasswordErrorHeader: {
    id: 'ChangePasswordConfirmPasswordErrorHeader',
    defaultMessage: 'You must <underline>repeat your password</underline>',
  },
  ChangePasswordTitle: {
    id: 'ChangePasswordTitle',
    defaultMessage: 'Change password',
  },
  ChangePasswordYouProvidedAnIncorrectOldPassword: {
    id: 'ChangePasswordYouProvidedAnIncorrectOldPassword',
    defaultMessage: 'You provided an incorrect old password',
  },
  ChangePasswordYourPasswordHasNowBeenChanged: {
    id: 'ChangePasswordYourPasswordHasNowBeenChanged',
    defaultMessage: 'Your password has now been changed',
  },
});
