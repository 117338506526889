import { Reducer } from 'redux';
import { Actions, ActionTypes } from './actions';
import { initialState, OrdersPageState } from './initial-state';

export const ordersPageReducer: Reducer<OrdersPageState> = (state = initialState, action: Actions): OrdersPageState => {
  switch (action.type) {
    case ActionTypes.GET_ORDERS_REQUEST: {
      return {
        ...state,
        query: {
          ...initialState.query,
          ...action.payload,
        },
        isFetchingResults: true,
      };
    }

    case ActionTypes.GET_VENDOR_ORDERS_REQUEST: {
      return {
        ...state,
        query: {
          ...initialState.query,
          ...action.payload.query,
        },
        isFetchingResults: true,
      };
    }

    case ActionTypes.GET_ORDERS_SUCCESS: {
      return {
        ...state,
        result: {
          ...action.payload,
        },
        isFetchingResults: false,
      };
    }

    case ActionTypes.GET_VENDOR_ORDERS_SUCCESS: {
      return {
        ...state,
        result: {
          ...action.payload,
        },
        isFetchingResults: false,
      };
    }

    case ActionTypes.GET_ORDERS_FAILURE: {
      return {
        ...state,
        isFetchingResults: false,
      };
    }

    case ActionTypes.GET_VENDOR_ORDERS_FAILURE: {
      return {
        ...state,
        isFetchingResults: false,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
