import { defineMessages } from 'react-intl';

export default defineMessages({
  VehicleBaseInformationFormVehicleInformation: {
    id: 'VehicleBaseInformationFormVehicleInformation',
    defaultMessage: 'Vehicle information',
  },
  VehicleBaseInformationFormNoInformation: {
    id: 'VehicleBaseInformationFormNoInformation',
    defaultMessage: 'No information',
  },
  VehicleBaseInformationKM: {
    id: 'VehicleBaseInformationKM',
    defaultMessage: 'Km',
  },
  VehicleBaseInformationFormMake: {
    id: 'VehicleBaseInformationFormMake',
    defaultMessage: 'Make',
  },
  VehicleBaseInformationFormModel: {
    id: 'VehicleBaseInformationFormModel',
    defaultMessage: 'Model',
  },
  VehicleBaseInformationFormModelYear: {
    id: 'VehicleBaseInformationFormModelYear',
    defaultMessage: 'Model year',
  },
  VehicleBaseInformationFormVersion: {
    id: 'VehicleBaseInformationFormVersion',
    defaultMessage: 'Version',
  },
  VehicleBaseInformationFormRegistrationDate: {
    id: 'VehicleBaseInformationFormRegistrationDate',
    defaultMessage: 'Registration date',
  },
  VehicleBaseInformationFormCondition: {
    id: 'VehicleBaseInformationFormCondition',
    defaultMessage: 'Condition',
  },
  VehicleBaseInformationFormMileage: {
    id: 'VehicleBaseInformationFormMileage',
    defaultMessage: 'Mileage',
  },
  VehicleBaseInformationFormLifetimeMileage: {
    id: 'VehicleBaseInformationFormLifetimeMileage',
    defaultMessage: 'Lifetime Mileage',
  },
  VehicleBaseInformationFormLastTestDate: {
    id: 'VehicleBaseInformationFormLastTestDate',
    defaultMessage: 'Last test date',
  },
  VehicleBaseInformationFormImportNumber: {
    id: 'VehicleBaseInformationFormImportNumber',
    defaultMessage: 'Import number',
  },
  VehicleBaseInformationFormBodyShape: {
    id: 'VehicleBaseInformationFormBodyShape',
    defaultMessage: 'Body shape',
  },
  VehicleBaseInformationFormKm: {
    id: 'VehicleBaseInformationFormKm',
    defaultMessage: 'km',
  },
  VehicleBaseInformationFormVin: {
    id: 'VehicleBaseInformationFormVin',
    defaultMessage: 'VIN',
  },
  VehicleBaseInformationFormVinChassisNumber: {
    id: 'VehicleBaseInformationFormVinChassisNumber',
    defaultMessage: 'VIN / Chassis number',
  },
  VehicleBaseInformationFormDesignationNumber: {
    id: 'VehicleBaseInformationFormDesignationNumber',
    defaultMessage: 'Designation number',
  },
  VehicleBaseInformationFormModelDesignationNumber: {
    id: 'VehicleBaseInformationFormModelDesignationNumber',
    defaultMessage: 'Model designation number',
  },
  VehicleBaseInformationFormInternalID: {
    id: 'VehicleBaseInformationFormInternalID',
    defaultMessage: 'Internal ID',
  },
  VehicleBaseInformationFormNew: {
    id: 'VehicleBaseInformationFormNew',
    defaultMessage: 'New',
  },
  VehicleBaseInformationFormUsed: {
    id: 'VehicleBaseInformationFormUsed',
    defaultMessage: 'Used',
  },
  VehicleBaseInformationFormNewSingleDayRegistration: {
    id: 'VehicleBaseInformationFormNewSingleDayRegistration',
    defaultMessage: 'New single day registration',
  },
  VehicleBaseInformationFormDisplayModel: {
    id: 'VehicleBaseInformationFormDisplayModel',
    defaultMessage: 'Display model',
  },
  VehicleBaseInformationFormOldTimer: {
    id: 'VehicleBaseInformationFormOldTimer',
    defaultMessage: 'Old timer',
  },
  VehicleBaseInformationFormConvertible: {
    id: 'VehicleBaseInformationFormConvertible',
    defaultMessage: 'Convertible',
  },
  VehicleBaseInformationFormSuv: {
    id: 'VehicleBaseInformationFormSuv',
    defaultMessage: 'SUV',
  },
  VehicleBaseInformationFormWagon: {
    id: 'VehicleBaseInformationFormWagon',
    defaultMessage: 'Wagon',
  },
  VehicleBaseInformationFormSedan: {
    id: 'VehicleBaseInformationFormSedan',
    defaultMessage: 'Sedan',
  },
  VehicleBaseInformationFormVan: {
    id: 'VehicleBaseInformationFormVan',
    defaultMessage: 'Van',
  },
  VehicleBaseInformationFormSmallCar: {
    id: 'VehicleBaseInformationFormSmallCar',
    defaultMessage: 'Small car',
  },
  VehicleBaseInformationFormPickup: {
    id: 'VehicleBaseInformationFormPickup',
    defaultMessage: 'Pickup',
  },
  VehicleBaseInformationFormSportsCar: {
    id: 'VehicleBaseInformationFormSportsCar',
    defaultMessage: 'Sports car',
  },
  VehicleBaseInformationFormGuarantee: {
    id: 'VehicleBaseInformationFormGuarantee',
    defaultMessage: 'Guarantee',
  },
  VehicleBaseInformationFormWarrantyStartType: {
    id: 'VehicleBaseInformationFormWarrantyStartType',
    defaultMessage: 'Warranty Start Type',
  },
  VehicleBaseInformationFormWarrantyInMonths: {
    id: 'VehicleBaseInformationFormWarrantyInMonths',
    defaultMessage: 'Warranty In Months',
  },
  VehicleBaseInformationFormWarrantyInKM: {
    id: 'VehicleBaseInformationFormWarrantyInKM',
    defaultMessage: 'Warranty In KM',
  },
  VehicleBaseInformationFormWarrantyUntilDate: {
    id: 'VehicleBaseInformationFormWarrantyUntilDate',
    defaultMessage: 'Warranty Until Date',
  },
  VehicleBaseInformationFormWarrantyMonthsLeft: {
    id: 'VehicleBaseInformationFormWarrantyMonthsLeft',
    defaultMessage: 'Warranty Months Left',
  },
  VehicleBaseInformationFormWarrantyInKMLeft: {
    id: 'VehicleBaseInformationFormWarrantyInKMLeft',
    defaultMessage: 'Warranty In KM Left',
  },
  VehicleBaseInformationFormGuaranteeText: {
    id: 'VehicleBaseInformationFormGuaranteeText',
    defaultMessage: 'Guarantee Text',
  },
  VehicleBaseInformationFormFromLicensingStart: {
    id: 'VehicleBaseInformationFormFromLicensingStart',
    defaultMessage: 'From licensing start',
  },
  VehicleBaseInformationFormFromTakeOver: {
    id: 'VehicleBaseInformationFormFromTakeOver',
    defaultMessage: 'From take over',
  },
  VehicleBaseInformationFormGreyImport: {
    id: 'VehicleBaseInformationFormGreyImport',
    defaultMessage: 'Grey import',
  },
});
