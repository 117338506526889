import { defineMessages } from 'react-intl';

export default defineMessages({
  TechnicalInformationFormTechnicalInformation: {
    id: 'TechnicalInformationFormTechnicalInformation',
    defaultMessage: 'Technical information',
  },
  TechnicalInformationFormGearboxDetailed: {
    id: 'TechnicalInformationFormGearboxDetailed',
    defaultMessage: 'Gearbox detailed',
  },
  TechnicalInformationFormGearbox: {
    id: 'TechnicalInformationFormGearbox',
    defaultMessage: 'Gearbox',
  },
  TechnicalInformationFormDrivenWheels: {
    id: 'TechnicalInformationFormDrivenWheels',
    defaultMessage: 'Driven wheels',
  },
  TechnicalInformationFormFuel: {
    id: 'TechnicalInformationFormFuel',
    defaultMessage: 'Fuel',
  },
  TechnicalInformationFormFuelDetailed: {
    id: 'TechnicalInformationFormFuelDetailed',
    defaultMessage: 'Fuel detailed',
  },
  TechnicalInformationFormExteriorColour: {
    id: 'TechnicalInformationFormExteriorColour',
    defaultMessage: 'Exterior colour',
  },
  TechnicalInformationFormInteriorColour: {
    id: 'TechnicalInformationFormInteriorColour',
    defaultMessage: 'Interior colour',
  },
  TechnicalInformationFormSeats: {
    id: 'TechnicalInformationFormSeats',
    defaultMessage: 'Seats',
  },
  TechnicalInformationFormDoors: {
    id: 'TechnicalInformationFormDoors',
    defaultMessage: 'Doors',
  },
  TechnicalInformationFormCylinders: {
    id: 'TechnicalInformationFormCylinders',
    defaultMessage: 'Cylinders',
  },
  TechnicalInformationFormHorsepower: {
    id: 'TechnicalInformationFormHorsepower',
    defaultMessage: 'Horsepower',
  },
  TechnicalInformationFormKilowatts: {
    id: 'TechnicalInformationFormKilowatts',
    defaultMessage: 'Kilowatts',
  },
  TechnicalInformationFormNumberOfGears: {
    id: 'TechnicalInformationFormNumberOfGears',
    defaultMessage: 'Number of gears',
  },
  TechnicalInformationFormEngineCapacity: {
    id: 'TechnicalInformationFormEngineCapacity',
    defaultMessage: 'Engine capacity',
  },
  TechnicalInformationFormEngineCapacityCm3: {
    id: 'TechnicalInformationFormEngineCapacityCm3',
    defaultMessage: 'Engine capacity (cm3)',
  },
  TechnicalInformationFormCo2Emissions: {
    id: 'TechnicalInformationFormCo2Emissions',
    defaultMessage: 'CO2 Emissions',
  },
  TechnicalInformationFormPowerConsumptionKwh100km: {
    id: 'TechnicalInformationFormPowerConsumptionKwh100km',
    defaultMessage: 'Power consumption (kWh / 100km)',
  },
  TechnicalInformationFormConsumption: {
    id: 'TechnicalInformationFormConsumption',
    defaultMessage: 'Consumption',
  },
  TechnicalInformationFormPowerConsumption: {
    id: 'TechnicalInformationFormPowerConsumption',
    defaultMessage: 'Power consumption',
  },
  TechnicalInformationFormEfficiencyRating: {
    id: 'TechnicalInformationFormEfficiencyRating',
    defaultMessage: 'Efficiency rating',
  },
  TechnicalInformationFormEuroStandard: {
    id: 'TechnicalInformationFormEuroStandard',
    defaultMessage: 'Euro standard',
  },
  TechnicalInformationFormTare: {
    id: 'TechnicalInformationFormTare',
    defaultMessage: 'Tare',
  },
  TechnicalInformationFormTrailerLoad: {
    id: 'TechnicalInformationFormTrailerLoad',
    defaultMessage: 'Trailer load',
  },
  TechnicalInformationFormAutomatic: {
    id: 'TechnicalInformationFormAutomatic',
    defaultMessage: 'Automatic',
  },
  TechnicalInformationFormManual: {
    id: 'TechnicalInformationFormManual',
    defaultMessage: 'Manual',
  },
  TechnicalInformationFormAutomaticSequential: {
    id: 'TechnicalInformationFormAutomaticSequential',
    defaultMessage: 'Automatic sequential',
  },
  TechnicalInformationFormAutomaticStepless: {
    id: 'TechnicalInformationFormAutomaticStepless',
    defaultMessage: 'Automatic stepless',
  },
  TechnicalInformationFormAutomaticSteplessSequential: {
    id: 'TechnicalInformationFormAutomaticSteplessSequential',
    defaultMessage: 'Automatic stepless sequential',
  },
  TechnicalInformationFormAutomaticManual: {
    id: 'TechnicalInformationFormAutomaticManual',
    defaultMessage: 'Automatic manual',
  },
  TechnicalInformationFormManualSequential: {
    id: 'TechnicalInformationFormManualSequential',
    defaultMessage: 'Manual sequential',
  },
  TechnicalInformationFormFront: {
    id: 'TechnicalInformationFormFront',
    defaultMessage: 'Front',
  },
  TechnicalInformationFormRear: {
    id: 'TechnicalInformationFormRear',
    defaultMessage: 'Rear',
  },
  TechnicalInformationFormPetrol: {
    id: 'TechnicalInformationFormPetrol',
    defaultMessage: 'Petrol',
  },
  TechnicalInformationFormDiesel: {
    id: 'TechnicalInformationFormDiesel',
    defaultMessage: 'Diesel',
  },
  TechnicalInformationFormHybrid: {
    id: 'TechnicalInformationFormHybrid',
    defaultMessage: 'Hybrid',
  },
  TechnicalInformationFormElectric: {
    id: 'TechnicalInformationFormElectric',
    defaultMessage: 'Electric',
  },
  TechnicalInformationFormCNG: {
    id: 'TechnicalInformationFormCNG',
    defaultMessage: 'CNG',
  },
  TechnicalInformationFormCNGPetrol: {
    id: 'TechnicalInformationFormCNGPetrol',
    defaultMessage: 'CNG / Petrol',
  },
  TechnicalInformationFormHybridDiesel: {
    id: 'TechnicalInformationFormHybridDiesel',
    defaultMessage: 'Hybrid Diesel',
  },
  TechnicalInformationFormHybridPetrol: {
    id: 'TechnicalInformationFormHybridPetrol',
    defaultMessage: 'Hybrid Petrol',
  },
  TechnicalInformationFormE85: {
    id: 'TechnicalInformationFormE85',
    defaultMessage: 'E85',
  },
  TechnicalInformationFormLPG: {
    id: 'TechnicalInformationFormLPG',
    defaultMessage: 'LPG',
  },
  TechnicalInformationFormH2: {
    id: 'TechnicalInformationFormH2',
    defaultMessage: 'H2',
  },
  TechnicalInformationFormFullHybridDiesel: {
    id: 'TechnicalInformationFormFullHybridDiesel',
    defaultMessage: 'Full Hybrid Diesel',
  },
  TechnicalInformationFormFullHybridPetrol: {
    id: 'TechnicalInformationFormFullHybridPetrol',
    defaultMessage: 'Full Hybrid Petrol',
  },
  TechnicalInformationFormPlugInHybridDiesel: {
    id: 'TechnicalInformationFormPlugInHybridDiesel',
    defaultMessage: 'Plug In Hybrid Diesel',
  },
  TechnicalInformationFormPlugInHybridPetrol: {
    id: 'TechnicalInformationFormPlugInHybridPetrol',
    defaultMessage: 'Plug In Hybrid Petrol',
  },
  TechnicalInformationFormMildHybridDiesel: {
    id: 'TechnicalInformationFormMildHybridDiesel',
    defaultMessage: 'Mild Hybrid Diesel',
  },
  TechnicalInformationFormMildHybridPetrol: {
    id: 'TechnicalInformationFormMildHybridPetrol',
    defaultMessage: 'Mild Hybrid Petrol',
  },
  TechnicalInformationFormBatteryRange: {
    id: 'TechnicalInformationFormBatteryRange',
    defaultMessage: 'Battery range',
  },
  TechnicalInformationFormConsumptionElectric: {
    id: 'TechnicalInformationFormConsumptionElectric',
    defaultMessage: 'Consumption (Electric)',
  },
  TechnicalInformationFormBatteryCapacity: {
    id: 'TechnicalInformationFormBatteryCapacity',
    defaultMessage: 'Battery capacity',
  },
});
