import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Checkbox from 'components/Forms/Checkbox';
import { leasingProviderOptions, MonthType } from 'constants/constants';
import { FieldArray, useFormikContext } from 'formik';
import { AddEditListingSchema } from 'pages/AddEditListings/schema';
import { getNextAvailableContractLength } from 'pages/AddEditListings/utils';
import { RootState } from 'pages/_store/root-reducer';
import React, { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ResidualValue } from 'types';
import { compose } from 'utils/compose';
import { Actions as AddEditListingsActions } from '../../../../store/actions';
import { ErrorType } from '../../../../store/initial-state';
import ResidualValueRowForm from './components/ResidualValueRowForm';
import styles from './ResidualValueBreakdownForm.module.scss';
import Translations from './ResidualValueBreakdownForm.translations';

const ResidualValueBreakdownForm: FC = () => {
  const { defaultResidualValues, deal } = useSelector(
    (state: RootState) => ({
      defaultResidualValues: state.addEditListingsPage.defaultResidualValues,
      deal: state.addEditListingsPage.deal,
    }),
    shallowEqual
  );
  const { values, setFieldValue } = useFormikContext<AddEditListingSchema>();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const [customResidualValues, setCustomResidualValues] = useState<ResidualValue[]>();

  const setResidualValuesFormValues = useCallback(
    (values: ResidualValue[]) => {
      const sortedResidualValues = sortResidualValues(values);

      setFieldValue('residualValues', sortedResidualValues);
    },
    [setFieldValue]
  );

  useEffect(() => {
    if (defaultResidualValues && values.isResidualValueDefault) {
      setResidualValuesFormValues(defaultResidualValues);
    } else if (customResidualValues && !values.isResidualValueDefault) {
      setResidualValuesFormValues(customResidualValues);
    } else if (deal && deal.residualValues) {
      setResidualValuesFormValues(deal.residualValues);
    } else if (!customResidualValues && defaultResidualValues && !values.isResidualValueDefault) {
      setResidualValuesFormValues(defaultResidualValues);
    }
  }, [deal, values.isResidualValueDefault, defaultResidualValues, customResidualValues, setResidualValuesFormValues]);

  const leasingProviderLabel = useMemo(
    () => leasingProviderOptions.find((lp) => lp.value === values.leasingProvider)?.label,
    [values.leasingProvider]
  );

  const setUnsavedChanges = () => dispatch(AddEditListingsActions.setUnsavedChanges());

  const onDefaultResidualValuesChange = (checked: boolean) => {
    if (checked) {
      setCustomResidualValues(values.residualValues);
      // loadDefaultResidualValues();
    }
    setUnsavedChanges();
  };

  const onAddContractLength = () => {
    const nextContractLength = getNextAvailableContractLength(values.residualValues);
    if (nextContractLength) {
      const index = values.residualValues?.length || 0;

      const newValue: Partial<ResidualValue> = {
        km: 10000,
        month: nextContractLength as MonthType,
      };
      setFieldValue(`residualValues[${index}]`, newValue);
      setUnsavedChanges();
    } else {
      dispatch(AddEditListingsActions.setError(ErrorType.SOMETHING_WENT_WRONG));
    }
  };

  const sortResidualValues = (residualValues: ResidualValue[]): ResidualValue[] => {
    return residualValues.sort(
      ({ km: km1, month: month1, isNewEntry: isnewEntry1 }, { km: km2, month: month2, isNewEntry: isnewEntry2 }) => {
        if (month1 > month2) return 1;
        if (month1 < month2) return -1;
        if (isnewEntry1) return 1;
        if (isnewEntry2) return -1;
        if (km1 === null) return 1;
        if (km2 === null) return -1;
        if (km1 > km2) return 1;
        if (km1 < km2) return -1;
        return 0;
      }
    );
  };

  const residualValuesMap = React.useMemo(() => {
    const returnVal = new Map<number, ResidualValue[]>();
    for (const residualValue of sortResidualValues(values.residualValues || [])) {
      if (residualValue) {
        const currentValue = returnVal.get(residualValue.month) || [];
        const updateValue = [...currentValue, residualValue];
        returnVal.set(residualValue.month, updateValue);
      }
    }
    return returnVal;
  }, [values.residualValues]);

  const getArrayIndex = (index: number, array: [number, ResidualValue[]][]): number => {
    let total = 0;
    for (let i = 0; i < array.length; i++) {
      if (i === index) break;

      total += array[i][1].length;
    }
    return total;
  };

  return residualValuesMap.size ? (
    <div className={styles.root}>
      <div className={styles.headerContainer}>
        <div className={styles.headerLabel}>
          {formatMessage(Translations.ResidualValuesBreakdownFormResidualValues)}
        </div>
      </div>
      <div className={styles.useDefaultValuesCheckboxContainer}>
        <Checkbox
          disabled
          label={formatMessage(Translations.ResidualValuesBreakdownFormUseDefaultResidualValuesFromLeasingProvider, {
            leasingProviderLabel,
          })}
          name="isResidualValueDefault"
          onChange={onDefaultResidualValuesChange}
        />
      </div>
      <div className={styles.columnHeaderContainer}>
        <div className={styles.columnHeader}>
          {formatMessage(Translations.ResidualValuesBreakdownFormContractLength)}
        </div>
        <div className={styles.columnHeader}>
          {formatMessage(Translations.ResidualValuesBreakdownFormAnnualMileageKm)}
        </div>
        <div className={styles.columnResidualValue}>
          {formatMessage(Translations.ResidualValuesBreakdownFormResidualValue)}
        </div>
      </div>
      <FieldArray
        name="residualValues"
        render={(fieldArrayProps) =>
          Array.from(residualValuesMap).map(([month, valuesByMonth], index, array) => (
            <ResidualValueRowForm
              key={`${month}-months`}
              residualValues={valuesByMonth}
              fieldArrayProps={fieldArrayProps}
              index={getArrayIndex(index, array)}
              disabled={values.isResidualValueDefault}
            />
          ))
        }
      />
      <button
        className={styles.addAnnualMileage}
        disabled={values.isResidualValueDefault || getNextAvailableContractLength(values.residualValues) === undefined}
        onClick={onAddContractLength}
        type="button"
      >
        <FontAwesomeIcon icon={['fas', 'plus-square']} className={styles.plusSquareIcon} />
        {` ${formatMessage(Translations.ResidualValuesBreakdownFormAddContractLength)}`}
      </button>
    </div>
  ) : (
    <Fragment />
  );
};

export default compose(ResidualValueBreakdownForm);
