import { defineMessages } from 'react-intl';

export default defineMessages({
  ManagePeopleSellerRowActive: {
    id: 'ManagePeopleSellerRowActive',
    defaultMessage: 'Active',
  },
  ManagePeopleSellerRowInactive: {
    id: 'ManagePeopleSellerRowInactive',
    defaultMessage: 'Inactive',
  },
  ManagePeopleSellerRowPendingInvite: {
    id: 'ManagePeopleSellerRowPendingInvite',
    defaultMessage: 'Pending invite',
  },
  ManagePeopleSellerRowSendInvite: {
    id: 'ManagePeopleSellerRowSendInvite',
    defaultMessage: 'Send invite',
  },
  ManagePeopleSellerRowResendInvite: {
    id: 'ManagePeopleSellerRowResendInvite',
    defaultMessage: 'Resend invite',
  },
});
