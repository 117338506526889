import { createStyles } from '@material-ui/core';
import { GowagoTheme } from 'theme';

export const styles = (theme: GowagoTheme) =>
  createStyles({
    root: {
      '&.Mui-error': {
        color: theme.gowago.text.darkError,
        lineHeight: theme.gowago.lineHeight.rem[26],
        fontSize: theme.gowago.fontSize.smaller,
        fontWeight: 500,
        margin: 0,
      },
    },
    icon: {
      fontSize: theme.gowago.fontSize.smaller,
      marginRight: theme.gowago.spacing[6],
    },
  });
