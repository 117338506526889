import { APIError } from 'types';
import { ActionsUnion, createAction } from 'utils/action-utils';
import { PublicOffer } from './../../../types';

export enum ActionTypes {
  GET_ORDER_REQUEST = '[ORDER] GET_ORDER_REQUEST',
  GET_ORDER_SUCCESS = '[ORDER] GET_ORDER_SUCCESS',
  GET_ORDER_FAILURE = '[ORDER] GET_ORDER_FAILURE',

  UPDATE_ORDER_REQUEST = '[ORDER] UPDATE_ORDER_REQUEST',
  UPDATE_ORDER_SUCCESS = '[ORDER] UPDATE_ORDER_SUCCESS',
  UPDATE_ORDER_FAILURE = '[ORDER] UPDATE_ORDER_FAILURE',

  RESET_SUCCESS = '[ORDER] RESET_SUCCESS',
}

export const Actions = {
  getOrderRequest: (publicId: string) => createAction(ActionTypes.GET_ORDER_REQUEST, publicId),
  getOrderSuccess: (result: PublicOffer) => createAction(ActionTypes.GET_ORDER_SUCCESS, result),
  getOrderFailure: (e: APIError) => createAction(ActionTypes.GET_ORDER_FAILURE, e),

  updateOrderRequest: (publicId: string, data: PublicOffer) =>
    createAction(ActionTypes.UPDATE_ORDER_REQUEST, { publicId, data }),
  updateOrderSuccess: () => createAction(ActionTypes.UPDATE_ORDER_SUCCESS),
  updateOrderFailure: (errorMessage?: Array<string>) =>
    createAction(ActionTypes.UPDATE_ORDER_FAILURE, { errorMessage }),

  resetSuccess: () => createAction(ActionTypes.RESET_SUCCESS),
  noActionWithoutPayload: () => createAction('NO_ACTION_WITHOUT_PAYLOAD'),
};

export type Actions = ActionsUnion<typeof Actions>;
