import { getOffers, getVendorOffers } from 'api/offers';
import { RootState } from 'pages/_store/root-reducer';
import { fork, put, takeEvery } from 'redux-saga/effects';
import { call, select } from 'typed-redux-saga';
import { OrdersQuery } from 'types';
import { Actions as OrdersActions, ActionTypes as OrdersActionTypes } from './actions';

const getViewAllOrdersQuery = (state: RootState): OrdersQuery => state.ordersPage.query;

function* getOrdersRequestAsync(action: ReturnType<typeof OrdersActions.getOrdersRequest>) {
  try {
    window.scrollTo(0, 0); //scroll to top before request
    const orderQuery = yield select(getViewAllOrdersQuery);
    const res = yield* call(getOffers, {
      ...orderQuery,
      ...action.payload,
    });

    yield put(OrdersActions.getOrdersSuccess(res));
  } catch (e) {
    yield put(OrdersActions.getOrdersFailure(e));
  }
}

function* watchGetOrdersRequestAsync() {
  yield takeEvery(OrdersActionTypes.GET_ORDERS_REQUEST, getOrdersRequestAsync);
}

function* getVendorOrdersRequestAsync(action: ReturnType<typeof OrdersActions.getVendorOrdersRequest>) {
  try {
    const orderQuery: OrdersQuery = yield select(getViewAllOrdersQuery);
    const res = yield* call(getVendorOffers, {
      vendorId: action.payload.vendorId,
      ...orderQuery,
    });

    yield put(OrdersActions.getVendorOrdersSuccess(res));
  } catch (e) {
    yield put(OrdersActions.getVendorOrdersFailure(e));
  }
}

function* watchGetVendorOrdersRequestAsync() {
  yield takeEvery(OrdersActionTypes.GET_VENDOR_ORDERS_REQUEST, getVendorOrdersRequestAsync);
}

export default [fork(watchGetOrdersRequestAsync), fork(watchGetVendorOrdersRequestAsync)];
