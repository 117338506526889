import { defineMessages } from 'react-intl';

export default defineMessages({
  SidePanelListings: {
    id: 'SidePanelListings',
    defaultMessage: 'Listings',
  },
  SidePanelAllListings: {
    id: 'SidePanelAllListings',
    defaultMessage: 'All Listings',
  },
  SidePanelAddListing: {
    id: 'SidePanelAddListing',
    defaultMessage: 'Add Listing',
  },
  SidePanelManagePeople: {
    id: 'SidePanelManagePeople',
    defaultMessage: 'Manage people',
  },
  SidePanelYourProfile: {
    id: 'SidePanelYourProfile',
    defaultMessage: 'Your profile',
  },
  SidePanelVendorSettings: {
    id: 'SidePanelVendorSettings',
    defaultMessage: 'Vendor settings',
  },
  SidePanelVendorInformation: {
    id: 'SidePanelVendorInformation',
    defaultMessage: 'Vendor information',
  },
  SidePanelListingsSettings: {
    id: 'SidePanelListingsSettings',
    defaultMessage: 'Listings settings',
  },
  SidePanelVendorManagement: {
    id: 'SidePanelVendorManagement',
    defaultMessage: 'Vendor management',
  },
  SidePanelSettingsItem: {
    id: 'SidePanelSettingsItem',
    defaultMessage: 'Settings',
  },
  SidePanelSettingsHeader: {
    id: 'SidePanelSettingsHeader',
    defaultMessage: 'Settings',
  },
  SidePanelHelpAndSupport: {
    id: 'SidePanelHelpAndSupport',
    defaultMessage: 'Help and Support',
  },
  SidePanelContactSupport: {
    id: 'SidePanelContactSupport',
    defaultMessage: 'Contact Support',
  },
  SidePanelAdminTools: {
    id: 'SidePanelAdminTools',
    defaultMessage: 'Admin tools',
  },
  SidePanelAllVendors: {
    id: 'SidePanelAllVendors',
    defaultMessage: 'All vendors',
  },
  SidePanelOrders: {
    id: 'SidePanelOrders',
    defaultMessage: 'Orders',
  },
  SidePanelAllOrders: {
    id: 'SidePanelAllOrders',
    defaultMessage: 'All orders',
  },
  SidePanelSuperAdmin: {
    id: 'SidePanelSuperAdmin',
    defaultMessage: 'Super-admin',
  },
});
