import { defineMessages } from 'react-intl';

export default defineMessages({
  RegisterPageThisFieldIsRequired: {
    id: 'RegisterPageThisFieldIsRequired',
    defaultMessage: 'This field is required',
  },
  RegisterPageSignUp: {
    id: 'RegisterPageSignUp',
    defaultMessage: 'Sign up',
  },
  RegisterPageSellYourCarsOnGowagoCh: {
    id: 'RegisterPageSellYourCarsOnGowagoCh',
    defaultMessage: 'Sell your cars on gowago.ch',
  },
  RegisterPageFirstName: {
    id: 'RegisterPageFirstName',
    defaultMessage: 'First name',
  },
  RegisterPageLastName: {
    id: 'RegisterPageLastName',
    defaultMessage: 'Last name',
  },
  RegisterPageYourEmail: {
    id: 'RegisterPageYourEmail',
    defaultMessage: 'Your email',
  },
  RegisterPageYouCanChangeThisLaterInYourProfileSettings: {
    id: 'RegisterPageYouCanChangeThisLaterInYourProfileSettings',
    defaultMessage: 'You can change this later in your profile settings',
  },
  RegisterPageVendorCompany: {
    id: 'RegisterPageVendorCompany',
    defaultMessage: 'Vendor / Company',
  },
  RegisterPageNewPassword: {
    id: 'RegisterPageNewPassword',
    defaultMessage: 'New password',
  },
  RegisterPageRepeatPassword: {
    id: 'RegisterPageRepeatPassword',
    defaultMessage: 'Repeat password',
  },
  RegisterPageByClickingSignUpYouAgreeToOurTermsOfUseAndPrivacyPolicy: {
    id: 'RegisterPageByClickingSignUpYouAgreeToOurTermsOfUseAndPrivacyPolicy',
    defaultMessage:
      'By clicking Sign up, you agree to our <TosLink>Terms of Use</TosLink>, and <PrivacyPolicyLink>Privacy policy</PrivacyPolicyLink>.',
  },
  RegisterPageSignUpSubmit: {
    id: 'RegisterPageSignUpSubmit',
    defaultMessage: 'SignUp',
  },
  RegisterPageNeedSomeHelp: {
    id: 'RegisterPageNeedSomeHelp',
    defaultMessage: 'Need some help?',
  },
  RegisterPageInvalidTokenTitle: {
    id: 'RegisterPageInvalidTokenTitle',
    defaultMessage: 'Invalid token',
  },
  RegisterPageInvalidTokenMessage: {
    id: 'RegisterPageInvalidTokenMessage',
    defaultMessage: 'Your token is invalid. Please contact our support team so we can assist you.',
  },
});
