import { defineMessages } from 'react-intl';

export default defineMessages({
  ViewAllDealsHeaderAllListings: {
    id: 'ViewAllDealsHeaderAllListings',
    defaultMessage: 'All listings',
  },
  ViewAllDealsShowingCountListings: {
    id: 'ViewAllDealsShowingCountListings',
    defaultMessage: 'Showing {count} listings',
  },
  ViewAllDealsAddNewListing: {
    id: 'ViewAllDealsAddNewListing',
    defaultMessage: 'Add new listing',
  },
});
