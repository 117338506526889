import { Reducer } from 'redux';
import { DealStatus } from 'types';
import { convertCarFilterToDeal, convertCarToDeal } from '../utils';
import { Actions, ActionTypes } from './actions';
import { AddEditListingsState, ErrorType, initialState } from './initial-state';

export const addEditListingsReducer: Reducer<AddEditListingsState> = (
  state = initialState,
  action: Actions
): AddEditListingsState => {
  switch (action.type) {
    case ActionTypes.DUPLICATE_SELECTED_DEAL_REQUEST: {
      return {
        ...state,
        isLoadingAction: true,
      };
    }
    case ActionTypes.DUPLICATE_SELECTED_DEAL_FAILURE: {
      return {
        ...state,
        isLoadingAction: false,
        isError: true,
        errorType: ErrorType.SOMETHING_WENT_WRONG,
      };
    }
    case ActionTypes.GET_DEAL_REQUEST: {
      return {
        ...initialState,
        isLoadingDeal: true,
      };
    }
    case ActionTypes.GET_DEAL_SUCCESS: {
      return {
        ...state,
        isLoadingDeal: false,
        deal: action.payload.deal,
      };
    }
    case ActionTypes.SET_IS_LOADING_IMAGES: {
      return {
        ...state,
        isLoadingImages: action.payload,
      };
    }
    case ActionTypes.SET_DEAL_IMAGES: {
      return {
        ...state,
        dealImages: action.payload,
        isLoadingImages: false,
      };
    }
    case ActionTypes.GET_DEAL_FAILURE: {
      return {
        ...state,
        isLoadingDeal: false,
        isLoadingImages: false,
        isError: true,
        errorType: ErrorType.SOMETHING_WENT_WRONG,
      };
    }

    case ActionTypes.UPLOAD_NEW_IMAGES_REQUEST: {
      return {
        ...initialState,
        isLoadingDeal: true,
      };
    }
    case ActionTypes.UPLOAD_NEW_IMAGES_SUCCESS: {
      return {
        ...state,
        isLoadingDeal: false,
        deal: {
          ...state.deal,
          listing: {
            ...state.deal?.listing,
            images: state.deal?.listing?.images
              ? [...state.deal?.listing?.images, ...action.payload.images]
              : action.payload.images,
          },
        },
      };
    }
    case ActionTypes.UPLOAD_NEW_IMAGES_FAILURE: {
      return {
        ...state,
        isLoadingDeal: false,
        isError: true,
        errorType: ErrorType.SOMETHING_WENT_WRONG,
      };
    }

    case ActionTypes.UPDATE_DEAL_WITH_CAR_DATA: {
      const convertedDeal = convertCarToDeal(action.payload.car);

      return {
        ...state,
        deal: {
          ...state.deal,
          ...convertedDeal,
          listing: {
            ...state.deal?.listing,
            ...convertedDeal.listing,
          },
        },
      };
    }

    case ActionTypes.UPDATE_DEAL_FOR_MANUAL_ADDING: {
      return {
        ...state,
        deal: {
          ...state.deal,
          isLockedToSource: false,
          isSelectedCar: false,
          isResidualValueDefault: true,
          isOwnershipDefault: true,
        },
      };
    }

    case ActionTypes.UPDATE_DEAL_WITH_CAR_FILTER: {
      const convertedDeal = convertCarFilterToDeal(action.payload.filter);
      return {
        ...state,
        deal: convertedDeal,
      };
    }

    case ActionTypes.GET_DEAL_OWNERSHIP_REQUEST: {
      return {
        ...state,
        isError: false,
      };
    }
    case ActionTypes.GET_DEAL_OWNERSHIP_SUCCESS: {
      return {
        ...state,
        isError: false,
        defaultDealOwnership: action.payload,
      };
    }
    case ActionTypes.GET_DEAL_OWNERSHIP_FAILURE: {
      return {
        ...state,
        isError: true,
        errorType: ErrorType.SOMETHING_WENT_WRONG,
      };
    }

    case ActionTypes.GET_RESIDUAL_VALUES_REQUEST: {
      return {
        ...state,
        isLoadingResidualValues: true,
        isError: false,
      };
    }
    case ActionTypes.GET_RESIDUAL_VALUES_SUCCESS: {
      return {
        ...state,
        isLoadingResidualValues: false,
        isError: false,
        defaultResidualValues: action.payload,
      };
    }
    case ActionTypes.GET_RESIDUAL_VALUES_FAILURE: {
      return {
        ...state,
        isLoadingResidualValues: false,
        isError: true,
        errorType: ErrorType.SOMETHING_WENT_WRONG,
      };
    }
    case ActionTypes.SAVE_DRAFT_REQUEST: {
      return {
        ...state,
        isLoadingAction: true,
        isError: false,
        successfullyUpdated: false,
        isSyncNotificationVisible: false,
        draftValidationErrors: {},
        errorMessages: [],
      };
    }
    case ActionTypes.SAVE_DRAFT_SUCCESS: {
      return {
        ...state,
        isLoadingAction: false,
        isError: false,
        successfullyUpdated: true,
        unsavedChanges: false,
        errorMessages: [],
        deal: action.payload.deal
          ? action.payload.deal
          : state.deal
          ? {
              ...state.deal,
              status: DealStatus.DRAFT,
            }
          : undefined,
        dealImages: action.payload.dealImages || state.dealImages,
      };
    }
    case ActionTypes.SAVE_DRAFT_FAILURE: {
      return {
        ...state,
        isLoadingAction: false,
        successfullyUpdated: false,
        isError: true,
        errorMessages: action.payload.errorMessage,
        errorType: ErrorType.SOMETHING_WENT_WRONG,
      };
    }

    case ActionTypes.MOVE_TO_TRASH_REQUEST: {
      return {
        ...state,
        isLoadingAction: true,
        isError: false,
        successfullyUpdated: false,
        isSyncNotificationVisible: false,
      };
    }
    case ActionTypes.MOVE_TO_TRASH_SUCCESS: {
      return {
        ...state,
        isLoadingAction: false,
        isError: false,
        successfullyUpdated: true,
        unsavedChanges: false,
        deal: state.deal
          ? {
              ...state.deal,
              status: DealStatus.TRASH,
            }
          : undefined,
      };
    }
    case ActionTypes.MOVE_TO_TRASH_FAILURE: {
      return {
        ...state,
        isLoadingAction: false,
        isError: true,
        errorType: ErrorType.SOMETHING_WENT_WRONG,
        successfullyUpdated: false,
      };
    }

    case ActionTypes.PUBLISH_REQUEST: {
      return {
        ...state,
        isLoadingAction: true,
        isError: false,
        errorMessages: [],
        successfullyUpdated: false,
        isSyncNotificationVisible: false,
        draftValidationErrors: {},
      };
    }
    case ActionTypes.PUBLISH_SUCCESS: {
      return {
        ...state,
        isLoadingAction: false,
        isError: false,
        successfullyUpdated: true,
        unsavedChanges: false,
        errorMessages: [],
        deal: action.payload.deal
          ? action.payload.deal
          : state.deal
          ? {
              ...state.deal,
              status: DealStatus.PUBLISHED,
            }
          : undefined,
        dealImages: action.payload.dealImages || state.dealImages,
      };
    }
    case ActionTypes.PUBLISH_FAILURE: {
      return {
        ...state,
        isLoadingAction: false,
        errorMessages: action.payload.errorMessage,
        isError: true,
        errorType: ErrorType.SOMETHING_WENT_WRONG,
        successfullyUpdated: false,
      };
    }

    case ActionTypes.GET_CARS_REQUEST: {
      return {
        ...state,
        isLoadingCars: true,
        isError: false,
        successGotCars: false,
        isSyncNotificationVisible: false,
        draftValidationErrors: {},
        cars: undefined,
      };
    }
    case ActionTypes.GET_CARS_SUCCESS: {
      return {
        ...state,
        isLoadingCars: false,
        isError: false,
        successGotCars: true,
        unsavedChanges: false,
        cars: action.payload,
      };
    }
    case ActionTypes.GET_CARS_FAILURE: {
      return {
        ...state,
        isLoadingCars: false,
        isError: true,
        errorType: ErrorType.SOMETHING_WENT_WRONG,
        successGotCars: false,
      };
    }
    case ActionTypes.RESET_CARS:
      return {
        ...state,
        cars: undefined,
        successGotCars: false,
      };
    case ActionTypes.HIDE_SUCCESS_SAVED_ALERT_MESSAGE: {
      return {
        ...state,
        successfullyUpdated: false,
      };
    }

    case ActionTypes.SET_UNSAVED_CHANGES: {
      return {
        ...state,
        unsavedChanges: true,
      };
    }

    case ActionTypes.HIDE_SYNC_NOTIFICATION: {
      return {
        ...state,
        isSyncNotificationVisible: false,
      };
    }

    case ActionTypes.SET_IS_STEP_CHANGE_DIALOG_OPEN: {
      return {
        ...state,
        isStepChangeDialogOpen: action.payload,
      };
    }

    case ActionTypes.SET_IS_DISABLE_SYNC_DIALOG_OPEN: {
      return {
        ...state,
        isDisableSyncDialogOpen: action.payload,
      };
    }

    case ActionTypes.SET_IS_ENABLE_SYNC_DIALOG_OPEN: {
      return {
        ...state,
        isEnableSyncDialogOpen: action.payload,
      };
    }

    case ActionTypes.UPDATE_LOCKED_TO_SOURCE_REQUEST: {
      return {
        ...state,
        isError: false,
        isSyncNotificationVisible: false,
        successfullyUpdated: false,
      };
    }

    case ActionTypes.UPDATE_LOCKED_TO_SOURCE_SUCCESS: {
      return {
        ...state,
        isSyncNotificationVisible: true,
        deal: action.payload,
      };
    }

    case ActionTypes.UPDATE_LOCKED_TO_SOURCE_FAILURE: {
      return {
        ...state,
        isError: true,
        errorType: ErrorType.SOMETHING_WENT_WRONG,
        isSyncUpdateError: true,
      };
    }

    case ActionTypes.SET_IS_UPDATE_SYNC_ERROR: {
      return {
        ...state,
        isError: true,
        isSyncUpdateError: false,
      };
    }

    case ActionTypes.RESET_STATE: {
      return {
        ...initialState,
      };
    }
    case ActionTypes.SET_DRAFT_VALIDATION_ERROR: {
      return {
        ...state,
        isError: true,
        draftValidationErrors: {
          ...action.payload,
        },
      };
    }
    case ActionTypes.SET_ERROR: {
      return {
        ...state,
        isError: true,
        errorType: action.payload.errorType,
        errorMessageParams: action.payload.errorMessageParams,
        isLoadingAction: false,
      };
    }

    default: {
      return state;
    }
  }
};
