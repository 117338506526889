import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import avatar from 'assets/images/empty-avatar.png';
import classNames from 'classnames';
import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { SellerPublic, SellerStatus, UseParamsParams } from 'types';
import { Actions as ManagePeopleActions } from '../../store/actions';
import styles from './SellerRow.module.scss';
import Translations from './SellerRow.translations';

interface Props {
  seller: SellerPublic;
}

const SellerRow: FunctionComponent<Props> = ({ seller: { id, firstName, lastName, email, status } }) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { vendorId } = useParams<UseParamsParams>();

  const onSendInvite = () => {
    dispatch(ManagePeopleActions.sendSellerSignupRequestRequest(Number(vendorId), id, status));
  };

  return (
    <div className={styles.root}>
      <div className={styles.sellerInfo}>
        <img src={avatar} alt="Avatar" className={styles.avatar} />
        <div className={styles.nameAndEmail}>
          <div className={styles.name}>
            {firstName} {lastName}
          </div>
          <div>{email}</div>
        </div>
      </div>
      {status === SellerStatus.ACTIVE && (
        <div className={styles.statusContainer}>
          <div>
            <FontAwesomeIcon
              icon={['fas', 'check-circle']}
              className={classNames(styles.statusIcon, styles.activeIcon)}
            />
          </div>
          <div className={styles.status}>{formatMessage(Translations.ManagePeopleSellerRowActive)}</div>
        </div>
      )}
      {status === SellerStatus.INACTIVE && (
        <>
          <div className={styles.statusContainer}>
            <div>
              <FontAwesomeIcon
                icon={['fas', 'circle']}
                className={classNames(styles.statusIcon, styles.inactiveIcon)}
              />
            </div>
            <div className={styles.status}>{formatMessage(Translations.ManagePeopleSellerRowInactive)}</div>
          </div>
          <button className={classNames(styles.button, styles.sendInviteButton)} onClick={onSendInvite}>
            <div>
              <FontAwesomeIcon icon={['fas', 'envelope']} className={styles.buttonIcon} />
            </div>
            {formatMessage(Translations.ManagePeopleSellerRowSendInvite)}
          </button>
        </>
      )}
      {status === SellerStatus.PENDING_INVITE && (
        <>
          <div className={styles.statusContainer}>
            <div>
              <FontAwesomeIcon icon={['fas', 'clock']} className={classNames(styles.statusIcon, styles.pendingIcon)} />
            </div>
            <div className={styles.status}>{formatMessage(Translations.ManagePeopleSellerRowPendingInvite)}</div>
          </div>
          <button className={classNames(styles.button, styles.resendInviteButton)} onClick={onSendInvite}>
            <div>
              <FontAwesomeIcon icon={['fas', 'envelope']} className={styles.buttonIcon} />
            </div>
            {formatMessage(Translations.ManagePeopleSellerRowResendInvite)}
          </button>
        </>
      )}
    </div>
  );
};

export default SellerRow;
