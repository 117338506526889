import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { Fragment, FunctionComponent } from 'react';
import { compose } from 'utils/compose';
import styles from './LoadingIndicator.module.scss';
interface Props {
  isLoading: boolean;
}

const LoadingIndicator: FunctionComponent<Props> = ({ children, isLoading }) => {
  return (
    <Fragment>
      <AnimatePresence>
        {isLoading && (
          <motion.div
            initial={{ opacity: 0.5 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            key="child"
            className={styles.loadingWrapper}
          >
            <div
              className={classNames(styles.loadingBar, [
                'progress-bar',
                'progress-bar-striped',
                'progress-bar-animated',
              ])}
              role="progressbar"
              style={{ width: '100%' }}
            />
            <FontAwesomeIcon icon={['far', 'circle-notch']} spin={true} className={styles.loadingIcon} />
          </motion.div>
        )}
      </AnimatePresence>
      {children}
    </Fragment>
  );
};

export default compose<Props>(LoadingIndicator);
