import { defineMessages } from 'react-intl';

export default defineMessages({
  EnableSyncConfirmationModalEnableSyncWithAutoScout24: {
    id: 'EnableSyncConfirmationModalEnableSyncWithAutoScout24',
    defaultMessage: 'Enable sync with AutoScout24',
  },
  EnableSyncConfirmationModalEnableAutomaticListingUpdates: {
    id: 'EnableSyncConfirmationModalEnableAutomaticListingUpdates',
    defaultMessage: 'Enable automatic listing updates from AutoScout24?',
  },
  EnableSyncConfirmationModalBack: {
    id: 'EnableSyncConfirmationModalBack',
    defaultMessage: 'Back',
  },
  EnableSyncConfirmationModalYesEnableSync: {
    id: 'EnableSyncConfirmationModalYesEnableSync',
    defaultMessage: 'Yes, enable sync',
  },
  EnableSyncConfirmationModalWhatWillChange: {
    id: 'EnableSyncConfirmationModalWhatWillChange',
    defaultMessage: 'What will change?',
  },
  EnableSyncConfirmationModalYourGowagoChListingsInformationWillBeReplaced: {
    id: 'EnableSyncConfirmationModalYourGowagoChListingsInformationWillBeReplaced',
    defaultMessage:
      "Your gowago.ch listing's information will be replaced by information from your AutoScout24 listing",
  },
  EnableSyncConfirmationModalAnyFutureChangesMadeToTheAutoScout24ListingWill: {
    id: 'EnableSyncConfirmationModalAnyFutureChangesMadeToTheAutoScout24ListingWill',
    defaultMessage:
      'Any future changes made to the AutoScout24 listing will automatically be reflected in your gowago.ch listing',
  },
  EnableSyncConfirmationModalLeasingInformationAndResidualValuesInYourGowago: {
    id: 'EnableSyncConfirmationModalLeasingInformationAndResidualValuesInYourGowago',
    defaultMessage:
      'Leasing information and residual values in your gowago.ch listing will not be affected by the sync',
  },
  EnableSyncConfirmationModalItCanTakeUpToAnHourForTheseChangesToTakeEffect: {
    id: 'EnableSyncConfirmationModalItCanTakeUpToAnHourForTheseChangesToTakeEffect',
    defaultMessage: 'It can take up to an hour for these changes to take effect',
  },
});
