import { createStyles } from '@material-ui/core';
import { GowagoTheme } from 'theme';

export const styles = (theme: GowagoTheme) =>
  createStyles({
    root: {},
    title: {
      fontSize: theme.gowago.fontSize.larger,
      lineHeight: theme.gowago.lineHeight.rem[27],

      '&:not(:first-of-type)': {
        marginTop: theme.gowago.spacing.larger,
      },
    },
    row: {
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
    fileUploadRoot: {
      height: '100%',
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'space-between',
    },
  });
