import ButtonBase from 'components/Forms/ButtonBase';
import TextField from 'components/Forms/TextField';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { useQuery } from 'hooks/router';
import { Actions as GlobalActions } from 'pages/_store/global/actions';
import { RootState } from 'pages/_store/root-reducer';
import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { ResetPasswordErrorType } from '../../_store/global/initial-state';
import ContactSupportLink from '../SharedComponents/ContactSupportLink';
import PublicHeader from '../SharedComponents/PublicHeader';
import SuccessCard from '../SharedComponents/SuccessCard';
import styles from './SetNewPasswordPage.module.scss';
import Translations from './translations';

interface SetNewPasswordForm {
  password: string;
  passwordRepeated: string;
}

type QueryProps = Partial<{
  token: string;
  email: string;
}>;

const SetNewPasswordPage: FC = () => {
  const { formatMessage } = useIntl();
  const { token, email } = useQuery<QueryProps>({
    decoder: (s) => s,
  });
  const [isUrlInvalid, setUrlInvalid] = useState(false);

  useEffect(() => {
    if (!token || !email) {
      setUrlInvalid(true);
    }
  }, [token, email]);
  const dispatch = useDispatch();

  const {
    globalAppState: { isPasswordResetSuccess, isPasswordRequestFetching, resetPasswordError },
  } = useSelector((state: RootState) => state, shallowEqual);

  const handleFormSubmit = async (values: SetNewPasswordForm, formikHelpers: FormikHelpers<SetNewPasswordForm>) => {
    await dispatch(
      GlobalActions.userResetPasswordRequest({
        ...values,
        email: email || '',
        token: token || '',
      })
    );
    formikHelpers.setSubmitting(false);
  };

  const SetNewPasswordFormSchema = Yup.object().shape({
    password: Yup.string().required(formatMessage(Translations.SetNewPasswordPageThisFieldIsRequired)),
    passwordRepeated: Yup.string()
      .required(formatMessage(Translations.SetNewPasswordPageThisFieldIsRequired))
      .when('password', {
        is: (val) => !!(val && val.length > 0),
        then: Yup.string().oneOf([Yup.ref('password')], formatMessage(Translations.SetNewPasswordPagePasswordNotEqual)),
      }),
  });

  const renderApiError = (error: ResetPasswordErrorType) => {
    switch (error) {
      case ResetPasswordErrorType.TOKEN_INVALID:
        return formatMessage(Translations.SetNewPasswordPageTokenInvalid);
      case ResetPasswordErrorType.EMAIL_INVALID:
        return formatMessage(Translations.SetNewPasswordPageEmailInvalid);
      case ResetPasswordErrorType.TOKEN_EXPIRED:
        return formatMessage(Translations.SetNewPasswordPageTokenExpired);
      default:
        return '';
    }
  };

  if (isPasswordResetSuccess)
    return <SuccessCard title={formatMessage(Translations.SetNewPasswordPageYourNewPasswordHasBeenSet)} />;
  if (isUrlInvalid)
    return (
      <SuccessCard
        title={formatMessage(Translations.SetNewPasswordPageInvalidUrlHeader)}
        subTitle={formatMessage(Translations.SetNewPasswordPageInvalidUrlMessage)}
      />
    );
  return (
    <div className={styles.root}>
      <PublicHeader title={formatMessage(Translations.SetNewPasswordPageSetANewPassword)} />
      <div className={styles.card}>
        <Formik
          initialValues={{ password: '', passwordRepeated: '' }}
          validationSchema={SetNewPasswordFormSchema}
          onSubmit={handleFormSubmit}
          render={(props: FormikProps<SetNewPasswordForm>) => (
            <form onSubmit={props.handleSubmit}>
              <TextField
                name="password"
                type="password"
                label={formatMessage(Translations.SetNewPasswordPageNewPassword)}
              />
              <TextField
                name="passwordRepeated"
                type="password"
                label={formatMessage(Translations.SetNewPasswordPageRepeatPassword)}
              />
              {!!resetPasswordError && <div className={styles.apiErrorLabel}>{renderApiError(resetPasswordError)}</div>}
              <div className={styles.submitButtonContainer}>
                <ButtonBase
                  type="submit"
                  className={styles.submitButton}
                  isLoading={isPasswordRequestFetching}
                  disabled={isPasswordRequestFetching}
                >
                  {formatMessage(Translations.SetNewPasswordPageSetPassword)}
                </ButtonBase>
              </div>
            </form>
          )}
        />
      </div>
      <ContactSupportLink label={formatMessage(Translations.SetNewPasswordPageNeedSomeHelp)} />
    </div>
  );
};

export default SetNewPasswordPage;
