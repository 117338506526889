import { makeToWritten } from '@carcodex/gow-shared-mono/modules/car/car.constants';
import noCarImage from 'assets/images/no-car-image.png';
import Card from 'components/Card';
import DataGrid from 'components/DataGrid';
import DataGridHeader from 'components/DataGrid/components/DataGridHeader';
import DataGridHeaderItem from 'components/DataGrid/components/DataGridHeaderItem';
import DataGridPagination from 'components/DataGrid/components/DataGridPagination';
import DataGridRow from 'components/DataGrid/components/DataGridRow';
import DataGridRowItem from 'components/DataGrid/components/DataGridRowItem';
import { routeConstants } from 'constants/routes';
import { useParams, useQuery, useUpdateQuery } from 'hooks/router';
import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { CarDealItemProps, DealSorting, DealStatus, DefaultVendorParams, RolesEnum, SortingOrder } from 'types';
import { RootState } from '../../../_store/root-reducer';
import { Actions as DealsResultActions } from '../../store/actions';
import { DealFilters, initialState } from '../../store/initial-state';
import HeaderNavigation from '../HeaderNavigation';
import styles from './DealsResults.module.scss';
import Translations from './DealsResults.translations';

type DealSortingKeys = keyof DealSorting;

interface Props {
  onSearchTermChange: (term: string) => void;
}

const DealsResults: FC<Props> = ({ onSearchTermChange }) => {
  const { formatMessage } = useIntl();
  const [isSelectedAll, setSelectedAll] = useState(false);

  const dispatch = useDispatch();
  const query = useQuery<Partial<DealFilters>>({
    initialObj: initialState.filters,
  });
  const params = useParams<DefaultVendorParams>();
  const vendorId = params.vendorId ? Number(params.vendorId) : undefined;

  const {
    results: listingResults,
    filters: { $skip, $limit, sorting, status, source, query: search },
    countFiltered,
    isDealResultLoading,
    isDuplicateLoading,
    selectedDeals,
  } = useSelector((state: RootState) => state.dealResultsState, shallowEqual);
  const {
    user: { roles },
  } = useSelector((state: RootState) => state.globalAppState, shallowEqual);

  const isUserAdmin = roles?.includes(RolesEnum.SUPER_ADMIN) || roles?.includes(RolesEnum.ADMIN);

  useEffect(() => {
    setSelectedAll(false);
  }, [listingResults]);

  const replaceQuery = useUpdateQuery<DealFilters>({
    replace: true,
    replaceQuery: true,
    initialState,
  });

  const prevLinkQuery = {
    $skip: $skip - $limit,
    sorting,
    status,
    source,
    query: search || undefined,
  };
  const prevLink = vendorId
    ? routeConstants.PROTECTED.VIEW_DEALS_PAGE.url(vendorId, prevLinkQuery)
    : routeConstants.PROTECTED.VIEW_ALL_DEALS_PAGE.url(prevLinkQuery);

  const nextLinkQuery = {
    $skip: $skip + $limit,
    sorting,
    status,
    source,
    query: search || undefined,
  };

  const nextLink = vendorId
    ? routeConstants.PROTECTED.VIEW_DEALS_PAGE.url(vendorId, nextLinkQuery)
    : routeConstants.PROTECTED.VIEW_ALL_DEALS_PAGE.url(nextLinkQuery);

  const generateStepLink = (page: number) => {
    const stepLinkQuery = {
      $skip: $limit * page,
      sorting,
      status,
      source,
      query: search || undefined,
    };

    return vendorId
      ? routeConstants.PROTECTED.VIEW_DEALS_PAGE.url(vendorId, stepLinkQuery)
      : routeConstants.PROTECTED.VIEW_ALL_DEALS_PAGE.url(stepLinkQuery);
  };
  const updateQuery = useUpdateQuery<DealFilters>({
    replace: true,
    initialState,
  });

  const handleSortClick = (fieldName: DealSortingKeys) => (sortValue: SortingOrder | undefined) => {
    updateQuery({
      sorting: {
        [fieldName]: sortValue,
      },
    });
  };

  const parseStatus = (status: DealStatus) => {
    switch (status) {
      case DealStatus.PUBLISHED:
        return formatMessage(Translations.ListingResultsPublished);
      case DealStatus.DRAFT:
        return formatMessage(Translations.ListingResultsDraft);
      case DealStatus.TRASH:
        return formatMessage(Translations.ListingResultsDeleted);
      case DealStatus.ERROR:
        return formatMessage(Translations.ListingResultsError);
      default:
        return '';
    }
  };

  const handleOnReset = () => {
    onSearchTermChange('');
    replaceQuery({});
  };

  const handleOnItemCheck = (itemId: number) => (checked: boolean) => {
    if (selectedDeals.includes(itemId)) {
      dispatch(DealsResultActions.updateSelectedDeals(selectedDeals.filter((id) => id !== itemId)));
    } else {
      dispatch(DealsResultActions.updateSelectedDeals([...selectedDeals, itemId]));
    }
  };
  const handleSelectAllItem = () => {
    if (isSelectedAll) {
      dispatch(DealsResultActions.updateSelectedDeals([]));
      setSelectedAll(false);
    } else {
      dispatch(DealsResultActions.updateSelectedDeals(listingResults.map((item) => item.id)));
      setSelectedAll(true);
    }
  };

  const handleOnClickNext = () => {
    updateQuery({
      $skip: $skip + $limit,
    });
  };
  const handleOnClickPrev = () => {
    const skip = $skip - $limit;
    if (skip < 0) {
      return;
    }

    updateQuery({
      $skip: $skip - $limit,
    });
  };
  return (
    <div className={styles.root}>
      <HeaderNavigation
        onClickNext={handleOnClickNext}
        onClickPrev={handleOnClickPrev}
        $limit={$limit}
        $total={countFiltered}
        $skip={$skip}
      />
      <Card>
        <DataGrid
          isLoading={isDealResultLoading || isDuplicateLoading}
          isEmptyResult={!isDealResultLoading && listingResults.length === 0}
          onButtonReset={handleOnReset}
          hideResetButton={Object.keys(query).length <= 0}
        >
          <DataGridHeader
            withSelection={
              isUserAdmin
                ? {
                    onChange: handleSelectAllItem,
                    checked: isSelectedAll,
                  }
                : undefined
            }
          >
            <DataGridHeaderItem
              size={10}
              extraStyle={{
                minWidth: 100,
              }}
            />
            <DataGridHeaderItem size={'auto'} sort={sorting.makeAndModel} onSortClick={handleSortClick('makeAndModel')}>
              {formatMessage(Translations.DealsResultsNameHeader)}
            </DataGridHeaderItem>
            <DataGridHeaderItem
              size={3}
              sort={sorting.status}
              onSortClick={handleSortClick('status')}
              extraStyle={{
                minWidth: 130,
              }}
            >
              {formatMessage(Translations.DealsResultsStatusHeader)}
            </DataGridHeaderItem>
            <DataGridHeaderItem
              size={3}
              sort={sorting.price}
              onSortClick={handleSortClick('price')}
              extraStyle={{
                minWidth: 148,
              }}
            >
              {formatMessage(Translations.DealsResultsVehiclePriceHeader)}
            </DataGridHeaderItem>
            <DataGridHeaderItem size={20} sort={sorting.createdAt} onSortClick={handleSortClick('createdAt')}>
              {formatMessage(Translations.DealsResultsDateAddedHeader)}
            </DataGridHeaderItem>
          </DataGridHeader>
          {listingResults.map((item: CarDealItemProps) => (
            <DataGridRow
              key={item.publicId}
              withSelection={isUserAdmin}
              checkboxValue={selectedDeals.includes(item.id)}
              onCheck={handleOnItemCheck(item.id)}
              to={routeConstants.PROTECTED.EDIT_LISTINGS_PAGE.url(item.vendor, item.id)}
            >
              <DataGridRowItem
                size={10}
                extraStyle={{
                  minWidth: 100,
                }}
              >
                <img
                  src={item.imageMain ? item.imageMain.thumbnail : noCarImage}
                  className={styles.imageRow}
                  alt={`${item.make} ${item.model}`}
                />
              </DataGridRowItem>
              <DataGridRowItem size={'auto'}>
                <div>
                  <div className={styles.rowBrandModelLabel}>{`${makeToWritten[item.make]} ${item.model}`}</div>
                  <div className={styles.rowEngineNameLabel}>{`${item.engine}`}</div>
                  <div className={styles.rowGowagoId}>
                    {formatMessage(Translations.DealsResultsGowagoID)}: {item.publicId}
                  </div>
                </div>
              </DataGridRowItem>
              <DataGridRowItem
                size={3}
                extraStyle={{
                  minWidth: 130,
                }}
              >
                {parseStatus(item.status)}
              </DataGridRowItem>
              <DataGridRowItem
                size={3}
                extraStyle={{
                  minWidth: 148,
                }}
              >{`CHF ${item?.price?.toLocaleString('de-CH') || 0}`}</DataGridRowItem>
              <DataGridRowItem size={20}>{new Date(item.createdAt).toLocaleDateString('de-CH')}</DataGridRowItem>
            </DataGridRow>
          ))}
          <DataGridPagination
            $total={countFiltered}
            $skip={$skip}
            $limit={$limit}
            generateStepLink={generateStepLink}
            nextLink={nextLink}
            prevLink={prevLink}
          />
        </DataGrid>
      </Card>
    </div>
  );
};

export default DealsResults;
