import { Fragment, FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { compose } from 'utils/compose';
import styles from './NoResultWrapper.module.scss';
import Translations from './NoResultWrapper.translations';

interface Props {
  isEmptyResult?: boolean;
  onButtonReset?: () => void;
  hideResetButton?: boolean;
}

const NoResultWrapper: FunctionComponent<Props> = ({ children, isEmptyResult, onButtonReset, hideResetButton }) => {
  const { formatMessage } = useIntl();
  return (
    <Fragment>
      {isEmptyResult && (
        <div className={styles.wrapper}>
          <div className={styles.wrapperInner}>
            <div className={styles.wrapperMessage}>
              {formatMessage(Translations.NoResultWrapperWeFound0ResultForYourQuery)}
            </div>
            {!hideResetButton && (
              <button className={styles.resetBtn} onClick={onButtonReset}>
                {formatMessage(Translations.NoResultWrapperResetFilters)}
              </button>
            )}
          </div>
        </div>
      )}
      {children}
    </Fragment>
  );
};

export default compose<Props>(NoResultWrapper);
