import { defineMessages } from 'react-intl';

export default defineMessages({
  GenericFormMessageFieldRequired: {
    id: 'GenericFormMessageFieldRequired',
    defaultMessage: 'This field is required',
  },
  GenericFormMessageInvalidEmail: {
    id: 'GenericFormMessageInvalidEmail',
    defaultMessage: 'Invalid email format',
  },
  GenericFormMessageEmailPlaceholder: {
    id: 'GenericFormMessageEmailPlaceholder',
    defaultMessage: 'Enter your email',
  },
  GenericFormMessageNewPasswordLabel: {
    id: 'GenericFormMessageNewPasswordLabel',
    defaultMessage: 'New password',
  },
  GenericFormMessageRepeatPasswordLabel: {
    id: 'GenericFormMessageRepeatPasswordLabel',
    defaultMessage: 'Repeat password',
  },
  ChunkLoadError: {
    id: 'ChunkLoadError',
    defaultMessage: 'Something went wrong. Try to reload the page.',
  },
  Yes: {
    id: 'Yes',
    defaultMessage: 'Yes',
  },
  No: {
    id: 'No',
    defaultMessage: 'No',
  },
});
