import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TextArea from 'components/Forms/TextArea';
import { Actions as GlobalActions } from 'pages/_store/global/actions';
import { RootState } from 'pages/_store/root-reducer';
import { FC, Fragment } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import AllInOneSectionTranslations from '../AllInOneSection.translations';
import styles from './AllInOneCosts.module.scss';
import Translations from './AllInOneCosts.translations';
import RecordValuesTable from './RecordValuesTable';
import ValuesTable from './ValuesTable';

interface Props {
  asJSON: boolean;
}

const AllInOneCosts: FC<Props> = ({ asJSON }) => {
  const { formatMessage } = useIntl();
  const { deal } = useSelector((state: RootState) => state.addEditListingsPage, shallowEqual);
  const dispatch = useDispatch();

  const costs = deal?.listing?.aio?.costs;

  const handleCopyButtonClick = () => {
    navigator.clipboard.writeText(JSON.stringify(costs, undefined, 2));
    dispatch(
      GlobalActions.sendNotification({
        message: formatMessage(AllInOneSectionTranslations.AllInOneSectionCopied),
        variant: 'info',
        persist: false,
        preventDuplicate: true,
      })
    );
  };

  return costs ? (
    <div className="AllInOneCosts">
      <p className={styles.title}>{formatMessage(Translations.AllInOneCostsCosts)}</p>
      {asJSON ? (
        <>
          <button onClick={handleCopyButtonClick} disabled={!costs} type="button" className={styles.copyButton}>
            <FontAwesomeIcon icon={faCopy} className={styles.copyIcon} />
            {formatMessage(AllInOneSectionTranslations.AllInOneSectionCopy)}
          </button>
          <TextArea rows={20} readOnly style={{ resize: 'none' }}>
            {JSON.stringify(costs, undefined, 2)}
          </TextArea>
        </>
      ) : (
        <>
          <ValuesTable
            name="insurance"
            title={formatMessage(Translations.AllInOneCostsInsurance)}
            data={costs?.insurance}
          />
          <RecordValuesTable
            name="taxes"
            title={formatMessage(Translations.AllInOneCostsTaxes)}
            columnTitle=""
            data={costs?.taxes}
          />
          <RecordValuesTable
            name="service"
            title={formatMessage(Translations.AllInOneCostsService)}
            columnTitle={formatMessage(Translations.AllInOneCostsKMPeriod)}
            data={costs?.service}
          />
          <RecordValuesTable
            name="tyres"
            title={formatMessage(Translations.AllInOneCostsTyres)}
            columnTitle={formatMessage(Translations.AllInOneCostsKMPeriod)}
            data={costs?.tyres}
          />
          <RecordValuesTable
            name="warranty"
            title={formatMessage(Translations.AllInOneCostsWarranty)}
            columnTitle={formatMessage(Translations.AllInOneCostsKMPeriod)}
            data={costs?.warranty}
          />
          <p>
            <span className={styles.rowTitle}>{formatMessage(Translations.AllInOneCostsVignette)}:</span>
            &nbsp;
            {costs?.vignette}
          </p>
        </>
      )}
      <div className={styles.divider} />
    </div>
  ) : (
    <Fragment />
  );
};

export default AllInOneCosts;
