import { createFile } from 'components/Forms/FileUpload/FileUpload.utils';
import {
  AIOPackageName,
  Deal,
  DealRequest,
  DealResultsResponse,
  DealStatus,
  DefaultResidualValuesRequest,
  ImageGalleryElement,
  LeasingMileage,
  LeasingPeriod,
  OwnershipType,
  ResidualValue,
  UpdateDealResponse,
} from 'types';
import { createImgUrl, getImgOptimizerFileInfo } from 'utils/image';
import { DealFilters } from '../pages/ViewAllDeals/store/initial-state';
import ApiClient from './apiClient';

export interface BatchUpdateDealStatusQuery {
  id: number;
  status: DealStatus;
}

export interface ListingDetailsApiRequest {
  id: string;
  zip: string;
  leasingPeriod: LeasingPeriod;
  leasingMileage: LeasingMileage;
  downPayment: number;
  canton?: string;
  ownershipType: OwnershipType;
}

export interface OfferPartnerDealApiRequest {
  id: string;
  zip?: string;
  leasingPeriod?: LeasingPeriod;
  leasingMileage: LeasingMileage;
  downPayment: number;
  canton?: string;
  ownershipType: OwnershipType;
  deliveryFee?: number;
  accessoriesPrice?: number;
  initialCarPrice?: number;
  aioPackageName?: AIOPackageName;
}

export function queryDeals(
  vendorId: number,
  { status, $skip, $limit, query, source, sorting, closedUserGroup, specialDealType }: DealFilters
): Promise<DealResultsResponse> {
  return ApiClient.post('deal/search', {
    $skip: Number($skip),
    $limit: Number($limit),
    query,
    vendorId: Number(vendorId),
    status: status?.length ? status : undefined,
    source: source?.length ? source : undefined,
    closedUserGroup,
    specialDealType: specialDealType?.length ? specialDealType : undefined,
    sorting,
  });
}

export const getDeal = async (id: number): Promise<Deal> => {
  return await ApiClient.get(`deal/${id}`);
};

export const getResidualValues = async (data: DefaultResidualValuesRequest): Promise<ResidualValue[]> => {
  return await ApiClient.post(`deal/residual-values`, data);
};

export const createDeal = async (data: DealRequest): Promise<Deal> => {
  return await ApiClient.post('deal', data);
};

export const updateDeal = async (id: number, data: Partial<DealRequest>): Promise<UpdateDealResponse> => {
  return await ApiClient.patch(`deal/${id}`, data);
};

export const uploadImages = async (id: number, images: File[]): Promise<ImageGalleryElement[]> => {
  const formData = new FormData();
  for (const image of images) {
    formData.append('upload', image);
  }
  return await ApiClient.uploadFormData(`deal/images/${id}`, formData);
};

export const getImages = async (images: ImageGalleryElement[]): Promise<File[]> => {
  const promises: Array<Promise<{ index: number; file: File }>> = [];
  images.forEach((image, index) => {
    promises.push(
      (async () => {
        const thumbnail = createImgUrl(image.images.thumbnail, { width: 256, quality: 80, format: 'webp' });
        try {
          return { index, file: await createFile(thumbnail, { getOptions: getImgOptimizerFileInfo }) };
        } catch (e) {
          console.log(`Failed retrieving a file: ${thumbnail}'`);
          return Promise.resolve(null);
        }
      })()
    );
  });
  return (await Promise.all(promises))
    .filter((p) => !!p)
    .sort((i1, i2) => i1.index - i2.index)
    .map((item) => item.file);
};

export function batchUpdateDealStatus(deals: BatchUpdateDealStatusQuery[]) {
  return ApiClient.patch('deal', [...deals]);
}
