import { createStyles } from '@material-ui/core';
import { GowagoTheme } from 'theme';

export const styles = (theme: GowagoTheme) =>
  createStyles({
    closeFloatingIcon: {
      position: 'fixed',
      fontSize: 36,
      top: 4,
      right: 8,
      color: theme.gowago.white,
      zIndex: 10000,
      cursor: 'pointer',
    },
    hidden: {
      display: 'none',
    },
  });
