import avatar from 'assets/images/empty-avatar.png';
import { ReactComponent as Logo } from 'assets/images/gowago_logo_ch_small_light.svg';
import { ReactComponent as LogoStaging } from 'assets/images/staging_small_light.svg';
import classNames from 'classnames';
import { routeConstants } from 'constants/routes';
import { Actions as AddEditListingsActions } from 'pages/AddEditListings/store/actions';
import DealResultsSelectors from 'pages/ViewAllDeals/store/selectors';
import { GlobalAppState } from 'pages/_store/global/initial-state';
import GlobalSelectors from 'pages/_store/global/selectors';
import { RootState } from 'pages/_store/root-reducer';
import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { DealStatus, Vendor, VendorStatus } from 'types';
import { compose } from 'utils/compose';
import { isStaging } from 'utils/environment';
import NavItem from './components/NavItem';
import NavItemSingleLink from './components/NavItemSingleLink';
import styles from './SidePanel.module.scss';
import Translations from './SidePanel.translations';

const SidePanel: FunctionComponent = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const {
    mainUI: { isSidePanelVisible },
    user: { firstName, lastName, email },
  } = useSelector<RootState, GlobalAppState>((state) => state.globalAppState, shallowEqual);
  const vendor = useSelector<RootState, Vendor>((state) => DealResultsSelectors.getSelectedVendor(state), shallowEqual);
  const isUserAdmin = useSelector<RootState, boolean>(GlobalSelectors.getIsAdmin, shallowEqual);
  const showManagePeople = useSelector<RootState, boolean>(
    (state) => !!state.managePeoplePage.sellers.length,
    shallowEqual
  );

  const handleAddListingClick = () => {
    dispatch(AddEditListingsActions.getDealSuccess());
    dispatch(AddEditListingsActions.setDealImages([]));
  };

  return (
    <div
      className={classNames(styles.root, {
        [styles.hidden]: !isSidePanelVisible,
      })}
    >
      <div
        className={classNames(styles.content, {
          [styles.expand]: !isSidePanelVisible,
        })}
      >
        <div className={styles.brandContainer}>{isStaging() ? <LogoStaging /> : <Logo />}</div>
        <div className={styles.avatarContainer}>
          <img src={avatar} alt={'Avatar'} />
          <div className={styles.userFullname}>{firstName && lastName ? `${firstName} ${lastName}` : email}</div>
          {isUserAdmin && (
            <div className={styles.userVendorGroup}>{formatMessage(Translations.SidePanelSuperAdmin)}</div>
          )}
        </div>

        {isUserAdmin && (
          <div className={styles.listingBorderlessMenuContainer}>
            <div className={styles.navHeader}>{formatMessage(Translations.SidePanelAdminTools)}</div>
            <NavItemSingleLink
              title={formatMessage(Translations.SidePanelAllListings)}
              titleIcon={['fas', 'list']}
              link={routeConstants.PROTECTED.VIEW_ALL_DEALS_PAGE.url({
                status: [DealStatus.PUBLISHED],
                $skip: 0,
              })}
            />
            <NavItemSingleLink
              title={formatMessage(Translations.SidePanelAllVendors)}
              titleIcon={['fas', 'users']}
              link={routeConstants.PROTECTED.VIEW_ALL_VENDORS_PAGE.url({
                vendorStatus: [VendorStatus.ACTIVE],
              })}
            />
            <NavItemSingleLink
              title={formatMessage(Translations.SidePanelAllOrders)}
              titleIcon={['fas', 'shopping-cart']}
              link={routeConstants.PROTECTED.VIEW_ORDERS_PAGE.route}
            />
          </div>
        )}

        {vendor && (
          <div className={isUserAdmin ? styles.listingMenuContainer : styles.listingMenuContainerNormalUser}>
            <div className={styles.listingHeaderTitle}>{vendor?.name}</div>
            <div className={styles.navHeader}>{formatMessage(Translations.SidePanelListings)}</div>
            <NavItemSingleLink
              title={formatMessage(Translations.SidePanelAllListings)}
              titleIcon={['fas', 'list']}
              link={routeConstants.PROTECTED.VIEW_DEALS_PAGE.url(vendor?.id)}
            />
            {isUserAdmin && (
              <NavItemSingleLink
                onClick={handleAddListingClick}
                title={formatMessage(Translations.SidePanelAddListing)}
                titleIcon={['fas', 'plus']}
                link={routeConstants.PROTECTED.ADD_LISTINGS_PAGE.url(vendor?.id)}
              />
            )}
            <div className={styles.navHeader}>{formatMessage(Translations.SidePanelOrders)}</div>
            <NavItemSingleLink
              title={formatMessage(Translations.SidePanelAllOrders)}
              titleIcon={['fas', 'shopping-cart']}
              link={routeConstants.PROTECTED.VIEW_VENDOR_ORDERS.url(vendor?.id)}
            />
            {isUserAdmin && (
              <>
                <div className={styles.navHeader}>{formatMessage(Translations.SidePanelVendorManagement)}</div>
                {showManagePeople && (
                  <NavItemSingleLink
                    title={formatMessage(Translations.SidePanelManagePeople)}
                    titleIcon={['fas', 'users']}
                    link={routeConstants.PROTECTED.MANAGE_PEOPLE.url(vendor?.id)}
                  />
                )}
                <NavItem
                  title={formatMessage(Translations.SidePanelVendorSettings)}
                  titleIcon={['fas', 'cog']}
                  rootLink={routeConstants.PROTECTED.VENDOR_SETTINGS_ROOT_PAGE.route}
                  subLinks={[
                    {
                      title: formatMessage(Translations.SidePanelVendorInformation),
                      link: routeConstants.PROTECTED.VENDOR_SETTINGS_VENDOR_INFORMATION_PAGE.url(vendor?.id),
                    },
                  ]}
                />
              </>
            )}
          </div>
        )}
        <div className={styles.listingBorderlessMenuContainer}>
          <div className={styles.navHeader}>{formatMessage(Translations.SidePanelSettingsHeader)}</div>
          <NavItemSingleLink
            title={formatMessage(Translations.SidePanelYourProfile)}
            titleIcon={['fas', 'user']}
            link={routeConstants.PROTECTED.PROFILE.route}
            exact={false}
          />
          <div className={styles.navHeader}>{formatMessage(Translations.SidePanelHelpAndSupport)}</div>
          <NavItemSingleLink
            title={formatMessage(Translations.SidePanelContactSupport)}
            titleIcon={['fas', 'phone']}
            link={routeConstants.PROTECTED.CONTACT_SUPPORT.url()}
          />
        </div>
      </div>
    </div>
  );
};

export default compose(SidePanel);
