import { findVendor } from 'api/vendor';
import { RootState } from 'pages/_store/root-reducer';
import { fork, put, takeEvery } from 'redux-saga/effects';
import { call, select } from 'typed-redux-saga';
import { VendorQuery } from 'types';
import { Actions as ViewAllVendorsAction, ActionTypes as ViewAllVendorActionTypes } from './actions';

const getViewAllListingsQuery = (state: RootState): VendorQuery => state.viewAllVendorsPage.query;

function* viewVendorRequestAsync(action: ReturnType<typeof ViewAllVendorsAction.viewVendorsRequest>) {
  try {
    window.scrollTo(0, 0); //scroll to top before request
    const vendorQuery = yield select(getViewAllListingsQuery);
    const res = yield* call(findVendor, {
      ...vendorQuery,
      ...action.payload,
    });
    yield put(ViewAllVendorsAction.viewVendorsSuccess(res));
  } catch (e) {
    yield put(ViewAllVendorsAction.viewVendorsFailure(e));
  }
}

function* watchViewVendorRequestAsync() {
  yield takeEvery(ViewAllVendorActionTypes.VIEW_VENDORS_REQUEST, viewVendorRequestAsync);
}

export default [fork(watchViewVendorRequestAsync)];
