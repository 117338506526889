import { StyledComponentProps, withStyles, WithStyles } from '@material-ui/core';
import clsx from 'clsx';
import MonthYearSelection from 'components/Forms/MonthYearSelection';
import TextField from 'components/Forms/TextField';
import { useFormikContext } from 'formik';
import { AddEditVehicleInformationShema } from 'pages/AddEditListings/schema';
import { Actions as AddEditListingsActions } from 'pages/AddEditListings/store/actions';
import { DefaultAddEditListingParams } from 'pages/AddEditListings/utils';
import { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { compose } from 'utils/compose';
import { getNumberOrNull } from 'utils/form';
import AddVehicleInformationSubmit from '../AddVehicleInformationSubmit';
import { styles } from './ByModelDesignationNumber.styles';
import Translations from './ByModelDesignationNumber.translations';

interface Props {
  onAddInfoManually: () => void;
}

const ByModelDesignationNumber: FC<Props & WithStyles<typeof styles>> = ({ classes, onAddInfoManually }) => {
  const { handleSubmit, setFieldValue, values, resetForm } = useFormikContext<AddEditVehicleInformationShema>();
  const { vendorId, dealId } = useParams<DefaultAddEditListingParams>();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const { modelDesignationNumber, registrationDateYear } = values;

  useEffect(() => {
    resetForm();
  }, [vendorId, dealId, resetForm]);

  useEffect(() => {
    if (modelDesignationNumber) {
      const modelYear = getNumberOrNull(registrationDateYear, true);

      dispatch(
        AddEditListingsActions.getCarsRequest({
          typenschein: modelDesignationNumber,
          modelYear,
        })
      );
    }
  }, [dispatch, modelDesignationNumber, registrationDateYear]);

  const handleModelDesignationNumberChange = () => setFieldValue('registrationDateYear', undefined);

  const isMonthDatePickerDisabled = !values.modelDesignationNumber;

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.root}>
        <div className={classes.formColumn}>
          <div className={classes.formRow}>
            <TextField
              name="modelDesignationNumber"
              type="text"
              label={formatMessage(Translations.ByModelDesignationNumberModelDesignationNumber)}
              required
              onChange={handleModelDesignationNumberChange}
            />
          </div>
          <div className={clsx(classes.formRow, classes.monthYearSelectionRow)}>
            <MonthYearSelection
              label={formatMessage(Translations.ByModelDesignationNumberRegistratioOrConstructionDate)}
              yearName="registrationDateYear"
              yearValue={values.registrationDateYear}
              disabled={isMonthDatePickerDisabled}
              yearOnly
              tooltip={
                isMonthDatePickerDisabled
                  ? formatMessage(Translations.ByModelDesignationNumberEnterValidModelDesignationNumberFirst)
                  : undefined
              }
            />
          </div>
        </div>
      </div>
      <AddVehicleInformationSubmit onAddInfoManually={onAddInfoManually} />
    </form>
  );
};

export default compose<Props & StyledComponentProps>(
  ByModelDesignationNumber,
  withStyles(styles, { name: 'ByModelDesignationNumber' })
);
