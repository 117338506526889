import { CircularProgress, StyledComponentProps, withStyles, WithStyles } from '@material-ui/core';
import clsx from 'clsx';
import { FC, ReactNode } from 'react';
import { compose } from 'utils/compose';
import { styles } from './ButtonSpinner.styles';

interface Props {
  loading: boolean;
  children: ReactNode;
}

const ButtonSpinner: FC<Props & WithStyles<typeof styles>> = ({ classes, children, loading }) => {
  if (!loading) return <>{children}</>;

  return (
    <CircularProgress
      className={clsx('ButtonSpinner', classes.root)}
      style={{
        width: 30,
        height: 30,
      }}
    />
  );
};

export default compose<Props & StyledComponentProps>(ButtonSpinner, withStyles(styles, { name: 'ButtonSpinner' }));
