import DateFnsUtils from '@date-io/date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers/';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { WrapperVariant } from '@material-ui/pickers/wrappers/Wrapper';
import classNames from 'classnames';
import { DATE_PICKER_FORMAT_VIEW } from 'constants/constants';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';
import frLocale from 'date-fns/locale/fr';
import { FC, useEffect, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { DateFormat } from 'types';
import { compose } from 'utils/compose';
import { formatDate, getUTCDateFromDate, isValidDateObject } from 'utils/form';
import { getLang } from 'utils/local-storage';
import { useStyles } from './DatePicker.styles';

interface Props {
  label: string;
  name: string;
  value: ParsableDate | null;
  disabled?: boolean;
  onChange?: (value: string | undefined) => void;
  isInput?: boolean;
  required?: boolean;
  error?: FieldError;
  variant?: WrapperVariant;
  clearable?: boolean;
}

const localeMap = {
  en: enLocale,
  fr: frLocale,
  de: deLocale,
};

const DatePicker: FC<Props> = ({
  label,
  name,
  required = false,
  disabled = false,
  value,
  onChange = () => {},
  isInput = false,
  error,
  variant,
  clearable,
}) => {
  const classes = useStyles();
  const lang = getLang();
  const [open, setIsOpen] = useState(false);

  const [innerDate, setInnerDate] = useState<Date | null>(null);

  const handleDateChange = (date: MaterialUiPickersDate, value?: string | null | undefined) => {
    if (date) {
      setInnerDate(date);

      // update form state with UTC time to avoid conversion issues on backend
      const utcDate = getUTCDateFromDate(date);
      onChange(isValidDateObject(utcDate) ? formatDate(DateFormat['YYYY-MM-DD'], utcDate) : null);
    } else {
      setInnerDate(null);
      onChange(null);
    }
  };

  useEffect(() => {
    value ? setInnerDate(new Date(value as string)) : setInnerDate(null);
  }, [value]);

  const datePickerProps = isInput
    ? {}
    : {
        onClick: () => setIsOpen(true),
        onClose: () => setIsOpen(false),
        open,
      };

  const renderHelperText = () => {
    if (!innerDate) {
      return '';
    } else if (error) {
      return error.message;
    }
  };

  return (
    <div className={classNames('DatePicker', classes.root)}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[lang]}>
        <label className={classNames(classes.label, { [classes.required]: required })}>
          {label}
          {required ? ` *` : ''}
        </label>
        <KeyboardDatePicker
          keyboardIcon={<FontAwesomeIcon icon={['far', 'calendar']} className={classNames(classes.adormentIcon)} />}
          inputProps={{
            className: classNames(classes.inputRoot, {
              [classes.cursorPointer]: !isInput,
              [classes.inputDisabled]: disabled,
            }),
          }}
          InputProps={{
            disableUnderline: true,
            name,
            disabled: !isInput,
          }}
          className={classNames(classes.pickerRoot, {
            [classes.noIcon]: isInput,
            [classes.disabled]: disabled,
          })}
          disableToolbar
          variant={variant || 'inline'}
          clearable={clearable}
          format="dd/MM/yyyy"
          readOnly
          placeholder={DATE_PICKER_FORMAT_VIEW}
          maskChar="-"
          maxDate={new Date(9999, 10, 31)}
          minDate={new Date(1971, 10, 31)}
          disabled={disabled}
          value={innerDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          error={!!error}
          helperText={renderHelperText()}
          {...datePickerProps}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default compose<Props>(DatePicker);
