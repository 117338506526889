import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonBase from 'components/Forms/ButtonBase';
import TextField from 'components/Forms/TextField';
import { Formik, FormikHelpers } from 'formik';
import { RootState } from 'pages/_store/root-reducer';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import ContactSupportLink from '../SharedComponents/ContactSupportLink';
import PublicHeader from '../SharedComponents/PublicHeader';
import SuccessCard from '../SharedComponents/SuccessCard';
import styles from './ResetPasswordPage.module.scss';
import { Actions as ResetPasswordActions } from './store/actions';
import Translations from './translations';

interface ResetPasswordForm {
  email: string;
}
const ResetPasswordPage: FC = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const {
    resetPasswordPage: { isRequestSuccess, isPasswordRequestFetching },
  } = useSelector((state: RootState) => state, shallowEqual);

  const LoginFormSchema = Yup.object().shape({
    email: Yup.string()
      .email(formatMessage(Translations.ResetPasswordPageInvalidEmailFormat))
      .required(formatMessage(Translations.ResetPasswordPageThisFieldIsRequired)),
  });
  if (isRequestSuccess)
    return (
      <SuccessCard
        title={formatMessage(Translations.ResetPasswordPageCheckYourInbox)}
        subTitle={formatMessage(Translations.ResetPasswordPageAnSuccessMessage)}
      />
    );

  const handleFormSubmition = async (values: ResetPasswordForm, formikHelpers: FormikHelpers<ResetPasswordForm>) => {
    await dispatch(ResetPasswordActions.resetPasswordRequest(values.email));
    formikHelpers.setSubmitting(false);
  };
  return (
    <div className={styles.root}>
      <PublicHeader
        title={formatMessage(Translations.ResetPasswordPageResetPassword)}
        subTitle={formatMessage(Translations.ResetPasswordPageEnterYourEmailToResetYourPassword)}
      />
      <div className={styles.card}>
        <div className={styles.backLinkContainer}>
          <FontAwesomeIcon icon={['fal', 'arrow-left']} className={styles.backIcon} />
          <Link to={'/'} className={styles.backLink}>
            {formatMessage(Translations.ResetPasswordPageBackToSignIn)}
          </Link>
        </div>
        <Formik
          initialValues={{ email: '' }}
          validationSchema={LoginFormSchema}
          onSubmit={handleFormSubmition}
          render={(props) => (
            <form onSubmit={props.handleSubmit}>
              <TextField
                name="email"
                placeholder={formatMessage(Translations.ResetPasswordPageEnterYourEmail)}
                type="email"
                label="Email"
              />
              <div className={styles.submitButtonContainer}>
                <ButtonBase
                  type="submit"
                  classes={{
                    root: styles.submitButton,
                  }}
                  isLoading={isPasswordRequestFetching}
                  disabled={isPasswordRequestFetching}
                >
                  {formatMessage(Translations.ResetPasswordPageResetPasswordSubmit)}
                </ButtonBase>
              </div>
            </form>
          )}
        />
      </div>
      <ContactSupportLink label={formatMessage(Translations.ResetPasswordPageNeedSomeHelp)} />
    </div>
  );
};

export default ResetPasswordPage;
