import { getVendorById, updateVendor } from 'api/vendor';
import { diff } from 'deep-object-diff';
import { RootState } from 'pages/_store/root-reducer';
import { fork, put, takeEvery } from 'redux-saga/effects';
import { call, select } from 'typed-redux-saga';
import { Vendor } from 'types';
import { Actions as VendorInformationAction, ActionTypes as VendorInformationActionTypes } from './actions';

function* viewVendorInfoRequestAsync(action: ReturnType<typeof VendorInformationAction.viewVendorInfoRequest>) {
  try {
    const selectedVendor = yield* call(getVendorById, action.payload.vendorId || 0);
    yield put(VendorInformationAction.viewVendorInfoSuccess(selectedVendor));
  } catch (e) {
    yield put(VendorInformationAction.viewVendorInfoFailure());
  }
}

function* watchViewVendorInfoRequestAsync() {
  yield takeEvery(VendorInformationActionTypes.VIEW_VENDOR_INFO_REQUEST, viewVendorInfoRequestAsync);
}

function* updateVendorInfoRequestAsync(action: ReturnType<typeof VendorInformationAction.updateVendorInfoRequest>) {
  try {
    const vendor = (yield* select((state: RootState) => state.vendorInformationPage.selectedVendor)) as Vendor;
    const currentVendorInfo = {
      name: vendor.name,
      phone: vendor.phone,
      street: vendor.street,
      city: vendor.city,
      zip: vendor.zip,
    };
    const vendorUpdate = {
      name: action.payload.name,
      phone: action.payload.phoneNumber,
      street: action.payload.streetAndNumber,
      city: action.payload.city,
      zip: Number(action.payload.zip),
    };

    const updatedVendorInfoFields = diff(currentVendorInfo, vendorUpdate);
    yield* call(updateVendor, vendor.id as number, updatedVendorInfoFields);
    yield put(VendorInformationAction.updateVendorInfoSuccess());
  } catch (error) {
    const isHubspotError = error.statusCode && Number(error.statusCode) >= 500 && Number(error.statusCode) <= 599;
    yield put(VendorInformationAction.updateVendorInfoFailure(isHubspotError));
  }
}

function* watchUpdateVendorInfoRequestAsync() {
  yield takeEvery(VendorInformationActionTypes.UPDATE_VENDOR_INFO_REQUEST, updateVendorInfoRequestAsync);
}

export default [fork(watchViewVendorInfoRequestAsync), fork(watchUpdateVendorInfoRequestAsync)];
