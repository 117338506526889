import { sellerSignupRequest } from 'api/seller';
import { findSellers } from 'api/vendor';
import { RootState } from 'pages/_store/root-reducer';
import { fork, put, takeEvery } from 'redux-saga/effects';
import { call, select } from 'typed-redux-saga';
import { SellerStatus } from 'types';
import { Actions as ManagePeopleActions, ActionTypes as ManagePeopleActionTypes } from './actions';

function* getSellersRequestAsync(action: ReturnType<typeof ManagePeopleActions.getSellersRequest>) {
  const vendorId = action.payload;

  try {
    const sellers = yield* call(findSellers, vendorId);

    yield put(ManagePeopleActions.getSellersSuccess(sellers));
  } catch (e) {
    yield put(ManagePeopleActions.getSellersFailure());
  }
}

function* watchGetSellersRequestAsync() {
  yield takeEvery(ManagePeopleActionTypes.GET_SELLERS_REQUEST, getSellersRequestAsync);
}

function* sendSellerSignupRequestAsync(action: ReturnType<typeof ManagePeopleActions.sendSellerSignupRequestRequest>) {
  const { vendorId, sellerId, currentStatus } = action.payload;

  try {
    yield* call(sellerSignupRequest, sellerId, vendorId);

    let sellers = yield* select((state: RootState) => state.managePeoplePage.sellers);

    if (currentStatus === SellerStatus.INACTIVE) {
      sellers = sellers.map((seller) =>
        seller.id === sellerId ? { ...seller, status: SellerStatus.PENDING_INVITE } : { ...seller }
      );
    }

    yield put(ManagePeopleActions.sendSellerSignupRequestSuccess(sellers));
  } catch (error) {
    yield put(ManagePeopleActions.sendSellerSignupRequestFailure());
  }
}

function* watchSendSellerSignupRequestAsync() {
  yield takeEvery(ManagePeopleActionTypes.SEND_SELLER_SIGNUP_REQUEST_REQUEST, sendSellerSignupRequestAsync);
}

export default [fork(watchGetSellersRequestAsync), fork(watchSendSellerSignupRequestAsync)];
