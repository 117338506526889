import { defineMessages } from 'react-intl';

export default defineMessages({
  NoResultWrapperWeFound0ResultForYourQuery: {
    id: 'NoResultWrapperWeFound0ResultForYourQuery',
    defaultMessage: 'We found 0 result for your query',
  },
  NoResultWrapperResetFilters: {
    id: 'NoResultWrapperResetFilters',
    defaultMessage: 'Reset filters',
  },
});
