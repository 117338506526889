import classNames from 'classnames';
import CheckboxBase from 'components/Forms/CheckboxBase';
import { FunctionComponent, MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'utils/compose';
import DataGridRowItem from '../DataGridRowItem';
import styles from './DataGridRow.module.scss';

interface Props {
  withSelection?: boolean;
  onCheck?: (value: boolean) => void;
  checkboxValue?: boolean;
  to?: string;
  onClick?: (data: any) => void;
  data?: any;
}

const RenderWithLink: FunctionComponent<{
  to?: string;
  checkboxValue?: boolean;
  data?: any;
  onClick?: (data: any) => void;
}> = ({ to, checkboxValue, children, data, onClick }) => {
  const handleOnToClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
    // This is to make sure that the checkbox event will be triggered if it's inside the link. By checking the target element is the checkbox container
    const classList = (event?.target as any)?.classList;
    if (classList && (classList.contains('cbSelection') || classList.contains('cbSelection-svg'))) {
      event.preventDefault();
    }
  };

  const handleOnClick = () => {
    if (onClick && data) {
      onClick(data);
    }
  };

  return to ? (
    <Link to={to} onClick={handleOnToClick} className={styles.rowLink}>
      <div
        className={classNames(styles.root, {
          [styles.rowSelected]: checkboxValue,
        })}
      >
        {children}
      </div>
    </Link>
  ) : (
    <div
      onClick={handleOnClick}
      className={classNames(styles.root, {
        [styles.rowSelected]: checkboxValue,
        [styles.selectable]: !!onClick,
      })}
    >
      {children}
    </div>
  );
};

const DataGridRow: FunctionComponent<Props> = ({
  children,
  withSelection,
  checkboxValue,
  onCheck,
  onClick,
  data,
  to,
}) => {
  const handleOnCheckChange = (value: boolean) => {
    if (onCheck) {
      onCheck(value);
    }
  };

  return (
    <RenderWithLink onClick={onClick} data={data} to={to} checkboxValue={checkboxValue}>
      {withSelection && (
        <DataGridRowItem size={10} maxWidth={40}>
          <div className={styles.selectionContainer}>
            {withSelection && (
              <CheckboxBase onChange={handleOnCheckChange} checked={checkboxValue} classId={'cbSelection'} />
            )}
          </div>
        </DataGridRowItem>
      )}
      {children}
    </RenderWithLink>
  );
};

export default compose<Props>(DataGridRow);
