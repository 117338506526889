import { Vendor } from 'types';

export interface VendorInformationState {
  filters: VendorInformationFilters;
  selectedVendor?: Vendor;
  isLoading: boolean;
  isError: boolean;
  successfullyUpdated: boolean;
}

export interface VendorInformationFilters {
  vendorId: number;
}

export const initialState: VendorInformationState = {
  filters: {
    vendorId: 0,
  },
  isLoading: false,
  isError: false,
  successfullyUpdated: false,
};
