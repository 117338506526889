import { defineMessages } from 'react-intl';

export default defineMessages({
  AddVehicleInformationTitle: {
    id: 'AddVehicleInformationTitle',
    defaultMessage: 'Add vehicle information',
  },
  AddVehicleInformationFindVehicleBy: {
    id: 'AddVehicleInformationFindVehicleBy',
    defaultMessage: 'Find vehicle by',
  },
  AddVehicleInformationModelDesignationNumber: {
    id: 'AddVehicleInformationModelDesignationNumber',
    defaultMessage: 'Model designation number',
  },
  AddVehicleInformationMakeAndModel: {
    id: 'AddVehicleInformationMakeAndModel',
    defaultMessage: 'Make and model',
  },
  AddVehicleNoCarsAvailable: {
    id: 'AddVehicleNoCarsAvailable',
    defaultMessage: 'There are no cars available for the current search criteria',
  },
});
