import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { FunctionComponent, useEffect, useState } from 'react';
import { Manager, Popper, Reference } from 'react-popper';
import { compose } from 'utils/compose';
import styles from './ButtonSelection.module.scss';

export interface MenuItem {
  value: string;
  label: string;
}

interface Props {
  id: string;
  items: MenuItem[];
  onChange: (item: MenuItem) => void;
  className?: {
    button?: string;
  };
}

const ButtonSelection: FunctionComponent<Props> = ({ id, items, onChange, children, className }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (e: Event) => {
      const buttonMenuPopperContainer = document.querySelector(`#${id}`);
      if (buttonMenuPopperContainer && !buttonMenuPopperContainer.contains(e.target as Node)) {
        setOpen(false);
      }
    };

    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open, id]);

  const handleMenuOnClick = () => setOpen(true);
  const handleMenuOptionClick = (item: MenuItem) => () => {
    setOpen(false);
    onChange(item);
  };
  return (
    <Manager>
      <Reference>
        {({ ref }) => (
          <button className={classNames(className?.button)} ref={ref} onClick={handleMenuOnClick}>
            {children}
            <FontAwesomeIcon icon={['far', open ? 'chevron-up' : 'chevron-down']} className={styles.buttonIcon} />
          </button>
        )}
      </Reference>
      {open && (
        <Popper placement="bottom">
          {({ ref, style, placement }) => {
            return (
              <div ref={ref} id={id} className={styles.popoverContainer} style={style} data-placement={placement}>
                {items.map((menuItem: MenuItem) => (
                  <button
                    className={classNames(styles.popoverMenuButton)}
                    key={menuItem.value}
                    onClick={handleMenuOptionClick(menuItem)}
                  >
                    {menuItem.label}
                  </button>
                ))}
              </div>
            );
          }}
        </Popper>
      )}
    </Manager>
  );
};

export default compose<Props>(ButtonSelection);
