import classNames from 'classnames';
import { CSSProperties, FunctionComponent } from 'react';
import { compose } from 'utils/compose';
import styles from './DataGridRowItem.module.scss';

export interface DataGridRowItemProps {
  size: number | 'auto';
  maxWidth?: number;
  rootClassname?: string;
  extraStyle?: CSSProperties;
}

const DataGridRowItem: FunctionComponent<DataGridRowItemProps> = ({
  children,
  size,
  extraStyle,
  maxWidth,
  rootClassname,
}) => {
  let gridStyle: CSSProperties = {
    flexBasis: 0,
    flexGrow: 1,
    maxWidth: '100%',
  };
  if (size === 'auto') {
    gridStyle = {
      flexBasis: 'auto',
      flexGrow: 0,
      maxWidth: 'none',
      width: '100%',
    };
  } else {
    if (typeof size === 'number') {
      const width = `0 0 ${size}%`;
      gridStyle = {
        flex: width,
        maxWidth: maxWidth ? maxWidth : `${size}%`,
      };
    } else {
      gridStyle = {
        flexBasis: size,
        maxWidth: size,
      };
    }
  }
  return (
    <div
      className={classNames(styles.root, rootClassname)}
      style={{
        ...gridStyle,
        ...extraStyle,
      }}
    >
      {children}
    </div>
  );
};

export default compose<DataGridRowItemProps>(DataGridRowItem);
