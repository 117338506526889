import Card from 'components/Card';
import Input from 'components/Forms/Input';
import { DEFAULT_DEBOUNCE_TIMEOUT } from 'constants/constants';
import debounce from 'debounce';
import { useUpdateQuery } from 'hooks/router';
import React, { FC, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { OrdersQuery } from 'types';
import { initialState } from '../store/initial-state';
import styles from './FilterPanel.module.scss';
import Translations from './ViewAllVendorsFilterPanel.translations';

interface Props {
  searchTerm: string;
  onSearchTermChange: (term: string) => void;
}

const FilterPanel: FC<Props> = ({ searchTerm, onSearchTermChange }) => {
  const { formatMessage } = useIntl();
  const updateQuery = useUpdateQuery<OrdersQuery>({
    replace: true,
    initialState,
  });

  const handleQuerySearchChangeDebounced = useCallback(
    debounce((value: string) => {
      updateQuery({
        ...initialState.query,
        query: value,
      });
    }, DEFAULT_DEBOUNCE_TIMEOUT),
    []
  );

  const handleQuerySearchChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    onSearchTermChange(evt.target.value);
    handleQuerySearchChangeDebounced(evt.target.value);
  };

  return (
    <div className={styles.root}>
      <Card>
        <div className={styles.container}>
          <div className={styles.title}>{formatMessage(Translations.ViewAllOrdersFilterPanelFilterOrders)}</div>
          <Input
            startAdornment={['far', 'search']}
            placeholder={formatMessage(Translations.ViewAllOrdersFilterPanelSearchFor)}
            onChange={handleQuerySearchChange}
            value={searchTerm}
          />
        </div>
      </Card>
    </div>
  );
};

export default FilterPanel;
