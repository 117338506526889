import { createStyles } from '@material-ui/core';
import { GowagoTheme } from 'theme';

export const styles = (theme: GowagoTheme) =>
  createStyles({
    dialogContent: {
      padding: theme.gowago.spacing[21],
    },
    dialogPaperScrollPaper: {
      ...theme.gowago.dialogs.dialogPaperScrollPaper(theme),
      maxWidth: 350,
    },
  });
