import Alert, { AlertType } from 'components/Alerts/Alert';
import ErrorMessage from 'components/Alerts/ErrorMessage';
import { RootState } from 'pages/_store/root-reducer';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Actions as AddEditListingsActins } from '../../store/actions';
import styles from './Notifications.module.scss';
import Translations from './Notifications.translations';

const Notifications = () => {
  const { showSuccessMessage, isError } = useSelector((state: RootState) => state.managePeoplePage, shallowEqual);
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const onSuccessMessageClose = () => {
    dispatch(AddEditListingsActins.hideSuccessMessage());
  };

  return (
    <>
      {showSuccessMessage && (
        <div className={styles.messageContainer}>
          <Alert
            type={AlertType.SUCCESS}
            message={formatMessage(Translations.ManagePeopleNotificationsInvitationSentSuccessfully)}
            onClose={onSuccessMessageClose}
          />
        </div>
      )}
      {isError && (
        <div className={styles.messageContainer}>
          <ErrorMessage title={formatMessage(Translations.ManagePeopleNotificationsSomethingWentWrong)}>
            <div>{formatMessage(Translations.ManagePeopleNotificationsIfThisIssuePersistPleaseContactOurSupport)}</div>
          </ErrorMessage>
        </div>
      )}
    </>
  );
};

export default Notifications;
