import { getClosedUserGroups } from 'api/closedUserGroups';
import { useEffect, useState } from 'react';
import { availableLenghtsOfLease, availableMileage, SelectOption } from 'types';
import { getLang } from 'utils/local-storage';
import messages from './PromotionsForm.translations';

export const formatContractLengthOptions = ({ formatMessage, allowedPeriods }: any) => {
  const result = availableLenghtsOfLease.map((leasePeriod) => {
    return {
      label: formatMessage(messages.PromotionsFormContractLengthMonths, { contractLength: leasePeriod }),
      value: String(leasePeriod),
      disabled: !allowedPeriods?.includes(leasePeriod),
    };
  });

  // empty value
  result.unshift({ label: formatMessage(messages.PromotionsSelectContractLength), value: '', disabled: false });
  return result;
};

export const formatAnnualMileageOptions = ({ formatMessage, allowedMilages }: any) => {
  const result = availableMileage.map((mileage) => {
    return {
      label: formatMessage(messages.PromotionsFormAnnualMileageKm, {
        mileage: mileage.toLocaleString('de-CH'),
      }),
      value: String(mileage),
      disabled: !allowedMilages?.includes(mileage),
    };
  });

  result.unshift({ label: formatMessage(messages.PromotionsSelectContractLength), value: '', disabled: false });
  return result;
};

export const useClosedUserGroupOptions = (formatMessage: any): SelectOption[] => {
  const lang = getLang();

  const defaultOption = {
    label: formatMessage(messages.PromotionsNoClosedUserGroupSelected),
    value: '',
  };
  const [options, setOptions] = useState<SelectOption[]>([defaultOption]);

  useEffect(() => {
    (async () => {
      const availableClosedUserGroups = await getClosedUserGroups(lang);
      setOptions(() => [
        defaultOption,
        ...availableClosedUserGroups.map(({ title, slug }) => ({
          label: title,
          value: slug,
        })),
      ]);
    })();
  }, [lang]);

  return options;
};
