import { defineMessages } from 'react-intl';

export default defineMessages({
  VendorResultsVendorName: {
    id: 'VendorResultsVendorName',
    defaultMessage: 'Vendor name',
  },
  VendorResultsHubspotId: {
    id: 'VendorResultsHubspotId',
    defaultMessage: 'Hubspot ID',
  },
  VendorResultsStatus: {
    id: 'VendorResultsStatus',
    defaultMessage: 'Status',
  },
  VendorResultsSelect: {
    id: 'VendorResultsSelect',
    defaultMessage: 'Select',
  },
  VendorResultsActive: {
    id: 'VendorResultsActive',
    defaultMessage: 'Active',
  },
  VendorResultsInactive: {
    id: 'VendorResultsInactive',
    defaultMessage: 'Inactive',
  },
  VendorResultsDropped: {
    id: 'VendorResultsDropped',
    defaultMessage: 'Dropped',
  },
  VendorResultsTesting: {
    id: 'VendorResultsTesting',
    defaultMessage: 'Testing',
  },
  VendorResultsActiveListingTotalPublishedTotal: {
    id: 'VendorResultsActiveListingTotalPublishedTotal',
    defaultMessage: '{activeListing} ({totalPublished} total)',
  },
});
