import { createTheme, darken } from '@material-ui/core';
import { CSSProperties } from 'react';

// http://mcg.mbitson.com/#!?organge=%23f9690e&grey=%2322313f&themename=gowago
export const orange = {
  50: '#feede2',
  100: '#fdd2b7',
  200: '#fcb487',
  300: '#fb9656',
  400: '#fa8032',
  500: '#f9690e',
  600: '#f8610c',
  700: '#f7560a',
  800: '#f64c08',
  900: '#f53b04',
  A100: '#ffffff',
  A200: '#ffece8',
  A400: '#ffc2b5',
  A700: '#ffad9c',
};

export const grey = {
  50: '#e4e6e8',
  100: '#bdc1c5',
  200: '#91989f',
  300: '#646f79',
  400: '#43505c',
  500: '#22313f',
  600: '#1e2c39',
  700: '#192531',
  800: '#141f29',
  900: '#0c131b',
  A100: '#5da6ff',
  A200: '#2a8bff',
  A400: '#0070f6',
  A700: '#0064dc',
};

const fontFamily = '"Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif';
export const gowago = {
  fontFamily,
  dawn: '#F9690E',
  white: '#FFFFFF',
  desaturatedDarkBlue: '#495057',
  lightGrey: '#fafafa',
  mediumGrey: '#ECF0F1',
  borderGrey: 'rgba(108,122,137,0.2)',
  red: '#FF4E52',
  borders: {
    red: '1px solid #FF4E52',
    lightGray: '1px solid rgba(108,122,137,0.2)',
    green: '1px solid rgba(108,122,137,0.2)',
    yellow: '1px solid rgba(108,122,137,0.2)',
    dawn: '1px solid #F9690E',
  },
  boxShadow: {
    card: '0 19px 38px 0 rgba(0,0,0,0.1)',
    cardSmaller: '0 12px 12px 0 rgba(0,0,0,0.1)',
  },
  spacing: {
    rem: '16px',
    small: '10px',
    smallest: '5px',
    standard: '15px',
    medium: '24px',
    larger: '20px',
    large: '30px',
    big: '40px',
    huge: '70px',
    '3': '0.1875rem',
    '4': '0.25rem',
    '5': '0.312rem',
    '6': '0.375rem',
    '8': '0.5rem',
    '11': '0.687rem',
    '12': '0.75rem',
    '14': '0.875rem',
    '18': '1.125rem',
    '21': '1.312rem',
    '22': '1.375rem',
    '23': '1.4375rem',
    '28': '1.75rem',
    '32': '2rem',
    '35': '2.187rem',
    '42': '2.625rem',
    '44': '2.75rem',
    '48': '3rem',
    '50': '3.125rem',
    '60': '3.75rem',
    '64': '4rem',
    '80': '5rem',
    '96': '6rem',
    '100': '6.25rem',
    '141': '8.8125rem',
  },
  fontSize: {
    smallest: '0.625rem', //10px
    mobileSmall: '0.6875rem', // 11px
    small: '0.75rem', // 12px
    mobileSmaller: '0.8125rem', // 13px
    smaller: '0.875rem', // 14px
    mobileStandard: '0.9375rem', // 15px
    standard: '1rem', // 16px
    bigger: '1.125rem', // 18px
    larger: '1.25rem', // 20px
    '22': '1.375rem', // 22px
    large: '1.5rem', // 24px,
    '26': '1.625rem',
    '28': '1.75rem',
    '29': '1.8125rem',
    '30': '1.875rem',
    largest: '2rem', // 32px,
    largeAF: '2.25rem', // 36px
    bigAF: '3rem', // 48px
  },
  links: {
    dawn: {
      color: '#F9690E',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    dawnReadOnly: (theme: GowagoTheme) => ({
      ...theme.gowago.links.dawn,
      cursor: 'initial',
      whiteSpace: 'nowrap' as any,
      fontWeight: 500,

      '&:hover': {
        textDecoration: 'none',
      },
    }),
  },
  colors: {
    blue: '#4A90E2',
    green: '#50BD2D',
    red: '#D0021B',
    buttonRed: '#E53934',
    textDisabled: 'rgba(0, 0, 0, 0.26);',
    darkGrey: '#B4BBC3',
    lightGrey: '#F1F1F3',
    lighterGray: '#dee2e6',
    lightGreen: '#EBF9F2',
    white: '#FFF',
    primaryBlue: '#2096f3',
    secondaryBg: '#2B3445',
    disabledGrey: '#DDD',
  },
  text: {
    primary: '#203a45',
    secondary: '#6c7a89',
    terciary: '#b4bbc3',
    green: '#0cac50',
    lightGreen: '#2EBC6A',
    darkGrey: '#495057',
    error: '#f44336',
    darkError: '#DD0A04',
  },
  lineHeight: {
    rem: {
      standard: '1rem',
      bigger: '2.4375rem',
      big: '1.375rem',
      '13': '0.8125rem',
      '14': '0.875rem',
      '15': '0.9375rem',
      '16': '1rem',
      '18': '1.125rem',
      '19': '1.1875rem',
      '20': '1.25rem',
      '21': '1.3125rem',
      '22': '1.375rem',
      '23': '1.4375rem',
      '24': '1.5rem',
      '25': '1.5625rem',
      '26': '1.625rem',
      '27': '1.6875rem',
      '29': '1.8125rem',
      '30': '1.875rem',
      '32': '2rem',
      '34': '2.125rem',
      '36': '2.25rem',
      '40': '2.5rem',
      '42': '2.625rem',
    },
  },
  utility: {
    golden: '#F7B80D',
    lightGreen: '#ECF9F1',
    darkGreen: '#107945',
    green: '#2DBD6A',
    red: '#FF4E52',
    lightRed: '#FFF8F6',
    darkRed: '#DD0005',
    lightYellow: '#FFF9E9',
    yellow: '#E9B334',
    darkYellow: '#7E621E',
    border: 'rgba(108,122,137,0.2)',
    whiteBorder: 'rgba(255, 255, 255, 0.3)',
  },
  truncateText: {
    whiteSpace: 'nowrap' as CSSProperties['whiteSpace'],
    overflow: 'hidden' as CSSProperties['overflow'],
    textOverflow: 'ellipsis' as CSSProperties['textOverflow'],
  },
  buttons: {
    primary: (theme: GowagoTheme) => ({
      outline: 'none',
      border: 'none',
      color: theme.gowago.colors.white,
      backgroundColor: theme.gowago.colors.primaryBlue,
      borderRadius: 5,
      cursor: 'pointer',

      '&:disabled': {
        opacity: 0.6,
        cursor: 'not-allowed',
        pointerEvents: 'all',
      },
    }),
    secondary: (theme: GowagoTheme) => ({
      color: theme.gowago.colors.primaryBlue,
      backgroundColor: theme.gowago.colors.white,
      border: `1px solid ${theme.gowago.colors.primaryBlue}`,
      borderRadius: 5,
      cursor: 'pointer',
    }),
    error: (theme: GowagoTheme) => ({
      outline: 'none',
      border: 'none',
      color: theme.gowago.colors.white,
      backgroundColor: theme.gowago.colors.buttonRed,
      borderRadius: 5,
      cursor: 'pointer',
    }),
  },
  dialogs: {
    loadingIndicator: (theme: GowagoTheme) => ({
      display: 'block',
      margin: `${theme.gowago.spacing.medium} auto`,
    }),
    dialogPaperScrollPaper: (theme: GowagoTheme) => ({
      maxWidth: 744,
      width: '100%',
      borderRadius: '10px',

      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
        borderRadius: '10px 10px 0 0',
        top: theme.gowago.spacing[32],
        paddingBottom: theme.gowago.spacing[32],
        boxSizing: 'border-box',
      },
    }),
  },
  baseButton: (color: string, theme: GowagoTheme) => ({
    backgroundColor: color,
    color: theme.gowago.white,
    border: 'none',
    padding: theme.gowago.spacing.small,
    font: 'inherit',
    cursor: 'pointer',
    outline: 'inherit',
    fontSize: theme.gowago.fontSize.standard,
    lineHeight: theme.gowago.lineHeight.rem[22],
    textAlign: 'center' as any,
    borderRadius: 5,
    '&:active': {
      backgroundColor: darken(color, 0.05),
    },
    '&:hover': {
      backgroundColor: darken(color, 0.05),
    },

    '&:disabled': {
      cursor: 'not-allowed',
      background: 'lightgray',
    },
  }),
};

const containers = {
  resetDefaultButtonStyles: {
    background: 'none',
    border: 0,
    font: 'inherit',
    lineHeight: 'normal',
    overflow: 'visible',
    paddingTop: '0.938em',
    '-webkit-appearance': 'button' /* for input */,
    '-webkit-user-select': 'none' /* for button */,
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    '&:focus': {
      outline: 'none',
    },
  },
};

const overrides = {
  MuiButton: {
    root: {
      fontFamily: 'Nunito Sans',
    },
  },
  MuiButtonBase: {
    root: {
      fontFamily: 'Nunito Sans',
      fontSize: '1rem',
    },
  },
};

export const theme = createTheme({
  gowago,
  palette: {
    primary: orange,
    secondary: grey,
  },
  typography: {
    fontFamily,
  },
  containers,
  overrides,
});

declare module '@material-ui/core/styles' {
  interface Theme {
    gowago: typeof gowago;
    containers: typeof containers;
  }
  interface ThemeOptions {
    gowago: typeof gowago;
    containers: typeof containers;
  }
}

export type GowagoTheme = typeof theme;
