import { MakeInterestRate, SelectOption, SellerPublic } from 'types';

export const selectCompareFn =
  (descending = false) =>
  (a: SelectOption, b: SelectOption) => {
    const sortIndex = descending ? -1 : 1;
    if (a.label > b.label) return 1 * sortIndex;
    if (a.label < b.label) return -1 * sortIndex;
    return 0;
  };

export const sellersComparer = (s1: SellerPublic, s2: SellerPublic) => {
  if (!s2.firstName) return 1;
  if (!s1.firstName) return -1;

  if (s1.firstName > s2.firstName) return 1;
  if (s1.firstName < s2.firstName) return -1;

  if (!s2.lastName) return 1;
  if (!s1.lastName) return -1;

  if (s1.lastName > s2.lastName) return 1;
  if (s1.lastName < s2.lastName) return -1;

  return 0;
};

export const makeInterestRateComparer = (a: MakeInterestRate, b: MakeInterestRate) => {
  if (a.make > b.make) return 1;
  if (a.make < b.make) return -1;
  return 0;
};
