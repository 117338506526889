import { defineMessages } from 'react-intl';

export default defineMessages({
  SellerProfileSaveChanges: {
    id: 'SellerProfileSaveChanges',
    defaultMessage: 'Save changes',
  },
  SellerProfileThisFieldIsRequired: {
    id: 'SellerProfileThisFieldIsRequired',
    defaultMessage: 'This field is required',
  },
  SellerProfileContactInformationWasSaved: {
    id: 'SellerProfileContactInformationWasSaved',
    defaultMessage: 'Contact information was saved',
  },
  SellerProfileContactInformationCouldNotBeChanged: {
    id: 'SellerProfileContactInformationCouldNotBeChanged',
    defaultMessage: 'Contact information could not be changed due to the following errors',
  },
  SellerProfileName: {
    id: 'SellerProfileName',
    defaultMessage: 'Name',
  },
  SellerProfileLastName: {
    id: 'SellerProfileLastName',
    defaultMessage: 'Last name',
  },
  SellerProfileYouHaveUnsavedChanged: {
    id: 'SellerProfileYouHaveUnsavedChanged',
    defaultMessage: 'You have unsaved changes',
  },
  SellerProfileContinueEditing: {
    id: 'SellerProfileContinueEditing',
    defaultMessage: 'Continue editing',
  },
  SellerProfileDiscardChanges: {
    id: 'SellerProfileDiscardChanges',
    defaultMessage: 'Discard changes',
  },
  SellerProfileSomethingWentWrong: {
    id: 'SellerProfileSomethingWentWrong',
    defaultMessage: 'Something went wrong and contact information could not be saved',
  },
  SellerProfilePleaseTrySavingOneMoreTime: {
    id: 'SellerProfilePleaseTrySavingOneMoreTime',
    defaultMessage: 'Please try saving one more time',
  },
  SellerProfileIfThisIssuePersistPleaseContactOurSupport: {
    id: 'SellerProfileIfThisIssuePersistPleaseContactOurSupport',
    defaultMessage: 'If this issue persists, please contact our support theam for assistence',
  },
});
