import Accordion from 'components/Accordion';
import Selection from 'components/Forms/Selection';
import TextField from 'components/Forms/TextField';
import LoadingIndicator from 'components/LoadingIndicator';
import useIsLockedToSource from 'pages/AddEditListings/hooks/useIsLockedToSource';
import { Actions as AddEditListingsActions } from 'pages/AddEditListings/store/actions';
import { RootState } from 'pages/_store/root-reducer';
import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { compose } from 'utils/compose';
import { preventDecimalInput } from '../../../../utils/input';
import ConsumptionInput from './components/ConsumptionInput';
import {
  efficiencyRatingOptions,
  euroStandardOptions,
  getDrivenWheelsOptions,
  getFuelDetailedOptions,
  getGearboxDetailedOptions,
} from './options';
import styles from './TechnicalInformationForm.module.scss';
import Translations from './TechnicalInformationForm.translations';

const TechnicalInformationForm: FunctionComponent = () => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const dispatch = useDispatch();

  const { isLoadingAction, isLoadingDeal } = useSelector((state: RootState) => state.addEditListingsPage, shallowEqual);

  const { isLockedToSource, lockedToSourceMessage } = useIsLockedToSource();

  const setUnsavedChanges = () => dispatch(AddEditListingsActions.setUnsavedChanges());

  return (
    <div className={styles.root}>
      <LoadingIndicator isLoading={isLoadingAction || isLoadingDeal}>
        <Accordion
          header={formatMessage(Translations.TechnicalInformationFormTechnicalInformation)}
          collapsable={false}
        >
          <div className={styles.formColumn}>
            <div className={styles.formRow}>
              <Selection
                name="gearboxDetailed"
                label={formatMessage(Translations.TechnicalInformationFormGearboxDetailed)}
                options={getGearboxDetailedOptions(intl)}
                required
                onChange={setUnsavedChanges}
                disabled={isLockedToSource}
                tooltip={lockedToSourceMessage}
              />
            </div>
            <div className={styles.formRow}>
              <Selection
                name="fuelDetailed"
                label={formatMessage(Translations.TechnicalInformationFormFuelDetailed)}
                options={getFuelDetailedOptions(intl)}
                required
                onChange={setUnsavedChanges}
                disabled={isLockedToSource}
                tooltip={lockedToSourceMessage}
              />
            </div>
            <div className={styles.formRow}>
              <Selection
                name="drivenWheels"
                label={formatMessage(Translations.TechnicalInformationFormDrivenWheels)}
                options={getDrivenWheelsOptions(intl)}
                required
                onChange={setUnsavedChanges}
                disabled={isLockedToSource}
                tooltip={lockedToSourceMessage}
              />
            </div>
          </div>
          <div className={styles.formColumn}>
            <div className={styles.formRow}>
              <TextField
                name="exteriorColor"
                type="text"
                label={formatMessage(Translations.TechnicalInformationFormExteriorColour)}
                onChange={setUnsavedChanges}
                disabled={isLockedToSource}
                tooltip={lockedToSourceMessage}
              />
            </div>
            <div className={styles.formRow}>
              <TextField
                name="interiorColor"
                type="text"
                label={formatMessage(Translations.TechnicalInformationFormInteriorColour)}
                onChange={setUnsavedChanges}
                disabled={isLockedToSource}
                tooltip={lockedToSourceMessage}
              />
            </div>
            <div className={styles.formRow}>
              <TextField
                name="seats"
                type="number"
                min={1}
                label={formatMessage(Translations.TechnicalInformationFormSeats)}
                required
                onChange={setUnsavedChanges}
                disabled={isLockedToSource}
                tooltip={lockedToSourceMessage}
              />
            </div>
          </div>
          <div className={styles.formColumn}>
            <div className={styles.formRow}>
              <TextField
                name="doors"
                type="number"
                min={1}
                label={formatMessage(Translations.TechnicalInformationFormDoors)}
                required
                onChange={setUnsavedChanges}
                disabled={isLockedToSource}
                tooltip={lockedToSourceMessage}
              />
            </div>
          </div>
          <div className={styles.divider} />
          <div className={styles.formColumn}>
            <div className={styles.formRow}>
              <TextField
                name="cylinders"
                type="number"
                min={0}
                label={formatMessage(Translations.TechnicalInformationFormCylinders)}
                onChange={setUnsavedChanges}
                disabled={isLockedToSource}
                tooltip={lockedToSourceMessage}
              />
            </div>
            <div className={styles.formRow}>
              <TextField
                name="horsepower"
                type="number"
                min={0}
                label={formatMessage(Translations.TechnicalInformationFormHorsepower)}
                startAdornment={'HP'}
                required
                onChange={setUnsavedChanges}
                disabled={isLockedToSource}
                tooltip={lockedToSourceMessage}
              />
            </div>
            <div className={styles.formRow} />
          </div>
          <div className={styles.formColumn}>
            <div className={styles.formRow}>
              <TextField
                name="engineCapacity"
                type="number"
                min={0}
                label={formatMessage(Translations.TechnicalInformationFormEngineCapacityCm3)}
                onChange={setUnsavedChanges}
                disabled={isLockedToSource}
                tooltip={lockedToSourceMessage}
              />
            </div>
            <div className={styles.formRow}>
              <TextField
                name="co2emissions"
                type="number"
                min={0}
                label={formatMessage(Translations.TechnicalInformationFormCo2Emissions)}
                startAdornment={'g/km'}
                onChange={setUnsavedChanges}
                disabled={isLockedToSource}
                tooltip={lockedToSourceMessage}
              />
            </div>
            <div className={styles.formRow} />
          </div>
          <div className={styles.divider} />
          <div className={styles.formColumn}>
            <div className={styles.formRow}>
              <ConsumptionInput />
            </div>
            <div className={styles.formRow}>
              <TextField
                name="powerConsumption"
                type="number"
                min={0}
                label={formatMessage(Translations.TechnicalInformationFormPowerConsumptionKwh100km)}
                onChange={setUnsavedChanges}
                disabled={isLockedToSource}
                tooltip={lockedToSourceMessage}
              />
            </div>
            <div className={styles.formRow}>
              <Selection
                name="efficiencyRating"
                label={formatMessage(Translations.TechnicalInformationFormEfficiencyRating)}
                options={efficiencyRatingOptions}
                required
                onChange={setUnsavedChanges}
                disabled={isLockedToSource}
                tooltip={lockedToSourceMessage}
              />
            </div>
          </div>
          <div className={styles.formColumn}>
            <div className={styles.formRow}>
              <Selection
                name="euroStandard"
                label={formatMessage(Translations.TechnicalInformationFormEuroStandard)}
                options={euroStandardOptions}
                onChange={setUnsavedChanges}
                disabled={isLockedToSource}
                tooltip={lockedToSourceMessage}
              />
            </div>
            <div className={styles.formRow}>
              <TextField
                name="tare"
                type="number"
                min={0}
                label={formatMessage(Translations.TechnicalInformationFormTare)}
                startAdornment={'kg'}
                onChange={setUnsavedChanges}
                disabled={isLockedToSource}
                tooltip={lockedToSourceMessage}
                onKeyPress={preventDecimalInput}
              />
            </div>
            <div className={styles.formRow}>
              <TextField
                name="trailerLoad"
                type="number"
                min={0}
                label={formatMessage(Translations.TechnicalInformationFormTrailerLoad)}
                startAdornment={'kg'}
                onChange={setUnsavedChanges}
                disabled={isLockedToSource}
                tooltip={lockedToSourceMessage}
                onKeyPress={preventDecimalInput}
              />
            </div>
          </div>
          <div className={styles.divider} />
          <div className={styles.formColumn}>
            <div className={styles.formRow}>
              <TextField
                name="batteryRange"
                type="number"
                min={0}
                label={formatMessage(Translations.TechnicalInformationFormBatteryRange)}
                onChange={setUnsavedChanges}
                disabled={isLockedToSource}
                tooltip={lockedToSourceMessage}
              />
            </div>
            <div className={styles.formRow}>
              <TextField
                name="batteryCapacity"
                type="number"
                min={0}
                label={formatMessage(Translations.TechnicalInformationFormBatteryCapacity)}
                onChange={setUnsavedChanges}
                disabled={isLockedToSource}
                tooltip={lockedToSourceMessage}
              />
            </div>
            <div className={styles.formRow}></div>
          </div>
        </Accordion>
      </LoadingIndicator>
    </div>
  );
};

export default compose(TechnicalInformationForm);
