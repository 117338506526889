import { StyledComponentProps, withStyles, WithStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { FC, ReactNode, useRef } from 'react';
import { ConnectDropTarget } from 'react-dnd';
import { compose } from 'utils/compose';
import { ALLOWED_MIME_TYPES } from '../../FileUpload.utils';
import { styles } from './FileUploadInput.styles';
import FileUploadInputLabel from './FileUploadInputLabel';
import { Props as FileUploadInputLabelProps } from './FileUploadInputLabel/FileUploadInputLabel';
import withDnD, { WithDnDProps } from './withDnD';

export interface ExternalProps {
  labelProps?: Omit<FileUploadInputLabelProps, 'isActive'>;
  children?: ReactNode;
  renderFilePreview?: (files: File[], onDismiss: () => void) => JSX.Element;
  isLoading?: boolean;
}
export interface Props extends WithDnDProps, ExternalProps {
  isOver: boolean;
  canDrop: boolean;
  accept?: string;
  droppedFiles?: File[];
  connectDropTarget: ConnectDropTarget;
  onFileInputChange: (files: FileList) => void;
  onDismiss: () => void;
  disabled?: boolean;
  name: string;
}

export const INPUT_UPLOAD_CLASS_PREFIX = 'input-file-';

const FileUploadInput: FC<Props & WithStyles<typeof styles>> = React.forwardRef(
  (
    {
      classes,
      canDrop,
      isOver,
      labelProps,
      accept,
      children,
      droppedFiles,
      isLoading = false,
      onFileInputChange,
      connectDropTarget,
      renderFilePreview,
      onDismiss,
      disabled = false,
      name,
    },
    ref
  ) => {
    if (!accept) {
      accept = ALLOWED_MIME_TYPES.join(', ');
    }

    const inputRef = useRef<HTMLInputElement>();

    const isActive = canDrop && isOver;

    const handleClick = () => {
      if (inputRef.current) {
        inputRef.current.click();
      }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files;

      if (files) {
        onFileInputChange(files);
      }
    };

    return connectDropTarget(
      <div
        ref={ref as any}
        id={`file-upload-input-${name}-element`}
        className={clsx('FileUploadInput', classes.root, {
          [classes.disabled]: disabled,
          [classes.rootActive]: isActive,
        })}
        onClick={handleClick}
      >
        {!droppedFiles.length && (
          <div className={classes.content}>
            {children}
            {!isLoading && (
              <FileUploadInputLabel
                classes={{
                  root: classes.label,
                }}
                isActive={isActive}
                {...(labelProps || {})}
              />
            )}
          </div>
        )}
        {renderFilePreview && renderFilePreview(droppedFiles, onDismiss)}
        <input
          disabled={disabled}
          ref={inputRef}
          type="file"
          accept={accept}
          className={classes.fileInput}
          onChange={handleChange}
          id={INPUT_UPLOAD_CLASS_PREFIX + name}
        />
      </div>
    );
  }
);

const StyledFileUploadInput = compose<Props & StyledComponentProps>(
  FileUploadInput,
  withStyles(styles, { name: 'FileUploadInput' })
);

export default withDnD(StyledFileUploadInput);
