import { defineMessages } from 'react-intl';

export default defineMessages({
  ResidualValuesBreakdownFormResidualValues: {
    id: 'ResidualValuesBreakdownFormResidualValues',
    defaultMessage: 'Residual values',
  },
  ResidualValuesBreakdownFormUseDefaultResidualValuesFromLeasingProvider: {
    id: 'ResidualValuesBreakdownFormUseDefaultResidualValuesFromLeasingProvider',
    defaultMessage: 'Use default residual values from {leasingProviderLabel}',
  },
  ResidualValuesBreakdownFormContractLength: {
    id: 'ResidualValuesBreakdownFormContractLength',
    defaultMessage: 'Contract length',
  },
  ResidualValuesBreakdownFormAnnualMileageKm: {
    id: 'ResidualValuesBreakdownFormAnnualMileageKm',
    defaultMessage: 'Annual mileage (km)',
  },
  ResidualValuesBreakdownFormResidualValue: {
    id: 'ResidualValuesBreakdownFormResidualValue',
    defaultMessage: 'Residual value',
  },
  ResidualValuesBreakdownFormMonthlyPrice: {
    id: 'ResidualValuesBreakdownFormMonthlyPrice',
    defaultMessage: 'Monthly price',
  },
  ResidualValuesBreakdownFormWith0DownPayment: {
    id: 'ResidualValuesBreakdownFormWith0DownPayment',
    defaultMessage: '(with 0% down payment)',
  },
  ResidualValuesBreakdownFormAddContractLength: {
    id: 'ResidualValuesBreakdownFormAddContractLength',
    defaultMessage: 'Add contract length',
  },
  ResidualValuesBreakdownFormDisableTheUseOfDefaultResidualValuesToEdit: {
    id: 'ResidualValuesBreakdownFormDisableTheUseOfDefaultResidualValuesToEdit',
    defaultMessage: 'Disable the use of default residual values to edit this field',
  },
});
