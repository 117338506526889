import { defineMessages } from 'react-intl';

export default defineMessages({
  ByMakeAndModelFormContentMake: {
    id: 'ByMakeAndModelFormContentMake',
    defaultMessage: 'Make',
  },
  ByMakeAndModelFormContentModel: {
    id: 'ByMakeAndModelFormContentModel',
    defaultMessage: 'Model',
  },
  ByMakeAndModelFormContentRegistrationOrConstructionYear: {
    id: 'ByMakeAndModelFormContentRegistrationOrConstructionYear',
    defaultMessage: 'Registration or construction year',
  },
  ByMakeAndModelFormContentPresent: {
    id: 'ByMakeAndModelFormContentPresent',
    defaultMessage: 'Present',
  },
  ByMakeAndModelFormContentSelectMakeAndModelFirst: {
    id: 'ByMakeAndModelFormContentSelectMakeAndModelFirst',
    defaultMessage: 'Select make and model first',
  },
});
