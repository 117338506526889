import { deleteFile } from 'api/offers';
import Translations from 'App.translations';
import { Actions as GlobalActions } from 'pages/_store/global/actions';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { FileType } from 'types';

interface DeleteFileArgs {
  offerId: string;
  customerId: number;
  fileType: FileType;
}

export const useDeleteFile = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const call = async ({ customerId, offerId, fileType }: DeleteFileArgs) => {
    try {
      await deleteFile({
        customerId,
        offerId,
        fileType,
      });
      return true;
    } catch (e) {
      dispatch(
        GlobalActions.sendNotification({
          message: formatMessage(Translations.AppErrorDeletingFile),
          variant: 'error',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'top',
          },
        })
      );

      return false;
    }
  };

  return call;
};
