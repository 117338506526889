import { defineMessages } from 'react-intl';

export default defineMessages({
  StepChangeModalThisWillDeleteSomeInformationFromListing: {
    id: 'StepChangeModalThisWillDeleteSomeInformationFromListing',
    defaultMessage: 'This will delete some information in your listing',
  },
  StepChangeBack: {
    id: 'StepChangeBack',
    defaultMessage: 'Back',
  },
});
