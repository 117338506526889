import { SellerPublic } from 'types';

export interface ManagePeopleState {
  isLoading: boolean;
  isError: boolean;
  sellers: SellerPublic[];
  showSuccessMessage: boolean;
}

export const initialState: ManagePeopleState = {
  isLoading: false,
  isError: false,
  showSuccessMessage: false,
  sellers: [],
};
