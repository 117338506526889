import { createStyles } from '@material-ui/core';
import { GowagoTheme } from 'theme';

export const styles = (theme: GowagoTheme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    img: {
      borderRadius: 2,
      objectFit: 'contain',
      objectPosition: 'center',
    },
    imgProgressiveWrapper: {
      display: 'flex',
    },
    loading: {
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'center',
    },
    loadingIcon: {
      fontSize: theme.gowago.fontSize.largest,
      lineHeight: theme.gowago.lineHeight.rem[22],
      color: theme.gowago.text.secondary,
    },
    loadingText: {
      fontSize: theme.gowago.fontSize.smaller,
      lineHeight: theme.gowago.lineHeight.rem[22],
    },
    filePlaceholder: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexFlow: 'column',
    },
    fileIcon: {
      color: theme.gowago.colors.green,
      fontSize: 48,
    },
    fileText: {
      marginTop: theme.gowago.spacing.small,
      fontSize: theme.gowago.fontSize.smaller,
    },
  });
