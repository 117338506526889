import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { FunctionComponent, useMemo } from 'react';
import ReactTooltip from 'react-tooltip';
import { compose } from 'utils/compose';
import { v4 as uuid } from 'uuid';
import styles from './CheckboxBase.module.scss';

export interface Props
  extends Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'onChange'> {
  label?: string;
  facets?: number;
  tooltip?: string;
  classId?: string;
  inputRef?: React.LegacyRef<HTMLInputElement>;
  onChange?: (value: boolean) => void;
}

const CheckboxBase: FunctionComponent<Props> = ({ label, tooltip, facets, classId, inputRef, onChange, ...other }) => {
  const tooltipUuid = useMemo(uuid, []);

  const handleOnChangeEvent = () => {
    if (other.disabled) return;
    if (onChange) {
      onChange(!other.checked);
    }
  };

  const onInputChange = () => {};

  const cx = classNames.bind(styles);

  return (
    <>
      <div
        className={classNames(styles.root, {
          [styles.disabled]: other.disabled,
        })}
        onClick={handleOnChangeEvent}
        data-tip={tooltip}
        data-for={tooltipUuid}
      >
        <div
          className={cx(
            classId,
            styles.checkbox,
            { checked: other.checked && !other.disabled },
            { [styles.disabled]: other.disabled }
          )}
        >
          {other.checked && <FontAwesomeIcon icon={['far', 'check']} className={`${classId}-svg`} />}
          <input {...other} onChange={onInputChange} ref={inputRef} type="checkbox" className={styles.nativeCheckbox} />
        </div>

        {!!label && <div className={styles.primaryLabel}>{label}</div>}
        {facets !== undefined && <div className={styles.facetLabel}>{`(${facets})`}</div>}
      </div>
      {!!tooltip && <ReactTooltip key={tooltip} effect="solid" id={tooltipUuid} />}
    </>
  );
};

export default compose<Props>(CheckboxBase);
