import { Reducer } from 'redux';
import { Actions, ActionTypes } from './actions';
import { ChangePasswordState, initialState } from './initial-state';

export const changePasswordReducer: Reducer<ChangePasswordState> = (
  state = initialState,
  action: Actions
): ChangePasswordState => {
  switch (action.type) {
    case ActionTypes.CHANGE_PASSWORD_REQUEST: {
      return {
        ...initialState,
        isFetching: true,
      };
    }

    case ActionTypes.CHANGE_PASSWORD_SUCCESS: {
      return {
        ...initialState,
        isSuccess: true,
      };
    }
    case ActionTypes.CHANGE_PASSWORD_FAILURE: {
      return {
        ...initialState,
        errorCode: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
