import { defineMessages } from 'react-intl';

export default defineMessages({
  AccountSettingsNavigationContactInformation: {
    id: 'AccountSettingsNavigationContactInformation',
    defaultMessage: 'Contact information',
  },
  NavigationChangePassword: {
    id: 'NavigationChangePassword',
    defaultMessage: 'Change password',
  },
});
