import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { compose } from 'utils/compose';
import styles from './Header.module.scss';
import Translations from './ViewAllVendorsHeader.translations';
interface Props {
  $total: number;
}

const Header: FunctionComponent<Props> = ({ $total }) => {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.root}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>{formatMessage(Translations.ViewAllVendorsHeaderAllVendors)}</div>
        <div className={styles.listingsResultLabel}>
          {formatMessage(Translations.ViewAllVendorsHeaderShowingCountFilteredVendors, {
            countFiltered: $total,
          })}
        </div>
      </div>
    </div>
  );
};

export default compose<Props>(Header);
