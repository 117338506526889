import classNames from 'classnames';
import Checkbox from 'components/Forms/Checkbox';
import DatePicker from 'components/Forms/DatePicker';
import Selection from 'components/Forms/Selection';
import TextAreaField from 'components/Forms/TextAreaField';
import TextField from 'components/Forms/TextField';
import TextFieldBase from 'components/Forms/TextFieldBase';
import { useFormikContext } from 'formik';
import useIsLockedToSource from 'pages/AddEditListings/hooks/useIsLockedToSource';
import { AddEditListingSchema } from 'pages/AddEditListings/schema';
import { Actions as AddEditListingsActions } from 'pages/AddEditListings/store/actions';
import { RootState } from 'pages/_store/root-reducer';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { StartTimeOfWarranty } from 'types';
import styles from './GuaranteeFormBox.module.scss';
import Translations from './GuaranteeFormBox.translations';

const GuaranteeFormBox: FC = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const { values, setFieldValue } = useFormikContext<AddEditListingSchema>();

  const { deal } = useSelector((state: RootState) => ({
    deal: state.addEditListingsPage.deal,
  }));

  const setUnsavedChanges = () => dispatch(AddEditListingsActions.setUnsavedChanges());

  const handleWarrantyUntilDateChange = (value: string | undefined) => {
    setFieldValue('warrantyUntilDate', value);
    setUnsavedChanges();
  };

  const guaranteeSelected = !!values.guarantee;

  const startTimeOfWarrantyOptions = Object.values(StartTimeOfWarranty).map((value) => ({
    label: formatMessage(Translations[value]),
    value,
  }));

  const { isLockedToSource, lockedToSourceMessage } = useIsLockedToSource();
  const disabled = isLockedToSource || !guaranteeSelected;

  return (
    <>
      <div className={classNames(styles.row, styles.checkboxRow)}>
        <div className={styles.column}>
          <Checkbox
            name="guarantee"
            label={formatMessage(Translations.GuaranteeFormBoxGuarantee)}
            onChange={setUnsavedChanges}
            tooltip={lockedToSourceMessage}
            disabled={isLockedToSource}
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.column}>
          <Selection
            name="warrantyStartType"
            label={formatMessage(Translations.GuaranteeFormBoxWarranyStartType)}
            options={startTimeOfWarrantyOptions}
            tooltip={lockedToSourceMessage}
            onChange={setUnsavedChanges}
            disabled={disabled}
          />
          <DatePicker
            onChange={handleWarrantyUntilDateChange}
            value={values.warrantyUntilDate}
            label={formatMessage(Translations.GuaranteeFormBoxWarranyUntilDate)}
            name="warrantyUntilDate"
            disabled={disabled}
          />
        </div>
        <div className={styles.column}>
          <TextField
            name="warrantyInMonths"
            type="number"
            label={formatMessage(Translations.GuaranteeFormBoxWarranyInMonths)}
            min={0}
            onChange={setUnsavedChanges}
            disabled={disabled}
            tooltip={lockedToSourceMessage}
          />
          <TextFieldBase
            key={deal?.listing?.warrantyMonthsLeft}
            name="warrantyMonthsLeft"
            type="number"
            label={formatMessage(Translations.GuaranteeFormBoxWarranyMonthsLeft)}
            min={0}
            value={deal?.listing?.warrantyMonthsLeft}
            disabled
            readOnly
          />
        </div>
        <div className={styles.column}>
          <TextField
            name="warrantyInKM"
            type="number"
            label={formatMessage(Translations.GuaranteeFormBoxWarranyInKM)}
            min={0}
            onChange={setUnsavedChanges}
            disabled={disabled}
            tooltip={lockedToSourceMessage}
          />
          <TextFieldBase
            key={deal?.listing?.warrantyKMLeft}
            name="warrantyKMLeft"
            type="number"
            label={formatMessage(Translations.GuaranteeFormBoxWarranyKMLeft)}
            min={0}
            value={deal?.listing?.warrantyKMLeft}
            disabled
            readOnly
          />
        </div>
      </div>
      <div className={styles.column}>
        <div className={styles.row}>
          <TextAreaField
            rows={6}
            name="guaranteeText"
            label={formatMessage(Translations.GuaranteeFormBoxGuaranteeText)}
            type="textarea"
            onChange={setUnsavedChanges}
            tooltip={lockedToSourceMessage}
            disabled={disabled}
            showErrorOnlyOnSubmit={true}
          />
        </div>
      </div>
    </>
  );
};

export default GuaranteeFormBox;
