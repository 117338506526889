import { Checkbox, CheckboxProps, FormControlLabel, withStyles, WithStyles } from '@material-ui/core';
import classNames from 'classnames';
import { ChangeEvent, FC, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import ReactTooltip from 'react-tooltip';
import { compose } from 'utils/compose';
import { v4 as uuid } from 'uuid';
import { styles } from './RHFCheckbox.styles';

export interface Props extends CheckboxProps {
  label?: string;
  facets?: number;
  tooltip?: string;
  classId?: string;
  name: string;
  onChange?: (event: ChangeEvent<{}>, checked: boolean) => void;
}
const RHFCheckbox: FC<Props & WithStyles<typeof styles>> = ({
  label,
  name,
  tooltip,
  facets,
  onChange,
  classes,
  disabled = false,
  ...other
}) => {
  const tooltipUuid = useMemo(uuid, []);
  const { register, watch, setValue } = useFormContext();
  const checked = watch(name);

  const handleOnChange = (e: ChangeEvent<{}>, checked: boolean) => {
    if (onChange) {
      onChange(e, checked);
    }

    setValue(name, checked);
  };

  return (
    <>
      <FormControlLabel
        checked={!!checked}
        onChange={handleOnChange}
        control={<Checkbox {...other} disabled={disabled} />}
        name={name}
        inputRef={register}
        className={classNames('RHFCheckbox', classes.root)}
        color="primary"
        label={label}
        disabled={disabled}
      />
      {!!tooltip && <ReactTooltip key={tooltip} effect="solid" id={tooltipUuid} />}
    </>
  );
};

export default compose<any>(RHFCheckbox, withStyles(styles, { name: 'RHFCheckbox' }));
