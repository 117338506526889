import { createStyles } from '@material-ui/core';
import { GowagoTheme } from 'theme';

export const styles = (theme: GowagoTheme) =>
  createStyles({
    root: {
      color: theme.gowago.colors.lighterGray,
      marginBottom: theme.gowago.spacing.medium,
    },

    header: {
      color: theme.gowago.white,
      fontSize: '1.562rem',
      fontWeight: 600,
      lineHeight: theme.gowago.lineHeight.rem[34],
    },

    subHeader: {
      color: theme.gowago.colors.lighterGray,
      fontSize: theme.gowago.fontSize.smaller,
      lineHeight: theme.gowago.lineHeight.rem[19],
      marginTop: 7,
      display: 'flex',
      flexFlow: 'column',
      rowGap: theme.gowago.spacing.smallest,
    },
  });
