import Cookies, { CookieSetOptions } from 'universal-cookie';
import { KEYS } from './local-storage';

export const cookieOptions: CookieSetOptions = {
  path: '/',
  secure: true,
  sameSite: true,
};

const cookies = new Cookies();

export const getCookie = (name: string) => cookies.get(name);
export const setCookie = (name: string, value: string, options?: CookieSetOptions) => cookies.set(name, value, options);
export const removeCookie = (name: string) => cookies.remove(name);

export const getJWTToken = () => getCookie(KEYS.AUTH_TOKEN);
export const setJWTToken = (value: string) => setCookie(KEYS.AUTH_TOKEN, value, cookieOptions);
export const removeJWTToken = () => removeCookie(KEYS.AUTH_TOKEN);
