import TextField from 'components/Forms/TextField';
import useIsLockedToSource from 'pages/AddEditListings/hooks/useIsLockedToSource';
import { Actions as AddEditListingsActions } from 'pages/AddEditListings/store/actions';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { compose } from 'utils/compose';
import styles from './ConsumptionInput.module.scss';
import Translations from './ConsumptionInput.translations';

const STEP = 0.1;

const ConsumptionInput: FC = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const { isLockedToSource, lockedToSourceMessage } = useIsLockedToSource();

  const setUnsavedChanges = () => dispatch(AddEditListingsActions.setUnsavedChanges());

  return (
    <div className={styles.root}>
      <div className={styles.mainLabel}>{formatMessage(Translations.ConsumptionInputConsumptionL100km)}</div>
      <div className={styles.inputContainer}>
        <div className={styles.inputContent}>
          <TextField
            name="consumptionCity"
            type="number"
            onChange={setUnsavedChanges}
            min={0}
            step={STEP}
            disabled={isLockedToSource}
            tooltip={lockedToSourceMessage}
          />
          <div className={styles.inputLabel}>{formatMessage(Translations.ConsumptionInputCity)}</div>
        </div>
        <div className={styles.inputContent}>
          <TextField
            name="consumptionCountry"
            onChange={setUnsavedChanges}
            type="number"
            min={0}
            step={STEP}
            disabled={isLockedToSource}
            tooltip={lockedToSourceMessage}
          />
          <div className={styles.inputLabel}>{formatMessage(Translations.ConsumptionInputCountry)}</div>
        </div>
        <div className={styles.inputContent}>
          <TextField
            name="consumptionTotal"
            onChange={setUnsavedChanges}
            type="number"
            step={STEP}
            min={0}
            disabled={isLockedToSource}
            tooltip={lockedToSourceMessage}
          />
          <div className={styles.inputLabel}>{formatMessage(Translations.ConsumptionInputTotal)}</div>
        </div>
      </div>
    </div>
  );
};

export default compose(ConsumptionInput);
