import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { LocaleOptions } from 'constants/constants';
import { FC, useEffect, useState } from 'react';
import { Manager, Popper, Reference } from 'react-popper';
import { Locale } from 'types';
import { compose } from 'utils/compose';
import { getLocale, setLocale } from 'utils/local-storage';
import styles from './LanguageSelection.module.scss';

interface Props {
  dark?: boolean;
}

const LanguageSelection: FC<Props> = ({ dark }) => {
  const [open, setOpen] = useState(false);
  const userLocale = getLocale();
  const handleClickOutside = (e: Event) => {
    const languagePopperContainer = document.querySelector('#LanguageSelectionPopper');
    if (languagePopperContainer && !languagePopperContainer.contains(e.target as Node)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  const handleMenuOnClick = () => setOpen(true);
  const handleMenuOptionClick = (locale: Locale) => () => {
    setLocale(locale);
    setOpen(false);
  };
  return (
    <Manager>
      <Reference>
        {({ ref }) => (
          <button
            className={classNames(styles.root, {
              [styles.dark]: dark,
            })}
            ref={ref}
            onClick={handleMenuOnClick}
          >
            <FontAwesomeIcon icon={[dark ? 'far' : 'fas', 'comment']} className={styles.buttonIcon} />
            <span className={styles.languageLabel}>{LocaleOptions[userLocale].shortLabel}</span>
          </button>
        )}
      </Reference>
      {open && (
        <Popper placement="bottom">
          {({ ref, style, placement }) => {
            return (
              <div
                ref={ref}
                id="LanguageSelectionPopper"
                className={styles.popoverContainer}
                style={style}
                data-placement={placement}
              >
                {Object.keys(LocaleOptions).map((key: string) => (
                  <button
                    className={classNames(styles.popoverMenuButton, {
                      [styles.active]: LocaleOptions[key].value === userLocale,
                    })}
                    key={key}
                    onClick={handleMenuOptionClick(LocaleOptions[key].value)}
                  >
                    {LocaleOptions[key].label}
                  </button>
                ))}
              </div>
            );
          }}
        </Popper>
      )}
    </Manager>
  );
};

export default compose<Props>(LanguageSelection);
