import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import TextArea from 'components/Forms/TextArea';
import { Actions as GlobalActions } from 'pages/_store/global/actions';
import { RootState } from 'pages/_store/root-reducer';
import { FC, Fragment } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import AllInOneSectionTranslations from '../AllInOneSection.translations';
import styles from './AllInOneYearlyFees.module.scss';
import Translations from './AllInOneYearlyFees.translations';

interface Props {
  asJSON: boolean;
}

const AllInOneYearlyFees: FC<Props> = ({ asJSON }) => {
  const { formatMessage } = useIntl();
  const { deal } = useSelector((state: RootState) => state.addEditListingsPage, shallowEqual);
  const dispatch = useDispatch();

  const yearlyFees = deal?.listing?.aio?.yearlyFees;

  const handleCopyButtonClick = () => {
    navigator.clipboard.writeText(JSON.stringify(yearlyFees, undefined, 2));
    dispatch(
      GlobalActions.sendNotification({
        message: formatMessage(AllInOneSectionTranslations.AllInOneSectionCopied),
        variant: 'info',
        persist: false,
        preventDuplicate: true,
      })
    );
  };

  return yearlyFees ? (
    <div className="AllInOneCosts">
      <p className={styles.title}>{formatMessage(Translations.AllInYearlyFeesYearlyFees)}</p>
      {asJSON ? (
        <>
          <button onClick={handleCopyButtonClick} disabled={!yearlyFees} type="button" className={styles.copyButton}>
            <FontAwesomeIcon icon={faCopy} className={styles.copyIcon} />
            {formatMessage(AllInOneSectionTranslations.AllInOneSectionCopy)}
          </button>
          <TextArea rows={4} readOnly style={{ resize: 'none' }}>
            {JSON.stringify(yearlyFees, undefined, 2)}
          </TextArea>
        </>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Gowago</TableCell>
                <TableCell>MSS</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{yearlyFees?.gowago}</TableCell>
                <TableCell>{yearlyFees?.mss}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  ) : (
    <Fragment />
  );
};

export default AllInOneYearlyFees;
