import { FC } from 'react';
import { compose } from 'utils/compose';
import styles from './FormSubtitle.module.scss';

interface Props {
  text: string;
}

const FormSubtitle: FC<Props> = ({ text }) => {
  return <div className={styles.root}>{text}</div>;
};

export default compose<Props>(FormSubtitle);
