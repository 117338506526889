import { FunctionComponent } from 'react';
import { compose } from 'utils/compose';
import LoadingIndicator from '../LoadingIndicator';
import NoResultWrapper from './components/NoResultWrapper';
import styles from './DataGrid.module.scss';

interface Props {
  isLoading: boolean;
  isEmptyResult?: boolean;
  onButtonReset?: () => void;
  hideResetButton?: boolean;
}

const DataGrid: FunctionComponent<Props> = ({ children, isLoading, isEmptyResult, onButtonReset, hideResetButton }) => (
  <div className={!isEmptyResult && !isLoading ? styles.root : styles.noResultsRoot}>
    {!isEmptyResult ? (
      <LoadingIndicator isLoading={isLoading}>{children}</LoadingIndicator>
    ) : (
      <NoResultWrapper isEmptyResult={isEmptyResult} onButtonReset={onButtonReset} hideResetButton={hideResetButton}>
        {children}
      </NoResultWrapper>
    )}
  </div>
);

export default compose<Props>(DataGrid);
