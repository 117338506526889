import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { FC, Fragment } from 'react';
import styles from './ValuesTable.module.scss';

interface Props {
  name: string;
  title: string;
  data: Record<string, string | number> | undefined;
}

const ValuesTable: FC<Props> = ({ name, title, data }) => {
  return data ? (
    <>
      <p className={styles.title}>{title}</p>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {Object.keys(data).map((key) => (
                <TableCell key={`${name}-${key}`}>{key}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {Object.values(data).map((value, index) => (
                <TableCell key={`${name}-${index}`}>{value}</TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  ) : (
    <Fragment />
  );
};

export default ValuesTable;
