import { defineMessages } from 'react-intl';

export default defineMessages({
  SellerProfileContactInformationContactInformation: {
    id: 'SellerProfileContactInformationContactInformation',
    defaultMessage: 'Contact information',
  },
  SellerProfileContactInformationName: {
    id: 'SellerProfileContactInformationName',
    defaultMessage: 'Name',
  },
  SellerProfileContactInformationLastName: {
    id: 'SellerProfileContactInformationLastName',
    defaultMessage: 'Last name',
  },
  SellerProfileContactInformationEmail: {
    id: 'SellerProfileContactInformationEmail',
    defaultMessage: 'Email',
  },
  SellerProfileContactInformationContactOurSupportTeamToChangeYourEmail: {
    id: 'SellerProfileContactInformationContactOurSupportTeamToChangeYourEmail',
    defaultMessage: 'Contact our support team to change your email.',
  },
  SellerProfileContactInformationVendor: {
    id: 'SellerProfileContactInformationVendor',
    defaultMessage: 'Vendor',
  },
  SellerProfileContactInformationContactOurSupportTeamToChangeYourVendor: {
    id: 'SellerProfileContactInformationContactOurSupportTeamToChangeYourVendor',
    defaultMessage: 'Contact our support team to change your vendor.',
  },
});
