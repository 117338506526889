import {
  CarDealItemProps,
  DealResultFacets,
  DealSorting,
  DealStatus,
  SourceType,
  SpecialDealType,
  Vendor,
} from 'types';

export interface DealResultsState {
  results: CarDealItemProps[];
  filters: DealFilters;
  isDealResultLoading: boolean;
  countFiltered: number;
  countTotal: number;
  facets: DealResultFacets;
  selectedVendor?: Vendor;
  selectedDeals: number[];
  isVendorNotFound: boolean;
  isDuplicateLoading: boolean;
}

export interface DealFilters {
  $limit: number;
  $skip: number;
  query: string;
  status: DealStatus[];
  source: SourceType[];
  specialDealType: SpecialDealType[];
  closedUserGroup: string;
  sorting: DealSorting;
}

export const initialState: DealResultsState = {
  results: [],
  filters: {
    $skip: 0,
    $limit: 20,
    query: '',
    status: [],
    sorting: {
      makeAndModel: 'ASC',
    },
    source: [],
    specialDealType: [],
    closedUserGroup: '',
  },
  facets: {
    source: [],
    status: [],
    specialDealType: [],
  },
  selectedDeals: [],
  isDealResultLoading: false,
  countFiltered: 0,
  countTotal: 0,
  isVendorNotFound: false,
  isDuplicateLoading: false,
};
