import { Make, FuelType, FuelTypesDetailed } from './car.types';

export const makes = Object.values(Make);

export const makeToSlug: Record<Make, string> = {
  [Make.AIXAM]: 'aixam',
  [Make['ALFA ROMEO']]: 'alfa-romeo',
  [Make['AMERICAN MOTORS']]: 'american-motors',
  [Make.ARTEGA]: 'artega',
  [Make['ASTON MARTIN']]: 'aston-martin',
  [Make.AUDI]: 'audi',
  [Make.AUSTIN]: 'austin',
  [Make.BENTLEY]: 'bentley',
  [Make.BERTONE]: 'bertone',
  [Make.BMW]: 'bmw',
  [Make['BMW-ALPINA']]: 'bmw-alpina',
  [Make.BUGATTI]: 'bugatti',
  [Make.BUICK]: 'buick',
  [Make.CADILLAC]: 'cadillac',
  [Make.CATERHAM]: 'caterham',
  [Make.CHEVROLET]: 'chevrolet',
  [Make.CHRYSLER]: 'chrysler',
  [Make.CITROEN]: 'citroen',
  [Make.CUPRA]: 'cupra',
  [Make.DACIA]: 'dacia',
  [Make.DAEWOO]: 'daewoo',
  [Make.DAIHATSU]: 'daihatsu',
  [Make.DAIMLER]: 'daimler',
  [Make['DE TOMASO']]: 'de-tomaso',
  [Make.DODGE]: 'dodge',
  [Make.DONKERVOORT]: 'donkervoort',
  [Make['DS AUTOMOBILES']]: 'ds-automobiles',
  [Make.FERRARI]: 'ferrari',
  [Make.FIAT]: 'fiat',
  [Make.FISKER]: 'fisker',
  [Make.FORD]: 'ford',
  [Make['FORD (USA)']]: 'ford-usa',
  [Make.GENESIS]: 'genesis',
  [Make.HONDA]: 'honda',
  [Make.HS]: 'hs',
  [Make.HUMMER]: 'hummer',
  [Make.HYUNDAI]: 'hyundai',
  [Make.INFINITI]: 'infiniti',
  [Make.INNOCENTI]: 'innocenti',
  [Make['ISUZU (GM)']]: 'isuzu-gm',
  [Make['ISUZU (J)']]: 'isuzu-j',
  [Make.ISUZU]: 'isuzu',
  [Make.IVECO]: 'iveco',
  [Make.JAGUAR]: 'jaguar',
  [Make.JEEP]: 'jeep',
  [Make.KIA]: 'kia',
  [Make.KTM]: 'ktm',
  [Make.LADA]: 'lada',
  [Make.LAMBORGHINI]: 'lamborghini',
  [Make.LANCIA]: 'lancia',
  [Make['LAND ROVER']]: 'land-rover',
  [Make.LEXUS]: 'lexus',
  [Make.LIGIER]: 'ligier',
  [Make.LOTUS]: 'lotus',
  [Make.MASERATI]: 'maserati',
  [Make.MAZDA]: 'mazda',
  [Make['MERCEDES-BENZ']]: 'mercedes-benz',
  [Make.MG]: 'mg',
  [Make.MICRO]: 'micro',
  [Make.MINELLI]: 'minelli',
  [Make.MINI]: 'mini',
  [Make.MITSUBISHI]: 'mitsubishi',
  [Make.MORGAN]: 'morgan',
  [Make.McLAREN]: 'mclaren',
  [Make.NISSAN]: 'nissan',
  [Make.OLDSMOBILE]: 'oldsmobile',
  [Make.OPEL]: 'opel',
  [Make.PEUGEOT]: 'peugeot',
  [Make.PONTIAC]: 'pontiac',
  [Make.PORSCHE]: 'porsche',
  [Make.PUCH]: 'puch',
  [Make.QVALE]: 'qvale',
  [Make.RELIANT]: 'reliant',
  [Make.RENAULT]: 'renault',
  [Make['ROLLS-ROYCE']]: 'rolls-royce',
  [Make.ROVER]: 'rover',
  [Make.SAAB]: 'saab',
  [Make.SEAT]: 'seat',
  [Make.SECMA]: 'secma',
  [Make.SKODA]: 'skoda',
  [Make.SMART]: 'smart',
  [Make['SSANG YONG']]: 'ssang-yong',
  [Make.SUBARU]: 'subaru',
  [Make.SUZUKI]: 'suzuki',
  [Make.TALBOT]: 'talbot',
  [Make.TATA]: 'tata',
  [Make.TAZZARI]: 'tazzari',
  [Make.TESLA]: 'tesla',
  [Make.THINK]: 'think',
  [Make.TOYOTA]: 'toyota',
  [Make.TVR]: 'tvr',
  [Make.VENTURI]: 'venturi',
  [Make.VOLVO]: 'volvo',
  [Make.VW]: 'vw',
  [Make.WIESMANN]: 'wiesmann',
  [Make.ZAGATO]: 'zagato',
  [Make.POLESTAR]: 'polestar',
};

export const makeToWritten: Record<Make, string> = {
  [Make.AIXAM]: 'Aixam',
  [Make['ALFA ROMEO']]: 'Alfa Romeo',
  [Make['AMERICAN MOTORS']]: 'American Motors',
  [Make.ARTEGA]: 'Artega',
  [Make['ASTON MARTIN']]: 'Aston Martin',
  [Make.AUDI]: 'Audi',
  [Make.AUSTIN]: 'Austin',
  [Make.BENTLEY]: 'Bentley',
  [Make.BERTONE]: 'Bertone',
  [Make.BMW]: 'BMW',
  [Make['BMW-ALPINA']]: 'BMW-Alpina',
  [Make.BUGATTI]: 'Bugatti',
  [Make.BUICK]: 'Buick',
  [Make.CADILLAC]: 'Cadillac',
  [Make.CATERHAM]: 'Caterham',
  [Make.CHEVROLET]: 'Chevrolet',
  [Make.CHRYSLER]: 'Chrysler',
  [Make.CITROEN]: 'Citroen',
  [Make.CUPRA]: 'Cupra',
  [Make.DACIA]: 'Dacia',
  [Make.DAEWOO]: 'Daewoo',
  [Make.DAIHATSU]: 'Daihatsu',
  [Make.DAIMLER]: 'Daimler',
  [Make['DE TOMASO']]: 'De Tomaso',
  [Make.DODGE]: 'Dodge',
  [Make.DONKERVOORT]: 'Donkervoort',
  [Make['DS AUTOMOBILES']]: 'DS Automobiles',
  [Make.FERRARI]: 'Ferrari',
  [Make.FIAT]: 'Fiat',
  [Make.FISKER]: 'Fisker',
  [Make.FORD]: 'Ford',
  [Make['FORD (USA)']]: 'Ford (USA)',
  [Make.GENESIS]: 'Genesis',
  [Make.HONDA]: 'Honda',
  [Make.HS]: 'HS',
  [Make.HUMMER]: 'Hummer',
  [Make.HYUNDAI]: 'Hyundai',
  [Make.INFINITI]: 'Infiniti',
  [Make.INNOCENTI]: 'Innocenti',
  [Make['ISUZU (GM)']]: 'Isuzu (Gm)',
  [Make['ISUZU (J)']]: 'Isuzu (J)',
  [Make.ISUZU]: 'Isuzu',
  [Make.IVECO]: 'Iveco',
  [Make.JAGUAR]: 'Jaguar',
  [Make.JEEP]: 'Jeep',
  [Make.KIA]: 'KIA',
  [Make.KTM]: 'KTM',
  [Make.LADA]: 'Lada',
  [Make.LAMBORGHINI]: 'Lamborghini',
  [Make.LANCIA]: 'Lancia',
  [Make['LAND ROVER']]: 'Land Rover',
  [Make.LEXUS]: 'Lexus',
  [Make.LIGIER]: 'Ligier',
  [Make.LOTUS]: 'Lotus',
  [Make.MASERATI]: 'Maserati',
  [Make.MAZDA]: 'Mazda',
  [Make['MERCEDES-BENZ']]: 'Mercedes-Benz',
  [Make.MG]: 'MG',
  [Make.MICRO]: 'Micro',
  [Make.MINELLI]: 'Minelli',
  [Make.MINI]: 'Mini',
  [Make.MITSUBISHI]: 'Mitsubishi',
  [Make.MORGAN]: 'Morgan',
  [Make.McLAREN]: 'Mclaren',
  [Make.NISSAN]: 'Nissan',
  [Make.OLDSMOBILE]: 'Oldsmobile',
  [Make.OPEL]: 'Opel',
  [Make.PEUGEOT]: 'Peugeot',
  [Make.PONTIAC]: 'Pontiac',
  [Make.PORSCHE]: 'Porsche',
  [Make.PUCH]: 'Puch',
  [Make.QVALE]: 'Qvale',
  [Make.RELIANT]: 'Reliant',
  [Make.RENAULT]: 'Renault',
  [Make['ROLLS-ROYCE']]: 'Rolls-Royce',
  [Make.ROVER]: 'Rover',
  [Make.SAAB]: 'SAAB',
  [Make.SEAT]: 'SEAT',
  [Make.SECMA]: 'SECMA',
  [Make.SKODA]: 'Skoda',
  [Make.SMART]: 'Smart',
  [Make['SSANG YONG']]: 'SsangYong',
  [Make.SUBARU]: 'Subaru',
  [Make.SUZUKI]: 'Suzuki',
  [Make.TALBOT]: 'Talbot',
  [Make.TATA]: 'Tata',
  [Make.TAZZARI]: 'Tazzari',
  [Make.TESLA]: 'Tesla',
  [Make.THINK]: 'Think',
  [Make.TOYOTA]: 'Toyota',
  [Make.TVR]: 'TVR',
  [Make.VENTURI]: 'Venturi',
  [Make.VOLVO]: 'Volvo',
  [Make.VW]: 'VW',
  [Make.WIESMANN]: 'Wiesmann',
  [Make.ZAGATO]: 'Zagato',
  [Make.POLESTAR]: 'Polestar',
};

export const makesUsed = [
  Make['ALFA ROMEO'],
  Make['ASTON MARTIN'],
  Make.AUDI,
  Make.BENTLEY,
  Make.BMW,
  Make['BMW-ALPINA'],
  Make.CADILLAC,
  Make.CHEVROLET,
  // Make.CHRYSLER,
  Make.CITROEN,
  Make.CUPRA,
  Make.DACIA,
  // Make.DAIHATSU,
  Make.DODGE,
  Make['DS AUTOMOBILES'],
  Make.FERRARI,
  Make.FIAT,
  Make.FISKER,
  Make.FORD,
  Make.GENESIS,
  Make.HONDA,
  // Make.HUMMER,
  Make.HYUNDAI,
  // Make.INFINITI,
  // Make.ISUZU,
  Make.JAGUAR,
  Make.JEEP,
  Make.KIA,
  Make.LAMBORGHINI,
  Make['LAND ROVER'],
  Make.LEXUS,
  Make.LOTUS,
  Make.MASERATI,
  Make.MAZDA,
  // Make.McLAREN,
  Make['MERCEDES-BENZ'],
  Make.MG,
  Make.MICRO,
  Make.MINI,
  Make.MITSUBISHI,
  Make.NISSAN,
  Make.OPEL,
  Make.PEUGEOT,
  Make.PORSCHE,
  Make.RENAULT,
  Make['ROLLS-ROYCE'],
  // Make.SAAB,
  Make.SEAT,
  Make.SKODA,
  Make.SMART,
  Make['SSANG YONG'],
  Make.SUBARU,
  Make.SUZUKI,
  Make.TESLA,
  Make.TOYOTA,
  Make.VOLVO,
  Make.VW,
  Make.POLESTAR,
];

export const fuelTypes: Record<string, FuelType> = {
  [FuelType[FuelType.PETROL]]: FuelType.PETROL,
  [FuelType[FuelType.HYBRID]]: FuelType.HYBRID,
  [FuelType[FuelType.DIESEL]]: FuelType.DIESEL,
  [FuelType[FuelType.ELECTRIC]]: FuelType.ELECTRIC,
  [FuelType[FuelType.CNG]]: FuelType.CNG,
  [FuelType[FuelType.E85]]: FuelType.E85,
  [FuelType[FuelType.LPG]]: FuelType.LPG,
  [FuelType[FuelType.H2]]: FuelType.H2,
};

export const fuelTypesDetailed: Record<string, FuelTypesDetailed> = {
  [FuelTypesDetailed[FuelTypesDetailed.PETROL]]: FuelTypesDetailed.PETROL,
  [FuelTypesDetailed[FuelTypesDetailed.DIESEL]]: FuelTypesDetailed.DIESEL,
  [FuelTypesDetailed[FuelTypesDetailed.HYBRID]]: FuelTypesDetailed.HYBRID,
  [FuelTypesDetailed[FuelTypesDetailed.HYBRID_PETROL]]:
    FuelTypesDetailed.HYBRID_PETROL,
  [FuelTypesDetailed[FuelTypesDetailed.HYBRID_DIESEL]]:
    FuelTypesDetailed.HYBRID_DIESEL,
  [FuelTypesDetailed[FuelTypesDetailed.ELECTRIC]]: FuelTypesDetailed.ELECTRIC,
  [FuelTypesDetailed[FuelTypesDetailed.CNG_PETROL]]:
    FuelTypesDetailed.CNG_PETROL,
  [FuelTypesDetailed[FuelTypesDetailed.CNG]]: FuelTypesDetailed.CNG,
  [FuelTypesDetailed[FuelTypesDetailed.E85]]: FuelTypesDetailed.E85,
  [FuelTypesDetailed[FuelTypesDetailed.LPG]]: FuelTypesDetailed.LPG,
  [FuelTypesDetailed[FuelTypesDetailed.H2]]: FuelTypesDetailed.H2,
  [FuelTypesDetailed[FuelTypesDetailed.FULL_HYBRID_DIESEL]]:
    FuelTypesDetailed.FULL_HYBRID_DIESEL,
  [FuelTypesDetailed[FuelTypesDetailed.FULL_HYBRID_PETROL]]:
    FuelTypesDetailed.FULL_HYBRID_PETROL,
  [FuelTypesDetailed[FuelTypesDetailed.PLUG_IN_HYBRID_PETROL]]:
    FuelTypesDetailed.PLUG_IN_HYBRID_PETROL,
  [FuelTypesDetailed[FuelTypesDetailed.PLUG_IN_HYBRID_DIESEL]]:
    FuelTypesDetailed.PLUG_IN_HYBRID_DIESEL,
  [FuelTypesDetailed[FuelTypesDetailed.MILD_HYBRID_PETROL]]:
    FuelTypesDetailed.MILD_HYBRID_PETROL,
  [FuelTypesDetailed[FuelTypesDetailed.MILD_HYBRID_DIESEL]]:
    FuelTypesDetailed.MILD_HYBRID_DIESEL,
};

export const HYBRIDS = [
  FuelTypesDetailed.HYBRID,
  FuelTypesDetailed.HYBRID_PETROL,
  FuelTypesDetailed.HYBRID_DIESEL,
  FuelTypesDetailed.FULL_HYBRID_DIESEL,
  FuelTypesDetailed.FULL_HYBRID_PETROL,
  FuelTypesDetailed.PLUG_IN_HYBRID_PETROL,
  FuelTypesDetailed.PLUG_IN_HYBRID_DIESEL,
  FuelTypesDetailed.MILD_HYBRID_PETROL,
  FuelTypesDetailed.MILD_HYBRID_DIESEL,
];
