import { routeConstants } from 'constants/routes';
import ManagePeople from 'pages/ManagePeople';
import { Actions as ManagePeopleActions } from 'pages/ManagePeople/store/actions';
import { Actions as DealsResultActions } from 'pages/ViewAllDeals/store/actions';
import { RootState } from 'pages/_store/root-reducer';
import { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useParams } from 'react-router-dom';
import { DefaultVendorParams } from 'types';
import ErrorMessage from '../../components/Alerts/ErrorMessage';
import AddListingsPage from '../AddEditListings/pages/AddListingsPage';
import EditListingsPage from '../AddEditListings/pages/EditListingsPage';
import VendorInformation from '../VendorInformation';
import ViewAllListings from '../ViewAllDeals';
import messages from './ViewVendorRootPage.translations';

const ViewVendorRootPage: FC = () => {
  const dispatch = useDispatch();
  const { vendorId } = useParams<DefaultVendorParams>();
  const { formatMessage } = useIntl();
  const { selectedVendor, isVendorNotFound } = useSelector((state: RootState) => state.dealResultsState, shallowEqual);

  useEffect(() => {
    if (selectedVendor?.id !== Number(vendorId)) {
      dispatch(DealsResultActions.viewVendorInfoRequest(Number(vendorId)));
    }
  }, [dispatch, vendorId, selectedVendor]);

  useEffect(() => {
    if (vendorId) {
      dispatch(ManagePeopleActions.getSellersRequest(Number(vendorId)));
    } else {
      dispatch(ManagePeopleActions.getSellersSuccess([]));
    }
  }, [dispatch, vendorId]);

  if (isVendorNotFound) {
    return (
      <ErrorMessage title={formatMessage(messages.ViewVendorRootPageCouldNotFindVendorTitle)}>
        <span>{formatMessage(messages.ViewVendorRootPageErrorMessage)}</span>
      </ErrorMessage>
    );
  }

  return (
    <Switch>
      <Route path={routeConstants.PROTECTED.VIEW_DEALS_PAGE.route} exact component={ViewAllListings} />
      <Route path={routeConstants.PROTECTED.ADD_LISTINGS_PAGE.route} exact component={AddListingsPage} />
      <Route path={routeConstants.PROTECTED.EDIT_LISTINGS_PAGE.route} exact component={EditListingsPage} />
      <Route
        exact
        path={routeConstants.PROTECTED.VENDOR_SETTINGS_VENDOR_INFORMATION_PAGE.route}
        component={VendorInformation}
      />
      <Route exact path={routeConstants.PROTECTED.MANAGE_PEOPLE.route} component={ManagePeople} />
    </Switch>
  );
};

export default ViewVendorRootPage;
