import classNames from 'classnames';
import Alert, { AlertType } from 'components/Alerts/Alert';
import ErrorMessage from 'components/Alerts/ErrorMessage';
import ButtonBase from 'components/Forms/ButtonBase';
import LoadingIndicator from 'components/LoadingIndicator';
import { Formik, FormikProps } from 'formik';
import { useQuery } from 'hooks/router';
import { RootState } from 'pages/_store/root-reducer';
import { FunctionComponent, useEffect, useState } from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Prompt, useParams } from 'react-router-dom';
import { DefaultVendorParams } from 'types';
import { compose } from 'utils/compose';
import * as Yup from 'yup';
import { Actions as GlobalActions } from '../_store/global/actions';
import ContactInformation from './components/ContactInformation';
import Header from './components/Header';
import Navigation from './components/Navigation';
import { Actions as VendorInformationActions } from './store/actions';
import { initialState, VendorInformationFilters } from './store/initial-state';
import { VendorInformationFormData } from './store/types';
import styles from './VendorInformation.module.scss';
import Translations from './VendorInformation.translations';

type QueryProps = Partial<VendorInformationFilters>;

const formikSchema = ({ formatMessage }: IntlShape) =>
  Yup.object().shape({
    name: Yup.string().required(formatMessage(Translations.VendorInformationThisFieldIsRequired)),
    phoneNumber: Yup.string().required(formatMessage(Translations.VendorInformationThisFieldIsRequired)),
    email: Yup.string()
      .email(formatMessage(Translations.VendorInformationInvalidEmail))
      .required(formatMessage(Translations.VendorInformationThisFieldIsRequired)),
    streetAndNumber: Yup.string().required(formatMessage(Translations.VendorInformationThisFieldIsRequired)),
    city: Yup.string().required(formatMessage(Translations.VendorInformationThisFieldIsRequired)),
    zip: Yup.number()
      .typeError(formatMessage(Translations.VendorInformationInvalidFormat))
      .required(formatMessage(Translations.VendorInformationThisFieldIsRequired)),
  });

const VendorInformation: FunctionComponent = () => {
  const dispatch = useDispatch();
  const query = useQuery<QueryProps>({
    initialObj: initialState.filters,
  });

  const [isContactInformation, setIsContactInformation] = useState(true);
  const [showErrorSummary, setShowErrorSummary] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const intl = useIntl();
  const { formatMessage } = intl;
  const { vendorId } = useParams<DefaultVendorParams>();

  const { vendor, isLoading, isSuccessfullyUpdated, isError } = useSelector(
    (state: RootState) => ({
      vendor: state.vendorInformationPage.selectedVendor,
      isLoading: state.vendorInformationPage.isLoading,
      isSuccessfullyUpdated: state.vendorInformationPage.successfullyUpdated,
      isError: state.vendorInformationPage.isError,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(
      GlobalActions.setConfirmationModalProps({
        message: formatMessage(Translations.VendorInformationYouHaveUnsavedChanged),
        okLabel: formatMessage(Translations.VendorInformationContinueEditing),
        closeLabel: formatMessage(Translations.VendorInformationDiscardChanges),
      })
    );
  }, [dispatch, intl, formatMessage]);

  useEffect(() => {
    return () => {
      dispatch(VendorInformationActions.resetState());
    };
  }, [dispatch, query, vendorId]);

  const onSubmit = (values: VendorInformationFormData) => {
    setUnsavedChanges(false);
    dispatch(VendorInformationActions.updateVendorInfoRequest(values));
  };

  const onSuccessMessageClose = () => {
    dispatch(VendorInformationActions.hideSuccessAlertMessage());
  };

  const unique = (value: string, index: number, self: string[]) => {
    return self.indexOf(value) === index;
  };

  const onChange = () => {
    setUnsavedChanges(true);
  };

  return (
    <div>
      <Header />
      <div className={styles.content}>
        <Navigation
          isContactInformationSelected={isContactInformation}
          setIsContactInformationSelected={setIsContactInformation}
        />
        <div className={styles.rootContent}>
          <LoadingIndicator isLoading={isLoading}>
            {vendor && (
              <Formik
                initialValues={{
                  name: vendor.name || '',
                  phoneNumber: vendor.phone || '',
                  email: vendor.leadEmail || '',
                  streetAndNumber: vendor.street || '',
                  city: vendor.city || '',
                  zip: vendor.zip?.toString() || '',
                }}
                validationSchema={formikSchema(intl)}
                onSubmit={onSubmit}
                enableReinitialize={true}
                render={(props: FormikProps<VendorInformationFormData>) => (
                  <>
                    {isSuccessfullyUpdated && (
                      <div className={styles.messageContainer}>
                        <Alert
                          type={AlertType.SUCCESS}
                          message={formatMessage(Translations.VendorInformationContactInformationWasSaved)}
                          onClose={onSuccessMessageClose}
                        />
                      </div>
                    )}
                    {isError && (
                      <div className={styles.messageContainer}>
                        <ErrorMessage title={formatMessage(Translations.VendorInformationSomethingWentWrong)}>
                          <>
                            <div>{formatMessage(Translations.VendorInformationPleaseTrySavingOneMoreTime)}</div>
                            <div>
                              {formatMessage(Translations.VendorInformationIfThisIssuePersistPleaseContactOurSupport)}
                            </div>
                          </>
                        </ErrorMessage>
                      </div>
                    )}
                    {showErrorSummary && (
                      <div className={styles.messageContainer}>
                        <ErrorMessage
                          title={formatMessage(Translations.VendorInformationContactInformationCouldNotBeChanged)}
                        >
                          <div>
                            {Object.keys(props.errors)
                              .filter(unique)
                              .map((field) => (
                                <div key={field}>
                                  {` - `}
                                  <FormattedMessage
                                    id="FieldMustMeetTheRequiredCriteria"
                                    defaultMessage="{field} must meet the required criteria"
                                    values={{
                                      field: (
                                        <u>
                                          {field === 'name' && formatMessage(Translations.VendorInformationName)}
                                          {field === 'phoneNumber' &&
                                            formatMessage(Translations.VendorInformationPhoneNumber)}
                                          {field === 'email' && formatMessage(Translations.VendorInformationLeadEmail)}
                                          {field === 'streetAndNumber' &&
                                            formatMessage(Translations.VendorInformationStreetAndNumber)}
                                          {field === 'city' && formatMessage(Translations.VendorInformationCity)}
                                          {field === 'zip' && formatMessage(Translations.VendorInformationZipCode)}
                                        </u>
                                      ),
                                    }}
                                  />
                                </div>
                              ))}
                          </div>
                        </ErrorMessage>
                      </div>
                    )}
                    <form onSubmit={props.handleSubmit}>
                      <div className={styles.cardRoot}>
                        <div
                          className={classNames({
                            [styles.hidden]: !isContactInformation,
                          })}
                        >
                          <ContactInformation formikProps={props} onChange={onChange} />
                        </div>
                        <ButtonBase
                          type="submit"
                          isLoading={isLoading}
                          classes={{
                            root: styles.submitButton,
                          }}
                          onClick={() => setShowErrorSummary(Object.keys(props.errors).length > 0)}
                        >
                          {formatMessage(Translations.VendorInformationSaveChanges)}
                        </ButtonBase>
                      </div>
                    </form>
                  </>
                )}
              />
            )}
          </LoadingIndicator>
        </div>
      </div>
      <Prompt when={unsavedChanges} message={''} />
    </div>
  );
};

export default compose(VendorInformation);
