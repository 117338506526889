import { defineMessages } from 'react-intl';

export default defineMessages({
  ViewAllVendorsHeaderAllVendors: {
    id: 'ViewAllVendorsHeaderAllVendors',
    defaultMessage: 'All vendors',
  },
  ViewAllVendorsHeaderShowingCountFilteredVendors: {
    id: 'ViewAllVendorsHeaderShowingCountFilteredVendors',
    defaultMessage: 'Showing {countFiltered} vendors',
  },
});
