import { routeConstants } from 'constants/routes';
import { FunctionComponent } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import styles from './AccountSettings.module.scss';
import Header from './components/Header';
import Navigation from './components/Navigation';
import ChangePassword from './pages/ChangePassword';
import SellerProfile from './pages/SellerProfile';
const AccountSettings: FunctionComponent = () => {
  return (
    <div className={styles.root}>
      <Header />
      <div className={styles.content}>
        <Navigation />
        <div className={styles.subContent}>
          <Switch>
            <Route path={routeConstants.PROTECTED.PROFILE.route} exact>
              <SellerProfile />
            </Route>
            <Route path={routeConstants.PROTECTED.CHANGE_PASSWORD.route} exact>
              <ChangePassword />
            </Route>
            <Route>
              <Redirect to={routeConstants.PROTECTED.PROFILE.url()} />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default AccountSettings;
