import { uploadFile } from 'api/offers';
import Translations from 'App.translations';
import { Actions as GlobalActions } from 'pages/_store/global/actions';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { FileType, UploadUpdateFileTypeAdmin } from 'types';

interface UploadCustomerImageArgs {
  offerId: string;
  customerId: number;
  file: File;
  fileType: FileType;
  isAdminFileType: boolean;
  uploadUpdateFileTypeSlug?: string;
  isMostUsedDocument?: boolean;
}

export const useUploadFile = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const call = async ({
    offerId,
    customerId,
    file,
    fileType,
    isAdminFileType,
    uploadUpdateFileTypeSlug,
    isMostUsedDocument,
  }: UploadCustomerImageArgs) => {
    try {
      const formData = new FormData();
      formData.append('upload', file);

      const result = await uploadFile({
        offerId,
        customerId,
        formData,
        fileType,
        isAdminFileType,
        uploadUpdateFileTypeSlug,
        isMostUsedDocument,
      });

      return result;
    } catch (e) {
      let message = formatMessage(Translations.AppErrorUploadingFile);
      if (fileType === UploadUpdateFileTypeAdmin.KFP_RESULTS && e.message) {
        message = e.message;
      }

      dispatch(
        GlobalActions.sendNotification({
          message,
          variant: 'error',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'top',
          },
        })
      );
      return false;
    }
  };

  return call;
};
