import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FunctionComponent, ReactNode, useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import styles from './Callout.module.scss';

interface Props {
  header: string;
  children: ReactNode;
  tooltipId: string;
  globalEventOff?: string;
}

const Callout: FunctionComponent<Props> = ({ header, children, tooltipId, globalEventOff = 'click' }) => {
  const ref = useRef<ReactTooltip>(null);

  const onCloseButtonClick = () => {
    if (ref?.current) {
      const element = (ref.current as any).tooltipRef as HTMLElement;
      element.classList.remove('show');
    }
  };

  return (
    <ReactTooltip
      id={tooltipId}
      globalEventOff={globalEventOff}
      clickable
      effect="solid"
      type="light"
      border
      className={styles.callout}
      ref={ref}
      getContent={() => (
        <div>
          <div className={styles.calloutHeader}>
            {header}
            <button className={styles.calloutCloseButton} type="button" onClick={onCloseButtonClick}>
              <FontAwesomeIcon icon={['far', 'times']} />
            </button>
          </div>
          <div className={styles.calloutBody}>{children}</div>
        </div>
      )}
    />
  );
};

export default Callout;
