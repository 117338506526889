import { createStyles } from '@material-ui/core';
import { GowagoTheme } from 'theme';

export const styles = (theme: GowagoTheme) =>
  createStyles({
    root: {
      height: 146,
      width: 234,
    },
    fileUploadInputRoot: {
      boxSizing: 'border-box',
      width: '100%',
      height: '100%',
      border: `1px dashed ${theme.gowago.borderGrey}`,
      borderRadius: 2,
      backgroundColor: theme.gowago.lightGrey,
      display: 'flex',
      justifyItems: 'center',
      alignItems: 'center',
    },
    fileUploadInputRootActive: {
      border: `1px solid ${theme.gowago.borderGrey}`,
      opacity: 0.5,
    },
    fileUploadInputRootActiveDisabled: {
      opacity: 1,
      border: `1px dashed ${theme.gowago.borderGrey}`,
    },
    fileUploadDnDRoot: {
      position: 'relative',
      width: '100%',
      height: '100%',
    },
    fileUploadInputContent: {
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'center',
      width: '100%',
      fontSize: theme.gowago.fontSize.smaller,
      lineHeight: theme.gowago.lineHeight.rem[22],
    },
    icon: {
      fontSize: theme.gowago.fontSize.largest,
      lineHeight: theme.gowago.lineHeight.rem[22],
      color: theme.gowago.text.secondary,
      marginBottom: theme.gowago.spacing[12],
    },
    filePreviewRoot: {
      position: 'absolute',
      left: 0,
      top: 0,
      height: '100%',
      width: '100%',
    },
  });
