import { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../_store/root-reducer';
import AddEditPageSelectors from '../store/selectors';

export const useJumpToTopOnError = () => {
  const isError = useSelector((state: RootState) => AddEditPageSelectors.extractIsError(state), shallowEqual);

  useEffect(() => {
    if (isError) {
      window.scrollTo(0, 0);
    }
  }, [isError]);
};
