import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { FormikHandlers } from 'formik';
import { FunctionComponent, useMemo } from 'react';
import Select, { components, Styles } from 'react-select';
import ReactTooltip from 'react-tooltip';
import { SelectOption } from 'types';
import { compose, composeStyle } from 'utils/compose';
import { v4 as uuid } from 'uuid';
import styles from './SelectionBase.module.scss';

interface Props<OptionType extends SelectOption = SelectOption, IsMulti extends boolean = false> {
  options: SelectOption[];
  tooltip?: string;
  placeholder?: string;
  disabled?: boolean;
  defaultValue?: SelectOption;
  value?: SelectOption;
  touched?: boolean;
  error?: boolean;
  onBlur?: FormikHandlers['handleBlur'];
  className?: string;
  customStyles?: Partial<Styles<OptionType, IsMulti>>;
  onChange?: (value: string) => void;
  classes?: Partial<typeof styles>;
  inputAdornment?: string;
}

type DropdownIndicatorProps = JSX.LibraryManagedAttributes<typeof components.DropdownIndicator, {}>;
const IndicatorSeparator = () => <span className={styles.indicatorSeparator} />;
const DropdownIndicator = (props: DropdownIndicatorProps) => {
  return <FontAwesomeIcon icon={['fas', 'caret-down']} className={styles.caretDownIcon} />;
};

const SelectionBase: FunctionComponent<Props> = ({
  options,
  tooltip,
  placeholder,
  onChange,
  onBlur,
  defaultValue,
  value,
  touched,
  error,
  className,
  customStyles,
  disabled = false,
  classes,
  inputAdornment,
}) => {
  const tooltipUuid = useMemo(uuid, []);
  const componentStyles = composeStyle(styles, classes);
  const cx = classNames.bind(componentStyles);

  if (!customStyles) {
    customStyles = {};
  }
  customStyles.control = (styles) => ({
    ...styles,
    border: 'none !important',
    boxShadow: 'none !important',
  });
  customStyles.container = (styles) => ({
    ...styles,
    width: '100%',
  });
  customStyles.option = (styles) => ({
    ...styles,
    minHeight: 37,
  });
  if (disabled) {
    customStyles.control = (styles) => ({
      ...styles,
      backgroundColor: '#E9ECEF',
      cursor: 'not-allowed',
      pointerEvents: 'auto',
    });
    customStyles.singleValue = (styles) => ({
      color: '#495057',
    });
  }

  const handleSelectionChange = (selection: SelectOption) => {
    if (onChange) onChange(selection?.value);
  };

  return (
    <>
      <div
        data-tip={tooltip}
        data-for={tooltipUuid}
        className={cx(componentStyles.root, { [componentStyles.error]: touched && error }, className)}
      >
        {!!inputAdornment && <div className={componentStyles.inputAdournment}>{inputAdornment}</div>}
        <Select
          options={options}
          onBlur={onBlur}
          onChange={handleSelectionChange}
          placeholder={placeholder || ''}
          components={{ IndicatorSeparator, DropdownIndicator }}
          isDisabled={disabled}
          defaultValue={defaultValue}
          className={styles.select}
          value={value}
          styles={customStyles}
        />
      </div>
      <ReactTooltip key={tooltip} effect="solid" id={tooltipUuid} />
    </>
  );
};

export default compose<Props>(SelectionBase);
