import { defineMessages } from 'react-intl';

export default defineMessages({
  ManagePeoplePeople: {
    id: 'ManagePeoplePeople',
    defaultMessage: 'People',
  },
  ManagePeopleContactOurSupportTeamToAddAndRemovePeople: {
    id: 'ManagePeopleContactOurSupportTeamToAddAndRemovePeople',
    defaultMessage:
      'Contact our support team to add and remove people from your team or to edit their contact information.',
  },
});
