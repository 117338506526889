import { FormikErrors } from 'formik';
import {
  CarMakesResponse,
  Deal,
  DealOwnership,
  DealRequest,
  DefaultDealOwnershipRequest,
  DefaultResidualValuesRequest,
  FilterListingsDto,
  ImageGalleryElement,
  ListingPublic,
  ResidualValue,
} from 'types';
import { ActionsUnion, createAction } from 'utils/action-utils';
import { AddEditListingSchema } from '../schema';
import { ErrorType } from './initial-state';

export enum ActionTypes {
  GET_DEAL_REQUEST = '[ADD_EDIT_LISTINGS] GET_DEAL_REQUEST_REQUEST',
  GET_DEAL_SUCCESS = '[ADD_EDIT_LISTINGS] GET_DEAL_SUCCESS',
  GET_DEAL_FAILURE = '[ADD_EDIT_LISTINGS] GET_DEAL_FAILURE',
  SET_DEAL_IMAGES = '[ADD_EDIT_LISTINGS] SET_DEAL_IMAGES',
  SET_IS_LOADING_IMAGES = '[ADD_EDIT_LISTINGS] SET_IS_LOADING_IMAGES',
  GET_DEAL_OWNERSHIP_REQUEST = '[ADD_EDIT_LISTINGS] GET_DEAL_OWNERSHIP_REQUEST',
  GET_DEAL_OWNERSHIP_SUCCESS = '[ADD_EDIT_LISTINGS] GET_DEAL_OWNERSHIP_SUCCESS',
  GET_DEAL_OWNERSHIP_FAILURE = '[ADD_EDIT_LISTINGS] GET_DEAL_OWNERSHIP_FAILURE',
  GET_RESIDUAL_VALUES_REQUEST = '[ADD_EDIT_LISTINGS] GET_RESIDUAL_VALUES_REQUEST',
  GET_RESIDUAL_VALUES_SUCCESS = '[ADD_EDIT_LISTINGS] GET_RESIDUAL_VALUES_SUCCESS',
  GET_RESIDUAL_VALUES_FAILURE = '[ADD_EDIT_LISTINGS] GET_RESIDUAL_VALUES_FAILURE',
  SAVE_DRAFT_REQUEST = '[ADD_EDIT_LISTINGS] SAVE_DRAFT_REQUEST',
  SAVE_DRAFT_SUCCESS = '[ADD_EDIT_LISTINGS] SAVE_DRAFT_SUCCESS',
  SAVE_DRAFT_FAILURE = '[ADD_EDIT_LISTINGS] SAVE_DRAFT_FAILURE',
  MOVE_TO_TRASH_REQUEST = '[ADD_EDIT_LISTINGS] MOVE_TO_TRASH_REQUEST',
  MOVE_TO_TRASH_SUCCESS = '[ADD_EDIT_LISTINGS] MOVE_TO_TRASH_SUCCESS',
  MOVE_TO_TRASH_FAILURE = '[ADD_EDIT_LISTINGS] MOVE_TO_TRASH_FAILURE',
  PUBLISH_REQUEST = '[ADD_EDIT_LISTINGS] PUBLISH_REQUEST',
  PUBLISH_SUCCESS = '[ADD_EDIT_LISTINGS] PUBLISH_SUCCESS',
  PUBLISH_FAILURE = '[ADD_EDIT_LISTINGS] PUBLISH_FAILURE',
  GET_CARS_REQUEST = '[ADD_EDIT_LISTING] GET_CARS_REQUEST',
  GET_CARS_SUCCESS = '[ADD_EDIT_LISTING] GET_CARS_SUCCESS',
  GET_CARS_FAILURE = '[ADD_EDIT_LISTING] GET_CARS_FAILURE',
  RESET_CARS = '[ADD_EDIT_LISTING] RESET_CARS',
  UPDATE_LOCKED_TO_SOURCE_REQUEST = '[ADD_EDIT_LISTINGS] UPDATE_LOCKED_ON_SOURCE_REQUEST',
  UPDATE_LOCKED_TO_SOURCE_SUCCESS = '[ADD_EDIT_LISTINGS] UPDATE_LOCKED_ON_SOURCE_SUCCESS',
  UPDATE_LOCKED_TO_SOURCE_FAILURE = '[ADD_EDIT_LISTINGS] UPDATE_LOCKED_ON_SOURCE_FAILURE',
  UPLOAD_NEW_IMAGES_REQUEST = '[ADD_EDIT_LISTINGS] UPLOAD_NEW_IMAGES_REQUEST',
  UPLOAD_NEW_IMAGES_SUCCESS = '[ADD_EDIT_LISTINGS] UPLOAD_NEW_IMAGES_SUCCESS',
  UPLOAD_NEW_IMAGES_FAILURE = '[ADD_EDIT_LISTINGS] UPLOAD_NEW_IMAGES_FAILURE',
  RESET_STATE = '[ADD_EDIT_LISTINGS] RESET_STATE',
  UPDATE_DEAL_WITH_CAR_DATA = '[ADD_EDIT_LISTING] UPDATE_DEAL_WITH_CAR_DATA',
  UPDATE_DEAL_WITH_CAR_FILTER = '[ADD_EDIT_LISTING] UPDATE_DEAL_WITH_CAR_FILTER',
  UPDATE_DEAL_FOR_MANUAL_ADDING = '[ADD_EDIT_LISTING] UPDATE_DEAL_FOR_MANUAL_ADDING',
  HIDE_SUCCESS_SAVED_ALERT_MESSAGE = '[ADD_EDIT_LISTINGS] HIDE_SUCCESS_SAVED_ALERT_MESSAGE',
  SET_UNSAVED_CHANGES = '[ADD_EDIT_LISTINGS] SET_UNSAVED_CHANGES',
  HIDE_SYNC_NOTIFICATION = '[ADD_EDIT_LISTINGS] HIDE_SYNC_NOTIFICATION',
  SET_IS_ENABLE_SYNC_DIALOG_OPEN = '[ADD_EDIT_LISTINGS] SET_IS_ENABLE_SYNC_DIALOG_OPEN',
  SET_IS_DISABLE_SYNC_DIALOG_OPEN = '[ADD_EDIT_LISTINGS] SET_IS_DISABLE_SYNC_DIALOG_OPEN',
  SET_IS_STEP_CHANGE_DIALOG_OPEN = '[ADD_EDIT_LISTINGS] SET_IS_STEP_CHANGE_DIALOG_OPEN',
  SET_IS_UPDATE_SYNC_ERROR = '[ADD_EDIT_LISTINGS] SET_IS_UPDATE_SYNC_ERROR',
  SET_DRAFT_VALIDATION_ERROR = '[ADD_EDIT_LISTINGS] SET_DRAFT_VALIDATION_ERROR',
  SET_ERROR = '[ADD_EDIT_LISTINGS] SET_ERROR',
  DUPLICATE_SELECTED_DEAL_REQUEST = '[ADD_EDIT_LISTINGS] DUPLICATE_SELECTED_DEAL_REQUEST',
  DUPLICATE_SELECTED_DEAL_FAILURE = '[ADD_EDIT_LISTINGS] DUPLICATE_SELECTED_DEAL_FAILURE',
}

export const Actions = {
  getDealRequest: (dealId: number) => createAction(ActionTypes.GET_DEAL_REQUEST, dealId),
  getDealSuccess: (deal?: Deal) => createAction(ActionTypes.GET_DEAL_SUCCESS, { deal }),
  getDealFailure: () => createAction(ActionTypes.GET_DEAL_FAILURE),
  setDealImages: (dealImages: File[] = []) => createAction(ActionTypes.SET_DEAL_IMAGES, dealImages),
  setIsLoadingImages: (value: boolean) => createAction(ActionTypes.SET_IS_LOADING_IMAGES, value),
  getDealOwnershipRequest: (data: DefaultDealOwnershipRequest) =>
    createAction(ActionTypes.GET_DEAL_OWNERSHIP_REQUEST, data),
  getDealOwnershipSuccess: (dealOwnership: DealOwnership) =>
    createAction(ActionTypes.GET_DEAL_OWNERSHIP_SUCCESS, dealOwnership),
  getDealOwnershipFailure: () => createAction(ActionTypes.GET_DEAL_OWNERSHIP_FAILURE),
  getResidualValuesRequest: (defaultResidualValuesRequest: DefaultResidualValuesRequest) =>
    createAction(ActionTypes.GET_RESIDUAL_VALUES_REQUEST, defaultResidualValuesRequest),
  getResidualValuesSuccess: (residualValues: ResidualValue[]) =>
    createAction(ActionTypes.GET_RESIDUAL_VALUES_SUCCESS, residualValues),
  getResidualValuesFailure: () => createAction(ActionTypes.GET_RESIDUAL_VALUES_FAILURE),
  saveDraftRequest: (values: AddEditListingSchema, data: DealRequest, images: File[], vendorId: number, id?: number) =>
    createAction(ActionTypes.SAVE_DRAFT_REQUEST, { id, data, images, values, vendorId }),
  saveDraftSuccess: (deal?: Deal, dealImages?: File[]) =>
    createAction(ActionTypes.SAVE_DRAFT_SUCCESS, { deal, dealImages }),
  saveDraftFailure: (errorMessage?: Array<string>) => createAction(ActionTypes.SAVE_DRAFT_FAILURE, { errorMessage }),
  moveToTrashRequest: (id?: number) => createAction(ActionTypes.MOVE_TO_TRASH_REQUEST, id),
  moveToTrashSuccess: () => createAction(ActionTypes.MOVE_TO_TRASH_SUCCESS),
  moveToTrashFailure: () => createAction(ActionTypes.MOVE_TO_TRASH_FAILURE),
  publishRequest: (data: DealRequest, images: File[], id?: number) =>
    createAction(ActionTypes.PUBLISH_REQUEST, { id, data, images }),
  publishSuccess: (deal?: Deal, dealImages?: File[]) => createAction(ActionTypes.PUBLISH_SUCCESS, { deal, dealImages }),
  publishFailure: (errorMessage?: Array<string>) => createAction(ActionTypes.PUBLISH_FAILURE, { errorMessage }),
  getCarsRequest: (data: FilterListingsDto) => createAction(ActionTypes.GET_CARS_REQUEST, { data }),
  getCarsSuccess: (data?: ListingPublic[]) => createAction(ActionTypes.GET_CARS_SUCCESS, data),
  getCarsFailure: () => createAction(ActionTypes.GET_CARS_FAILURE),
  resetCars: () => createAction(ActionTypes.RESET_CARS),
  uploadNewImagesRequest: (id: number, images: File[]) =>
    createAction(ActionTypes.UPLOAD_NEW_IMAGES_REQUEST, { id, images }),
  uploadNewImagesSuccess: (images: Array<ImageGalleryElement>) =>
    createAction(ActionTypes.UPLOAD_NEW_IMAGES_SUCCESS, { images }),
  uploadNewImagesFailure: () => createAction(ActionTypes.UPLOAD_NEW_IMAGES_FAILURE),
  updateLockedToSourceRequest: (isLockedToSource: boolean) =>
    createAction(ActionTypes.UPDATE_LOCKED_TO_SOURCE_REQUEST, isLockedToSource),
  updateLockedToSourceSuccess: (deal: Deal) => createAction(ActionTypes.UPDATE_LOCKED_TO_SOURCE_SUCCESS, deal),
  updateLockedToSourceFailure: () => createAction(ActionTypes.UPDATE_LOCKED_TO_SOURCE_FAILURE),
  updateDealWithCarData: (car: Partial<ListingPublic>, carMakes: CarMakesResponse) =>
    createAction(ActionTypes.UPDATE_DEAL_WITH_CAR_DATA, { car, carMakes }),
  updateDealWithCarFilter: (filter: FilterListingsDto) =>
    createAction(ActionTypes.UPDATE_DEAL_WITH_CAR_FILTER, { filter }),
  updateDealForManualAdding: () => createAction(ActionTypes.UPDATE_DEAL_FOR_MANUAL_ADDING),
  hideSuccessAlertMessage: () => createAction(ActionTypes.HIDE_SUCCESS_SAVED_ALERT_MESSAGE),
  setUnsavedChanges: () => createAction(ActionTypes.SET_UNSAVED_CHANGES),
  hideSyncNotification: () => createAction(ActionTypes.HIDE_SYNC_NOTIFICATION),
  setIsStepChangeModalOpen: (isOpen: boolean) => createAction(ActionTypes.SET_IS_STEP_CHANGE_DIALOG_OPEN, isOpen),
  setIsEnableSyncDialogOpen: (isOpen: boolean) => createAction(ActionTypes.SET_IS_ENABLE_SYNC_DIALOG_OPEN, isOpen),
  setIsDisableSyncDialogOpen: (isOpen: boolean) => createAction(ActionTypes.SET_IS_DISABLE_SYNC_DIALOG_OPEN, isOpen),
  setIsUpdateSyncError: (isError: boolean) => createAction(ActionTypes.SET_IS_UPDATE_SYNC_ERROR, isError),
  resetState: () => createAction(ActionTypes.RESET_STATE),
  setDraftValidationError: (errors: FormikErrors<Deal>) => createAction(ActionTypes.SET_DRAFT_VALIDATION_ERROR, errors),
  setError: (errorType: ErrorType, errorMessageParams: { [key: string]: string } = {}) =>
    createAction(ActionTypes.SET_ERROR, { errorType, errorMessageParams }),
  duplicateDealRequest: (vendorId: number) => createAction(ActionTypes.DUPLICATE_SELECTED_DEAL_REQUEST, vendorId),
  duplicateDealFailure: () => createAction(ActionTypes.DUPLICATE_SELECTED_DEAL_FAILURE),
};

export type Actions = ActionsUnion<typeof Actions>;
