import { FuelType, FuelTypesDetailed, Make } from '@carcodex/gow-shared-mono/modules/car/car.types';
import { DEFAULT_DEALER_DELIVERY_FEE_IN_CHF, MAX_KM, MAX_PRICE, MIN_PRICE } from 'constants/constants';
import { IntlShape } from 'react-intl';
import {
  CarLocation,
  ConditionType,
  ContractType,
  DeliveredIn,
  DeliveryTimeUnit,
  DrivenWheels,
  LeasingProvider,
  ResidualValue,
  StartTimeOfWarranty,
  TransmissionDetailed,
  Vendor,
} from 'types';
import * as Yup from 'yup';
import Translations from './AddEditListings.translations';

export interface AddEditListingSchema {
  make?: string;
  modelSlug?: string;
  model?: string;
  version?: string;
  registrationDateMonth?: string;
  registrationDateYear?: string;
  condition?: ConditionType;
  lifetimeMileage?: number;
  modelYear?: number;
  lastTestDate?: string;
  modelDesignationNumber?: string;
  bodyShape?: string;
  vin?: string;
  internalId?: string;
  residualValues?: ResidualValue[];
  guarantee?: boolean;
  guaranteeText?: string;
  warrantyInMonths?: number;
  warrantyInKM?: number;
  warrantyUntilDate?: string;
  warrantyStartType?: StartTimeOfWarranty;
  price?: number;
  newPrice?: number;
  leasingProvider?: LeasingProvider;
  interestRate?: number;
  leaseTypeBusiness?: boolean;
  leaseTypePrivate?: boolean;
  monthlyManagementFee?: number;
  gearboxDetailed?: TransmissionDetailed;
  drivenWheels?: DrivenWheels;
  fuel?: FuelType;
  fuelDetailed?: FuelTypesDetailed;
  exteriorColor?: string;
  interiorColor?: string;
  seats?: number;
  doors?: number;
  cylinders?: number;
  horsepower?: number;
  engineCapacity?: number;
  batteryRange?: number;
  batteryCapacity?: number;
  consumptionPower?: number;
  co2emissions?: number;
  consumptionCity?: number;
  consumptionCountry?: number;
  consumptionTotal?: number;
  powerConsumption?: number;
  efficiencyRating?: string;
  euroStandard?: string;
  tare?: number;
  trailerLoad?: number;
  isOwnershipDefault: boolean;
  isResidualValueDefault: boolean;
  optionsExtended: string[];
  images: File[];
  isLockedToSource: boolean;
  showOnSalePage?: boolean;
  showInFeaturedDeals?: boolean;
  closedUserGroups?: string;
  contractType?: ContractType;
  isDealOfTheMonth?: boolean;
  isAvailableForFleetDiscount?: boolean;
  isHotDeal?: boolean;
  orderGowagoSale?: number | null;
  orderFeatured?: number | null;
  defaultLeasingPeriod?: string | null;
  defaultLeasingMileage?: string | null;
  defaultDownPayment?: number | null;
  carLocation?: Partial<CarLocation>;
  isVendorCarLocation: boolean;
  deliveredIn: DeliveredIn;
  dealerDeliveryFee?: number;
  greyImport?: boolean;
}

export interface AddEditVehicleInformationShema {
  modelDesignationNumber?: string;
  registrationDateYear?: string;
  make?: Make;
  modelSlug?: string;
}

export const AddEditVehicleMakeAndModelInitialValues: AddEditVehicleInformationShema = {
  registrationDateYear: undefined,
  make: undefined,
  modelSlug: undefined,
};

export const AddEditVehicleModelDesignationNumberInitialValues: AddEditVehicleInformationShema = {
  modelDesignationNumber: '',
  registrationDateYear: undefined,
};

export const getInitialValues = (vendor?: Vendor): AddEditListingSchema => ({
  condition: undefined,
  bodyShape: undefined,
  internalId: undefined,
  lastTestDate: undefined,
  lifetimeMileage: undefined,
  modelYear: undefined,
  make: undefined,
  model: undefined,
  modelSlug: undefined,
  modelDesignationNumber: undefined,
  registrationDateMonth: undefined,
  registrationDateYear: undefined,
  version: undefined,
  vin: undefined,
  residualValues: [],
  guarantee: false,
  guaranteeText: undefined,
  warrantyInMonths: undefined,
  warrantyInKM: undefined,
  warrantyStartType: undefined,
  warrantyUntilDate: undefined,
  leasingProvider: LeasingProvider.MIGROS_BANK,
  leaseTypeBusiness: false,
  leaseTypePrivate: true,
  monthlyManagementFee: 0,
  gearboxDetailed: undefined,
  drivenWheels: undefined,
  fuelDetailed: undefined,
  exteriorColor: undefined,
  interiorColor: undefined,
  efficiencyRating: undefined,
  euroStandard: undefined,
  price: undefined,
  dealerDeliveryFee: DEFAULT_DEALER_DELIVERY_FEE_IN_CHF,
  horsepower: undefined,
  interestRate: undefined,
  isOwnershipDefault: false,
  isResidualValueDefault: true,
  optionsExtended: [],
  images: [],
  isLockedToSource: false,
  showOnSalePage: false,
  showInFeaturedDeals: false,
  closedUserGroups: undefined,
  contractType: undefined,
  isAvailableForFleetDiscount: false,
  isDealOfTheMonth: false,
  isHotDeal: false,
  orderGowagoSale: undefined,
  orderFeatured: undefined,
  defaultLeasingPeriod: undefined,
  defaultLeasingMileage: undefined,
  defaultDownPayment: undefined,
  carLocation: {
    street: vendor?.street,
    city: vendor?.city,
    zip: vendor?.zip,
  },
  isVendorCarLocation: true,
  deliveredIn: {
    unit: DeliveryTimeUnit.WEEKS,
    min: 2,
    max: 3,
  },
  greyImport: false,
});

export const AddEditVehicleInformationModelDesignationNumberValidationShema = ({ formatMessage }: IntlShape) => {
  const shapeObj = {
    modelDesignationNumber: Yup.string()
      .max(50, formatMessage(Translations.AddEditListingsTooLong))
      .required(formatMessage(Translations.AddEditListingsRequired)),
    registrationDateMonth: Yup.string().nullable(),
    registrationDateYear: Yup.string().nullable(),
  };
  return Yup.object().shape(shapeObj);
};

export const AddEditVehicleInformationMakeAndModelValidationShema = ({ formatMessage }: IntlShape) => {
  const shapeObj = {
    make: Yup.string().required(formatMessage(Translations.AddEditListingsRequired)),
    modelSlug: Yup.string().required(formatMessage(Translations.AddEditListingsRequired)),
    registrationDateYear: Yup.string().required(formatMessage(Translations.AddEditListingsRequired)).nullable(),
  };
  return Yup.object().shape(shapeObj);
};

export const AddEditListingsValidationSchema = ({ formatMessage }: IntlShape) => {
  const shapeObj = {
    make: Yup.string().required(formatMessage(Translations.AddEditListingsRequired)),
    modelSlug: Yup.string().required(formatMessage(Translations.AddEditListingsRequired)),
    modelYear: Yup.number().test(
      'valid-date-range',
      formatMessage(Translations.AddEditListingsDateMustBe),
      function (this, value) {
        if (!value) return true;

        if (value < 1900 || value > 2100) return false;

        return true;
      }
    ),
    model: Yup.string(),
    version: Yup.string()
      .required(formatMessage(Translations.AddEditListingsRequired))
      .max(150, formatMessage(Translations.AddEditListingsTooLong)),
    registrationDateMonth: Yup.string().nullable(),
    registrationDateYear: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string(),
      })
      .nullable(),
    condition: Yup.string().required(formatMessage(Translations.AddEditListingsRequired)),
    lifetimeMileage: Yup.number()
      .min(0, formatMessage(Translations.AddEditListingsOnlyPositiveNumbersAllowed))
      .max(MAX_KM, formatMessage(Translations.AddEditListingsTooLong))
      .required(formatMessage(Translations.AddEditListingsRequired)),
    lastTestDate: Yup.string().nullable(),
    modelDesignationNumber: Yup.string().max(50, formatMessage(Translations.AddEditListingsTooLong)),
    bodyShape: Yup.string(),
    vin: Yup.string().max(50, formatMessage(Translations.AddEditListingsTooLong)),
    internalId: Yup.string().max(50, formatMessage(Translations.AddEditListingsTooLong)),
    guarantee: Yup.boolean(),
    guaranteeText: Yup.string().min(2, formatMessage(Translations.AddEditListingsTooShort)),
    warrantyInMonths: Yup.number()
      .min(0, formatMessage(Translations.AddEditListingsOnlyPositiveNumbersAllowed))
      .nullable(),
    warrantyInKM: Yup.number().min(0, formatMessage(Translations.AddEditListingsOnlyPositiveNumbersAllowed)).nullable(),
    warrantyStartType: Yup.string().nullable(),
    warrantyUntilDate: Yup.string().nullable(),
    price: Yup.number()
      .min(
        MIN_PRICE,
        formatMessage(Translations.AddEditListingsValueMustBeGreaterThan, {
          value: MIN_PRICE,
        })
      )
      .max(
        MAX_PRICE,
        formatMessage(Translations.AddEditListingsValueMustBeLessThan, {
          value: MAX_PRICE,
        })
      )
      .required(formatMessage(Translations.AddEditListingsRequired)),
    newPrice: Yup.number()
      .min(
        MIN_PRICE,
        formatMessage(Translations.AddEditListingsValueMustBeGreaterThan, {
          value: MIN_PRICE,
        })
      )
      .max(
        MAX_PRICE,
        formatMessage(Translations.AddEditListingsValueMustBeLessThan, {
          value: MAX_PRICE,
        })
      )
      .when('isNewPriceRequired', {
        is: true,
        then: Yup.number().required(formatMessage(Translations.AddEditListingsRequired)),
      }),
    dealerDeliveryFee: Yup.number()
      .required(formatMessage(Translations.AddEditListingsRequired))
      .min(0, formatMessage(Translations.AddEditListingsOnlyPositiveNumbersAllowed)),
    leasingProvider: Yup.string().required(formatMessage(Translations.AddEditListingsRequired)),
    interestRate: Yup.number()
      .min(0, formatMessage(Translations.AddEditListingsTooShort))
      .max(100, formatMessage(Translations.AddEditListingsTooLong))
      .nullable(),
    leaseType: Yup.string(),
    monthlyManagementFee: Yup.number().min(0, formatMessage(Translations.AddEditListingsOnlyPositiveNumbersAllowed)),
    gearboxDetailed: Yup.string().required(formatMessage(Translations.AddEditListingsRequired)),
    drivenWheels: Yup.string().required(formatMessage(Translations.AddEditListingsRequired)),
    exteriorColor: Yup.string()
      .min(2, formatMessage(Translations.AddEditListingsTooShort))
      .max(50, formatMessage(Translations.AddEditListingsTooLong)),
    interiorColor: Yup.string()
      .min(2, formatMessage(Translations.AddEditListingsTooShort))
      .max(50, formatMessage(Translations.AddEditListingsTooLong)),
    seats: Yup.number()
      .min(1, formatMessage(Translations.AddEditListingsOnlyPositiveNumbersAllowed))
      .required(formatMessage(Translations.AddEditListingsRequired)),
    doors: Yup.number()
      .min(1, formatMessage(Translations.AddEditListingsOnlyPositiveNumbersAllowed))
      .required(formatMessage(Translations.AddEditListingsRequired)),
    cylinders: Yup.number().min(0, formatMessage(Translations.AddEditListingsOnlyPositiveNumbersAllowed)),
    horsepower: Yup.number()
      .min(0, formatMessage(Translations.AddEditListingsOnlyPositiveNumbersAllowed))
      .required(formatMessage(Translations.AddEditListingsRequired)),
    engineCapacity: Yup.number().min(0, formatMessage(Translations.AddEditListingsOnlyPositiveNumbersAllowed)),
    batteryRange: Yup.number().min(0, formatMessage(Translations.AddEditListingsOnlyPositiveNumbersAllowed)),
    batteryCapacity: Yup.number().min(0, formatMessage(Translations.AddEditListingsOnlyPositiveNumbersAllowed)),
    consumptionPower: Yup.number().min(0, formatMessage(Translations.AddEditListingsOnlyPositiveNumbersAllowed)),
    co2emissions: Yup.number().min(0, formatMessage(Translations.AddEditListingsOnlyPositiveNumbersAllowed)),
    consumptionCity: Yup.number().min(0, formatMessage(Translations.AddEditListingsOnlyPositiveNumbersAllowed)),
    consumptionCountry: Yup.number().min(0, formatMessage(Translations.AddEditListingsOnlyPositiveNumbersAllowed)),
    consumptionTotal: Yup.number().min(0, formatMessage(Translations.AddEditListingsOnlyPositiveNumbersAllowed)),
    powerConsumption: Yup.number().min(0, formatMessage(Translations.AddEditListingsOnlyPositiveNumbersAllowed)),
    efficiencyRating: Yup.string(),
    euroStandard: Yup.string(),
    tare: Yup.number().min(0, formatMessage(Translations.AddEditListingsOnlyPositiveNumbersAllowed)),
    trailerLoad: Yup.number().min(0, formatMessage(Translations.AddEditListingsOnlyPositiveNumbersAllowed)),
    isOwnershipDefault: Yup.boolean(),
    isResidualValueDefault: Yup.boolean(),
    optionsExtended: Yup.array(Yup.string()),
    residualValues: Yup.array(
      Yup.object().shape({
        residualValue: Yup.number(),
      })
    ),
    isLockedToSource: Yup.boolean(),
    orderGowagoSale: Yup.number(),
    orderFeatured: Yup.number(),
    isVendorCarLocation: Yup.boolean(),
    carLocation: Yup.object().when('isVendorCarLocation', {
      is: false,
      then: Yup.object().shape({
        street: Yup.string().required(formatMessage(Translations.AddEditListingsRequired)),
        city: Yup.string().required(formatMessage(Translations.AddEditListingsRequired)),
        zip: Yup.number()
          .required(formatMessage(Translations.AddEditListingsRequired))
          .positive(formatMessage(Translations.AddEditListingsOnlyPositiveNumbersAllowed)),
      }),
    }),
    deliveredIn: Yup.object().shape({
      min: Yup.number()
        .required(formatMessage(Translations.AddEditListingsRequired))
        .positive(formatMessage(Translations.AddEditListingsOnlyPositiveNumbersAllowed))
        .test(
          'less-then-max',
          formatMessage(Translations.AddEditListingsValueMustBeLessThan, {
            value: 'max',
          }),
          function (this, value) {
            const { max } = this.parent;
            if (max <= value) {
              return false;
            }

            return true;
          }
        ),
      max: Yup.number()
        .required(formatMessage(Translations.AddEditListingsRequired))
        .positive(formatMessage(Translations.AddEditListingsOnlyPositiveNumbersAllowed)),
    }),
    greyImport: Yup.boolean(),
  };

  return Yup.object().shape(shapeObj);
};

export const DraftListingsValidationSchema = ({ formatMessage }: IntlShape) => {
  const shapeObj = {
    version: Yup.string().max(150, formatMessage(Translations.AddEditListingsTooLong)),
    registrationDateMonth: Yup.string().nullable(),
    registrationDateYear: Yup.string().nullable(),
    lifetimeMileage: Yup.number()
      .min(0, formatMessage(Translations.AddEditListingsOnlyPositiveNumbersAllowed))
      .max(MAX_KM, formatMessage(Translations.AddEditListingsTooLong)),
    lastTestDate: Yup.string(),
    modelDesignationNumber: Yup.string().max(50, formatMessage(Translations.AddEditListingsTooLong)),
    bodyShape: Yup.string(),
    vin: Yup.string().max(50, formatMessage(Translations.AddEditListingsTooLong)),
    internalId: Yup.string().max(50, formatMessage(Translations.AddEditListingsTooLong)),
    guarantee: Yup.boolean(),
    guaranteeText: Yup.string().min(2, formatMessage(Translations.AddEditListingsTooShort)),
    price: Yup.number().min(1000, formatMessage(Translations.AddEditListingsOnlyPositiveNumbersAllowed)),
    newPrice: Yup.number()
      .min(1000, formatMessage(Translations.AddEditListingsOnlyPositiveNumbersAllowed))
      .when('isNewPriceRequired', {
        is: true,
        then: Yup.number().required(formatMessage(Translations.AddEditListingsRequired)),
      }),
    interestRate: Yup.number()
      .min(0, formatMessage(Translations.AddEditListingsTooShort))
      .max(100, formatMessage(Translations.AddEditListingsTooLong))
      .nullable(),
    leaseType: Yup.string(),
    monthlyManagementFee: Yup.number().min(0, formatMessage(Translations.AddEditListingsOnlyPositiveNumbersAllowed)),
    exteriorColor: Yup.string()
      .min(2, formatMessage(Translations.AddEditListingsTooShort))
      .max(50, formatMessage(Translations.AddEditListingsTooLong)),
    interiorColor: Yup.string()
      .min(2, formatMessage(Translations.AddEditListingsTooShort))
      .max(50, formatMessage(Translations.AddEditListingsTooLong)),
    seats: Yup.number().min(0, formatMessage(Translations.AddEditListingsOnlyPositiveNumbersAllowed)),
    doors: Yup.number().min(0, formatMessage(Translations.AddEditListingsOnlyPositiveNumbersAllowed)),
    cylinders: Yup.number().min(0, formatMessage(Translations.AddEditListingsOnlyPositiveNumbersAllowed)),
    horsepower: Yup.number().min(0, formatMessage(Translations.AddEditListingsOnlyPositiveNumbersAllowed)),
    engineCapacity: Yup.number().min(0, formatMessage(Translations.AddEditListingsOnlyPositiveNumbersAllowed)),
    batteryRange: Yup.number().min(0, formatMessage(Translations.AddEditListingsOnlyPositiveNumbersAllowed)),
    batteryCapacity: Yup.number().min(0, formatMessage(Translations.AddEditListingsOnlyPositiveNumbersAllowed)),
    consumptionPower: Yup.number().min(0, formatMessage(Translations.AddEditListingsOnlyPositiveNumbersAllowed)),
    co2emissions: Yup.number().min(0, formatMessage(Translations.AddEditListingsOnlyPositiveNumbersAllowed)),
    consumptionCity: Yup.number().min(0, formatMessage(Translations.AddEditListingsOnlyPositiveNumbersAllowed)),
    consumptionCountry: Yup.number().min(0, formatMessage(Translations.AddEditListingsOnlyPositiveNumbersAllowed)),
    consumptionTotal: Yup.number().min(0, formatMessage(Translations.AddEditListingsOnlyPositiveNumbersAllowed)),
    powerConsumption: Yup.number().min(0, formatMessage(Translations.AddEditListingsOnlyPositiveNumbersAllowed)),
    tare: Yup.number().min(0, formatMessage(Translations.AddEditListingsOnlyPositiveNumbersAllowed)),
    trailerLoad: Yup.number().min(0, formatMessage(Translations.AddEditListingsOnlyPositiveNumbersAllowed)),
    isOwnershipDefault: Yup.boolean(),
    isResidualValueDefault: Yup.boolean(),
    optionsExtended: Yup.array(Yup.string()),
    residualValues: Yup.array(
      Yup.object().shape({
        residualValue: Yup.number(),
        km: Yup.number(),
      })
    ),
    isLockedToSource: Yup.boolean(),
    condition: Yup.string().required(formatMessage(Translations.AddEditListingsRequired)),
    greyImport: Yup.boolean(),
  };

  return Yup.object().shape(shapeObj);
};
