import { MimeTypes } from 'components/Forms/FileUpload/FileUpload.utils';
import {
  AllFileTypes,
  CustomerFileType,
  CustomerFileTypeAdmin,
  FileType,
  OfferFileType,
  OfferFileTypeAdmin,
  PartnerSpouseDocumentFileTypeAdmin,
  UploadUpdateFileTypeAdmin,
} from 'types';

export const CUSTOMER_FILES_FILE_TYPES = [
  CustomerFileType.ID_DOCUMENT_FRONT,
  CustomerFileType.ID_DOCUMENT_BACK,
  CustomerFileType.RESIDENCE_PERMIT_FRONT,
  CustomerFileType.RESIDENCE_PERMIT_BACK,
  CustomerFileType.RESIDENCE_PERMIT_BOTH_SIDES,
  CustomerFileTypeAdmin.CERTIFIED_COPY_ID,
  CustomerFileTypeAdmin.CERTIFIED_COPY_PASSPORT,
  CustomerFileTypeAdmin.CERTIFIED_COPY_RESIDENCE_PERMIT,
  CustomerFileTypeAdmin.RESIDENCE_PERMIT_RENEWAL_CONFIRMATION,
  CustomerFileTypeAdmin.OTHER_ID_DOCS,
  CustomerFileType.SALARY_SLIPS_COLLECTION,
  CustomerFileType.PAY_SLIP_FIRST,
  CustomerFileType.PAY_SLIP_SECOND,
  CustomerFileType.PAY_SLIP_THIRD,
  CustomerFileType.PAY_SLIP_FOURTH,
  CustomerFileType.PAY_SLIP_FIFTH,
  CustomerFileType.PAY_SLIP_SIXTH,
  CustomerFileTypeAdmin.DEBT_ENFORCEMENT_EXTRACT,
  CustomerFileTypeAdmin.PASSPORT,
  CustomerFileTypeAdmin.WORKING_CONTRACT,
  CustomerFileTypeAdmin.INSURANCE_POLICY,
  CustomerFileTypeAdmin.EMPLOYER_LETTERS,
  CustomerFileTypeAdmin.TRAIL_PERIOD_CONFIRMATION,
  CustomerFileTypeAdmin.TEST_EMAIL_COMPANY_DOMAIN,
  CustomerFileTypeAdmin.EMPLOYMENT_CONFIRMATION,
  CustomerFileTypeAdmin.OTHER_EMPLOYMENT_DOCS,
  CustomerFileTypeAdmin.BANKING_STATEMENT,
  CustomerFileTypeAdmin.BANKING_STATEMENT_1,
  CustomerFileTypeAdmin.BANKING_STATEMENT_2,
  CustomerFileTypeAdmin.BANKING_STATEMENT_3,
  CustomerFileTypeAdmin.BANKING_STATEMENT_4,
  CustomerFileTypeAdmin.BANKING_STATEMENT_5,
  CustomerFileTypeAdmin.BANKING_STATEMENT_6,
  CustomerFileTypeAdmin.FITNESS_TO_WORK_CERTIFICATE,
  CustomerFileTypeAdmin.RENTAL_AGREEMENT,
  CustomerFileTypeAdmin.TAX_DECLARATION,
  CustomerFileTypeAdmin.OTHER_PROOF_OF_FINANCIAL_VIABILITY,
  CustomerFileTypeAdmin.THIRD_PARTY_CREDIT_AGREEMENT,
  CustomerFileTypeAdmin.THIRD_PARTY_LEASING_AGREEMENT,
  CustomerFileTypeAdmin.CRIF_REPORT,
  CustomerFileTypeAdmin.FLEET_DISCOUNT_CONFIRMATION,
  CustomerFileTypeAdmin.OTHER_1,
  CustomerFileTypeAdmin.OTHER_2,
  CustomerFileTypeAdmin.OTHER_3,
  CustomerFileType.ADDRESS_CHANGE_FORM,
  CustomerFileType.ADDITIONAL_INCOME_SLIP_1,
  CustomerFileType.ADDITIONAL_INCOME_SLIP_2,
  CustomerFileType.ADDITIONAL_INCOME_SLIP_3,
  CustomerFileType.ADDITIONAL_INCOME_SLIP_4,
  CustomerFileType.ADDITIONAL_INCOME_SLIP_5,
  CustomerFileType.ADDITIONAL_INCOME_SLIP_6,
];

export const PARTNER_SPOUSE_DOCUMENTS = [
  PartnerSpouseDocumentFileTypeAdmin.PARTNER_ID,
  PartnerSpouseDocumentFileTypeAdmin.PARTNER_PASSPORT,
  PartnerSpouseDocumentFileTypeAdmin.PARTNER_RESIDENCE_PERMIT,
];

export const TRADE_IN_DOCUMENTS_FILE_TYPES = [
  CustomerFileType.TRADE_IN_REGISTRATION,
  CustomerFileType.TRADE_IN_DAMAGE1,
  CustomerFileType.TRADE_IN_DAMAGE2,
  CustomerFileType.TRADE_IN_DAMAGE3,
  CustomerFileType.TRADE_IN_DAMAGE4,
  CustomerFileType.TRADE_IN_DAMAGE5,
  CustomerFileType.TRADE_IN_DAMAGE6,
  CustomerFileType.TRADE_IN_DAMAGE7,
  CustomerFileType.TRADE_IN_DAMAGE8,
  CustomerFileType.TRADE_IN_FRONT,
  CustomerFileType.TRADE_IN_REAR,
  CustomerFileType.TRADE_IN_DRIVER_SIDE,
  CustomerFileType.TRADE_IN_PASSENGER_SIDE,
  CustomerFileType.TRADE_IN_TYRES,
  CustomerFileType.TRADE_IN_FRONT_SEATS,
  CustomerFileType.TRADE_IN_DASHBOARD,
  CustomerFileTypeAdmin.PURCHASE_AGREEMENT,
  CustomerFileTypeAdmin.PURCHASE_AGREEMENT_CUSTOMER_GOWAGO,
  CustomerFileTypeAdmin.PURCHASE_AGREEMENT_RESELLER_GOWAGO,
  CustomerFileTypeAdmin.HANDOVER_PROTOCOL,
  CustomerFileTypeAdmin.TRADEIN_OTHER,
];

export const PRIVATE_CREDIT_FILES_FILE_TYPES = [
  OfferFileTypeAdmin.PRIVATE_CREDIT_CONTRACT,
  OfferFileTypeAdmin.PRIVATE_CREDIT_PRE_APPROVAL,
];

export const LEASING_OFFER_FILES_FILE_TYPES = [
  OfferFileType.LOG_TC_AND_ZEK,
  OfferFileTypeAdmin.LEASING_CONTRACT,
  OfferFileTypeAdmin.LEASING_CONTRACT_WITHOUT_KFP,
  ...PRIVATE_CREDIT_FILES_FILE_TYPES,
  OfferFileTypeAdmin.FORM_A,
  OfferFileTypeAdmin.FORM_K,
  OfferFileTypeAdmin.FORM_KYC,
  OfferFileTypeAdmin.POWER_OF_ATTORNEY,
  OfferFileTypeAdmin.BUSINESS_LEASING_ZESSION,
  OfferFileTypeAdmin.EQUIPMENT,
  OfferFileTypeAdmin.CARTE_GRIS,
  OfferFileTypeAdmin.PCAS_OPT_CONFORMATION,
  OfferFileTypeAdmin.KFP,
  OfferFileTypeAdmin.BUYING_CONTRACT_DEALER,
  OfferFileTypeAdmin.RESIDUAL_VALUE_OBLIGATION_MIGROS,
  OfferFileTypeAdmin.INVOICE_DEALER,
  OfferFileTypeAdmin.HANDOVER_PROTOCOL_MB,
  OfferFileTypeAdmin.ANZEIGE_ZESSION,
  OfferFileTypeAdmin.INVOICE_MIBA,
  OfferFileTypeAdmin.PURCHASE_AGREEMENT_MIBA,
];

export const AIO_OFFER_FILES_FILE_TYPES = [
  OfferFileTypeAdmin.AIO_CONTRACT,
  OfferFileTypeAdmin.AIO_CONTRACT_SIGNED,
  OfferFileTypeAdmin.HANDOVER_PROTOCOL_AIO,
  OfferFileTypeAdmin.MSS_OFFER,
  OfferFileTypeAdmin.MSS_OFFER_1,
  OfferFileTypeAdmin.MSS_OFFER_2,
  OfferFileTypeAdmin.MSS_OFFER_3,
  OfferFileTypeAdmin.AIO_CALCULATION,
  OfferFileTypeAdmin.TAX_YEAR_1,
  OfferFileTypeAdmin.TAX_YEAR_2,
  OfferFileTypeAdmin.TAX_YEAR_3,
  OfferFileTypeAdmin.TAX_YEAR_4,
  OfferFileTypeAdmin.TAX_YEAR_5,
  OfferFileTypeAdmin.CAR_REGISTRATION_INVOICE,
  OfferFileTypeAdmin.OTHER,
];

export const UPLOAD_TO_UPDATE_FILE_TYPES = [UploadUpdateFileTypeAdmin.KFP_RESULTS];

export const ALL_FILE_TYPES = [
  ...CUSTOMER_FILES_FILE_TYPES,
  ...PARTNER_SPOUSE_DOCUMENTS,
  ...TRADE_IN_DOCUMENTS_FILE_TYPES,
  ...LEASING_OFFER_FILES_FILE_TYPES,
  ...AIO_OFFER_FILES_FILE_TYPES,
  ...PRIVATE_CREDIT_FILES_FILE_TYPES,
];

export const isUploadUpdateFileType = (fileType: AllFileTypes) =>
  Object.values(UploadUpdateFileTypeAdmin).includes(fileType);

export const isFileTypeAdmin = (fileType: AllFileTypes) =>
  [
    ...Object.values(CustomerFileTypeAdmin),
    ...Object.values(PartnerSpouseDocumentFileTypeAdmin),
    ...Object.values(OfferFileTypeAdmin),
    ...Object.values(UploadUpdateFileTypeAdmin),
  ].includes(fileType);

export const getUploadUpdateFileTypeSlug = (fileType: AllFileTypes) => {
  if (isUploadUpdateFileType(fileType)) {
    switch (fileType) {
      case UploadUpdateFileTypeAdmin.KFP_RESULTS:
        return 'kfp';
    }
  }

  return undefined;
};

export const getAllowedMimeTypes = (fileType: FileType) => {
  switch (fileType) {
    case UploadUpdateFileTypeAdmin.KFP_RESULTS:
      return [MimeTypes.XML];
  }

  return undefined;
};

export const MOST_USED_FILE_TYPES = [
  CustomerFileType.ID_DOCUMENT_FRONT,
  CustomerFileType.ID_DOCUMENT_BACK,
  OfferFileType.LOG_TC_AND_ZEK,
  OfferFileTypeAdmin.LEASING_CONTRACT,
  OfferFileTypeAdmin.FORM_A,
  OfferFileTypeAdmin.CARTE_GRIS,
  OfferFileTypeAdmin.KFP,
  OfferFileTypeAdmin.INVOICE_DEALER,
  OfferFileTypeAdmin.INVOICE_MIBA,
  OfferFileTypeAdmin.PURCHASE_AGREEMENT_MIBA,
  OfferFileTypeAdmin.AIO_CONTRACT_SIGNED,
  UploadUpdateFileTypeAdmin.KFP_RESULTS,
];

export const getIsMostUsedDocument = (fileType: AllFileTypes) => MOST_USED_FILE_TYPES.includes(fileType);
