// ⚠️ DO NOT FORGET TO ADD A NEW MAKE TO HUBSPOT make
export enum Make {
  AIXAM = 'AIXAM',
  'ALFA ROMEO' = 'ALFA ROMEO',
  'AMERICAN MOTORS' = 'AMERICAN MOTORS',
  ARTEGA = 'ARTEGA',
  'ASTON MARTIN' = 'ASTON MARTIN',
  AUDI = 'AUDI',
  AUSTIN = 'AUSTIN',
  BENTLEY = 'BENTLEY',
  BERTONE = 'BERTONE',
  BMW = 'BMW',
  'BMW-ALPINA' = 'BMW-ALPINA',
  BUGATTI = 'BUGATTI',
  BUICK = 'BUICK',
  CADILLAC = 'CADILLAC',
  CATERHAM = 'CATERHAM',
  CHEVROLET = 'CHEVROLET',
  CHRYSLER = 'CHRYSLER',
  CITROEN = 'CITROEN',
  CUPRA = 'CUPRA',
  DACIA = 'DACIA',
  DAEWOO = 'DAEWOO',
  DAIHATSU = 'DAIHATSU',
  DAIMLER = 'DAIMLER',
  'DE TOMASO' = 'DE TOMASO',
  DODGE = 'DODGE',
  DONKERVOORT = 'DONKERVOORT',
  'DS AUTOMOBILES' = 'DS AUTOMOBILES',
  FERRARI = 'FERRARI',
  FIAT = 'FIAT',
  FISKER = 'FISKER',
  FORD = 'FORD',
  'FORD (USA)' = 'FORD (USA)',
  GENESIS = 'GENESIS',
  HONDA = 'HONDA',
  HS = 'HS',
  HUMMER = 'HUMMER',
  HYUNDAI = 'HYUNDAI',
  INFINITI = 'INFINITI',
  INNOCENTI = 'INNOCENTI',
  'ISUZU (GM)' = 'ISUZU (GM)',
  'ISUZU (J)' = 'ISUZU (J)',
  ISUZU = 'ISUZU',
  IVECO = 'IVECO',
  JAGUAR = 'JAGUAR',
  JEEP = 'JEEP',
  KIA = 'KIA',
  KTM = 'KTM',
  LADA = 'LADA',
  LAMBORGHINI = 'LAMBORGHINI',
  LANCIA = 'LANCIA',
  'LAND ROVER' = 'LAND ROVER',
  LEXUS = 'LEXUS',
  LIGIER = 'LIGIER',
  LOTUS = 'LOTUS',
  MASERATI = 'MASERATI',
  MAZDA = 'MAZDA',
  'MERCEDES-BENZ' = 'MERCEDES-BENZ',
  MG = 'MG',
  MICRO = 'MICRO',
  MINELLI = 'MINELLI',
  MINI = 'MINI',
  MITSUBISHI = 'MITSUBISHI',
  MORGAN = 'MORGAN',
  McLAREN = 'McLAREN',
  NISSAN = 'NISSAN',
  OLDSMOBILE = 'OLDSMOBILE',
  OPEL = 'OPEL',
  PEUGEOT = 'PEUGEOT',
  PONTIAC = 'PONTIAC',
  PORSCHE = 'PORSCHE',
  PUCH = 'PUCH',
  QVALE = 'QVALE',
  RELIANT = 'RELIANT',
  RENAULT = 'RENAULT',
  'ROLLS-ROYCE' = 'ROLLS-ROYCE',
  ROVER = 'ROVER',
  SAAB = 'SAAB',
  SEAT = 'SEAT',
  SECMA = 'SECMA',
  SKODA = 'SKODA',
  SMART = 'SMART',
  'SSANG YONG' = 'SSANG YONG',
  SUBARU = 'SUBARU',
  SUZUKI = 'SUZUKI',
  TALBOT = 'TALBOT',
  TATA = 'TATA',
  TAZZARI = 'TAZZARI',
  TESLA = 'TESLA',
  THINK = 'THINK',
  TOYOTA = 'TOYOTA',
  TVR = 'TVR',
  VENTURI = 'VENTURI',
  VOLVO = 'VOLVO',
  VW = 'VW',
  WIESMANN = 'WIESMANN',
  ZAGATO = 'ZAGATO',
  POLESTAR = 'POLESTAR',
}

export enum FuelType {
  PETROL = 'petrol',
  HYBRID = 'hybrid',
  DIESEL = 'diesel',
  ELECTRIC = 'electric',
  CNG = 'CNG',
  E85 = 'E85',
  LPG = 'LPG',
  H2 = 'H2',
}

export enum FuelTypesDetailed {
  PETROL = 'petrol', // Benzin
  DIESEL = 'diesel', // Diesel
  HYBRID = 'hybrid', // Hybrid
  HYBRID_PETROL = 'hybrid_petrol', // Elektrisch (Plug-in) / Benzin
  HYBRID_DIESEL = 'hybrid_diesel', // Elektrisch (Plug-in) / Diesel
  ELECTRIC = 'electric', // Elektrisch (Plug-in)
  CNG_PETROL = 'CNG_petrol', // Erdgas (CNG) / Benzin
  CNG = 'CNG', // Erdgas (CNG)
  E85 = 'E85', // Ethanol E85 / Benzin
  LPG = 'LPG', // Flüssiggas (LPG) / Benzin
  H2 = 'H2',
  FULL_HYBRID_DIESEL = 'full_hybrid_diesel', // Voll-Hybrid Diesel/Elektro
  FULL_HYBRID_PETROL = 'full_hybrid_petrol', // Voll-Hybrid Benzin/Elektro
  PLUG_IN_HYBRID_PETROL = 'plug_in_hybrid_petrol', // Plug-in-Hybrid Benzin/Elektro
  PLUG_IN_HYBRID_DIESEL = 'plug_in_hybrid_diesel', // Plug-in-Hybrid Diesel/Elektro'
  MILD_HYBRID_PETROL = 'mild_hybrid_petrol', // Mild-Hybrid Benzin/Elektro
  MILD_HYBRID_DIESEL = 'mild_hybrid_diesel', // Mild-Hybrid Diesel/Elektro
}

export enum Transmission {
  AUTOMATIC = 'automatic',
  MANUAL = 'manual',
}

export enum TransmissionDetailed {
  AUTOMATIC = 'automatic', // Automat
  AUTOMATIC_SEQUENTIAL = 'automatic_sequential', // Automat sequentiell
  AUTOMATIC_STEPLESS = 'automatic_stepless', // Automat stufenlos
  AUTOMATIC_STEPLESS_SEQUENTIAL = 'automatic_stepless_sequential', // Automat stufenlos, sequentiell
  AUTOMATIC_MANUAL = 'automatic_manual', // Automatisiertes Schaltgetriebe
  MANUAL = 'manual', // Schaltgetriebe
  MANUAL_SEQUENTIAL = 'manual_sequential', // Schaltgetriebe sequentiell
}

export enum DrivenWheels {
  REAR = 'rear',
  FRONT = 'front',
  '4X4' = '4x4',
}

export enum Segments {
  CONVERTIBLE = 'convertible',
  // HATCHBACK = 'hatchback',
  // COUPE = 'coupe',
  SUV = 'suv',
  WAGON = 'wagon',
  SEDAN = 'sedan',
  VAN = 'van',
  SMALL_CAR = 'smallCar',
  PICKUP = 'pickup',
  SPORTS_CAR = 'sportsCar',
}

export enum ConditionTypeDetailed {
  NEW = 'new',
  NEW_SINGLE_DAY_REGISTRATION = 'new_single_day_registration',
  USED = 'used',
  DISPLAY_MODEL = 'display_model',
  OLD_TIMER = 'old_timer',
}

export type ConditionType = ConditionTypeDetailed;

export enum ConsumptionRating {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
}

export enum PollutionNorm {
  EURO_1 = 'Euro 1',
  EURO_2 = 'Euro 2',
  EURO_3 = 'Euro 3',
  EURO_4 = 'Euro 4',
  EURO_5 = 'Euro 5',
  EURO_6 = 'Euro 6',
  EURO_6A = 'Euro 6a',
  EURO_6B = 'Euro 6b',
  EURO_6C = 'Euro 6c',
  EURO_6D = 'Euro 6d',
  EURO_6D_TEMP = 'Euro 6d-TEMP',
}

export enum CarUsage {
  FAMILY_CAR = 'familyCar',
  DRIVING_FOR_FUN = 'drivingForFun',
  BIG_BOOT = 'bigBoot',
  LONG_JOURNEY = 'longJourney',
  CITY_DRIVING = 'cityDriving',
  COMPANY_CAR = 'companyCar',
  '4X4' = '4x4',
  NEW_DRIVER = 'newDriver',
}

export enum OptionName {
  PARKING_360_CAM = 'parking360Cam',
  USB_PLUG = 'usbPlug', //
  BLUETOOTH = 'bluetooth',
  NAVIGATION = 'navigation',
  PARKING_ASSISTANT = 'parkingAssistant',
  PARKING_DISTANCE_REAR = 'parkingDistanceRear',
  LANE_ASSISTANT = 'laneAssistant',
  REAR_CAMERA = 'rearCamera',
  CHILD_SEAT_MOUNT = 'childSeatMount',
  DRIVER_HEATING_SEAT = 'driverHeatingSeat',
  DEAD_ANGLE_WARNING = 'deadAngleWarning',
  CRUISE_CONTROL = 'cruiseControl',
  FULL_LEATHER_SEATS = 'fullLeatherSeats',
  FAST_CHARGING = 'fastCharging',
  GLASS_ROOF = 'glassRoof',
}

export enum OptionExtra {
  EIGHT_TYRES = 'eight_tyres',
}

export enum OptionExtended {
  ALLOY_WHEELS = 'alloy_wheels',
  TRAILER_HITCH = 'trailer_hitch',
  BLUETOOTH_INTERFACE = 'bluetooth_interface',
  DAB_RADIO = 'dab_radio',
  DIFFERENTIAL_LOCK = 'differential_lock',
  DISTANCE_CONTROLLER = 'distance_controller',
  ELECTRIC_SEAT_ADJUSTMENT = 'electric_seat_adjustment',
  HANDSFREE = 'handsfree',
  HARDTOP = 'hardtop',
  ISOFIX = 'isofix',
  AIR_CONDITIONING = 'air_conditioning',
  AUTOMATIC_AIR_CONDITIONING = 'automatic_air_conditioning',
  MANUAL_AIR_CONDITIONING = 'manual_air_conditioning',
  BEND_LIGHTING = 'bend_lighting',
  AIR_SUSPENSION = 'air_suspension',
  NAVIGATION_SYSTEM = 'navigation_system',
  BUILT_IN_NAVIGATION_SYSTEM = 'built_in_navigation_system',
  PORTABLE_NAVIGATION_SYSTEM = 'portable_navigation_system',
  PANORAMA_ROOF = 'panorama_roof',
  PARKING_AID = 'parking_aid',
  PARKING_SENSORS_IN_THE_BACK = 'parking_sensors_in_the_back',
  PARKING_SENSORS_FRONT = 'parking_sensors_front',
  REAR_VIEW_CAMERA = 'rear_view_camera',
  HEADLIGHTS = 'headlights',
  LED_HEADLIGHTS = 'led_headlights',
  XENON_HEADLIGHTS = 'xenon_headlights',
  LASER_HEADLIGHTS = 'laser_headlights',
  SUNROOF = 'sunroof',
  KEYLESS_ACCESS_START = 'keyless_access_start',
  SEAT_VENTILATION = 'seat_ventilation',
  SEAT_COVERS = 'seat_covers',
  SEAT_COVER_ALCANTARA = 'seat_cover_alcantara',
  SEAT_COVER_LEATHER = 'seat_cover_leather',
  SEAT_COVER_FABRIC = 'seat_cover_fabric',
  SEAT_COVER_PARTIAL_LEATHER = 'seat_cover_partial_leather',
  SEAT_HEATING = 'seat_heating',
  SPORTS_SEATS = 'sports_seats',
  LANE_KEEPING_ASSISTANT = 'lane_keeping_assistant',
  STABILITY_CONTROL = 'stability_control',
  PARKING_HEATER = 'parking_heater',
  START_STOP_SYSTEM = 'start_stop_system',
  CRUISE_CONTROL = 'cruise_control',
  BLIND_SPOT_ASSISTANT = 'blind_spot_assistant',
  EIGHT_TIRES = 'eight_tires',
  ROOF_RACK = 'roof_rack',
  DOG_LATTICE = 'dog_lattice',
}

export interface OptionsDetailed {
  de: LanguageOptionDetailed;
  fr: LanguageOptionDetailed;
}

export interface LanguageOptionDetailed {
  standard: string[];
  optional: string[];
}
