import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from 'components/Card';
import { GOWAGO_CONTACT_PHONE, GOWAGO_OPEN_HOURS, SUPPORT_EMAIL_LINK } from 'constants/constants';
import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import styles from './ContactSupport.module.scss';
import Translations from './ContactSupport.translations';

const ContactSupport: FunctionComponent = () => {
  const { formatMessage } = useIntl();

  return (
    <div>
      <div className={styles.header}>
        <div className={styles.title}>{formatMessage(Translations.ContactSupportContactSupport)}</div>
      </div>
      <Card className={styles.card}>
        <div className={styles.row}>
          <div className={styles.iconContainer}>
            <FontAwesomeIcon icon={['fas', 'clock']} className={styles.icon} />
          </div>
          {formatMessage(Translations.ContactSupportOpenMondayToFriday)} {GOWAGO_OPEN_HOURS}
        </div>
        <div className={styles.row}>
          <div className={styles.iconContainer}>
            <FontAwesomeIcon icon={['fas', 'phone']} className={styles.icon} />
          </div>
          <a href={`tel:${GOWAGO_CONTACT_PHONE}`} className={styles.link}>
            {GOWAGO_CONTACT_PHONE}
          </a>
        </div>
        <div className={styles.row}>
          <div className={styles.iconContainer}>
            <FontAwesomeIcon icon={['fas', 'envelope']} className={styles.icon} />
          </div>
          <a href={`mailto:${SUPPORT_EMAIL_LINK}`} className={styles.link}>
            {SUPPORT_EMAIL_LINK}
          </a>
        </div>
      </Card>
    </div>
  );
};

export default ContactSupport;
