import ButtonBase from 'components/Forms/ButtonBase';
import TextField from 'components/Forms/TextField';
import LoadingIndicator from 'components/LoadingIndicator';
import { Formik, FormikHelpers } from 'formik';
import { useNavigate, useQuery } from 'hooks/router';
import { RootState } from 'pages/_store/root-reducer';
import { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import ContactSupportLink from '../SharedComponents/ContactSupportLink';
import PublicHeader from '../SharedComponents/PublicHeader';
import SuccessCard from '../SharedComponents/SuccessCard';
import styles from './RegisterPage.module.scss';
import Translations from './RegisterPage.translations';
import { Actions as RegistrationPageActions } from './store/actions';
import { RegisterPageState, SellerRegistrationData } from './store/initial-state';

type QueryProps = Partial<{
  token: string;
}>;

const RegisterPage: FC = () => {
  const { formatMessage } = useIntl();
  const { token } = useQuery<QueryProps>();
  const visit = useNavigate('/');
  const dispatch = useDispatch();
  const { sellerData, isTokenValidationFetching, isTokenInvalid, isSuccess, isSellerRegistrationFetching } =
    useSelector<RootState, RegisterPageState>((state: RootState) => state.registrationPage, shallowEqual);

  useEffect(() => {
    dispatch(RegistrationPageActions.validateTokenRequest(token || ''));
  }, [dispatch, token]);

  useEffect(() => {
    if (isSuccess) {
      visit('/');
    }
  }, [isSuccess, visit]);

  const SellerRegistrationFormSchema = Yup.object().shape({
    firstName: Yup.string().required(formatMessage(Translations.RegisterPageThisFieldIsRequired)),
    lastName: Yup.string().required(formatMessage(Translations.RegisterPageThisFieldIsRequired)),
    password: Yup.string().required(formatMessage(Translations.RegisterPageThisFieldIsRequired)),
    passwordRepeated: Yup.string().required(formatMessage(Translations.RegisterPageThisFieldIsRequired)),
  });

  if (isTokenValidationFetching) {
    //TODO: provide a loading indicator here
    return <span />;
  }

  const handleFormSubmition = async (
    values: SellerRegistrationData,
    formikHelpers: FormikHelpers<SellerRegistrationData>
  ) => {
    await dispatch(RegistrationPageActions.registerSellerRequest(values));
    formikHelpers.setSubmitting(false);
  };

  if (isTokenInvalid)
    return (
      <SuccessCard
        title={formatMessage(Translations.RegisterPageInvalidTokenTitle)}
        subTitle={formatMessage(Translations.RegisterPageInvalidTokenMessage)}
      />
    );

  return (
    <div className={styles.root}>
      <PublicHeader
        title={formatMessage(Translations.RegisterPageSignUp)}
        subTitle={formatMessage(Translations.RegisterPageSellYourCarsOnGowagoCh)}
      />
      <div className={styles.card}>
        <LoadingIndicator isLoading={isSellerRegistrationFetching}>
          <Formik
            initialValues={sellerData}
            validationSchema={SellerRegistrationFormSchema}
            onSubmit={handleFormSubmition}
            render={(props) => (
              <form onSubmit={props.handleSubmit}>
                <div className={styles.nameContainer}>
                  <div className={styles.nameRow}>
                    <TextField name="firstName" label={formatMessage(Translations.RegisterPageFirstName)} />
                  </div>
                  <div className={styles.nameRow}>
                    <TextField name="lastName" label={formatMessage(Translations.RegisterPageLastName)} />
                  </div>
                </div>
                <TextField
                  name="email"
                  type="email"
                  label={formatMessage(Translations.RegisterPageYourEmail)}
                  footerLabel={formatMessage(Translations.RegisterPageYouCanChangeThisLaterInYourProfileSettings)}
                  disabled
                />
                <TextField
                  name="vendorName"
                  type="text"
                  label={formatMessage(Translations.RegisterPageVendorCompany)}
                  disabled
                />
                <div className={styles.divider} />
                <TextField
                  name="password"
                  type="password"
                  label={formatMessage(Translations.RegisterPageNewPassword)}
                />
                <TextField
                  name="passwordRepeated"
                  type="password"
                  label={formatMessage(Translations.RegisterPageRepeatPassword)}
                />
                <span className={styles.tosMessage}>
                  {formatMessage(Translations.RegisterPageByClickingSignUpYouAgreeToOurTermsOfUseAndPrivacyPolicy, {
                    TosLink: (msg: string) => (
                      <a className={styles.tosLink} target="_blank" href="/">
                        {msg}
                      </a>
                    ),
                    PrivacyPolicyLink: (msg: string) => (
                      <a className={styles.tosLink} target="_blank" href="/">
                        {msg}
                      </a>
                    ),
                  })}
                </span>
                <div className={styles.submitButtonContainer}>
                  <ButtonBase
                    type="submit"
                    classes={{ root: styles.submitButton }}
                    disabled={isTokenValidationFetching || isSellerRegistrationFetching}
                    isLoading={isTokenValidationFetching || isSellerRegistrationFetching}
                  >
                    {formatMessage(Translations.RegisterPageSignUpSubmit)}
                  </ButtonBase>
                </div>
              </form>
            )}
          />
        </LoadingIndicator>
      </div>
      <ContactSupportLink label={formatMessage(Translations.RegisterPageNeedSomeHelp)} />
    </div>
  );
};

export default RegisterPage;
