import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { compose } from 'utils/compose';
import styles from './ButtonBase.module.scss';

interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  classes?: Partial<typeof styles>;
  isLoading?: boolean;
}

const Button: FunctionComponent<Props> = ({ children, classes = {}, disabled, isLoading, ...other }) => {
  return (
    <button
      disabled={isLoading}
      className={classNames(classes?.root, styles.root, {
        [styles.disabled]: disabled,
      })}
      {...other}
    >
      {isLoading && <FontAwesomeIcon icon={['fad', 'spinner-third']} spin={true} className={styles.icon} />}
      {children}
    </button>
  );
};

export default compose<Props>(Button);
