import { SupportedLanguages } from 'constants/constants';
import { Nationality } from 'types';

export const nationalityTranslations = {
  [SupportedLanguages.EN]: {
    [Nationality.AFGHANISTAN]: 'Afghanistan',
    [Nationality.ÅLAND_ISLANDS]: 'Åland Islands',
    [Nationality.ALBANIA]: 'Albania',
    [Nationality.ALGERIA]: 'Algeria',
    [Nationality.AMERICAN_SAMOA]: 'American Samoa',
    [Nationality.ANDORRA]: 'Andorra',
    [Nationality.ANGOLA]: 'Angola',
    [Nationality.ANGUILLA]: 'Anguilla',
    [Nationality.ANTARCTICA]: 'Antarctica',
    [Nationality.ANTIGUA_AND_BARBUDA]: 'Antigua and Barbuda',
    [Nationality.ARGENTINA]: 'Argentina',
    [Nationality.ARMENIA]: 'Armenia',
    [Nationality.ARUBA]: 'Aruba',
    [Nationality.AUSTRALIA]: 'Australia',
    [Nationality.AUSTRIA]: 'Austria',
    [Nationality.AZERBAIJAN]: 'Azerbaijan',
    [Nationality.BAHAMAS]: 'Bahamas',
    [Nationality.BAHRAIN]: 'Bahrain',
    [Nationality.BANGLADESH]: 'Bangladesh',
    [Nationality.BARBADOS]: 'Barbados',
    [Nationality.BELARUS]: 'Belarus',
    [Nationality.BELGIUM]: 'Belgium',
    [Nationality.BELIZE]: 'Belize',
    [Nationality.BENIN]: 'Benin',
    [Nationality.BERMUDA]: 'Bermuda',
    [Nationality.BHUTAN]: 'Bhutan',
    [Nationality.BOLIVIA]: 'Bolivia',
    [Nationality.BOSNIA_AND_HERZEGOVINA]: 'Bosnia and Herzegovina',
    [Nationality.BOTSWANA]: 'Botswana',
    [Nationality.BOUVET_ISLAND]: 'Bouvet Island',
    [Nationality.BRAZIL]: 'Brazil',
    [Nationality.BRITISH_INDIAN_OCEAN_TERRITORY]: 'British Indian Ocean Territory',
    [Nationality.BRITISH_VIRGIN_ISLANDS]: 'British Virgin Islands',
    [Nationality.BRUNEI]: 'Brunei',
    [Nationality.BULGARIA]: 'Bulgaria',
    [Nationality.BURKINA_FASO]: 'Burkina Faso',
    [Nationality.BURUNDI]: 'Burundi',
    [Nationality.CAMBODIA]: 'Cambodia',
    [Nationality.CAMEROON]: 'Cameroon',
    [Nationality.CANADA]: 'Canada',
    [Nationality.CAPE_VERDE]: 'Cape Verde',
    [Nationality.CARIBBEAN_NETHERLANDS]: 'Caribbean Netherlands',
    [Nationality.CAYMAN_ISLANDS]: 'Cayman Islands',
    [Nationality.CENTRAL_AFRICAN_REPUBLIC]: 'Central African Republic',
    [Nationality.CHAD]: 'Chad',
    [Nationality.CHILE]: 'Chile',
    [Nationality.CHINA]: 'China',
    [Nationality.CHRISTMAS_ISLAND]: 'Christmas Island',
    [Nationality.COCOS_KEELING_ISLANDS]: 'Cocos (Keeling) Islands',
    [Nationality.COLOMBIA]: 'Colombia',
    [Nationality.COMOROS]: 'Comoros',
    [Nationality.CONGO]: 'Congo',
    [Nationality.COOK_ISLANDS]: 'Cook Islands',
    [Nationality.COSTA_RICA]: 'Costa Rica',
    [Nationality.COTE_DIVOIRE]: "Cote d'Ivoire",
    [Nationality.CROATIA]: 'Croatia',
    [Nationality.CUBA]: 'Cuba',
    [Nationality.CURAÇAO]: 'Curaçao',
    [Nationality.CYPRUS]: 'Cyprus',
    [Nationality.CZECH_REPUBLIC]: 'Czech Republic',
    [Nationality.DEMOCRATIC_REPUBLIC_OF_THE_CONGO]: 'Democratic Republic of the Congo',
    [Nationality.DENMARK]: 'Denmark',
    [Nationality.DJIBOUTI]: 'Djibouti',
    [Nationality.DOMINICA]: 'Dominica',
    [Nationality.DOMINICAN_REPUBLIC]: 'Dominican Republic',
    [Nationality.EAST_TIMOR]: 'East Timor',
    [Nationality.ECUADOR]: 'Ecuador',
    [Nationality.EGYPT]: 'Egypt',
    [Nationality.EL_SALVADOR]: 'El Salvador',
    [Nationality.EQUATORIAL_GUINEA]: 'Equatorial Guinea',
    [Nationality.ERITREA]: 'Eritrea',
    [Nationality.ESTONIA]: 'Estonia',
    [Nationality.ETHIOPIA]: 'Ethiopia',
    [Nationality.FALKLAND_ISLANDS]: 'Falkland Islands',
    [Nationality.FAROE_ISLANDS]: 'Faroe Islands',
    [Nationality.FIJI]: 'Fiji',
    [Nationality.FINLAND]: 'Finland',
    [Nationality.FRANCE]: 'France',
    [Nationality.FRENCH_GUIANA]: 'French Guiana',
    [Nationality.FRENCH_POLYNESIA]: 'French Polynesia',
    [Nationality.FRENCH_SOUTHERN_AND_ANTARCTIC_LANDS]: 'French Southern and Antarctic Lands',
    [Nationality.GABON]: 'Gabon',
    [Nationality.GAMBIA]: 'Gambia',
    [Nationality.GEORGIA]: 'Georgia',
    [Nationality.GERMANY]: 'Germany',
    [Nationality.GHANA]: 'Ghana',
    [Nationality.GIBRALTAR]: 'Gibraltar',
    [Nationality.GREECE]: 'Greece',
    [Nationality.GREENLAND]: 'Greenland',
    [Nationality.GRENADA]: 'Grenada',
    [Nationality.GUADELOUPE]: 'Guadeloupe',
    [Nationality.GUAM]: 'Guam',
    [Nationality.GUATEMALA]: 'Guatemala',
    [Nationality.GUERNSEY]: 'Guernsey',
    [Nationality.GUINEA]: 'Guinea',
    [Nationality.GUINEA_BISSAU]: 'Guinea-Bissau',
    [Nationality.GUYANA]: 'Guyana',
    [Nationality.HAITI]: 'Haiti',
    [Nationality.HEARD_ISLAND_AND_MCDONALD_ISLANDS]: 'Heard Island and McDonald Islands',
    [Nationality.HONDURAS]: 'Honduras',
    [Nationality.HONG_KONG]: 'Hong Kong',
    [Nationality.HUNGARY]: 'Hungary',
    [Nationality.ICELAND]: 'Iceland',
    [Nationality.INDIA]: 'India',
    [Nationality.INDONESIA]: 'Indonesia',
    [Nationality.IRAN]: 'Iran',
    [Nationality.IRAQ]: 'Iraq',
    [Nationality.IRELAND]: 'Ireland',
    [Nationality.ISLE_OF_MAN]: 'Isle of Man',
    [Nationality.ISRAEL]: 'Israel',
    [Nationality.ITALY]: 'Italy',
    [Nationality.JAMAICA]: 'Jamaica',
    [Nationality.JAPAN]: 'Japan',
    [Nationality.JERSEY]: 'Jersey',
    [Nationality.JORDAN]: 'Jordan',
    [Nationality.KAZAKHSTAN]: 'Kazakhstan',
    [Nationality.KENYA]: 'Kenya',
    [Nationality.KIRIBATI]: 'Kiribati',
    [Nationality.KUWAIT]: 'Kuwait',
    [Nationality.KYRGYZSTAN]: 'Kyrgyzstan',
    [Nationality.LAOS]: 'Laos',
    [Nationality.LATVIA]: 'Latvia',
    [Nationality.LEBANON]: 'Lebanon',
    [Nationality.LESOTHO]: 'Lesotho',
    [Nationality.LIBERIA]: 'Liberia',
    [Nationality.LIBYA]: 'Libya',
    [Nationality.LIECHTENSTEIN]: 'Liechtenstein',
    [Nationality.LITHUANIA]: 'Lithuania',
    [Nationality.LUXEMBOURG]: 'Luxembourg',
    [Nationality.MACAU]: 'Macau',
    [Nationality.MACEDONIA_FYROM]: 'Macedonia (FYROM)',
    [Nationality.MADAGASCAR]: 'Madagascar',
    [Nationality.MALAWI]: 'Malawi',
    [Nationality.MALAYSIA]: 'Malaysia',
    [Nationality.MALDIVES]: 'Maldives',
    [Nationality.MALI]: 'Mali',
    [Nationality.MALTA]: 'Malta',
    [Nationality.MARSHALL_ISLANDS]: 'Marshall Islands',
    [Nationality.MARTINIQUE]: 'Martinique',
    [Nationality.MAURITANIA]: 'Mauritania',
    [Nationality.MAURITIUS]: 'Mauritius',
    [Nationality.MAYOTTE]: 'Mayotte',
    [Nationality.MEXICO]: 'Mexico',
    [Nationality.MICRONESIA]: 'Micronesia',
    [Nationality.MOLDOVA]: 'Moldova',
    [Nationality.MONACO]: 'Monaco',
    [Nationality.MONGOLIA]: 'Mongolia',
    [Nationality.MONTENEGRO]: 'Montenegro',
    [Nationality.MONTSERRAT]: 'Montserrat',
    [Nationality.MOROCCO]: 'Morocco',
    [Nationality.MOZAMBIQUE]: 'Mozambique',
    [Nationality.MYANMAR_BURMA]: 'Myanmar (Burma)',
    [Nationality.NAMIBIA]: 'Namibia',
    [Nationality.NAURU]: 'Nauru',
    [Nationality.NEPAL]: 'Nepal',
    [Nationality.NETHERLANDS]: 'Netherlands',
    [Nationality.NETHERLANDS_ANTILLES]: 'Netherlands Antilles',
    [Nationality.NEW_CALEDONIA]: 'New Caledonia',
    [Nationality.NEW_ZEALAND]: 'New Zealand',
    [Nationality.NICARAGUA]: 'Nicaragua',
    [Nationality.NIGER]: 'Niger',
    [Nationality.NIGERIA]: 'Nigeria',
    [Nationality.NIUE]: 'Niue',
    [Nationality.NORFOLK_ISLAND]: 'Norfolk Island',
    [Nationality.NORTH_KOREA]: 'North Korea',
    [Nationality.NORTHERN_MARIANA_ISLANDS]: 'Northern Mariana Islands',
    [Nationality.NORWAY]: 'Norway',
    [Nationality.OMAN]: 'Oman',
    [Nationality.PAKISTAN]: 'Pakistan',
    [Nationality.PALAU]: 'Palau',
    [Nationality.PALESTINE]: 'Palestine',
    [Nationality.PANAMA]: 'Panama',
    [Nationality.PAPUA_NEW_GUINEA]: 'Papua New Guinea',
    [Nationality.PARAGUAY]: 'Paraguay',
    [Nationality.PERU]: 'Peru',
    [Nationality.PHILIPPINES]: 'Philippines',
    [Nationality.PITCAIRN_ISLANDS]: 'Pitcairn Islands',
    [Nationality.POLAND]: 'Poland',
    [Nationality.PORTUGAL]: 'Portugal',
    [Nationality.PUERTO_RICO]: 'Puerto Rico',
    [Nationality.QATAR]: 'Qatar',
    [Nationality.RÉUNION]: 'Réunion',
    [Nationality.ROMANIA]: 'Romania',
    [Nationality.RUSSIA]: 'Russia',
    [Nationality.RWANDA]: 'Rwanda',
    [Nationality.SAINT_BARTHÉLEMY]: 'Saint Barthélemy',
    [Nationality.SAINT_HELENA]: 'Saint Helena',
    [Nationality.SAINT_KITTS_AND_NEVIS]: 'Saint Kitts and Nevis',
    [Nationality.SAINT_LUCIA]: 'Saint Lucia',
    [Nationality.SAINT_MARTIN]: 'Saint Martin',
    [Nationality.SAINT_PIERRE_AND_MIQUELON]: 'Saint Pierre and Miquelon',
    [Nationality.SAINT_VINCENT_AND_THE_GRENADINES]: 'Saint Vincent and the Grenadines',
    [Nationality.SAMOA]: 'Samoa',
    [Nationality.SAN_MARINO]: 'San Marino',
    [Nationality.SAO_TOME_AND_PRINCIPE]: 'Sao Tome and Principe',
    [Nationality.SAUDI_ARABIA]: 'Saudi Arabia',
    [Nationality.SENEGAL]: 'Senegal',
    [Nationality.SERBIA]: 'Serbia',
    [Nationality.SEYCHELLES]: 'Seychelles',
    [Nationality.SIERRA_LEONE]: 'Sierra Leone',
    [Nationality.SINGAPORE]: 'Singapore',
    [Nationality.SINT_MAARTEN]: 'Sint Maarten',
    [Nationality.SLOVAKIA]: 'Slovakia',
    [Nationality.SLOVENIA]: 'Slovenia',
    [Nationality.SOLOMON_ISLANDS]: 'Solomon Islands',
    [Nationality.SOMALIA]: 'Somalia',
    [Nationality.SOUTH_AFRICA]: 'South Africa',
    [Nationality.SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS]: 'South Georgia and the South Sandwich Islands',
    [Nationality.SOUTH_KOREA]: 'South Korea',
    [Nationality.SOUTH_SUDAN]: 'South Sudan',
    [Nationality.SPAIN]: 'Spain',
    [Nationality.SRI_LANKA]: 'Sri Lanka',
    [Nationality.SUDAN]: 'Sudan',
    [Nationality.SURINAME]: 'Suriname',
    [Nationality.SVALBARD_AND_JAN_MAYEN]: 'Svalbard and Jan Mayen',
    [Nationality.SWAZILAND]: 'Swaziland',
    [Nationality.SWEDEN]: 'Sweden',
    [Nationality.SWITZERLAND]: 'Switzerland',
    [Nationality.SYRIA]: 'Syria',
    [Nationality.TAIWAN]: 'Taiwan',
    [Nationality.TAJIKISTAN]: 'Tajikistan',
    [Nationality.TANZANIA]: 'Tanzania',
    [Nationality.THAILAND]: 'Thailand',
    [Nationality.TOGO]: 'Togo',
    [Nationality.TOKELAU]: 'Tokelau',
    [Nationality.TONGA]: 'Tonga',
    [Nationality.TRINIDAD_AND_TOBAGO]: 'Trinidad and Tobago',
    [Nationality.TUNISIA]: 'Tunisia',
    [Nationality.TURKEY]: 'Turkey',
    [Nationality.TURKMENISTAN]: 'Turkmenistan',
    [Nationality.TURKS_AND_CAICOS_ISLANDS]: 'Turks and Caicos Islands',
    [Nationality.TUVALU]: 'Tuvalu',
    [Nationality.U_S_VIRGIN_ISLANDS]: 'U.S. Virgin Islands',
    [Nationality.UGANDA]: 'Uganda',
    [Nationality.UKRAINE]: 'Ukraine',
    [Nationality.UNITED_ARAB_EMIRATES]: 'United Arab Emirates',
    [Nationality.UNITED_KINGDOM]: 'United Kingdom',
    [Nationality.UNITED_STATES]: 'United States',
    [Nationality.UNITED_STATES_MINOR_OUTLYING_ISLANDS]: 'United States Minor Outlying Islands',
    [Nationality.URUGUAY]: 'Uruguay',
    [Nationality.UZBEKISTAN]: 'Uzbekistan',
    [Nationality.VANUATU]: 'Vanuatu',
    [Nationality.VATICAN_CITY]: 'Vatican City',
    [Nationality.VENEZUELA]: 'Venezuela',
    [Nationality.VIETNAM]: 'Vietnam',
    [Nationality.WALLIS_AND_FUTUNA]: 'Wallis and Futuna',
    [Nationality.WESTERN_SAHARA]: 'Western Sahara',
    [Nationality.YEMEN]: 'Yemen',
    [Nationality.ZAMBIA]: 'Zambia',
    [Nationality.ZIMBABWE]: 'Zimbabwe',
  },
  [SupportedLanguages.DE]: {
    [Nationality.AFGHANISTAN]: 'Afghanistan',
    [Nationality.ÅLAND_ISLANDS]: 'Åland-Inseln',
    [Nationality.ALBANIA]: 'Albanien',
    [Nationality.ALGERIA]: 'Algerien',
    [Nationality.AMERICAN_SAMOA]: 'Amerikanisch-Samoa',
    [Nationality.ANDORRA]: 'Andorra',
    [Nationality.ANGOLA]: 'Angola',
    [Nationality.ANGUILLA]: 'Anguilla',
    [Nationality.ANTARCTICA]: 'Antarktika',
    [Nationality.ANTIGUA_AND_BARBUDA]: 'Antigua und Barbuda',
    [Nationality.ARGENTINA]: 'Argentinien',
    [Nationality.ARMENIA]: 'Armenien',
    [Nationality.ARUBA]: 'Aruba',
    [Nationality.AUSTRALIA]: 'Australien',
    [Nationality.AUSTRIA]: 'Österreich',
    [Nationality.AZERBAIJAN]: 'Aserbaidschan',
    [Nationality.BAHAMAS]: 'Bahamas',
    [Nationality.BAHRAIN]: 'Bahrain',
    [Nationality.BANGLADESH]: 'Bangladesch',
    [Nationality.BARBADOS]: 'Barbados',
    [Nationality.BELARUS]: 'Weissrussland',
    [Nationality.BELGIUM]: 'Belgien',
    [Nationality.BELIZE]: 'Belize',
    [Nationality.BENIN]: 'Benin',
    [Nationality.BERMUDA]: 'Bermuda',
    [Nationality.BHUTAN]: 'Bhutan',
    [Nationality.BOLIVIA]: 'Bolivien',
    [Nationality.BOSNIA_AND_HERZEGOVINA]: 'Bosnien und Herzegowina',
    [Nationality.BOTSWANA]: 'Botswana',
    [Nationality.BOUVET_ISLAND]: 'Bouvetinsel',
    [Nationality.BRAZIL]: 'Brasilien',
    [Nationality.BRITISH_INDIAN_OCEAN_TERRITORY]: 'Britisches Territorium im Indischen Ozean',
    [Nationality.BRITISH_VIRGIN_ISLANDS]: 'Britische Jungferninseln',
    [Nationality.BRUNEI]: 'Brunei',
    [Nationality.BULGARIA]: 'Bulgarien',
    [Nationality.BURKINA_FASO]: 'Burkina Faso',
    [Nationality.BURUNDI]: 'Burundi',
    [Nationality.CAMBODIA]: 'Kambodscha',
    [Nationality.CAMEROON]: 'Kamerun',
    [Nationality.CANADA]: 'Kanada',
    [Nationality.CAPE_VERDE]: 'Kap Verde',
    [Nationality.CARIBBEAN_NETHERLANDS]: 'Karibische Niederlande',
    [Nationality.CAYMAN_ISLANDS]: 'Kaimaninseln',
    [Nationality.CENTRAL_AFRICAN_REPUBLIC]: 'Zentralafrikanische Republik',
    [Nationality.CHAD]: 'Tschad',
    [Nationality.CHILE]: 'Chile',
    [Nationality.CHINA]: 'China',
    [Nationality.CHRISTMAS_ISLAND]: 'Weihnachtsinsel',
    [Nationality.COCOS_KEELING_ISLANDS]: 'Kokosinseln/Keelinginseln',
    [Nationality.COLOMBIA]: 'Kolumbien',
    [Nationality.COMOROS]: 'Komoren',
    [Nationality.CONGO]: 'Kongo',
    [Nationality.COOK_ISLANDS]: 'Cookinseln',
    [Nationality.COSTA_RICA]: 'Costa Rica',
    [Nationality.COTE_DIVOIRE]: 'Elfenbeinküste',
    [Nationality.CROATIA]: 'Kroatien',
    [Nationality.CUBA]: 'Kuba',
    [Nationality.CURAÇAO]: 'Curaçao',
    [Nationality.CYPRUS]: 'Zypern',
    [Nationality.CZECH_REPUBLIC]: 'Tschechische Republik',
    [Nationality.DEMOCRATIC_REPUBLIC_OF_THE_CONGO]: 'Demokratische Republik Kongo',
    [Nationality.DENMARK]: 'Dänemark',
    [Nationality.DJIBOUTI]: 'Dschibuti',
    [Nationality.DOMINICA]: 'Dominica',
    [Nationality.DOMINICAN_REPUBLIC]: 'Dominikanische Republik',
    [Nationality.EAST_TIMOR]: 'Osttimor',
    [Nationality.ECUADOR]: 'Ecuador',
    [Nationality.EGYPT]: 'Ägypten',
    [Nationality.EL_SALVADOR]: 'El Salvador',
    [Nationality.EQUATORIAL_GUINEA]: 'Äquatorialguinea',
    [Nationality.ERITREA]: 'Eritrea',
    [Nationality.ESTONIA]: 'Estland',
    [Nationality.ETHIOPIA]: 'Äthiopien',
    [Nationality.FALKLAND_ISLANDS]: 'Falklandinseln',
    [Nationality.FAROE_ISLANDS]: 'Färöer-Inseln',
    [Nationality.FIJI]: 'Fidschi',
    [Nationality.FINLAND]: 'Finnland',
    [Nationality.FRANCE]: 'Frankreich',
    [Nationality.FRENCH_GUIANA]: 'Französisch-Guayana',
    [Nationality.FRENCH_POLYNESIA]: 'Französisch-Polynesien',
    [Nationality.FRENCH_SOUTHERN_AND_ANTARCTIC_LANDS]: 'Französische Süd- und Antarktisgebiete',
    [Nationality.GABON]: 'Gabun',
    [Nationality.GAMBIA]: 'Gambia',
    [Nationality.GEORGIA]: 'Georgien',
    [Nationality.GERMANY]: 'Deutschland',
    [Nationality.GHANA]: 'Ghana',
    [Nationality.GIBRALTAR]: 'Gibraltar',
    [Nationality.GREECE]: 'Griechenland',
    [Nationality.GREENLAND]: 'Grönland',
    [Nationality.GRENADA]: 'Grenada',
    [Nationality.GUADELOUPE]: 'Guadeloupe',
    [Nationality.GUAM]: 'Guam',
    [Nationality.GUATEMALA]: 'Guatemala',
    [Nationality.GUERNSEY]: 'Guernsey',
    [Nationality.GUINEA]: 'Guinea',
    [Nationality.GUINEA_BISSAU]: 'Guinea-Bissau',
    [Nationality.GUYANA]: 'Guyana',
    [Nationality.HAITI]: 'Haiti',
    [Nationality.HEARD_ISLAND_AND_MCDONALD_ISLANDS]: 'Heard Island und McDonald Islands',
    [Nationality.HONDURAS]: 'Honduras',
    [Nationality.HONG_KONG]: 'Hongkong',
    [Nationality.HUNGARY]: 'Ungarn',
    [Nationality.ICELAND]: 'Island',
    [Nationality.INDIA]: 'Indien',
    [Nationality.INDONESIA]: 'Indonesien',
    [Nationality.IRAN]: 'Iran',
    [Nationality.IRAQ]: 'Irak',
    [Nationality.IRELAND]: 'Irland',
    [Nationality.ISLE_OF_MAN]: 'Isle of Man',
    [Nationality.ISRAEL]: 'Israel',
    [Nationality.ITALY]: 'Italien',
    [Nationality.JAMAICA]: 'Jamaika',
    [Nationality.JAPAN]: 'Japan',
    [Nationality.JERSEY]: 'Jersey',
    [Nationality.JORDAN]: 'Jordanien',
    [Nationality.KAZAKHSTAN]: 'Kasachstan',
    [Nationality.KENYA]: 'Kenia',
    [Nationality.KIRIBATI]: 'Kiribati',
    [Nationality.KUWAIT]: 'Kuwait',
    [Nationality.KYRGYZSTAN]: 'Kirgisistan',
    [Nationality.LAOS]: 'Laos',
    [Nationality.LATVIA]: 'Lettland',
    [Nationality.LEBANON]: 'Libanon',
    [Nationality.LESOTHO]: 'Lesotho',
    [Nationality.LIBERIA]: 'Liberia',
    [Nationality.LIBYA]: 'Libyen',
    [Nationality.LIECHTENSTEIN]: 'Liechtenstein',
    [Nationality.LITHUANIA]: 'Litauen',
    [Nationality.LUXEMBOURG]: 'Luxemburg',
    [Nationality.MACAU]: 'Macau',
    [Nationality.MACEDONIA_FYROM]: 'Mazedonien (FYROM)',
    [Nationality.MADAGASCAR]: 'Madagaskar',
    [Nationality.MALAWI]: 'Malawi',
    [Nationality.MALAYSIA]: 'Malaysia',
    [Nationality.MALDIVES]: 'Malediven',
    [Nationality.MALI]: 'Mali',
    [Nationality.MALTA]: 'Malta',
    [Nationality.MARSHALL_ISLANDS]: 'Marshallinseln',
    [Nationality.MARTINIQUE]: 'Martinique',
    [Nationality.MAURITANIA]: 'Mauretanien',
    [Nationality.MAURITIUS]: 'Mauritius',
    [Nationality.MAYOTTE]: 'Mayotte',
    [Nationality.MEXICO]: 'Mexiko',
    [Nationality.MICRONESIA]: 'Mikronesien',
    [Nationality.MOLDOVA]: 'Moldawien',
    [Nationality.MONACO]: 'Monaco',
    [Nationality.MONGOLIA]: 'Mongolei',
    [Nationality.MONTENEGRO]: 'Montenegro',
    [Nationality.MONTSERRAT]: 'Montserrat',
    [Nationality.MOROCCO]: 'Marokko',
    [Nationality.MOZAMBIQUE]: 'Mosambik',
    [Nationality.MYANMAR_BURMA]: 'Myanmar (Burma)',
    [Nationality.NAMIBIA]: 'Namibia',
    [Nationality.NAURU]: 'Nauru',
    [Nationality.NEPAL]: 'Nepal',
    [Nationality.NETHERLANDS]: 'Niederlande',
    [Nationality.NETHERLANDS_ANTILLES]: 'Niederländische Antillen',
    [Nationality.NEW_CALEDONIA]: 'Neukaledonien',
    [Nationality.NEW_ZEALAND]: 'Neuseeland',
    [Nationality.NICARAGUA]: 'Nicaragua',
    [Nationality.NIGER]: 'Niger',
    [Nationality.NIGERIA]: 'Nigeria',
    [Nationality.NIUE]: 'Niue',
    [Nationality.NORFOLK_ISLAND]: 'Norfolkinsel',
    [Nationality.NORTH_KOREA]: 'Nordkorea',
    [Nationality.NORTHERN_MARIANA_ISLANDS]: 'Nördliche Marianen',
    [Nationality.NORWAY]: 'Norwegen',
    [Nationality.OMAN]: 'Oman',
    [Nationality.PAKISTAN]: 'Pakistan',
    [Nationality.PALAU]: 'Palau',
    [Nationality.PALESTINE]: 'Palästina',
    [Nationality.PANAMA]: 'Panama',
    [Nationality.PAPUA_NEW_GUINEA]: 'Papua-Neuguinea',
    [Nationality.PARAGUAY]: 'Paraguay',
    [Nationality.PERU]: 'Peru',
    [Nationality.PHILIPPINES]: 'Philippinen',
    [Nationality.PITCAIRN_ISLANDS]: 'Pitcairn Inseln',
    [Nationality.POLAND]: 'Polen',
    [Nationality.PORTUGAL]: 'Portugal',
    [Nationality.PUERTO_RICO]: 'Puerto Rico',
    [Nationality.QATAR]: 'Katar',
    [Nationality.RÉUNION]: 'Réunion',
    [Nationality.ROMANIA]: 'Rumänien',
    [Nationality.RUSSIA]: 'Russland',
    [Nationality.RWANDA]: 'Ruanda',
    [Nationality.SAINT_BARTHÉLEMY]: 'St. Barthélemy',
    [Nationality.SAINT_HELENA]: 'St. Helena',
    [Nationality.SAINT_KITTS_AND_NEVIS]: 'St. Kitts und Nevis',
    [Nationality.SAINT_LUCIA]: 'St. Lucia',
    [Nationality.SAINT_MARTIN]: 'St. Martin',
    [Nationality.SAINT_PIERRE_AND_MIQUELON]: 'St. Pierre und Miquelon',
    [Nationality.SAINT_VINCENT_AND_THE_GRENADINES]: 'St. Vincent und die Grenadinen',
    [Nationality.SAMOA]: 'Samoa',
    [Nationality.SAN_MARINO]: 'San Marino',
    [Nationality.SAO_TOME_AND_PRINCIPE]: 'Sao Tome und Principe',
    [Nationality.SAUDI_ARABIA]: 'Saudi-Arabien',
    [Nationality.SENEGAL]: 'Senegal',
    [Nationality.SERBIA]: 'Serbien',
    [Nationality.SEYCHELLES]: 'Seychellen',
    [Nationality.SIERRA_LEONE]: 'Sierra Leone',
    [Nationality.SINGAPORE]: 'Singapur',
    [Nationality.SINT_MAARTEN]: 'Sint Maarten',
    [Nationality.SLOVAKIA]: 'Slowakei',
    [Nationality.SLOVENIA]: 'Slowenien',
    [Nationality.SOLOMON_ISLANDS]: 'Salomonen',
    [Nationality.SOMALIA]: 'Somalia',
    [Nationality.SOUTH_AFRICA]: 'Südafrika',
    [Nationality.SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS]: 'Südgeorgien und die Südlichen Sandwichinseln',
    [Nationality.SOUTH_KOREA]: 'Südkorea',
    [Nationality.SOUTH_SUDAN]: 'Südsudan',
    [Nationality.SPAIN]: 'Spanien',
    [Nationality.SRI_LANKA]: 'Sri Lanka',
    [Nationality.SUDAN]: 'Sudan',
    [Nationality.SURINAME]: 'Surinam',
    [Nationality.SVALBARD_AND_JAN_MAYEN]: 'Svalbard und Jan Mayen',
    [Nationality.SWAZILAND]: 'Swasiland',
    [Nationality.SWEDEN]: 'Schweden',
    [Nationality.SWITZERLAND]: 'Schweiz',
    [Nationality.SYRIA]: 'Syrien',
    [Nationality.TAIWAN]: 'Taiwan',
    [Nationality.TAJIKISTAN]: 'Tadschikistan',
    [Nationality.TANZANIA]: 'Tansania',
    [Nationality.THAILAND]: 'Thailand',
    [Nationality.TOGO]: 'Togo',
    [Nationality.TOKELAU]: 'Tokelau',
    [Nationality.TONGA]: 'Tonga',
    [Nationality.TRINIDAD_AND_TOBAGO]: 'Trinidad und Tobago',
    [Nationality.TUNISIA]: 'Tunesien',
    [Nationality.TURKEY]: 'Türkei',
    [Nationality.TURKMENISTAN]: 'Turkmenistan',
    [Nationality.TURKS_AND_CAICOS_ISLANDS]: 'Turks- und Caicosinseln',
    [Nationality.TUVALU]: 'Tuvalu',
    [Nationality.U_S_VIRGIN_ISLANDS]: 'Amerikanische Jungferninseln',
    [Nationality.UGANDA]: 'Uganda',
    [Nationality.UKRAINE]: 'Ukraine',
    [Nationality.UNITED_ARAB_EMIRATES]: 'Vereinigte Arabische Emirate',
    [Nationality.UNITED_KINGDOM]: 'Vereinigtes Königreich',
    [Nationality.UNITED_STATES]: 'Vereinigte Staaten von Amerika',
    [Nationality.UNITED_STATES_MINOR_OUTLYING_ISLANDS]: 'United States Minor Outlying Islands',
    [Nationality.URUGUAY]: 'Uruguay',
    [Nationality.UZBEKISTAN]: 'Usbekistan',
    [Nationality.VANUATU]: 'Vanuatu',
    [Nationality.VATICAN_CITY]: 'Vatikanstadt',
    [Nationality.VENEZUELA]: 'Venezuela',
    [Nationality.VIETNAM]: 'Vietnam',
    [Nationality.WALLIS_AND_FUTUNA]: 'Wallis und Futuna',
    [Nationality.WESTERN_SAHARA]: 'Westsahara',
    [Nationality.YEMEN]: 'Jemen',
    [Nationality.ZAMBIA]: 'Sambia',
    [Nationality.ZIMBABWE]: 'Zimbabwe',
  },
  [SupportedLanguages.FR]: {
    [Nationality.AFGHANISTAN]: 'Afghanistan',
    [Nationality.ÅLAND_ISLANDS]: 'Îles Åland',
    [Nationality.ALBANIA]: 'Albanie',
    [Nationality.ALGERIA]: 'Algérie',
    [Nationality.AMERICAN_SAMOA]: 'Samoa américaines',
    [Nationality.ANDORRA]: 'Andorre',
    [Nationality.ANGOLA]: 'Angola',
    [Nationality.ANGUILLA]: 'Anguilla',
    [Nationality.ANTARCTICA]: 'Antarctique',
    [Nationality.ANTIGUA_AND_BARBUDA]: 'Antigua-et-Barbuda',
    [Nationality.ARGENTINA]: 'Argentine',
    [Nationality.ARMENIA]: 'Arménie',
    [Nationality.ARUBA]: 'Aruba',
    [Nationality.AUSTRALIA]: 'Australie',
    [Nationality.AUSTRIA]: 'Autriche',
    [Nationality.AZERBAIJAN]: 'Azerbaïdjan',
    [Nationality.BAHAMAS]: 'Bahamas',
    [Nationality.BAHRAIN]: 'Bahreïn',
    [Nationality.BANGLADESH]: 'Bangladesh',
    [Nationality.BARBADOS]: 'Barbade',
    [Nationality.BELARUS]: 'Bélarus',
    [Nationality.BELGIUM]: 'Belgique',
    [Nationality.BELIZE]: 'Belize',
    [Nationality.BENIN]: 'Bénin',
    [Nationality.BERMUDA]: 'Bermudes',
    [Nationality.BHUTAN]: 'Bhoutan',
    [Nationality.BOLIVIA]: 'Bolivie',
    [Nationality.BOSNIA_AND_HERZEGOVINA]: 'Bosnie-Herzégovine',
    [Nationality.BOTSWANA]: 'Botswana',
    [Nationality.BOUVET_ISLAND]: 'Île Bouvet',
    [Nationality.BRAZIL]: 'Brésil',
    [Nationality.BRITISH_INDIAN_OCEAN_TERRITORY]: "Territoire britannique de l'océan Indien",
    [Nationality.BRITISH_VIRGIN_ISLANDS]: 'îles Vierges britanniques',
    [Nationality.BRUNEI]: 'Brunei',
    [Nationality.BULGARIA]: 'Bulgarie',
    [Nationality.BURKINA_FASO]: 'Burkina Faso',
    [Nationality.BURUNDI]: 'Burundi',
    [Nationality.CAMBODIA]: 'Cambodge',
    [Nationality.CAMEROON]: 'Cameroun',
    [Nationality.CANADA]: 'Canada',
    [Nationality.CAPE_VERDE]: 'Cap-Vert',
    [Nationality.CARIBBEAN_NETHERLANDS]: 'Caraïbes',
    [Nationality.CAYMAN_ISLANDS]: 'îles Cayman',
    [Nationality.CENTRAL_AFRICAN_REPUBLIC]: 'République centrafricaine',
    [Nationality.CHAD]: 'Tchad',
    [Nationality.CHILE]: 'Chili',
    [Nationality.CHINA]: 'Chine',
    [Nationality.CHRISTMAS_ISLAND]: 'île Christmas',
    [Nationality.COCOS_KEELING_ISLANDS]: 'îles Cocos (Keeling)',
    [Nationality.COLOMBIA]: 'Colombie',
    [Nationality.COMOROS]: 'Comores',
    [Nationality.CONGO]: 'Congo',
    [Nationality.COOK_ISLANDS]: 'îles Cook',
    [Nationality.COSTA_RICA]: 'Costa Rica',
    [Nationality.COTE_DIVOIRE]: "Côte d'Ivoire",
    [Nationality.CROATIA]: 'Croatie',
    [Nationality.CUBA]: 'Cuba',
    [Nationality.CURAÇAO]: 'Curaçao',
    [Nationality.CYPRUS]: 'Chypre',
    [Nationality.CZECH_REPUBLIC]: 'République tchèque',
    [Nationality.DEMOCRATIC_REPUBLIC_OF_THE_CONGO]: 'République démocratique du Congo',
    [Nationality.DENMARK]: 'Danemark',
    [Nationality.DJIBOUTI]: 'Djibouti',
    [Nationality.DOMINICA]: 'Dominique',
    [Nationality.DOMINICAN_REPUBLIC]: 'République dominicaine',
    [Nationality.EAST_TIMOR]: 'Timor oriental',
    [Nationality.ECUADOR]: 'Equateur',
    [Nationality.EGYPT]: 'Egypte',
    [Nationality.EL_SALVADOR]: 'El Salvador',
    [Nationality.EQUATORIAL_GUINEA]: 'Guinée équatoriale',
    [Nationality.ERITREA]: 'Erythrée',
    [Nationality.ESTONIA]: 'Estonie',
    [Nationality.ETHIOPIA]: 'Ethiopie',
    [Nationality.FALKLAND_ISLANDS]: 'Iles Falkland',
    [Nationality.FAROE_ISLANDS]: 'Iles Féroé',
    [Nationality.FIJI]: 'Fidji',
    [Nationality.FINLAND]: 'Finlande',
    [Nationality.FRANCE]: 'France',
    [Nationality.FRENCH_GUIANA]: 'Guyane française',
    [Nationality.FRENCH_POLYNESIA]: 'Polynésie française',
    [Nationality.FRENCH_SOUTHERN_AND_ANTARCTIC_LANDS]: 'Terres australes et antarctiques françaises',
    [Nationality.GABON]: 'Gabon',
    [Nationality.GAMBIA]: 'Gambie',
    [Nationality.GEORGIA]: 'Géorgie',
    [Nationality.GERMANY]: 'Allemagne',
    [Nationality.GHANA]: 'Ghana',
    [Nationality.GIBRALTAR]: 'Gibraltar',
    [Nationality.GREECE]: 'Grèce',
    [Nationality.GREENLAND]: 'Groenland',
    [Nationality.GRENADA]: 'Grenade',
    [Nationality.GUADELOUPE]: 'Guadeloupe',
    [Nationality.GUAM]: 'Guam',
    [Nationality.GUATEMALA]: 'Guatemala',
    [Nationality.GUERNSEY]: 'Guernesey',
    [Nationality.GUINEA]: 'Guinée',
    [Nationality.GUINEA_BISSAU]: 'Guinée-Bissau',
    [Nationality.GUYANA]: 'Guyane',
    [Nationality.HAITI]: 'Haïti',
    [Nationality.HEARD_ISLAND_AND_MCDONALD_ISLANDS]: 'Île Heard et Îles McDonald',
    [Nationality.HONDURAS]: 'Honduras',
    [Nationality.HONG_KONG]: 'Hong Kong',
    [Nationality.HUNGARY]: 'Hongrie',
    [Nationality.ICELAND]: 'Islande',
    [Nationality.INDIA]: 'Inde',
    [Nationality.INDONESIA]: 'Indonésie',
    [Nationality.IRAN]: 'Iran',
    [Nationality.IRAQ]: 'Iraq',
    [Nationality.IRELAND]: 'Irlande',
    [Nationality.ISLE_OF_MAN]: 'Île de Man',
    [Nationality.ISRAEL]: 'Israël',
    [Nationality.ITALY]: 'Italie',
    [Nationality.JAMAICA]: 'Jamaïque',
    [Nationality.JAPAN]: 'Japon',
    [Nationality.JERSEY]: 'Jersey',
    [Nationality.JORDAN]: 'Jordanie',
    [Nationality.KAZAKHSTAN]: 'Kazakhstan',
    [Nationality.KENYA]: 'Kenya',
    [Nationality.KIRIBATI]: 'Kiribati',
    [Nationality.KUWAIT]: 'Koweït',
    [Nationality.KYRGYZSTAN]: 'Kirghizstan',
    [Nationality.LAOS]: 'Laos',
    [Nationality.LATVIA]: 'Lettonie',
    [Nationality.LEBANON]: 'Liban',
    [Nationality.LESOTHO]: 'Lesotho',
    [Nationality.LIBERIA]: 'Liberia',
    [Nationality.LIBYA]: 'Libye',
    [Nationality.LIECHTENSTEIN]: 'Liechtenstein',
    [Nationality.LITHUANIA]: 'Lituanie',
    [Nationality.LUXEMBOURG]: 'Luxembourg',
    [Nationality.MACAU]: 'Macao',
    [Nationality.MACEDONIA_FYROM]: 'Macédoine (ARYM)',
    [Nationality.MADAGASCAR]: 'Madagascar',
    [Nationality.MALAWI]: 'Malawi',
    [Nationality.MALAYSIA]: 'Malaisie',
    [Nationality.MALDIVES]: 'Maldives',
    [Nationality.MALI]: 'Mali',
    [Nationality.MALTA]: 'Malte',
    [Nationality.MARSHALL_ISLANDS]: 'Iles Marshall',
    [Nationality.MARTINIQUE]: 'Martinique',
    [Nationality.MAURITANIA]: 'Mauritanie',
    [Nationality.MAURITIUS]: 'Maurice',
    [Nationality.MAYOTTE]: 'Mayotte',
    [Nationality.MEXICO]: 'Mexique',
    [Nationality.MICRONESIA]: 'Micronésie',
    [Nationality.MOLDOVA]: 'Moldavie',
    [Nationality.MONACO]: 'Monaco',
    [Nationality.MONGOLIA]: 'Mongolie',
    [Nationality.MONTENEGRO]: 'Monténégro',
    [Nationality.MONTSERRAT]: 'Montserrat',
    [Nationality.MOROCCO]: 'Maroc',
    [Nationality.MOZAMBIQUE]: 'Mozambique',
    [Nationality.MYANMAR_BURMA]: 'Myanmar (Birmanie)',
    [Nationality.NAMIBIA]: 'Namibie',
    [Nationality.NAURU]: 'Nauru',
    [Nationality.NEPAL]: 'Népal',
    [Nationality.NETHERLANDS]: 'Pays-Bas',
    [Nationality.NETHERLANDS_ANTILLES]: 'Antilles néerlandaises',
    [Nationality.NEW_CALEDONIA]: 'Nouvelle-Calédonie',
    [Nationality.NEW_ZEALAND]: 'Nouvelle-Zélande',
    [Nationality.NICARAGUA]: 'Nicaragua',
    [Nationality.NIGER]: 'Niger',
    [Nationality.NIGERIA]: 'Nigeria',
    [Nationality.NIUE]: 'Niue',
    [Nationality.NORFOLK_ISLAND]: 'Île Norfolk',
    [Nationality.NORTH_KOREA]: 'Corée du Nord',
    [Nationality.NORTHERN_MARIANA_ISLANDS]: 'Îles Mariannes du Nord',
    [Nationality.NORWAY]: 'Norvège',
    [Nationality.OMAN]: 'Oman',
    [Nationality.PAKISTAN]: 'Pakistan',
    [Nationality.PALAU]: 'Palau',
    [Nationality.PALESTINE]: 'Palestine',
    [Nationality.PANAMA]: 'Panama',
    [Nationality.PAPUA_NEW_GUINEA]: 'Papouasie-Nouvelle-Guinée',
    [Nationality.PARAGUAY]: 'Paraguay',
    [Nationality.PERU]: 'Pérou',
    [Nationality.PHILIPPINES]: 'Philippines',
    [Nationality.PITCAIRN_ISLANDS]: 'Îles Pitcairn',
    [Nationality.POLAND]: 'Pologne',
    [Nationality.PORTUGAL]: 'Portugal',
    [Nationality.PUERTO_RICO]: 'Porto Rico',
    [Nationality.QATAR]: 'Qatar',
    [Nationality.RÉUNION]: 'Ile de la Réunion',
    [Nationality.ROMANIA]: 'Roumanie',
    [Nationality.RUSSIA]: 'Russie',
    [Nationality.RWANDA]: 'Rwanda',
    [Nationality.SAINT_BARTHÉLEMY]: 'Saint-Barthélemy',
    [Nationality.SAINT_HELENA]: 'Sainte-Hélène',
    [Nationality.SAINT_KITTS_AND_NEVIS]: 'Saint-Kitts-et-Nevis',
    [Nationality.SAINT_LUCIA]: 'Sainte-Lucie',
    [Nationality.SAINT_MARTIN]: 'Saint-Martin',
    [Nationality.SAINT_PIERRE_AND_MIQUELON]: 'Saint-Pierre-et-Miquelon',
    [Nationality.SAINT_VINCENT_AND_THE_GRENADINES]: 'Saint-Vincent-et-les-Grenadines',
    [Nationality.SAMOA]: 'Samoa',
    [Nationality.SAN_MARINO]: 'Saint-Marin',
    [Nationality.SAO_TOME_AND_PRINCIPE]: 'Sao Tomé-et-Principe',
    [Nationality.SAUDI_ARABIA]: 'Arabie saoudite',
    [Nationality.SENEGAL]: 'Sénégal',
    [Nationality.SERBIA]: 'Serbie',
    [Nationality.SEYCHELLES]: 'Seychelles',
    [Nationality.SIERRA_LEONE]: 'Sierra Leone',
    [Nationality.SINGAPORE]: 'Singapour',
    [Nationality.SINT_MAARTEN]: 'Saint-Martin',
    [Nationality.SLOVAKIA]: 'Slovaquie',
    [Nationality.SLOVENIA]: 'Slovénie',
    [Nationality.SOLOMON_ISLANDS]: 'Îles Salomon',
    [Nationality.SOMALIA]: 'Somalie',
    [Nationality.SOUTH_AFRICA]: 'Afrique du Sud',
    [Nationality.SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS]: 'Géorgie du Sud et les îles Sandwich du Sud',
    [Nationality.SOUTH_KOREA]: 'Corée du Sud',
    [Nationality.SOUTH_SUDAN]: 'Soudan du Sud',
    [Nationality.SPAIN]: 'Espagne',
    [Nationality.SRI_LANKA]: 'Sri Lanka',
    [Nationality.SUDAN]: 'Soudan',
    [Nationality.SURINAME]: 'Suriname',
    [Nationality.SVALBARD_AND_JAN_MAYEN]: 'Svalbard et Jan Mayen',
    [Nationality.SWAZILAND]: 'Swaziland',
    [Nationality.SWEDEN]: 'Suède',
    [Nationality.SWITZERLAND]: 'Suisse',
    [Nationality.SYRIA]: 'Syrie',
    [Nationality.TAIWAN]: 'Taiwan',
    [Nationality.TAJIKISTAN]: 'Tadjikistan',
    [Nationality.TANZANIA]: 'Tanzanie',
    [Nationality.THAILAND]: 'Thaïlande',
    [Nationality.TOGO]: 'Togo',
    [Nationality.TOKELAU]: 'Tokelau',
    [Nationality.TONGA]: 'Tonga',
    [Nationality.TRINIDAD_AND_TOBAGO]: 'Trinité-et-Tobago',
    [Nationality.TUNISIA]: 'Tunisie',
    [Nationality.TURKEY]: 'Turquie',
    [Nationality.TURKMENISTAN]: 'Turkménistan',
    [Nationality.TURKS_AND_CAICOS_ISLANDS]: 'Îles Turks et Caicos',
    [Nationality.TUVALU]: 'Tuvalu',
    [Nationality.U_S_VIRGIN_ISLANDS]: 'Îles Vierges des États-Unis',
    [Nationality.UGANDA]: 'Ouganda',
    [Nationality.UKRAINE]: 'Ukraine',
    [Nationality.UNITED_ARAB_EMIRATES]: 'Émirats arabes unis',
    [Nationality.UNITED_KINGDOM]: 'Royaume-Uni',
    [Nationality.UNITED_STATES]: 'États-Unis',
    [Nationality.UNITED_STATES_MINOR_OUTLYING_ISLANDS]: 'Îles mineures éloignées des États-Unis',
    [Nationality.URUGUAY]: 'Uruguay',
    [Nationality.UZBEKISTAN]: 'Ouzbékistan',
    [Nationality.VANUATU]: 'Vanuatu',
    [Nationality.VATICAN_CITY]: 'Cité du Vatican',
    [Nationality.VENEZUELA]: 'Venezuela',
    [Nationality.VIETNAM]: 'Vietnam',
    [Nationality.WALLIS_AND_FUTUNA]: 'Wallis-et-Futuna',
    [Nationality.WESTERN_SAHARA]: 'Sahara occidental',
    [Nationality.YEMEN]: 'Yémen',
    [Nationality.ZAMBIA]: 'Zambie',
    [Nationality.ZIMBABWE]: 'Zimbabwe',
  },
};
