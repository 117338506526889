import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import Card from 'components/Card';
import CheckboxBase from 'components/Forms/CheckboxBase';
import Input from 'components/Forms/Input';
import { DEFAULT_DEBOUNCE_TIMEOUT } from 'constants/constants';
import debounce from 'debounce';
import { useUpdateQuery } from 'hooks/router';
import { GlobalAppState } from 'pages/_store/global/initial-state';
import { RootState } from 'pages/_store/root-reducer';
import React, { FC, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { DealStatus, RolesEnum, SourceType, SpecialDealType } from 'types';
import { DealFilters, initialState } from '../../store/initial-state';
import messages from './DealsFilterPanel.translate';
import styles from './FilterPanel.module.scss';

interface Props {
  searchTerm: string;
  onSearchTermChange: (term: string) => void;
}

const FilterPanel: FC<Props> = ({ searchTerm, onSearchTermChange }) => {
  const { formatMessage } = useIntl();
  const {
    user: { roles },
  } = useSelector<RootState, GlobalAppState>((state) => state.globalAppState, shallowEqual);

  const isUserAdmin = roles?.includes(RolesEnum.SUPER_ADMIN) || roles?.includes(RolesEnum.ADMIN);

  const updateQuery = useUpdateQuery<DealFilters>({
    replace: true,
    initialState,
  });

  // const facetsStatus: ListingStatusFacet[] = useSelector<RootState, ListingStatusFacet[]>(
  //   (state) => state.dealResultsState.facets.status,
  //   shallowEqual
  // );
  // const facetSource: ListingFacet[] = useSelector<RootState, ListingFacet[]>(
  //   (state) => state.dealResultsState.facets.source,
  //   shallowEqual
  // );
  // const facetSpecialDealType = useSelector<RootState, ListingSpecialDealTypeFacet[]>(
  //   (state) => state.dealResultsState.facets.specialDealType,
  //   shallowEqual
  // );
  const filterStatus = useSelector<RootState, DealStatus[]>(
    (state) => state.dealResultsState.filters.status,
    shallowEqual
  );
  const filterSource = useSelector<RootState, SourceType[]>(
    (state) => state.dealResultsState.filters.source,
    shallowEqual
  );
  const filterSpecialDealType = useSelector<RootState, SpecialDealType[]>(
    (state) => state.dealResultsState.filters.specialDealType,
    shallowEqual
  );
  const filterClosedUserGroupSearch = useSelector<RootState, string>(
    (state: RootState) => state.dealResultsState.filters.closedUserGroup,
    shallowEqual
  );

  // const getFacetsStatus = (key: string) => findByKey(facetsStatus, key);
  // const getFacetsSource = (key: string) => findByKey(facetSource, key);
  // const getFacetsSpecialDealType = (key: string) => findByKey(facetSpecialDealType, key);

  // const facetPublished = getFacetsStatus('published');
  // const facetDraft = getFacetsStatus('draft');
  // const facetDeleted = getFacetsStatus('trash');
  // const facetError = getFacetsStatus('error');

  // const facetAs24 = getFacetsSource(SourceType.AS24);
  // const facetManual = getFacetsSource(SourceType.MANUAL);

  // const facetHotDeals = getFacetsSpecialDealType(SpecialDealType.HOT_DEAL);
  // const facetGowagoSaleDeals = getFacetsSpecialDealType(SpecialDealType.GOWAGO_SALE);
  // const facetFeaturedDeals = getFacetsSpecialDealType(SpecialDealType.FEATURED);

  const handleFacetChange = (facet: DealStatus) => () => {
    const newFilterStatus = filterStatus.includes(facet)
      ? filterStatus.filter((item) => item !== facet)
      : [...filterStatus, facet];
    updateQuery({
      status: [...newFilterStatus],
      $skip: 0,
    });
  };

  const handleSourceChange = (source: SourceType) => () => {
    const newFilterSource = filterSource.includes(source)
      ? filterSource.filter((item) => item !== source)
      : [...filterSource, source];

    updateQuery({
      source: [...newFilterSource],
      $skip: 0,
    });
  };

  const handleSpecialDealTypeChange = (specialDealType: SpecialDealType) => () => {
    const newFilterSource = filterSpecialDealType.includes(specialDealType)
      ? filterSpecialDealType.filter((item) => item !== specialDealType)
      : [...filterSpecialDealType, specialDealType];

    updateQuery({
      specialDealType: [...newFilterSource],
      $skip: 0,
    });
  };

  const handleQuerySearchChangeDebounced = useCallback(
    debounce((value: string) => {
      updateQuery({
        query: value,
      });
    }, DEFAULT_DEBOUNCE_TIMEOUT),
    []
  );

  const handleQuerySearchChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    onSearchTermChange(evt.target.value);
    handleQuerySearchChangeDebounced(evt.target.value);
  };

  const handleClosedUserGroupSearchChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    updateQuery({
      closedUserGroup: evt.target.value,
    });
  };

  return (
    <div className={styles.root}>
      <Card>
        <div className={styles.container}>
          <div className={styles.title}>{formatMessage(messages.DealsFilterPanelFilterListingsHeader)}</div>
          <Input
            startAdornment={faSearch}
            placeholder={formatMessage(messages.DealsFilterPanelSearchForPlaceholder)}
            onChange={handleQuerySearchChange}
            value={searchTerm}
          />
          <div className={styles.divider} />
          <div className={styles.subTitle}>{formatMessage(messages.DealsFilterPanelStatusSubHeader)}</div>
          <div className={styles.checkboxContainer}>
            <CheckboxBase
              label={formatMessage(messages.DealsFilterPanelPublishedLabel)}
              // facets={facetPublished ? facetPublished.count : 0}
              checked={filterStatus.includes(DealStatus.PUBLISHED)}
              onChange={handleFacetChange(DealStatus.PUBLISHED)}
              // disabled={!facetPublished}
            />
            <CheckboxBase
              label={formatMessage(messages.DealsFilterPanelDraftLabel)}
              // facets={facetDraft ? facetDraft.count : 0}
              checked={filterStatus.includes(DealStatus.DRAFT)}
              onChange={handleFacetChange(DealStatus.DRAFT)}
              // disabled={!facetDraft}
            />
            <CheckboxBase
              label={formatMessage(messages.DealsFilterPanelErrorLabel)}
              // facets={facetError ? facetError.count : 0}
              checked={filterStatus.includes(DealStatus.ERROR)}
              onChange={handleFacetChange(DealStatus.ERROR)}
              // disabled={!facetError}
            />
            <CheckboxBase
              label={formatMessage(messages.DealsFilterPanelTrashLabel)}
              // facets={facetDeleted ? facetDeleted.count : 0}
              checked={filterStatus.includes(DealStatus.TRASH)}
              onChange={handleFacetChange(DealStatus.TRASH)}
              // disabled={!facetDeleted}
            />
          </div>
          {!!isUserAdmin && (
            <>
              <div className={styles.divider} />
              <div className={styles.subTitle}>{formatMessage(messages.DealsFilterPanelSourcesSubHeader)}</div>
              <div className={styles.checkboxContainer}>
                <CheckboxBase
                  label={'AutoScout24'}
                  // facets={facetAs24 ? facetAs24.count : 0}
                  // disabled={!facetAs24 || facetAs24?.count === 0}
                  checked={filterSource.includes(SourceType.AS24)}
                  onChange={handleSourceChange(SourceType.AS24)}
                />
                <CheckboxBase
                  label={'gowago.ch Sales Portal'}
                  // disabled={!facetManual || facetManual?.count === 0}
                  // facets={facetManual ? facetManual.count : 0}
                  checked={filterSource.includes(SourceType.MANUAL)}
                  onChange={handleSourceChange(SourceType.MANUAL)}
                />
              </div>
              <div className={styles.divider} />
              <div className={styles.subTitle}>{formatMessage(messages.DealsFilterPanelPromotions)}</div>
              <div className={styles.closedUserGroupsInputContainer}>
                <Input
                  startAdornment={faSearch}
                  placeholder={formatMessage(messages.DealsFilterPanelClosedUserGroup)}
                  onChange={handleClosedUserGroupSearchChange}
                  value={filterClosedUserGroupSearch}
                />
              </div>
              <div className={styles.checkboxContainer}>
                <CheckboxBase
                  label={formatMessage(messages.DealsFilterPanelHotDeals)}
                  // disabled={!facetHotDeals || facetHotDeals?.count === 0}
                  // facets={facetHotDeals?.count || 0}
                  checked={filterSpecialDealType.includes(SpecialDealType.HOT_DEAL)}
                  onChange={handleSpecialDealTypeChange(SpecialDealType.HOT_DEAL)}
                />
                <CheckboxBase
                  label={formatMessage(messages.DealsFilterPanelGowagoSalesPage)}
                  // disabled={!facetGowagoSaleDeals || facetGowagoSaleDeals?.count === 0}
                  // facets={facetGowagoSaleDeals?.count || 0}
                  checked={filterSpecialDealType.includes(SpecialDealType.GOWAGO_SALE)}
                  onChange={handleSpecialDealTypeChange(SpecialDealType.GOWAGO_SALE)}
                />
                <CheckboxBase
                  label={formatMessage(messages.DealsFilterPanelFeaturedDeals)}
                  // disabled={!facetFeaturedDeals || facetFeaturedDeals?.count === 0}
                  // facets={facetFeaturedDeals?.count || 0}
                  checked={filterSpecialDealType.includes(SpecialDealType.FEATURED)}
                  onChange={handleSpecialDealTypeChange(SpecialDealType.FEATURED)}
                />
              </div>
            </>
          )}
        </div>
      </Card>
    </div>
  );
};

export default FilterPanel;
