import { useFormikContext } from 'formik';
import { FunctionComponent } from 'react';
import CheckboxBase, { Props as CheckboxBaseProps } from '../CheckboxBase';

interface Props extends CheckboxBaseProps {
  name: string;
  tooltip?: string;
}

const Checkbox: FunctionComponent<Props> = ({ name, tooltip, ...rest }) => {
  const { values, setFieldValue } = useFormikContext();

  const handleOnChange = (value: boolean) => {
    setFieldValue(name, value);
    if (rest.onChange) {
      rest.onChange(value);
    }
  };

  return <CheckboxBase {...rest} checked={(values as any)[name]} onChange={handleOnChange} tooltip={tooltip} />;
};

export default Checkbox;
