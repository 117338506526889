interface SortArgument {
  model: string;
  slug: string;
}

export const sortModels = (a: SortArgument, b: SortArgument) => {
  if (a.model < b.model) {
    return -1;
  }

  if (a.model > b.model) {
    return 1;
  }

  return 0;
};
