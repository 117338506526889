import React from 'react';

type RC<P> = React.SFC<P> | React.ComponentClass<P>;

type HOC<O, P> = (C: RC<O>) => RC<P>;

// compose components from left to right
// tslint:disable-next-line:no-any
export const compose = <P>(C: RC<P>, ...hocs: HOC<any, any>[]): RC<P> => hocs.reduce((g, f) => f(g), C);

export const composeStyle = <T>(defaultStyles: T, addtlStyles?: Partial<T>): T => {
  if (!addtlStyles) return defaultStyles;
  const returnVal = {};

  Object.keys(defaultStyles).forEach((key) => {
    if (addtlStyles[key]) {
      returnVal[key] = `${defaultStyles[key]} ${addtlStyles[key]}`;
    } else {
      returnVal[key] = defaultStyles[key];
    }
  });

  return returnVal as T;
};
