import apiClient from 'api/apiClient';
import InitialPageLoadingIndicator from 'components/InitialPageLoadingIndicator';
import { Actions as GlobalActions } from 'pages/_store/global/actions';
import { User } from 'pages/_store/global/initial-state';
import GlobalSelectors from 'pages/_store/global/selectors';
import { RootState } from 'pages/_store/root-reducer';
import { FunctionComponent, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { compose } from 'utils/compose';
import patchDOMForGoogleTranslate from 'utils/patchDOMForGoogleTranslate';
import Translations from './App.translations';
import Dashboard from './layouts/Dashboard';
import Public from './layouts/Public';

const App: FunctionComponent = () => {
  const dispatch = useDispatch();

  const { formatMessage } = useIntl();

  const isAuthenticated = useSelector<RootState, boolean>(GlobalSelectors.extractIsAuthenticated, shallowEqual);
  const isInitialLoaded = useSelector(GlobalSelectors.extractIsInitialLoaded, shallowEqual);
  const user = useSelector<RootState, User>(GlobalSelectors.extractUser, shallowEqual);

  useEffect(() => {
    patchDOMForGoogleTranslate();
  }, []);

  useEffect(() => {
    apiClient.initialize(() =>
      dispatch(
        GlobalActions.sendNotification({
          message: formatMessage(Translations.AppTheServerIsTemporarilyUnavailable),
          variant: 'error',
          persist: true,
          preventDuplicate: true,
        })
      )
    );
    dispatch(GlobalActions.userMeRequest());
    dispatch(GlobalActions.getCarMakesRequest());
  }, [dispatch, formatMessage]);

  if (!isInitialLoaded) {
    return <InitialPageLoadingIndicator />;
  }

  if (isAuthenticated && user?.id) {
    return <Dashboard />;
  }

  return <Public />;
};

export default compose(App);
