import { defineMessages } from 'react-intl';

export default defineMessages({
  GuaranteeFormBoxGuarantee: {
    id: 'GuaranteeFormBoxGuarantee',
    defaultMessage: 'Guarantee',
  },
  GuaranteeFormBoxGuaranteeText: {
    id: 'GuaranteeFormBoxGuaranteeText',
    defaultMessage: 'Guarantee text',
  },
  GuaranteeFormBoxNoGuarantee: {
    id: 'GuaranteeFormBoxNoGuarantee',
    defaultMessage: 'No guarantee',
  },
  GuaranteeFormBoxFromPickup: {
    id: 'GuaranteeFormBoxFromPickup',
    defaultMessage: 'From pickup',
  },
  GuaranteeFormBoxFromFirst: {
    id: 'GuaranteeFormBoxFromFirst',
    defaultMessage: 'From first',
  },
  GuaranteeFormBoxFromDate: {
    id: 'GuaranteeFormBoxFromDate',
    defaultMessage: 'From date',
  },
  GuaranteeFormBoxWarranyInKM: {
    id: 'GuaranteeFormBoxWarranyInKM',
    defaultMessage: 'Warranty in KM',
  },
  GuaranteeFormBoxWarranyInMonths: {
    id: 'GuaranteeFormBoxWarranyInMonths',
    defaultMessage: 'Warranty in months',
  },
  GuaranteeFormBoxWarranyKMLeft: {
    id: 'GuaranteeFormBoxWarranyKMLeft',
    defaultMessage: 'Warranty KM left',
  },
  GuaranteeFormBoxWarranyMonthsLeft: {
    id: 'GuaranteeFormBoxWarranyMonthsLeft',
    defaultMessage: 'Warranty months left',
  },
  GuaranteeFormBoxWarranyStartType: {
    id: 'GuaranteeFormBoxWarranyStartType',
    defaultMessage: 'Warranty start type',
  },
  GuaranteeFormBoxWarranyUntilDate: {
    id: 'GuaranteeFormBoxWarranyUntilDate',
    defaultMessage: 'Warranty until date',
  },
  from_licensing_start: {
    id: 'GuaranteeFormBoxFromLicensingStart',
    defaultMessage: 'From licensing start',
  },
  from_take_over: {
    id: 'GuaranteeFormBoxFromTakeOver',
    defaultMessage: 'From take over',
  },
});
