import TextFieldBase from 'components/Forms/TextFieldBase';
import { RootState } from 'pages/_store/root-reducer';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import styles from './AutoI.module.scss';
import Translations from './AutoI.translations';

const AutoI: FC = () => {
  const { formatMessage } = useIntl();

  const { deal } = useSelector((state: RootState) => ({
    deal: state.addEditListingsPage.deal,
  }));

  return (
    <>
      <div className={styles.header}>{formatMessage(Translations.AutoI)}</div>
      <div className={styles.row}>
        <div className={styles.column}>
          <TextFieldBase name="autoIFields.Marke" label="Marke" value={deal?.autoIFields?.Marke} disabled readOnly />
        </div>
        <div className={styles.column}>
          <TextFieldBase
            name="autoIFields.ModellDe"
            label="ModellDe"
            value={deal?.autoIFields?.ModellDe}
            disabled
            readOnly
          />
        </div>
        <div className={styles.column}>
          <TextFieldBase name="autoIFields.TypDE" label="TypDE" value={deal?.autoIFields?.TypDe} disabled readOnly />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.column}>
          <TextFieldBase name="autoIFields.PS" label="PS" value={deal?.autoIFields?.PS} disabled readOnly />
        </div>
        <div className={styles.column}>
          <TextFieldBase
            name="autoIFields.ProdVon"
            label="ProdVon"
            value={deal?.autoIFields?.ProdVon}
            disabled
            readOnly
          />
        </div>
        <div className={styles.column}>
          <TextFieldBase
            name="autoIFields.LetzterNP"
            label="LetzterNP"
            value={deal?.autoIFields?.LetzterNP}
            disabled
            readOnly
          />
        </div>
      </div>
    </>
  );
};

export default AutoI;
