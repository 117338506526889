import ConfirmationModalBase from 'components/ConfirmationModalBase';
import AddEditListingSelectors from 'pages/AddEditListings/store/selectors';
import { RootState } from 'pages/_store/root-reducer';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { compose } from 'utils/compose';
import { Actions as AddEditListingsActions } from '../../store/actions';
import Translations from './StepChangeModal.translations';

interface Props {
  title: string;
  okLabel: string;
  onOk: (stepNumber: number) => void;
  stepNumber: number;
}

const StepChangeModal: FC<Props> = ({ title, okLabel, onOk, stepNumber }) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const isStepChangeModalOpen = useSelector(
    (state: RootState) => AddEditListingSelectors.extractIsStepDialogOpen(state),
    shallowEqual
  );

  const handleOnOk = () => {
    dispatch(AddEditListingsActions.setIsStepChangeModalOpen(false));
    onOk(stepNumber);
  };

  const onClose = () => {
    dispatch(AddEditListingsActions.setIsStepChangeModalOpen(false));
  };

  return (
    <ConfirmationModalBase
      title={title}
      message={formatMessage(Translations.StepChangeModalThisWillDeleteSomeInformationFromListing)}
      okLabel={okLabel}
      closeLabel={formatMessage(Translations.StepChangeBack)}
      isOpen={isStepChangeModalOpen}
      onOkay={handleOnOk}
      onClose={onClose}
      variant={'error'}
      isOkButtonLeft={false}
    />
  );
};

export default compose<Props>(StepChangeModal);
