import { SellerPublic } from 'types';
import { Ownership, VendorQuery, VendorQueryResult, VendorRequest } from '../types';
import ApiClient from './apiClient';

export const findVendor = async ({
  vendorStatus,
  sorting,
  name,
  $skip,
  $limit,
}: VendorQuery): Promise<VendorQueryResult> => {
  return ApiClient.post('vendor/search', {
    name,
    $skip: Number($skip),
    $limit: Number($limit),
    vendorStatus: vendorStatus && vendorStatus.length > 0 ? vendorStatus : undefined,
    sorting,
  });
};

export const getVendorById = async (id: number): Promise<VendorRequest> => {
  return await ApiClient.get<VendorRequest>(`vendor/${id}`, { queryParams: { vendor: id } });
};

export const updateVendor = async (id: number, vendor: Partial<VendorRequest>): Promise<VendorRequest> => {
  return await ApiClient.put(`vendor/${id}`, vendor, undefined);
};

export const getVendorOwnership = async (id: number): Promise<Ownership> => {
  return await ApiClient.get(`vendor/${id}/ownership`);
};

export const findSellers = async (vendorId: number): Promise<SellerPublic[]> => {
  return await ApiClient.get(`vendor/${vendorId}/sellers`, { queryParams: { vendor: vendorId } });
};
