import { APIError, VendorQuery, VendorQueryResult } from 'types';
import { ActionsUnion, createAction } from 'utils/action-utils';

export enum ActionTypes {
  VIEW_VENDORS_REQUEST = '[VIEW_ALL_VENDORS_PAGE] VIEW_VENDORS_REQUEST',
  VIEW_VENDORS_SUCCESS = '[VIEW_ALL_VENDORS_PAGE] VIEW_VENDORS_SUCCESS',
  VIEW_VENDORS_FAILURE = '[VIEW_ALL_VENDORS_PAGE] VIEW_VENDORS_FAILURE',
}

export const Actions = {
  viewVendorsRequest: (query: Partial<VendorQuery>) => createAction(ActionTypes.VIEW_VENDORS_REQUEST, query),
  viewVendorsSuccess: (result: VendorQueryResult) => createAction(ActionTypes.VIEW_VENDORS_SUCCESS, result),
  viewVendorsFailure: (e: APIError) => createAction(ActionTypes.VIEW_VENDORS_FAILURE, e),
  noActionWithoutPayload: () => createAction('NO_ACTION_WITHOUT_PAYLOAD'),
};

export type Actions = ActionsUnion<typeof Actions>;
