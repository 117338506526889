import { defineMessages } from 'react-intl';

export default defineMessages({
  VendorInformationNavigationContactInformation: {
    id: 'VendorInformationNavigationContactInformation',
    defaultMessage: 'Contact information',
  },
  VendorInformationNavigationOpeningTimes: {
    id: 'VendorInformationNavigationOpeningTimes',
    defaultMessage: 'Opening times',
  },
});
