import { makeToWritten } from '@carcodex/gow-shared-mono/modules/car/car.constants';
import { StyledComponentProps, withStyles, WithStyles } from '@material-ui/core';
import clsx from 'clsx';
import MonthYearSelection from 'components/Forms/MonthYearSelection';
import Selection from 'components/Forms/Selection';
import { useFormikContext } from 'formik';
import { AddEditVehicleInformationShema } from 'pages/AddEditListings/schema';
import { Actions as AddEditListingsActions } from 'pages/AddEditListings/store/actions';
import { DefaultAddEditListingParams } from 'pages/AddEditListings/utils';
import { RootState } from 'pages/_store/root-reducer';
import { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { compose } from 'utils/compose';
import { getNumberOrNull } from 'utils/form';
import AddVehicleInformationSubmit from '../AddVehicleInformationSubmit';
import { styles } from './ByMakeAndModelFormContent.styles';
import Translations from './ByMakeAndModelFormContent.translations';
import { sortModels } from './util';

interface Props {
  onAddInfoManually: () => void;
}

const ByMakeAndModelFormContent: FC<Props & WithStyles<typeof styles>> = ({ classes, onAddInfoManually }) => {
  const { handleSubmit, setFieldValue, resetForm, values } = useFormikContext<AddEditVehicleInformationShema>();
  const { vendorId, dealId } = useParams<DefaultAddEditListingParams>();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const { make, modelSlug, registrationDateYear } = values;

  const { carMakes } = useSelector(
    (state: RootState) => ({
      carMakes: state.globalAppState.carMakes,
    }),
    shallowEqual
  );

  useEffect(() => {
    resetForm();
  }, [vendorId, dealId, resetForm]);

  useEffect(() => {
    if (make && modelSlug && registrationDateYear) {
      const modelYear = getNumberOrNull(registrationDateYear, true);

      dispatch(
        AddEditListingsActions.getCarsRequest({
          make,
          modelSlug,
          modelYear,
        })
      );
    }
  }, [dispatch, make, modelSlug, registrationDateYear]);

  const handleMakeChange = () => {
    setFieldValue('modelSlug', undefined);
    setFieldValue('registrationDateYear', undefined);
  };

  const handleModelChange = () => setFieldValue('registrationDateYear', undefined);

  const carMakesSorted = Object.keys(carMakes).sort();
  const makeOptions = carMakesSorted.map((key: string) => ({
    label: makeToWritten[(key as keyof typeof makeToWritten)?.toUpperCase()],
    value: key,
  }));

  const makeValue = values.make;
  const modelOptions =
    !!makeValue && carMakes[makeValue]
      ? (() => {
          const modelsSorted = carMakes[makeValue].sort(sortModels);
          return modelsSorted.map((item) => ({
            label: item.model,
            value: item.slug,
          }));
        })()
      : [];

  const isMonthDatePickerDisabled = !values.make || !values.modelSlug;

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.root}>
        <div className={classes.formColumn}>
          <div className={classes.formRow}>
            <Selection
              name="make"
              label={formatMessage(Translations.ByMakeAndModelFormContentMake)}
              classes={{ root: classes.inputRoot }}
              options={makeOptions}
              required
              onChange={handleMakeChange}
            />
          </div>
          <div className={classes.formRow}>
            <Selection
              name="modelSlug"
              label={formatMessage(Translations.ByMakeAndModelFormContentModel)}
              disabled={!values.make}
              options={modelOptions}
              required
              onChange={handleModelChange}
            />
          </div>
          <div className={clsx(classes.formRow, classes.monthYearSelectionRow)}>
            <MonthYearSelection
              label={formatMessage(Translations.ByMakeAndModelFormContentRegistrationOrConstructionYear)}
              yearName="registrationDateYear"
              yearValue={values.registrationDateYear}
              disabled={isMonthDatePickerDisabled}
              yearOnly
              required
              tooltip={
                isMonthDatePickerDisabled
                  ? formatMessage(Translations.ByMakeAndModelFormContentSelectMakeAndModelFirst)
                  : undefined
              }
            />
          </div>
        </div>
      </div>
      <AddVehicleInformationSubmit onAddInfoManually={onAddInfoManually} />
    </form>
  );
};

export default compose<Props & StyledComponentProps>(
  ByMakeAndModelFormContent,
  withStyles(styles, { name: 'ByMakeAndModelFormContent' })
);
