import React, { FC } from 'react';
import styles from './FieldErrorLabel.module.scss';

type CustomErrorRenderer = (value: any, error: boolean, dirty: boolean) => React.ReactElement;

interface Props {
  errorLabel: string;
  touched: boolean;
  error: boolean;
  dirty: boolean;
  value?: any;
  customErrorLabel?: CustomErrorRenderer;
}

const FieldErrorLabel: FC<Props> = ({ errorLabel, touched, error, dirty, value, customErrorLabel }) => {
  const handleErrorLabel = () => {
    if (touched && error) {
      return errorLabel;
    }
    if (customErrorLabel) {
      return customErrorLabel(value, true, dirty);
    }
  };
  return <div className={styles.root}>{handleErrorLabel()}</div>;
};

export default FieldErrorLabel;
