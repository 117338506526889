import { RootState } from 'pages/_store/root-reducer';
import { OfferType, OwnershipType } from 'types';

const extractOrder = (state: RootState) => state.orderPage.order;
const extractIsFetchingResult = (state: RootState) => state.orderPage.isFetchingResult;
const getIsOEM = (state: RootState) => extractOrder(state)?.offerType === OfferType.OEM;
const getIsPrivateCredit = (state: RootState) => extractOrder(state)?.ownershipType === OwnershipType.PRIVATE_CREDIT;

export default {
  extractOrder,
  extractIsFetchingResult,
  getIsOEM,
  getIsPrivateCredit,
};
