export function isNil(value: any): boolean {
  return value === undefined || value === null;
}

export const hasMoreThanTwoNumbersAfterDecimalPoint = (value: string) => {
  if (value.indexOf('.') === -1) return false;

  const numberOfDigitsAfterDecimalPoint = value.split('.')[1];
  return numberOfDigitsAfterDecimalPoint.length > 2;
};
