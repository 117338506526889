import { defineMessages } from 'react-intl';

export default defineMessages({
  ErrorPageTitle: {
    id: 'ErrorPageTitle',
    defaultMessage: 'Internal error',
  },
  ErrorPageInternalErrorText: {
    id: 'ErrorPageInternalErrorText',
    defaultMessage: 'Sorry, your page couldn’t be loaded due to an internal error.',
  },
  ErrorPageTryReloadingThePage: {
    id: 'ErrorPageTryReloadingThePage',
    defaultMessage: 'Try reloading the page. If that doesn’t work, contact our support team for help.',
  },
});
