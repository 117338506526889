import { faAngleLeft, faAngleRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { compose } from 'utils/compose';
import styles from './TableNavigation.module.scss';
import Translations from './TableNavigation.translations';

interface Props {
  onClickNext: () => void;
  onClickPrev: () => void;
  total: number;
  skip: number;
  limit: number;
}

const TableNavigation: FC<Props> = ({ onClickNext, onClickPrev, total, skip, limit }) => {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.root}>
      <div className={styles.resultLabel}>
        {formatMessage(Translations.TableNavigationShowingCountOfTotal, {
          from: skip + 1,
          to: skip + limit < total ? skip + limit : total,
          total: total,
        })}
      </div>
      {total > 0 && (
        <>
          <button className={styles.navButton} onClick={onClickPrev} disabled={skip === 0}>
            <FontAwesomeIcon icon={faAngleLeft} className={styles.navIcon} />
          </button>
          <button className={styles.navButton} onClick={onClickNext} disabled={skip + limit >= total}>
            <FontAwesomeIcon icon={faAngleRight} className={styles.navIcon} />
          </button>
        </>
      )}
    </div>
  );
};

export default compose<Props>(TableNavigation);
