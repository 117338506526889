import generalTranslations from 'constants/general-translations';
import { Actions as GlobalActions } from 'pages/_store/global/actions';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

interface Args {
  importFunc: () => Promise<any>;
  onLoad?: () => void;
  onError?: () => void;
  force?: boolean;
}

export const useLazyComponentImport = <T>() => {
  const [Component, setComponent] = useState<T>();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const load = ({ force = false, importFunc, onLoad, onError }: Args) => {
    if (Component && !force) return;

    importFunc()
      .then((LoadedComponent) => {
        setComponent(LoadedComponent.default);
        if (onLoad) {
          onLoad();
        }
      })
      .catch(() => {
        dispatch(
          GlobalActions.sendNotification({
            message: formatMessage(generalTranslations.ChunkLoadError),
            variant: 'error',
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'top',
            },
          })
        );
        if (onError) {
          onError();
        }
      });
  };

  // id: 'FeedbackSnackbarChunkLoadError',
  //   defaultMessage: 'Something went wrong. Try to reload the page.',

  const reset = () => setComponent(undefined);

  return {
    load,
    reset,
    Component,
  };
};
