import { IntlShape } from 'react-intl';
import { DealStatus, SelectOption } from 'types';
import Translation from './SubmitButtons.translations';

export const getStatusOptions = ({ formatMessage }: IntlShape): SelectOption[] => [
  {
    label: formatMessage(Translation.SubmitButtonsChangeStatus),
    value: '',
  },
  {
    label: formatMessage(Translation.SubmitButtonsStatusValueDraft),
    value: DealStatus.DRAFT,
  },
  {
    label: formatMessage(Translation.SubmitButtonsStatusValueTrash),
    value: DealStatus.TRASH,
  },
];
