import { ThemeProvider } from '@material-ui/core/styles';
import { GoogleOAuthProvider } from '@react-oauth/google';
import * as Sentry from '@sentry/react';
import 'core-js';
import 'font-awesome.config';
import ConnectedIntlProvider from 'HOCs/IntlProviderWrapper';
import NotificationHandler from 'HOCs/NotificationHandler';
import { SnackbarProvider } from 'notistack';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { theme } from 'theme';
import App from './App';
import './index.scss';
import store from './pages/_store';
import * as serviceWorker from './serviceWorker';

const clientId = process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID;

const currentEnviroment = String(process.env.REACT_APP_ENV || 'LOCAL');

if (currentEnviroment !== 'LOCAL') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: currentEnviroment,
  });
}

ReactDOM.render(
  <Provider store={store}>
    <ConnectedIntlProvider>
      <GoogleOAuthProvider clientId={clientId}>
        <SnackbarProvider maxSnack={20}>
          <NotificationHandler>
            <ThemeProvider theme={theme}>
              <DndProvider backend={HTML5Backend}>
                <App />
              </DndProvider>
            </ThemeProvider>
          </NotificationHandler>
        </SnackbarProvider>
      </GoogleOAuthProvider>
    </ConnectedIntlProvider>
  </Provider>,

  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
