import { ResetPasswordRequest } from 'api/user';
import { APIError, CarMakesResponse, NotificationProps, UserInfo, Vendor } from 'types';
import { ActionsUnion, createAction } from 'utils/action-utils';
import { ConfirmationModalProps, ResetPasswordErrorType } from './initial-state';

export enum ActionTypes {
  TOGGLE_SIDEPANEL_NAV = '[GLOBAL_STATE] TOGGLE_SIDEPANEL_NAV',
  USER_LOGIN_REQUEST = '[GLOBAL_STATE] USER_LOGIN_REQUEST',
  USER_LOGIN_SUCCESS = '[GLOBAL_STATE] USER_LOGIN_SUCCESS',
  USER_LOGIN_FAILURE = '[GLOBAL_STATE] USER_LOGIN_FAILURE',
  SET_USER_LOGIN_FETCHING = '[GLOBAL_STATE] SET_USER_LOGIN_FETCHING',
  GOOGLE_USER_LOGIN_REQUEST = '[GLOBAL_STATE] GOOGLE_USER_LOGIN_REQUEST',
  GOOGLE_USER_LOGIN_SUCCESS = '[GLOBAL_STATE] GOOGLE_USER_LOGIN_SUCCESS',
  GOOGLE_USER_LOGIN_FAILURE = '[GLOBAL_STATE] GOOGLE_USER_LOGIN_FAILURE',
  USER_ME_REQUEST = '[GLOBAL_STATE] USER_ME_REQUEST',
  USER_ME_SUCCESS = '[GLOBAL_STATE] USER_ME_SUCCESS',
  USER_ME_FAILURE = '[GLOBAL_STATE] USER_ME_FAILURE',
  USER_RESET_PASSWORD_REQUEST = '[GLOBAL_STATE] USER_RESET_PASSWORD_REQUEST',
  USER_RESET_PASSWORD_SUCCESS = '[GLOBAL_STATE] USER_RESET_PASSWORD_SUCCESS',
  USER_RESET_PASSWORD_FAILURE = '[GLOBAL_STATE] USER_RESET_PASSWORD_FAILURE',
  USER_LOGOUT = '[GLOBAL_STATE] USER_LOGOUT',
  USER_TOKEN_EXPIRE = '[GLOBAL_STATE] USER_TOKEN_EXPIRE',
  SEND_NOTIFICATION = '[GLOBAL_STATE] SEND_NOTIFICATION',
  SEND_NOTIFICATIONS = '[GLOBAL_STATE] SEND_NOTIFICATIONS',
  CLEAR_NOTIFICATIONS = '[GLOBAL_STATE] CLEAR_NOTIFICATIONS',
  REMOVE_NOTIFICATION = '[GLOBAL_STATE] REMOVE_NOTIFICATION',
  SET_CONFIRMATION_MODAL_PROPS = '[GLOBAL_STATE] SET_CONFIRMATION_MODAL_PROPS',
  OPEN_CONFIRMATION_MODAL = '[GLOBAL_STATE] OPEN_CONFIRMATION_MODAL',
  CLOSE_CONFIRMATION_MODAL = '[GLOBAL_STATE] CLOSE_CONFIRMATION_MODAL',
  GET_CAR_MAKES_REQUEST = '[GLOBAL_STATE] GET_CAR_MAKES_REQUEST',
  GET_CAR_MAKES_SUCCESS = '[GLOBAL_STATE] GET_CAR_MAKES_SUCCESS',
  GET_CAR_MAKES_FAILURE = '[GLOBAL_STATE] GET_CAR_MAKES_FAILURE',
}

export interface LoginRequestParams {
  email: string;
  password: string;
}

export interface GoogleLoginRequestParams {
  token: string;
}

export const Actions = {
  toggleSidePanelNav: () => createAction(ActionTypes.TOGGLE_SIDEPANEL_NAV),
  loginRequest: (loginRequest: LoginRequestParams) => createAction(ActionTypes.USER_LOGIN_REQUEST, loginRequest),
  loginFailure: (e: APIError) => createAction(ActionTypes.USER_LOGIN_FAILURE, e),
  setUserLoginFetching: (fetching: boolean) => createAction(ActionTypes.SET_USER_LOGIN_FETCHING, fetching),
  loginSuccess: (accessToken: string) => createAction(ActionTypes.USER_LOGIN_SUCCESS, accessToken),
  googleLoginRequest: (loginRequest: GoogleLoginRequestParams) =>
    createAction(ActionTypes.GOOGLE_USER_LOGIN_REQUEST, loginRequest),
  googleLoginFailure: (e: APIError) => createAction(ActionTypes.GOOGLE_USER_LOGIN_FAILURE, e),
  googleLoginSuccess: (accessToken: string) => createAction(ActionTypes.GOOGLE_USER_LOGIN_SUCCESS, accessToken),
  userMeRequest: () => createAction(ActionTypes.USER_ME_REQUEST),
  userMeSuccess: (userInfo: UserInfo, vendors?: Vendor[]) =>
    createAction(ActionTypes.USER_ME_SUCCESS, { userInfo, vendors }),
  userMeFailure: (e: APIError) => createAction(ActionTypes.USER_ME_FAILURE, e),
  userResetPasswordRequest: (resetPasswordRequest: ResetPasswordRequest) =>
    createAction(ActionTypes.USER_RESET_PASSWORD_REQUEST, resetPasswordRequest),
  userResetPasswordSuccess: () => createAction(ActionTypes.USER_RESET_PASSWORD_SUCCESS),
  userResetPasswordFailure: (e: ResetPasswordErrorType) => createAction(ActionTypes.USER_RESET_PASSWORD_FAILURE, e),
  userLogout: () => createAction(ActionTypes.USER_LOGOUT),
  sendNotification: (props: NotificationProps) => createAction(ActionTypes.SEND_NOTIFICATION, props),
  sendNotifications: (props: NotificationProps[]) => createAction(ActionTypes.SEND_NOTIFICATIONS, props),
  clearNotifications: () => createAction(ActionTypes.CLEAR_NOTIFICATIONS),
  removeNotification: (id: string) => createAction(ActionTypes.REMOVE_NOTIFICATION, id),
  setConfirmationModalProps: (props: Partial<ConfirmationModalProps>) =>
    createAction(ActionTypes.SET_CONFIRMATION_MODAL_PROPS, props),
  openConfirmationModal: (callback: (ok: boolean) => void) =>
    createAction(ActionTypes.OPEN_CONFIRMATION_MODAL, callback),
  closeConfirmationModal: (reset: boolean) => createAction(ActionTypes.CLOSE_CONFIRMATION_MODAL, reset),
  getCarMakesRequest: () => createAction(ActionTypes.GET_CAR_MAKES_REQUEST),
  getCarMakesSuccess: (carMakes: CarMakesResponse) => createAction(ActionTypes.GET_CAR_MAKES_SUCCESS, carMakes),
  getCarMakesFailure: () => createAction(ActionTypes.GET_CAR_MAKES_FAILURE),
  userTokenExpire: () => createAction(ActionTypes.USER_TOKEN_EXPIRE),
};

export type Actions = ActionsUnion<typeof Actions>;
