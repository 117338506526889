import { Reducer } from 'redux';
import { Actions, ActionTypes } from './actions';
import { initialState, ResetPasswordPageState } from './initial-state';

export const resetPasswordPageReducer: Reducer<ResetPasswordPageState> = (
  state = initialState,
  action: Actions
): ResetPasswordPageState => {
  switch (action.type) {
    case ActionTypes.RESET_PASSWORD_REQUEST: {
      return {
        ...state,
        isPasswordRequestFetching: true,
        email: action.payload,
      };
    }

    case ActionTypes.RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        isPasswordRequestFetching: false,
        isRequestSuccess: true,
      };
    }
    case ActionTypes.RESET_PASSWORD_FAILURE: {
      return {
        ...state,
        isPasswordRequestFetching: false,
      };
    }
    default: {
      return state;
    }
  }
};
