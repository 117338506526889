import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  RHFFileUploadDelete: {
    id: 'RHFFileUploadDelete',
    defaultMessage: 'Delete',
  },
  RHFFileUploadOpen: {
    id: 'RHFFileUploadOpen',
    defaultMessage: 'Open',
  },
  RHFFileUploadDownload: {
    id: 'RHFFileUploadDownload',
    defaultMessage: 'Download',
  },
});
