import { defineMessages } from 'react-intl';

export default defineMessages({
  ListingResultsPublished: {
    id: 'ListingResultsPublished',
    defaultMessage: 'Published',
  },
  ListingResultsDraft: {
    id: 'ListingResultsDraft',
    defaultMessage: 'Draft',
  },
  ListingResultsError: {
    id: 'ListingResultsError',
    defaultMessage: 'Error',
  },
  ListingResultsDeleted: {
    id: 'ListingResultsDeleted',
    defaultMessage: 'Deleted',
  },
  DealsResultsNameHeader: {
    id: 'DealsResultsNameHeader',
    defaultMessage: 'Name',
  },
  DealsResultsStatusHeader: {
    id: 'DealsResultsStatusHeader',
    defaultMessage: 'Status',
  },
  DealsResultsVehiclePriceHeader: {
    id: 'DealsResultsVehiclePriceHeader',
    defaultMessage: 'Vehicle price',
  },
  DealsResultsDateAddedHeader: {
    id: 'DealsResultsDateAddedHeader',
    defaultMessage: 'Date added',
  },
  DealsResultsGowagoID: {
    id: 'DealsResultsGowagoID',
    defaultMessage: 'Gowago ID',
  },
});
