import Card from 'components/Card';
import DataGrid from 'components/DataGrid';
import DataGridHeader from 'components/DataGrid/components/DataGridHeader';
import DataGridHeaderItem from 'components/DataGrid/components/DataGridHeaderItem';
import DataGridPagination from 'components/DataGrid/components/DataGridPagination';
import DataGridRow from 'components/DataGrid/components/DataGridRow';
import DataGridRowItem from 'components/DataGrid/components/DataGridRowItem';
import { DATE_FORMAT } from 'constants/constants';
import { routeConstants } from 'constants/routes';
import { useParams, useQuery, useUpdateQuery } from 'hooks/router';
import moment from 'moment';
import { RootState } from 'pages/_store/root-reducer';
import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { DefaultVendorParams, OfferForSalesPortal, OrdersQuery } from 'types';
import { compose } from 'utils/compose';
import { initialState, OrdersPageState } from '../store/initial-state';
import styles from './OrderResults.module.scss';
import Translations from './OrderResults.translations';

interface Props {
  onSearchTermChange: (term: string) => void;
}

const OrderResults: FunctionComponent<Props> = ({ onSearchTermChange }) => {
  const { formatMessage } = useIntl();
  const {
    result: { offers, countFiltered },
    query: { $limit, $skip },
    isFetchingResults,
  } = useSelector<RootState, OrdersPageState>((state) => state.ordersPage, shallowEqual);

  const query = useQuery<Partial<OrdersQuery>>({
    initialObj: initialState.query,
  });

  const { vendorId } = useParams<DefaultVendorParams>();

  const updateQuery = useUpdateQuery<OrdersQuery>({
    replace: true,
    initialState,
  });

  const getNavigationUrl = (query: Partial<OrdersQuery>) =>
    vendorId
      ? routeConstants.PROTECTED.VIEW_VENDOR_ORDERS.url(+vendorId, query)
      : routeConstants.PROTECTED.VIEW_ORDERS_PAGE.url(query);

  const prevLink = getNavigationUrl({
    ...query,
    $skip: $skip - $limit,
  });
  const nextLink = getNavigationUrl({
    ...query,
    $skip: $skip + $limit,
  });

  const generateStepLink = (page: number) => {
    return getNavigationUrl({
      ...query,
      $skip: $limit * page,
    });
  };

  const handleReset = () => {
    updateQuery({
      ...initialState.query,
    });
    onSearchTermChange(initialState.query.query);
  };

  const formatDealName = (offer: OfferForSalesPortal) => {
    let result = '';

    if (offer.dealId) {
      result += offer.dealId;
    } else if (offer.publicId) {
      result += offer.publicId;
    }

    if (offer?.customer?.firstName && offer?.customer?.lastName) {
      result += ` - ${offer.customer?.firstName} ${offer.customer?.lastName}`;
    }

    return result;
  };

  return (
    <div className={styles.root}>
      <Card>
        <DataGrid
          isLoading={isFetchingResults}
          isEmptyResult={!isFetchingResults && offers.length === 0}
          onButtonReset={handleReset}
        >
          <DataGridHeader>
            <DataGridHeaderItem size={20}>{formatMessage(Translations.OrderResultsHubspotDealId)}</DataGridHeaderItem>
            <DataGridHeaderItem size={30}>{formatMessage(Translations.OrderResultsDealName)}</DataGridHeaderItem>
            <DataGridHeaderItem size={30}>{formatMessage(Translations.OrderResultsMakeAndModel)}</DataGridHeaderItem>
            <DataGridHeaderItem size={20}>{formatMessage(Translations.OrderResultsDateCreated)}</DataGridHeaderItem>
          </DataGridHeader>
          {offers.map((offer: OfferForSalesPortal) => (
            <DataGridRow key={offer.hubspotDealId} to={routeConstants.PROTECTED.VIEW_ORDER_PAGE.url(offer.publicId)}>
              <DataGridRowItem size={20}>{offer.hubspotDealId || '-'}</DataGridRowItem>
              <DataGridRowItem size={30}>{formatDealName(offer) || '-'}</DataGridRowItem>
              <DataGridRowItem size={30}>
                {offer.make && offer.model ? `${offer.make} ${offer.model}` : '-'}
              </DataGridRowItem>
              <DataGridRowItem size={13}>
                {offer.createdAt ? moment(offer.createdAt).format(DATE_FORMAT) : '-'}
              </DataGridRowItem>
            </DataGridRow>
          ))}
          <DataGridPagination
            $total={countFiltered}
            $skip={$skip}
            $limit={$limit}
            generateStepLink={generateStepLink}
            nextLink={nextLink}
            prevLink={prevLink}
          />
        </DataGrid>
      </Card>
    </div>
  );
};

export default compose<Props>(OrderResults);
