import { defineMessages } from 'react-intl';

export default defineMessages({
  FileUploadDnDPlaceholderUnsupportedFileFormat: {
    id: 'FileUploadDnDPlaceholderUnsupportedFileFormat',
    defaultMessage: 'Unsupported file format',
  },
  FileUploadDnDPlaceholderTheSelectedFileIsTooLarge: {
    id: 'FileUploadDnDPlaceholderTheSelectedFileIsTooLarge',
    defaultMessage: 'The selected file is too large',
  },
});
