import { OrdersQuery, OrdersQueryResult } from 'types';

export interface OrdersPageState {
  result: OrdersQueryResult;
  query: OrdersQuery;
  isFetchingResults: boolean;
}

export const initialState: OrdersPageState = {
  result: {
    offers: [],
    $skip: 0,
    $limit: 20,
    countTotal: 0,
    countFiltered: 0,
  },
  query: {
    query: '',
    $skip: 0,
    $limit: 20,
  },
  isFetchingResults: false,
};
