import { Vendor } from 'types';
import { ActionsUnion, createAction } from 'utils/action-utils';
import { VendorInformationFilters } from './initial-state';
import { VendorInformationFormData } from './types';

export enum ActionTypes {
  VIEW_VENDOR_INFO_REQUEST = '[VENDOR_INFORMATION] VIEW_VENDOR_INFO_REQUEST',
  VIEW_VENDOR_INFO_SUCCESS = '[VENDOR_INFORMATION] VIEW_VENDOR_INFO_SUCCESS',
  VIEW_VENDOR_INFO_FAILURE = '[VENDOR_INFORMATION] VIEW_VENDOR_INFO_FAILURE',
  UPDATE_VENDOR_INFO_REQUEST = '[VENDOR_INFORMATION] UPDATE_VENDOR_INFO_REQUEST',
  UPDATE_VENDOR_INFO_SUCCESS = '[VENDOR_INFORMATION] UPDATE_VENDOR_INFO_SUCCESS',
  UPDATE_VENDOR_INFO_FAILURE = '[VENDOR_INFORMATION] UPDATE_VENDOR_INFO_FAILURE',
  HIDE_SUCCESS_ALERT_MESSAGE = '[VENDOR_INFORMATION] HIDE_SUCCESS_ALERT_MESSAGE',
  RESET_STATE = '[VENDOR_INFORMATION] RESET_STATE',
}

export const Actions = {
  viewVendorInfoRequest: (filters: Partial<VendorInformationFilters>) =>
    createAction(ActionTypes.VIEW_VENDOR_INFO_REQUEST, filters),
  viewVendorInfoSuccess: (vendor: Vendor) => createAction(ActionTypes.VIEW_VENDOR_INFO_SUCCESS, vendor),
  viewVendorInfoFailure: () => createAction(ActionTypes.VIEW_VENDOR_INFO_FAILURE),
  updateVendorInfoRequest: (vendorInfoData: VendorInformationFormData) =>
    createAction(ActionTypes.UPDATE_VENDOR_INFO_REQUEST, vendorInfoData),
  updateVendorInfoSuccess: () => createAction(ActionTypes.UPDATE_VENDOR_INFO_SUCCESS),
  updateVendorInfoFailure: (isError: boolean) => createAction(ActionTypes.UPDATE_VENDOR_INFO_FAILURE, { isError }),
  hideSuccessAlertMessage: () => createAction(ActionTypes.HIDE_SUCCESS_ALERT_MESSAGE),
  resetState: () => createAction(ActionTypes.RESET_STATE),
};

export type Actions = ActionsUnion<typeof Actions>;
