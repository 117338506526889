import messages from 'i18n/translated-messages.json';
import { FC, useCallback, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { getLocale, KEYS } from 'utils/local-storage';

const IntlProviderWrapper: FC = ({ children }) => {
  const [locale, setLocale] = useState(getLocale());

  const setLocaleCallback = useCallback((event: CustomEvent) => setLocale(event.detail), []);

  useEffect(() => {
    window.addEventListener(KEYS.LOCALE as any, setLocaleCallback);

    return () => {
      window.removeEventListener(KEYS.LOCALE as any, setLocaleCallback);
    };
  }, [setLocaleCallback]);

  return (
    <IntlProvider
      locale={locale}
      defaultLocale={locale}
      messages={messages[locale]}
      onError={() => {
        return;
      }}
    >
      {children}
    </IntlProvider>
  );
};

export default IntlProviderWrapper;
