import { createStyles } from '@material-ui/core';
import { GowagoTheme } from 'theme';

export const styles = (theme: GowagoTheme) =>
  createStyles({
    root: {
      borderRadius: '5px',
      border: '1px solid #ced4da',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      minHeight: '36px',
      marginBottom: theme.gowago.spacing.rem,
      '&.error': {
        border: '1px solid #e53934',
      },
      '&:hover': {
        border: '1px solid #2196f3',
      },
      '&>div': {
        width: '100%',
      },
    },
    disabledRoot: {
      background: '#E9ECEF',
    },
    inputAdournment: {
      height: 36,
      width: 36,
      backgroundColor: '#e9ecef',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    indicatorSeparator: {
      display: 'none',
    },

    label: {
      display: 'inline-block',
      marginBottom: '0.5rem',
      boxSizing: 'border-box',
      cursor: 'default',
      color: theme.gowago.text.primary,
      fontSize: 14,
      lineHeight: '19px',
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
    },

    select: {
      paddingLeft: 10,
      '&:focus': {
        backgroundColor: 'white!important',
      },
    },

    errorLabel: {
      fontSize: theme.gowago.fontSize.small,
      marginTop: 5,
      color: '#fd5252',
    },

    caretDownIcon: {
      fontSize: theme.gowago.fontSize.smaller,
      marginRight: 12,
    },
    error: {},
    required: {
      fontWeight: 'bold',
    },
  });
