import { faFile } from '@fortawesome/free-solid-svg-icons';
import { faEllipsisH, faFileCheck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StyledComponentProps, Typography, withStyles, WithStyles } from '@material-ui/core';
import clsx from 'clsx';
import { FC, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { compose } from 'utils/compose';
import { getMaxLengthString } from 'utils/string';
import { MimeTypes } from '../FileUpload.utils';
import { styles } from './FilePreview.styles';
import { loadImage } from './FilePreview.utils';

export interface Props {
  files: File[] | string[];
  width: number;
  height: number;
  renderLoading?: () => JSX.Element;
  onLoad?: (src: string) => void;
}

const FilePreview: FC<Props & WithStyles<typeof styles>> = ({
  classes,
  files,
  width,
  height,
  renderLoading,
  onLoad,
}) => {
  const [src, setSrc] = useState<string>();
  const [loading, setLoading] = useState(!src);

  const file = files[0];

  const handleSetSrc = (loadedSrc: string) => {
    setSrc(loadedSrc);

    if (onLoad) {
      onLoad(loadedSrc);
    }
  };

  useEffect(() => {
    if (file) {
      setSrc(null);
      setLoading(true);
      if (typeof file !== 'string') {
        loadImage(file, handleSetSrc);
      } else {
        setSrc(file as string);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  useEffect(() => {
    if (src) {
      setLoading(false);
    }
  }, [src]);

  const fileAsFile = file as File;
  const isPDF = fileAsFile?.type === MimeTypes.PDF;
  const isXML = fileAsFile?.type === MimeTypes.XML;
  const isImg = Object.values(MimeTypes).includes(fileAsFile?.type as any) && !isPDF && !isXML;

  return file ? (
    <div className={clsx('FilePreview', classes.root)}>
      {src ? (
        isImg ? (
          <div className={classes.imgProgressiveWrapper}>
            <img width={width} height={height} src={src} alt="preview" className={classes.img} loading="lazy" />
          </div>
        ) : (
          <div className={classes.filePlaceholder}>
            <FontAwesomeIcon icon={isPDF ? faFileCheck : faFile} className={classes.fileIcon} />
            <Typography className={classes.fileText}>
              {getMaxLengthString({
                value: fileAsFile.name,
                maxLength: 30,
                fromBehind: true,
              })}
            </Typography>
          </div>
        )
      ) : loading && renderLoading ? (
        renderLoading()
      ) : (
        <div className={classes.loading}>
          <FontAwesomeIcon icon={faEllipsisH} className={classes.loadingIcon} />
          <Typography className={classes.loadingText}>
            <FormattedMessage id="FilePreviewUploading" defaultMessage="Uploading" />
          </Typography>
        </div>
      )}
    </div>
  ) : null;
};

export default compose<Props & StyledComponentProps>(FilePreview, withStyles(styles, { name: 'ImageFilePreview' }));
