import { FC } from 'react';
import { useIntl } from 'react-intl';
import GoogleLoginButton from '../../../components/Forms/ButtonGoogleAuth/ButtonGoogleAuth';
import PublicHeader from '../SharedComponents/PublicHeader';
import styles from './Login.module.scss';
import messages from './LoginPage.translations';

const Login: FC = () => {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.root}>
      <PublicHeader
        title={formatMessage(messages.LoginPageWelcomeBack)}
        subTitle={formatMessage(messages.LoginPageSignInToYourAccountToContinue)}
      />
      <div className={styles.googleLoginButtonCard}>
        <GoogleLoginButton />
      </div>
    </div>
  );
};

export default Login;
