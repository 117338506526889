import { getVendorsOfSeller } from 'api/seller';
import { me, registerSeller, validateToken } from 'api/user';
import { SOMETHING_WENT_WRONG } from 'constants/errors';
import { Actions, Actions as GlobalActions } from 'pages/_store/global/actions';
import { RootState } from 'pages/_store/root-reducer';
import { fork, put, takeEvery } from 'redux-saga/effects';
import { call, select } from 'typed-redux-saga';
import { Vendor } from 'types';
import { Actions as RegisterPageActions, ActionTypes as RegisterPageActionTypes } from './actions';
import { SellerRegistrationData } from './initial-state';

const getSellerData = (state: RootState): SellerRegistrationData => state.registrationPage.sellerData;
function* validateSellerTokenAsync(action: ReturnType<typeof RegisterPageActions.validateTokenRequest>) {
  try {
    const resp = yield* call(validateToken, action.payload);
    yield put(RegisterPageActions.validateTokenSuccess(resp));
  } catch (e) {
    yield put(RegisterPageActions.validateTokenFailure(e));
  }
}

function* registerSellerAsync(action: ReturnType<typeof RegisterPageActions.registerSellerRequest>) {
  try {
    const sellerData: SellerRegistrationData = yield select(getSellerData);
    const resp = yield* call(registerSeller, {
      ...sellerData,
      ...action.payload,
    });
    yield put(RegisterPageActions.registerSellerSuccess());
    yield put(Actions.loginSuccess(resp.accessToken));
    const userMeResp = yield* call(me);
    let sellerVendors: Vendor[];
    if (!userMeResp.roles) {
      sellerVendors = yield* call(getVendorsOfSeller, userMeResp.id);
    }
    yield put(GlobalActions.userMeSuccess(userMeResp, sellerVendors));
  } catch (e) {
    yield put(RegisterPageActions.registerSellerFailure(e));
    yield put(
      GlobalActions.sendNotification({
        message: e?.message || SOMETHING_WENT_WRONG,
        variant: 'error',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'top',
        },
      })
    );
  }
}

function* watchValidateSellerTokenAsync() {
  yield takeEvery(RegisterPageActionTypes.VALIDATE_TOKEN_REQUEST, validateSellerTokenAsync);
}

function* watchRegiserSellerAsync() {
  yield takeEvery(RegisterPageActionTypes.REGISTER_SELLER_REQUEST, registerSellerAsync);
}

export default [fork(watchValidateSellerTokenAsync), fork(watchRegiserSellerAsync)];
