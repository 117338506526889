import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input, InputProps, StyledComponentProps, WithStyles, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import React, { ChangeEventHandler, FC, KeyboardEventHandler, useMemo, useState } from 'react';
import { FieldError, useFormContext } from 'react-hook-form';
import ReactTooltip from 'react-tooltip';
import { compose } from 'utils/compose';
import { v4 as uuid } from 'uuid';
import FieldErrorLabel from '../FieldErrorLabel';
import { styles } from './RHFInput.styles';

type CustomErrorRenderer = (value: any, error: boolean, dirty: boolean) => React.ReactElement;

interface Props extends InputProps {
  startAdornment?: IconProp | string;
  touched?: boolean;
  tooltip?: string;
  classes?: {
    root?: string;
    input?: string;
    componentRoot?: string;
  };
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  integer?: boolean;
  label: string;
  name: string;
  errorLabel?: FieldError;
  customErrorLabel?: CustomErrorRenderer;
  footerLabel?: string;
  required?: boolean;
  labelClassName?: string;
  min?: number;
  max?: number;
}

const RHFInput: FC<Props & WithStyles<typeof styles>> = ({
  startAdornment,
  touched,
  onBlur,
  disabled,
  classes,
  tooltip,
  onChange,
  name,
  label,
  footerLabel,
  labelClassName,
  required = false,
  integer = false,
  errorLabel,
  customErrorLabel,
  min,
  max,
  ...props
}) => {
  const { register } = useFormContext();
  const cx = classNames.bind(styles);
  const [isFocused, setInputFocus] = useState(false);
  const [isDirty, setDirty] = useState(false);
  const tooltipUuid = useMemo(uuid, []);

  const handleFocus = () => {
    setInputFocus(true);
  };
  const handleBlur = (e: React.FocusEvent<any>) => {
    setInputFocus(false);
    if (onBlur) {
      onBlur(e);
    }
  };

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (integer && (e.key === '.' || e.key === ',')) {
      e.preventDefault();
      return;
    }

    if (props?.onKeyDown) {
      props?.onKeyDown(e);
    }
  };

  const onInput: ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.value;
    if (integer && !isNaN(value as any)) {
      e.target.value = Number.parseInt(value)?.toString();
    }

    if (props?.onInput) {
      props?.onInput(e);
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e);
    }
    if (!isDirty) {
      setDirty(true);
    }
  };

  const onScroll = (e: React.WheelEvent<HTMLInputElement> & { target: HTMLInputElement }) => {
    e?.target?.blur();
  };

  return (
    <div className={classNames(classes.componentRoot, classes?.componentRoot)}>
      <label className={classNames(classes.label, labelClassName, { [classes.required]: required })}>
        {label}
        {required ? ` *` : ''}
      </label>
      <div
        className={cx(
          classes.root,
          {
            active: isFocused,
            error: !!errorLabel?.message,
            disabled,
            withInputAdornment: !!startAdornment,
          },
          classes.root
        )}
        data-tip={tooltip}
        data-for={tooltipUuid}
      >
        {!!startAdornment && (
          <div
            className={cx(classes.inputAdornment, {
              start: true,
              isText: typeof startAdornment === 'string',
            })}
          >
            {typeof startAdornment === 'string' ? (
              startAdornment
            ) : (
              <FontAwesomeIcon icon={startAdornment} className={classes.adormentIcon} />
            )}
          </div>
        )}
        <Input
          {...props}
          name={name}
          className={cx(classes.input, classes.input)}
          readOnly={disabled}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onWheel={onScroll}
          onChange={handleOnChange}
          inputRef={register}
          disableUnderline
          onKeyDown={onKeyDown}
          onInput={onInput}
        />
      </div>
      <ReactTooltip key={tooltip} effect="solid" id={tooltipUuid} />
      <FieldErrorLabel
        dirty={isDirty}
        touched={true}
        value={props.value}
        customErrorLabel={customErrorLabel}
        error={!!errorLabel?.message}
        errorLabel={errorLabel?.message || ''}
      />
      {!!footerLabel && <div className={classes.footerLabel}>{footerLabel}</div>}
    </div>
  );
};

export default compose<Props & StyledComponentProps>(RHFInput, withStyles(styles, { name: 'RHFInput' }));
