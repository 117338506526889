export const getKeyValueRows = (service: Record<string, Record<string, number>>) => {
  const resultMap = new Map<string, number[]>();

  Object.values(service).forEach((kmCost) => {
    Object.entries(kmCost).forEach(([km, cost]) => {
      const resultSetValues = resultMap.get(km) || [];
      resultSetValues.push(cost);
      resultMap.set(km, resultSetValues);
    });
  });

  const resultArray = Array.from(resultMap).map(([km, costs]) => ({
    km,
    costs: costs as (number | null)[],
  }));

  const totalColumns = Object.keys(service).length;

  for (const map of resultArray) {
    while (map.costs.length < totalColumns) {
      map.costs.push(null);
    }
  }

  return resultArray;
};
