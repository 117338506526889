import { useNavigate } from 'hooks/router';
import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { compose } from 'utils/compose';
import PublicHeader from '../PublicHeader';
import styles from './SuccessCard.module.scss';
import Translations from './SuccessCard.translations';

interface Props {
  title: string;
  subTitle?: string;
}

const SuccessCard: FunctionComponent<Props> = ({ title, subTitle }) => {
  const { formatMessage } = useIntl();
  const visit = useNavigate('/');
  const handleNavigate = () => visit('/');
  return (
    <div className={styles.root}>
      <PublicHeader title={title} subTitle={subTitle} />

      <button type="submit" className={styles.submitButton} onClick={handleNavigate}>
        {formatMessage(Translations.SuccessCardBackToSignIn)}
      </button>
    </div>
  );
};

export default compose<Props>(SuccessCard);
