import { defineMessages } from 'react-intl';

export default defineMessages({
  DealsFilterPanelSearchForPlaceholder: {
    id: 'DealsFilterPanelSearchForPlaceholder',
    defaultMessage: 'Search for...',
  },
  DealsFilterPanelFilterListingsHeader: {
    id: 'DealsFilterPanelFilterListingsHeader',
    defaultMessage: 'Filter Listings',
  },
  DealsFilterPanelStatusSubHeader: {
    id: 'DealsFilterPanelStatusSubHeader',
    defaultMessage: 'Status',
  },
  DealsFilterPanelPublishedLabel: {
    id: 'DealsFilterPanelPublishedLabel',
    defaultMessage: 'Published',
  },
  DealsFilterPanelDraftLabel: {
    id: 'DealsFilterPanelDraftLabel',
    defaultMessage: 'Draft',
  },
  DealsFilterPanelErrorLabel: {
    id: 'DealsFilterPanelErrorLabel',
    defaultMessage: 'Error',
  },
  DealsFilterPanelTrashLabel: {
    id: 'DealsFilterPanelTrashLabel',
    defaultMessage: 'Trash',
  },
  DealsFilterPanelSourcesSubHeader: {
    id: 'DealsFilterPanelSourcesSubHeader',
    defaultMessage: 'Sources',
  },
  DealsFilterPanelPromotions: {
    id: 'DealsFilterPanelPromotions',
    defaultMessage: 'Promotions',
  },
  DealsFilterPanelClosedUserGroup: {
    id: 'DealsFilterPanelClosedUserGroup',
    defaultMessage: 'Closed user group',
  },
  DealsFilterPanelHotDeals: {
    id: 'DealsFilterPanelHotDeals',
    defaultMessage: 'Hot deals',
  },
  DealsFilterPanelGowagoSalesPage: {
    id: 'DealsFilterPanelGowagoSalesPage',
    defaultMessage: 'Gowago Sales Page',
  },
  DealsFilterPanelFeaturedDeals: {
    id: 'DealsFilterPanelFeaturedDeals',
    defaultMessage: 'Featured deals',
  },
});
