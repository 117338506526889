import { defineMessages } from 'react-intl';

export interface GetValidCantonArgs {
  filterCanton: string;
  userCanton: string;
}

export const DEFAULT_CANTON = 'ZH';
export const DEFAULT_POSTCODE = '8000';

export const messages = defineMessages({
  CantonAG: {
    defaultMessage: 'Aargau',
    id: 'CantonAG',
  },
  CantonAR: {
    defaultMessage: 'Appenzell Ausserrhoden',
    id: 'CantonAR',
  },
  CantonAI: {
    defaultMessage: 'Appenzell Innerrhoden',
    id: 'CantonAI',
  },
  CantonBL: {
    defaultMessage: 'Basel-Landschaft',
    id: 'CantonBL',
  },
  CantonBS: {
    defaultMessage: 'Basel-Stadt',
    id: 'CantonBS',
  },
  CantonBE: {
    defaultMessage: 'Bern',
    id: 'CantonBE',
  },
  CantonFR: {
    defaultMessage: 'Fribourg',
    id: 'CantonFR',
  },
  CantonGE: {
    defaultMessage: 'Geneva',
    id: 'CantonGE',
  },
  CantonGL: {
    defaultMessage: 'Glarus',
    id: 'CantonGL',
  },
  CantonGR: {
    defaultMessage: 'Grisons',
    id: 'CantonGR',
  },
  CantonJU: {
    defaultMessage: 'Jura',
    id: 'CantonJU',
  },
  CantonLU: {
    defaultMessage: 'Luzern',
    id: 'CantonLU',
  },
  CantonNE: {
    defaultMessage: 'Neuchâtel',
    id: 'CantonNE',
  },
  CantonNW: {
    defaultMessage: 'Nidwalden',
    id: 'CantonNW',
  },
  CantonOW: {
    defaultMessage: 'Obwalden',
    id: 'CantonOW',
  },
  CantonSH: {
    defaultMessage: 'Schaffhausen',
    id: 'CantonSH',
  },
  CantonSZ: {
    defaultMessage: 'Schwyz',
    id: 'CantonSZ',
  },
  CantonSO: {
    defaultMessage: 'Solothurn',
    id: 'CantonSO',
  },
  CantonSG: {
    defaultMessage: 'St. Gallen',
    id: 'CantonSG',
  },
  CantonTG: {
    defaultMessage: 'Thurgau',
    id: 'CantonTG',
  },
  CantonTI: {
    defaultMessage: 'Ticino',
    id: 'CantonTI',
  },
  CantonUR: {
    defaultMessage: 'Uri',
    id: 'CantonUR',
  },
  CantonVS: {
    defaultMessage: 'Valais',
    id: 'CantonVS',
  },
  CantonVD: {
    defaultMessage: 'Vaud',
    id: 'CantonVD',
  },
  CantonZG: {
    defaultMessage: 'Zug',
    id: 'CantonZG',
  },
  CantonZH: {
    defaultMessage: 'Zürich',
    id: 'CantonZH',
  },
});

export const cantonsMap = {
  AG: messages.CantonAG,
  AR: messages.CantonAR,
  AI: messages.CantonAI,
  BL: messages.CantonBL,
  BS: messages.CantonBS,
  BE: messages.CantonBE,
  FR: messages.CantonFR,
  GE: messages.CantonGE,
  GL: messages.CantonGL,
  GR: messages.CantonGR,
  JU: messages.CantonJU,
  LU: messages.CantonLU,
  NE: messages.CantonNE,
  NW: messages.CantonNW,
  OW: messages.CantonOW,
  SH: messages.CantonSH,
  SZ: messages.CantonSZ,
  SO: messages.CantonSO,
  SG: messages.CantonSG,
  TG: messages.CantonTG,
  TI: messages.CantonTI,
  UR: messages.CantonUR,
  VS: messages.CantonVS,
  VD: messages.CantonVD,
  ZG: messages.CantonZG,
  ZH: messages.CantonZH,
};

const cantons = [
  {
    label: messages.CantonAG,
    value: 'AG',
  },
  {
    label: messages.CantonAR,
    value: 'AR',
  },
  {
    label: messages.CantonAI,
    value: 'AI',
  },
  {
    label: messages.CantonBL,
    value: 'BL',
  },
  {
    label: messages.CantonBS,
    value: 'BS',
  },
  {
    label: messages.CantonBE,
    value: 'BE',
  },
  {
    label: messages.CantonFR,
    value: 'FR',
  },
  {
    label: messages.CantonGE,
    value: 'GE',
  },
  {
    label: messages.CantonGL,
    value: 'GL',
  },
  {
    label: messages.CantonGR,
    value: 'GR',
  },
  {
    label: messages.CantonJU,
    value: 'JU',
  },
  {
    label: messages.CantonLU,
    value: 'LU',
  },
  {
    label: messages.CantonNE,
    value: 'NE',
  },
  {
    label: messages.CantonNW,
    value: 'NW',
  },
  {
    label: messages.CantonOW,
    value: 'OW',
  },
  {
    label: messages.CantonSH,
    value: 'SH',
  },
  {
    label: messages.CantonSZ,
    value: 'SZ',
  },
  {
    label: messages.CantonSO,
    value: 'SO',
  },
  {
    label: messages.CantonSG,
    value: 'SG',
  },
  {
    label: messages.CantonTG,
    value: 'TG',
  },
  {
    label: messages.CantonTI,
    value: 'TI',
  },
  {
    label: messages.CantonUR,
    value: 'UR',
  },
  {
    label: messages.CantonVS,
    value: 'VS',
  },
  {
    label: messages.CantonVD,
    value: 'VD',
  },
  {
    label: messages.CantonZG,
    value: 'ZG',
  },
  {
    label: messages.CantonZH,
    value: 'ZH',
  },
];

export const getCantonOptions = (formatMessage: any) => {
  return cantons.map((cantonObj) => {
    return {
      label: formatMessage(cantonObj.label),
      value: cantonObj.value,
    };
  });
};

export const getSelectedCanton = (selectedCanton: string) => cantons.find((canton) => canton.value === selectedCanton);

export const isCantonValid = (selectedCanton: string) => (getSelectedCanton(selectedCanton) ? true : false);

/**
 * Returns valid the valid canton
 */
export const getValidCanton = ({ filterCanton, userCanton }: GetValidCantonArgs) => {
  let validCanton = userCanton;
  const isNewCantonValid = isCantonValid(filterCanton);

  if (filterCanton && isNewCantonValid) {
    validCanton = filterCanton;
  } else if ((filterCanton && !isNewCantonValid) || filterCanton === '') {
    validCanton = DEFAULT_CANTON;
  }

  return validCanton;
};

export default cantons;
