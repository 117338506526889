import Alert, { AlertType } from 'components/Alerts/Alert';
import ErrorMessage from 'components/Alerts/ErrorMessage';
import { RootState } from 'pages/_store/root-reducer';
import { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Actions as OrderActions } from '../store/actions';
import styles from './Notifications.module.scss';
import Translations from './Notifications.translations';

const Notifications = () => {
  const {
    order: { updateSuccess, errorMessages },
  } = useSelector(
    (state: RootState) => ({
      order: state.orderPage,
    }),
    shallowEqual
  );
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const onSuccessSavedMessageClose = () => {
    dispatch(OrderActions.resetSuccess());
  };

  return updateSuccess ? (
    <div className={styles.messageContainer}>
      <Alert
        type={AlertType.SUCCESS}
        message={formatMessage(Translations.OrderNotificationsListingWasSaved)}
        onClose={onSuccessSavedMessageClose}
      />
    </div>
  ) : errorMessages?.length ? (
    <ErrorMessage
      title={formatMessage(Translations.AddEditListingsNotificationsOrderCouldNotBeUpdatedDueTo)}
      classes={{ root: styles.messageContainer }}
    >
      <ul className={styles.dash}>
        {errorMessages.map((errMsg) => (
          <li key={errMsg}>{errMsg}</li>
        ))}
      </ul>
    </ErrorMessage>
  ) : (
    <Fragment />
  );
};

export default Notifications;
