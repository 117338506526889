import { changePassword } from 'api/user';
import { fork, put, select, takeEvery } from 'redux-saga/effects';
import { call } from 'typed-redux-saga';
import { User } from '../../../../_store/global/initial-state';
import { RootState } from '../../../../_store/root-reducer';
import { Actions as ChangePasswordActions, ActionTypes as ChangePasswordActionTypes } from './actions';

const getUser = (state: RootState): User => state.globalAppState.user;

function* changePasswordAsync(action: ReturnType<typeof ChangePasswordActions.changePasswordRequest>) {
  try {
    const user = yield select(getUser);
    yield* call(changePassword, user.id, user.email, action.payload);
    yield put(ChangePasswordActions.changePasswordSuccess());
  } catch (e) {
    yield put(ChangePasswordActions.changePasswordFailure(e.statusCode));
  }
}

function* watchResetPasswordRequestAsync() {
  yield takeEvery(ChangePasswordActionTypes.CHANGE_PASSWORD_REQUEST, changePasswordAsync);
}

export default [fork(watchResetPasswordRequestAsync)];
