import { defineMessages } from 'react-intl';

export default defineMessages({
  SubmitButtonsStatusLabel: {
    id: 'SubmitButtonsStatusLabel',
    defaultMessage: 'Status: {value}',
  },
  SubmitButtonsStatusValueUnsaved: {
    id: 'SubmitButtonsStatusValueUnsaved',
    defaultMessage: 'Unsaved',
  },
  SubmitButtonsStatusValuePublished: {
    id: 'SubmitButtonsStatusValuePublished',
    defaultMessage: 'Published',
  },
  SubmitButtonsStatusValueTrash: {
    id: 'SubmitButtonsStatusValueTrash',
    defaultMessage: 'Trash',
  },
  SubmitButtonsStatusValueError: {
    id: 'SubmitButtonsStatusValueError',
    defaultMessage: 'Error',
  },
  SubmitButtonsStatusValueDraft: {
    id: 'SubmitButtonsStatusValueDraft',
    defaultMessage: 'Draft',
  },
  SubmitButtonsVisibilityLabel: {
    id: 'SubmitButtonsVisibilityLabel',
    defaultMessage: 'Visibility: {value}',
  },
  SubmitButtonsVisibilityPrivateUnlistedValue: {
    id: 'SubmitButtonsVisibilityPrivateUnlistedValue',
    defaultMessage: 'Private (unlisted)',
  },
  SubmitButtonsVisibilityPublicListed: {
    id: 'SubmitButtonsVisibilityPublicListed',
    defaultMessage: 'Public (listed)',
  },
  SubmitButtonsSaveDraft: {
    id: 'SubmitButtonsSaveDraft',
    defaultMessage: 'Save draft',
  },
  SubmitButtonsDuplicate: {
    id: 'SubmitButtonsDuplicate',
    defaultMessage: 'Duplicate',
  },
  SubmitButtonsPreview: {
    id: 'SubmitButtonsPreview',
    defaultMessage: 'Preview',
  },
  SubmitButtonsMoveToTrash: {
    id: 'SubmitButtonsMoveToTrash',
    defaultMessage: 'Move to trash',
  },
  SubmitButtonsPublish: {
    id: 'SubmitButtonsPublish',
    defaultMessage: 'Publish',
  },
  SubmitButtonsUpdate: {
    id: 'SubmitButtonsUpdate',
    defaultMessage: 'Update',
  },
  SubmitButtonsRequiredInformation: {
    id: 'SubmitButtonsRequiredInformation',
    defaultMessage: '* Required information',
  },
  SubmitButtonsChangeStatus: {
    id: 'SubmitButtonsChangeStatus',
    defaultMessage: 'Change status',
  },
});
