import qs from 'qs';
import { getJWTToken } from 'utils/cookies';

const apiRoot = String(process.env.REACT_APP_API_ROOT);

export enum HttpMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

const ErrorsConstants = {
  UNREADABLE_JSON_ERROR: {
    statusCode: 666,
    message: 'Unreadable JSON Object',
  },
  TIMEOUT: {
    statusCode: 408,
    message: 'Timeout',
  },
  FAILED_TO_FETCH: {
    statusCode: 503,
    message: 'Failed to fetch',
  },
};

interface ApiClientOptions {
  queryParams?: object;
  header?: object;
  isFormData?: boolean;
  noBody?: boolean;
  apiUrl?: string;
}

class ApiClient {
  private onTimeout: (() => void) | undefined;

  initialize(onTimeout: () => void) {
    this.onTimeout = onTimeout;
  }

  getDefaultHeader(isFormData: boolean) {
    const result = {
      Accept: 'application/json',
      Authorization: `Bearer ${getJWTToken()}`,
    };

    if (!isFormData) {
      return {
        'Content-Type': 'application/json; charset=utf-8',
        ...result,
      };
    }

    return result;
  }

  getFileUploadHeader() {
    return {
      Accept: 'application/json',
      Authorization: `Bearer ${getJWTToken()}`,
    };
  }

  async get<T>(
    url: string,
    { queryParams, header, noBody, isFormData = false, apiUrl = apiRoot }: ApiClientOptions = {}
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      const queryString = queryParams
        ? qs.stringify(queryParams, {
            encode: false,
          })
        : '';

      const fetchUrl = queryString ? `${apiUrl}${url}?${queryString}` : `${apiUrl}${url}`;

      this.fetchWrapper(fetchUrl, {
        method: HttpMethod.GET,
        headers: {
          ...this.getDefaultHeader(isFormData),
          ...header,
        },
      }).then((result) => {
        if (noBody) return;

        const res = result as Response;
        if (res.ok) {
          res
            .json()
            .then((resp) => resolve(resp))
            .catch((e) => reject(ErrorsConstants.UNREADABLE_JSON_ERROR));
        } else {
          res.json().then((resp) => reject(resp));
        }
      });
    });
  }

  async post<T>(
    url: string,
    body: any,
    { header, noBody, isFormData = false, apiUrl = apiRoot }: ApiClientOptions = {}
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      const fetchUrl = `${apiUrl}${url}`;
      this.fetchWrapper(fetchUrl, {
        method: HttpMethod.POST,
        headers: {
          ...this.getDefaultHeader(isFormData),
          ...header,
        },
        body: isFormData ? body : JSON.stringify(body),
      }).then((result) => {
        const res = result as Response;

        if (noBody) {
          res.ok ? resolve(true as any) : reject();
          return;
        }

        if (res.ok) {
          res
            .json()
            .then((resp) => resolve(resp))
            .catch((e) => {
              if (res.ok) {
                resolve(true as any);
              } else {
                reject(ErrorsConstants.UNREADABLE_JSON_ERROR);
              }
            });
        } else {
          res
            .json()
            .then((resp) => reject(resp))
            .catch((e) => reject(ErrorsConstants.UNREADABLE_JSON_ERROR));
        }
      });
    });
  }

  async uploadFormData<T>(
    url: string,
    body: FormData,
    { header, noBody, apiUrl = apiRoot }: ApiClientOptions = {}
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      const fetchUrl = `${apiUrl}${url}`;
      this.fetchWrapper(fetchUrl, {
        method: HttpMethod.POST,
        headers: {
          ...this.getFileUploadHeader(),
          ...header,
        },
        body,
      }).then((result) => {
        if (noBody) return;

        const res = result as Response;
        if (res.ok) {
          res
            .json()
            .then((resp) => resolve(resp))
            .catch((e) => reject(ErrorsConstants.UNREADABLE_JSON_ERROR));
        } else {
          res
            .json()
            .then((resp) => reject(resp))
            .catch((e) => reject(ErrorsConstants.UNREADABLE_JSON_ERROR));
        }
      });
    });
  }

  async put<T>(
    url: string,
    body: any,
    { header, noBody, isFormData = false, apiUrl = apiRoot }: ApiClientOptions = {},
    deserializeResponse: boolean = true
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      const fetchUrl = `${apiUrl}${url}`;
      this.fetchWrapper(fetchUrl, {
        method: HttpMethod.PUT,
        headers: {
          ...this.getDefaultHeader(isFormData),
          ...header,
        },
        body: isFormData ? body : JSON.stringify(body),
      }).then((result) => {
        if (noBody) return;

        const res = result as Response;
        if (res.ok) {
          if (deserializeResponse) {
            res
              .json()
              .then((resp) => resolve(resp))
              .catch((e) => reject(ErrorsConstants.UNREADABLE_JSON_ERROR));
          } else {
            resolve(res as any);
          }
        } else {
          res
            .json()
            .then((resp) => reject(resp))
            .catch((e) => reject(ErrorsConstants.UNREADABLE_JSON_ERROR));
        }
      });
    });
  }

  async patch<T>(
    url: string,
    body: any,
    { header, noBody, isFormData = false, apiUrl = apiRoot }: ApiClientOptions = {}
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      const fetchUrl = `${apiUrl}${url}`;
      this.fetchWrapper(fetchUrl, {
        method: HttpMethod.PATCH,
        headers: {
          ...this.getDefaultHeader(isFormData),
          ...header,
        },
        body: isFormData ? body : JSON.stringify(body),
      })
        .then((result) => {
          const res = result as Response;

          if (noBody) return res.ok ? resolve(true as any) : reject(res.statusText);

          if (res.ok) {
            if (res.status === 204) {
              resolve(true as any);
            } else {
              res
                .json()
                .then((resp) => resolve(resp))
                .catch((e) => reject(ErrorsConstants.UNREADABLE_JSON_ERROR));
            }
          } else {
            res
              .json()
              .then((resp) => reject(resp))
              .catch((e) => reject(ErrorsConstants.UNREADABLE_JSON_ERROR));
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  async delete(url: string, { header, isFormData = false, apiUrl = apiRoot }: ApiClientOptions = {}) {
    const fetchUrl = `${apiUrl}${url}`;

    await this.fetchWrapper(fetchUrl, {
      method: HttpMethod.DELETE,
      headers: {
        ...this.getDefaultHeader(isFormData),
        ...header,
      },
    });
  }

  fetchWrapper = async (url: string, options?: RequestInit, timeout = 60000) => {
    return Promise.race([
      fetch(url, options),
      new Promise((_, reject) => {
        setTimeout(() => reject(ErrorsConstants.TIMEOUT), timeout);
      }),
    ]).catch((err) => {
      if (
        err?.statusCode === ErrorsConstants.TIMEOUT.statusCode ||
        err?.message === ErrorsConstants.FAILED_TO_FETCH.message
      ) {
        if (this.onTimeout) {
          this.onTimeout();
        }
      }
      return Promise.reject(err);
    });
  };
}

const apiClient = new ApiClient();
export default apiClient;
