import { defineMessages } from 'react-intl';

export default defineMessages({
  ViewVendorRootPageCouldNotFindVendorTitle: {
    id: 'ViewVendorRootPageCouldNotFindVendorTitle',
    defaultMessage: 'Could not find vendor',
  },
  ViewVendorRootPageErrorMessage: {
    id: 'ViewVendorRootPageErrorMessage',
    defaultMessage: "Sorry, but we couldn't find the vendor. please contact us for assistance.",
  },
});
