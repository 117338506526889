import { defineMessages } from 'react-intl';

export default defineMessages({
  CarLocationFormCarLocation: {
    id: 'CarLocationFormCarLocation',
    defaultMessage: 'Car location',
  },
  CarLocationFormUseMyVendorLocation: {
    id: 'CarLocationFormUseMyVendorLocation',
    defaultMessage: 'Use my vendor location',
  },
  CarLocationFormVendorLocation: {
    id: 'CarLocationFormVendorLocation',
    defaultMessage: 'Vendor location',
  },
  CarLocationFormViewOrChangeYourLocationInOurVendorInformation: {
    id: 'CarLocationFormViewOrChangeYourLocationInOurVendorInformation',
    defaultMessage: 'View or change your location in our {vendorInformation}.',
  },
  CarLocationFormVendorInformation: {
    id: 'CarLocationFormVendorInformation',
    defaultMessage: 'Vendor information',
  },
  CarLocationFormStreetAndNumber: {
    id: 'CarLocationFormStreetAndNumber',
    defaultMessage: 'Street and number',
  },
  CarLocationFormCity: {
    id: 'CarLocationFormCity',
    defaultMessage: 'City',
  },
  CarLocationFormZipCode: {
    id: 'CarLocationFormZipCode',
    defaultMessage: 'Zip code',
  },
});
