import { faArrowDown, faArrowUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSSProperties, FunctionComponent } from 'react';
import { SortingOrder, SortingOrderEnum } from 'types';
import { compose } from 'utils/compose';
import DataGridRowItem, { DataGridRowItemProps } from '../DataGridRowItem';
import styles from './DataGridHeaderItem.module.scss';

interface Props extends DataGridRowItemProps {
  sort?: SortingOrder;
  onSortClick?: (sortOrder: SortingOrder | undefined) => void;
}

const DataGridHeaderItem: FunctionComponent<Props> = ({ children, sort, onSortClick, ...other }) => {
  let sortStyleUp;
  let sortStyleDown;

  if (sort === SortingOrderEnum.ASC) {
    sortStyleUp = {
      color: '#203A45',
    } as CSSProperties;
  } else if (sort === SortingOrderEnum.DESC) {
    sortStyleDown = {
      color: '#203A45',
    } as CSSProperties;
  }

  const handleSortClick = () => {
    if (onSortClick) {
      if (sort === SortingOrderEnum.ASC) {
        onSortClick(SortingOrderEnum.DESC);
      } else if (sort === SortingOrderEnum.DESC) {
        onSortClick(undefined);
      } else {
        onSortClick(SortingOrderEnum.ASC);
      }
    }
  };
  return (
    <DataGridRowItem {...other} rootClassname={onSortClick ? styles.rootClickable : undefined}>
      <div className={styles.root} onClick={handleSortClick}>
        <div className={styles.headerLabel}>{children}</div>
        {!!onSortClick && (
          <button className={styles.headerSortButton}>
            <FontAwesomeIcon icon={faArrowUp} className={styles.headerSortIconUp} style={sortStyleUp} />
            <FontAwesomeIcon icon={faArrowDown} className={styles.headerSortIconDown} style={sortStyleDown} />
          </button>
        )}
      </div>
    </DataGridRowItem>
  );
};

export default compose<Props>(DataGridHeaderItem);
