import { Reducer } from 'redux';
import { Actions, ActionTypes } from './actions';
import { initialState, RegisterPageState } from './initial-state';

export const registerPageReducer: Reducer<RegisterPageState> = (
  state = initialState,
  action: Actions
): RegisterPageState => {
  switch (action.type) {
    case ActionTypes.VALIDATE_TOKEN_REQUEST: {
      return {
        ...state,
        isTokenValidationFetching: true,
        sellerData: {
          ...state.sellerData,
          token: action.payload,
        },
      };
    }

    case ActionTypes.VALIDATE_TOKEN_SUCCESS: {
      return {
        ...state,
        isTokenValidationFetching: false,
        sellerData: {
          ...state.sellerData,
          ...action.payload,
        },
      };
    }
    case ActionTypes.VALIDATE_TOKEN_FAILURE: {
      return {
        ...state,
        isTokenValidationFetching: false,
        isTokenInvalid: true,
      };
    }
    case ActionTypes.REGISTER_SELLER_REQUEST: {
      return {
        ...state,
        isSellerRegistrationFetching: true,
        sellerData: {
          ...state.sellerData,
          ...action.payload,
        },
        isSuccess: false,
      };
    }

    case ActionTypes.REGISTER_SELLER_SUCCESS: {
      return {
        ...state,
        isSellerRegistrationFetching: false,
        isSuccess: true,
      };
    }
    case ActionTypes.REGISTER_SELLER_FAILURE: {
      return {
        ...state,
        isSellerRegistrationFetching: false,
      };
    }
    default: {
      return state;
    }
  }
};
