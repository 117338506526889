import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { compose } from 'utils/compose';
import styles from './Button.module.scss';

interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  color?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info';
  size?: 'small' | 'medium' | 'large';
  variant?: 'normal' | 'rounded' | 'outline';
  icon?: IconProp;
  classes?: {
    root?: string;
  };
}

const Button: FunctionComponent<Props> = ({
  children,
  color = 'primary',
  size = 'medium',
  variant = 'normal',
  icon,
  classes = {},
  ...other
}) => {
  const cx = classNames.bind(styles);
  const buttonCss = cx(styles.root, [color, size, variant === 'outline' ? `${color}Outline` : variant], classes.root);
  return (
    <button className={buttonCss} {...other}>
      {!!icon && <FontAwesomeIcon icon={icon} className={styles.icon} />}
      {children}
    </button>
  );
};

export default compose<Props>(Button);
