import CheckboxBase from 'components/Forms/CheckboxBase';
import { FunctionComponent } from 'react';
import { compose } from 'utils/compose';
import DataGridRowItem from '../DataGridRowItem';
import styles from './DataGridHeader.module.scss';

interface Props {
  withSelection?: {
    checked: boolean;
    onChange: () => void;
  };
}

const DataGridHeader: FunctionComponent<Props> = ({ children, withSelection }) => {
  return (
    <div className={styles.root}>
      {withSelection && (
        <DataGridRowItem size={10} maxWidth={40}>
          <CheckboxBase onChange={withSelection?.onChange} checked={withSelection?.checked} />
        </DataGridRowItem>
      )}
      {children}
    </div>
  );
};

export default compose<Props>(DataGridHeader);
