export const YYYY_MM_DD = 'YYYY-MM-DD';
export const DD_MM_YYYY = 'DD/MM/YYYY';

export const getDateWithoutTimeUTC = (date: Date): Date => {
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
};

export const getMonthsFromToday = (numberOfMonths: number) => {
  const X_MONTHS_AGO = new Date();

  X_MONTHS_AGO.setMonth(X_MONTHS_AGO.getMonth() - numberOfMonths);

  return X_MONTHS_AGO;
};
