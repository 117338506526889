import { StyledComponentProps, Typography, withStyles, WithStyles } from '@material-ui/core';
import clsx from 'clsx';
import { FC, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { compose } from 'utils/compose';
import { styles } from './FileUploadInputLabel.styles';

export interface Props {
  isActive: ReactNode | string;
  active?: ReactNode | string;
  idle?: ReactNode | string;
  hasItems?: boolean;
}

const FileUploadInputLabel: FC<Props & WithStyles<typeof styles>> = ({ classes, isActive, active, idle }) => {
  return (
    <Typography className={clsx('FileUploadInputLabel', classes.root)}>
      {(isActive ? active : idle) || (
        <FormattedMessage id="FileUploadInputLabelUploadDocument" defaultMessage="Upload document" />
      )}
    </Typography>
  );
};

export default compose<Props & StyledComponentProps>(
  FileUploadInputLabel,
  withStyles(styles, { name: 'FileUploadInputLabel' })
);
