import classNames from 'classnames';
import { Fragment, FunctionComponent, ReactNode } from 'react';
import { compose } from 'utils/compose';
import styles from './ConfirmationModalBase.module.scss';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onOkay: () => void;
  message: string | ReactNode;
  okLabel: string | ReactNode;
  closeLabel: string | ReactNode;
  variant?: 'primary' | 'error';
  children?: ReactNode;
  title?: string | ReactNode;
  isOkButtonLeft?: boolean;
}

const ConfirmationModalBase: FunctionComponent<Props> = ({
  isOpen,
  onClose,
  onOkay,
  message,
  okLabel,
  closeLabel,
  variant = 'primary',
  children,
  title,
  isOkButtonLeft = true,
}) => {
  if (!isOpen) {
    return <Fragment />;
  }

  const okButton = (isLeft: boolean) => () =>
    (
      <button
        onClick={onOkay}
        className={classNames(
          styles.primaryButton,
          { [styles.errorButton]: variant === 'error' },
          { [styles.okButtonLeft]: isLeft },
          { [styles.okButtonRight]: !isLeft }
        )}
      >
        {okLabel}
      </button>
    );

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        {title && <div className={styles.title}>{title}</div>}
        <div className={styles.message}>{message}</div>
        <div className={styles.controls}>
          {isOkButtonLeft && okButton(true)()}
          <button className={styles.outlineButton} onClick={onClose}>
            {closeLabel}
          </button>
          {!isOkButtonLeft && okButton(false)()}
        </div>
        {children && <div className={styles.description}>{children}</div>}
      </div>
    </div>
  );
};

export default compose(ConfirmationModalBase);
