import { queryStringify } from '../hooks/router';
import { DealFilters } from '../pages/ViewAllDeals/store/initial-state';
import { OrdersQuery, VendorQuery } from '../types';

export const routeConstants = {
  PUBLIC: {
    LOGIN_PAGE: {
      route: '/',
      url: () => '/',
    },
    REGISTER_PAGE: {
      route: '/register',
      url: () => '/register',
    },
    SET_NEW_PASSWORD_PAGE: {
      route: '/new-password',
      url: () => '/new-password',
    },
    RESET_PASSWORD_PAGE: {
      route: '/reset-password',
      url: () => '/reset-password',
    },
  },
  PROTECTED: {
    VIEW_ALL_DEALS_PAGE: {
      route: '/deals',
      url: (query?: Partial<DealFilters>) => `/deals?${query ? queryStringify(query) : ''}`,
    },
    VIEW_ALL_VENDORS_PAGE: {
      route: '/vendors',
      url: (query: Partial<VendorQuery>) => `/vendors?${queryStringify(query)}`,
    },
    VIEW_ORDERS_PAGE: {
      route: '/orders',
      url: (query?: Partial<OrdersQuery>) => `/orders?${query ? queryStringify(query) : ''}`,
    },
    VIEW_VENDOR_ORDERS: {
      route: '/vendors/:vendorId/orders',
      url: (vendorId: number, query?: Partial<OrdersQuery>) =>
        `/vendors/${vendorId}/orders?${query ? queryStringify(query) : ''}`,
    },
    VIEW_ORDER_PAGE: {
      route: '/orders/:orderId',
      url: (orderId: string) => `/orders/${orderId}`,
    },
    VIEW_ORDER_FILES_PAGE: {
      route: '/orders/:orderId/files',
      url: (orderId: string) => `/orders/${orderId}/files`,
    },
    VIEW_VENDOR_MAIN: {
      route: '/vendors/:vendorId',
      url: (vendorId: number) => `/vendors/${vendorId}`,
    },
    VIEW_DEALS_PAGE: {
      route: '/vendors/:vendorId/deals',
      url: (id: number, query?: Partial<DealFilters>) => `/vendors/${id}/deals?${query ? queryStringify(query) : ''}`,
    },
    ADD_LISTINGS_PAGE: {
      route: '/vendors/:vendorId/deals/create',
      url: (vendorId: number) => `/vendors/${vendorId}/deals/create`,
    },
    EDIT_LISTINGS_PAGE: {
      route: '/vendors/:vendorId/deals/edit/:dealId',
      url: (vendorId: number, dealId: number) => `/vendors/${vendorId}/deals/edit/${dealId}`,
    },
    VENDOR_SETTINGS_ROOT_PAGE: {
      route: '/vendors/:vendorId/settings',
      url: (vendorId: number) => `/vendors/${vendorId}/settings`,
    },
    VENDOR_SETTINGS_VENDOR_INFORMATION_PAGE: {
      route: '/vendors/:vendorId/settings/information',
      url: (vendorId: number) => `/vendors/${vendorId}/settings/information`,
    },
    MANAGE_PEOPLE: {
      route: '/vendors/:vendorId/people',
      url: (vendorId: number) => `/vendors/${vendorId}/people`,
    },
    ACCOUNT_SETTINGS: {
      route: '/account-settings',
      url: () => '/account-settings',
    },
    PROFILE: {
      route: '/account-settings/profile',
      url: () => '/account-settings/profile',
    },
    CHANGE_PASSWORD: {
      route: '/account-settings/change-password',
      url: () => '/account-settings/change-password',
    },
    CONTACT_SUPPORT: {
      route: '/support',
      url: () => '/support',
    },
  },
};
