import { batchUpdateDealStatus, createDeal, getDeal, queryDeals } from 'api/deals';
import { getVendorById } from 'api/vendor';
import { SOMETHING_WENT_WRONG } from 'constants/errors';
import { routeConstants } from 'constants/routes';
import { Actions as GlobalActions } from 'pages/_store/global/actions';
import { RootState } from 'pages/_store/root-reducer';
import { fork, put, takeEvery } from 'redux-saga/effects';
import { call, select } from 'typed-redux-saga';
import { CarDealItemProps, NotificationProps } from 'types';
import { extractValidationErrors } from 'utils/api';
import { createDuplicateDealRequest } from 'utils/duplicate-deal';
import { Actions as ViewAllDealsAction, ActionTypes as ViewAllDealsActionTypes } from './actions';
import { DealFilters } from './initial-state';

const getViewAllDealsQuery = (state: RootState): DealFilters => state.dealResultsState.filters;
const getSelectedDeals = (state: RootState): number[] => state.dealResultsState.selectedDeals;
const getResultDeals = (state: RootState): CarDealItemProps[] => state.dealResultsState.results;

function* viewDealsRequestAsync(action: ReturnType<typeof ViewAllDealsAction.dealResultRequest>) {
  try {
    window.scrollTo(0, 0); //scroll to top before request
    const dealQuery = yield select(getViewAllDealsQuery);
    const vendorId = action.payload.vendorId;

    const res = yield* call(queryDeals, vendorId, {
      ...dealQuery,
      ...action.payload.filters,
      vendorId: action.payload.vendorId,
    });
    yield put(ViewAllDealsAction.dealResultSuccess(res));
  } catch (e) {
    yield put(ViewAllDealsAction.dealResultFailure(e));
  }
}

function* viewVendorInfoRequestAsync(action: ReturnType<typeof ViewAllDealsAction.viewVendorInfoRequest>) {
  try {
    const selectedVendor = yield* call(getVendorById, action.payload);
    yield put(ViewAllDealsAction.viewVendorInfoSuccess(selectedVendor));
  } catch (e) {
    yield put(ViewAllDealsAction.viewVendorInfoFailure(e));
  }
}

function* bulkDealsStatusUpdateAsync(action: ReturnType<typeof ViewAllDealsAction.bulkStatusUpdateDealsRequest>) {
  try {
    const selectedDeals = yield select(getSelectedDeals);
    const dealResults = yield select(getResultDeals);
    const updatedDeals = [];
    const query = yield select(getViewAllDealsQuery);

    for (const deal of dealResults) {
      if (selectedDeals.includes(deal.id)) {
        updatedDeals.push({
          id: deal.id,
          status: action.payload,
        });
      }
    }

    yield* call(batchUpdateDealStatus, updatedDeals);
    yield put(ViewAllDealsAction.bulkStatusUpdateDealsSuccess());
    yield put(ViewAllDealsAction.dealResultRequest({ ...query }));
  } catch (e) {
    yield put(ViewAllDealsAction.bulkStatusUpdateDealsFailure(e));
  }
}

function* duplicateSelectedDealAsync(action: ReturnType<typeof ViewAllDealsAction.duplicateSelectedDealRequest>) {
  try {
    const selectedDealsIds: number[] = yield select(getSelectedDeals);
    const dealResults: CarDealItemProps[] = yield select(getResultDeals);
    const selectedDeal = dealResults.find((d) => d.id === selectedDealsIds[0]);

    const deal = yield* call(getDeal, selectedDealsIds[0]);

    const request = createDuplicateDealRequest({ deal, vendor: selectedDeal.vendor });

    const response = yield* call(createDeal, request);
    window.location.href = routeConstants.PROTECTED.EDIT_LISTINGS_PAGE.url(selectedDeal.vendor, response.id);
  } catch (e) {
    const validationErrors = extractValidationErrors(e);

    const notistackProps: Partial<NotificationProps> = {
      variant: 'error',
      anchorOrigin: {
        horizontal: 'center',
        vertical: 'top',
      },
    };

    if (!validationErrors.length) {
      yield put(
        GlobalActions.sendNotification({
          message: SOMETHING_WENT_WRONG,
          autoHideDuration: 6000,
          ...notistackProps,
        })
      );
    } else {
      const messages = [];
      for (const error of validationErrors) {
        messages.push(`${Object.values(error.constraints)[0]} (${error.property})`);
      }
      yield put(
        GlobalActions.sendNotifications(
          messages.map((message) => ({
            message,
            ...notistackProps,
          }))
        )
      );
    }
  }
  yield put(ViewAllDealsAction.duplicateSelectedDealDone());
}

function* watchViewVendorInfoRequestAsync() {
  yield takeEvery(ViewAllDealsActionTypes.VIEW_VENDOR_INFO_REQUEST, viewVendorInfoRequestAsync);
}

function* watchViewDealRequestAsync() {
  yield takeEvery(ViewAllDealsActionTypes.DEAL_RESULT_REQUEST, viewDealsRequestAsync);
}

function* watchBulkDealsStatusUpdateAsync() {
  yield takeEvery(ViewAllDealsActionTypes.BULK_STATUS_UPDATE_DEALS_REQUEST, bulkDealsStatusUpdateAsync);
}

function* watchDuplicateSelectedDealAsync() {
  yield takeEvery(ViewAllDealsActionTypes.DUPLICATE_SELECTED_DEAL_REQUEST, duplicateSelectedDealAsync);
}

export default [
  fork(watchViewDealRequestAsync),
  fork(watchViewVendorInfoRequestAsync),
  fork(watchBulkDealsStatusUpdateAsync),
  fork(watchDuplicateSelectedDealAsync),
];
