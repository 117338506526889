import { SellerRegistrationData, ValidateSellerInfo } from 'pages/Public/RegisterPage/store/initial-state';
import { UserInfo } from 'types';
import { ChangePasswordParams } from '../pages/AccountSettings/pages/ChangePassword/store/initial-state';
import ApiClient from './apiClient';

export interface LoginResponse {
  accessToken: string;
}

export interface ResetPasswordRequest {
  email: string;
  token: string;
  password: string;
  passwordRepeated: string;
}

export function me(): Promise<UserInfo> {
  return ApiClient.get('auth/me');
}

export function login(email: string, password: string): Promise<LoginResponse> {
  return ApiClient.post<LoginResponse>('auth/login', {
    email,
    password,
  });
}

export function loginGoogle(token: string): Promise<LoginResponse> {
  return ApiClient.post<LoginResponse>('google-auth', {
    token,
  });
}

export function validateToken(token: string): Promise<ValidateSellerInfo> {
  return ApiClient.get(`seller-signup-request/${token}`);
}

export function registerSeller(sellerData: SellerRegistrationData): Promise<LoginResponse> {
  const { firstName, lastName, token, password, passwordRepeated, sellerId, vendorId } = sellerData;
  return ApiClient.post(`seller-signup-request/validate`, {
    firstName,
    lastName,
    token,
    password,
    passwordRepeated,
    sellerId,
    vendorId,
  });
}
export function resetPasswordRequest(email: string): Promise<{}> {
  return ApiClient.post('seller-reset-password', {
    email,
  });
}
export function resetPassword(resetPasswordRequest: ResetPasswordRequest): Promise<{}> {
  return ApiClient.post('seller-reset-password/reset', {
    ...resetPasswordRequest,
  });
}

export const changePassword = async (id: number, email: string, params: ChangePasswordParams): Promise<any> => {
  return await ApiClient.put(
    `auth/change-password`,
    {
      ...params,
    },
    undefined,
    false
  );
};
