import classNames from 'classnames';
import Selection from 'components/Forms/Selection';
import TextField from 'components/Forms/TextField';
import { Actions as AddEditListingsActions } from 'pages/AddEditListings/store/actions';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { DeliveryTimeUnit } from 'types';
import styles from './DeliveredIn.module.scss';
import messages from './DeliveredIn.translations';

const DeliveredIn: FC = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const setUnsavedChanges = () => dispatch(AddEditListingsActions.setUnsavedChanges());

  const unitOptions = Object.values(DeliveryTimeUnit).map((value) => ({
    label: formatMessage(messages[value]),
    value,
  }));

  return (
    <div className={styles.root}>
      <label className={classNames(styles.label, { required: true })}>
        {formatMessage(messages.DeliveredInDeliveredIn)}
        {` *`}
      </label>
      <div className={styles.formRow}>
        <div className={styles.input}>
          <TextField
            name="deliveredIn.min"
            min={1}
            type="number"
            errorLabelClass={styles.errorLabelClass}
            onChange={setUnsavedChanges}
          />
        </div>
        <span className={styles.to}>{formatMessage(messages.DeliveredInTo)}</span>
        <div className={styles.input}>
          <TextField
            name="deliveredIn.max"
            min={1}
            type="number"
            onChange={setUnsavedChanges}
            className={styles.input}
            errorLabelClass={styles.errorLabelClass}
          />
        </div>
        <div className={styles.selection}>
          <Selection name="deliveredIn.unit" options={unitOptions} required onChange={setUnsavedChanges} />
        </div>
      </div>
    </div>
  );
};

export default DeliveredIn;
