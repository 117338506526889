import { RootState } from 'pages/_store/root-reducer';
import { useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { UseParamsParams } from 'types';
import { isNullOrUndefined } from 'util';
import Translations from '../AddEditListings.translations';

const useIsLockedToSource = () => {
  const { deal } = useSelector((state: RootState) => state.addEditListingsPage, shallowEqual);

  const { dealId } = useParams<UseParamsParams>();
  const { formatMessage } = useIntl();
  const isLockedToSource = !isNullOrUndefined(dealId) && deal?.isLockedToSource;
  const lockedToSourceMessage = isLockedToSource
    ? formatMessage(Translations.AddEditListingsDisableSyncWithAutoScout24ToEditThisField)
    : undefined;

  return {
    isLockedToSource,
    lockedToSourceMessage,
  };
};

export default useIsLockedToSource;
