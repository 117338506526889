import { SUPPORT_EMAIL_LINK } from 'constants/constants';
import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { compose } from 'utils/compose';
import Translations from './ContactSupport.translations';
import styles from './ContactSupportLink.module.scss';

interface Props {
  label: string;
}

const ContactSupportLink: FunctionComponent<Props> = ({ label }) => {
  const { formatMessage } = useIntl();
  return (
    <span className={styles.root}>
      {label}{' '}
      <a href={`mailto:${SUPPORT_EMAIL_LINK}`} className={styles.contactLink}>
        {formatMessage(Translations.ContactSupportContactOurSupportTeam)}
      </a>
    </span>
  );
};

export default compose<Props>(ContactSupportLink);
