import classNames from 'classnames';
import { useField, useFormikContext } from 'formik';
import { FC } from 'react';
import { SelectOption } from 'types';
import { compose, composeStyle } from 'utils/compose';
import SelectionBase from '../SelectionBase';
import styles from './Selection.module.scss';

interface Props {
  name: string;
  label?: string;
  errorLabel?: string;
  options: SelectOption[];
  placeholder?: string;
  disabled?: boolean;
  defaultValue?: SelectOption;
  required?: boolean;
  tooltip?: string;
  onChange?: (value: string) => void;
  classes?: Partial<typeof styles>;
}

const Selection: FC<Props> = ({
  label,
  name,
  options,
  placeholder = '',
  disabled,
  defaultValue,
  required = false,
  tooltip,
  onChange,
  classes,
}) => {
  const [field, meta] = useField(name);
  const { setFieldValue, submitCount } = useFormikContext();
  const componentStyles = composeStyle(styles, classes);

  const handleChange = (value: string) => {
    setFieldValue(name, value);

    if (onChange) onChange(value);
  };

  const getOptionByValue = () => {
    const key = field.value;
    const result = options.find((o) => o.value === key);

    return result || { label: '', value: '' };
  };

  const touched = meta.touched || submitCount > 0;

  return (
    <div className={componentStyles.root}>
      {label && (
        <label className={classNames(componentStyles.label, { required })}>
          {label}
          {required ? ` *` : ''}
        </label>
      )}
      <SelectionBase
        options={options}
        tooltip={tooltip}
        onBlur={field.onBlur}
        onChange={handleChange}
        placeholder={placeholder}
        disabled={disabled}
        defaultValue={defaultValue}
        error={!!(touched && meta.error)}
        touched={touched}
        value={getOptionByValue()}
      />
      {!!(touched && meta.error) && <div className={componentStyles.errorLabel}>{meta.error}</div>}
    </div>
  );
};

export default compose<Props>(Selection);
