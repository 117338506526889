import { defineMessages } from 'react-intl';

export default defineMessages({
  OrderResultsHubspotDealId: {
    id: 'OrderResultsHubspotDealId',
    defaultMessage: 'Hubspot deal ID',
  },
  OrderResultsDealName: {
    id: 'OrderResultsDealName',
    defaultMessage: 'Deal - name',
  },
  OrderResultsDateCreated: {
    id: 'OrderResultsDateCreated',
    defaultMessage: 'Date created',
  },
  OrderResultsMakeAndModel: {
    id: 'OrderResultsMakeAndModel',
    defaultMessage: 'Make and model',
  },
  OrderResultsOrderId: {
    id: 'OrderResultsOrderId',
    defaultMessage: 'Order ID',
  },
  OrderResultsCustomerName: {
    id: 'OrderResultsCustomerName',
    defaultMessage: 'Customer name',
  },
  OrderResultsStatus: {
    id: 'OrderResultsStatus',
    defaultMessage: 'Status',
  },
  OrderResultsCreatedAt: {
    id: 'OrderResultsCreatedAt',
    defaultMessage: 'Created at',
  },
  OrderResults: {
    id: 'OrderResults',
    defaultMessage: '',
  },
  VendorResultsActiveListingTotalPublishedTotal: {
    id: 'VendorResultsActiveListingTotalPublishedTotal',
    defaultMessage: '{activeListing} ({totalPublished} total)',
  },
  VendorResultsView: {
    id: 'VendorResultsView',
    defaultMessage: 'View',
  },
});
