import { createStyles } from '@material-ui/core';
import { GowagoTheme } from 'theme';

export const styles = (theme: GowagoTheme) =>
  createStyles({
    root: {
      width: '100%',
      position: 'relative',
    },

    formColumn: {
      display: 'flex',
    },

    formRow: {
      maxWidth: '32%',
      flexBasis: '32%',

      '&:first-child': {
        paddingRight: theme.gowago.spacing.standard,
      },
    },

    divider: {
      boxSizing: 'border-box',
      height: 1,
      width: '100%',
      border: '1px solid #000000',
      opacity: 0.1,
      margin: `${theme.gowago.spacing.large} 0`,
    },

    inputRoot: {
      '& > div': {
        paddingLeft: 1,
      },
    },

    monthYearSelectionRow: {
      '& .MonthYearSelection-label': {
        fontWeight: 'bold',
      },
    },
  });
