import { useField, useFormikContext } from 'formik';
import { FunctionComponent } from 'react';
import SwitchBase, { SwitchBaseProps } from '../SwitchBase';

interface Props extends SwitchBaseProps {
  name: string;
}

const Switch: FunctionComponent<Props> = ({ name, onChange, ...other }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(name);

  const handleChange = (value: boolean) => {
    setFieldValue(name, value);

    if (onChange) {
      onChange(value);
    }
  };

  return <SwitchBase onChange={handleChange} checked={field.value || false} {...other} />;
};

export default Switch;
