import { ReactComponent as Logo } from 'assets/images/gowago_logo_ch_small_dark.svg';
import LanguageSelection from 'components/Menu/LanguageSelection';
import { routeConstants } from 'constants/routes';
import LoginPage from 'pages/Public/LoginPage';
import RegisterPage from 'pages/Public/RegisterPage';
import ResetPasswordPage from 'pages/Public/ResetPasswordPage';
import SetNewPasswordPage from 'pages/Public/SetNewPasswordPage';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { Route, Switch } from 'react-router';
import { BrowserRouter as Router } from 'react-router-dom';
import styles from './Public.module.scss';
import Translations from './Public.translations';

const Public: FC = () => {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.root}>
      <div className={styles.headerContainer}>
        <a href={'/'} className={styles.headerLink}>
          <div className={styles.logoContainer}>
            <Logo />
            <div className={styles.logoLineBreaker} />
            <span className={styles.logoTitle}>{formatMessage(Translations.PublicSalesPortal)}</span>
          </div>
        </a>
        <LanguageSelection dark={true} />
      </div>
      <div className={styles.content}>
        <Router>
          <Switch>
            <Route component={LoginPage} path={routeConstants.PUBLIC.LOGIN_PAGE.route} exact />
            <Route component={ResetPasswordPage} path={routeConstants.PUBLIC.RESET_PASSWORD_PAGE.route} exact />
            <Route component={SetNewPasswordPage} path={routeConstants.PUBLIC.SET_NEW_PASSWORD_PAGE.route} exact />
            <Route component={RegisterPage} path={routeConstants.PUBLIC.REGISTER_PAGE.route} />
            <Route component={LoginPage} />
          </Switch>
        </Router>
      </div>
    </div>
  );
};

export default Public;
