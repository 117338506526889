import classNames from 'classnames';
import Alert, { AlertType } from 'components/Alerts/Alert';
import ErrorMessage from 'components/Alerts/ErrorMessage';
import ButtonBase from 'components/Forms/ButtonBase';
import LoadingIndicator from 'components/LoadingIndicator';
import { Formik, FormikProps } from 'formik';
import { Actions as GlobalActions } from 'pages/_store/global/actions';
import { RootState } from 'pages/_store/root-reducer';
import { FunctionComponent, useEffect, useState } from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import ContactInformation from './components/ContactInformation';
import styles from './SellerProfile.module.scss';
import Translations from './SellerProfile.translations';
import { Actions as SellerProfileActions } from './store/actions';
import { SellerProfileFormData } from './store/types';

const formikSchema = ({ formatMessage }: IntlShape) =>
  Yup.object().shape({
    name: Yup.string().required(formatMessage(Translations.SellerProfileThisFieldIsRequired)),
    lastName: Yup.string().required(formatMessage(Translations.SellerProfileThisFieldIsRequired)),
  });

const SellerProfile: FunctionComponent = () => {
  const dispatch = useDispatch();

  const [showErrorSummary, setShowErrorSummary] = useState(false);

  const intl = useIntl();
  const { formatMessage } = intl;

  const {
    sellerProfilePage: { seller, isLoading, successfullyUpdated, isError },
    sellerId,
  } = useSelector(
    (state: RootState) => ({
      sellerProfilePage: state.sellerProfilePage,
      sellerId: state.globalAppState.user.id,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(
      GlobalActions.setConfirmationModalProps({
        message: formatMessage(Translations.SellerProfileYouHaveUnsavedChanged),
        okLabel: formatMessage(Translations.SellerProfileContinueEditing),
        closeLabel: formatMessage(Translations.SellerProfileDiscardChanges),
      })
    );
  }, [dispatch, intl, formatMessage]);

  useEffect(() => {
    dispatch(SellerProfileActions.getSellerRequest(sellerId));
    return () => {
      dispatch(SellerProfileActions.resetState());
    };
  }, [dispatch, sellerId]);

  const onSubmit = (values: SellerProfileFormData) => {
    dispatch(SellerProfileActions.updateSellerRequest(values));
  };

  const onSuccessMessageClose = () => {
    dispatch(SellerProfileActions.hideSuccessAlertMessage());
  };

  const unique = (value: string, index: number, self: string[]) => self.indexOf(value) === index;

  const onChange = () => '';

  return (
    <div className={styles.root}>
      <LoadingIndicator isLoading={isLoading}>
        {seller && (
          <Formik
            initialValues={{
              name: seller.firstName || '',
              lastName: seller.lastName || '',
              email: seller.email || '',
            }}
            validationSchema={formikSchema(intl)}
            onSubmit={onSubmit}
            enableReinitialize={true}
            render={(props: FormikProps<SellerProfileFormData>) => (
              <>
                {successfullyUpdated && (
                  <div className={styles.messageContainer}>
                    <Alert
                      type={AlertType.SUCCESS}
                      message={formatMessage(Translations.SellerProfileContactInformationWasSaved)}
                      onClose={onSuccessMessageClose}
                    />
                  </div>
                )}
                {isError && (
                  <div className={styles.messageContainer}>
                    <ErrorMessage title={formatMessage(Translations.SellerProfileSomethingWentWrong)}>
                      <>
                        <div>{formatMessage(Translations.SellerProfilePleaseTrySavingOneMoreTime)}</div>
                        <div>{formatMessage(Translations.SellerProfileIfThisIssuePersistPleaseContactOurSupport)}</div>
                      </>
                    </ErrorMessage>
                  </div>
                )}
                {showErrorSummary && (
                  <div className={styles.messageContainer}>
                    <ErrorMessage title={formatMessage(Translations.SellerProfileContactInformationCouldNotBeChanged)}>
                      <div>
                        {Object.keys(props.errors)
                          .filter(unique)
                          .map((field) => (
                            <div key={field}>
                              {` - `}
                              <FormattedMessage
                                id="SellerProfileFieldMustMeetTheRequiredCriteria"
                                defaultMessage="{field} must meet the required criteria"
                                values={{
                                  field: (
                                    <u>
                                      {field === 'name' && formatMessage(Translations.SellerProfileName)}
                                      {field === 'lastName' && formatMessage(Translations.SellerProfileLastName)}
                                    </u>
                                  ),
                                }}
                              />
                            </div>
                          ))}
                      </div>
                    </ErrorMessage>
                  </div>
                )}
                <form onSubmit={props.handleSubmit}>
                  <div className={styles.cardRoot}>
                    <div
                      className={classNames({
                        [styles.hidden]: false,
                      })}
                    >
                      <ContactInformation formikProps={props} onChange={onChange} />
                    </div>
                    <ButtonBase
                      type="submit"
                      classes={{
                        root: styles.submitButton,
                      }}
                      isLoading={isLoading}
                      onClick={() => setShowErrorSummary(Object.keys(props.errors).length > 0)}
                    >
                      {formatMessage(Translations.SellerProfileSaveChanges)}
                    </ButtonBase>
                  </div>
                </form>
              </>
            )}
          />
        )}
      </LoadingIndicator>
    </div>
  );
};

export default SellerProfile;
