import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { FunctionComponent, useState } from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { compose } from 'utils/compose';
import styles from './NavItem.module.scss';

interface Props {
  title: string;
  titleIcon: IconProp;
  subLinks: SubLinkProps[];
  rootLink: string;
}

interface SubLinkProps {
  title: string;
  link: string;
}

const NavItem: FunctionComponent<Props> = ({ title, titleIcon, rootLink, subLinks }) => {
  const isRootLinkMatch = useRouteMatch({
    path: rootLink,
    exact: false,
  });
  const [isToggled, setToggle] = useState(!!isRootLinkMatch);
  const handleToggle = () => setToggle(!isToggled);

  return (
    <div className={styles.root}>
      <div
        className={classNames(styles.titleContainer, {
          [styles.activeLink]: !!isRootLinkMatch,
        })}
        onClick={handleToggle}
      >
        <FontAwesomeIcon icon={titleIcon} className={styles.titleIcon} fixedWidth />
        <span className={styles.title}>{title}</span>
        <FontAwesomeIcon
          icon={['far', 'chevron-right']}
          className={classNames(styles.titleAccordionIcon, {
            [styles.iconActive]: isToggled,
          })}
          fixedWidth
        />
      </div>
      <div
        className={classNames(styles.itemContainer, {
          [styles.active]: isToggled,
        })}
      >
        {subLinks.map((item) => (
          <NavLink key={item.title} to={item.link} className={styles.itemLink} activeClassName={styles.itemLinkActive}>
            {item.title}
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default compose<Props>(NavItem);
