export interface ValidationError {
  property: string;
  constraints: Array<Record<string, string>>;
}

export const createRequestQueryArray = (propertyName: string, data: Array<string>) => {
  if (!data?.length) return '';
  let query = '';
  data.forEach((item, i) => {
    query += propertyName + `[]=` + item + '&';
  });

  return query;
};

export const extractValidationErrors = (error: any) => {
  if (error?.error !== 'Bad Request' || !error?.message) return [];

  const getChildConstraints = (children: any): ValidationError[] => {
    const constraints: ValidationError[] = [];

    for (const child of children) {
      if (child.constraints) {
        constraints.push({
          property: child.property,
          constraints: child.constraints,
        });

        if (child.children) {
          constraints.push(...getChildConstraints(child.children));
        }
      }
    }

    return constraints;
  };

  const result: ValidationError[] = [];

  for (const message of error.message) {
    if (message.children) {
      result.push(...getChildConstraints(message.children));
    }
  }

  return result;
};
