export enum MimeTypes {
  JPG = 'image/jpg',
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  GIF = 'image/gif',
  PDF = 'application/pdf',
  XML = 'text/xml',
}
export const DEFAULT_ALLOWED_IMAGE_MIME_TYPES = [MimeTypes.JPG, MimeTypes.JPEG, MimeTypes.PNG, MimeTypes.GIF];
export const DEFAULT_ALLOWED_FILE_MIME_TYPES = [MimeTypes.PDF];

export const ALLOWED_MIME_TYPES = [...DEFAULT_ALLOWED_IMAGE_MIME_TYPES, ...DEFAULT_ALLOWED_FILE_MIME_TYPES];

export enum FileUploadErrorTypes {
  FORMAT = 'FORMAT',
  LENGTH = 'LENGTH',
  SIZE = 'SIZE',
}

const getFileMeta = (path: string) => {
  if (!path) return {};

  const imageExtensions = DEFAULT_ALLOWED_IMAGE_MIME_TYPES.map((mime) => mime.substr(mime.indexOf('/') + 1));
  const fileExtensions = DEFAULT_ALLOWED_FILE_MIME_TYPES.map((mime) => mime.substr(mime.indexOf('/') + 1));
  const pathWithoutQuery = path.substr(0, path.indexOf('?'));

  let extension = '';
  let fileName = '';
  let hasPreview = false;

  const isImage = imageExtensions.some((e) => {
    const includes = pathWithoutQuery.includes(e);
    if (includes) {
      extension = `image/${e}`;
      fileName = pathWithoutQuery.substr(pathWithoutQuery.lastIndexOf('/') + 1);
      hasPreview = true;
    }
    return includes;
  });
  const isPdf = fileExtensions.some((e) => {
    const includes = pathWithoutQuery.includes(e);
    if (includes) {
      if (e === 'pdf') {
        extension = `application/${e}`;
      } else {
        extension = `file/${e}`;
      }

      fileName = pathWithoutQuery.substr(pathWithoutQuery.lastIndexOf('/') + 1);
      hasPreview = false;
    }
    return includes;
  });

  return {
    isImage,
    isPdf,
    extension,
    fileName,
    hasPreview,
  };
};

interface CreateFileOptions {
  getOptions?: (url: string) => { extension: string; fileName: string };
}

export const createFile = async (url: string, { getOptions }: CreateFileOptions = {}) => {
  const { extension, fileName } = getOptions ? getOptions(url) : getFileMeta(url);
  const response = await fetch(url);
  const data = await response.blob();
  return new File([data], fileName || url, { type: extension });
};
