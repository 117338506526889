import { MimeTypes } from 'components/Forms/FileUpload/FileUpload.utils';
import qs from 'qs';

interface CreateImgUrlOptions {
  width?: number;
  quality?: number;
  format?: string;
}

export const createImgUrl = (src: string, { width, quality, format }: CreateImgUrlOptions = {}) => {
  if (!src) {
    return '';
  }

  const addQueryParam = (name: string, value: string | number) =>
    (src += `${src.includes('?') ? '&' : '?'}${name}=${value}`);

  if (width) addQueryParam('w', width);
  if (quality) addQueryParam('q', quality);
  if (format) addQueryParam('f', format);

  return src;
};

export const getImgOptimizerFileInfo = (url: string, mime?: string) => {
  const search = qs.parse(url.substring(url.indexOf('?'))) as any;
  const searchParams = qs.parse(search, {
    ignoreQueryPrefix: true,
  });

  const pathWithoutQuery = url.includes('?') ? url.substring(0, url.indexOf('?')) : url;

  const extension = mime ? mime : searchParams.f ? `image/${searchParams.f}` : MimeTypes.JPEG;

  return {
    extension,
    fileName: pathWithoutQuery,
  };
};
