import { APIError, DealResultsRequestArgs, DealResultsResponse, DealStatus, Vendor } from 'types';
import { ActionsUnion, createAction } from 'utils/action-utils';

export enum ActionTypes {
  DEAL_RESULT_REQUEST = '[DEALS] DEAL_RESULT_REQUEST',
  DEAL_RESULT_SUCCESS = '[DEALS] DEAL_RESULT_SUCCESS',
  DEAL_RESULT_FAILURE = '[DEALS] DEAL_RESULT_FAILURE',
  VIEW_VENDOR_INFO_REQUEST = '[DEALS] VIEW_VENDOR_INFO_REQUEST',
  VIEW_VENDOR_INFO_SUCCESS = '[DEALS] VIEW_VENDOR_INFO_SUCCESS',
  VIEW_VENDOR_INFO_FAILURE = '[DEALS] VIEW_VENDOR_INFO_FAILURE',
  UPDATED_SELECTED_DEALS = '[DEALS] UPDATE_SELECTED_DEALS',
  BULK_STATUS_UPDATE_DEALS_REQUEST = '[DEALS] BULK_STATUS_UPDATE_DEALS_REQUEST',
  BULK_STATUS_UPDATE_DEALS_SUCCESS = '[DEALS] BULK_STATUS_UPDATE_DEALS_SUCCESS',
  BULK_STATUS_UPDATE_DEALS_FAILURE = '[DEALS] BULK_STATUS_UPDATE_DEALS_FAILURE',
  RESET_SELECTED_VENDOR = '[DEALS] RESET_SELECTED_VENDOR',
  DUPLICATE_SELECTED_DEAL_DONE = '[DEALS] DUPLICATE_SELECTED_DEAL_DONE',
  DUPLICATE_SELECTED_DEAL_REQUEST = '[DEALS] DUPLICATE_SELECTED_DEAL_REQUEST',
}

export const Actions = {
  dealResultRequest: (args: DealResultsRequestArgs) => createAction(ActionTypes.DEAL_RESULT_REQUEST, args),
  dealResultSuccess: (result: DealResultsResponse) => createAction(ActionTypes.DEAL_RESULT_SUCCESS, result),
  dealResultFailure: (e: APIError) => createAction(ActionTypes.DEAL_RESULT_FAILURE, e),
  viewVendorInfoRequest: (id: number) => createAction(ActionTypes.VIEW_VENDOR_INFO_REQUEST, id),
  viewVendorInfoSuccess: (vendor: Vendor) => createAction(ActionTypes.VIEW_VENDOR_INFO_SUCCESS, vendor),
  updateSelectedDeals: (deals: number[]) => createAction(ActionTypes.UPDATED_SELECTED_DEALS, deals),
  viewVendorInfoFailure: (e: APIError) => createAction(ActionTypes.VIEW_VENDOR_INFO_FAILURE, e),
  bulkStatusUpdateDealsRequest: (status: DealStatus) =>
    createAction(ActionTypes.BULK_STATUS_UPDATE_DEALS_REQUEST, status),
  bulkStatusUpdateDealsSuccess: () => createAction(ActionTypes.BULK_STATUS_UPDATE_DEALS_SUCCESS),
  bulkStatusUpdateDealsFailure: (e: APIError) => createAction(ActionTypes.BULK_STATUS_UPDATE_DEALS_FAILURE, e),
  resetSelectedVendor: () => createAction(ActionTypes.RESET_SELECTED_VENDOR),
  duplicateSelectedDealRequest: () => createAction(ActionTypes.DUPLICATE_SELECTED_DEAL_REQUEST),
  duplicateSelectedDealDone: () => createAction(ActionTypes.DUPLICATE_SELECTED_DEAL_DONE),
};

export type Actions = ActionsUnion<typeof Actions>;
