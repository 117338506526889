import { defineMessages } from 'react-intl';

export default defineMessages({
  OrderNotificationsListingWasSaved: {
    id: 'OrderNotificationsListingWasSaved',
    defaultMessage: 'Order was saved',
  },
  AddEditListingsNotificationsOrderCouldNotBeUpdatedDueTo: {
    id: 'AddEditListingsNotificationsOrderCouldNotBeUpdatedDueTo',
    defaultMessage: 'Order could not be updated due to the following errors',
  },
});
