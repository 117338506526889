import { faBell, faCheck, faExclamationCircle, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { FC, ReactNode } from 'react';
import styles from './Alert.module.scss';

export enum AlertType {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
}

interface Props {
  type: AlertType;
  message: ReactNode;
  onClose: () => void;
  classes?: {
    root?: string;
  };
}

const Alert: FC<Props> = ({ classes, type, message, onClose }) => {
  const isSuccess = type === AlertType.SUCCESS;
  const isWarning = type === AlertType.WARNING;
  const isError = type === AlertType.ERROR;

  return (
    <div
      className={clsx(styles.root, classes?.root, {
        [styles.rootSuccess]: isSuccess,
        [styles.rootWraning]: isWarning,
        [styles.rootError]: isError,
      })}
    >
      <div className={styles.checkContainer}>
        <FontAwesomeIcon icon={isSuccess ? faCheck : isWarning ? faExclamationCircle : faBell} />
      </div>
      <div className={styles.messageContainer}>{message}</div>
      <div className={styles.closeContainer}>
        <button className={styles.closeButton} onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
    </div>
  );
};

export default Alert;
