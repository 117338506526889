import { DateFormat } from 'types';

export const INVALID_DATE = 'Invalid Date';
// formik and yup don't recognize null value when validating required
export function getValueOrUndefined<T>(value: T): T | undefined {
  if (typeof value === 'number' && value === 0) {
    return value;
  }
  return value || undefined;
}

export function getStringValueOrNull<T>(value: T): string | null {
  if ((typeof value === 'number' && value === 0) || value) {
    return String(value);
  }
  return String(value) || null;
}

export function getNumberOrNull(value: any, isUndefined?: boolean): number | null {
  if (!value && value !== 0) {
    return isUndefined ? undefined : null;
  }
  return Number(value);
}

export const getUTCDateFromDate = (date: Date | null) => {
  if (!date) {
    return null;
  }

  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
};

export const formatMonthAndDayOfMonth = (number: number) => {
  if (number < 10) {
    return String(number).padStart(2, '0');
  }
  return number;
};

export const formatDate = (format: DateFormat, date: Date | null) => {
  if (!date) return undefined;

  switch (format) {
    case DateFormat['YYYY-MM-DD']: {
      return `${date.getFullYear()}-${formatMonthAndDayOfMonth(date.getMonth() + 1)}-${formatMonthAndDayOfMonth(
        date.getDate()
      )}`;
    }
  }
};

export const isValidDateObject = (d: Date | string | null | undefined) => {
  if (!d || (d && typeof d === 'object' && isNaN(d.getTime()))) return false;
  return true;
};

export const isValidDateString = (d: Date | string | null | undefined) => {
  if (!d || (d && typeof d === 'string' && d === INVALID_DATE)) return false;
  return true;
};

export const transformEmptyToNull = (value: string, originalValue: string) => (originalValue === '' ? null : value);
