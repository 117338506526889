import { RootState } from 'pages/_store/root-reducer';
import { RolesEnum } from 'types';

const extractIsLoadingCarMakes = (state: RootState) => state.globalAppState.isLoadingCarMakes;
const extractUserVendors = (state: RootState) => state.globalAppState.user.vendors;
const extractUser = (state: RootState) => state.globalAppState.user;
const extractRoles = (store: RootState) => store.globalAppState.user.roles;
const extractIsAuthenticated = (store: RootState) => store.globalAppState.user.isAuthenticated;
const extractIsInitialLoaded = (store: RootState) => store.globalAppState.isInitialLoaded;

const getIsAdmin = (state: RootState) =>
  extractRoles(state)?.includes(RolesEnum.SUPER_ADMIN) || extractRoles(state)?.includes(RolesEnum.ADMIN);

const getIsSuperAdmin = (state: RootState) => extractRoles(state)?.includes(RolesEnum.SUPER_ADMIN);

const getIsOfferPricingUpdateAdmin = (state: RootState) =>
  extractRoles(state)?.includes(RolesEnum.OFFER_PRICING_UPDATE_ADMIN);

export default {
  extractIsLoadingCarMakes,
  extractUserVendors,
  extractUser,
  extractRoles,
  extractIsAuthenticated,
  extractIsInitialLoaded,
  getIsAdmin,
  getIsSuperAdmin,
  getIsOfferPricingUpdateAdmin,
};
