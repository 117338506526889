import { useLocation, useParams, useQuery } from 'hooks/router';
import { FC, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { DefaultVendorParams } from 'types';
import { RootState } from '../_store/root-reducer';
import ListingResults from './components/DealsResults';
import FilterPanel from './components/FilterPanel';
import Header from './components/Header';
import { Actions as DealsResultActions } from './store/actions';
import { DealFilters, initialState } from './store/initial-state';
import styles from './ViewAllDeals.module.scss';

const ViewAllListings: FC = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const query = useQuery<Partial<DealFilters>>({
    initialObj: initialState.filters,
  });
  const [searchTerm, setSearchTerm] = useState(query?.query);

  const { countFiltered } = useSelector((state: RootState) => state.dealResultsState, shallowEqual);

  const { vendorId } = useParams<DefaultVendorParams>();

  useEffect(() => {
    dispatch(DealsResultActions.resetSelectedVendor());
    dispatch(DealsResultActions.updateSelectedDeals([]));
  }, [dispatch, pathname]);

  useEffect(() => {
    dispatch(
      DealsResultActions.dealResultRequest({
        vendorId: vendorId ? Number(vendorId) : undefined,
        filters: { ...query },
      })
    );
  }, [dispatch, query, vendorId]);

  return (
    <div className={styles.root}>
      <Header $total={countFiltered} />
      <div className={styles.resultsContainer}>
        <div className={styles.filterContainer}>
          <FilterPanel searchTerm={searchTerm} onSearchTermChange={setSearchTerm} />
        </div>
        <ListingResults onSearchTermChange={setSearchTerm} />
      </div>
    </div>
  );
};

export default ViewAllListings;
