import { defineMessages } from 'react-intl';

export default defineMessages({
  ChooseModelAndVersionModelAndVersion: {
    id: 'ChooseModelAndVersionModelAndVersion',
    defaultMessage: 'Model and version',
  },
  ChooseModelAndVersionBodyShape: {
    id: 'ChooseModelAndVersionBodyShape',
    defaultMessage: 'Body shape',
  },
  ChooseModelAndVersionHPHeader: {
    id: 'ChooseModelAndVersionHPHeader',
    defaultMessage: 'Horsepower',
  },
  ChooseModelAndVersionDoors: {
    id: 'ChooseModelAndVersionDoors',
    defaultMessage: 'Doors',
  },
  ChooseModelAndVersionSeats: {
    id: 'ChooseModelAndVersionSeats',
    defaultMessage: 'Seats',
  },
  ChooseModelAndVersionGearbox: {
    id: 'ChooseModelAndVersionGearbox',
    defaultMessage: 'Gearbox',
  },
  ChooseModelAndVersionFuel: {
    id: 'ChooseModelAndVersionFuel',
    defaultMessage: 'Fuel',
  },
  ChooseModelAndVersionDrivenWheels: {
    id: 'ChooseModelAndVersionDrivenWheels',
    defaultMessage: 'Driven wheels',
  },
  ChooseModelAndVersionHP: {
    id: 'ChooseModelAndVersionHP',
    defaultMessage: '{hp} HP',
  },
  ChooseModelAndVersionKw: {
    id: 'ChooseModelAndVersionKw',
    defaultMessage: '{kw} kW',
  },
  ChooseModelAndVersionHPAndKw: {
    id: 'ChooseModelAndVersionHPAndKw',
    defaultMessage: '{hp} HP ({kw} kW)',
  },
  ChooseModelAndVersionNotSpecified: {
    id: 'ChooseModelAndVersionNotSpecified',
    defaultMessage: 'Not specified',
  },
  ChooseModelAndVersionDoorNumber: {
    id: 'ChooseModelAndVersionDoorNumber',
    defaultMessage: '{numberOfDoors} doors',
  },
  ChooseModelAndVersionSeatNumber: {
    id: 'ChooseModelAndVersionSeatNumber',
    defaultMessage: '{numberOfSeats} seats',
  },
  ChooseModelAndVersion: {
    id: 'ChooseModelAndVersion',
    defaultMessage: 'Add',
  },
  ChooseModelAndVersionAddVehicleInformationManually: {
    id: 'ChooseModelAndVersionAddVehicleInformationManually',
    defaultMessage: 'Add vehicle information manually',
  },

  // segment
  ChooseModelAndVersionSegmentSmallCar: {
    id: 'ChooseModelAndVersionSegmentSmallCar',
    defaultMessage: 'Small car',
  },
  ChooseModelAndVersionSegmentConvertible: {
    id: 'ChooseModelAndVersionSegmentConvertible',
    defaultMessage: 'Convertible',
  },
  ChooseModelAndVersionSegmenSUV: {
    id: 'ChooseModelAndVersionSegmenSUV',
    defaultMessage: 'SUV',
  },
  ChooseModelAndVersionSegmentWagon: {
    id: 'ChooseModelAndVersionSegmentWagon',
    defaultMessage: 'Wagon',
  },
  ChooseModelAndVersionSegmentSedan: {
    id: 'ChooseModelAndVersionSegmentSedan',
    defaultMessage: 'Sedan',
  },
  ChooseModelAndVersionSegmentVan: {
    id: 'ChooseModelAndVersionSegmentVan',
    defaultMessage: 'Van',
  },
  ChooseModelAndVersionSegmentPickup: {
    id: 'ChooseModelAndVersionSegmentPickup',
    defaultMessage: 'Pickup',
  },
  ChooseModelAndVersionSegmentSportsCar: {
    id: 'ChooseModelAndVersionSegmentSportsCar',
    defaultMessage: 'Sports car',
  },

  // Transmission
  ChooseModelAndVersionTransmissionManual: {
    id: 'ChooseModelAndVersionTransmissionManual',
    defaultMessage: 'Manual',
  },
  ChooseModelAndVersionTransmissionAutomatic: {
    id: 'ChooseModelAndVersionTransmissionAutomatic',
    defaultMessage: 'Automatic',
  },

  // Driven wheels
  ChooseModelAndVersionDrivenWheels4x4: {
    id: 'ChooseModelAndVersionDrivenWheels4x4',
    defaultMessage: '4x4',
  },
  ChooseModelAndVersionDrivenWheelsFront: {
    id: 'ChooseModelAndVersionDrivenWheelsFront',
    defaultMessage: 'Front',
  },
  ChooseModelAndVersionDrivenWheelsRear: {
    id: 'ChooseModelAndVersionDrivenWheelsRear',
    defaultMessage: 'Rear',
  },
});
