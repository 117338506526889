import * as Sentry from '@sentry/react';
import ErrorPage from 'pages/ErrorPage';
import { FC, ReactNode } from 'react';
import { compose } from 'utils/compose';

interface Props {
  children: ReactNode;
}

const SentryErrorBoundary: FC<Props> = ({ children }) => {
  return (
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack, resetError }) => (
        <ErrorPage error={error} componentStack={componentStack} resetError={resetError} />
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default compose<Props>(SentryErrorBoundary);
