import { FuelTypesDetailed } from '@carcodex/gow-shared-mono/modules/car/car.types';
import Card from 'components/Card';
import DataGrid from 'components/DataGrid';
import DataGridHeader from 'components/DataGrid/components/DataGridHeader';
import DataGridHeaderItem from 'components/DataGrid/components/DataGridHeaderItem';
import DataGridRow from 'components/DataGrid/components/DataGridRow';
import DataGridRowItem from 'components/DataGrid/components/DataGridRowItem';
import Translations from 'pages/AddEditListings/components/ChooseModelAndVersion/ChooseModelAndVersion.translations';
import AddEditListingsSelectors from 'pages/AddEditListings/store/selectors';
import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { DrivenWheels, ListingPublic, Segments, TransmissionDetailed } from 'types';
import { compose } from 'utils/compose';
import { getAndTranslatefuelTypeDetailedValues } from 'utils/technicalInformationValuesTranslated';
import { RootState } from '../../../_store/root-reducer';
import { Actions as AddEditListingsActions } from '../../store/actions';
import styles from './ChooseModelAndVersion.module.scss';

interface Props {
  onMoveToStepThree: () => void;
  onAddInfoManually: () => void;
}

const ChooseModelAndVersion: FC<Props> = ({ onMoveToStepThree, onAddInfoManually }) => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const dispatch = useDispatch();

  const { cars, carMakes, isFetchingResults } = useSelector(
    (state: RootState) => ({
      cars: AddEditListingsSelectors.extractCars(state),
      carMakes: state.globalAppState.carMakes,
      isFetchingResults: AddEditListingsSelectors.extractIsLoadingCars(state),
    }),
    shallowEqual
  );

  const handleSelectCar = (car: Partial<ListingPublic>) => {
    dispatch(AddEditListingsActions.updateDealWithCarData(car, carMakes));
    onMoveToStepThree();
    window.scrollTo(0, 0);
  };

  const renderCarSegmentName = (segment: Segments) => {
    return segment === Segments.SMALL_CAR ? (
      formatMessage(Translations.ChooseModelAndVersionSegmentSmallCar)
    ) : segment === Segments.CONVERTIBLE ? (
      formatMessage(Translations.ChooseModelAndVersionSegmentConvertible)
    ) : segment === Segments.SUV ? (
      formatMessage(Translations.ChooseModelAndVersionSegmenSUV)
    ) : segment === Segments.WAGON ? (
      formatMessage(Translations.ChooseModelAndVersionSegmentWagon)
    ) : segment === Segments.SEDAN ? (
      formatMessage(Translations.ChooseModelAndVersionSegmentSedan)
    ) : segment === Segments.VAN ? (
      formatMessage(Translations.ChooseModelAndVersionSegmentVan)
    ) : segment === Segments.PICKUP ? (
      formatMessage(Translations.ChooseModelAndVersionSegmentPickup)
    ) : segment === Segments.SPORTS_CAR ? (
      formatMessage(Translations.ChooseModelAndVersionSegmentSportsCar)
    ) : (
      <i>{formatMessage(Translations.ChooseModelAndVersionNotSpecified)}</i>
    );
  };

  const renderFuelType = (type: FuelTypesDetailed) => {
    if (!type) return <i>{formatMessage(Translations.ChooseModelAndVersionNotSpecified)}</i>;

    return getAndTranslatefuelTypeDetailedValues(intl, type);
  };

  const renderDrivenWheels = (drivenWheels: DrivenWheels) => {
    return drivenWheels === DrivenWheels['4X4'] ? (
      formatMessage(Translations.ChooseModelAndVersionDrivenWheels4x4)
    ) : drivenWheels === DrivenWheels.FRONT ? (
      formatMessage(Translations.ChooseModelAndVersionDrivenWheelsFront)
    ) : drivenWheels === DrivenWheels.REAR ? (
      formatMessage(Translations.ChooseModelAndVersionDrivenWheelsRear)
    ) : (
      <i>{formatMessage(Translations.ChooseModelAndVersionNotSpecified)}</i>
    );
  };

  const renderTransmission = (transmission: TransmissionDetailed) => {
    switch (transmission) {
      case TransmissionDetailed.AUTOMATIC:
        return formatMessage(Translations.ChooseModelAndVersionTransmissionAutomatic);
      case TransmissionDetailed.AUTOMATIC_MANUAL:
        return <FormattedMessage id="TechnicalInformationFormAutomaticManual" defaultMessage="Automatic manual" />;
      case TransmissionDetailed.AUTOMATIC_SEQUENTIAL:
        return (
          <FormattedMessage id="TechnicalInformationFormAutomaticSequential" defaultMessage="Automatic sequential" />
        );
      case TransmissionDetailed.AUTOMATIC_STEPLESS:
        return <FormattedMessage id="TechnicalInformationFormAutomaticStepless" defaultMessage="Automatic stepless" />;
      case TransmissionDetailed.AUTOMATIC_STEPLESS_SEQUENTIAL:
        return (
          <FormattedMessage
            id="TechnicalInformationFormAutomaticSteplessSequential"
            defaultMessage="Automatic stepless sequential"
          />
        );
      case TransmissionDetailed.MANUAL:
        return formatMessage(Translations.ChooseModelAndVersionTransmissionManual);
      case TransmissionDetailed.MANUAL_SEQUENTIAL:
        return <FormattedMessage id="TechnicalInformationFormManualSequential" defaultMessage="Manual sequential" />;
      default:
        return <i>{formatMessage(Translations.ChooseModelAndVersionNotSpecified)}</i>;
    }
  };

  return (
    <div className={styles.root}>
      <Card>
        <DataGrid isLoading={isFetchingResults} isEmptyResult={!cars?.length}>
          <DataGridHeader>
            <DataGridHeaderItem size={23}>
              {formatMessage(Translations.ChooseModelAndVersionModelAndVersion)}
            </DataGridHeaderItem>
            <DataGridHeaderItem size={14}>
              {formatMessage(Translations.ChooseModelAndVersionBodyShape)}
            </DataGridHeaderItem>
            <DataGridHeaderItem size={14}>
              {formatMessage(Translations.ChooseModelAndVersionHPHeader)}
            </DataGridHeaderItem>
            <DataGridHeaderItem size={8}>{formatMessage(Translations.ChooseModelAndVersionDoors)}</DataGridHeaderItem>
            <DataGridHeaderItem size={8}>{formatMessage(Translations.ChooseModelAndVersionSeats)}</DataGridHeaderItem>
            <DataGridHeaderItem size={10}>
              {formatMessage(Translations.ChooseModelAndVersionGearbox)}
            </DataGridHeaderItem>
            <DataGridHeaderItem size={8}>{formatMessage(Translations.ChooseModelAndVersionFuel)}</DataGridHeaderItem>
            <DataGridHeaderItem size={14}>
              {formatMessage(Translations.ChooseModelAndVersionDrivenWheels)}
            </DataGridHeaderItem>
          </DataGridHeader>
          {!!cars &&
            cars.map((car: ListingPublic) => (
              <DataGridRow key={car.id} onClick={handleSelectCar} data={car}>
                <DataGridRowItem size={23}>
                  {car.model && car.engine ? (
                    <b>{car.model + ' ' + car.engine}</b>
                  ) : car.model ? (
                    car.model
                  ) : car.engine ? (
                    car.engine
                  ) : (
                    <i>{formatMessage(Translations.ChooseModelAndVersionNotSpecified)}</i>
                  )}
                </DataGridRowItem>
                <DataGridRowItem size={14}>
                  {!car.segments?.length ? (
                    <i>{formatMessage(Translations.ChooseModelAndVersionNotSpecified)}</i>
                  ) : (
                    renderCarSegmentName(car.segments[0])
                  )}
                </DataGridRowItem>
                <DataGridRowItem size={14}>
                  {car.hp ? (
                    formatMessage(Translations.ChooseModelAndVersionHP, { hp: car.hp })
                  ) : (
                    <i>{formatMessage(Translations.ChooseModelAndVersionNotSpecified)}</i>
                  )}
                </DataGridRowItem>
                <DataGridRowItem size={8}>
                  {car.doors ? (
                    formatMessage(Translations.ChooseModelAndVersionDoorNumber, { numberOfDoors: car.doors })
                  ) : (
                    <i>{formatMessage(Translations.ChooseModelAndVersionNotSpecified)}</i>
                  )}
                </DataGridRowItem>
                <DataGridRowItem size={8}>
                  {car.seats ? (
                    formatMessage(Translations.ChooseModelAndVersionSeatNumber, { numberOfSeats: car.seats })
                  ) : (
                    <i>{formatMessage(Translations.ChooseModelAndVersionNotSpecified)}</i>
                  )}
                </DataGridRowItem>
                <DataGridRowItem size={10}>{renderTransmission(car.transmissionDetailed)}</DataGridRowItem>
                <DataGridRowItem size={8}>
                  {car.fuelTypeDetailed ? (
                    renderFuelType(car.fuelTypeDetailed)
                  ) : (
                    <i>{formatMessage(Translations.ChooseModelAndVersionNotSpecified)}</i>
                  )}
                </DataGridRowItem>
                <DataGridRowItem size={14}>
                  {car.drivenWheels ? (
                    renderDrivenWheels(car.drivenWheels)
                  ) : (
                    <i>{formatMessage(Translations.ChooseModelAndVersionNotSpecified)}</i>
                  )}
                </DataGridRowItem>
              </DataGridRow>
            ))}
        </DataGrid>
        <p className={styles.addInfoManually} onClick={onAddInfoManually}>
          {formatMessage(Translations.ChooseModelAndVersionAddVehicleInformationManually)}
        </p>
      </Card>
    </div>
  );
};

export default compose<Props>(ChooseModelAndVersion);
