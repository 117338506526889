import Card from 'components/Card';
import DataGrid from 'components/DataGrid';
import DataGridHeader from 'components/DataGrid/components/DataGridHeader';
import DataGridHeaderItem from 'components/DataGrid/components/DataGridHeaderItem';
import DataGridPagination from 'components/DataGrid/components/DataGridPagination';
import DataGridRow from 'components/DataGrid/components/DataGridRow';
import DataGridRowItem from 'components/DataGrid/components/DataGridRowItem';
import { routeConstants } from 'constants/routes';
import { useQuery, useUpdateQuery } from 'hooks/router';
import { RootState } from 'pages/_store/root-reducer';
import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { DealStatus, SortingOrder, Vendor, VendorQuery, VendorSorting, VendorStatus } from 'types';
import { compose } from 'utils/compose';
import { initialState } from '../../store/initial-state';
import styles from './VendorResults.module.scss';
import Translations from './VendorResults.translations';

interface Props {
  onSearchTermChange: (term: string) => void;
}

type SortingParamsKeys = keyof VendorSorting;

const ListingResults: FunctionComponent<Props> = ({ onSearchTermChange }) => {
  const { formatMessage } = useIntl();
  const {
    result: { results, countFiltered },
    query: { $limit, $skip, sorting },
    isFetchingResults,
  } = useSelector((state: RootState) => state.viewAllVendorsPage, shallowEqual);
  const query = useQuery<Partial<VendorQuery>>({
    initialObj: initialState.query,
  });
  const updateQuery = useUpdateQuery<VendorQuery>({
    replace: true,
    initialState,
  });
  const replaceQuery = useUpdateQuery<VendorQuery>({
    replace: true,
    replaceQuery: true,
    initialState,
  });
  const prevLink = routeConstants.PROTECTED.VIEW_ALL_VENDORS_PAGE.url({
    ...query,
    $skip: $skip - $limit,
  });
  const nextLink = routeConstants.PROTECTED.VIEW_ALL_VENDORS_PAGE.url({
    ...query,
    $skip: $skip + $limit,
  });

  const generateStepLink = (page: number) => {
    return routeConstants.PROTECTED.VIEW_ALL_VENDORS_PAGE.url({
      ...query,
      $skip: $limit * page,
    });
  };

  const handleSortClick = (fieldName: SortingParamsKeys) => (sortValue: SortingOrder | undefined) => {
    updateQuery({
      sorting: {
        [fieldName]: sortValue,
      },
    });
  };

  const parseVendorStatus = (status: VendorStatus) => {
    switch (status) {
      case 'active':
        return formatMessage(Translations.VendorResultsActive);
      case 'inactive':
        return formatMessage(Translations.VendorResultsInactive);
      case 'dropped':
        return formatMessage(Translations.VendorResultsDropped);
      case 'testing':
        return formatMessage(Translations.VendorResultsTesting);
      default:
        return '';
    }
  };
  const handleReset = () => {
    onSearchTermChange('');
    replaceQuery({
      vendorStatus: [VendorStatus.ACTIVE],
    });
  };

  return (
    <div className={styles.root}>
      <Card>
        <DataGrid
          isLoading={isFetchingResults}
          isEmptyResult={!isFetchingResults && results.length === 0}
          onButtonReset={handleReset}
        >
          <DataGridHeader>
            <DataGridHeaderItem size={58} sort={sorting.name} onSortClick={handleSortClick('name')}>
              {formatMessage(Translations.VendorResultsVendorName)}
            </DataGridHeaderItem>
            <DataGridHeaderItem size={15} sort={sorting.hubspotId} onSortClick={handleSortClick('hubspotId')}>
              {formatMessage(Translations.VendorResultsHubspotId)}
            </DataGridHeaderItem>
            <DataGridHeaderItem size={12} sort={sorting.vendorStatus} onSortClick={handleSortClick('vendorStatus')}>
              {formatMessage(Translations.VendorResultsStatus)}
            </DataGridHeaderItem>
            <DataGridHeaderItem size={12} />
          </DataGridHeader>
          {results.map((vendor: Vendor) => (
            <DataGridRow key={vendor.id}>
              <DataGridRowItem size={58}>{vendor.name}</DataGridRowItem>
              <DataGridRowItem size={15}>{vendor.hubspotId}</DataGridRowItem>
              <DataGridRowItem size={12}>
                <>{parseVendorStatus(vendor.status)}</>
              </DataGridRowItem>
              <DataGridRowItem size={15}>
                <Link
                  to={routeConstants.PROTECTED.VIEW_DEALS_PAGE.url(vendor.id, { status: [DealStatus.PUBLISHED] })}
                  className={styles.itemLinkBtn}
                >
                  <div className={styles.itemLinkContainer}>{formatMessage(Translations.VendorResultsSelect)}</div>
                </Link>
              </DataGridRowItem>
            </DataGridRow>
          ))}
          <DataGridPagination
            $total={countFiltered}
            $skip={$skip}
            $limit={$limit}
            generateStepLink={generateStepLink}
            nextLink={nextLink}
            prevLink={prevLink}
          />
        </DataGrid>
      </Card>
    </div>
  );
};

export default compose<Props>(ListingResults);
