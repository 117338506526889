import cxBind from 'classnames/bind';
import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { compose } from 'utils/compose';
import pagination from 'utils/pagination';
import styles from './DataGridPagination.module.scss';
import Translations from './DataGridPagination.translations';
interface Props {
  $total: number;
  $skip: number;
  $limit: number;
  prevLink: string;
  nextLink: string;
  generateStepLink: (step: number) => string;
}

const DataGridPagination: FunctionComponent<Props> = ({
  $skip,
  $limit,
  $total,
  nextLink,
  prevLink,
  generateStepLink,
}) => {
  const cx = cxBind.bind(styles);
  const { formatMessage } = useIntl();
  const currentPageNum = $skip / $limit + 1;
  const totalPages = $total / $limit;

  const { currentPage, pages } = pagination($total, currentPageNum, $limit, 9);
  const pageSteps = pages.map((p) => (
    <li className={cx('page-item', { active: p === currentPage })} key={p}>
      <Link className={cx('page-link')} to={generateStepLink(p - 1)}>
        {p}
      </Link>
    </li>
  ));
  return (
    <div className={styles.root}>
      {$total > 0 && (
        <span className={styles.pageCountlabel}>{`Showing ${$skip + 1}-${
          $skip + $limit < $total ? $skip + $limit : $total
        } of ${$total}`}</span>
      )}
      {pages.length > 1 && (
        <div className={styles.pagination}>
          <ul className={cx(['pagination', 'center'])}>
            <li className={cx('page-item', { disabled: currentPage === 1 })}>
              <Link className={cx('page-link')} to={prevLink} title="Back">
                {formatMessage(Translations.DataGridPaginationPrevious)}
              </Link>
            </li>
            {pageSteps}
            <li
              className={cx('page-item', {
                disabled: currentPage >= totalPages,
              })}
            >
              <Link className={cx('page-link')} to={nextLink} title="Next">
                {formatMessage(Translations.DataGridPaginationNext)}
              </Link>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default compose<Props>(DataGridPagination);
