import { makeStyles } from '@material-ui/core';
import { theme } from 'theme';

export const useStyles = makeStyles({
  root: {
    width: '100%',

    '&> label.required': {
      fontWeight: 'bold',
    },
  },
  headerContainer: {
    color: theme.gowago.colors.lighterGray,
  },
  header: {
    fontSize: 25,
    lineHeight: theme.gowago.lineHeight.rem[34],
  },
  subHeader: {
    fontSize: theme.gowago.fontSize.standard,
    marginBottom: 25,
    marginTop: 7,
  },
});
