import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  OfferPrivateCreditFormPrivateCredit: {
    id: 'OfferPrivateCreditFormPrivateCredit',
    defaultMessage: 'Private credit',
  },
  OfferPrivateCreditFinancingAmount: {
    id: 'OfferPrivateCreditFinancingAmount',
    defaultMessage: 'Financing amount',
  },
  OfferPrivateCreditFormDuration: {
    id: 'OfferPrivateCreditFormDuration',
    defaultMessage: 'Duration',
  },
  OfferPrivateCreditFormMonthlyRate: {
    id: 'OfferPrivateCreditFormMonthlyRate',
    defaultMessage: 'Monthly rate',
  },
  OfferPrivateCreditFormInterestRateNominal: {
    id: 'OfferPrivateCreditFormInterestRateNominal',
    defaultMessage: 'Interest rate nominal',
  },
  OfferPrivateCreditFormOEMReservationFee: {
    id: 'OfferPrivateCreditFormOEMReservationFee',
    defaultMessage: 'OEM reservation fee',
  },
  OfferPrivateCreditFormDownPayment: {
    id: 'OfferPrivateCreditFormDownPayment',
    defaultMessage: 'Down payment',
  },
});
