import { applyMiddleware, compose, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { rootReducer } from './root-reducer';
import RootSaga from './root-saga';

const sagaMiddleware = createSagaMiddleware();

applyMiddleware(sagaMiddleware);
const storeEnhancer =
  process.env.NODE_ENV === 'production'
    ? compose(applyMiddleware(sagaMiddleware))
    : composeWithDevTools(applyMiddleware(sagaMiddleware));

const store = createStore(rootReducer, storeEnhancer);
sagaMiddleware.run(RootSaga);

export default store;
