import Alert, { AlertType } from 'components/Alerts/Alert';
import ErrorMessage from 'components/Alerts/ErrorMessage';
import ErrorValidationList from 'components/Alerts/ErrorMessage/components/ErrorValidationList';
import { useFormikContext } from 'formik';
import { AddEditListingSchema } from 'pages/AddEditListings/schema';
import { ErrorType } from 'pages/AddEditListings/store/initial-state';
import { FormStatus } from 'pages/AddEditListings/utils';
import { RootState } from 'pages/_store/root-reducer';
import { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ErrorValidationListFields } from 'types';
import { Actions as AddEditListingsActins } from '../../store/actions';
import AddEditListingsPageSelectors from '../../store/selectors';
import CarPriceFormTranslations from '../CarPriceForm/CarPriceForm.translations';
import ResidualValuesTranslations from '../CarPriceForm/components/ResidualValueBreakdownForm/ResidualValueBreakdownForm.translations';
import ConsumptionTranslations from '../TechnicalInformationForm/components/ConsumptionInput/ConsumptionInput.translations';
import TechnicalInformationTranslations from '../TechnicalInformationForm/TechnicalInformationForm.translations';
import DeliveredInTranslations from '../VehicleBaseInformationForm/components/DeliveredIn/DeliveredIn.translations';
import GuaranteeTranslations from '../VehicleBaseInformationForm/components/GuaranteeFormBox/GuaranteeFormBox.translations';
import VehicleBaseInformationTranslations from '../VehicleBaseInformationForm/VehicleBaseInformationForm.translations';
import styles from './Notifications.module.scss';
import Translations from './Notifications.translations';
import { renderErrorMessage } from './Notifications.utils';

const Notifications = () => {
  const {
    successfullyUpdated,
    isSyncNotificationVisible,
    isError,
    errorType,
    errorMessageParams,
    draftValidationErrors,
    backendErrorMessages,
    publishErrors,
  } = useSelector(
    (state: RootState) => ({
      successfullyUpdated: AddEditListingsPageSelectors.extractSuccessfullyUpdated(state),
      isSyncNotificationVisible: AddEditListingsPageSelectors.extractIsSyncNotificationVisible(state),
      isError: AddEditListingsPageSelectors.extractIsError(state),
      errorType: AddEditListingsPageSelectors.extractErrorType(state),
      draftValidationErrors: AddEditListingsPageSelectors.extractDraftValidationErrors(state),
      errorMessageParams: AddEditListingsPageSelectors.extractErrorMessageParams(state),
      backendErrorMessages: AddEditListingsPageSelectors.extractBackendErrorMessages(state),
      publishErrors: AddEditListingsPageSelectors.extractPublishErrors(state),
    }),
    shallowEqual
  );
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { submitCount, values, errors, status } = useFormikContext<AddEditListingSchema>();
  const showPublishErrors = publishErrors && Object.keys(publishErrors).length;

  const onSuccessSavedMessageClose = () => {
    dispatch(AddEditListingsActins.hideSuccessAlertMessage());
  };

  const onSuccessSyncMessageClose = () => {
    dispatch(AddEditListingsActins.hideSyncNotification());
  };

  const formatRequiredMessage = (field: { id: string; defaultMessage: string }) => {
    return formatMessage(Translations.AddEditListingsNotificationsMustBeProvided, {
      field: <u>{formatMessage(field)}</u>,
    });
  };

  const formatRangeMessage = (field: { id: string; defaultMessage: string }) => {
    return formatMessage(Translations.AddEditListingsNotificationsMustBeWithinThePermittedRange, {
      field: <u>{formatMessage(field)}</u>,
    });
  };

  const formatGeneralMessage = (field: { id: string; defaultMessage: string }) => {
    return formatMessage(Translations.AddEditListingsNotificationsFieldMustMeetTheRequiredCriteria, {
      field: <u>{formatMessage(field)}</u>,
    });
  };

  const errorHeaderMessages: ErrorValidationListFields = {
    make: formatRequiredMessage(VehicleBaseInformationTranslations.VehicleBaseInformationFormMake),
    modelSlug: formatRequiredMessage(VehicleBaseInformationTranslations.VehicleBaseInformationFormModel),
    version: formatRangeMessage(VehicleBaseInformationTranslations.VehicleBaseInformationFormVersion),
    condition: formatRequiredMessage(VehicleBaseInformationTranslations.VehicleBaseInformationFormCondition),
    lifetimeMileage: formatGeneralMessage(VehicleBaseInformationTranslations.VehicleBaseInformationFormLifetimeMileage),
    modelYear: formatGeneralMessage(VehicleBaseInformationTranslations.VehicleBaseInformationFormModelYear),
    modelDesignationNumber: formatRangeMessage(
      VehicleBaseInformationTranslations.VehicleBaseInformationFormModelDesignationNumber
    ),
    vin: formatRangeMessage(VehicleBaseInformationTranslations.VehicleBaseInformationFormVinChassisNumber),
    internalId: formatRangeMessage(VehicleBaseInformationTranslations.VehicleBaseInformationFormInternalID),
    deliveredIn: formatGeneralMessage(DeliveredInTranslations.DeliveredInDeliveredIn),
    guaranteeText: formatRangeMessage(GuaranteeTranslations.GuaranteeFormBoxGuaranteeText),
    warrantyInKM: formatGeneralMessage(GuaranteeTranslations.GuaranteeFormBoxWarranyInKM),
    warrantyInMonths: formatGeneralMessage(GuaranteeTranslations.GuaranteeFormBoxWarranyInMonths),
    price: formatGeneralMessage(CarPriceFormTranslations.CarPriceFormPrice),
    newPrice: formatGeneralMessage(CarPriceFormTranslations.CarPriceFormNewPrice),
    dealerDeliveryFee: formatGeneralMessage(CarPriceFormTranslations.CarPriceFormDealerDeliveryFee),
    leasingProvider: formatRequiredMessage(CarPriceFormTranslations.CarPriceFormLeasingProvider),
    interestRate: formatRequiredMessage(CarPriceFormTranslations.CarPriceFormInterestRate),
    monthlyManagementFee: formatRangeMessage(CarPriceFormTranslations.CarPriceFormMonthlyManagementFee),
    gearboxDetailed: formatRequiredMessage(TechnicalInformationTranslations.TechnicalInformationFormGearboxDetailed),
    drivenWheels: formatRequiredMessage(TechnicalInformationTranslations.TechnicalInformationFormDrivenWheels),
    exteriorColor: formatRangeMessage(TechnicalInformationTranslations.TechnicalInformationFormExteriorColour),
    interiorColor: formatRangeMessage(TechnicalInformationTranslations.TechnicalInformationFormInteriorColour),
    seats: formatGeneralMessage(TechnicalInformationTranslations.TechnicalInformationFormSeats),
    doors: formatGeneralMessage(TechnicalInformationTranslations.TechnicalInformationFormDoors),
    cylinders: formatRangeMessage(TechnicalInformationTranslations.TechnicalInformationFormCylinders),
    horsepower: formatGeneralMessage(TechnicalInformationTranslations.TechnicalInformationFormHorsepower),
    engineCapacity: formatRangeMessage(TechnicalInformationTranslations.TechnicalInformationFormEngineCapacityCm3),
    co2emissions: formatRangeMessage(TechnicalInformationTranslations.TechnicalInformationFormCo2Emissions),
    consumptionCity: formatRangeMessage(ConsumptionTranslations.ConsumptionInputCity),
    consumptionCountry: formatRangeMessage(ConsumptionTranslations.ConsumptionInputCountry),
    consumptionTotal: formatRangeMessage(ConsumptionTranslations.ConsumptionInputTotal),
    powerConsumption: formatRangeMessage(
      TechnicalInformationTranslations.TechnicalInformationFormPowerConsumptionKwh100km
    ),
    efficiencyRating: formatRequiredMessage(TechnicalInformationTranslations.TechnicalInformationFormEfficiencyRating),
    euroStandard: formatRequiredMessage(TechnicalInformationTranslations.TechnicalInformationFormEuroStandard),
    tare: formatRangeMessage(TechnicalInformationTranslations.TechnicalInformationFormTare),
    trailerLoad: formatRangeMessage(TechnicalInformationTranslations.TechnicalInformationFormTrailerLoad),
    residualValues: formatGeneralMessage(ResidualValuesTranslations.ResidualValuesBreakdownFormResidualValues),
  };

  return (
    <>
      {successfullyUpdated ? (
        <div className={styles.messageContainer}>
          <Alert
            type={AlertType.SUCCESS}
            message={formatMessage(Translations.AddEditListingsNotificationsListingWasSaved)}
            onClose={onSuccessSavedMessageClose}
          />
        </div>
      ) : showPublishErrors ? (
        <ErrorMessage
          title={formatMessage(Translations.AddEditListingsNotificationsListingCouldNotBePublishedDueTo)}
          classes={{
            root: styles.messageContainer,
          }}
        >
          <ul className={styles.dash}>
            {Object.entries(publishErrors).map(([key, value]) => renderErrorMessage(key, value))}
          </ul>
        </ErrorMessage>
      ) : backendErrorMessages?.length ? (
        <ErrorMessage
          title={formatMessage(Translations.AddEditListingsNotificationsListingCouldNotBePublishedDueTo)}
          classes={{
            root: styles.messageContainer,
          }}
        >
          <ul className={styles.dash}>
            {backendErrorMessages.map((errMsg) => (
              <li key={errMsg}>{errMsg}</li>
            ))}
          </ul>
        </ErrorMessage>
      ) : isSyncNotificationVisible ? (
        <div className={styles.messageContainer}>
          <Alert
            type={AlertType.SUCCESS}
            message={
              !values.isLockedToSource
                ? formatMessage(Translations.AddEditListingsNotificationsSyncWithAutoScout24WasDisabled)
                : formatMessage(Translations.AddEditListingsNotificationsSyncWithAutoScout24WasEnabled)
            }
            onClose={onSuccessSyncMessageClose}
          />
        </div>
      ) : isError && Object.keys(draftValidationErrors).length === 0 ? (
        <div className={styles.messageContainer}>
          <ErrorMessage title={formatMessage(Translations.AddEditListingsNotificationsSomethingWentWrong)}>
            <div>
              {errorType === ErrorType.SOMETHING_WENT_WRONG
                ? formatMessage(Translations.AddEditListingsNotificationsIfThisIssuePersistPleaseContactOurSupport)
                : errorType === ErrorType.MAX_AGE_OF_CAR
                ? formatMessage(Translations.AddEditListingsNotificationsAgeMustBeLessThanMonths, errorMessageParams)
                : errorType === ErrorType.MINIMUM_PRICE
                ? formatMessage(Translations.AddEditListingsNotificationsPriceMustBeGreaterThan, errorMessageParams)
                : errorType === ErrorType.MAXIMUM_PRICE
                ? formatMessage(Translations.AddEditListingsNotificationsPriceMustBeLessThan, errorMessageParams)
                : errorType === ErrorType.MINIMUM_LIST_PRICE
                ? formatMessage(Translations.AddEditListingsNotificationsListPriceMustBeGreaterThan, errorMessageParams)
                : errorType === ErrorType.MAXIMUM_LIST_PRICE
                ? formatMessage(Translations.AddEditListingsNotificationsListPriceMustBeLessThan, errorMessageParams)
                : errorType === ErrorType.SAME
                ? formatMessage(
                    Translations.AddEditListingsNotificationsTheListingWithTheSameParametersHasAlreadyBeenAdded
                  )
                : errorType === ErrorType.NO_RESIDUAL_VALUES
                ? errorMessageParams?.noResidualValues
                : ''}
            </div>
          </ErrorMessage>
        </div>
      ) : status === FormStatus.PUBLISH && !!submitCount && !!Object.keys(errors).length ? (
        <ErrorMessage
          title={formatMessage(Translations.AddEditListingsNotificationsListingCouldNotBePublishedDueTo)}
          classes={{
            root: styles.messageContainer,
          }}
        >
          <ErrorValidationList errorMessages={errorHeaderMessages} errors={Object.keys(errors)} />
        </ErrorMessage>
      ) : status === FormStatus.DRAFT && Object.keys(draftValidationErrors).length > 0 ? (
        <ErrorMessage
          title={formatMessage(Translations.AddEditListingsNotificationsListingCouldNotBeSavedToDraft)}
          classes={{
            root: styles.messageContainer,
          }}
        >
          <ErrorValidationList errorMessages={errorHeaderMessages} errors={Object.keys(draftValidationErrors)} />
        </ErrorMessage>
      ) : (
        <Fragment />
      )}
    </>
  );
};

export default Notifications;
