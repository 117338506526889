import { defineMessages } from 'react-intl';

export default defineMessages({
  AppTheServerIsTemporarilyUnavailable: {
    id: 'AppTheServerIsTemporarilyUnavailable',
    defaultMessage: 'The server is temporarily unavailable',
  },
  AppErrorUploadingFile: {
    id: 'AppErrorUploadingFile',
    defaultMessage: 'Error uploading file',
  },
  AppErrorFetchingFiles: {
    id: 'AppErrorFetchingFiles',
    defaultMessage: 'Error fetching files',
  },
  AppErrorDeletingFile: {
    id: 'AppErrorDeletingFile',
    defaultMessage: 'Error deleting file',
  },
  AppErrorDownloadingFile: {
    id: 'AppErrorDownloadingFile',
    defaultMessage: 'Error downloading file',
  },
  AppNoInformation: {
    id: 'AppNoInformation',
    defaultMessage: 'No information',
  },
});
