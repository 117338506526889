export const loadImage = (file: Blob, onLoad: (src: string) => void) => {
  const fileReader = new FileReader();
  fileReader.onload = (e: ProgressEvent<FileReader>) => {
    const result = e.target?.result;
    if (result) {
      onLoad(result as string);
    }
  };
  fileReader.readAsDataURL(file);
};
