import { createStyles } from '@material-ui/core';
import { GowagoTheme } from 'theme';

export const styles = ({
  breakpoints: { down },
  gowago: { spacing, utility, lineHeight, dawn, borders, fontSize },
}: GowagoTheme) =>
  createStyles({
    root: {},
    title: {
      marginTop: spacing.rem,
      marginBottom: spacing[8],
      fontSize: fontSize.smaller,
      lineHeight: lineHeight.rem[26],
    },
    link: {
      marginLeft: spacing.rem,
      fontWeight: 500,
      color: dawn,
      cursor: 'pointer',
      textDecoration: 'none',
    },
    errorRoot: {
      border: borders.red,
      background: utility.lightRed,
    },
  });
