import { defineMessages } from 'react-intl';

export default defineMessages({
  AddEditListingsRequired: {
    id: 'AddEditListingsRequired',
    defaultMessage: 'Required',
  },
  AddEditListingsTooShort: {
    id: 'AddEditListingsTooShort',
    defaultMessage: 'Too Short!',
  },
  AddEditListingsOnlyPositiveNumbersAllowed: {
    id: 'AddEditListingsOnlyPositiveNumbersAllowed',
    defaultMessage: 'Only positive numbers allowed',
  },
  AddEditListingsValueMustBeGreaterThan: {
    id: 'AddEditListingsValueMustBeGreaterThan',
    defaultMessage: 'Value must be greater than {value}',
  },
  AddEditListingsValueMustBeLessThan: {
    id: 'AddEditListingsValueMustBeLessThan',
    defaultMessage: 'Value must be less than {value}',
  },
  AddEditListingsTooLong: {
    id: 'AddEditListingsTooLong',
    defaultMessage: 'Too Long!',
  },
  AddEditListingsAddANewListing: {
    id: 'AddEditListingsAddANewListing',
    defaultMessage: 'Add a new listing',
  },
  AddEditListingsEditListing: {
    id: 'AddEditListingsEditListing',
    defaultMessage: 'Edit listing',
  },
  AddEditListingsGowagoID: {
    id: 'AddEditListingsGowagoID',
    defaultMessage: 'Gowago ID',
  },
  AddEditListingsCreated: {
    id: 'AddEditListingsCreated',
    defaultMessage: 'Created',
  },
  AddEditListingsYouHaveUnsavedChanged: {
    id: 'AddEditListingsYouHaveUnsavedChanged',
    defaultMessage: 'You have unsaved changes',
  },
  AddEditListingsContinueEditing: {
    id: 'AddEditListingsContinueEditing',
    defaultMessage: 'Continue editing',
  },
  AddEditListingsDiscardChanges: {
    id: 'VendorInformationDiscardChanges',
    defaultMessage: 'Discard changes',
  },
  AddEditListingsDisableSyncWithAutoScout24ToEditThisField: {
    id: 'AddEditListingsDisableSyncWithAutoScout24ToEditThisField',
    defaultMessage: 'Disable sync with AutoScout24 to edit this field',
  },
  AddEditListingsVehicleInformation: {
    id: 'AddEditListingsVehicleInformation',
    defaultMessage: 'Vehicle information',
  },
  AddEditListingsModelAndVersion: {
    id: 'AddEditListingsModelAndVersion',
    defaultMessage: 'Model and version',
  },
  AddEditListingsListingDetails: {
    id: 'AddEditListingsListingDetails',
    defaultMessage: 'Listing details',
  },
  AddEditListingsChangeModelAndVersion: {
    id: 'AddEditListingsChangeModelAndVersion',
    defaultMessage: 'Change model and version',
  },
  AddEditListingsYesChangeModelAndVersion: {
    id: 'AddEditListingsYesChangeModelAndVersion',
    defaultMessage: 'Yes, change model and version',
  },
  AddEditListingsChangeVehicleInformation: {
    id: 'AddEditListingsChangeVehicleInformation',
    defaultMessage: 'Change vehicle information',
  },
  AddEditListingsYesChangeVehicleInformation: {
    id: 'AddEditListingsYesChangeVehicleInformation',
    defaultMessage: 'Yes, change vehicle information',
  },
  AddEditListingsDateMustBe: {
    id: 'AddEditListingsDateMustBe',
    defaultMessage: 'Date must be between 1900 and 2100',
  },
});
