import { Reducer } from 'redux';
import { Actions, ActionTypes } from './actions';
import { initialState, ViewAllVendorsPageState } from './initial-state';

export const viewAllVendorsPageReducer: Reducer<ViewAllVendorsPageState> = (
  state = initialState,
  action: Actions
): ViewAllVendorsPageState => {
  switch (action.type) {
    case ActionTypes.VIEW_VENDORS_REQUEST: {
      return {
        ...state,
        query: {
          ...initialState.query,
          ...action.payload,
        },
        isFetchingResults: true,
      };
    }

    case ActionTypes.VIEW_VENDORS_SUCCESS: {
      return {
        ...state,
        result: {
          ...action.payload,
        },
        isFetchingResults: false,
      };
    }
    case ActionTypes.VIEW_VENDORS_FAILURE: {
      return {
        ...state,
        isFetchingResults: false,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
