import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StyledComponentProps, withStyles, WithStyles } from '@material-ui/core';
import clsx from 'clsx';
import { FC } from 'react';
import ReactDOM from 'react-dom';
import { compose } from 'utils/compose';
import { styles } from './DialogFloatingCloseButton.styles';

interface Props {
  onClick: () => void;
  isVisible: boolean;
}

const CloseButton: FC<Props & WithStyles<typeof styles>> = ({ classes, onClick, isVisible }) =>
  ReactDOM.createPortal(
    <FontAwesomeIcon
      icon={faTimes}
      className={clsx(classes.closeFloatingIcon, { [classes.hidden]: !isVisible })}
      onClick={onClick}
    />,
    document.getElementById('dialog-close-button')
  );

export default compose<Props & StyledComponentProps>(CloseButton, withStyles(styles, { name: 'CloseButton' }));
