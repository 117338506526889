import { defineMessages } from 'react-intl';

export default defineMessages({
  ConsumptionInputConsumptionL100km: {
    id: 'ConsumptionInputConsumptionL100km',
    defaultMessage: 'Consumption (L / 100km)',
  },
  ConsumptionInputCity: {
    id: 'ConsumptionInputCity',
    defaultMessage: 'City',
  },
  ConsumptionInputCountry: {
    id: 'ConsumptionInputCountry',
    defaultMessage: 'Country',
  },
  ConsumptionInputTotal: {
    id: 'ConsumptionInputTotal',
    defaultMessage: 'Total',
  },
});
