import clsx from 'clsx';
import Accordion from 'components/Accordion';
import RHFInput from 'components/Forms/RHFInput/RHFInput';
import RHFSelect from 'components/Forms/RHFSelect';
import LoadingIndicator from 'components/LoadingIndicator';
import { OrderPageState } from 'pages/EditOrderPage/store/initial-state';
import { RootState } from 'pages/_store/root-reducer';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { PublicOffer } from 'types';
import { getPrivateCreditDurationOptions } from 'utils/aio-packages';
import { useStyles } from '../FormContent.styles';
import { messages } from './OfferPrivateCreditForm.translations';

const privateCreditDurationOptions = getPrivateCreditDurationOptions();

const OfferPrivateCreditForm: FC = () => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const {
    orderPage: { isFetchingResult, order },
  } = useSelector<RootState, { orderPage: OrderPageState }>((state) => ({ orderPage: state.orderPage }), shallowEqual);

  const { setValue, errors } = useFormContext<PublicOffer>();
  const { privateCreditFinancingAmount, privateCreditDuration } = errors;

  const orderDeactivated = !!order?.datetimeDeactivated;

  const handlePrivateCreditDurationChange = (value: string) => setValue('privateCreditDuration', Number(value));

  return (
    <div className={clsx('OfferPrivateCreditForm', classes.root)}>
      <LoadingIndicator isLoading={isFetchingResult}>
        <Accordion header={formatMessage(messages.OfferPrivateCreditFormPrivateCredit)} collapsable={false}>
          <div className={classes.formRow}>
            <div className={classes.formColumn}>
              <RHFInput
                errorLabel={privateCreditFinancingAmount}
                startAdornment="CHF"
                type="number"
                name="privateCreditFinancingAmount"
                integer
                label={formatMessage(messages.OfferPrivateCreditFinancingAmount)}
                disabled={orderDeactivated}
                inputProps={{
                  step: 1000,
                  min: 1000,
                }}
              />
            </div>
            <div className={classes.formColumn}>
              <RHFSelect
                name="privateCreditDuration"
                label={formatMessage(messages.OfferPrivateCreditFormDuration)}
                options={privateCreditDurationOptions}
                onChange={handlePrivateCreditDurationChange}
                disabled={orderDeactivated}
                error={!!privateCreditDuration?.message}
              />
            </div>
            <div className={classes.formColumn}>
              <RHFInput
                name="privateCreditMonthlyRate"
                label={formatMessage(messages.OfferPrivateCreditFormMonthlyRate)}
                disabled
              />
            </div>
          </div>
          <div className={classes.formRow}>
            <div className={classes.formColumn}>
              <RHFInput
                name="privateCreditInterestRateNominal"
                label={formatMessage(messages.OfferPrivateCreditFormInterestRateNominal)}
                disabled
              />
            </div>
            <div className={classes.formColumn}>
              <RHFInput
                name="oemReservationFee"
                label={formatMessage(messages.OfferPrivateCreditFormOEMReservationFee)}
                disabled
              />
            </div>
            <div className={classes.formColumn}>
              <RHFInput
                name="privateCreditDownPayment"
                label={formatMessage(messages.OfferPrivateCreditFormDownPayment)}
                disabled
                integer
                type="number"
              />
            </div>
          </div>
        </Accordion>
      </LoadingIndicator>
    </div>
  );
};

export default OfferPrivateCreditForm;
