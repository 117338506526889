import { defineMessages } from 'react-intl';

export default defineMessages({
  ResetPasswordPageInvalidEmailFormat: {
    id: 'ResetPasswordPageInvalidEmailFormat',
    defaultMessage: 'Invalid email format',
  },
  ResetPasswordPageThisFieldIsRequired: {
    id: 'ResetPasswordPageThisFieldIsRequired',
    defaultMessage: 'This field is required',
  },
  ResetPasswordPageCheckYourInbox: {
    id: 'ResetPasswordPageCheckYourInbox',
    defaultMessage: 'Check your inbox',
  },
  ResetPasswordPageAnSuccessMessage: {
    id: 'ResetPasswordPageAnSuccessMessage',
    defaultMessage: 'If your email exists in our database, an email has been sent to recover access',
  },
  ResetPasswordPageResetPassword: {
    id: 'ResetPasswordPageResetPassword',
    defaultMessage: 'Reset password',
  },
  ResetPasswordPageEnterYourEmailToResetYourPassword: {
    id: 'ResetPasswordPageEnterYourEmailToResetYourPassword',
    defaultMessage: 'Enter your email to reset your password',
  },
  ResetPasswordPageBackToSignIn: {
    id: 'ResetPasswordPageBackToSignIn',
    defaultMessage: 'Back to sign in',
  },
  ResetPasswordPageEnterYourEmail: {
    id: 'ResetPasswordPageEnterYourEmail',
    defaultMessage: 'Enter your email',
  },
  ResetPasswordPageNeedSomeHelp: {
    id: 'ResetPasswordPageNeedSomeHelp',
    defaultMessage: 'Need some help?',
  },
  ResetPasswordPageResetPasswordSubmit: {
    id: 'ResetPasswordPageResetPasswordSubmit',
    defaultMessage: 'Reset password',
  },
});
