import { resetPasswordRequest } from 'api/user';
import { fork, put, takeEvery } from 'redux-saga/effects';
import { call } from 'typed-redux-saga';
import { Actions as ResetPasswordPageActions, ActionTypes as ResetPasswordPageActionTypes } from './actions';

function* resetPasswordAsync(action: ReturnType<typeof ResetPasswordPageActions.resetPasswordRequest>) {
  try {
    yield* call(resetPasswordRequest, action.payload);
    yield put(ResetPasswordPageActions.resetPasswordSuccess());
  } catch (e) {
    yield put(ResetPasswordPageActions.resetPasswordFailure(e));
  }
}

function* watchResetPasswordRequestAsync() {
  yield takeEvery(ResetPasswordPageActionTypes.RESET_PASSWORD_REQUEST, resetPasswordAsync);
}

export default [fork(watchResetPasswordRequestAsync)];
