import ConfirmationModal from 'components/ConfirmationModal';
import { GOWAGO_FOOTER_COPYRIGHT } from 'constants/constants';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { compose } from 'utils/compose';
import { getLang } from 'utils/local-storage';
import NavPanel from '../NavPanel';
import messages from './Footer.translations';
import styles from './MainContainer.module.scss';

const MainContainer: FC = ({ children }) => {
  const { formatMessage } = useIntl();
  const lang = getLang();
  const GOWAGO_PRIVACY_POLICY_PAGE = `${process.env.REACT_APP_GOWAGO_URL}${lang}/content/privacy-policy`;
  const GOWAGO_TERMS_OF_USE = `${process.env.REACT_APP_GOWAGO_URL}${lang}/content/terms-of-use-sales-portal`;

  return (
    <div className={styles.root}>
      <NavPanel />
      <div className={styles.content}>{children}</div>
      <ConfirmationModal />
      <div className={styles.footer}>
        <div className={styles.leftContent}>
          <a href={GOWAGO_TERMS_OF_USE} className={styles.link} target="_blank" rel="noopener noreferrer">
            {formatMessage(messages.FooterTermsOfUse)}
          </a>
          <a href={GOWAGO_PRIVACY_POLICY_PAGE} className={styles.link} target="_blank" rel="noopener noreferrer">
            {formatMessage(messages.FooterPrivacyPolicy)}
          </a>
        </div>
        <div className={styles.rightContent}>{GOWAGO_FOOTER_COPYRIGHT}</div>
      </div>
    </div>
  );
};

export default compose(MainContainer);
