import { FormikErrors } from 'formik';
import { Deal, DealOwnership, ListingPublic, ResidualValue } from 'types';

export enum ErrorType {
  SOMETHING_WENT_WRONG = 'SOMETHING_WENT_WRONG',
  MAX_AGE_OF_CAR = 'MAX_AGE_OF_CAR',
  MINIMUM_PRICE = 'MINIMUM_PRICE',
  MAXIMUM_PRICE = 'MAXIMUM_PRICE',
  MINIMUM_LIST_PRICE = 'MINIMUM_LIST_PRICE',
  MAXIMUM_LIST_PRICE = 'MAXIMUM_LIST_PRICE',
  SAME = 'SAME',
  NO_RESIDUAL_VALUES = 'NO_RESIDUAL_VALUES',
}

export interface AddEditListingsState {
  isLoadingDeal: boolean;
  isLoadingResidualValues: boolean;
  isLoadingAction: boolean;
  isLoadingCars: boolean;
  isLoadingImages: boolean;
  cars?: ListingPublic[];
  isError: boolean;
  errorMessages?: Array<string>;
  isSyncUpdateError: boolean;
  errorType: ErrorType;
  errorMessageParams: { [key: string]: string };
  unsavedChanges: boolean;
  defaultDealOwnership?: DealOwnership;
  defaultResidualValues?: ResidualValue[];
  deal?: Partial<Deal>;
  dealImages: File[];
  successfullyUpdated: boolean;
  successGotCars: boolean;
  isSyncNotificationVisible: boolean;
  isStepChangeDialogOpen: boolean;
  isDisableSyncDialogOpen: boolean;
  isEnableSyncDialogOpen: boolean;
  draftValidationErrors: FormikErrors<Deal>;
}

export const initialState: AddEditListingsState = {
  isLoadingDeal: false,
  isLoadingResidualValues: false,
  isLoadingAction: false,
  isLoadingCars: false,
  isLoadingImages: false,
  isError: false,
  errorType: ErrorType.SOMETHING_WENT_WRONG,
  errorMessageParams: {},
  isSyncUpdateError: false,
  dealImages: [],
  successfullyUpdated: false,
  successGotCars: false,
  unsavedChanges: false,
  isSyncNotificationVisible: false,
  isDisableSyncDialogOpen: false,
  isEnableSyncDialogOpen: false,
  isStepChangeDialogOpen: false,
  draftValidationErrors: {},
};
