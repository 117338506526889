import classNames from 'classnames';
import Card from 'components/Card';
import LoadingIndicator from 'components/LoadingIndicator';
import { RootState } from 'pages/_store/root-reducer';
import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { sellersComparer } from 'utils/comparer-functions';
import Header from './components/Header';
import Notifications from './components/Notifications';
import SellerRow from './components/SellerRow';
import styles from './ManagePeople.module.scss';
import Translations from './ManagePeople.translations';

const ManagePeople: FunctionComponent = () => {
  const { formatMessage } = useIntl();

  const {
    managePeoplePage: { isLoading, isError, showSuccessMessage },
    sellers,
  } = useSelector(
    (state: RootState) => ({
      managePeoplePage: state.managePeoplePage,
      sellers: state.managePeoplePage.sellers.sort(sellersComparer),
    }),
    shallowEqual
  );

  return (
    <div>
      <Header />
      <Notifications />
      <Card
        className={classNames(styles.card, { [styles.cardNotification]: isError || showSuccessMessage })}
        classes={{
          body: styles.cardBody,
        }}
      >
        <LoadingIndicator isLoading={isLoading}>
          <div className={styles.title}>{formatMessage(Translations.ManagePeoplePeople)}</div>
          <div className={styles.container}>
            <div className={styles.descriptionContainer}>
              {formatMessage(Translations.ManagePeopleContactOurSupportTeamToAddAndRemovePeople)}
            </div>
            <div className={styles.content}>
              {sellers.map((seller) => (
                <SellerRow key={seller.id} seller={seller} />
              ))}
            </div>
          </div>
        </LoadingIndicator>
      </Card>
    </div>
  );
};

export default ManagePeople;
