import { useIntl } from 'react-intl';
import { OptionExtended } from 'types';
import Translations from '../pages/AddEditListings/components/StandardEquipmentForm/StandardEquipmentForm.translations';

export const useRenderSortedStandardEquipmentTranslationsLabels = () => {
  const { formatMessage } = useIntl();

  const getLabel = (item: string) => {
    const translation = (Translations as any)[`StandardEquipmentForm_${item}`];
    return translation ? formatMessage(translation) : item;
  };

  const keys = Object.keys(OptionExtended);
  const options: string[] = keys.map((k) => (OptionExtended as any)[k]);

  const listItems = options
    .map((value) => ({
      label: getLabel(value),
      key: value,
    }))
    .sort(({ label: l1 }, { label: l2 }) => {
      if (l1 > l2) return 1;
      if (l1 < l2) return -1;
      return 0;
    });

  return listItems;
};
