import GlobalAppStateSelectors from 'pages/_store/global/selectors';
import { RootState } from 'pages/_store/root-reducer';

const extractIsError = (state: RootState) => state.addEditListingsPage.isError;
const extractSuccessGotCars = (state: RootState) => state.addEditListingsPage.successGotCars;
const extractErrorType = (state: RootState) => state.addEditListingsPage.errorType;
const extractErrorMessageParams = (state: RootState) => state.addEditListingsPage.errorMessageParams;
const extractDraftValidationErrors = (state: RootState) => state.addEditListingsPage.draftValidationErrors;
const extractSuccessfullyUpdated = (state: RootState) => state.addEditListingsPage.successfullyUpdated;
const extractIsSyncNotificationVisible = (state: RootState) => state.addEditListingsPage.isSyncNotificationVisible;
const extractIsLoadingAction = (state: RootState) => state.addEditListingsPage.isLoadingAction;
const extractIsLoadingDeal = (state: RootState) => state.addEditListingsPage.isLoadingDeal;
const extractIsLoadingCars = (state: RootState) => state.addEditListingsPage.isLoadingCars;
const extractCars = (state: RootState) => state.addEditListingsPage.cars;
const extractIsCarSelected = (state: RootState) => state.addEditListingsPage.deal?.isSelectedCar;
const extractIsStepDialogOpen = (state: RootState) => state.addEditListingsPage.isStepChangeDialogOpen;
const extractDeal = (state: RootState) => state.addEditListingsPage.deal;
const extractBackendErrorMessages = (state: RootState) => state.addEditListingsPage.errorMessages;
const extractPublishErrors = (state: RootState) => state.addEditListingsPage.deal?.publishErrors;

const getIsLoadingVehicleInformation = (state: RootState) => {
  const isLoadingAction = extractIsLoadingAction(state);
  const isLoadingDeal = extractIsLoadingDeal(state);
  const isLoadingCars = extractIsLoadingCars(state);
  const isLoadingCarMakes = GlobalAppStateSelectors.extractIsLoadingCarMakes(state);

  return isLoadingAction || isLoadingDeal || isLoadingCars || isLoadingCarMakes;
};

export default {
  extractIsError,
  extractSuccessGotCars,
  extractErrorType,
  extractDraftValidationErrors,
  extractSuccessfullyUpdated,
  extractIsSyncNotificationVisible,
  extractErrorMessageParams,
  extractIsLoadingCars,
  extractCars,
  extractIsCarSelected,
  extractIsStepDialogOpen,
  extractDeal,
  extractBackendErrorMessages,
  extractPublishErrors,
  getIsLoadingVehicleInformation,
};
