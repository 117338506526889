import { defineMessages } from 'react-intl';

export default defineMessages({
  HeaderNavigationMoveItemsSelectedToTrash: {
    id: 'HeaderNavigationMoveItemsSelectedToTrash',
    defaultMessage: 'Move {itemsSelected} to trash',
  },
  HeaderNavigationDuplicate: {
    id: 'HeaderNavigationDuplicate',
    defaultMessage: 'Duplicate',
  },
  HeaderNavigationChangeStatus: {
    id: 'HeaderNavigationChangeStatus',
    defaultMessage: 'Change status',
  },
  HeaderNavigationPublishedItem: {
    id: 'HeaderNavigationPublishedItem',
    defaultMessage: 'Published',
  },
  HeaderNavigationDraftItem: {
    id: 'HeaderNavigationDraftItem',
    defaultMessage: 'Draft',
  },
  HeaderNavigationDeletedItem: {
    id: 'HeaderNavigationDeletedItem',
    defaultMessage: 'Deleted',
  },
  HeaderNavigationRemoveListingsFromGowagoCh: {
    id: 'HeaderNavigationRemoveListingsFromGowagoCh',
    defaultMessage: 'Remove listings from gowago.ch?',
  },
  HeaderNavigationYesMoveListingToTrash: {
    id: 'HeaderNavigationYesMoveListingToTrash',
    defaultMessage: 'Yes, move listing to trash',
  },
  HeaderNavigationCancel: {
    id: 'HeaderNavigationCancel',
    defaultMessage: 'Cancel',
  },
});
