import { IntlShape } from 'react-intl';
import { ConditionType, Segments, SelectOption } from 'types';
import { selectCompareFn } from 'utils/comparer-functions';
import Translations from './VehicleBaseInformationForm.translations';

export const getConditionOptions = ({ formatMessage }: IntlShape): SelectOption[] =>
  [
    {
      label: formatMessage(Translations.VehicleBaseInformationFormNew),
      value: ConditionType.NEW,
    },
    {
      label: formatMessage(Translations.VehicleBaseInformationFormUsed),
      value: ConditionType.USED,
    },
    {
      label: formatMessage(Translations.VehicleBaseInformationFormNewSingleDayRegistration),
      value: ConditionType.NEW_SINGLE_DAY_REGISTRATION,
    },
    {
      label: formatMessage(Translations.VehicleBaseInformationFormDisplayModel),
      value: ConditionType.DISPLAY_MODEL,
    },
    {
      label: formatMessage(Translations.VehicleBaseInformationFormOldTimer),
      value: ConditionType.OLD_TIMER,
    },
  ].sort(selectCompareFn());

export const getBodyShape = ({ formatMessage }: IntlShape): SelectOption[] =>
  [
    {
      label: formatMessage(Translations.VehicleBaseInformationFormConvertible),
      value: Segments.CONVERTIBLE,
    },
    {
      label: formatMessage(Translations.VehicleBaseInformationFormSuv),
      value: Segments.SUV,
    },
    {
      label: formatMessage(Translations.VehicleBaseInformationFormWagon),
      value: Segments.WAGON,
    },
    {
      label: formatMessage(Translations.VehicleBaseInformationFormSedan),
      value: Segments.SEDAN,
    },
    {
      label: formatMessage(Translations.VehicleBaseInformationFormVan),
      value: Segments.VAN,
    },
    {
      label: formatMessage(Translations.VehicleBaseInformationFormSmallCar),
      value: Segments.SMALL_CAR,
    },
    {
      label: formatMessage(Translations.VehicleBaseInformationFormSportsCar),
      value: Segments.SPORTS_CAR,
    },
  ].sort(selectCompareFn());
