import { OrderPageState } from 'pages/EditOrderPage/store/initial-state';
import { orderPageReducer } from 'pages/EditOrderPage/store/reducers';
import { ManagePeopleState } from 'pages/ManagePeople/store/initial-state';
import { managePeopleReducer } from 'pages/ManagePeople/store/reducers';
import { RegisterPageState } from 'pages/Public/RegisterPage/store/initial-state';
import { registerPageReducer } from 'pages/Public/RegisterPage/store/reducers';
import { DealResultsState } from 'pages/ViewAllDeals/store/initial-state';
import { dealResultReducer } from 'pages/ViewAllDeals/store/reducers';
import { OrdersPageState } from 'pages/ViewAllOrders/store/initial-state';
import { ordersPageReducer } from 'pages/ViewAllOrders/store/reducers';
import { combineReducers } from 'redux';
import { ChangePasswordState } from '../AccountSettings/pages/ChangePassword/store/initial-state';
import { changePasswordReducer } from '../AccountSettings/pages/ChangePassword/store/reducers';
import { SellerProfileState } from '../AccountSettings/pages/SellerProfile/store/initial-state';
import { sellerProfileReducer } from '../AccountSettings/pages/SellerProfile/store/reducers';
import { AddEditListingsState } from '../AddEditListings/store/initial-state';
import { addEditListingsReducer } from '../AddEditListings/store/reducers';
import { ResetPasswordPageState } from '../Public/ResetPasswordPage/store/initial-state';
import { resetPasswordPageReducer } from '../Public/ResetPasswordPage/store/reducers';
import { VendorInformationState } from '../VendorInformation/store/initial-state';
import { vendorInformationReducer } from '../VendorInformation/store/reducers';
import { ViewAllVendorsPageState } from '../ViewAllVendors/store/initial-state';
import { viewAllVendorsPageReducer } from '../ViewAllVendors/store/reducers';
import { GlobalAppState } from './global/initial-state';
import { globalAppReducer } from './global/reducers';

export interface RootState {
  globalAppState: GlobalAppState;
  dealResultsState: DealResultsState;
  registrationPage: RegisterPageState;
  resetPasswordPage: ResetPasswordPageState;
  viewAllVendorsPage: ViewAllVendorsPageState;
  vendorInformationPage: VendorInformationState;
  sellerProfilePage: SellerProfileState;
  changePasswordPage: ChangePasswordState;
  addEditListingsPage: AddEditListingsState;
  managePeoplePage: ManagePeopleState;
  ordersPage: OrdersPageState;
  orderPage: OrderPageState;
}

export const rootReducer = combineReducers<RootState>({
  globalAppState: globalAppReducer,
  dealResultsState: dealResultReducer,
  registrationPage: registerPageReducer,
  resetPasswordPage: resetPasswordPageReducer,
  viewAllVendorsPage: viewAllVendorsPageReducer,
  vendorInformationPage: vendorInformationReducer,
  sellerProfilePage: sellerProfileReducer,
  changePasswordPage: changePasswordReducer,
  addEditListingsPage: addEditListingsReducer,
  managePeoplePage: managePeopleReducer,
  ordersPage: ordersPageReducer,
  orderPage: orderPageReducer,
});
