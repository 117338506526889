import { useQuery, useUpdateQuery } from 'hooks/router';
import { Actions as ViewDealsActions } from 'pages/ViewAllDeals/store/actions';
import { FC, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { VendorQuery, VendorStatus } from 'types';
import { RootState } from '../_store/root-reducer';
import FilterPanel from './components/FilterPanel';
import Header from './components/Header';
import VendorResults from './components/VendorResults';
import { Actions as ViewVendorActions } from './store/actions';
import { initialState } from './store/initial-state';
import styles from './ViewAllVendors.module.scss';

const ViewAllVendors: FC = () => {
  const dispatch = useDispatch();

  const query = useQuery<Partial<VendorQuery>>({
    initialObj: initialState.query,
  });
  const updateQuery = useUpdateQuery<Partial<VendorQuery>>({
    replace: true,
    initialState: initialState.query,
  });
  const [isInit, setInit] = useState(false);
  const [searchTerm, setSearchTerm] = useState(query?.name);

  useEffect(() => {
    if (!isInit && Object.keys(query).length === 0) {
      updateQuery({
        vendorStatus: [VendorStatus.ACTIVE],
      });
      setInit(true);
    } else {
      dispatch(ViewVendorActions.viewVendorsRequest(query));
    }
    dispatch(ViewDealsActions.resetSelectedVendor());
  }, [query, dispatch, isInit, updateQuery]);

  const countFiltered = useSelector<RootState, number>(
    (state) => state.viewAllVendorsPage.result.countFiltered,
    shallowEqual
  );

  return (
    <div className={styles.root}>
      <Header $total={countFiltered} />
      <div className={styles.resultsContainer}>
        <div className={styles.filterContainer}>
          <FilterPanel searchTerm={searchTerm} onSearchTermChange={setSearchTerm} />
        </div>
        <VendorResults onSearchTermChange={setSearchTerm} />
      </div>
    </div>
  );
};

export default withRouter(ViewAllVendors);
