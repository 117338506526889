import { Actions as GlobalActions } from 'pages/_store/global/actions';
import { RootState } from 'pages/_store/root-reducer';
import { Fragment, FunctionComponent } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { compose } from 'utils/compose';
import ConfirmationModalBase from '../ConfirmationModalBase';

const ConfirmationModal: FunctionComponent = () => {
  const dispatch = useDispatch();
  const {
    confirmationModal: { message, okLabel, closeLabel, callback, isOpen },
  } = useSelector(
    (state: RootState) => ({
      confirmationModal: state.globalAppState.confirmationModal,
    }),
    shallowEqual
  );

  const onButtonClick = (ok: boolean) => () => {
    callback(!ok);
    dispatch(GlobalActions.closeConfirmationModal(!ok));
  };

  if (!isOpen) {
    return <Fragment />;
  }

  return (
    <ConfirmationModalBase
      message={message}
      onClose={onButtonClick(false)}
      onOkay={onButtonClick(true)}
      closeLabel={closeLabel}
      isOpen={isOpen}
      okLabel={okLabel}
    />
  );
};

export default compose(ConfirmationModal);
