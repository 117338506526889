import { FC } from 'react';
import { useIntl } from 'react-intl';
import { compose } from 'utils/compose';
import styles from './ViewAllOrdersHeader.module.scss';
import Translations from './ViewAllOrdersHeader.translations';

interface Props {
  $total: number;
}

const ViewAllOrdersHeader: FC<Props> = ({ $total }) => {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.root}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>{formatMessage(Translations.ViewAllVendorsHeaderAllOrders)}</div>
        <div className={styles.listingsResultLabel}>
          {formatMessage(Translations.ViewAllOrdersHeaderShowingCountFilteredOrders, {
            countFiltered: $total,
          })}
        </div>
      </div>
    </div>
  );
};

export default compose<Props>(ViewAllOrdersHeader);
