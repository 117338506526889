import { defineMessages } from 'react-intl';

export default defineMessages({
  AllInOneSectionCopy: {
    id: 'AllInOneSectionCopy',
    defaultMessage: 'Copy',
  },
  AllInOneSectionCopied: {
    id: 'AllInOneSectionCopied',
    defaultMessage: 'Copied',
  },
});
