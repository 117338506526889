import { defineMessages } from 'react-intl';

export default defineMessages({
  ViewAllVendorsHeaderAllOrders: {
    id: 'ViewAllVendorsHeaderAllOrders',
    defaultMessage: 'All orders',
  },
  ViewAllOrdersHeaderShowingCountFilteredOrders: {
    id: 'ViewAllOrdersHeaderShowingCountFilteredOrders',
    defaultMessage: 'Showing {countFiltered} orders',
  },
});
