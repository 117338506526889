import { createStyles } from '@material-ui/core';
import { GowagoTheme } from 'theme';

export const styles = (theme: GowagoTheme) =>
  createStyles({
    root: {
      fontSize: '0.875rem',
      lineHeight: '1.375rem',
    },
  });
