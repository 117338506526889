import { Reducer } from 'redux';
import { removeJWTToken, setJWTToken } from 'utils/cookies';
import { v4 as uuid } from 'uuid';
import { Actions, ActionTypes } from './actions';
import { GlobalAppState, initialState } from './initial-state';

export const globalAppReducer: Reducer<GlobalAppState> = (state = initialState, action: Actions): GlobalAppState => {
  switch (action.type) {
    case ActionTypes.TOGGLE_SIDEPANEL_NAV: {
      return {
        ...state,
        mainUI: {
          ...state.mainUI,
          isSidePanelVisible: !state.mainUI.isSidePanelVisible,
        },
      };
    }
    case ActionTypes.USER_LOGIN_REQUEST: {
      return {
        ...state,
        isLoginInvalid: false,
        isLoginFetching: true,
      };
    }
    case ActionTypes.USER_LOGIN_SUCCESS: {
      setJWTToken(action.payload);

      return {
        ...state,
        user: {
          ...state.user,
          isAuthenticated: true,
        },
        isLoginFetching: false,
      };
    }
    case ActionTypes.USER_LOGIN_FAILURE: {
      return {
        ...state,
        isLoginInvalid: true,
        isLoginFetching: false,
      };
    }
    case ActionTypes.SET_USER_LOGIN_FETCHING: {
      return {
        ...state,
        isLoginFetching: action.payload,
      };
    }
    case ActionTypes.GOOGLE_USER_LOGIN_REQUEST: {
      return {
        ...state,
        isLoginInvalid: false,
        isLoginFetching: true,
      };
    }
    case ActionTypes.GOOGLE_USER_LOGIN_SUCCESS: {
      setJWTToken(action.payload);

      return {
        ...state,
        user: {
          ...state.user,
          isAuthenticated: true,
        },
        isLoginFetching: false,
      };
    }
    case ActionTypes.GOOGLE_USER_LOGIN_FAILURE: {
      return {
        ...state,
        isLoginInvalid: true,
        isLoginFetching: false,
      };
    }
    case ActionTypes.USER_ME_REQUEST: {
      return {
        ...state,
      };
    }

    case ActionTypes.USER_ME_SUCCESS: {
      return {
        ...state,
        isInitialLoaded: true,
        user: {
          ...state.user,
          ...action.payload.userInfo,
          vendors: action.payload?.vendors || state.user.vendors,
          isAuthenticated: true,
        },
      };
    }
    case ActionTypes.USER_ME_FAILURE: {
      return {
        ...state,
        isInitialLoaded: true,
        user: {
          ...state.user,
          isAuthenticated: false,
        },
      };
    }

    case ActionTypes.USER_RESET_PASSWORD_REQUEST: {
      return {
        ...state,
        isPasswordRequestFetching: true,
        resetPasswordError: undefined,
      };
    }

    case ActionTypes.USER_RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        isPasswordRequestFetching: false,
        isPasswordResetSuccess: true,
      };
    }
    case ActionTypes.USER_RESET_PASSWORD_FAILURE: {
      return {
        ...state,
        resetPasswordError: action.payload,
        isPasswordRequestFetching: false,
      };
    }
    case ActionTypes.USER_LOGOUT: {
      removeJWTToken();

      return {
        ...initialState,
        isInitialLoaded: true,
      };
    }
    case ActionTypes.USER_TOKEN_EXPIRE: {
      removeJWTToken();

      return {
        ...initialState,
        isInitialLoaded: true,
      };
    }
    case ActionTypes.SEND_NOTIFICATION: {
      return {
        ...state,
        notifications: [...state.notifications, { id: uuid(), ...action.payload }],
      };
    }
    case ActionTypes.SEND_NOTIFICATIONS: {
      return {
        ...state,
        notifications: [...state.notifications, ...action.payload.map((props) => ({ id: uuid(), ...props }))],
      };
    }
    case ActionTypes.REMOVE_NOTIFICATION: {
      return {
        ...state,
        notifications: [...state.notifications.filter((n) => n.id !== action.payload)],
      };
    }
    case ActionTypes.SET_CONFIRMATION_MODAL_PROPS: {
      return {
        ...state,
        confirmationModal: {
          ...state.confirmationModal,
          ...action.payload,
        },
      };
    }
    case ActionTypes.OPEN_CONFIRMATION_MODAL: {
      return {
        ...state,
        confirmationModal: {
          ...state.confirmationModal,
          callback: action.payload,
          isOpen: true,
        },
      };
    }

    case ActionTypes.CLOSE_CONFIRMATION_MODAL: {
      const reset = action.payload;
      if (reset) {
        return {
          ...state,
          confirmationModal: {
            ...initialState.confirmationModal,
          },
        };
      }
      return {
        ...state,
        confirmationModal: {
          ...state.confirmationModal,
          isOpen: false,
        },
      };
    }

    case ActionTypes.GET_CAR_MAKES_REQUEST: {
      return {
        ...state,
        isLoadingCarMakes: true,
      };
    }

    case ActionTypes.GET_CAR_MAKES_SUCCESS: {
      return {
        ...state,
        carMakes: action.payload,
        isLoadingCarMakes: false,
      };
    }
    case ActionTypes.GET_CAR_MAKES_FAILURE: {
      return {
        ...state,
        isLoadingCarMakes: false,
      };
    }

    default: {
      return state;
    }
  }
};
