import { ReactComponent as Logo } from 'assets/images/gowago_logo_ch_small_dark.svg';
import { FC } from 'react';
import styles from './InitialPageLoadingIndicator.module.scss';

const InitialPageLoadingIndicator: FC = () => (
  <div className={styles.root}>
    <div>
      <Logo className={styles.logo} />
    </div>
  </div>
);

export default InitialPageLoadingIndicator;
