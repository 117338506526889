import { defineMessages } from 'react-intl';

export default defineMessages({
  ViewAllVendorsFilterPanelFilterVendors: {
    id: 'ViewAllVendorsFilterPanelFilterVendors',
    defaultMessage: 'Filter vendors',
  },
  ViewAllVendorsFilterPanelSearchFor: {
    id: 'ViewAllVendorsFilterPanelSearchFor',
    defaultMessage: 'Search for...',
  },
  ViewAllVendorsFilterPanelStatus: {
    id: 'ViewAllVendorsFilterPanelStatus',
    defaultMessage: 'Status',
  },
  ViewAllVendorsFilterPanelActive: {
    id: 'ViewAllVendorsFilterPanelActive',
    defaultMessage: 'Active',
  },
  ViewAllVendorsFilterPanelInactive: {
    id: 'ViewAllVendorsFilterPanelInactive',
    defaultMessage: 'Inactive',
  },
  ViewAllVendorsFilterPanelDropped: {
    id: 'ViewAllVendorsFilterPanelDropped',
    defaultMessage: 'Dropped',
  },
  ViewAllVendorsFilterPanelTesting: {
    id: 'ViewAllVendorsFilterPanelTesting',
    defaultMessage: 'Testing',
  },
});
