import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { FunctionComponent, useMemo, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { compose } from 'utils/compose';
import { v4 as uuid } from 'uuid';
import styles from './TextArea.module.scss';

interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
  startAdornment?: IconProp | string;
  touched?: boolean;
  error?: boolean;
  rows?: number;
  tooltip?: string;
  classes?: {
    root?: string;
  };
}

const TextArea: FunctionComponent<Props> = ({
  startAdornment,
  touched,
  error,
  onBlur,
  disabled,
  classes = {},
  rows,
  tooltip,
  ...other
}) => {
  const cx = classNames.bind(styles);
  const [isFocused, setInputFocus] = useState(false);
  const tooltipUuid = useMemo(uuid, []);

  const handleFocus = () => {
    setInputFocus(true);
  };
  const handleBlur = (e: React.FocusEvent<any>) => {
    setInputFocus(false);
    if (onBlur) {
      onBlur(e);
    }
  };
  return (
    <>
      <div
        className={cx(
          styles.root,
          {
            active: isFocused,
            error: touched && error,
            disabled,
            withInputAdornment: !!startAdornment,
          },
          classes.root
        )}
        data-tip={tooltip}
        data-for={tooltipUuid}
      >
        {!!startAdornment && (
          <div
            className={cx(styles.inputAdornment, {
              start: true,
              isText: typeof startAdornment === 'string',
            })}
          >
            {typeof startAdornment === 'string' ? (
              startAdornment
            ) : (
              <FontAwesomeIcon icon={startAdornment} className={styles.adormentIcon} />
            )}
          </div>
        )}
        <textarea
          {...other}
          rows={rows}
          className={styles.input}
          disabled={disabled}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      </div>
      <ReactTooltip key={tooltip} effect="solid" id={tooltipUuid} />
    </>
  );
};

export default compose<Props>(TextArea);
