import { StyledComponentProps, withStyles, WithStyles } from '@material-ui/core';
import AddEditListingSelector from 'pages/AddEditListings/store/selectors';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { compose } from 'utils/compose';
import { styles } from './AddVehicleInformationSubmit.styles';

interface Props {
  onAddInfoManually: () => void;
}

const AddVehicleInformationSubmit: FC<Props & WithStyles<typeof styles>> = ({ classes, onAddInfoManually }) => {
  const cars = useSelector(AddEditListingSelector.extractCars, shallowEqual);

  return (
    <div className={classes.submitWrapper}>
      <button className={classes.submitButton} type="submit" disabled={!cars?.length}>
        <FormattedMessage id="AddVehicleInformationModelFindVehicle" defaultMessage="Find vehicle" />
      </button>
      <p className={classes.addInfoManually} onClick={onAddInfoManually}>
        <FormattedMessage id="AddVehicleInformationAddInfoManually" defaultMessage="Add vehicle information manually" />
      </p>
    </div>
  );
};

export default compose<Props & StyledComponentProps>(
  AddVehicleInformationSubmit,
  withStyles(styles, { name: 'AddVehicleInformationSubmit' })
);
