import Button from 'components/Forms/Button';
import { routeConstants } from 'constants/routes';
import { useNavigate } from 'hooks/router';
import DealResultsSelectors from 'pages/ViewAllDeals/store/selectors';
import { RootState } from 'pages/_store/root-reducer';
import { FunctionComponent, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { RolesEnum, Vendor } from 'types';
import { compose } from 'utils/compose';
import styles from './Header.module.scss';
import Translations from './Header.translations';

interface Props {
  $total: number;
}

const Header: FunctionComponent<Props> = ({ $total }) => {
  const vendor = useSelector<RootState, Vendor>((state) => DealResultsSelectors.getSelectedVendor(state));
  const vendorId = vendor?.id || 0;

  // Track user vendor via Heap
  useEffect(() => {
    if (vendor?.name) {
      heap.addUserProperties({ vendor: vendor.name });
      heap.track('select_vendor', { name: vendor.name, hubspotId: vendor.hubspotId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorId]);

  const roles = useSelector<RootState, string[]>((state: RootState) => state.globalAppState.user.roles, shallowEqual);

  const isUserAdmin = roles?.includes(RolesEnum.SUPER_ADMIN) || roles?.includes(RolesEnum.ADMIN);

  const visit = useNavigate(routeConstants.PROTECTED.ADD_LISTINGS_PAGE.url(vendorId));
  const { formatMessage } = useIntl();
  const onClick = () => {
    visit('');
  };
  return (
    <div className={styles.root}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>{formatMessage(Translations.ViewAllDealsHeaderAllListings)}</div>
        <div className={styles.listingsResultLabel}>
          {formatMessage(Translations.ViewAllDealsShowingCountListings, {
            count: $total,
          })}
        </div>
      </div>
      {vendorId && !!isUserAdmin && (
        <Button size="large" icon={['far', 'plus']} onClick={onClick}>
          {formatMessage(Translations.ViewAllDealsAddNewListing)}
        </Button>
      )}
    </div>
  );
};

export default compose<Props>(Header);
