import { diff } from 'deep-object-diff';
import { Location } from 'history';
import isNil from 'lodash.isnil';
import isObject from 'lodash.isobject';
import omitBy from 'lodash.omitby';
import qs from 'qs';
import { useCallback, useContext, useMemo } from 'react';
import { RouteComponentProps, __RouterContext as RouterContext } from 'react-router';
import uriTemplate, { URITemplate } from 'uri-templates';
import { convertFieldsToCorrespondingTypes } from 'utils/objects';

export const useRouter = <Params extends { [K in keyof Params]?: string } = {}>(): RouteComponentProps<Params> =>
  useContext(RouterContext) as RouteComponentProps<Params>;

export const useLocation = (): Location => {
  const { location } = useRouter();
  return location;
};

const cleanQueryparams = (newQuery: object, initialState: object) => {
  // first, let's clean up the string, if it's an empty string then make it undefined
  const cleanedQuery = {
    ...newQuery,
  };
  Object.keys(cleanedQuery).forEach((key) => {
    if (cleanedQuery[key] === '') {
      cleanedQuery[key] = undefined;
    }
    if (isObject(cleanedQuery[key])) {
      cleanedQuery[key] = omitBy(cleanedQuery[key], isNil);
    }
  });
  return diff(initialState, cleanedQuery);
};

export const useParams = <Params extends { [K in keyof Params]?: string } = {}>(): Params => {
  const { match } = useRouter<Params>();
  return match.params;
};

interface UseQueryParams<T> {
  initialObj?: T;
  decoder?: (val: string) => void;
}

export const useQuery = <T>({ initialObj, decoder }: UseQueryParams<T> = {}): T => {
  const { search } = useLocation();
  const query = useMemo(() => {
    const parseResult = qs.parse(search, {
      ignoreQueryPrefix: true,
      decoder,
    });

    if (initialObj) {
      return convertFieldsToCorrespondingTypes(parseResult, initialObj);
    }

    return parseResult;
  }, [initialObj, search, decoder]);
  return query as T;
};

interface UpdateQueryOptions {
  replace: boolean;
  initialState: object;
  replaceQuery?: boolean;
}

type UpdateQuery<T> = (patch: Partial<T>) => void;

type Visit<T> = (params: T) => void;

const USE_PUSH = { replace: false, initialState: {} };

export const queryStringify = (obj: any) => {
  return qs.stringify(obj, {
    encode: false,
  });
};

export const useUpdateQuery = <T>(options: UpdateQueryOptions = USE_PUSH): UpdateQuery<T> => {
  const { history } = useRouter();
  const query = useQuery<T>();
  const { replace, replaceQuery } = options;
  const updateQuery = useCallback(
    (patch: Partial<T>): void => {
      const newQuery = cleanQueryparams(replaceQuery ? { ...patch } : { ...query, ...patch }, options.initialState);
      const newSearch = queryStringify(newQuery);
      if (replace) {
        history.replace({ search: newSearch });
      } else {
        history.push({ search: newSearch });
      }
    },
    [history, options.initialState, query, replace, replaceQuery]
  );
  return updateQuery;
};

export const useNavigate = <T>(to: string | URITemplate, options: UpdateQueryOptions = USE_PUSH): Visit<T> => {
  const { history } = useRouter();
  const { replace } = options;
  const template = useMemo(() => {
    if (typeof to === 'string') {
      return uriTemplate(to);
    }
    return to;
  }, [to]);

  const visit = useCallback(
    (params: T): void => {
      const newLocation = template.fill(params as any);
      if (replace) {
        history.replace(newLocation);
      } else {
        history.push(newLocation);
      }
    },
    [template, history, replace]
  );

  return visit;
};
