import { defineMessages } from 'react-intl';

export default defineMessages({
  DataGridPaginationNext: {
    id: 'DataGridPaginationNext',
    defaultMessage: 'Next',
  },
  DataGridPaginationPrevious: {
    id: 'DataGridPaginationPrevious',
    defaultMessage: 'Previous',
  },
});
