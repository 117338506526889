import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Translation } from 'types';
import styles from './HorizontalStepper.module.scss';
import { StepState } from './types';

interface Props {
  activeStep?: number;
  stepTitles: Array<Translation>;
  stepContent: Array<React.ReactElement>;
  stepStates?: Array<StepState>;
  onStepChange?: (stepNumber: number) => void;
  onFinish?: () => void;
}

const HorizintalStepper: FC<Props> = ({ activeStep, stepTitles, stepContent, stepStates, onStepChange, onFinish }) => {
  const [_activeStep, _setActiveStep] = useState(activeStep ? activeStep : 0);
  const { formatMessage } = useIntl();

  useEffect(() => {
    _setActiveStep(activeStep || 0);
  }, [activeStep]);

  const isClickable = (step: number) => {
    const status = stepStates?.[step];
    return status !== 'DISABLED' && step < _activeStep;
  };

  const _onStepChange = (currentStep: number) => {
    if (!isClickable(currentStep)) return;

    // step scope has to be [0, numberOfSteps]
    if (currentStep <= stepTitles.length && currentStep >= 0) {
      // _setActiveStep(currentStep)

      if (onStepChange) onStepChange(currentStep);
    }

    if (currentStep === stepTitles.length + 1 && onFinish) {
      onFinish();
    }
  };

  const renderStepIcon = (currentStep: number) => {
    // if step icons are controlled
    if (stepStates?.length === stepTitles?.length) {
      return stepStates?.[currentStep] === 'COMPLETED' ? (
        <FontAwesomeIcon icon={['fas', 'check-circle']} className={styles.stepIcon} />
      ) : stepStates?.[currentStep] === 'DISABLED' || stepStates?.[currentStep] === 'ERROR' ? (
        <FontAwesomeIcon icon={['far', 'times-circle']} className={styles.stepIcon} />
      ) : (
        currentStep + 1 + '. '
      );
    }

    // if icons are not controlled (normal behaviour)
    return currentStep < _activeStep ? (
      <FontAwesomeIcon icon={['fas', 'check-circle']} className={styles.stepIcon} />
    ) : (
      currentStep + 1 + '. '
    );
  };

  const renderSteps = () => {
    const steps: ReactElement[] = [];

    stepTitles.forEach((stepTranslation, i) => {
      const isActive = i === _activeStep;
      steps.push(
        <button
          key={'step-' + i}
          value={i}
          onClick={({ currentTarget }) => _onStepChange(Number(currentTarget.value))}
          disabled={!isClickable(i)}
          className={classNames(styles.step, {
            [styles.stepActive]: isActive,
            [styles.stepDisabled]: !isClickable(i) && !isActive,
          })}
        >
          {renderStepIcon(i)}
          {formatMessage(stepTranslation)}
        </button>
      );
    });
    return steps;
  };

  return (
    <div className={classNames('HorizontalStepper', styles.root)}>
      {renderSteps()}
      <div>{stepContent[_activeStep]}</div>
    </div>
  );
};

export default HorizintalStepper;
