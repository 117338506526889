import classNames from 'classnames';
import { routeConstants } from 'constants/routes';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { Link, useRouteMatch } from 'react-router-dom';
import styles from './Navigation.module.scss';
import Translations from './Navigation.translations';

const Navigation: FC = () => {
  const { formatMessage } = useIntl();
  const isProfileUrlMatch = useRouteMatch(routeConstants.PROTECTED.PROFILE.route);
  const isChangePasswordUrlMatch = useRouteMatch(routeConstants.PROTECTED.CHANGE_PASSWORD.route);

  return (
    <div className={styles.root}>
      <div className={styles.rootCard}>
        <Link
          className={classNames(styles.navItem, {
            [styles.navItemSelected]: isProfileUrlMatch,
          })}
          to={routeConstants.PROTECTED.PROFILE.url()}
        >
          {formatMessage(Translations.AccountSettingsNavigationContactInformation)}
        </Link>
        <Link
          className={classNames(styles.navItem, {
            [styles.navItemSelected]: isChangePasswordUrlMatch,
          })}
          to={routeConstants.PROTECTED.CHANGE_PASSWORD.url()}
        >
          {formatMessage(Translations.NavigationChangePassword)}
        </Link>
      </div>
    </div>
  );
};

export default Navigation;
