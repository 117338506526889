import clsx from 'clsx';
import { useFormikContext } from 'formik';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { SelectOption } from 'types';
import SelectionBase from '../SelectionBase';
import Translations from './ModuleYearSelection.translations';
import styles from './MonthYearSelection.module.scss';

interface Props {
  monthName?: string;
  yearName: string;
  label: string;
  errorLabel?: string;
  monthValue?: string;
  yearValue?: string;
  defaultYearOptions?: string[];
  defaultMonthOptions?: string[];
  tooltip?: string;
  disabled?: boolean;
  required?: boolean;
  yearOnly?: boolean;
  onMonthChange?: (value: string) => void;
  onYearChange?: (value: string) => void;
}

const createSelectOption = (value: string) => ({ label: value, value });

const MonthYearSelection: FC<Props> = ({
  label,
  monthName,
  yearName,
  monthValue,
  yearValue,
  tooltip,
  disabled = false,
  defaultYearOptions,
  defaultMonthOptions,
  yearOnly = false,
  required,
  onMonthChange,
  onYearChange,
}) => {
  const { setFieldValue } = useFormikContext();
  const { formatMessage } = useIntl();

  const yearOptions: SelectOption[] = [{ label: '', value: '' }];
  const monthOptions: SelectOption[] = [{ label: '', value: '' }];

  const yearMax = new Date().getFullYear();
  for (let i = yearMax; i >= yearMax - 15; i--) {
    yearOptions.push(createSelectOption(i.toString()));
  }
  for (let i = 12; i >= 1; i--) {
    monthOptions.push(createSelectOption(i.toString()));
  }

  const handleMonthChange = (value: string) => {
    setFieldValue(monthName, value);

    if (onMonthChange) onMonthChange(value);
  };

  const handleYearChange = (value: string) => {
    setFieldValue(yearName, value);

    if (onYearChange) onYearChange(value);
  };

  return (
    <div className={styles.root}>
      <label className={clsx('MonthYearSelection-label', styles.label, { [styles.required]: required })}>
        {label}
        {required ? ` *` : ''}
      </label>
      <div className={styles.selectionContainer}>
        {!yearOnly && (
          <div className={styles.monthSelection}>
            <SelectionBase
              options={defaultMonthOptions?.map(createSelectOption) || monthOptions}
              onChange={handleMonthChange}
              value={createSelectOption(monthValue)}
              tooltip={tooltip}
              disabled={disabled}
              placeholder={formatMessage(Translations.MonthYearSelectionMonth)}
            />
          </div>
        )}
        <div className={clsx(styles.yearSelection, { [styles.yearOnly]: yearOnly })}>
          <SelectionBase
            options={defaultYearOptions?.map(createSelectOption) || yearOptions}
            onChange={handleYearChange}
            value={createSelectOption(yearValue)}
            tooltip={tooltip}
            disabled={disabled}
            placeholder={formatMessage(Translations.MonthYearSelectionYear)}
          />
        </div>
      </div>
    </div>
  );
};

export default MonthYearSelection;
