import { StyledComponentProps, Typography, withStyles, WithStyles } from '@material-ui/core';
import clsx from 'clsx';
import Accordion from 'components/Accordion';
import FileUpload from 'components/Forms/FileUpload/FileUpload';
import LoadingIndicator from 'components/LoadingIndicator';
import { Actions as GlobalActions } from 'pages/_store/global/actions';
import { RootState } from 'pages/_store/root-reducer';
import { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useDeleteFile } from 'services/useDeleteFile';
import { useFetchFiles } from 'services/useFetchFiles';
import { useUploadFile } from 'services/useUploadFile';
import { AllFileTypes, FileType, OrderQueryParams, PublicOffer, UploadUpdateFileTypeAdmin } from 'types';
import { compose } from 'utils/compose';
import { Actions as OrderActions } from '../store/actions';
import { OrderPageState } from '../store/initial-state';
import { styles } from './OfferFileUploadForm.styles';
import Translations from './OfferFileUploadForm.translations';
import {
  AIO_OFFER_FILES_FILE_TYPES,
  ALL_FILE_TYPES,
  CUSTOMER_FILES_FILE_TYPES,
  getAllowedMimeTypes,
  getIsMostUsedDocument,
  getUploadUpdateFileTypeSlug,
  isFileTypeAdmin,
  isUploadUpdateFileType,
  LEASING_OFFER_FILES_FILE_TYPES,
  MOST_USED_FILE_TYPES,
  PARTNER_SPOUSE_DOCUMENTS,
  TRADE_IN_DOCUMENTS_FILE_TYPES,
  UPLOAD_TO_UPDATE_FILE_TYPES,
} from './OfferFileUploadForm.utils';

type DefaultDocumentsType = {
  [key in FileType]?: string;
};

const OfferFileUploadForm: FC<WithStyles<typeof styles>> = ({ classes }) => {
  const { formatMessage } = useIntl();

  const { isFetchingResult, order } = useSelector<RootState, OrderPageState>((state) => state.orderPage, shallowEqual);

  const dispatch = useDispatch();

  const publicId = order?.publicId;

  const { orderId } = useParams<OrderQueryParams>();

  const [loading, setLoading] = useState(false);
  const [defaultDocuments, setDefaultDocuments] = useState<DefaultDocumentsType>({});

  useEffect(() => {
    dispatch(OrderActions.getOrderRequest(orderId));
  }, [orderId, dispatch]);

  const fetchFiles = useFetchFiles();
  const uploadFile = useUploadFile();
  const deleteFile = useDeleteFile();

  useEffect(() => {
    if (orderId !== publicId) {
      setDefaultDocuments({} as any);
    }
  }, [orderId, publicId]);

  useEffect(() => {
    if (!order || orderId !== publicId) return;

    (async () => {
      setLoading(true);
      const orderTyped = order as PublicOffer;
      const files = await fetchFiles({
        customerId: orderTyped.customerId,
        offerId: publicId,
        fileTypes: [...ALL_FILE_TYPES],
      });
      setDefaultDocuments(files || {});
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicId]);

  const handleDelete = async (fileType: AllFileTypes) => {
    const isMostUsedDocument = getIsMostUsedDocument(fileType);
    const orderTyped = order as PublicOffer;

    deleteFile({
      customerId: orderTyped.customerId,
      offerId: publicId,
      fileType: fileType,
    });

    if (isMostUsedDocument) {
      setDefaultDocuments((prevState) => {
        const { [fileType]: fileTypeToRemove, ...newState } = prevState;

        return newState;
      });
    }
  };

  const uploadFn = async (file: File, fileType: AllFileTypes) => {
    const isMostUsedDocument = getIsMostUsedDocument(fileType);

    const uploadResult = await uploadFile({
      customerId: order.customerId,
      offerId: publicId,
      file,
      fileType: fileType as FileType,
      isAdminFileType: isFileTypeAdmin(fileType),
      uploadUpdateFileTypeSlug: getUploadUpdateFileTypeSlug(fileType),
      isMostUsedDocument,
    });

    if (isMostUsedDocument && uploadResult !== false && uploadResult?.location) {
      setDefaultDocuments((prevState) => {
        const newState = {
          ...prevState,
          [fileType]: uploadResult.location,
        };

        return newState;
      });
    }

    if (uploadResult && fileType === UploadUpdateFileTypeAdmin.KFP_RESULTS) {
      dispatch(
        GlobalActions.sendNotification({
          message: formatMessage(Translations.OfferFileUploadFormKFPResultsUpdatedForCustomer),
          variant: 'success',
        })
      );
    }

    if (!uploadResult) {
      throw Error(`Failed to upload file: ${fileType}`);
    }
  };

  const renderFiles = (fileTypes: FileType[]) => (
    <div className={classes.row}>
      {fileTypes.map((value) => (
        <div key={value}>
          <FileUpload
            onDelete={handleDelete}
            isLoading={loading}
            src={defaultDocuments[value] || ''}
            name={value}
            label={formatMessage(Translations[value])}
            fileType={value}
            uploadFn={uploadFn}
            allowedTypes={getAllowedMimeTypes(value)}
            acceptsTypes={getAllowedMimeTypes(value)}
            hasControls={!isUploadUpdateFileType(value as AllFileTypes)}
            classes={{
              root: classes.fileUploadRoot,
            }}
          />
        </div>
      ))}
    </div>
  );

  return (
    <div className={clsx('OfferFileUploadForm', classes.root)}>
      <LoadingIndicator isLoading={isFetchingResult}>
        <Accordion
          header={<FormattedMessage id="OfferFileUploadFormDocuments" defaultMessage="Documents" />}
          collapsable={false}
        >
          <Accordion
            header={
              <Typography className={classes.title}>
                <FormattedMessage id="OfferFileUploadFormMostUsed" defaultMessage="Most used" />
              </Typography>
            }
          >
            {renderFiles(MOST_USED_FILE_TYPES)}
          </Accordion>

          <Accordion
            header={
              <Typography className={classes.title}>
                <FormattedMessage id="OfferFileUploadFormCustomerFiles" defaultMessage="Customer files" />
              </Typography>
            }
            initialExpandState={false}
          >
            {renderFiles(CUSTOMER_FILES_FILE_TYPES)}
          </Accordion>

          <Accordion
            header={
              <Typography className={classes.title}>
                <FormattedMessage
                  id="OfferFileUploadFormPartnerSpouseDocuments"
                  defaultMessage="Partner/Spouse documents"
                />
              </Typography>
            }
            initialExpandState={false}
          >
            {renderFiles(PARTNER_SPOUSE_DOCUMENTS)}
          </Accordion>

          <Accordion
            header={
              <Typography className={classes.title}>
                <FormattedMessage id="OfferFileUploadFormTradeInDocuments" defaultMessage="Trade in documents" />
              </Typography>
            }
            initialExpandState={false}
          >
            {renderFiles(TRADE_IN_DOCUMENTS_FILE_TYPES)}
          </Accordion>

          <Accordion
            header={
              <Typography className={classes.title}>
                <FormattedMessage id="OfferFileUploadFormLeasingOfferFiles" defaultMessage="Leasing offer files" />
              </Typography>
            }
            initialExpandState={false}
          >
            {renderFiles(LEASING_OFFER_FILES_FILE_TYPES)}
          </Accordion>

          <Accordion
            header={
              <Typography className={classes.title}>
                <FormattedMessage id="OfferFileUploadFormAIOOfferFiles" defaultMessage="AIO offer files" />
              </Typography>
            }
            initialExpandState={false}
          >
            {renderFiles(AIO_OFFER_FILES_FILE_TYPES)}
          </Accordion>

          <Accordion
            header={
              <Typography className={classes.title}>
                <FormattedMessage id="OfferFileUploadFormOfferFilesAdmin" defaultMessage="Upload to update" />
              </Typography>
            }
            initialExpandState={false}
          >
            {renderFiles(UPLOAD_TO_UPDATE_FILE_TYPES)}
          </Accordion>
        </Accordion>
      </LoadingIndicator>
    </div>
  );
};

export default compose<StyledComponentProps>(OfferFileUploadForm, withStyles(styles, { name: 'OfferFileUploadForm' }));
