import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormatNumber from 'components/FormatNumber';
import Input from 'components/Forms/Input';
import SelectionBase from 'components/Forms/SelectionBase';
import { CHF, KMType } from 'constants/constants';
import { useField, useFormikContext } from 'formik';
import { AddEditListingSchema } from 'pages/AddEditListings/schema';
import { Actions as AddEditListingsActions } from 'pages/AddEditListings/store/actions';
import { calculateResidualValuePercent } from 'pages/AddEditListings/utils';
import { RootState } from 'pages/_store/root-reducer';
import React, { FunctionComponent, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ResidualValue, ResidualValueCalculation } from 'types';
import Translations from '../../../../ResidualValueBreakdownForm.translations';
import styles from './ResidualValueMileageRow.module.scss';

interface Props {
  residualValue: ResidualValue;
  index: number;
  availableKMs: KMType[];
  disabled: boolean;
  remove: (index: number) => void;
}

const ResidualValueMileageRow: FunctionComponent<Props> = ({
  index,
  residualValue: item,
  disabled,
  remove,
  availableKMs,
}) => {
  const { values, setFieldValue } = useFormikContext<AddEditListingSchema>();
  const deal = useSelector((state: RootState) => state.addEditListingsPage.deal, shallowEqual);
  const { formatMessage, formatNumber } = useIntl();
  const dispatch = useDispatch();

  const getName = () => `residualValues[${index}]`;

  const [, meta] = useField(getName());

  const isMounted = useRef(false);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
    }
  }, []);

  const setUnsavedChanges = () => dispatch(AddEditListingsActions.setUnsavedChanges());

  const onAbsoluteValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = Number(e.target.value);

    const isOnPrice = deal?.residualValueCalculation === ResidualValueCalculation.ON_PRICE;
    let isOnListPrice = deal?.residualValueCalculation === ResidualValueCalculation.ON_LIST_PRICE;

    // On  deal create, the deal object is not available and we use a fallback
    if (!isOnPrice && !isOnListPrice) {
      isOnListPrice = true;
    }

    let percentage = calculateResidualValuePercent(value, isOnPrice, isOnListPrice, values.price, values.newPrice);

    if (percentage) {
      if (percentage > 100) {
        value = (100 * value) / percentage;
        percentage = 100;
      }

      if (percentage < 0) {
        value = 0;
        percentage = 0;
      }

      if (percentage !== parseInt(percentage.toString(), 2)) {
        percentage = Number(percentage.toFixed(1));
      }
    }

    setFieldValue(`${getName()}.residualValue`, percentage);
    setUnsavedChanges();
  };

  const onResidualValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = Number(e.target.value);
    if (value > 100) {
      value = 100;
    }

    if (value < 0) {
      value = 0;
    }

    setFieldValue(`${getName()}.residualValue`, value);
    isMounted.current = false;
    setUnsavedChanges();
  };

  const onKMTypeChange = (value: string) => {
    setFieldValue(`${getName()}.km`, Number(value));
    setUnsavedChanges();
  };

  const onDeleteButtonClick = () => {
    remove(index);
    setUnsavedChanges();
  };
  const kmOptions = availableKMs.map((km) => ({
    label: formatNumber(km),
    value: String(km),
  }));

  const dealPrice = deal?.listing?.price || 0;

  return (
    <div className={styles.residualValuesRow}>
      <div className={styles.rowValue}>
        <button className={styles.deleteButton} onClick={onDeleteButtonClick} disabled={disabled}>
          <FontAwesomeIcon icon={['fal', 'trash-alt']} />
        </button>

        {item.km ? (
          <span className={styles.milleage}>
            <FormatNumber value={item.km} withCurrency={false} />
          </span>
        ) : (
          <SelectionBase
            options={kmOptions}
            classes={{
              root: styles.selectionRoot,
            }}
            inputAdornment={'km'}
            onChange={onKMTypeChange}
          />
        )}
      </div>
      <div className={styles.residualValueInputRow}>
        <Input
          startAdornment={'%'}
          value={Math.round((10000 * item.residualValueAbsolute) / dealPrice) / 100}
          classes={{
            root: styles.residualInputPercent,
          }}
          type="number"
          min={0}
          max={100}
          onChange={onResidualValueChange}
          name={getName()}
          disabled={disabled}
          step={0.1}
          tooltip={
            values.isResidualValueDefault
              ? formatMessage(Translations.ResidualValuesBreakdownFormDisableTheUseOfDefaultResidualValuesToEdit)
              : undefined
          }
        />
        <div className={styles.equalSeparator}>=</div>
        <Input
          startAdornment={CHF}
          value={item.residualValueAbsolute}
          onChange={onAbsoluteValueChange}
          type="number"
          min={0}
          classes={{
            root: styles.residualInputAbsolute,
          }}
          disabled={
            disabled ||
            (!values.price && deal?.residualValueCalculation === ResidualValueCalculation.ON_PRICE) ||
            (!values.newPrice && deal?.residualValueCalculation === ResidualValueCalculation.ON_LIST_PRICE)
          }
          tooltip={
            values.isResidualValueDefault
              ? formatMessage(Translations.ResidualValuesBreakdownFormDisableTheUseOfDefaultResidualValuesToEdit)
              : undefined
          }
        />
        {!!meta.error && <div className={styles.errorLabel}>{(meta.error as any).residualValue}</div>}
      </div>
    </div>
  );
};

export default ResidualValueMileageRow;
