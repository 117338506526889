import ConfirmationModalBase from 'components/ConfirmationModalBase';
import { useFormikContext } from 'formik';
import { AddEditListingSchema } from 'pages/AddEditListings/schema';
import { RootState } from 'pages/_store/root-reducer';
import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Actions as AddEditListingsActions } from '../../store/actions';
import Translations from './DisableSyncConfirmationModal.translations';

const DisableSyncConfirmationModal: FunctionComponent = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const isDisableSyncConfirmationModalOpen = useSelector(
    (state: RootState) => state.addEditListingsPage.isDisableSyncDialogOpen,
    shallowEqual
  );

  const { setFieldValue, values } = useFormikContext<AddEditListingSchema>();

  const onOkey = () => {
    dispatch(AddEditListingsActions.setIsDisableSyncDialogOpen(false));
    dispatch(AddEditListingsActions.updateLockedToSourceRequest(false));
  };

  const onClose = () => {
    setFieldValue('isLockedToSource', !values.isLockedToSource);
    dispatch(AddEditListingsActions.setIsDisableSyncDialogOpen(false));
  };

  return (
    <ConfirmationModalBase
      title={formatMessage(Translations.DisableSyncConfirmationModalDisableSyncWithAutoScout24)}
      message={formatMessage(Translations.DisableSyncConfirmationModalDisableAutomaticListingUpdates)}
      okLabel={formatMessage(Translations.DisableSyncConfirmationModalYesDisableSync)}
      closeLabel={formatMessage(Translations.DisableSyncConfirmationModalBack)}
      isOpen={isDisableSyncConfirmationModalOpen}
      onOkay={onOkey}
      onClose={onClose}
      variant={'error'}
      isOkButtonLeft={false}
    />
  );
};

export default DisableSyncConfirmationModal;
