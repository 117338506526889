import { defineMessages } from 'react-intl';

export default defineMessages({
  VendorInformationSaveChanges: {
    id: 'VendorInformationSaveChanges',
    defaultMessage: 'Save changes',
  },
  VendorInformationThisFieldIsRequired: {
    id: 'VendorInformationThisFieldIsRequired',
    defaultMessage: 'This field is required',
  },
  VendorInformationInvalidFormat: {
    id: 'VendorInformationInvalidFormat',
    defaultMessage: 'Invalid format',
  },
  VendorInformationInvalidEmail: {
    id: 'VendorInformationInvalidEmail',
    defaultMessage: 'Invalid email',
  },
  VendorInformationContactInformationWasSaved: {
    id: 'VendorInformationContactInformationWasSaved',
    defaultMessage: 'Contact information was saved',
  },
  VendorInformationContactInformationCouldNotBeChanged: {
    id: 'VendorInformationContactInformationCouldNotBeChanged',
    defaultMessage: 'Contact information could not be changed due to the following errors',
  },
  VendorInformationName: {
    id: 'VendorInformationName',
    defaultMessage: 'Name',
  },
  VendorInformationPhoneNumber: {
    id: 'VendorInformationPhoneNumber',
    defaultMessage: 'Phone number',
  },
  VendorInformationLeadEmail: {
    id: 'VendorInformationLeadEmail',
    defaultMessage: 'Lead Email',
  },
  VendorInformationStreetAndNumber: {
    id: 'VendorInformationStreetAndNumber',
    defaultMessage: 'Street and number',
  },
  VendorInformationCity: {
    id: 'VendorInformationCity',
    defaultMessage: 'City',
  },
  VendorInformationZipCode: {
    id: 'VendorInformationZipCode',
    defaultMessage: 'Zip code',
  },
  VendorInformationYouHaveUnsavedChanged: {
    id: 'VendorInformationYouHaveUnsavedChanged',
    defaultMessage: 'You have unsaved changes',
  },
  VendorInformationContinueEditing: {
    id: 'VendorInformationContinueEditing',
    defaultMessage: 'Continue editing',
  },
  VendorInformationDiscardChanges: {
    id: 'VendorInformationDiscardChanges',
    defaultMessage: 'Discard changes',
  },
  VendorInformationSomethingWentWrong: {
    id: 'VendorInformationSomethingWentWrong',
    defaultMessage: 'Something went wrong and contact information could not be saved',
  },
  VendorInformationPleaseTrySavingOneMoreTime: {
    id: 'VendorInformationPleaseTrySavingOneMoreTime',
    defaultMessage: 'Please try saving one more time',
  },
  VendorInformationIfThisIssuePersistPleaseContactOurSupport: {
    id: 'VendorInformationIfThisIssuePersistPleaseContactOurSupport',
    defaultMessage: 'If this issue persists, please contact our support team for assistence',
  },
});
