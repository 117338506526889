import { Reducer } from 'redux';
import { Actions, ActionTypes } from './actions';
import { initialState, ManagePeopleState } from './initial-state';

export const managePeopleReducer: Reducer<ManagePeopleState> = (
  state = initialState,
  action: Actions
): ManagePeopleState => {
  switch (action.type) {
    case ActionTypes.GET_SELLERS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ActionTypes.GET_SELLERS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        sellers: action.payload,
      };
    }
    case ActionTypes.GET_SELLERS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    }

    case ActionTypes.SEND_SELLER_SIGNUP_REQUEST_REQUEST: {
      return {
        ...state,
        isLoading: true,
        showSuccessMessage: false,
      };
    }
    case ActionTypes.SEND_SELLER_SIGNUP_REQUEST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        sellers: action.payload,
        showSuccessMessage: true,
      };
    }
    case ActionTypes.SEND_SELLER_SIGNUP_REQUEST_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        showSuccessMessage: false,
      };
    }

    case ActionTypes.HIDE_SUCCESS_MESSAGE: {
      return {
        ...state,
        showSuccessMessage: false,
      };
    }

    default: {
      return state;
    }
  }
};
