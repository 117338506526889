import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Accordion from 'components/Accordion';
import LoadingIndicator from 'components/LoadingIndicator';
import { useFormikContext } from 'formik';
import useIsLockedToSource from 'pages/AddEditListings/hooks/useIsLockedToSource';
import { AddEditListingSchema } from 'pages/AddEditListings/schema';
import { RootState } from 'pages/_store/root-reducer';
import { FC, Fragment, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { useDropzone } from 'react-dropzone';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ImageUploadFile } from 'types';
import { compose } from 'utils/compose';
import { Actions as AddEditListingsActions } from '../../store/actions';
import styles from './CarImageForm.module.scss';
import Translations from './CarImageForm.translations';

const CarImageForm: FC = () => {
  const { formatMessage } = useIntl();

  const { isLoadingAction, isLoadingDeal, dealImages, isLoadingImages } = useSelector(
    (state: RootState) => state.addEditListingsPage,
    shallowEqual
  );

  const { isLockedToSource } = useIsLockedToSource();

  const [files, setFiles] = useState<ImageUploadFile[]>([]);
  const { values, setFieldValue } = useFormikContext<AddEditListingSchema>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (dealImages.length && !values.images.length) {
      const images: ImageUploadFile[] = dealImages.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      setFiles(images);
      setFieldValue('images', images);
    }
    // eslint-disable-next-line
  }, [dealImages, setFieldValue]);

  const setUnsavedChanges = () => dispatch(AddEditListingsActions.setUnsavedChanges());

  const reorder = (list: ImageUploadFile[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      const values = [
        ...files,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ];
      setFiles(values);
      setFieldValue('images', values);
      setUnsavedChanges();
    },
  });

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(files, result.source.index, result.destination.index);
    const values = [...items];
    setFiles(values);
    setFieldValue('images', values);
    setUnsavedChanges();
  };

  const removeImage = (index: number) => () => {
    const values = files.filter((item, itemIndex) => itemIndex !== index);
    setFiles(values);
    setFieldValue('images', values);
    setUnsavedChanges();
  };

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  // temporary fix for the ordering bug when we have uploaded and uploaded files
  const hasUnuploadedImages = !!files.find((file) => file.path);
  const hasUploadedImages = !!files.find((file) => !file.path);

  const cannotDrag = hasUnuploadedImages && hasUploadedImages;

  return (
    <div className={styles.root}>
      <LoadingIndicator isLoading={isLoadingAction || isLoadingDeal || isLoadingImages}>
        <Accordion header={formatMessage(Translations.CarImageFormCarPhotos)} collapsable={false}>
          {!isLockedToSource && (
            <>
              <div className={styles.headerContainer}>
                <div className={styles.header}>
                  {formatMessage(Translations.CarImageFormAddUpTo16PhotosOfTheCar, {
                    bold: (msg) => (
                      <span key="header-counter" className={styles.headerCounter}>
                        {msg}
                      </span>
                    ),
                  })}
                </div>
                <div className={styles.subHeader}>
                  {formatMessage(Translations.CarImageFormImagesMustBeInJpgPngOrGifFormatsAMinimumOf)}
                </div>
              </div>
              <section className="container">
                <div
                  {...getRootProps({
                    className: classNames('dropzone', styles.dropZoneContainer),
                  })}
                >
                  <input {...getInputProps()} />
                  <p className={styles.dragAndDropLabel}>
                    {formatMessage(Translations.CarImageFormDragAndDropPhotosHereOrBrowseFiles, {
                      browselink: (msg) => (
                        <span key="browse-file-label" className={styles.dragAndDropBrowseFileLabel}>
                          {msg}
                        </span>
                      ),
                    })}
                  </p>
                </div>
              </section>
            </>
          )}
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" direction="horizontal">
              {(provided, snapshot) => (
                <div className={styles.imagePreviewRoot}>
                  <div {...provided.droppableProps} ref={provided.innerRef} className={styles.imagePreviewContainer}>
                    {files.map((file: ImageUploadFile, index: number) => (
                      <Fragment key={'draggable' + file.name + file.preview}>
                        <div
                          className={classNames(styles.imagePreviewContent, {
                            [styles.hidden]: !(isLockedToSource || cannotDrag),
                          })}
                        >
                          {!isLockedToSource && (
                            <button className={styles.imagePreviewCloseButton} onClick={removeImage(index)}>
                              <FontAwesomeIcon icon={['far', 'times']} />
                            </button>
                          )}
                          <div
                            className={styles.imagePreview}
                            onClick={() => window.open(file.name, '_blank')}
                            style={{
                              backgroundImage: `url(${file.preview}), url(${file.name})`,
                            }}
                          />
                        </div>
                        <div className={classNames({ [styles.hidden]: isLockedToSource || cannotDrag })}>
                          <Draggable
                            key={'draggable' + file.name + file.preview}
                            draggableId={file.name + file.preview}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={provided.draggableProps.style}
                                className={styles.imagePreviewContent}
                              >
                                <button className={styles.imagePreviewCloseButton} onClick={removeImage(index)}>
                                  <FontAwesomeIcon icon={['far', 'times']} />
                                </button>
                                <div
                                  className={styles.imagePreview}
                                  style={{
                                    backgroundImage: `url(${file.preview})`,
                                  }}
                                />
                              </div>
                            )}
                          </Draggable>
                        </div>
                      </Fragment>
                    ))}
                    {provided.placeholder}
                  </div>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Accordion>
      </LoadingIndicator>
    </div>
  );
};

export default compose(CarImageForm);
