import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import Accordion from 'components/Accordion';
import Checkbox from 'components/Forms/Checkbox';
import Selection from 'components/Forms/Selection';
import TextField from 'components/Forms/TextField';
import LoadingIndicator from 'components/LoadingIndicator';
import { useFormikContext } from 'formik';
import { AddEditListingSchema } from 'pages/AddEditListings/schema';
import { Actions as AddEditListingsActions } from 'pages/AddEditListings/store/actions';
import { RootState } from 'pages/_store/root-reducer';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { availableLenghtsOfLease, availableMileage } from 'types';
import { compose } from 'utils/compose';
import FormSubtitle from '../FormSubtitle';
import styles from './PromotionsForm.module.scss';
import { default as messages, default as Translations } from './PromotionsForm.translations';
import {
  formatAnnualMileageOptions,
  formatContractLengthOptions,
  useClosedUserGroupOptions,
} from './PromotionsForm.utils';

interface Params {
  vendorId: string;
  dealId?: string;
}

const PromotionsForm: FC = () => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const dispatch = useDispatch();
  const { dealId: dealIdParam } = useParams<Params>();

  const { values, setFieldValue } = useFormikContext<AddEditListingSchema>();

  const contractLengthOptions = formatContractLengthOptions({
    formatMessage,
    availableLenghtsOfLease,
  });

  const mileageOptions = formatAnnualMileageOptions({
    formatMessage,
    availableMileage,
  });

  const closedUserGroupsOptions = useClosedUserGroupOptions(formatMessage);

  const { isLoadingAction, isLoadingDeal } = useSelector((state: RootState) => state.addEditListingsPage, shallowEqual);

  const setUnsavedChanges = () => dispatch(AddEditListingsActions.setUnsavedChanges());

  const onContractLengthChange = (value: string) => {
    setFieldValue('defaultLeasingPeriod', value);
    setUnsavedChanges();
  };

  const onMileageChange = (value: string) => {
    setFieldValue('defaultLeasingMileage', value);
    setUnsavedChanges();
  };

  const onClosedUserGroupChange = (value: string) => {
    setFieldValue('closedUserGroups', value);
    setUnsavedChanges();
  };

  const isLoading = isLoadingAction || isLoadingDeal;
  return (
    <div className={styles.root}>
      <LoadingIndicator isLoading={isLoading}>
        <Accordion
          classes={{ icon: styles.icon }}
          header={formatMessage(Translations.PromotionsFormPromotions)}
          collapsable
        >
          <FormSubtitle text={formatMessage(Translations.PromotionsFormGowagoChSalePage)} />
          <div className={styles.checkbox}>
            <Checkbox
              label={formatMessage(Translations.PromotionsFormShowInGowagoChSalePage)}
              name="showOnSalePage"
              className={styles.checkbox}
              onChange={setUnsavedChanges}
            />
          </div>
          <div className={styles.formColumn}>
            <div className={styles.formRow}>
              <TextField
                name="orderGowagoSale"
                type="number"
                onChange={setUnsavedChanges}
                disabled={!values.showOnSalePage}
                label={formatMessage(Translations.PromotionsFormOrderInTheList)}
              />
              <Typography className={styles.secondaryLabel}>
                {formatMessage(Translations.PromotionsFormChoseThePositionOfThisCar)}
              </Typography>
            </div>
          </div>
          <div className={styles.formColumn}>
            <div className={styles.formRow}>
              <Selection
                name="defaultLeasingPeriod"
                label={formatMessage(Translations.PromotionsFormDefaultContractLength)}
                placeholder={formatMessage(messages.PromotionsSelectContractLength)}
                options={contractLengthOptions}
                onChange={onContractLengthChange}
                disabled={!values.showOnSalePage}
              />
            </div>
            <div className={styles.formRow}>
              <Selection
                name="defaultLeasingMileage"
                label={formatMessage(Translations.PromotionsFormDefaultAnnualMileage)}
                placeholder={formatMessage(messages.PromotionsSelectAnnualMileage)}
                options={mileageOptions}
                onChange={onMileageChange}
                disabled={!values.showOnSalePage}
              />
            </div>
            <div className={styles.formRow}>
              <TextField
                name="defaultDownPayment"
                type="number"
                label={formatMessage(Translations.PromotionsFormDefaultDownPayment)}
                disabled={!values.showOnSalePage}
              />
            </div>
          </div>
          <div className={styles.divider} />
          <FormSubtitle text={formatMessage(Translations.PromotionsFormFeaturedDeals)} />
          <div className={styles.checkbox}>
            <Checkbox
              label={formatMessage(Translations.PromotionsFormShowInFeaturedDeals)}
              name="showInFeaturedDeals"
              onChange={setUnsavedChanges}
            />
          </div>
          <div className={styles.formColumn}>
            <div className={styles.formRow}>
              <TextField
                name="orderFeatured"
                type="number"
                onChange={setUnsavedChanges}
                disabled={!values.showInFeaturedDeals}
                label={formatMessage(Translations.PromotionsFormOrderInTheList)}
              />
              <Typography className={styles.secondaryLabel}>
                {formatMessage(Translations.PromotionsFormChoseThePositionOfThisCar)}
              </Typography>
            </div>
          </div>

          <div className={styles.divider} />
          <div className={styles.otherDealTypesContainer}>
            <div className={styles.otherDealType}>
              <FormSubtitle text={formatMessage(Translations.PromotionsFormHotDeals)} />
              <div className={styles.checkbox}>
                <Checkbox
                  label={formatMessage(Translations.PromotionsFormMarkAsAHotDeal)}
                  name="isHotDeal"
                  className={styles.checkbox}
                  onChange={setUnsavedChanges}
                />
              </div>
            </div>
            <div className={styles.otherDealType}>
              <FormSubtitle text={formatMessage(Translations.PromotionsFormDealOfTheMonth)} />
              <div className={styles.checkbox}>
                <Checkbox
                  label={formatMessage(Translations.PromotionsFormMarkAsDealOfTheMonth)}
                  name="isDealOfTheMonth"
                  className={styles.checkbox}
                  onChange={setUnsavedChanges}
                />
              </div>
            </div>
            <div className={styles.otherDealType}>
              {!!dealIdParam && (
                <>
                  <FormSubtitle text={formatMessage(Translations.PromotionsFormFleetDiscount)} />
                  <div className={classNames(styles.checkbox, styles.alignNormal)}>
                    <Checkbox
                      label={formatMessage(Translations.PromotionsFormMarkAsAvailableForFleetDiscount)}
                      name="isAvailableForFleetDiscount"
                      className={styles.checkbox}
                      onChange={setUnsavedChanges}
                      disabled
                    />
                  </div>
                </>
              )}
            </div>
          </div>

          {/* Closed user groups */}
          <div className={styles.divider} />
          <FormSubtitle text={formatMessage(Translations.PromotionsFormClosedUserGroups)} />
          <div className={styles.formColumn}>
            <div className={styles.formLargerRow}>
              <Selection
                label=""
                name="closedUserGroups"
                placeholder={formatMessage(messages.PromotionsNoClosedUserGroupSelected)}
                options={closedUserGroupsOptions}
                onChange={onClosedUserGroupChange}
              />
            </div>
          </div>
          <Typography className={styles.secondaryLabel}>
            {formatMessage(Translations.PromotionsFormShowThisToCloseGroupsMembers)}
          </Typography>
        </Accordion>
      </LoadingIndicator>
    </div>
  );
};

export default compose(PromotionsForm);
