import { useSnackbar } from 'notistack';
import { Actions as GlobalActions } from 'pages/_store/global/actions';
import { RootState } from 'pages/_store/root-reducer';
import { FC, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { NotificationPropsExtended } from 'types';

const NotificationHandler: FC = ({ children }) => {
  const notification: NotificationPropsExtended[] = useSelector(
    (state: RootState) => state.globalAppState.notifications,
    shallowEqual
  );
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [displayedNotifications, setDisplayedNotifications] = useState<string[]>([]);

  useEffect(() => {
    notification
      .filter((n) => !displayedNotifications.includes(n.id))
      .forEach((notifItem) => {
        const { message, id, ...notifProps } = notifItem;
        setDisplayedNotifications((displayedNotifications) => [...displayedNotifications, id]);

        enqueueSnackbar(message, {
          ...notifProps,
          onClick: (ev) => {
            if (notifProps.onClick) {
              notifProps.onClick(ev);
            }

            closeSnackbar(notifProps.key);
          },
          onClose: () => {
            setDisplayedNotifications((displayedNotifications) => [...displayedNotifications.filter((n) => n !== id)]);
            closeSnackbar(notifProps.key);
            dispatch(GlobalActions.removeNotification(id));
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, enqueueSnackbar, closeSnackbar, notification]);

  return <>{children}</>;
};

export default NotificationHandler;
