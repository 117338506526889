import { useParams, useQuery, useUpdateQuery } from 'hooks/router';
import { FC, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DefaultVendorParams, OrdersQuery } from 'types';
import { RootState } from '../../_store/root-reducer';
import FilterPanel from '../FilterPanel';
import OrderResults from '../OrderResults';
import { Actions as OrderActions } from '../store/actions';
import { initialState } from '../store/initial-state';
import styles from '../ViewAllOrders.module.scss';
import Header from '../ViewAllOrdersHeader';

const ViewAllVendorOrders: FC = () => {
  const dispatch = useDispatch();
  const { vendorId } = useParams<DefaultVendorParams>();

  const countFiltered = useSelector((state: RootState) => state.ordersPage.result.countFiltered, shallowEqual);

  const query = useQuery<Partial<OrdersQuery>>({
    initialObj: initialState.query,
  });

  const updateQuery = useUpdateQuery<Partial<OrdersQuery>>({
    replace: true,
    initialState: initialState.query,
  });

  const [searchTerm, setSearchTerm] = useState(query?.query);

  // fetch data on query change
  useEffect(() => {
    dispatch(OrderActions.getVendorOrdersRequest(Number(vendorId), query));
  }, [vendorId, query, dispatch, updateQuery]);

  return (
    <div className={styles.root}>
      <Header $total={countFiltered} />
      <div className={styles.resultsContainer}>
        <div className={styles.filterContainer}>
          <FilterPanel searchTerm={searchTerm} onSearchTermChange={setSearchTerm} />
        </div>
        <OrderResults onSearchTermChange={setSearchTerm} />
      </div>
    </div>
  );
};

export default withRouter(ViewAllVendorOrders);
