import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  EditOrderPageEditOrder: {
    id: 'EditOrderPageEditOrder',
    defaultMessage: 'Edit order',
  },
  EditOrderPageCreated: {
    id: 'EditOrderPageCreated',
    defaultMessage: 'Created: {dateString}',
  },
  EditOrderPageOrderDeactivated: {
    id: 'EditOrderPageOrderDeactivated',
    defaultMessage: 'Order deactivated',
  },
  EditOrderPageOrderReactivated: {
    id: 'EditOrderPageOrderReactivated',
    defaultMessage: 'Order re-activated',
  },
  EditOrderPageErrorDeactivatingTheOrder: {
    id: 'EditOrderPageErrorDeactivatingTheOrder',
    defaultMessage: 'Error deactivating the order',
  },
  EditOrderPageErrorReactivatingTheOrder: {
    id: 'EditOrderPageErrorReactivatingTheOrder',
    defaultMessage: 'Error re-activating the order',
  },
  EditOrderPageRequired: {
    id: 'EditOrderPageRequired',
    defaultMessage: 'Required',
  },
  EditOrderPageLicensingDateShouldBeLaterThan: {
    id: 'EditOrderPageLicensingDateShouldBeLaterThan',
    defaultMessage: 'Licensing date should be later than {value}',
  },
});
