import { APIError } from 'types';
import { ActionsUnion, createAction } from 'utils/action-utils';
import { SellerRegistrationData } from './initial-state';

export enum ActionTypes {
  VALIDATE_TOKEN_REQUEST = '[REGISTER_PAGE] VALIDATE_TOKEN_REQUEST',
  VALIDATE_TOKEN_SUCCESS = '[REGISTER_PAGE] VALIDATE_TOKEN_SUCCESS',
  VALIDATE_TOKEN_FAILURE = '[REGISTER_PAGE] VALIDATE_TOKEN_FAILURE',
  REGISTER_SELLER_REQUEST = '[REGISTER_PAGE] REGISTER_SELLER_REQUEST',
  REGISTER_SELLER_SUCCESS = '[REGISTER_PAGE] REGISTER_SELLER_SUCCESS',
  REGISTER_SELLER_FAILURE = '[REGISTER_PAGE] REGISTER_SELLER_FAILURE',
}

export const Actions = {
  validateTokenRequest: (token: string) => createAction(ActionTypes.VALIDATE_TOKEN_REQUEST, token),
  validateTokenSuccess: (userData: Partial<SellerRegistrationData>) =>
    createAction(ActionTypes.VALIDATE_TOKEN_SUCCESS, userData),
  validateTokenFailure: (e: APIError) => createAction(ActionTypes.VALIDATE_TOKEN_FAILURE, e),
  registerSellerRequest: (sellerData: Partial<SellerRegistrationData>) =>
    createAction(ActionTypes.REGISTER_SELLER_REQUEST, sellerData),
  registerSellerSuccess: () => createAction(ActionTypes.REGISTER_SELLER_SUCCESS),
  registerSellerFailure: (e: APIError) => createAction(ActionTypes.REGISTER_SELLER_FAILURE, e),
};

export type Actions = ActionsUnion<typeof Actions>;
