import clsx from 'clsx';
import Accordion from 'components/Accordion';
import SwitchBase from 'components/Forms/SwitchBase';
import LoadingIndicator from 'components/LoadingIndicator';
import { RootState } from 'pages/_store/root-reducer';
import { FC, Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import AllInOneCosts from './AllInOneCosts';
import styles from './AllInOneSection.module.scss';
import AllInOneYearlyFees from './AllInOneYearlyFees';

const AllInOneSection: FC = () => {
  const [asJSON, setAsJSON] = useState(false);
  const { isLoadingAction, isLoadingDeal, deal } = useSelector(
    (state: RootState) => state.addEditListingsPage,
    shallowEqual
  );

  const hasCosts = deal?.listing?.aio?.costs;
  const hasYearlyFees = deal?.listing?.aio?.yearlyFees;

  const handleViewChange = (value: boolean) => {
    setAsJSON(value);
  };

  if (!isLoadingAction && !isLoadingDeal && !hasCosts && !hasYearlyFees) {
    return <Fragment />;
  }

  return (
    <div className={clsx('AllInOneSection', styles.root)}>
      <LoadingIndicator isLoading={isLoadingAction || isLoadingDeal}>
        <Accordion header="All-in-one">
          <div>
            <SwitchBase
              onChange={handleViewChange}
              checked={asJSON}
              label={<FormattedMessage id="AllInOneSectionShowAsJSON" defaultMessage="View as JSON" />}
            />
          </div>
          <AllInOneCosts asJSON={asJSON} />
          <AllInOneYearlyFees asJSON={asJSON} />
        </Accordion>
      </LoadingIndicator>
    </div>
  );
};

export default AllInOneSection;
